import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './Loading.css'

const Loading = (props) => {
    // ! to be shown in a block need isInBlock true and the parent element must be absolute, fixed or relative
    const { isInBlock = false } = props;
    return (
        <div className={isInBlock ? 'inBlockClass' : 'loading'}>
            <CircularProgress color="inherit" />
        </div>
    )
}

export default Loading
