import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import error_icon from '../../images/error_icon.png';
import { selectLoading, setIsLoading, stepperRank, vibrationPopUp } from '../../reducer/onBoardSlice';
import { useDispatch } from 'react-redux';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { apiMethods, baseGalenHeaders, galenBasePath } from '../../api/helper';
import { fetchData } from '../../api/fetchData';
import { login, setCurrentFirmware, setNextFirmware, setUserLoadData, userToken } from '../../reducer/userSlice';
import Loading from '../../shared/Loading-screen/Loading';
import { setNotify } from '../../reducer/notifySlice';
import { notifyTypes, stepsToDevice } from '../../utils/constants';
import { isEmpty } from '../../utils/_helperFunction';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const initState = {
    emailAddress: '',
    password: '',
    keepLoggedIn: false
}

const LoginModal = ({server}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(selectLoading);
    const [error, setError ] = React.useState(false);
    const [state, setState] = React.useState(initState);
    const [isPasswordShow, setShowPassword] = React.useState(false);
    const [isCapsShow, setShowCaps] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!isPasswordShow);

    const { emailAddress, password, keepLoggedIn } = state;

    const onSignUpClick = () => {
        if (isLoading) {
            return
        }
        dispatch(stepperRank(0.2))
    }

    const onForgotPasswordClick = async () => {
        const path = `${galenBasePath}/user/password/send-reset-code?email=${emailAddress}`;
        try {
            await fetchData(path, apiMethods.POST, null, null, baseGalenHeaders);
            dispatch(
                setNotify({
                    message: `Reset link sent. Please check ${emailAddress}.`,
                    type: notifyTypes.INFO,
                    duration: 3000,
                })
            );
        } catch (error) {
            dispatch(
                setNotify({
                    message: error,
                    type: notifyTypes.ERROR,
                    duration: 3000,
                })
            );
        }
    };

    const handleCapsLock= event => {
        if (!event.getModifierState("CapsLock")) {
            if (isCapsShow) {
                setShowCaps(false)
                setError('')
            }
            return
        } else {
            setShowCaps(true)
            setError('Caps Lock is on')
        }
    }

    const handleChange = (event) => {
        if (isLoading) {
            return
        }
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'keepLoggedIn') {
            value = !keepLoggedIn
        }
        setState(prev => ({...prev, [name]: value}))
        if (error) {
            setError(false)
        }
    }

    const startWork = () => {
        dispatch(vibrationPopUp(false));
        dispatch(stepperRank(0))
        navigate("/onboarding")
        dispatch(setNotify({message: 'Logged in successfully!', type: notifyTypes.SUCCESS, duration: 3000}))
    }

    const onSubmit = async () => {
        if (password.length < 6 || !emailAddress.includes('@') || emailAddress.length < 4 ) {
            setError(true)
            return;
        }
        dispatch(setIsLoading(true))
        try {
            const path = `${galenBasePath}/auth/login?emailAddress=${emailAddress}&password=${password}`;
            const method = apiMethods.POST;
            const headers = baseGalenHeaders;
            const response = await fetchData(path, method, null, null, headers);
            if (response?.data) {
                const userData = response.data;
                dispatch(
                    login({
                    userId: userData.userId,
                    userName: userData.fullName,
                    userMail: userData.emailAddress,
                    userRole: userData.currentRole.role,
                    canViewSensitiveData: userData.canViewSensitiveData,
                    status: userData.status
                    })
                );
            }
            if (response?.token) {
                dispatch(userToken({ token: response.token?.replace('Bearer ', '') }));
            }
            if (response?.data && response?.token) {
                const currentDisplayVersion = await server.getVersion();
                dispatch(setCurrentFirmware(currentDisplayVersion))
                console.log('currentDisplayVersion', currentDisplayVersion)
                const deviceId = '6f740190-c76f-4dac-940d-a9f8879d8a79';
                if (currentDisplayVersion) {
                    const path = `${galenBasePath}/user/firmware/next?deviceId=${deviceId}&currentDisplayVersion=${currentDisplayVersion}`;
                    const method = apiMethods.GET;
                    const firmwareHeaders = {
                        ...baseGalenHeaders,
                        'authorization': response?.token
                    };
                    try {
                        const { data } = await fetchData(path, method, null, null, firmwareHeaders);
                        if (data.firmwareId) {
                            try {
                                dispatch(setNextFirmware(data));
                                dispatch(stepperRank(0.4));
                            } catch (err) {
                                console.log('get_new_firmware_ERROR', err)
                            }
                        } else {
                            startWork()
                        }
                    } catch (error) {
                        console.log('next_firmware_ERROR', error)
                        startWork()
                    }
                }
                try {
                    const loadDataPath = `${galenBasePath}/data/devicedata-advanced?pageNumber=0&pageSize=1&sortBy=maxValueProvidedOn&sortOrder=DESC`;
                    const loadDataMethod = apiMethods.POST;
                    const loadDataBody = {
                        deviceDataModelId: 'fd088a7a-4427-402e-a81f-0f297432a676',
                        deviceCriteria: [
                            {
                                key: 'Side',
                                operator: 'IsSet',
                            },
                            {
                                key: 'Placement',
                                operator: 'IsSet',
                            },
                            {
                                key: 'Orientation',
                                operator: 'IsSet',
                            },
                            {
                                key: 'LeftClickValue',
                                operator: 'IsSet',
                            },
                            {
                                key: 'RightClickValue',
                                operator: 'IsSet',
                            },
                            {
                                key: 'SlideRightValue',
                                operator: 'IsSet',
                            },
                            {
                                key: 'SlideLeftValue',
                                operator: 'IsSet',
                            },
                            {
                                key: 'SlideUpValue',
                                operator: 'IsSet',
                            },
                            {
                                key: 'SlideDownValue',
                                operator: 'IsSet',
                            },
                        ],
                    };
                    const loadDataHeaders = {
                        ...baseGalenHeaders,
                        'authorization': response?.token
                    };
                    const { data: userLoadData } = await fetchData(
                        loadDataPath,
                        loadDataMethod,
                        loadDataBody,
                        null,
                        loadDataHeaders
                    );
                    let isAllItemsInLoadData = true;
                    let data = null;
                    const checkArray = [...stepsToDevice, 'ThresholdValue'];
                    if (userLoadData?.content?.length > 0) {
                        data = userLoadData?.content[userLoadData?.content?.length - 1]?.data;
                    }
                    if (data && !isEmpty(data)) {
                        if (checkArray.some(one => !data[one] || isEmpty(data[one]))) {
                            isAllItemsInLoadData = false;
                        }
                    }
                    if (userLoadData?.totalElements > 0 && userLoadData?.size > 0 && data && isAllItemsInLoadData) {
                        dispatch(setUserLoadData(userLoadData))
                    }
                } catch (error) {
                    console.log('load_previous_data_check_ERROR', error)
                }
            } 
        } catch (error) {
            console.log('login_onSubmit_ERROR', error);
            dispatch(
                setNotify({
                    message: error,
                    type: notifyTypes.ERROR,
                    duration: 3000
                })
            );
        }
        dispatch(setIsLoading(false));
    }

    return (
        <div className="homeScreenPopoup" style={{ padding: '2rem 2rem 0 2rem' }}>
            <div className="popUp-content">
                <div
                    className="popUp-body"
                    style={{ alignItems: 'flex-start', padding: '1rem 2rem 0 2rem' }}
                >
                    <div className="account-title" style={{ marginBottom: '45px' }}>
                        <h3>Log In</h3>
                    </div>
                    <div className="account-control">
                        <TextField
                            label="Email"
                            variant="standard"
                            name="emailAddress"
                            type="email"
                            placeholder="Email"
                            value={emailAddress}
                            onKeyUp={handleCapsLock}
                            onChange={handleChange}
                            fullWidth
                        />
                    </div>
                    <div className="account-control">
                        <TextField
                            label="Password"
                            variant="standard"
                            name="password"
                            placeholder="Password"
                            type={isPasswordShow ? 'text' : 'password'}
                            value={password}
                            onKeyUp={handleCapsLock}
                            onChange={handleChange}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            // onMouseLeave={handleMouseDownPassword}
                                        >
                                            {isPasswordShow ? (
                                                <Visibility style={{ outline: 'none' }} />
                                            ) : (
                                                <VisibilityOff style={{ outline: 'none' }} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                // startAdornment: (
                                //     <>
                                //         {
                                //             isCapsShow ? (
                                //                 <InputAdornment position="start">
                                //                 <IconButton>
                                //                     <img src={error_icon} alt='IconButton'/>
                                //                 </IconButton>
                                //             </InputAdornment>
                                //             ) : null
                                //         }
                                //     </>
                                // )
                            }}
                        />
                    </div>
                    {error ? (
                        <div className="account-error">
                            <img src={error_icon} style={{ margin: '0 1rem' }} alt="error_icon" />
                            <p style={{ color: '#E11D48', fontWeight: '500', marginTop: '0.7rem' }}>
                                {error?.length ? error : 'Incorrect email or password! Try again.'}
                            </p>
                        </div>
                    ) : (
                        <div style={{ height: '55px' }}></div>
                    )}
                    <div className="forgot-password">
                        {/* <input
                            type="checkbox"
                            name="keepLoggedIn"
                            value={keepLoggedIn}
                            onChange={handleChange}
                        />
                        Keep me logged in */}
                        <span
                            title="Make sure Email is not empty"
                            disabled={!emailAddress}
                            onClick={
                                emailAddress
                                    ? onForgotPasswordClick
                                    : () =>
                                          dispatch(
                                              setNotify({
                                                  message: 'Make sure Email is not empty',
                                                  type: notifyTypes.WARNING,
                                                  duration: 3000,
                                              })
                                          )
                            }
                            style={{
                                color: emailAddress ? '#3840D7' : 'grey',
                                cursor: emailAddress ? 'pointer' : 'default',
                                marginLeft: '390px',
                            }}
                        >
                            Forgot Password?
                        </span>
                    </div>
                    <button
                        onClick={onSubmit}
                        className="button-blue"
                        disabled={isLoading}
                        style={{ marginTop: '20px', width: '100%' }}
                    >
                        Log In
                    </button>
                    <div className="have-account">
                        <p style={{ margin: '10px 0 20px 0' }}>
                            Don’t have an account?{' '}
                            <span
                                onClick={onSignUpClick}
                                style={{ color: '#3840D7', cursor: 'pointer' }}
                            >
                                Sign Up
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            {isLoading && <Loading isInBlock />}
        </div>
    );
}

export default LoginModal;