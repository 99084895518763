import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectLoading, setIsLoading, stepperRank, vibrationPopUp } from '../../reducer/onBoardSlice';
import { useDispatch } from 'react-redux';
import { baseGalenHeaders, galenBasePath } from '../../api/helper';
import { fetchData } from '../../api/fetchData';
import { setNotify } from '../../reducer/notifySlice';
import { notifyTypes } from '../../utils/constants';
import { selectToken, selectNextFirmware, selectUserLoadData } from '../../reducer/userSlice';
import Loading from '../../shared/Loading-screen/Loading';

const getNewFirmware = async (data, firmwareHeaders) => {
    const newFirmwarePath = `${galenBasePath}/user/firmware-media/${data.firmwareId}`;
    const response = await fetchData(newFirmwarePath, "GET", null, null, firmwareHeaders, true);
    const type = response.headers['content-type']
    const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = data.fileName
    link.click()
}

const FirmwareUpdateModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const nextFirmware = useSelector(selectNextFirmware);
    const token = useSelector(selectToken);
    const isLoading = useSelector(selectLoading);
    const userLoadData = useSelector(selectUserLoadData);

    const loadDataStep = () => {
        dispatch(stepperRank(0.6))
    }

    const handleCloseClick = () => {
        if (userLoadData?.totalElements > 0) {
            loadDataStep();
        } else {
            dispatch(vibrationPopUp(false));
            dispatch(stepperRank(0))
            navigate("/onboarding")
            dispatch(setNotify({message: 'Logged in successfully!', type: notifyTypes.SUCCESS, duration: 3000}))
        }
    }
    const handleOkClick = async () => {
        const firmwareHeaders = {
            ...baseGalenHeaders,
            'authorization': `Bearer ${token}`
        };
        getNewFirmware(nextFirmware, firmwareHeaders)
        dispatch(setNotify({message: 'Download of the new firmware version has started !', type: notifyTypes.SUCCESS, duration: 3000}))
        handleCloseClick()
    }

    return (
        <div
            className="homeScreenPopoup"
            style={{ padding: '2rem 2rem 0 2rem', borderRadius: '0', position: 'relative' }}
        >
            <div className="popUp-panel-factory" style={{ backgroundColor: '#EDEDED' }}>
                <p style={{ color: '#142194' }}>Firmware Update</p>
            </div>
            <div className="popUp-content">
                <div className="popUp-body">
                    <div className="factory-text" style={{ padding: '60px 70px 20px' }}>
                        <p>
                            A new firmware update is now available for your device. Would you like
                            to download it?
                        </p>
                    </div>
                    <div className="footnote-wrap">
                        <span className="footnote">
                            {' '}
                            * To install this update please extract the downloaded file and follow
                            the provided instructions.
                        </span>
                    </div>
                    <div className="blue-red-buttons" style={{ marginBottom: '20px' }}>
                        <button
                            onClick={handleOkClick}
                            disabled={isLoading}
                            className="button-blue"
                        >
                            Yes
                        </button>
                        <button
                            onClick={handleCloseClick}
                            disabled={isLoading}
                            className="button-blue"
                        >
                            No
                        </button>
                    </div>
                </div>
            </div>
            {isLoading && <Loading isInBlock />}
        </div>
    );
}

export default FirmwareUpdateModal;