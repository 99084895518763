import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CustomizedSnackBar from '../../components/notifications/CustomizedSnackBar';

// HEADER IMPORT
import Header from '../../shared/header/Header';

// FOOTER IMPORT
/* import Footer from '../../shared/footer/Footer'; */

// LOADING IMPORT
import Loading from '../../shared/Loading-screen/Loading';

// PRIVATE ROUTES
/* import { PrivateRoutes } from '../private-routes/PrivateRoutes'; */
/* const PrivateRoutes = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import("../private-routes/PrivateRoutes")), 2000);
    });
}); */

// ALL OTHER VIEWS WITHOUT PRIVATE ROUTES
const HomePage = lazy(() => import('../../pages/home/Home')); // Home
const NotFoundPage = lazy(() =>
  import('../../pages/notFoundPage/NotFoundPage')
); // Not Found Page
const OnBoardingPage = lazy(() => import('../../pages/onBoarding/OnBoarding')); // Home
// const LoginPage = lazy(() => import("../../pages/login/Login"));

/*
// ALL PRIVATE ROUTES
const DashBoardPage = lazy(() => import('../../views/DashBoard/DashBoard')); // DashBoard
*/
function router() {
  return (
    <BrowserRouter>
      <Header style={{ minWidth: '1300px' }} />
      <CustomizedSnackBar />

        <main>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/onboarding" element={<OnBoardingPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </main>

      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default router;
