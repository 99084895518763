import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectLoading, stepperRank, vibrationPopUp, setFactoryPopUp, setDefaultDataAfterLoad, setIsLoading } from '../../reducer/onBoardSlice';
import { useDispatch } from 'react-redux';
import BluetoothContext from '../../terminal/BluetoothContext';
import Loading from '../../shared/Loading-screen/Loading';

const FactoryResetModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(selectLoading);
    const server = useContext(BluetoothContext);

    const handleClose = () => {
        dispatch(setFactoryPopUp(false));
        dispatch(vibrationPopUp(false));
    }
    const handleOkClick = async () => {
        dispatch(setIsLoading(true))
        await server.sendCommand('AT#FACTORYRESET', 5000);
        handleClose()
        dispatch(setDefaultDataAfterLoad())
        dispatch(stepperRank(0))
        dispatch(setIsLoading(false))
        navigate('/')
    }

    return (
        <div className="homeScreenPopoup" style={{padding: '2rem 2rem 0 2rem', borderRadius: '0', position: 'relative'}}>
            <div className="popUp-panel-factory">
                <p>Factory Reset</p>
            </div>
            <div className="popUp-content">
                <div className="popUp-body">
                    <div className="factory-text" style={{margin: '60px 70px 30px'}}>
                        <p>Delete all user settings, calibration files, and restore the factory defaults?</p>
                    </div>
                    <div className="blue-red-buttons" style={{marginBottom: '30px'}}>
                        <button onClick={handleOkClick} disabled={isLoading} className='button-red'>Yes</button>
                        <button onClick={handleClose} disabled={isLoading} className='button-red'>No</button>
                    </div>                                      
                </div>
            </div>
            {isLoading && <Loading isInBlock/>}
        </div>
    )
}

export default FactoryResetModal;