import React, { useState } from 'react';
import error_icon from '../../images/error_icon.png';
import {
  selectLoading,
  setIsLoading,
  stepperRank,
} from '../../reducer/onBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { apiMethods, baseGalenHeaders, galenBasePath } from '../../api/helper';
import { fetchData } from '../../api/fetchData';
import { setNotify } from '../../reducer/notifySlice';
import { notifyTypes } from '../../utils/constants';
import { vibrationPopUp } from '../../reducer/onBoardSlice';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const location = document.location;

const createAccountErrors = [
  'InvalidArgumentException: password-not-enough-uppercase-letters:1',
  'InvalidArgumentException: password-not-enough-lowercase-letters:1',
  'InvalidArgumentException: password-not-enough-numbers:1',
  'InvalidArgumentException: password-not-enough-special-characters:1',
  'password-not-long-enough',
];

const initState = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  password: '',
};

const CreateAccountModal = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const [isPasswordError, setIsPasswordError] = useState(false);

  const [isPasswordShow, setShowPassword] = useState(false);
  const [isCapsShow, setShowCaps] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!isPasswordShow);
  // const handleMouseDownPassword = () => setShowPassword(!isPasswordShow);

  const [state, setState] = useState(initState);
  const [error, setError] = useState(false);
  const { firstName, lastName, emailAddress, password } = state;

  const handleCapsLock = (event) => {
    if (!event.getModifierState('CapsLock')) {
      if (isCapsShow) {
        setShowCaps(false);
        setError('');
      }
      return;
    } else {
      setShowCaps(true);
      setError('Caps Lock is on');
    }
  };

  const handleChange = (event) => {
    if (isLoading) {
      return;
    }
    const name = event.target.name;
    let value = event.target.value;
    setState((prev) => ({ ...prev, [name]: value }));
    if (error) {
      setError(false);
    }
  };

  const onSubmit = async () => {
    const passwordRegex = new RegExp(
      /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,})/
    );

    if (!passwordRegex.test(password)) {
      setIsPasswordError(true);
      return;
    } else {
      setIsPasswordError(false);
    }
    if (
      !emailAddress.includes('@') ||
      emailAddress.length < 4 ||
      firstName.length < 3 ||
      lastName.length < 3
    ) {
      setError('Too short email, first or last name field! Try again.');
      return;
    }
    dispatch(setIsLoading(true));

    const body = {
      user: {
        tenant: {
          tenantId: '6DEGREES-DEV',
        },
        firstName,
        lastName,
        emailAddress,
        roles: [
          {
            role: 'Patient',
          },
        ],
      },
      password,
      confirmPassword: password,
      status: 'Active',
      loggableAccount: true,
    };
    const path = `${galenBasePath}/user/register`;
    const method = apiMethods.POST;
    const headers = {
      ...baseGalenHeaders,
      'Content-Type': 'application/json',
      'X-APP-TYPE': 'DEVICE',
    };
    try {
      await fetchData(path, method, body, {}, headers);
      setTimeout(() => {
          console.log('terminate request detected');
          window.location.href = 'https://www.6degrees.tech'
      }, 5000);
      dispatch(
        setNotify({
          message: `Please check ${emailAddress} email to activate your account, this window will now auto close`,
          type: notifyTypes.INFO,
          duration: 5000,
        })
      );
      dispatch(vibrationPopUp(false));
    } catch (error) {
      console.log('CreateAccount_onSubmit_ERROR', error);
      dispatch(
        setNotify({ message: error, type: notifyTypes.ERROR, duration: 3000 })
      );
      // setError(error?.replace('InvalidArgumentException: ', ''))
    }
    dispatch(setIsLoading(false));
  };

  const onLoginClick = () => dispatch(stepperRank(0.1));
  const handleFocus = (e, key) => setFocus(key);

  return (
    <div className="homeScreenPopoup" style={{ padding: '0 2rem 0 2rem' }}>
      <div className="popUp-content">
        <div className="popUp-body" style={{ alignItems: 'flex-start' }}>
          <div className="account-title">
            <h3>Create Account</h3>
          </div>
          <div className="account-control">
            <TextField
              label="First Name"
              variant="standard"
              name="firstName"
              type="text"
              placeholder="First Name"
              value={firstName}
              onKeyUp={handleCapsLock}
              onChange={handleChange}
            />
          </div>
          <div className="account-control">
            <TextField
              label="Last Name"
              variant="standard"
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={lastName}
              onKeyUp={handleCapsLock}
              onChange={handleChange}
            />
          </div>
          <div className="account-control">
            <TextField
              label="Email"
              variant="standard"
              type="email"
              name="emailAddress"
              placeholder="Email"
              value={emailAddress}
              onKeyUp={handleCapsLock}
              onChange={handleChange}
            />
          </div>
          <div className="account-control">
            <TextField
              label="Password"
              variant="standard"
              type={isPasswordShow ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              value={password}
              onChange={handleChange}
              onKeyUp={handleCapsLock}
              // onFocus={(e) => handleFocus(e, true)}
              // onBlur={(e) => handleFocus(e, false)}
              error={isPasswordError}
              helperText={
                'Must be 8 or more characters and contain at least 1 number, 1 lower case letter, 1 upper case letter and 1 special character.'
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseLeave={handleMouseDownPassword}
                    >
                      {isPasswordShow ? (
                        <Visibility style={{ outline: 'none' }} />
                      ) : (
                        <VisibilityOff style={{ outline: 'none' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                // startAdornment: (
                //     <>
                //         {
                //             isCapsShow ? (
                //                 <InputAdornment position="start">
                //                 <IconButton>
                //                     <img src={error_icon} alt='IconButton'/>
                //                 </IconButton>
                //             </InputAdornment>
                //             ) : null
                //         }
                //     </>
                // )
              }}
            />
          </div>
          {!isFocus && <div style={{ height: '43px' }}></div>}
          {error ? (
            <div className="account-error">
              <img
                src={error_icon}
                style={{ margin: '0 1rem' }}
                alt="error_icon"
              />
              <p
                style={{
                  color: '#E11D48',
                  fontWeight: '500',
                  marginTop: '0.7rem',
                }}
              >
                {error?.length
                  ? error
                  : 'Incorrect email or password or name! Try again.'}
              </p>
            </div>
          ) : (
            <div style={{ height: '55px' }}></div>
          )}
          <span
            onClick={onSubmit}
            className="button-blue"
            style={{ width: '100%', marginTop: error ? '10px' : '' }}
          >
            Create Account
          </span>
          <div className="have-account">
            <p>
              Already have an account?{' '}
              <span
                onClick={onLoginClick}
                style={{ color: '#3840D7', cursor: 'pointer' }}
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccountModal;
