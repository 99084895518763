/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { removeNotify, selectNotifications } from '../../reducer/notifySlice';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackBar = () => {
    const notifications = useSelector(selectNotifications);
    const dispatch = useDispatch();

    const handleClose = (event, reason, id) => {
        if (reason === 'clickaway') {
        return;
    }
    dispatch(removeNotify(id))
    };

    const notify = React.useMemo(() => {
            const {id, message, type, duration } = notifications[0] || {}
            return (
                <Snackbar
                open={notifications?.length && id ? true : false}
                autoHideDuration={duration}
                onClose={(e,r) => handleClose(e,r,id)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                key={id}
                    style={{
                        borderRadius: '2rem',
                        overflow: 'hidden',
                        minWidth: '20rem'
                    }}
                >
                    <Alert
                        onClose={(e,r) => handleClose(e,r,id)}
                        severity={type}
                        sx={{ width: '100%' }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            )
    }, [notifications])

    return (
        <>
            {notify}
        </>
    );
}

export default CustomizedSnackBar;
