/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import place_the_device from "../../images/place_the_device.png";
import practice from "../../images/practice.png";
import pair_tooltip from "../../images/pair_tooltip.png";
import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import {
  vibrationPopUp,
  selectVibrationPopUp,
  selectInstructionsPopUp,
  stepperRank,
  instructionsPopUpState,
  checkStepperContent,
  selectStepperRank,
  selectStepperData,
  stepperData,
  selectFactoryPopUp,
  selectLoadDataPopUp,
  selectFirmwareUpdatePopUp,
  setFactoryPopUp,
  setLoadDataPopUp,
  setFirmwareUpdatePopUp,
  selectLoading,
  selectMeasurements,
} from "../../reducer/onBoardSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import "./CommonModal.css";
import BluetoothContext from "../../terminal/BluetoothContext";
import Loading from "../../shared/Loading-screen/Loading";
import LoginModal from "./LoginModal";
import CreateAccountModal from "./CreateAccountModal";
import FactoryResetModal from "./FactoryResetModal";
import LoadDataModal from "./LoadDataModal";
import FirmwareUpdateModal from "./FirmwareUpdateModal";
import EmailVerificationModal from "./EmailVerificationModal";
import PasswordResetModal from "./PasswordResetModal";
import RegisteredEmailModal from "./RegisteredEmailModal";
import {
  selectToken,
  selectUser,
  selectUserLoadData,
} from "../../reducer/userSlice";
import { setNotify } from "../../reducer/notifySlice";
import { notifyTypes } from "../../utils/constants";
import { isEmpty } from "../../utils/_helperFunction";

const Transition = React.forwardRef((props = {}, ref) => (
  <Slide container={document.body} ref={ref} {...props} />
));

const CommonModal = ({ modalType, checkEmailVerify }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const server = useContext(BluetoothContext);

  const VibrationPopUp = useSelector(selectVibrationPopUp);
  const instructionsPopUp = useSelector(selectInstructionsPopUp);
  const factoryPopUp = useSelector(selectFactoryPopUp);
  const loadDataPopUp = useSelector(selectLoadDataPopUp);
  const firmwareUpdatePopUp = useSelector(selectFirmwareUpdatePopUp);

  const [instructionsModalStep, setInstructionsModalStep] = useState(0);

  const StepperRank = useSelector(selectStepperRank);
  const StepperData = useSelector(selectStepperData);

  const [isCurrentLoading, setIsLoading] = useState(false);
  const isLoading = useSelector(selectLoading);
  const userLoadData = useSelector(selectUserLoadData);
  const measurements = useSelector(selectMeasurements);

  useEffect(() => {
    if (!instructionsPopUp && instructionsModalStep === 1) {
      setInstructionsModalStep(0);
    }
  }, [instructionsModalStep, instructionsPopUp]);

  const handleClose = (event) => {
    if (isLoading || isCurrentLoading) {
      return;
    }
    if (modalType === "firmwareUpdate") {
      if (userLoadData?.totalElements > 0) {
        dispatch(stepperRank(0.6));
      } else {
        dispatch(vibrationPopUp(false));
        dispatch(stepperRank(0));
        navigate("/onboarding");
        dispatch(
          setNotify({
            message: "Logged in successfully!",
            type: notifyTypes.SUCCESS,
            duration: 3000,
          })
        );
      }
    }
    if (modalType === "loadData") {
      dispatch(vibrationPopUp(false));
      if (StepperRank <= 0.6) {
        dispatch(stepperRank(0));
      }
      navigate("/onboarding");
    }
    // if (modalType === "homeScreen" && !server.getDevice()) {
    //     dispatch(setNotify({message: 'Bluetooth device is not available!', type: notifyTypes.ERROR, duration: 3000}))
    // }
    if (modalType === "createAccount" || modalType === "logIn") {
      dispatch(stepperRank(0));
    }

    dispatch(vibrationPopUp(false));
    dispatch(setFactoryPopUp(false));
    dispatch(setLoadDataPopUp(false));
    dispatch(setFirmwareUpdatePopUp(false));
  };

  const handleConnect = (event) => {
    if (!server.getDevice()) {
      dispatch(
        setNotify({
          message: "Bluetooth device is not available!",
          type: notifyTypes.ERROR,
          duration: 3000,
        })
      );
      // dispatch(vibrationPopUp(false))
    }
    if (server.getDevice()) {
      dispatch(
        setNotify({
          message: "Bluetooth device connected successfully!",
          type: notifyTypes.SUCCESS,
          duration: 3000,
        })
      );
    }
    if (server.getDevice() && user && token) {
      navigate("/onboarding");
    }
    if (server.getDevice() && (!user || !token)) {
      dispatch(stepperRank(0.1));
    }
    if (!user || !token) {
      dispatch(stepperRank(0.1));
    }
    if (user && token) {
      navigate("/onboarding");
    }
  };
  const onConnect = () =>
    setTimeout(() => {
      setIsLoading(false);
      handleConnect();
    }, 5000);

  const getDeviceInfo = async () => {
    if (!navigator.bluetooth) {
      dispatch(
        setNotify({
          message: "Bluetooth device is not available!",
          type: notifyTypes.ERROR,
          duration: 3000,
        })
      );
      return;
    } else {
      setIsLoading(true);
      server
        .start()
        .then(() => {
          console.log("Common modal, the app is connected!");
          onConnect();
        })
        .catch((err) => {
          console.log("currentTerminal_connect_ERROR", err.message || err);
          dispatch(
            setNotify({
              message: `Connection error ${err.message}`,
              type: notifyTypes.ERROR,
              duration: 3000,
            })
          );
          setIsLoading(false);
        });
    }
  };

  const handleClick = (e) => {
    const value = e.target.defaultValue || e.target.innerText.toLoverCase();
    if (value === "medium") {
      document.documentElement.style.setProperty("--medium-color", "#E76687");
      document.documentElement.style.setProperty("--strong-color", "#3840D7");
    } else if (value === "strong") {
      document.documentElement.style.setProperty("--strong-color", "#E76687");
      document.documentElement.style.setProperty("--medium-color", "#3840D7");
    } else if (value === "off") {
      document.documentElement.style.setProperty("--strong-color", "#3840D7");
      document.documentElement.style.setProperty("--medium-color", "#3840D7");
    }
    const data = {
      ...StepperData,
      step1: { ...StepperData.step1, vibration: value },
    };
    dispatch(stepperData(data));
  };

  useEffect(() => {
    const temp = { target: { defaultValue: "strong" } };
    temp["target"]["defaultValue"] = StepperData.step1?.vibration || "strong";
    handleClick(temp);
  }, []);

  const StyledFormControlLabel = styled((props = {}) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": checked
      ? {
          color: "#E86987",
          fontSize: 18,
          fontWeight: 600,
        }
      : {
          color: "#141E92",
          fontSize: 18,
          fontWeight: 600,
        },
  }));

  function MyFormControlLabel(props = {}) {
    const radioGroup = useRadioGroup();
    let { checked = false } = props;

    if (radioGroup) {
      checked = checked || radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }
  return (
    <Dialog
      onClose={handleClose}
      ariaLabel="VibrationControl"
      TransitionComponent={Transition}
      keepMounted
      scroll="body"
      open={
        VibrationPopUp ||
        factoryPopUp ||
        loadDataPopUp ||
        firmwareUpdatePopUp ||
        instructionsPopUp
      }
      className="VibrationControl"
    >
      {modalType === "step1Part" && (
        <div className="VibrationControl_container">
          <div className="vibration-text">
            <p>
              1. Please make sure to place the device with the “
              <span style={{ color: "#E86987" }}>On/Off</span>” button facing up
              (leg or arm) or towards your body.
            </p>
          </div>
          <div className="place-image">
            <img src={place_the_device} alt="place_the_device" />
          </div>
          <div className="vibration-text">
            <p>
              2. You can easily adjust{" "}
              <span style={{ color: "#E86987" }}>vibration intensity </span>or
              switch it off.
            </p>
          </div>
          <div className="select">
            <RadioGroup
              ariaLabel="demo-radio-buttons-group-label"
              defaultValue="Subtle"
              name="radio-buttons-group"
            >
              <MyFormControlLabel
                labelPlacement="start"
                onClick={handleClick}
                checked={StepperData.step1?.vibration === "off"}
                value="off"
                label="Off"
                control={
                  <Radio
                    style={{ backgroundColor: "transparent" }}
                    sx={{
                      color: "#3840D7",
                      "&.Mui-checked": {
                        color: "#E76687",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                    }}
                  />
                }
              />
              <MyFormControlLabel
                labelPlacement="start"
                onClick={handleClick}
                checked={StepperData.step1?.vibration === "medium"}
                value="medium"
                label="Medium"
                control={
                  <Radio
                    style={{ backgroundColor: "transparent" }}
                    sx={{
                      color: "#3840D7",
                      "&.Mui-checked": {
                        color: "#E76687",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                    }}
                  />
                }
              />
              <div className="vibration-level-medium">
                <div className="medium1"></div>
                <div className="medium2"></div>
              </div>
              <MyFormControlLabel
                labelPlacement="start"
                onClick={handleClick}
                checked={StepperData.step1?.vibration === "strong"}
                value="strong"
                label="Strong"
                control={
                  <Radio
                    style={{ backgroundColor: "transparent" }}
                    sx={{
                      color: "#3840D7",
                      "&.Mui-checked": {
                        color: "#E76687",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                    }}
                  />
                }
              />
              <div className="vibration-level-strong">
                <div className="strong1"></div>
                <div className="strong2"></div>
                <div className="strong3"></div>
              </div>
            </RadioGroup>
          </div>
          <div className="button_On">
            <Button
              onClick={() => {
                dispatch(vibrationPopUp(false));
                const rank = StepperRank + StepperData?.step1?.wrist ? 1 : 1.5;
                dispatch(stepperRank(rank));
                const checkData = { ...StepperData["step2"], ok: null };
                let valueTrueExist = Object.values(checkData);
                const isAllow = measurements?.find((item) => item["1.5"]);
                dispatch(
                  checkStepperContent(
                    (valueTrueExist[0] ||
                      valueTrueExist[1] ||
                      valueTrueExist[2] ||
                      isAllow) &&
                      StepperData["step1"].wrist
                      ? true
                      : isAllow
                      ? true
                      : false
                  )
                );
              }}
            >
              OK
            </Button>
          </div>
        </div>
      )}

      {modalType === "instructions" && (
        <div className="homeScreenPopoup">
          <div className="popUp-panel">
            <p className="popUp-panel-text">Pay Attention</p>
          </div>
          <div className="popUp-content">
            <div className="popUp-body">
              <p className="instructions-text">
                {instructionsModalStep === 0
                  ? `We will now go through the command calibration. Repeat each
                motion 10 times, returning to your neutral position and resting
                after each motion. Follow instructions for when to move and
                rest.`
                  : `Movement should be repetitive and executed at a quicker pace than usual, while still within your abilities.`}
              </p>

              <div
                className="blue-red-buttons"
                style={{ justifyContent: "center", marginTop: "25px" }}
              >
                <button
                  onClick={() => {
                    if (instructionsModalStep === 0) {
                      setInstructionsModalStep(1);
                    }
                    if (instructionsModalStep === 1) {
                      dispatch(instructionsPopUpState(false));
                      const isAllow =
                        !isEmpty(StepperData["step3"]) &&
                        Object.keys(StepperData["step3"])?.some(
                          (item) => StepperData["step3"][item]
                        );
                      dispatch(stepperRank(StepperRank + 0.5));
                      dispatch(checkStepperContent(isAllow ? true : false));
                      // setNextHover((nextHover) => !nextHover);
                    }
                  }}
                  className="button-blue"
                >
                  {instructionsModalStep === 0 ? `Next` : "Close"}
                </button>
              </div>
            </div>
          </div>
          {isCurrentLoading && <Loading isInBlock />}
        </div>
      )}

      {modalType === "homeScreen" && (
        <div className="homeScreenPopoup">
          <div className="popUp-panel">
            <img
              src="/assets/Images/Google_Chrome_icon.png"
              style={{ margin: "0 10px" }}
            />
            <p className="popUp-panel-text">
              Recommended using on Chrome browser
            </p>
          </div>
          <div className="popUp-content">
            <div className="popUp-body">
              <div className="list">
                <ol className="text">
                  <li>Turn on the Bluetooth connection on your computer.</li>
                  <li>Turn on your MyMove.</li>
                  <li>Click on the "Find" button below.</li>
                  <li>Select the "6Degrees" device and click "Pair".</li>
                </ol>

                <div className="animation">
                  <img
                    src="/assets/Images/bluetooth_icon.png"
                    alt="bluetooth_icon"
                    width="38"
                    height="60"
                  />
                  <div className="switch-bluetooth">
                    <p id="on-off"></p>
                    <div className="bluetooth-on">
                      <span className="switch-dot"></span>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src={pair_tooltip}
                alt="pair_tooltip"
                className="modalExampleImage"
              />
              <div
                className="blue-red-buttons"
                style={{ justifyContent: "center", marginTop: "25px" }}
              >
                {/* <button
                  onClick={handleConnect}
                  disabled={isCurrentLoading}
                  className="button-blue"
                >
                  OK
                </button> */}
                <button
                  onClick={getDeviceInfo}
                  disabled={isCurrentLoading}
                  className="button-blue"
                >
                  Find
                </button>
              </div>
            </div>
          </div>
          {isCurrentLoading && <Loading isInBlock />}
        </div>
      )}

      {modalType === "practiceMovements" && (
        <div className="homeScreenPopoup">
          <div className="popUp-content">
            <div className="popUp-body">
              <div className="practice-title">
                <h3>Want to practice your movements before you go?</h3>
              </div>
              <img
                src={practice}
                style={{ marginTop: 3 + "rem", marginBottom: 3 + "rem" }}
                altpractice
              />
              <div className="blue-red-buttons">
                <span
                  onClick={() => {
                    dispatch(vibrationPopUp(false));
                    dispatch(stepperRank(StepperRank + 0.5));
                  }}
                  className="button-blue"
                >
                  No
                </span>
                <span className="button-blue">Yes</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {modalType === "createAccount" && <CreateAccountModal />}
      {modalType === "logIn" && <LoginModal server={server} />}
      {modalType === "factoryReset" && <FactoryResetModal />}
      {modalType === "loadData" && <LoadDataModal />}
      {modalType === "firmwareUpdate" && <FirmwareUpdateModal />}
      {modalType === "emailVerification" && (
        <EmailVerificationModal checkEmailVerify={checkEmailVerify} />
      )}
      {modalType === "passwordReset" && (
        <PasswordResetModal checkEmailVerify={checkEmailVerify} />
      )}
      {modalType === "registeredEmail" && <RegisteredEmailModal />}
    </Dialog>
  );
};

export default CommonModal;
