import axios from 'axios';
import { isEmpty } from '../utils/_helperFunction';

export const fetchData = async (path, method = "GET", body, token, headers, fetchFile) => {
  
  return axios({
    url: path,
    method,
    ...(fetchFile && {responseType: 'blob'}),
    headers: headers || {
      "content-type": "application/json",
      ...(token && { authorization: `Bearer ${token}` }),
    },
    data: body,
  })
    .then(async (response) => {
          return {
              ...response,
              token: response.headers?.authorization,
          };
    })
    .catch((error) => {
      console.log('fetchData_catch_ERROR === ', error)
      let err = error.response?.data || error.message || error
      if (!err || isEmpty(err)) {
        err = "Something went wrong";
      }
      throw err;
    });
};
