export const notifyTypes = {
    'ERROR': 'error',
    'WARNING': 'warning',
    'INFO': 'info',
    'SUCCESS': 'success'
}

export const stopAndOnboard = ['S', '8', '1', '4'];
export const stopAndSetDefault = ['S', '8', '2'];

export const clickForDevice = {
    TwistIn: '1',
    TwistOut: '2',
    WhipUp: '5',
    WhipDown: '6',
    FistBumpIn: '7',
    FistBumpOut: '8',
    SwipeLeft: '9',
    SwipeRight: '9',
    ShrugUp: '3',
    ShrugDown: '4',
    FrontKick: '9',
    SideKickLeft: '9',
    SideKickRight: '9',
    KneeUP: '9'
}

export const stepsToDevice = [
    'Side',
    'Placement',
    'ApiVersion',
    'Orientation',
    'BuzzerStrength',
    'ThresholdValue',
    'LeftClickMotion',
    'LeftClickPercentage',
    'LeftClickValue',
    // 'LeftClickMaingyro',
    'RightClickMotion',
    'RightClickPercentage',
    'RightClickValue',
    // 'RightClickMaingyro',
    'SlideRightValue',
    'SlideRightPercentage',
    // 'SlideRightMaingyro',
    'SlideLeftValue',
    'SlideLeftPercentage',
    // 'SlideLeftMaingyro',
    'UpSlidePercentage',
    // 'SlideUpMaingyro',
    'SlideUpValue',
    'SlideDownValue',
    'SlideDownPercentage',
    // 'SlideDownMaingyro'
]

// export const fakeMeasurements = [
//     {
//         "1.5": {
//             "filteredStepsData": {
//                 "vibration": "off",
//                 "wrist": true,
//                 "right": true,
//                 "horizontal": true
//             },
//             "measurementResult": [],
//             "galenResponse": "624412d6-099a-4e44-8c54-f4ae38c138ba",
//             "galenAnalyze": null
//         }
//     },
//     {
//         "2.5": {
//             "filteredStepsData": {
//                 "vibration": "strong",
//                 "wrist": true,
//                 "right": true,
//                 "horizontal": true,
//                 "bump": true,
//                 "in": true,
//                 "leftClick": true
//             },
//             "measurementResult": [],
//             "galenResponse": "0e0678c8-db4b-49cb-811c-8ece32027f7c",
//             "galenAnalyze": {
//                 "Calibration": 136,
//                 "Percentage": 78
//             }
//         }
//     },
//     {
//         "2.7": {
//             "filteredStepsData": {
//                 "vibration": "strong",
//                 "wrist": true,
//                 "left": true,
//                 "vertical": true,
//                 "bump": true,
//                 "out": true,
//                 "rightClick": true
//             },
//             "measurementResult": [],
//             "galenResponse": "6547087e-054f-4a62-b2c1-2905f64e1437",
//             "galenAnalyze": {
//                 "Calibration": 140,
//                 "Percentage": 80
//             }
//         }
//     },
//     {
//         "3": {
//             "filteredStepsData": {
//                 "swipe": true,
//                 "right": true
//             },
//             "measurementResult": [],
//             "galenResponse": "20786b91-a229-4c91-8e88-2ba61e26e503",
//             "galenAnalyze": {
//                 "Calibration": 120,
//                 "Percentage": 60
//             }
//         }
//     },
//     {
//         "3.1": {
//             "filteredStepsData": {
//                 "swipe": true,
//                 "left": true
//             },
//             "measurementResult": [],
//             "galenResponse": "36a23cc2-31ed-4325-8a0b-0f3fbe9bb673",
//             "galenAnalyze": {
//                 "Calibration": 100,
//                 "Percentage": 45
//             }
//         }
//     },
//     {
//         "3.2": {
//             "filteredStepsData": {
//                 "swipe": true,
//                 "up": true
//             },
//             "measurementResult": [],
//             "galenResponse": "09b46472-ff65-44af-9ea7-8e2183024f4e",
//             "galenAnalyze": {
//                 "Calibration": 60,
//                 "Percentage": 60
//             }
//         }
//     },
//     {
//         "3.3": {
//             "filteredStepsData": {
//                 "swipe": true,
//                 "down": true
//             },
//             "measurementResult": [],
//             "galenResponse": "dd1afeeb-29b9-4e1f-b20a-a869db952e80",
//             "galenAnalyze": {
//                 "Calibration": 40,
//                 "Percentage": 80
//             }
//         }
//     }
// ]

export const fakeMeasurements = [
    {
        "1.5": {
            "filteredStepsData": {},
            "galenAnalyze": null
        }
    },
    {
        "2.5": {
            "filteredStepsData": {},
            "galenAnalyze": {}
        }
    },
    {
        "2.7": {
            "filteredStepsData": {},
            "galenAnalyze": {}
        }
    },
    {
        "3": {
            "filteredStepsData": {},
            "galenAnalyze": {}
        }
    },
    {
        "3.1": {
            "filteredStepsData": {},
            "galenAnalyze": {}
        }
    },
    {
        "3.2": {
            "filteredStepsData": {},
            "galenAnalyze": {}
        }
    },
    {
        "3.3": {
            "filteredStepsData": {},
            "galenAnalyze": {}
        }
    }
]

export const fakeStepsData = {
    "step1": {},
    "step1.5": {},
    "step2": {},
    "step2.5": {},
    "step2.7": {},
    "step3": {},
    "step3.1": {},
    "step3.2": {},
    "step3.3": {},
    "step3.5": {},
    "step4": {},
    "step4.1": {},
    "step4.2": {},
    "step4.3": {},
    "step5": {},
    "step5.5": {},
    "step6": {},
    "step7": {}
}

export const testDataToAnalyze = [
    "#0000006374 NoMotion-1. Scaled. Acc [ -00050.29, -00005.37, 00999.02 ], Gyr [ 00000.12, -00002.44, -00002.50 ], Mag [ 00019.65, 00083.40, 00108.45 ]",
    "#0000006375 NoMotion-1. Scaled. Acc [ -00033.20, -00005.86, 00982.42 ], Gyr [ 00002.68, 00000.79, -00001.28 ], Mag [ 00019.65, 00083.40, 00108.45 ]",
    "#0000006376 NoMotion-1. Scaled. Acc [ -00037.60, 00000.49, 00976.07 ], Gyr [ -00000.24, 00000.73, -00000.18 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006377 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00997.07 ], Gyr [ -00000.43, 00001.40, -00002.32 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006378 NoMotion-1. Scaled. Acc [ -00047.36, -00003.91, 00987.79 ], Gyr [ 00000.49, 00001.59, 00000.12 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000006379 NoMotion-1. Scaled. Acc [ -00034.18, -00001.46, 00984.38 ], Gyr [ 00001.65, 00002.32, 00000.67 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000006380 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00988.77 ], Gyr [ -00000.06, 00003.78, -00002.07 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006381 NoMotion-1. Scaled. Acc [ -00039.06, 00004.39, 01001.95 ], Gyr [ 00000.49, 00001.16, -00002.13 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006382 NoMotion-1. Scaled. Acc [ -00052.73, -00009.28, 00992.68 ], Gyr [ 00000.37, 00000.98, -00001.10 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006383 NoMotion-1. Scaled. Acc [ -00039.55, -00012.70, 00983.40 ], Gyr [ 00001.28, 00000.37, -00002.74 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006384 NoMotion-1. Scaled. Acc [ -00050.78, -00008.30, 00990.72 ], Gyr [ 00000.43, 00001.34, -00002.38 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006385 NoMotion-1. Scaled. Acc [ -00052.25, 00008.30, 01009.28 ], Gyr [ 00001.16, -00001.71, -00002.01 ], Mag [ 00019.95, 00084.30, 00108.90 ]",
    "#0000006386 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00998.54 ], Gyr [ 00000.30, -00000.30, -00000.37 ], Mag [ 00019.95, 00084.30, 00108.90 ]",
    "#0000006387 NoMotion-1. Scaled. Acc [ -00041.50, -00017.09, 00988.28 ], Gyr [ 00001.46, 00000.91, -00000.91 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000006388 NoMotion-1. Scaled. Acc [ -00038.09, -00003.42, 00997.07 ], Gyr [ -00000.79, 00001.71, 00000.61 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000006389 NoMotion-1. Scaled. Acc [ -00030.76, -00013.67, 00990.23 ], Gyr [ 00000.61, 00000.67, -00000.73 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006390 NoMotion-1. Scaled. Acc [ -00038.57, -00003.42, 00988.77 ], Gyr [ -00000.18, 00002.50, 00000.24 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006391 NoMotion-1. Scaled. Acc [ -00037.11, -00020.02, 00992.19 ], Gyr [ 00000.12, 00001.04, -00000.91 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006392 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 00979.00 ], Gyr [ -00000.37, 00004.02, -00000.37 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006393 NoMotion-1. Scaled. Acc [ -00036.62, -00012.21, 00997.56 ], Gyr [ -00001.95, 00001.77, -00000.91 ], Mag [ 00020.40, 00084.90, 00106.20 ]",
    "#0000006394 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00996.58 ], Gyr [ 00001.04, -00000.43, -00001.89 ], Mag [ 00020.40, 00084.90, 00106.20 ]",
    "#0000006395 NoMotion-1. Scaled. Acc [ -00037.60, -00019.04, 00991.21 ], Gyr [ -00000.55, 00001.52, -00003.84 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000006396 NoMotion-1. Scaled. Acc [ -00036.62, -00011.72, 00996.58 ], Gyr [ 00000.24, 00002.93, -00002.01 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000006397 NoMotion-1. Scaled. Acc [ -00039.06, 00000.00, 00993.65 ], Gyr [ 00001.95, 00000.55, -00002.44 ], Mag [ 00020.40, 00084.90, 00108.75 ]",
    "#0000006398 NoMotion-1. Scaled. Acc [ -00038.09, -00011.72, 00984.86 ], Gyr [ 00001.52, 00000.00, -00001.65 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006399 NoMotion-1. Scaled. Acc [ -00038.09, -00016.60, 00984.38 ], Gyr [ 00000.73, 00002.68, -00003.17 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006400 NoMotion-1. Scaled. Acc [ -00041.50, -00021.97, 00990.23 ], Gyr [ -00001.28, 00000.67, 00000.37 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006401 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00981.93 ], Gyr [ 00000.18, 00004.39, -00000.98 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006402 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00995.61 ], Gyr [ 00001.04, 00001.59, -00001.89 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006403 NoMotion-1. Scaled. Acc [ -00039.55, 00000.49, 00991.21 ], Gyr [ 00001.04, -00000.85, 00000.79 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006404 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00989.26 ], Gyr [ -00000.67, 00001.16, -00002.07 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006405 NoMotion-1. Scaled. Acc [ -00036.13, 00000.98, 00981.45 ], Gyr [ 00000.18, 00000.67, -00001.71 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006406 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00989.26 ], Gyr [ -00001.34, 00003.11, -00003.11 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006407 NoMotion-1. Scaled. Acc [ -00036.62, -00003.91, 00984.38 ], Gyr [ 00002.68, -00000.24, -00001.34 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006408 NoMotion-1. Scaled. Acc [ -00026.37, -00011.72, 00993.16 ], Gyr [ 00001.40, 00002.50, -00003.60 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006409 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00986.82 ], Gyr [ 00000.49, 00000.98, -00000.73 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006410 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 01000.49 ], Gyr [ 00001.52, 00000.43, 00000.18 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006411 NoMotion-1. Scaled. Acc [ -00037.11, -00002.93, 00998.05 ], Gyr [ 00000.85, -00000.24, -00001.34 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006412 NoMotion-1. Scaled. Acc [ -00042.48, -00006.84, 00989.26 ], Gyr [ 00003.35, 00002.26, -00004.39 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006413 NoMotion-1. Scaled. Acc [ -00038.09, -00009.28, 00982.42 ], Gyr [ 00001.77, 00000.30, -00001.22 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006414 NoMotion-1. Scaled. Acc [ -00030.76, -00002.93, 00996.58 ], Gyr [ 00000.37, 00000.49, -00001.40 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006415 NoMotion-1. Scaled. Acc [ -00038.09, -00024.90, 00986.82 ], Gyr [ 00002.01, 00000.00, -00000.79 ], Mag [ 00018.60, 00085.20, 00109.05 ]",
    "#0000006416 NoMotion-1. Scaled. Acc [ -00038.57, -00012.21, 01000.00 ], Gyr [ 00001.10, 00000.85, -00002.01 ], Mag [ 00018.60, 00085.20, 00109.05 ]",
    "#0000006417 NoMotion-1. Scaled. Acc [ -00036.13, -00014.16, 00985.35 ], Gyr [ 00001.71, 00003.78, -00002.13 ], Mag [ 00018.60, 00083.40, 00108.45 ]",
    "#0000006418 NoMotion-1. Scaled. Acc [ -00026.37, 00000.00, 00979.49 ], Gyr [ 00001.28, -00001.40, -00000.85 ], Mag [ 00018.60, 00083.40, 00108.45 ]",
    "#0000006419 NoMotion-1. Scaled. Acc [ -00039.06, -00011.23, 00992.68 ], Gyr [ 00001.10, 00000.49, -00002.07 ], Mag [ 00019.05, 00082.95, 00108.45 ]",
    "#0000006420 NoMotion-1. Scaled. Acc [ -00039.55, -00004.39, 00994.14 ], Gyr [ 00000.30, 00000.79, -00002.74 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006421 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00997.07 ], Gyr [ -00001.28, 00003.48, -00002.50 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006422 NoMotion-1. Scaled. Acc [ -00034.18, -00019.53, 00996.58 ], Gyr [ 00002.26, -00000.37, -00001.59 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006423 NoMotion-1. Scaled. Acc [ -00027.83, 00000.49, 00979.98 ], Gyr [ 00002.26, -00000.55, -00000.67 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000006424 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00991.21 ], Gyr [ 00002.50, 00001.52, -00000.30 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000006425 NoMotion-1. Scaled. Acc [ -00055.18, -00014.16, 01007.32 ], Gyr [ 00001.71, 00001.04, -00001.16 ], Mag [ 00019.95, 00084.75, 00106.50 ]",
    "#0000006426 NoMotion-1. Scaled. Acc [ -00050.29, -00010.25, 00988.28 ], Gyr [ 00001.71, 00000.18, -00000.43 ], Mag [ 00019.95, 00084.75, 00106.50 ]",
    "#0000006427 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00988.28 ], Gyr [ 00002.07, 00000.67, 00000.18 ], Mag [ 00020.70, 00082.05, 00109.65 ]",
    "#0000006428 NoMotion-1. Scaled. Acc [ -00044.43, -00010.74, 00990.72 ], Gyr [ -00000.85, 00000.43, -00000.91 ], Mag [ 00020.70, 00082.05, 00109.65 ]",
    "#0000006429 NoMotion-1. Scaled. Acc [ -00045.41, -00011.72, 00992.68 ], Gyr [ 00001.28, 00003.72, -00003.48 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006430 NoMotion-1. Scaled. Acc [ -00046.88, -00010.25, 00992.19 ], Gyr [ -00000.91, 00002.74, -00001.83 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006431 NoMotion-1. Scaled. Acc [ -00049.32, -00001.46, 00992.68 ], Gyr [ 00001.52, 00002.80, -00001.46 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006432 NoMotion-1. Scaled. Acc [ -00053.71, -00005.86, 00984.86 ], Gyr [ 00001.83, 00002.38, -00002.74 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006433 NoMotion-1. Scaled. Acc [ -00045.41, -00013.18, 00989.75 ], Gyr [ 00001.89, 00002.56, -00003.11 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006434 NoMotion-1. Scaled. Acc [ -00045.41, -00005.86, 00995.61 ], Gyr [ 00000.30, -00002.68, 00000.67 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006435 NoMotion-1. Scaled. Acc [ -00047.85, -00003.91, 00995.12 ], Gyr [ 00002.20, -00000.37, -00001.34 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000006436 NoMotion-1. Scaled. Acc [ -00048.83, -00014.16, 00979.49 ], Gyr [ 00000.98, 00001.10, -00002.26 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000006437 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 01006.84 ], Gyr [ 00001.83, 00001.59, -00002.93 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000006438 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00979.49 ], Gyr [ 00001.77, 00000.91, 00001.65 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000006439 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00988.77 ], Gyr [ 00000.18, 00000.79, 00001.28 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006440 NoMotion-1. Scaled. Acc [ -00048.83, -00009.28, 00987.30 ], Gyr [ 00001.46, -00000.55, 00000.24 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006441 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00994.63 ], Gyr [ -00000.67, 00001.10, -00002.62 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006442 NoMotion-1. Scaled. Acc [ -00038.57, -00012.21, 00998.05 ], Gyr [ -00001.28, 00001.04, -00002.74 ], Mag [ 00020.10, 00084.90, 00108.00 ]",
    "#0000006443 NoMotion-1. Scaled. Acc [ -00029.79, -00009.28, 00986.82 ], Gyr [ -00000.30, 00003.23, -00002.68 ], Mag [ 00020.10, 00084.90, 00108.00 ]",
    "#0000006444 NoMotion-1. Scaled. Acc [ -00043.46, -00010.25, 00986.82 ], Gyr [ 00001.83, -00002.07, -00001.95 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006445 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00992.68 ], Gyr [ 00000.61, 00001.16, -00001.59 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006446 NoMotion-1. Scaled. Acc [ -00048.83, -00002.93, 01004.39 ], Gyr [ 00002.62, -00000.85, 00001.34 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006447 NoMotion-1. Scaled. Acc [ -00044.92, -00011.72, 01003.42 ], Gyr [ 00000.91, 00000.00, -00002.93 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006448 NoMotion-1. Scaled. Acc [ -00046.39, -00010.74, 00989.26 ], Gyr [ 00002.01, 00001.40, -00002.13 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000006449 NoMotion-1. Scaled. Acc [ -00041.99, 00001.46, 00988.77 ], Gyr [ 00000.91, -00000.37, -00001.83 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000006450 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00995.12 ], Gyr [ 00002.07, 00001.28, -00002.38 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000006451 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00989.26 ], Gyr [ 00000.55, 00002.62, -00001.71 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000006452 NoMotion-1. Scaled. Acc [ -00043.95, 00001.46, 00983.40 ], Gyr [ -00000.91, 00001.83, -00002.68 ], Mag [ 00020.85, 00084.45, 00108.75 ]",
    "#0000006453 NoMotion-1. Scaled. Acc [ -00043.46, -00004.39, 01000.98 ], Gyr [ 00002.50, 00001.46, -00002.44 ], Mag [ 00020.85, 00084.45, 00108.75 ]",
    "#0000006454 NoMotion-1. Scaled. Acc [ -00042.48, 00000.98, 00997.56 ], Gyr [ 00000.24, 00002.13, -00004.09 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006455 NoMotion-1. Scaled. Acc [ -00051.76, -00007.81, 00979.00 ], Gyr [ 00000.12, 00003.90, -00004.57 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006456 NoMotion-1. Scaled. Acc [ -00048.83, -00020.51, 00999.51 ], Gyr [ 00001.95, 00002.13, -00002.20 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006457 NoMotion-1. Scaled. Acc [ -00039.06, -00007.81, 00987.30 ], Gyr [ 00002.44, -00001.46, -00002.74 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006458 NoMotion-1. Scaled. Acc [ -00034.18, -00015.62, 00999.02 ], Gyr [ 00000.24, 00001.04, -00003.23 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006459 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00983.89 ], Gyr [ -00001.16, 00003.84, 00000.49 ], Mag [ 00018.75, 00083.55, 00107.10 ]",
    "#0000006460 NoMotion-1. Scaled. Acc [ -00055.66, -00011.23, 00994.63 ], Gyr [ 00002.01, 00001.83, -00000.91 ], Mag [ 00018.75, 00083.55, 00107.10 ]",
    "#0000006461 NoMotion-1. Scaled. Acc [ -00047.36, -00001.95, 00996.09 ], Gyr [ 00002.26, -00001.65, -00000.18 ], Mag [ 00019.05, 00084.15, 00105.90 ]",
    "#0000006462 NoMotion-1. Scaled. Acc [ -00054.69, -00005.86, 00998.54 ], Gyr [ 00002.26, 00001.77, 00000.24 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006463 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00992.19 ], Gyr [ 00001.22, 00002.13, -00001.04 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006464 NoMotion-1. Scaled. Acc [ -00041.50, -00013.18, 00988.77 ], Gyr [ 00000.06, 00002.74, -00003.72 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006465 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 01007.32 ], Gyr [ -00000.73, -00000.37, -00002.01 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006466 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00989.75 ], Gyr [ 00000.61, 00003.23, -00002.80 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006467 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00989.26 ], Gyr [ -00000.18, 00001.34, -00000.85 ], Mag [ 00021.15, 00084.45, 00106.20 ]",
    "#0000006468 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00999.51 ], Gyr [ 00001.46, 00002.38, -00000.49 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006469 NoMotion-1. Scaled. Acc [ -00033.69, -00006.35, 00988.77 ], Gyr [ 00002.01, 00000.49, -00001.16 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006470 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 01000.00 ], Gyr [ 00000.73, 00002.38, -00002.44 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006471 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 00994.63 ], Gyr [ 00000.79, 00000.85, -00003.72 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006472 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00988.77 ], Gyr [ 00000.73, 00000.49, 00000.61 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006473 NoMotion-1. Scaled. Acc [ -00039.06, -00007.81, 00980.47 ], Gyr [ 00001.34, 00003.96, -00002.20 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006474 NoMotion-1. Scaled. Acc [ -00043.95, 00004.39, 00998.54 ], Gyr [ -00000.37, 00001.59, -00000.30 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006475 NoMotion-1. Scaled. Acc [ -00038.57, -00010.74, 00994.14 ], Gyr [ -00001.10, -00000.30, -00003.05 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000006476 NoMotion-1. Scaled. Acc [ -00057.13, -00013.18, 01004.88 ], Gyr [ -00000.30, -00000.12, -00002.62 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000006477 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00998.54 ], Gyr [ -00001.04, 00002.07, -00001.10 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000006478 NoMotion-1. Scaled. Acc [ -00053.22, -00009.28, 00992.19 ], Gyr [ -00001.71, 00000.67, -00003.90 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000006479 NoMotion-1. Scaled. Acc [ -00040.53, 00003.42, 00995.61 ], Gyr [ 00000.91, 00003.48, -00001.77 ], Mag [ 00018.45, 00084.75, 00107.85 ]",
    "#0000006480 NoMotion-1. Scaled. Acc [ -00035.64, -00003.42, 00998.05 ], Gyr [ -00001.59, 00001.59, -00002.07 ], Mag [ 00018.45, 00084.75, 00107.85 ]",
    "#0000006481 NoMotion-1. Scaled. Acc [ -00047.85, -00023.93, 00991.21 ], Gyr [ 00000.55, 00002.26, -00000.73 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006482 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00996.09 ], Gyr [ -00000.55, 00001.83, -00000.18 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006483 NoMotion-1. Scaled. Acc [ -00048.34, -00001.46, 00984.38 ], Gyr [ -00001.22, 00003.35, -00002.68 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006484 NoMotion-1. Scaled. Acc [ -00039.55, -00015.62, 00994.63 ], Gyr [ 00001.10, -00000.37, -00001.77 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006485 NoMotion-1. Scaled. Acc [ -00041.50, -00018.55, 00989.26 ], Gyr [ -00000.24, 00001.77, 00000.24 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000006486 NoMotion-1. Scaled. Acc [ -00059.57, -00010.25, 00998.54 ], Gyr [ 00000.79, 00000.06, -00001.16 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000006487 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00982.42 ], Gyr [ 00000.79, 00000.73, -00002.68 ], Mag [ 00019.35, 00084.45, 00108.75 ]",
    "#0000006488 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00995.12 ], Gyr [ 00001.89, -00000.49, -00002.26 ], Mag [ 00019.35, 00084.45, 00108.75 ]",
    "#0000006489 NoMotion-1. Scaled. Acc [ -00047.85, -00015.14, 00988.77 ], Gyr [ 00001.83, 00000.49, -00000.67 ], Mag [ 00020.40, 00083.70, 00106.20 ]",
    "#0000006490 NoMotion-1. Scaled. Acc [ -00047.85, 00000.49, 00994.63 ], Gyr [ 00000.37, 00002.38, -00002.87 ], Mag [ 00020.40, 00083.70, 00106.20 ]",
    "#0000006491 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00991.21 ], Gyr [ 00000.79, 00002.07, -00002.01 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006492 NoMotion-1. Scaled. Acc [ -00029.30, 00005.37, 00989.75 ], Gyr [ -00000.49, 00000.85, -00000.24 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006493 NoMotion-1. Scaled. Acc [ -00050.78, -00000.98, 00996.09 ], Gyr [ 00001.89, 00000.98, -00003.05 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006494 NoMotion-1. Scaled. Acc [ -00043.46, -00017.09, 01001.46 ], Gyr [ 00001.65, 00001.40, 00000.24 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006495 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00991.70 ], Gyr [ -00001.40, 00001.83, 00000.67 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000006496 NoMotion-1. Scaled. Acc [ -00047.36, -00007.32, 00979.98 ], Gyr [ 00002.80, 00004.15, 00000.43 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000006497 NoMotion-1. Scaled. Acc [ -00043.46, -00007.32, 00992.68 ], Gyr [ 00000.49, 00000.91, -00002.32 ], Mag [ 00021.60, 00084.45, 00108.00 ]",
    "#0000006498 NoMotion-1. Scaled. Acc [ -00048.34, 00000.49, 00986.33 ], Gyr [ 00001.95, 00001.71, -00001.65 ], Mag [ 00021.60, 00084.45, 00108.00 ]",
    "#0000006499 NoMotion-1. Scaled. Acc [ -00037.11, -00005.37, 00993.65 ], Gyr [ 00000.55, 00001.95, 00001.52 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006500 NoMotion-1. Scaled. Acc [ -00032.23, -00016.11, 00990.72 ], Gyr [ 00001.71, 00000.98, -00001.40 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006501 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00991.70 ], Gyr [ 00001.28, -00000.79, -00000.79 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006502 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00989.75 ], Gyr [ -00001.52, 00003.35, -00002.87 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006503 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00990.72 ], Gyr [ -00000.79, 00003.29, -00002.20 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000006504 NoMotion-1. Scaled. Acc [ -00047.36, -00010.25, 00993.65 ], Gyr [ -00000.12, 00001.04, -00002.99 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000006505 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00985.84 ], Gyr [ 00000.37, 00001.83, -00001.59 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000006506 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00996.58 ], Gyr [ 00001.59, 00002.93, -00002.50 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000006507 NoMotion-1. Scaled. Acc [ -00037.60, -00005.37, 00981.93 ], Gyr [ 00000.24, 00001.34, -00001.65 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000006508 NoMotion-1. Scaled. Acc [ -00049.32, -00002.93, 00996.09 ], Gyr [ -00000.73, 00000.73, -00003.11 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000006509 NoMotion-1. Scaled. Acc [ -00040.53, -00000.98, 00989.75 ], Gyr [ 00000.55, 00001.77, -00000.43 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006510 NoMotion-1. Scaled. Acc [ -00033.20, -00012.21, 01006.84 ], Gyr [ -00000.55, -00002.20, -00001.95 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006511 NoMotion-1. Scaled. Acc [ -00031.25, -00001.95, 00984.86 ], Gyr [ -00001.83, 00001.71, 00000.24 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006512 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00990.23 ], Gyr [ -00001.22, 00000.98, -00001.83 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006513 NoMotion-1. Scaled. Acc [ -00035.64, -00001.46, 00989.75 ], Gyr [ 00000.00, 00002.38, -00000.79 ], Mag [ 00019.35, 00084.15, 00106.65 ]",
    "#0000006514 NoMotion-1. Scaled. Acc [ -00054.20, -00008.30, 00991.70 ], Gyr [ 00002.80, 00003.66, -00000.85 ], Mag [ 00019.95, 00084.30, 00107.55 ]",
    "#0000006515 NoMotion-1. Scaled. Acc [ -00031.74, -00007.32, 00984.86 ], Gyr [ 00000.67, 00000.18, -00001.22 ], Mag [ 00019.95, 00084.30, 00107.55 ]",
    "#0000006516 NoMotion-1. Scaled. Acc [ -00045.41, 00002.93, 00986.82 ], Gyr [ 00001.22, 00000.91, -00001.10 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000006517 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00997.56 ], Gyr [ -00000.06, -00000.12, 00000.67 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006518 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00989.26 ], Gyr [ 00001.71, 00001.71, 00000.00 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006519 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00998.54 ], Gyr [ -00001.16, 00001.95, -00003.41 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006520 NoMotion-1. Scaled. Acc [ -00041.99, 00001.95, 00990.23 ], Gyr [ -00000.73, 00000.06, -00001.28 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006521 NoMotion-1. Scaled. Acc [ -00034.67, -00008.30, 00975.59 ], Gyr [ 00001.89, 00000.49, 00000.85 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006522 NoMotion-1. Scaled. Acc [ -00044.43, -00008.79, 00985.35 ], Gyr [ 00000.24, 00001.22, -00003.05 ], Mag [ 00019.50, 00082.05, 00107.10 ]",
    "#0000006523 NoMotion-1. Scaled. Acc [ -00044.92, -00007.32, 00994.63 ], Gyr [ 00000.00, 00003.29, -00004.27 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006524 NoMotion-1. Scaled. Acc [ -00031.74, -00006.84, 00993.65 ], Gyr [ 00002.26, 00001.59, -00000.18 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006525 NoMotion-1. Scaled. Acc [ -00043.46, -00016.11, 00982.91 ], Gyr [ 00000.98, -00000.43, -00002.20 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000006526 NoMotion-1. Scaled. Acc [ -00052.25, -00007.81, 00997.07 ], Gyr [ -00000.55, -00000.55, -00001.22 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000006527 NoMotion-1. Scaled. Acc [ -00056.64, 00000.49, 00995.61 ], Gyr [ 00000.67, 00002.01, 00000.79 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006528 NoMotion-1. Scaled. Acc [ -00043.46, -00016.11, 00993.65 ], Gyr [ 00000.18, 00002.80, -00002.32 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006529 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00994.63 ], Gyr [ -00000.55, 00000.67, -00000.49 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006530 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00987.30 ], Gyr [ 00000.67, 00000.43, -00000.98 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006531 NoMotion-1. Scaled. Acc [ -00030.76, -00003.42, 00993.65 ], Gyr [ -00000.06, 00002.26, -00000.85 ], Mag [ 00019.95, 00083.25, 00105.75 ]",
    "#0000006532 NoMotion-1. Scaled. Acc [ -00046.88, 00002.93, 00980.47 ], Gyr [ 00001.04, -00000.37, -00002.38 ], Mag [ 00019.95, 00083.25, 00105.75 ]",
    "#0000006533 NoMotion-1. Scaled. Acc [ -00048.83, -00006.84, 00988.77 ], Gyr [ 00001.10, 00001.65, -00001.28 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006534 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00991.70 ], Gyr [ -00000.49, 00000.73, -00002.13 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006535 NoMotion-1. Scaled. Acc [ -00065.92, 00005.37, 00985.84 ], Gyr [ 00001.83, 00001.65, -00000.06 ], Mag [ 00020.40, 00083.70, 00108.75 ]",
    "#0000006536 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 00995.12 ], Gyr [ 00002.80, 00000.79, -00000.61 ], Mag [ 00020.40, 00083.70, 00108.75 ]",
    "#0000006537 NoMotion-1. Scaled. Acc [ -00044.43, 00000.98, 00991.21 ], Gyr [ 00000.67, 00000.73, 00000.67 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006538 NoMotion-1. Scaled. Acc [ -00048.34, 00005.86, 00990.72 ], Gyr [ 00001.34, 00002.56, 00000.91 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006539 NoMotion-1. Scaled. Acc [ -00044.92, -00005.86, 01000.98 ], Gyr [ 00000.98, 00002.56, -00002.26 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006540 NoMotion-1. Scaled. Acc [ -00044.92, -00018.07, 00987.79 ], Gyr [ 00000.43, 00003.17, -00002.80 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000006541 NoMotion-1. Scaled. Acc [ -00044.92, -00005.37, 00990.23 ], Gyr [ 00001.40, -00001.04, -00001.34 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000006542 NoMotion-1. Scaled. Acc [ -00043.95, -00000.98, 00996.09 ], Gyr [ 00000.37, 00000.06, -00002.93 ], Mag [ 00019.05, 00083.40, 00106.65 ]",
    "#0000006543 NoMotion-1. Scaled. Acc [ -00048.34, -00002.44, 00992.68 ], Gyr [ 00001.16, -00000.18, -00000.12 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006544 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00984.86 ], Gyr [ 00001.46, 00000.98, -00000.67 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006545 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00997.07 ], Gyr [ 00000.30, 00001.04, -00002.07 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006546 NoMotion-1. Scaled. Acc [ -00052.25, -00020.02, 00988.77 ], Gyr [ 00000.37, 00003.29, -00002.38 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006547 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00989.26 ], Gyr [ 00000.67, 00003.05, -00001.89 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006548 NoMotion-1. Scaled. Acc [ -00052.25, 00008.30, 00984.86 ], Gyr [ 00001.10, 00002.01, -00001.71 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006549 NoMotion-1. Scaled. Acc [ -00042.97, -00000.98, 00988.28 ], Gyr [ 00001.40, 00003.60, -00002.62 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006550 NoMotion-1. Scaled. Acc [ -00057.62, -00003.91, 00987.79 ], Gyr [ 00001.59, -00000.55, -00002.68 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006551 NoMotion-1. Scaled. Acc [ -00042.48, -00015.14, 00985.35 ], Gyr [ 00000.49, 00000.73, -00003.48 ], Mag [ 00021.15, 00083.70, 00106.65 ]",
    "#0000006552 NoMotion-1. Scaled. Acc [ -00038.57, -00009.28, 00991.21 ], Gyr [ 00000.55, 00002.07, -00001.71 ], Mag [ 00019.35, 00084.45, 00107.40 ]",
    "#0000006553 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00983.89 ], Gyr [ 00000.49, 00002.44, 00000.30 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006554 NoMotion-1. Scaled. Acc [ -00061.52, -00019.04, 01000.00 ], Gyr [ -00000.91, 00001.65, -00004.45 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006555 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00987.79 ], Gyr [ 00000.37, 00003.84, 00001.59 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006556 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 01001.46 ], Gyr [ 00002.26, 00003.54, -00001.89 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006557 NoMotion-1. Scaled. Acc [ -00034.67, -00005.86, 00990.23 ], Gyr [ 00000.61, -00001.59, -00001.95 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000006558 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00990.23 ], Gyr [ 00000.55, 00001.28, -00002.74 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000006559 NoMotion-1. Scaled. Acc [ -00040.04, -00012.70, 00995.12 ], Gyr [ 00001.52, 00001.89, 00000.79 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006560 NoMotion-1. Scaled. Acc [ -00048.83, -00010.25, 00987.30 ], Gyr [ 00001.16, 00002.80, 00000.12 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006561 NoMotion-1. Scaled. Acc [ -00052.73, -00007.32, 00991.70 ], Gyr [ 00001.46, -00001.16, -00004.39 ], Mag [ 00019.50, 00084.75, 00108.90 ]",
    "#0000006562 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00993.65 ], Gyr [ -00000.49, 00000.55, 00000.00 ], Mag [ 00019.50, 00084.75, 00108.90 ]",
    "#0000006563 NoMotion-1. Scaled. Acc [ -00049.32, -00020.02, 00993.16 ], Gyr [ -00000.49, 00001.34, -00002.56 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006564 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00991.21 ], Gyr [ 00001.52, -00000.12, 00000.79 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006565 NoMotion-1. Scaled. Acc [ -00043.46, -00003.91, 00991.21 ], Gyr [ 00000.43, 00002.13, -00002.01 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006566 NoMotion-1. Scaled. Acc [ -00039.06, -00010.74, 00986.82 ], Gyr [ 00000.00, 00004.09, -00000.98 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000006567 NoMotion-1. Scaled. Acc [ -00051.27, -00002.44, 00992.19 ], Gyr [ 00001.28, -00000.37, -00001.46 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000006568 NoMotion-1. Scaled. Acc [ -00039.06, -00003.42, 00977.54 ], Gyr [ 00000.43, 00000.61, -00003.54 ], Mag [ 00018.60, 00082.65, 00109.50 ]",
    "#0000006569 NoMotion-1. Scaled. Acc [ -00032.23, -00009.77, 00982.91 ], Gyr [ -00000.18, 00000.73, -00002.68 ], Mag [ 00018.60, 00082.65, 00109.50 ]",
    "#0000006570 NoMotion-1. Scaled. Acc [ -00042.97, -00021.00, 01004.39 ], Gyr [ -00000.24, 00001.04, -00002.01 ], Mag [ 00019.05, 00084.90, 00108.00 ]",
    "#0000006571 NoMotion-1. Scaled. Acc [ -00042.97, -00003.42, 01001.95 ], Gyr [ 00000.37, -00000.61, -00000.49 ], Mag [ 00019.05, 00084.90, 00108.00 ]",
    "#0000006572 NoMotion-1. Scaled. Acc [ -00035.64, 00009.28, 00991.21 ], Gyr [ 00001.10, 00003.84, -00000.24 ], Mag [ 00020.85, 00081.90, 00107.40 ]",
    "#0000006573 NoMotion-1. Scaled. Acc [ -00043.46, -00017.58, 00993.16 ], Gyr [ 00000.49, 00002.07, -00000.98 ], Mag [ 00020.85, 00081.90, 00107.40 ]",
    "#0000006574 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00988.77 ], Gyr [ 00000.67, 00002.13, -00003.23 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000006575 NoMotion-1. Scaled. Acc [ -00039.06, -00000.98, 00980.47 ], Gyr [ 00000.06, 00003.05, -00002.74 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000006576 NoMotion-1. Scaled. Acc [ -00051.27, -00006.84, 00982.91 ], Gyr [ 00000.37, -00000.79, -00000.12 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000006577 NoMotion-1. Scaled. Acc [ -00039.55, 00003.42, 00992.68 ], Gyr [ 00000.67, 00004.70, -00001.40 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000006578 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00992.68 ], Gyr [ -00001.95, 00001.83, -00002.44 ], Mag [ 00019.05, 00084.90, 00106.95 ]",
    "#0000006579 NoMotion-1. Scaled. Acc [ -00039.06, -00009.77, 00998.54 ], Gyr [ 00000.37, 00003.05, -00002.87 ], Mag [ 00019.05, 00084.90, 00106.95 ]",
    "#0000006580 NoMotion-1. Scaled. Acc [ -00041.99, 00002.44, 00972.66 ], Gyr [ 00000.79, 00000.79, -00003.29 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006581 NoMotion-1. Scaled. Acc [ -00051.76, -00009.28, 00988.28 ], Gyr [ 00000.30, 00001.22, 00001.16 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006582 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00984.86 ], Gyr [ 00002.26, 00003.29, 00000.43 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006583 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00976.56 ], Gyr [ 00000.73, 00003.66, 00000.30 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006584 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00990.72 ], Gyr [ 00001.52, 00001.71, -00000.91 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006585 NoMotion-1. Scaled. Acc [ -00041.02, -00002.44, 00993.65 ], Gyr [ 00000.30, 00002.93, -00001.10 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006586 NoMotion-1. Scaled. Acc [ -00030.27, 00005.37, 00993.16 ], Gyr [ 00001.10, 00002.50, -00002.44 ], Mag [ 00019.65, 00083.40, 00106.20 ]",
    "#0000006587 NoMotion-1. Scaled. Acc [ -00050.29, 00001.95, 00985.84 ], Gyr [ 00001.10, 00001.89, -00003.66 ], Mag [ 00019.65, 00083.40, 00106.20 ]",
    "#0000006588 NoMotion-1. Scaled. Acc [ -00042.48, 00011.23, 00996.09 ], Gyr [ -00000.43, 00001.52, -00003.23 ], Mag [ 00019.65, 00084.15, 00108.00 ]",
    "#0000006589 NoMotion-1. Scaled. Acc [ -00034.18, -00002.93, 00982.91 ], Gyr [ 00000.00, 00000.98, -00000.79 ], Mag [ 00019.65, 00084.15, 00108.00 ]",
    "#0000006590 NoMotion-1. Scaled. Acc [ -00054.20, -00007.81, 00991.70 ], Gyr [ 00000.91, 00004.76, -00001.16 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006591 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00991.21 ], Gyr [ 00000.12, 00002.32, -00001.65 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006592 NoMotion-1. Scaled. Acc [ -00043.95, -00008.79, 00990.72 ], Gyr [ -00000.30, 00001.65, -00003.66 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006593 NoMotion-1. Scaled. Acc [ -00035.16, 00002.44, 00983.89 ], Gyr [ 00000.85, 00002.99, -00001.28 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000006594 NoMotion-1. Scaled. Acc [ -00047.36, -00007.81, 00993.16 ], Gyr [ -00000.06, -00000.73, -00000.18 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000006595 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00996.09 ], Gyr [ 00001.83, 00001.22, -00002.13 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006596 NoMotion-1. Scaled. Acc [ -00044.43, -00011.72, 00997.56 ], Gyr [ -00000.55, 00003.90, -00002.26 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006597 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00983.89 ], Gyr [ 00000.67, 00000.06, 00001.10 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006598 NoMotion-1. Scaled. Acc [ -00036.13, -00013.67, 00989.75 ], Gyr [ -00000.61, 00001.89, 00000.12 ], Mag [ 00019.35, 00083.70, 00106.95 ]",
    "#0000006599 NoMotion-1. Scaled. Acc [ -00035.64, -00015.14, 00994.14 ], Gyr [ 00001.40, 00001.22, -00002.68 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006600 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00990.72 ], Gyr [ -00001.22, 00002.68, -00000.06 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006601 NoMotion-1. Scaled. Acc [ -00055.66, -00005.37, 00982.42 ], Gyr [ 00003.05, 00000.00, -00002.44 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006602 NoMotion-1. Scaled. Acc [ -00042.97, -00002.44, 00993.65 ], Gyr [ 00001.52, 00002.13, -00000.91 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006603 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 00994.63 ], Gyr [ 00000.06, 00002.80, -00000.43 ], Mag [ 00019.20, 00085.05, 00107.55 ]",
    "#0000006604 NoMotion-1. Scaled. Acc [ -00042.48, -00003.42, 00986.82 ], Gyr [ -00002.68, 00003.72, -00002.38 ], Mag [ 00018.60, 00083.40, 00108.75 ]",
    "#0000006605 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00992.68 ], Gyr [ 00000.67, 00001.89, -00003.84 ], Mag [ 00018.60, 00083.40, 00108.75 ]",
    "#0000006606 NoMotion-1. Scaled. Acc [ -00052.73, -00014.65, 00984.38 ], Gyr [ -00000.55, 00001.52, -00000.30 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000006607 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00994.63 ], Gyr [ 00002.50, 00001.52, 00000.73 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000006608 NoMotion-1. Scaled. Acc [ -00035.64, -00004.88, 00996.09 ], Gyr [ 00000.85, 00003.05, -00001.52 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006609 NoMotion-1. Scaled. Acc [ -00035.16, -00003.91, 01008.30 ], Gyr [ 00001.40, -00001.52, -00002.50 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006610 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00990.72 ], Gyr [ 00001.83, 00001.46, -00000.24 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006611 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 00993.65 ], Gyr [ 00000.85, -00000.67, 00000.85 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006612 NoMotion-1. Scaled. Acc [ -00059.08, -00002.44, 00986.82 ], Gyr [ -00000.55, 00002.26, -00005.85 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006613 NoMotion-1. Scaled. Acc [ -00034.18, -00002.44, 00994.63 ], Gyr [ -00000.61, 00003.17, 00000.49 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006614 NoMotion-1. Scaled. Acc [ -00045.41, -00005.37, 00986.33 ], Gyr [ -00000.49, -00001.89, 00000.06 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006615 NoMotion-1. Scaled. Acc [ -00046.39, -00002.93, 00989.75 ], Gyr [ -00000.85, 00001.95, -00003.17 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006616 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00986.33 ], Gyr [ -00000.24, 00002.50, -00000.79 ], Mag [ 00017.55, 00081.45, 00107.70 ]",
    "#0000006617 NoMotion-1. Scaled. Acc [ -00043.46, -00017.58, 00995.12 ], Gyr [ -00000.73, 00001.04, -00004.21 ], Mag [ 00017.55, 00081.45, 00107.70 ]",
    "#0000006618 NoMotion-1. Scaled. Acc [ -00036.62, -00005.37, 00991.21 ], Gyr [ 00000.98, 00003.66, -00000.37 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006619 NoMotion-1. Scaled. Acc [ -00040.53, -00011.72, 00992.68 ], Gyr [ -00000.06, -00001.16, -00000.73 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006620 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00994.14 ], Gyr [ 00000.06, -00000.49, -00000.91 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006621 NoMotion-1. Scaled. Acc [ -00036.62, -00014.65, 00995.61 ], Gyr [ 00002.44, 00003.23, -00000.55 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006622 NoMotion-1. Scaled. Acc [ -00044.43, -00001.95, 00997.56 ], Gyr [ 00000.67, -00000.18, -00001.71 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006623 NoMotion-1. Scaled. Acc [ -00046.39, 00001.95, 00984.86 ], Gyr [ 00001.40, 00001.59, -00001.77 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000006624 NoMotion-1. Scaled. Acc [ -00036.62, -00015.62, 00976.56 ], Gyr [ 00000.67, 00001.22, -00001.04 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006625 NoMotion-1. Scaled. Acc [ -00044.92, -00017.58, 01005.86 ], Gyr [ 00001.10, 00001.46, 00000.43 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006626 NoMotion-1. Scaled. Acc [ -00046.88, -00016.60, 00991.70 ], Gyr [ 00002.01, 00000.43, -00003.78 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006627 NoMotion-1. Scaled. Acc [ -00040.04, -00011.23, 00995.12 ], Gyr [ -00002.07, 00001.10, -00002.74 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006628 NoMotion-1. Scaled. Acc [ -00033.20, -00003.91, 00998.54 ], Gyr [ 00000.55, 00000.49, 00001.34 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006629 NoMotion-1. Scaled. Acc [ -00041.02, -00001.95, 00996.09 ], Gyr [ 00000.37, 00000.67, -00002.62 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006630 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00993.16 ], Gyr [ 00000.06, 00003.11, -00003.78 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000006631 NoMotion-1. Scaled. Acc [ -00037.60, -00002.44, 00990.23 ], Gyr [ -00000.91, 00003.66, -00001.46 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000006632 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 00994.63 ], Gyr [ 00001.04, 00001.22, 00000.61 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000006633 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00992.19 ], Gyr [ 00001.83, 00001.04, -00002.32 ], Mag [ 00019.35, 00084.90, 00108.00 ]",
    "#0000006634 NoMotion-1. Scaled. Acc [ -00045.90, -00014.65, 00996.58 ], Gyr [ -00000.37, 00001.52, -00000.79 ], Mag [ 00019.35, 00084.90, 00108.00 ]",
    "#0000006635 NoMotion-1. Scaled. Acc [ -00054.69, -00006.84, 00987.30 ], Gyr [ 00000.67, 00000.06, 00000.55 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006636 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00982.42 ], Gyr [ -00000.24, 00000.67, -00000.24 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006637 NoMotion-1. Scaled. Acc [ -00046.88, -00001.46, 00983.40 ], Gyr [ 00000.49, 00000.49, -00001.16 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006638 NoMotion-1. Scaled. Acc [ -00052.73, -00002.93, 00982.42 ], Gyr [ -00000.43, 00003.05, -00003.54 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006639 NoMotion-1. Scaled. Acc [ -00035.16, -00007.81, 00989.26 ], Gyr [ -00000.79, 00000.85, -00004.02 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000006640 NoMotion-1. Scaled. Acc [ -00026.37, 00001.95, 00978.52 ], Gyr [ 00000.79, -00002.07, -00001.46 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000006641 NoMotion-1. Scaled. Acc [ -00048.34, -00007.32, 00999.51 ], Gyr [ -00000.79, 00002.99, 00000.30 ], Mag [ 00019.35, 00082.95, 00109.50 ]",
    "#0000006642 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00985.35 ], Gyr [ 00000.24, 00002.32, -00002.74 ], Mag [ 00019.35, 00082.95, 00109.50 ]",
    "#0000006643 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00995.12 ], Gyr [ -00000.24, 00002.74, -00003.48 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000006644 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00994.63 ], Gyr [ 00000.06, 00000.49, -00003.05 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000006645 NoMotion-1. Scaled. Acc [ -00033.20, 00008.79, 00980.96 ], Gyr [ 00001.95, 00003.29, -00001.65 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006646 NoMotion-1. Scaled. Acc [ -00041.50, -00011.23, 00994.63 ], Gyr [ 00001.71, -00001.04, 00000.00 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006647 NoMotion-1. Scaled. Acc [ -00053.71, -00012.70, 00986.33 ], Gyr [ 00002.07, 00000.37, 00000.06 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006648 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00983.40 ], Gyr [ 00000.61, 00002.99, -00001.52 ], Mag [ 00020.25, 00081.75, 00107.40 ]",
    "#0000006649 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00984.38 ], Gyr [ 00002.50, 00001.28, -00002.26 ], Mag [ 00020.25, 00081.75, 00106.50 ]",
    "#0000006650 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00999.51 ], Gyr [ 00001.83, -00000.85, -00001.83 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006651 NoMotion-1. Scaled. Acc [ -00042.97, -00011.23, 00988.28 ], Gyr [ 00000.98, -00000.98, -00000.12 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006652 NoMotion-1. Scaled. Acc [ -00049.80, -00003.42, 00996.58 ], Gyr [ -00000.06, 00003.05, -00001.34 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006653 NoMotion-1. Scaled. Acc [ -00052.25, -00008.79, 00985.35 ], Gyr [ -00001.34, 00001.52, -00001.46 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000006654 NoMotion-1. Scaled. Acc [ -00041.02, 00004.88, 00993.16 ], Gyr [ 00000.79, 00001.28, -00002.56 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006655 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00987.30 ], Gyr [ -00002.20, 00000.98, -00000.61 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006656 NoMotion-1. Scaled. Acc [ -00024.41, -00006.35, 00986.33 ], Gyr [ 00000.49, 00002.93, -00000.85 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006657 NoMotion-1. Scaled. Acc [ -00039.55, -00003.42, 00988.77 ], Gyr [ -00000.24, 00003.29, -00001.16 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006658 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 00997.07 ], Gyr [ 00002.26, 00001.04, -00000.12 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006659 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00993.16 ], Gyr [ 00001.46, 00000.30, -00000.43 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006660 NoMotion-1. Scaled. Acc [ -00041.50, -00003.42, 00991.70 ], Gyr [ 00000.12, 00004.51, -00003.60 ], Mag [ 00018.60, 00084.15, 00109.05 ]",
    "#0000006661 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00984.86 ], Gyr [ 00000.49, 00000.61, -00000.43 ], Mag [ 00018.60, 00084.15, 00109.05 ]",
    "#0000006662 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00991.21 ], Gyr [ 00002.44, -00000.06, -00000.43 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000006663 NoMotion-1. Scaled. Acc [ -00049.32, -00015.62, 01000.98 ], Gyr [ -00001.28, 00002.68, -00000.98 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006664 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 01005.37 ], Gyr [ 00001.40, 00002.56, -00003.11 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006665 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00991.21 ], Gyr [ -00000.37, 00001.52, -00000.91 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006666 NoMotion-1. Scaled. Acc [ -00041.02, -00006.35, 00998.05 ], Gyr [ 00001.04, -00000.30, 00000.37 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006667 NoMotion-1. Scaled. Acc [ -00035.64, -00000.98, 00990.72 ], Gyr [ -00002.20, 00002.74, -00002.38 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000006668 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 00983.89 ], Gyr [ 00001.10, 00004.09, -00001.71 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000006669 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00993.16 ], Gyr [ 00000.73, 00001.04, -00000.98 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006670 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 00987.79 ], Gyr [ 00002.99, 00002.50, -00002.44 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006671 NoMotion-1. Scaled. Acc [ -00051.27, -00012.21, 00998.05 ], Gyr [ 00001.83, 00001.46, 00000.06 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006672 NoMotion-1. Scaled. Acc [ -00037.60, -00006.35, 00994.63 ], Gyr [ 00000.06, -00002.01, -00001.34 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006673 NoMotion-1. Scaled. Acc [ -00041.99, 00001.46, 01000.98 ], Gyr [ 00001.95, 00000.43, -00000.06 ], Mag [ 00018.15, 00085.05, 00108.90 ]",
    "#0000006674 NoMotion-1. Scaled. Acc [ -00048.34, -00008.30, 00984.86 ], Gyr [ 00001.59, -00000.49, -00001.34 ], Mag [ 00018.15, 00085.05, 00108.90 ]",
    "#0000006675 NoMotion-1. Scaled. Acc [ -00042.48, -00012.21, 00987.30 ], Gyr [ 00000.00, 00001.83, -00002.68 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000006676 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00987.79 ], Gyr [ 00001.83, 00000.37, -00002.99 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000006677 NoMotion-1. Scaled. Acc [ -00041.99, -00003.42, 00985.35 ], Gyr [ 00000.61, 00000.91, -00001.65 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006678 NoMotion-1. Scaled. Acc [ -00039.06, -00000.49, 00991.21 ], Gyr [ -00000.49, 00004.15, 00000.43 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006679 NoMotion-1. Scaled. Acc [ -00041.02, -00002.44, 00995.61 ], Gyr [ -00001.83, 00001.71, -00002.20 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006680 NoMotion-1. Scaled. Acc [ -00062.50, 00000.98, 00991.21 ], Gyr [ 00000.61, -00000.37, -00002.74 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006681 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00982.42 ], Gyr [ 00001.95, 00002.32, -00002.26 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006682 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00991.21 ], Gyr [ 00003.11, -00001.10, 00000.43 ], Mag [ 00018.15, 00085.05, 00107.55 ]",
    "#0000006683 NoMotion-1. Scaled. Acc [ -00051.27, -00013.18, 00997.07 ], Gyr [ 00001.16, 00001.83, -00001.89 ], Mag [ 00019.20, 00083.55, 00107.10 ]",
    "#0000006684 NoMotion-1. Scaled. Acc [ -00044.43, 00005.86, 00993.16 ], Gyr [ 00001.22, -00001.34, 00000.73 ], Mag [ 00019.20, 00083.55, 00107.10 ]",
    "#0000006685 NoMotion-1. Scaled. Acc [ -00028.81, -00001.95, 00986.82 ], Gyr [ 00000.73, 00000.30, 00001.65 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000006686 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00989.75 ], Gyr [ 00001.77, -00000.85, -00001.40 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000006687 NoMotion-1. Scaled. Acc [ -00049.32, -00004.39, 00991.21 ], Gyr [ -00000.12, 00000.91, -00001.16 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006688 NoMotion-1. Scaled. Acc [ -00039.06, 00000.00, 00996.58 ], Gyr [ 00000.37, 00002.01, -00001.04 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006689 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00986.82 ], Gyr [ 00000.18, 00002.01, -00001.52 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006690 NoMotion-1. Scaled. Acc [ -00040.53, -00018.55, 00994.14 ], Gyr [ 00000.37, 00000.43, -00001.95 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006691 NoMotion-1. Scaled. Acc [ -00025.39, -00016.11, 01003.91 ], Gyr [ -00000.43, 00001.71, -00000.98 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000006692 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00990.23 ], Gyr [ -00000.24, 00001.28, -00002.32 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000006693 NoMotion-1. Scaled. Acc [ -00040.53, -00013.67, 01003.42 ], Gyr [ 00000.49, 00002.38, -00000.73 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000006694 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00981.93 ], Gyr [ 00001.10, 00002.32, -00000.49 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000006695 NoMotion-1. Scaled. Acc [ -00036.13, 00001.46, 00998.05 ], Gyr [ -00000.24, 00003.66, 00000.98 ], Mag [ 00019.65, 00083.70, 00108.00 ]",
    "#0000006696 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 01008.79 ], Gyr [ 00001.77, 00001.28, -00003.23 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006697 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00994.14 ], Gyr [ 00002.26, 00000.73, -00001.59 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006698 NoMotion-1. Scaled. Acc [ -00055.18, -00005.86, 01000.98 ], Gyr [ 00002.74, -00000.30, 00000.18 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006699 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00997.56 ], Gyr [ 00001.46, 00000.67, 00000.79 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006700 NoMotion-1. Scaled. Acc [ -00050.78, -00010.74, 00994.63 ], Gyr [ -00001.04, 00000.98, -00000.55 ], Mag [ 00018.60, 00085.20, 00105.90 ]",
    "#0000006701 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00995.61 ], Gyr [ 00000.67, 00002.07, 00001.22 ], Mag [ 00018.60, 00085.20, 00105.90 ]",
    "#0000006702 NoMotion-1. Scaled. Acc [ -00038.09, -00006.35, 00985.35 ], Gyr [ -00000.37, 00002.80, -00000.18 ], Mag [ 00020.40, 00084.45, 00106.95 ]",
    "#0000006703 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00986.82 ], Gyr [ 00000.24, 00003.35, -00004.21 ], Mag [ 00020.40, 00084.45, 00106.95 ]",
    "#0000006704 NoMotion-1. Scaled. Acc [ -00042.48, -00021.97, 00993.16 ], Gyr [ 00000.30, 00002.56, 00001.16 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000006705 NoMotion-1. Scaled. Acc [ -00051.27, -00003.91, 00994.63 ], Gyr [ 00002.26, 00001.71, -00001.34 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000006706 NoMotion-1. Scaled. Acc [ -00045.90, 00004.39, 00992.68 ], Gyr [ 00000.30, 00004.94, -00001.83 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006707 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00989.26 ], Gyr [ -00000.18, 00006.10, -00004.21 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006708 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00993.65 ], Gyr [ -00000.55, 00000.37, 00001.16 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006709 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00995.61 ], Gyr [ 00000.43, 00001.52, -00001.52 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006710 NoMotion-1. Scaled. Acc [ -00047.85, -00010.25, 00983.89 ], Gyr [ 00000.55, 00000.79, -00004.21 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006711 NoMotion-1. Scaled. Acc [ -00053.22, -00007.81, 00998.54 ], Gyr [ 00001.65, 00000.12, -00000.37 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006712 NoMotion-1. Scaled. Acc [ -00032.71, -00004.39, 00997.56 ], Gyr [ -00000.91, 00001.40, -00002.93 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006713 NoMotion-1. Scaled. Acc [ -00038.57, -00000.98, 00990.23 ], Gyr [ 00001.46, 00000.91, -00000.55 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006714 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 00991.21 ], Gyr [ 00000.55, 00000.98, -00001.28 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006715 NoMotion-1. Scaled. Acc [ -00041.02, -00014.65, 00983.40 ], Gyr [ 00000.49, 00001.77, 00001.04 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006716 NoMotion-1. Scaled. Acc [ -00048.34, -00024.41, 01000.00 ], Gyr [ 00000.67, 00000.79, -00000.98 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006717 NoMotion-1. Scaled. Acc [ -00031.74, -00016.11, 00995.12 ], Gyr [ -00000.67, 00002.07, 00000.00 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006718 NoMotion-1. Scaled. Acc [ -00041.50, 00003.91, 00993.16 ], Gyr [ 00000.06, 00001.83, -00000.91 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006719 NoMotion-1. Scaled. Acc [ -00046.88, -00008.30, 00992.19 ], Gyr [ 00001.04, 00003.84, -00001.95 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006720 NoMotion-1. Scaled. Acc [ -00055.18, -00018.55, 00991.21 ], Gyr [ 00000.67, 00001.89, -00002.87 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006721 NoMotion-1. Scaled. Acc [ -00021.97, -00003.42, 00997.56 ], Gyr [ 00000.37, 00001.52, 00001.22 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006722 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00978.03 ], Gyr [ 00001.34, 00000.91, -00002.07 ], Mag [ 00020.85, 00081.90, 00107.70 ]",
    "#0000006723 NoMotion-1. Scaled. Acc [ -00035.64, -00002.93, 00994.14 ], Gyr [ 00001.10, 00000.55, -00000.24 ], Mag [ 00020.85, 00081.90, 00107.70 ]",
    "#0000006724 NoMotion-1. Scaled. Acc [ -00040.53, -00006.35, 01004.39 ], Gyr [ 00000.55, 00003.17, -00001.83 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006725 NoMotion-1. Scaled. Acc [ -00053.71, -00006.35, 00997.56 ], Gyr [ 00000.37, 00000.91, -00001.95 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006726 NoMotion-1. Scaled. Acc [ -00043.46, -00005.37, 00986.33 ], Gyr [ -00002.20, -00001.16, -00002.68 ], Mag [ 00020.10, 00084.45, 00108.45 ]",
    "#0000006727 NoMotion-1. Scaled. Acc [ -00040.53, -00016.11, 00987.30 ], Gyr [ 00002.20, -00000.73, -00001.95 ], Mag [ 00020.10, 00084.45, 00108.45 ]",
    "#0000006728 NoMotion-1. Scaled. Acc [ -00047.36, -00014.16, 00999.51 ], Gyr [ 00000.18, 00002.50, -00001.95 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006729 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00987.79 ], Gyr [ -00000.06, 00001.04, -00002.93 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006730 NoMotion-1. Scaled. Acc [ -00043.95, -00013.67, 00993.65 ], Gyr [ -00001.59, 00002.87, -00000.30 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006731 NoMotion-1. Scaled. Acc [ -00039.55, -00007.32, 00988.77 ], Gyr [ -00001.10, 00004.09, -00001.52 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006732 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00985.35 ], Gyr [ 00000.06, 00003.78, -00002.13 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000006733 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00987.30 ], Gyr [ 00000.18, -00000.12, 00000.06 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006734 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00996.58 ], Gyr [ 00000.61, 00001.34, 00000.85 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006735 NoMotion-1. Scaled. Acc [ -00042.48, 00000.49, 00991.21 ], Gyr [ -00000.55, 00002.80, -00001.22 ], Mag [ 00018.75, 00084.00, 00108.90 ]",
    "#0000006736 NoMotion-1. Scaled. Acc [ -00032.71, -00010.25, 01000.49 ], Gyr [ 00001.16, 00001.34, -00001.89 ], Mag [ 00018.75, 00084.00, 00108.90 ]",
    "#0000006737 NoMotion-1. Scaled. Acc [ -00035.16, -00012.21, 00994.14 ], Gyr [ 00000.67, 00002.20, -00002.80 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006738 NoMotion-1. Scaled. Acc [ -00048.83, -00011.72, 00999.02 ], Gyr [ 00001.40, 00002.50, 00001.40 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006739 NoMotion-1. Scaled. Acc [ -00038.57, -00011.23, 01002.93 ], Gyr [ -00000.06, 00002.99, -00002.50 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006740 NoMotion-1. Scaled. Acc [ -00050.78, -00005.37, 01001.95 ], Gyr [ -00001.65, 00003.96, -00000.55 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006741 NoMotion-1. Scaled. Acc [ -00038.57, -00014.16, 01000.00 ], Gyr [ 00000.43, -00000.67, -00001.59 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000006742 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00997.56 ], Gyr [ -00000.79, 00003.48, -00001.22 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000006743 NoMotion-1. Scaled. Acc [ -00034.18, -00004.39, 01001.46 ], Gyr [ 00001.52, 00002.80, -00000.73 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006744 NoMotion-1. Scaled. Acc [ -00041.99, 00005.86, 00989.75 ], Gyr [ 00001.52, 00001.89, -00002.07 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006745 NoMotion-1. Scaled. Acc [ -00040.04, -00003.91, 00997.56 ], Gyr [ 00000.61, 00000.12, -00001.95 ], Mag [ 00020.70, 00084.30, 00109.35 ]",
    "#0000006746 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 01002.44 ], Gyr [ 00000.30, 00000.24, -00001.83 ], Mag [ 00020.70, 00084.30, 00109.35 ]",
    "#0000006747 NoMotion-1. Scaled. Acc [ -00033.20, -00009.77, 00994.14 ], Gyr [ -00000.73, 00002.44, -00001.22 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006748 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00987.79 ], Gyr [ 00000.61, -00001.83, -00000.24 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006749 NoMotion-1. Scaled. Acc [ -00040.04, -00018.07, 00994.63 ], Gyr [ 00000.49, 00000.43, -00000.67 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006750 NoMotion-1. Scaled. Acc [ -00042.48, -00017.58, 00989.75 ], Gyr [ 00000.43, -00000.30, -00001.77 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006751 NoMotion-1. Scaled. Acc [ -00046.39, -00007.81, 00995.61 ], Gyr [ -00000.30, 00003.60, -00003.66 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006752 NoMotion-1. Scaled. Acc [ -00048.34, -00020.02, 00991.70 ], Gyr [ 00002.38, 00000.73, 00000.37 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006753 NoMotion-1. Scaled. Acc [ -00045.90, -00011.72, 00988.28 ], Gyr [ 00001.10, -00000.85, -00002.38 ], Mag [ 00020.70, 00083.25, 00107.10 ]",
    "#0000006754 NoMotion-1. Scaled. Acc [ -00031.25, -00005.37, 00977.54 ], Gyr [ 00001.22, -00000.85, -00000.06 ], Mag [ 00020.70, 00083.25, 00107.10 ]",
    "#0000006755 NoMotion-1. Scaled. Acc [ -00033.20, -00003.91, 00983.40 ], Gyr [ 00000.67, 00002.07, -00003.41 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006756 NoMotion-1. Scaled. Acc [ -00035.64, -00005.37, 00994.63 ], Gyr [ 00000.12, 00000.12, -00001.34 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006757 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 01006.84 ], Gyr [ -00000.55, 00001.77, -00002.80 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006758 NoMotion-1. Scaled. Acc [ -00039.55, 00001.46, 00984.86 ], Gyr [ 00000.18, 00002.80, -00001.77 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006759 NoMotion-1. Scaled. Acc [ -00051.27, -00013.67, 00993.65 ], Gyr [ 00000.67, 00001.16, -00000.67 ], Mag [ 00021.30, 00084.75, 00107.10 ]",
    "#0000006760 NoMotion-1. Scaled. Acc [ -00046.88, -00010.25, 00996.09 ], Gyr [ -00000.30, -00000.79, -00001.71 ], Mag [ 00021.30, 00084.75, 00107.10 ]",
    "#0000006761 NoMotion-1. Scaled. Acc [ -00058.11, -00002.44, 00978.03 ], Gyr [ 00000.12, 00002.38, -00000.91 ], Mag [ 00019.95, 00084.00, 00105.45 ]",
    "#0000006762 NoMotion-1. Scaled. Acc [ -00040.53, -00002.44, 01000.98 ], Gyr [ 00002.68, 00002.68, -00002.07 ], Mag [ 00019.95, 00084.00, 00105.45 ]",
    "#0000006763 NoMotion-1. Scaled. Acc [ -00030.76, 00000.49, 00988.77 ], Gyr [ 00002.13, 00001.22, 00000.06 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000006764 NoMotion-1. Scaled. Acc [ -00030.76, -00013.18, 01000.98 ], Gyr [ 00002.26, -00000.12, 00000.06 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000006765 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00993.65 ], Gyr [ 00000.85, 00002.07, -00001.77 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006766 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00987.30 ], Gyr [ 00002.93, -00000.91, -00002.44 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006767 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00995.61 ], Gyr [ 00001.95, 00003.90, -00002.44 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006768 NoMotion-1. Scaled. Acc [ -00045.90, -00002.93, 00992.68 ], Gyr [ -00000.06, 00002.13, -00000.30 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006769 NoMotion-1. Scaled. Acc [ -00054.69, -00004.88, 00994.14 ], Gyr [ 00001.22, 00001.22, -00002.74 ], Mag [ 00019.50, 00084.30, 00107.55 ]",
    "#0000006770 NoMotion-1. Scaled. Acc [ -00054.20, -00006.35, 00992.19 ], Gyr [ -00002.07, 00001.89, 00000.18 ], Mag [ 00019.50, 00084.30, 00107.55 ]",
    "#0000006771 NoMotion-1. Scaled. Acc [ -00031.74, -00002.44, 00988.77 ], Gyr [ -00000.30, 00002.50, -00003.29 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000006772 NoMotion-1. Scaled. Acc [ -00046.88, 00001.46, 00988.28 ], Gyr [ 00000.98, 00002.20, 00000.24 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000006773 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00987.79 ], Gyr [ 00002.93, -00001.22, 00000.00 ], Mag [ 00019.35, 00083.70, 00106.65 ]",
    "#0000006774 NoMotion-1. Scaled. Acc [ -00047.85, 00005.37, 00983.40 ], Gyr [ 00000.73, -00000.67, 00000.12 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006775 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00988.28 ], Gyr [ 00002.93, -00000.91, -00002.80 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006776 NoMotion-1. Scaled. Acc [ -00046.39, 00001.46, 00993.16 ], Gyr [ 00000.85, 00000.00, -00000.30 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006777 NoMotion-1. Scaled. Acc [ -00038.57, -00018.07, 00990.23 ], Gyr [ -00002.13, 00000.37, -00001.16 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006778 NoMotion-1. Scaled. Acc [ -00042.97, 00012.70, 00994.63 ], Gyr [ 00001.65, 00002.26, -00000.49 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006779 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00994.63 ], Gyr [ 00000.49, 00003.11, -00001.04 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006780 NoMotion-1. Scaled. Acc [ -00034.18, -00021.00, 00994.63 ], Gyr [ 00000.55, 00002.74, -00002.87 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006781 NoMotion-1. Scaled. Acc [ -00032.23, -00006.84, 01003.42 ], Gyr [ 00000.24, 00004.33, -00001.83 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006782 NoMotion-1. Scaled. Acc [ -00052.73, -00007.81, 00987.79 ], Gyr [ -00000.85, 00000.06, -00003.54 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006783 NoMotion-1. Scaled. Acc [ -00041.02, 00000.98, 00986.33 ], Gyr [ 00001.28, 00000.06, -00002.44 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006784 NoMotion-1. Scaled. Acc [ -00040.04, -00017.09, 01000.98 ], Gyr [ -00000.49, 00000.79, 00000.37 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000006785 NoMotion-1. Scaled. Acc [ -00054.20, -00005.37, 01002.93 ], Gyr [ 00001.04, 00001.10, -00002.01 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000006786 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00992.19 ], Gyr [ -00000.12, 00001.10, -00000.85 ], Mag [ 00020.40, 00084.45, 00107.70 ]",
    "#0000006787 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00994.63 ], Gyr [ 00001.59, -00000.06, -00001.10 ], Mag [ 00020.40, 00084.45, 00107.70 ]",
    "#0000006788 NoMotion-1. Scaled. Acc [ -00045.41, 00004.39, 00984.38 ], Gyr [ 00001.04, 00000.12, -00003.11 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000006789 NoMotion-1. Scaled. Acc [ -00031.74, 00006.84, 01000.98 ], Gyr [ 00000.18, -00001.04, 00000.67 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006790 NoMotion-1. Scaled. Acc [ -00053.71, -00014.65, 00992.19 ], Gyr [ 00000.12, 00001.10, -00002.68 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006791 NoMotion-1. Scaled. Acc [ -00046.39, -00008.30, 00993.65 ], Gyr [ 00000.18, 00002.38, -00002.07 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006792 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00982.42 ], Gyr [ -00001.10, 00001.77, -00002.93 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000006793 NoMotion-1. Scaled. Acc [ -00047.36, -00006.35, 00999.51 ], Gyr [ -00000.73, 00002.07, -00000.37 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000006794 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00984.86 ], Gyr [ 00001.52, -00000.49, -00002.01 ], Mag [ 00018.75, 00084.75, 00106.80 ]",
    "#0000006795 NoMotion-1. Scaled. Acc [ -00052.73, -00007.32, 00993.16 ], Gyr [ -00000.43, 00000.85, -00002.20 ], Mag [ 00018.75, 00084.75, 00106.80 ]",
    "#0000006796 NoMotion-1. Scaled. Acc [ -00058.59, -00003.42, 00995.12 ], Gyr [ 00000.00, 00000.73, -00000.43 ], Mag [ 00019.35, 00085.20, 00107.70 ]",
    "#0000006797 NoMotion-1. Scaled. Acc [ -00057.13, -00014.65, 00991.70 ], Gyr [ 00000.30, 00001.77, -00001.22 ], Mag [ 00019.35, 00085.20, 00107.70 ]",
    "#0000006798 NoMotion-1. Scaled. Acc [ -00044.43, -00007.81, 00990.72 ], Gyr [ 00001.16, 00001.04, -00001.89 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006799 NoMotion-1. Scaled. Acc [ -00038.57, -00008.30, 00998.54 ], Gyr [ 00000.30, 00000.24, -00001.77 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006800 NoMotion-1. Scaled. Acc [ -00034.18, -00009.28, 00989.75 ], Gyr [ 00000.06, 00002.38, -00002.01 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006801 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00992.19 ], Gyr [ 00000.85, 00001.28, -00000.49 ], Mag [ 00019.50, 00083.55, 00108.60 ]",
    "#0000006802 NoMotion-1. Scaled. Acc [ -00044.43, -00001.46, 00983.40 ], Gyr [ 00001.10, 00003.72, -00001.83 ], Mag [ 00019.50, 00083.55, 00108.60 ]",
    "#0000006803 NoMotion-1. Scaled. Acc [ -00041.50, -00008.79, 00984.86 ], Gyr [ -00000.85, 00004.15, 00000.55 ], Mag [ 00020.40, 00083.55, 00108.60 ]",
    "#0000006804 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00999.51 ], Gyr [ -00000.85, 00003.41, -00003.48 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000006805 NoMotion-1. Scaled. Acc [ -00055.18, -00011.72, 00990.72 ], Gyr [ -00001.77, 00002.56, -00001.22 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006806 NoMotion-1. Scaled. Acc [ -00048.83, 00001.46, 00995.61 ], Gyr [ 00000.37, 00002.26, -00002.62 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006807 NoMotion-1. Scaled. Acc [ -00038.57, -00001.46, 01003.91 ], Gyr [ 00000.37, 00001.65, -00002.32 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006808 NoMotion-1. Scaled. Acc [ -00035.16, 00001.95, 00991.70 ], Gyr [ 00001.59, 00000.30, 00000.67 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006809 NoMotion-1. Scaled. Acc [ -00045.41, 00001.95, 00993.16 ], Gyr [ 00001.34, -00000.85, -00000.61 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006810 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00996.09 ], Gyr [ 00002.44, 00001.04, 00000.55 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000006811 NoMotion-1. Scaled. Acc [ -00052.25, -00011.23, 01000.49 ], Gyr [ 00001.52, 00002.32, -00000.85 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000006812 NoMotion-1. Scaled. Acc [ -00035.16, -00012.21, 00988.77 ], Gyr [ -00000.18, 00002.62, -00001.46 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000006813 NoMotion-1. Scaled. Acc [ -00042.48, -00017.09, 00991.70 ], Gyr [ 00001.40, 00001.16, -00001.10 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000006814 NoMotion-1. Scaled. Acc [ -00041.50, -00003.91, 01000.49 ], Gyr [ 00002.01, -00000.06, -00000.43 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000006815 NoMotion-1. Scaled. Acc [ -00040.04, -00017.09, 00990.23 ], Gyr [ 00002.44, 00001.34, -00003.05 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000006816 NoMotion-1. Scaled. Acc [ -00048.34, -00004.39, 00978.52 ], Gyr [ -00000.24, 00000.79, -00003.96 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006817 NoMotion-1. Scaled. Acc [ -00049.32, -00001.46, 00995.61 ], Gyr [ 00000.06, 00001.16, -00001.34 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006818 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 01003.91 ], Gyr [ 00000.43, 00002.68, -00002.68 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006819 NoMotion-1. Scaled. Acc [ -00044.92, -00006.35, 00992.68 ], Gyr [ 00000.85, 00004.02, -00004.09 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006820 NoMotion-1. Scaled. Acc [ -00047.85, -00004.39, 00996.09 ], Gyr [ 00000.00, 00001.34, -00001.65 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000006821 NoMotion-1. Scaled. Acc [ -00050.78, -00006.84, 00983.89 ], Gyr [ -00001.04, -00000.24, -00001.83 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006822 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00992.68 ], Gyr [ 00001.16, 00001.46, -00002.01 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006823 NoMotion-1. Scaled. Acc [ -00043.46, -00003.42, 00994.14 ], Gyr [ 00001.89, 00001.16, 00000.61 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006824 NoMotion-1. Scaled. Acc [ -00052.73, -00001.95, 00993.65 ], Gyr [ 00003.84, 00000.24, -00001.40 ], Mag [ 00019.35, 00082.95, 00106.20 ]",
    "#0000006825 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00989.75 ], Gyr [ 00000.61, 00000.67, -00001.65 ], Mag [ 00019.35, 00082.95, 00106.20 ]",
    "#0000006826 NoMotion-1. Scaled. Acc [ -00039.55, 00002.93, 00985.35 ], Gyr [ 00001.04, 00000.12, -00002.80 ], Mag [ 00019.20, 00085.05, 00108.30 ]",
    "#0000006827 NoMotion-1. Scaled. Acc [ -00038.09, -00009.28, 00995.12 ], Gyr [ -00000.79, 00002.62, -00001.28 ], Mag [ 00019.20, 00085.05, 00108.30 ]",
    "#0000006828 NoMotion-1. Scaled. Acc [ -00050.78, -00006.35, 00993.65 ], Gyr [ 00001.46, 00002.80, -00003.96 ], Mag [ 00021.60, 00084.45, 00109.05 ]",
    "#0000006829 NoMotion-1. Scaled. Acc [ -00040.04, -00004.88, 00985.84 ], Gyr [ -00001.59, 00001.04, -00002.50 ], Mag [ 00021.60, 00084.45, 00109.05 ]",
    "#0000006830 NoMotion-1. Scaled. Acc [ -00057.13, -00004.39, 00992.68 ], Gyr [ -00001.59, 00002.50, -00000.85 ], Mag [ 00020.70, 00085.05, 00108.30 ]",
    "#0000006831 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00985.35 ], Gyr [ -00001.16, 00002.93, -00000.98 ], Mag [ 00020.70, 00085.05, 00108.30 ]",
    "#0000006832 NoMotion-1. Scaled. Acc [ -00040.04, -00020.51, 00984.38 ], Gyr [ -00000.24, 00001.95, -00002.80 ], Mag [ 00021.00, 00085.50, 00107.55 ]",
    "#0000006833 NoMotion-1. Scaled. Acc [ -00046.39, -00012.70, 00991.70 ], Gyr [ -00000.12, 00003.41, -00001.52 ], Mag [ 00021.00, 00085.50, 00107.55 ]",
    "#0000006834 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00986.82 ], Gyr [ 00000.00, 00002.50, -00003.72 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006835 NoMotion-1. Scaled. Acc [ -00039.55, -00021.00, 00985.84 ], Gyr [ 00001.16, 00000.85, -00001.77 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006836 NoMotion-1. Scaled. Acc [ -00043.46, -00006.35, 00984.38 ], Gyr [ 00001.52, 00000.79, -00001.40 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006837 NoMotion-1. Scaled. Acc [ -00041.50, -00001.46, 00989.26 ], Gyr [ -00001.65, 00002.56, -00001.40 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006838 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00982.91 ], Gyr [ 00001.59, 00000.67, -00000.73 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006839 NoMotion-1. Scaled. Acc [ -00054.20, 00000.98, 00996.58 ], Gyr [ 00000.61, 00001.04, -00001.16 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006840 NoMotion-1. Scaled. Acc [ -00049.32, -00009.28, 00990.23 ], Gyr [ -00000.24, 00001.77, -00001.77 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006841 NoMotion-1. Scaled. Acc [ -00047.36, -00000.98, 00999.51 ], Gyr [ 00000.49, 00001.52, -00000.67 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006842 NoMotion-1. Scaled. Acc [ -00040.53, -00025.39, 00994.63 ], Gyr [ -00002.01, 00002.87, -00000.37 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000006843 NoMotion-1. Scaled. Acc [ -00042.97, -00014.16, 00998.05 ], Gyr [ 00000.12, 00003.72, -00002.56 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000006844 NoMotion-1. Scaled. Acc [ -00037.60, -00010.25, 00994.63 ], Gyr [ 00000.55, 00004.39, -00001.95 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006845 NoMotion-1. Scaled. Acc [ -00044.43, 00000.49, 00992.19 ], Gyr [ -00000.49, 00000.43, -00003.54 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006846 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00991.21 ], Gyr [ 00002.07, 00000.24, -00001.52 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006847 NoMotion-1. Scaled. Acc [ -00037.60, -00003.91, 00984.86 ], Gyr [ 00001.22, 00002.93, -00002.68 ], Mag [ 00019.50, 00083.55, 00108.90 ]",
    "#0000006848 NoMotion-1. Scaled. Acc [ -00047.85, -00019.04, 00996.58 ], Gyr [ 00000.67, 00001.95, 00000.24 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006849 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00987.30 ], Gyr [ -00000.37, -00000.73, -00001.59 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006850 NoMotion-1. Scaled. Acc [ -00034.18, 00003.42, 00979.49 ], Gyr [ 00000.49, -00000.37, -00000.85 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006851 NoMotion-1. Scaled. Acc [ -00041.50, 00001.46, 00995.61 ], Gyr [ 00001.10, 00002.32, 00000.55 ], Mag [ 00019.35, 00082.20, 00107.40 ]",
    "#0000006852 NoMotion-1. Scaled. Acc [ -00042.48, 00008.79, 00989.75 ], Gyr [ -00000.24, 00001.95, -00002.99 ], Mag [ 00019.35, 00082.20, 00107.40 ]",
    "#0000006853 NoMotion-1. Scaled. Acc [ -00044.43, 00003.42, 00981.45 ], Gyr [ 00000.91, 00002.32, -00003.66 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006854 NoMotion-1. Scaled. Acc [ -00041.99, -00010.25, 00998.05 ], Gyr [ 00001.52, 00000.37, -00003.05 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006855 NoMotion-1. Scaled. Acc [ -00044.92, -00023.44, 00978.03 ], Gyr [ 00002.50, -00000.43, -00000.30 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006856 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 00994.14 ], Gyr [ 00000.85, 00000.79, -00003.54 ], Mag [ 00019.50, 00085.05, 00107.85 ]",
    "#0000006857 NoMotion-1. Scaled. Acc [ -00031.25, -00007.81, 00996.09 ], Gyr [ -00000.12, 00000.85, -00002.87 ], Mag [ 00019.50, 00085.05, 00107.85 ]",
    "#0000006858 NoMotion-1. Scaled. Acc [ -00041.02, -00007.81, 00990.23 ], Gyr [ -00000.43, 00000.55, -00002.01 ], Mag [ 00020.85, 00083.70, 00107.70 ]",
    "#0000006859 NoMotion-1. Scaled. Acc [ -00038.09, -00008.79, 00989.75 ], Gyr [ 00000.98, 00000.55, -00001.46 ], Mag [ 00020.85, 00083.70, 00107.70 ]",
    "#0000006860 NoMotion-1. Scaled. Acc [ -00041.50, -00003.42, 00984.38 ], Gyr [ 00002.99, 00002.32, -00001.71 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000006861 NoMotion-1. Scaled. Acc [ -00039.55, 00002.93, 01002.93 ], Gyr [ -00001.52, 00000.98, -00001.77 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000006862 NoMotion-1. Scaled. Acc [ -00029.30, -00006.84, 00990.23 ], Gyr [ 00000.43, 00001.52, -00002.07 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006863 NoMotion-1. Scaled. Acc [ -00056.15, -00004.39, 00994.14 ], Gyr [ 00001.28, 00001.52, 00001.04 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006864 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00991.70 ], Gyr [ 00000.18, 00000.06, -00001.34 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006865 NoMotion-1. Scaled. Acc [ -00047.36, -00009.77, 00991.70 ], Gyr [ -00000.30, 00001.10, -00003.29 ], Mag [ 00018.30, 00082.95, 00106.65 ]",
    "#0000006866 NoMotion-1. Scaled. Acc [ -00039.55, -00020.51, 00992.19 ], Gyr [ 00000.55, 00001.71, -00004.27 ], Mag [ 00018.30, 00082.95, 00106.65 ]",
    "#0000006867 NoMotion-1. Scaled. Acc [ -00045.41, -00010.25, 01002.93 ], Gyr [ 00000.24, -00000.91, -00004.39 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000006868 NoMotion-1. Scaled. Acc [ -00042.48, -00005.86, 00982.91 ], Gyr [ 00000.00, 00000.18, 00000.18 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000006869 NoMotion-1. Scaled. Acc [ -00042.97, -00012.21, 00990.72 ], Gyr [ 00000.73, 00003.05, -00002.20 ], Mag [ 00019.20, 00084.30, 00108.30 ]",
    "#0000006870 NoMotion-1. Scaled. Acc [ -00042.48, -00002.44, 00986.33 ], Gyr [ -00001.34, 00002.80, -00003.54 ], Mag [ 00019.20, 00084.30, 00108.30 ]",
    "#0000006871 NoMotion-1. Scaled. Acc [ -00051.27, -00001.95, 01005.37 ], Gyr [ -00000.06, 00000.12, -00001.83 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006872 NoMotion-1. Scaled. Acc [ -00045.90, 00003.42, 00998.54 ], Gyr [ 00002.62, 00000.79, -00001.16 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006873 NoMotion-1. Scaled. Acc [ -00045.41, -00014.16, 00983.89 ], Gyr [ -00001.46, -00001.10, -00004.21 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006874 NoMotion-1. Scaled. Acc [ -00037.11, -00020.51, 00993.65 ], Gyr [ 00000.73, 00000.67, 00001.16 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006875 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00985.35 ], Gyr [ 00001.52, -00000.12, -00001.46 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006876 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 01002.93 ], Gyr [ 00001.52, 00000.24, -00000.43 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000006877 NoMotion-1. Scaled. Acc [ -00041.02, -00005.86, 00984.38 ], Gyr [ -00000.30, 00000.43, 00000.43 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000006878 NoMotion-1. Scaled. Acc [ -00045.41, 00007.32, 00977.54 ], Gyr [ -00000.79, 00000.24, -00002.20 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006879 NoMotion-1. Scaled. Acc [ -00044.92, -00007.81, 00985.35 ], Gyr [ 00001.77, 00000.73, -00002.62 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006880 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00990.72 ], Gyr [ -00000.06, 00001.16, -00000.49 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006881 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00981.93 ], Gyr [ -00000.37, 00001.71, -00003.54 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006882 NoMotion-1. Scaled. Acc [ -00038.09, -00006.84, 00980.47 ], Gyr [ 00002.93, -00001.04, 00001.34 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006883 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00986.82 ], Gyr [ 00000.49, 00002.26, -00001.59 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006884 NoMotion-1. Scaled. Acc [ -00041.50, -00017.58, 00983.40 ], Gyr [ 00000.43, 00000.49, 00000.00 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006885 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00986.82 ], Gyr [ 00000.91, 00000.37, 00001.16 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006886 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 01005.37 ], Gyr [ 00001.04, 00002.44, 00001.89 ], Mag [ 00021.15, 00084.15, 00109.50 ]",
    "#0000006887 NoMotion-1. Scaled. Acc [ -00035.16, -00007.81, 00996.58 ], Gyr [ 00000.49, 00002.20, 00000.30 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006888 NoMotion-1. Scaled. Acc [ -00049.32, -00014.65, 00993.65 ], Gyr [ 00001.52, 00002.01, -00001.46 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006889 NoMotion-1. Scaled. Acc [ -00044.43, -00020.02, 01002.44 ], Gyr [ 00003.17, 00003.17, 00000.30 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006890 NoMotion-1. Scaled. Acc [ -00031.25, -00004.88, 00986.82 ], Gyr [ 00000.91, 00001.04, -00002.93 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006891 NoMotion-1. Scaled. Acc [ -00050.78, -00018.07, 00982.91 ], Gyr [ 00001.77, 00000.79, 00002.01 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006892 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00992.19 ], Gyr [ -00000.91, 00002.07, -00002.50 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000006893 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00989.75 ], Gyr [ -00000.55, 00003.29, -00002.07 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000006894 NoMotion-1. Scaled. Acc [ -00051.27, -00003.42, 00988.77 ], Gyr [ 00001.16, 00000.91, -00003.11 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000006895 NoMotion-1. Scaled. Acc [ -00051.76, -00003.42, 00989.26 ], Gyr [ 00000.06, 00001.16, -00000.43 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000006896 NoMotion-1. Scaled. Acc [ -00038.09, -00008.30, 00997.56 ], Gyr [ -00001.04, 00001.04, -00002.80 ], Mag [ 00018.45, 00084.00, 00108.30 ]",
    "#0000006897 NoMotion-1. Scaled. Acc [ -00039.06, 00001.46, 01000.49 ], Gyr [ 00001.34, -00000.18, -00001.10 ], Mag [ 00018.45, 00084.00, 00108.30 ]",
    "#0000006898 NoMotion-1. Scaled. Acc [ -00053.71, -00020.02, 00989.26 ], Gyr [ 00000.98, 00001.04, -00002.01 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006899 NoMotion-1. Scaled. Acc [ -00041.99, -00015.14, 00999.51 ], Gyr [ 00000.06, 00001.16, -00002.62 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006900 NoMotion-1. Scaled. Acc [ -00050.78, 00001.46, 00995.61 ], Gyr [ -00000.98, 00002.93, -00000.43 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006901 NoMotion-1. Scaled. Acc [ -00035.64, -00003.42, 00986.33 ], Gyr [ -00000.43, 00001.59, -00001.22 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006902 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00998.54 ], Gyr [ -00001.71, 00002.44, -00003.41 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006903 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00988.28 ], Gyr [ 00001.52, 00002.26, -00002.26 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006904 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00991.21 ], Gyr [ -00000.79, 00001.65, 00001.83 ], Mag [ 00019.05, 00082.95, 00106.95 ]",
    "#0000006905 NoMotion-1. Scaled. Acc [ -00038.09, -00016.11, 00998.54 ], Gyr [ -00000.43, 00002.20, -00000.91 ], Mag [ 00019.05, 00082.95, 00106.95 ]",
    "#0000006906 NoMotion-1. Scaled. Acc [ -00040.04, 00001.95, 00997.07 ], Gyr [ 00000.67, 00002.68, 00001.10 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006907 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00990.23 ], Gyr [ 00002.62, 00002.13, -00002.13 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006908 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00982.91 ], Gyr [ 00002.50, -00001.28, 00003.11 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006909 NoMotion-1. Scaled. Acc [ -00043.95, -00012.21, 00979.49 ], Gyr [ 00000.43, 00002.44, -00000.55 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006910 NoMotion-1. Scaled. Acc [ -00044.43, 00000.98, 00988.28 ], Gyr [ 00000.85, 00002.13, -00000.49 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006911 NoMotion-1. Scaled. Acc [ -00031.25, -00012.70, 00989.75 ], Gyr [ 00001.95, 00001.95, -00001.10 ], Mag [ 00019.95, 00082.80, 00106.05 ]",
    "#0000006912 NoMotion-1. Scaled. Acc [ -00045.90, -00018.07, 00993.65 ], Gyr [ 00000.79, 00001.10, -00001.95 ], Mag [ 00019.95, 00082.80, 00106.05 ]",
    "#0000006913 NoMotion-1. Scaled. Acc [ -00042.97, 00004.39, 00983.89 ], Gyr [ -00000.18, 00005.67, -00002.74 ], Mag [ 00020.70, 00083.55, 00106.80 ]",
    "#0000006914 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00996.09 ], Gyr [ 00000.00, 00001.16, -00001.83 ], Mag [ 00020.70, 00083.55, 00106.80 ]",
    "#0000006915 NoMotion-1. Scaled. Acc [ -00044.43, 00003.42, 00990.23 ], Gyr [ -00001.04, 00000.79, -00000.49 ], Mag [ 00018.75, 00085.80, 00106.80 ]",
    "#0000006916 NoMotion-1. Scaled. Acc [ -00034.18, -00002.93, 00991.21 ], Gyr [ 00000.85, -00000.61, -00000.55 ], Mag [ 00018.75, 00085.80, 00106.80 ]",
    "#0000006917 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00989.26 ], Gyr [ -00000.30, 00003.60, -00000.85 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006918 NoMotion-1. Scaled. Acc [ -00053.22, -00010.25, 01003.91 ], Gyr [ -00001.22, -00000.06, -00002.74 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006919 NoMotion-1. Scaled. Acc [ -00038.09, -00001.95, 00981.93 ], Gyr [ 00002.74, 00000.85, -00000.24 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006920 NoMotion-1. Scaled. Acc [ -00035.16, -00010.74, 00995.12 ], Gyr [ 00000.79, -00001.40, -00002.80 ], Mag [ 00019.65, 00084.90, 00108.00 ]",
    "#0000006921 NoMotion-1. Scaled. Acc [ -00042.48, -00011.23, 00994.63 ], Gyr [ -00001.52, 00003.84, -00001.59 ], Mag [ 00019.65, 00084.90, 00108.00 ]",
    "#0000006922 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00993.16 ], Gyr [ 00002.44, -00000.12, -00001.89 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000006923 NoMotion-1. Scaled. Acc [ -00039.55, -00008.30, 00997.07 ], Gyr [ 00000.43, 00002.01, 00002.32 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000006924 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 00990.23 ], Gyr [ 00000.06, 00001.59, -00002.50 ], Mag [ 00018.45, 00085.50, 00107.10 ]",
    "#0000006925 NoMotion-1. Scaled. Acc [ -00043.95, 00003.42, 00991.70 ], Gyr [ 00003.66, 00001.22, 00001.22 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006926 NoMotion-1. Scaled. Acc [ -00041.50, -00015.14, 00988.77 ], Gyr [ -00000.61, 00003.23, -00002.99 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006927 NoMotion-1. Scaled. Acc [ -00043.95, -00010.74, 00993.16 ], Gyr [ -00001.28, 00004.88, -00002.01 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006928 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00993.65 ], Gyr [ 00001.46, -00000.91, -00001.65 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006929 NoMotion-1. Scaled. Acc [ -00041.99, -00013.18, 00989.26 ], Gyr [ -00000.73, 00002.32, -00002.32 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006930 NoMotion-1. Scaled. Acc [ -00041.99, -00004.39, 00992.19 ], Gyr [ -00000.30, 00002.44, -00002.13 ], Mag [ 00019.20, 00083.25, 00108.30 ]",
    "#0000006931 NoMotion-1. Scaled. Acc [ -00055.18, -00002.44, 00982.42 ], Gyr [ 00000.30, 00001.28, -00001.22 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000006932 NoMotion-1. Scaled. Acc [ -00051.27, -00009.28, 00987.30 ], Gyr [ 00001.40, -00000.24, 00001.83 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000006933 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00993.16 ], Gyr [ -00000.12, 00005.00, -00002.44 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006934 NoMotion-1. Scaled. Acc [ -00033.20, -00005.86, 00986.33 ], Gyr [ -00000.06, 00001.83, -00003.90 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006935 NoMotion-1. Scaled. Acc [ -00034.18, -00010.74, 00988.28 ], Gyr [ 00000.30, 00001.28, 00001.95 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006936 NoMotion-1. Scaled. Acc [ -00059.57, -00004.88, 00991.21 ], Gyr [ 00001.59, 00001.89, -00002.93 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006937 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00997.07 ], Gyr [ 00000.73, -00001.22, 00000.24 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006938 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 01000.00 ], Gyr [ -00000.73, 00001.34, -00001.04 ], Mag [ 00018.45, 00084.30, 00109.35 ]",
    "#0000006939 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00981.45 ], Gyr [ 00000.91, 00002.68, 00000.18 ], Mag [ 00018.45, 00084.30, 00109.35 ]",
    "#0000006940 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00988.28 ], Gyr [ 00002.68, 00000.55, -00002.32 ], Mag [ 00019.65, 00083.70, 00107.70 ]",
    "#0000006941 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00992.68 ], Gyr [ -00000.49, -00001.89, -00001.71 ], Mag [ 00019.65, 00083.70, 00107.70 ]",
    "#0000006942 NoMotion-1. Scaled. Acc [ -00040.53, -00016.11, 00986.33 ], Gyr [ 00000.43, 00002.20, -00001.28 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000006943 NoMotion-1. Scaled. Acc [ -00050.78, -00006.35, 00991.21 ], Gyr [ 00000.61, 00003.17, -00000.61 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000006944 NoMotion-1. Scaled. Acc [ -00036.62, -00020.51, 00990.23 ], Gyr [ 00002.32, 00002.50, -00001.22 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006945 NoMotion-1. Scaled. Acc [ -00048.83, -00011.72, 00995.61 ], Gyr [ 00000.30, 00002.38, -00002.80 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006946 NoMotion-1. Scaled. Acc [ -00039.55, -00007.81, 00978.52 ], Gyr [ 00000.06, 00000.55, -00001.71 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006947 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00986.82 ], Gyr [ 00000.43, 00001.04, -00002.26 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006948 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00989.26 ], Gyr [ 00001.34, 00001.65, -00000.12 ], Mag [ 00019.65, 00084.45, 00109.80 ]",
    "#0000006949 NoMotion-1. Scaled. Acc [ -00049.32, -00020.51, 01006.84 ], Gyr [ 00001.46, 00001.77, -00002.38 ], Mag [ 00019.65, 00084.45, 00109.80 ]",
    "#0000006950 NoMotion-1. Scaled. Acc [ -00035.64, -00014.16, 01003.42 ], Gyr [ -00000.06, 00001.40, 00000.30 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000006951 NoMotion-1. Scaled. Acc [ -00045.41, -00005.86, 00983.89 ], Gyr [ 00000.85, 00001.52, -00000.67 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000006952 NoMotion-1. Scaled. Acc [ -00039.55, -00009.28, 00993.16 ], Gyr [ -00001.59, -00000.12, -00000.49 ], Mag [ 00020.10, 00083.40, 00106.95 ]",
    "#0000006953 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00980.96 ], Gyr [ 00001.34, 00003.11, -00001.10 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006954 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00989.26 ], Gyr [ 00001.22, 00000.73, -00001.40 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006955 NoMotion-1. Scaled. Acc [ -00044.43, -00003.91, 00985.35 ], Gyr [ 00002.38, 00001.71, -00002.93 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006956 NoMotion-1. Scaled. Acc [ -00042.48, 00000.49, 00987.79 ], Gyr [ 00000.79, 00002.68, -00002.26 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006957 NoMotion-1. Scaled. Acc [ -00033.69, -00004.39, 00991.70 ], Gyr [ 00000.67, -00000.61, -00002.01 ], Mag [ 00017.70, 00084.75, 00108.30 ]",
    "#0000006958 NoMotion-1. Scaled. Acc [ -00043.46, 00001.95, 00978.52 ], Gyr [ 00002.07, -00000.43, 00000.49 ], Mag [ 00017.70, 00084.75, 00108.30 ]",
    "#0000006959 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00995.12 ], Gyr [ -00001.04, 00001.46, -00000.06 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000006960 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00990.23 ], Gyr [ -00000.18, 00001.77, -00002.01 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000006961 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00991.21 ], Gyr [ 00002.38, -00001.59, -00001.89 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000006962 NoMotion-1. Scaled. Acc [ -00032.23, -00019.53, 00987.79 ], Gyr [ 00000.00, 00001.46, -00000.67 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000006963 NoMotion-1. Scaled. Acc [ -00047.36, -00017.58, 00985.84 ], Gyr [ 00002.13, 00002.07, -00001.22 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006964 NoMotion-1. Scaled. Acc [ -00049.80, -00006.35, 00980.96 ], Gyr [ -00001.04, 00000.00, 00000.30 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006965 NoMotion-1. Scaled. Acc [ -00028.32, 00000.49, 00990.72 ], Gyr [ -00000.06, 00001.22, -00001.95 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006966 NoMotion-1. Scaled. Acc [ -00043.46, 00000.00, 00987.79 ], Gyr [ 00001.59, -00000.06, -00001.52 ], Mag [ 00018.75, 00084.75, 00106.50 ]",
    "#0000006967 NoMotion-1. Scaled. Acc [ -00054.69, -00014.16, 00995.12 ], Gyr [ 00000.55, 00001.40, -00002.68 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000006968 NoMotion-1. Scaled. Acc [ -00047.85, -00011.72, 00989.26 ], Gyr [ -00001.28, -00000.12, -00003.48 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000006969 NoMotion-1. Scaled. Acc [ -00038.57, -00001.95, 00986.33 ], Gyr [ 00001.22, 00000.12, -00002.99 ], Mag [ 00019.95, 00084.75, 00107.55 ]",
    "#0000006970 NoMotion-1. Scaled. Acc [ -00051.27, -00001.95, 00992.68 ], Gyr [ 00001.59, 00000.61, 00000.55 ], Mag [ 00019.95, 00084.75, 00107.55 ]",
    "#0000006971 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00998.54 ], Gyr [ 00000.00, 00001.10, -00001.04 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006972 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00991.21 ], Gyr [ 00001.04, 00000.43, 00000.30 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006973 NoMotion-1. Scaled. Acc [ -00051.27, -00001.46, 00986.33 ], Gyr [ -00000.30, -00000.24, -00001.16 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006974 NoMotion-1. Scaled. Acc [ -00036.62, -00008.79, 00995.12 ], Gyr [ 00000.61, -00001.71, -00001.59 ], Mag [ 00021.00, 00084.75, 00107.55 ]",
    "#0000006975 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00989.26 ], Gyr [ -00000.61, 00000.06, 00000.00 ], Mag [ 00021.00, 00084.75, 00107.55 ]",
    "#0000006976 NoMotion-1. Scaled. Acc [ -00048.34, -00003.91, 00998.05 ], Gyr [ -00000.06, 00001.46, -00003.35 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000006977 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00986.33 ], Gyr [ 00000.18, 00001.95, -00002.80 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000006978 NoMotion-1. Scaled. Acc [ -00034.67, -00003.91, 00996.58 ], Gyr [ 00000.06, 00001.95, -00000.18 ], Mag [ 00020.25, 00084.30, 00107.10 ]",
    "#0000006979 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00995.61 ], Gyr [ -00001.71, 00001.59, -00002.38 ], Mag [ 00020.25, 00084.30, 00107.10 ]",
    "#0000006980 NoMotion-1. Scaled. Acc [ -00059.57, 00000.49, 00984.38 ], Gyr [ 00000.43, 00002.44, -00002.99 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006981 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00976.07 ], Gyr [ -00000.30, 00001.89, -00004.76 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006982 NoMotion-1. Scaled. Acc [ -00041.02, -00010.25, 00981.45 ], Gyr [ 00002.01, -00000.43, -00000.98 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006983 NoMotion-1. Scaled. Acc [ -00054.69, -00016.11, 00992.19 ], Gyr [ 00000.12, 00000.37, -00000.73 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006984 NoMotion-1. Scaled. Acc [ -00036.62, -00020.02, 00979.00 ], Gyr [ -00000.49, -00000.24, -00004.82 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000006985 NoMotion-1. Scaled. Acc [ -00035.16, -00008.79, 00985.35 ], Gyr [ 00000.37, 00000.37, 00000.06 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000006986 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00990.72 ], Gyr [ -00000.12, -00000.61, -00002.68 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000006987 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00991.70 ], Gyr [ 00001.46, 00002.44, -00001.52 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000006988 NoMotion-1. Scaled. Acc [ -00041.02, 00003.42, 00991.70 ], Gyr [ -00000.43, -00000.30, -00000.37 ], Mag [ 00020.70, 00083.55, 00107.10 ]",
    "#0000006989 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 00979.00 ], Gyr [ -00001.34, 00003.23, -00003.78 ], Mag [ 00020.70, 00083.55, 00107.10 ]",
    "#0000006990 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00997.56 ], Gyr [ -00000.18, 00001.83, -00000.18 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006991 NoMotion-1. Scaled. Acc [ -00040.53, -00000.98, 00987.79 ], Gyr [ 00001.16, -00000.24, -00001.77 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006992 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00983.40 ], Gyr [ 00001.28, 00002.62, -00001.34 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006993 NoMotion-1. Scaled. Acc [ -00048.34, -00008.79, 00983.40 ], Gyr [ 00001.04, 00002.44, -00000.73 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000006994 NoMotion-1. Scaled. Acc [ -00041.02, -00006.35, 00987.30 ], Gyr [ -00000.43, 00001.40, -00002.68 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000006995 NoMotion-1. Scaled. Acc [ -00052.25, -00000.98, 00997.56 ], Gyr [ -00000.24, 00001.40, -00002.74 ], Mag [ 00019.35, 00084.15, 00105.90 ]",
    "#0000006996 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00985.84 ], Gyr [ 00001.95, -00000.37, -00000.73 ], Mag [ 00019.35, 00084.15, 00105.90 ]",
    "#0000006997 NoMotion-1. Scaled. Acc [ -00048.34, -00006.35, 00992.68 ], Gyr [ 00000.79, 00000.12, -00001.40 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000006998 NoMotion-1. Scaled. Acc [ -00046.39, -00003.91, 00995.12 ], Gyr [ 00000.85, -00001.10, -00003.17 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000006999 NoMotion-1. Scaled. Acc [ -00038.57, -00022.46, 00998.05 ], Gyr [ -00000.24, 00000.00, 00000.18 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007000 NoMotion-1. Scaled. Acc [ -00048.83, -00012.70, 00988.77 ], Gyr [ 00001.04, 00000.79, -00001.28 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007001 NoMotion-1. Scaled. Acc [ -00059.08, -00010.74, 00991.21 ], Gyr [ 00001.16, -00000.49, -00004.27 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007002 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00988.77 ], Gyr [ 00000.49, 00001.40, -00001.34 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007003 NoMotion-1. Scaled. Acc [ -00040.53, -00010.74, 00983.89 ], Gyr [ 00000.55, 00000.98, -00002.38 ], Mag [ 00021.75, 00084.75, 00108.60 ]",
    "#0000007004 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00992.68 ], Gyr [ 00001.46, 00000.91, -00000.37 ], Mag [ 00021.75, 00084.75, 00108.60 ]",
    "#0000007005 NoMotion-1. Scaled. Acc [ -00045.41, -00011.23, 00981.45 ], Gyr [ 00001.28, -00000.98, 00001.28 ], Mag [ 00019.05, 00082.95, 00107.40 ]",
    "#0000007006 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00991.70 ], Gyr [ -00000.85, 00001.95, -00000.18 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000007007 NoMotion-1. Scaled. Acc [ -00037.60, -00021.00, 00987.30 ], Gyr [ -00002.20, 00004.27, -00004.39 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000007008 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 00993.65 ], Gyr [ 00001.40, 00002.44, -00001.65 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007009 NoMotion-1. Scaled. Acc [ -00059.08, -00000.49, 00994.14 ], Gyr [ 00000.37, 00001.04, -00001.16 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007010 NoMotion-1. Scaled. Acc [ -00057.13, -00012.70, 00984.38 ], Gyr [ 00001.04, -00000.37, 00000.30 ], Mag [ 00019.35, 00082.65, 00106.20 ]",
    "#0000007011 NoMotion-1. Scaled. Acc [ -00033.69, 00006.35, 00988.28 ], Gyr [ 00001.59, -00001.83, -00003.66 ], Mag [ 00019.35, 00082.65, 00106.20 ]",
    "#0000007012 NoMotion-1. Scaled. Acc [ -00041.99, -00007.32, 00984.86 ], Gyr [ -00001.16, 00003.54, -00002.01 ], Mag [ 00018.15, 00084.00, 00107.55 ]",
    "#0000007013 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00996.58 ], Gyr [ -00001.89, 00001.34, 00000.37 ], Mag [ 00018.15, 00084.00, 00107.55 ]",
    "#0000007014 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00995.12 ], Gyr [ 00000.91, 00002.80, -00001.89 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000007015 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.77 ], Gyr [ 00000.37, 00001.34, -00002.68 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000007016 NoMotion-1. Scaled. Acc [ -00046.88, -00012.21, 00985.35 ], Gyr [ 00000.18, 00000.85, 00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007017 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 01000.49 ], Gyr [ 00002.80, -00000.91, -00003.60 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007018 NoMotion-1. Scaled. Acc [ -00036.62, -00005.86, 01000.98 ], Gyr [ 00002.07, -00001.34, -00000.79 ], Mag [ 00018.75, 00084.30, 00106.80 ]",
    "#0000007019 NoMotion-1. Scaled. Acc [ -00040.53, -00008.79, 00986.82 ], Gyr [ 00001.40, 00000.55, 00000.18 ], Mag [ 00018.75, 00084.30, 00106.80 ]",
    "#0000007020 NoMotion-1. Scaled. Acc [ -00053.22, -00012.70, 00999.02 ], Gyr [ 00001.71, 00004.33, -00001.16 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007021 NoMotion-1. Scaled. Acc [ -00038.57, -00026.86, 00987.30 ], Gyr [ 00001.04, 00000.00, 00000.79 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007022 NoMotion-1. Scaled. Acc [ -00032.23, 00000.00, 00991.70 ], Gyr [ 00000.73, -00001.10, -00001.89 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007023 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00984.38 ], Gyr [ 00000.49, 00001.40, -00001.40 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007024 NoMotion-1. Scaled. Acc [ -00036.62, 00000.98, 00989.26 ], Gyr [ 00001.46, 00004.51, -00001.34 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007025 NoMotion-1. Scaled. Acc [ -00036.62, -00007.32, 00993.16 ], Gyr [ 00001.04, 00001.83, 00000.43 ], Mag [ 00021.15, 00083.70, 00107.70 ]",
    "#0000007026 NoMotion-1. Scaled. Acc [ -00042.48, 00009.77, 00989.75 ], Gyr [ -00000.06, 00001.89, -00002.56 ], Mag [ 00021.15, 00083.70, 00107.70 ]",
    "#0000007027 NoMotion-1. Scaled. Acc [ -00050.78, 00000.49, 00999.51 ], Gyr [ -00000.98, -00000.49, -00002.07 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000007028 NoMotion-1. Scaled. Acc [ -00041.99, -00002.93, 01000.98 ], Gyr [ -00000.18, 00004.70, 00000.37 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000007029 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 01000.49 ], Gyr [ 00001.95, 00001.65, -00000.79 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007030 NoMotion-1. Scaled. Acc [ -00038.09, 00000.00, 00993.16 ], Gyr [ -00000.85, 00000.30, -00000.85 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007031 NoMotion-1. Scaled. Acc [ -00049.32, 00000.00, 00990.23 ], Gyr [ -00001.04, -00000.49, -00003.23 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007032 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00987.79 ], Gyr [ 00000.85, 00000.00, -00000.73 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007033 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 00990.23 ], Gyr [ 00000.91, -00000.73, -00000.06 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007034 NoMotion-1. Scaled. Acc [ -00042.48, -00016.60, 00999.51 ], Gyr [ 00002.56, 00000.91, -00000.18 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007035 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 00987.79 ], Gyr [ 00001.89, 00002.93, -00003.90 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007036 NoMotion-1. Scaled. Acc [ -00041.50, -00016.11, 00993.16 ], Gyr [ -00000.12, 00000.37, 00000.91 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007037 NoMotion-1. Scaled. Acc [ -00050.29, -00006.84, 00991.70 ], Gyr [ 00002.44, 00000.30, -00003.11 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007038 NoMotion-1. Scaled. Acc [ -00037.11, -00014.16, 00982.91 ], Gyr [ 00001.16, 00002.38, -00000.12 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007039 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00988.28 ], Gyr [ -00001.28, 00002.07, -00002.26 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007040 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00988.28 ], Gyr [ -00000.43, 00000.18, -00000.67 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007041 NoMotion-1. Scaled. Acc [ -00048.83, 00007.32, 01000.49 ], Gyr [ 00001.04, 00000.91, -00001.34 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007042 NoMotion-1. Scaled. Acc [ -00047.85, 00002.93, 00991.70 ], Gyr [ 00000.73, 00002.32, -00002.13 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007043 NoMotion-1. Scaled. Acc [ -00042.48, -00007.81, 01000.49 ], Gyr [ -00001.40, 00001.83, -00002.07 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000007044 NoMotion-1. Scaled. Acc [ -00052.73, 00002.44, 00985.84 ], Gyr [ -00000.24, 00002.87, -00004.39 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000007045 NoMotion-1. Scaled. Acc [ -00044.92, -00015.14, 00993.16 ], Gyr [ 00001.16, -00000.37, -00001.34 ], Mag [ 00018.45, 00083.25, 00107.55 ]",
    "#0000007046 NoMotion-1. Scaled. Acc [ -00037.11, -00003.91, 00988.28 ], Gyr [ 00000.91, 00002.32, -00001.77 ], Mag [ 00018.45, 00083.25, 00107.55 ]",
    "#0000007047 NoMotion-1. Scaled. Acc [ -00047.85, 00002.44, 00999.51 ], Gyr [ 00000.18, 00001.83, -00003.05 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007048 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00987.30 ], Gyr [ -00000.73, 00002.07, -00001.40 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007049 NoMotion-1. Scaled. Acc [ -00045.90, -00005.86, 00994.14 ], Gyr [ -00000.06, 00001.89, -00000.85 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007050 NoMotion-1. Scaled. Acc [ -00036.62, -00007.32, 00990.72 ], Gyr [ 00000.00, 00000.37, 00000.30 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007051 NoMotion-1. Scaled. Acc [ -00053.22, 00008.30, 01000.98 ], Gyr [ 00000.55, 00002.68, -00000.43 ], Mag [ 00018.45, 00083.55, 00107.85 ]",
    "#0000007052 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00985.35 ], Gyr [ -00000.67, 00000.73, -00000.12 ], Mag [ 00018.45, 00083.55, 00107.85 ]",
    "#0000007053 NoMotion-1. Scaled. Acc [ -00041.99, -00001.95, 01003.42 ], Gyr [ -00000.55, 00001.10, -00001.95 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000007054 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00987.30 ], Gyr [ -00000.06, 00001.10, -00000.06 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000007055 NoMotion-1. Scaled. Acc [ -00043.46, -00013.18, 00996.58 ], Gyr [ 00001.89, 00001.28, -00001.04 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000007056 NoMotion-1. Scaled. Acc [ -00043.46, -00001.46, 00985.35 ], Gyr [ 00001.52, 00000.55, -00003.41 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000007057 NoMotion-1. Scaled. Acc [ -00052.25, -00008.30, 00990.23 ], Gyr [ 00000.91, -00000.67, 00001.16 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007058 NoMotion-1. Scaled. Acc [ -00051.27, -00004.88, 00984.38 ], Gyr [ 00000.24, 00000.55, 00000.91 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007059 NoMotion-1. Scaled. Acc [ -00037.60, -00011.72, 00988.77 ], Gyr [ 00000.30, 00001.28, -00001.40 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007060 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00994.14 ], Gyr [ -00000.06, 00001.95, -00000.43 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007061 NoMotion-1. Scaled. Acc [ -00048.34, -00018.07, 00991.70 ], Gyr [ 00003.05, 00002.87, -00000.91 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000007062 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00986.82 ], Gyr [ 00000.37, 00002.32, -00001.52 ], Mag [ 00019.95, 00083.55, 00108.60 ]",
    "#0000007063 NoMotion-1. Scaled. Acc [ -00032.23, -00011.72, 00993.16 ], Gyr [ 00000.55, 00002.50, -00000.06 ], Mag [ 00019.95, 00083.55, 00108.60 ]",
    "#0000007064 NoMotion-1. Scaled. Acc [ -00054.20, -00021.97, 00986.82 ], Gyr [ 00000.30, 00000.79, -00002.13 ], Mag [ 00020.85, 00081.15, 00108.00 ]",
    "#0000007065 NoMotion-1. Scaled. Acc [ -00057.13, -00002.93, 00991.21 ], Gyr [ -00000.67, 00001.83, -00001.22 ], Mag [ 00020.85, 00081.15, 00108.00 ]",
    "#0000007066 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00991.21 ], Gyr [ 00000.12, 00000.85, -00002.26 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007067 NoMotion-1. Scaled. Acc [ -00046.39, -00000.98, 00998.54 ], Gyr [ 00000.12, 00002.93, -00001.40 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007068 NoMotion-1. Scaled. Acc [ -00038.57, -00006.35, 00998.54 ], Gyr [ 00000.73, 00000.85, -00000.43 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007069 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00997.56 ], Gyr [ 00000.18, 00003.29, -00002.68 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007070 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00998.05 ], Gyr [ 00000.79, 00003.78, -00000.06 ], Mag [ 00020.70, 00083.55, 00107.55 ]",
    "#0000007071 NoMotion-1. Scaled. Acc [ -00039.06, -00012.21, 00989.75 ], Gyr [ 00000.43, 00000.73, 00000.00 ], Mag [ 00020.70, 00083.55, 00107.55 ]",
    "#0000007072 NoMotion-1. Scaled. Acc [ -00050.78, -00012.70, 00984.38 ], Gyr [ 00000.91, 00003.17, 00001.34 ], Mag [ 00018.60, 00082.20, 00108.00 ]",
    "#0000007073 NoMotion-1. Scaled. Acc [ -00045.41, -00007.81, 00989.75 ], Gyr [ 00000.30, 00001.89, -00002.20 ], Mag [ 00018.60, 00082.20, 00108.00 ]",
    "#0000007074 NoMotion-1. Scaled. Acc [ -00043.46, -00024.90, 01000.00 ], Gyr [ 00001.40, 00000.67, -00002.32 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007075 NoMotion-1. Scaled. Acc [ -00031.74, 00000.98, 00987.79 ], Gyr [ 00000.24, -00000.85, 00000.91 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007076 NoMotion-1. Scaled. Acc [ -00041.50, -00008.30, 00991.70 ], Gyr [ -00000.55, 00002.50, -00001.46 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007077 NoMotion-1. Scaled. Acc [ -00035.16, 00001.46, 00995.61 ], Gyr [ 00002.68, 00002.50, -00000.55 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007078 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00989.26 ], Gyr [ 00000.43, 00002.68, -00003.84 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007079 NoMotion-1. Scaled. Acc [ -00039.06, -00002.93, 00988.77 ], Gyr [ -00000.37, 00000.61, -00000.91 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007080 NoMotion-1. Scaled. Acc [ -00048.83, -00008.30, 00999.51 ], Gyr [ 00000.43, 00001.22, -00000.55 ], Mag [ 00018.60, 00082.65, 00108.00 ]",
    "#0000007081 NoMotion-1. Scaled. Acc [ -00048.83, -00006.84, 00993.65 ], Gyr [ 00000.00, 00000.00, -00001.52 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007082 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00989.26 ], Gyr [ -00001.77, 00001.40, -00002.62 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007083 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00997.07 ], Gyr [ 00000.73, 00001.16, -00000.55 ], Mag [ 00018.60, 00084.15, 00108.00 ]",
    "#0000007084 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00984.86 ], Gyr [ -00000.98, 00000.43, -00003.29 ], Mag [ 00018.60, 00084.15, 00108.00 ]",
    "#0000007085 NoMotion-1. Scaled. Acc [ -00046.88, -00007.81, 00988.28 ], Gyr [ 00001.46, 00000.85, -00000.73 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007086 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00987.30 ], Gyr [ -00001.10, 00001.59, 00000.00 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007087 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00990.23 ], Gyr [ 00000.18, 00000.06, 00000.00 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007088 NoMotion-1. Scaled. Acc [ -00033.69, -00024.90, 00988.77 ], Gyr [ 00000.06, 00001.40, -00002.80 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007089 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 00984.86 ], Gyr [ 00000.79, 00004.02, -00001.46 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007090 NoMotion-1. Scaled. Acc [ -00044.43, -00009.28, 01004.88 ], Gyr [ 00000.00, 00001.10, -00001.28 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007091 NoMotion-1. Scaled. Acc [ -00052.25, -00007.32, 00990.72 ], Gyr [ 00001.83, 00001.16, -00001.95 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007092 NoMotion-1. Scaled. Acc [ -00041.50, -00010.74, 00995.61 ], Gyr [ -00001.10, 00001.71, -00000.06 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007093 NoMotion-1. Scaled. Acc [ -00041.02, -00008.30, 00983.40 ], Gyr [ 00000.49, 00005.37, 00000.49 ], Mag [ 00019.95, 00084.30, 00106.50 ]",
    "#0000007094 NoMotion-1. Scaled. Acc [ -00044.43, -00012.70, 00992.19 ], Gyr [ -00000.12, 00003.72, -00002.80 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007095 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00993.16 ], Gyr [ 00002.01, 00000.79, -00000.67 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007096 NoMotion-1. Scaled. Acc [ -00038.57, -00020.02, 00986.82 ], Gyr [ 00001.46, 00000.00, -00000.73 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007097 NoMotion-1. Scaled. Acc [ -00049.80, -00004.39, 00996.58 ], Gyr [ -00000.30, 00001.95, -00002.44 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007098 NoMotion-1. Scaled. Acc [ -00039.06, -00014.16, 00995.61 ], Gyr [ 00000.73, 00002.99, -00000.98 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007099 NoMotion-1. Scaled. Acc [ -00051.76, -00002.44, 00990.72 ], Gyr [ 00000.67, 00002.32, -00000.37 ], Mag [ 00018.30, 00083.70, 00106.95 ]",
    "#0000007100 NoMotion-1. Scaled. Acc [ -00051.27, -00000.98, 00984.86 ], Gyr [ 00001.22, 00000.85, -00002.44 ], Mag [ 00018.30, 00083.70, 00106.95 ]",
    "#0000007101 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00995.12 ], Gyr [ 00001.59, 00000.98, -00001.65 ], Mag [ 00018.60, 00083.40, 00105.90 ]",
    "#0000007102 NoMotion-1. Scaled. Acc [ -00032.71, -00000.98, 00986.82 ], Gyr [ -00000.91, 00002.20, -00002.32 ], Mag [ 00018.60, 00083.40, 00105.90 ]",
    "#0000007103 NoMotion-1. Scaled. Acc [ -00054.20, -00009.77, 00982.91 ], Gyr [ 00000.67, -00000.49, -00001.34 ], Mag [ 00021.15, 00084.15, 00108.00 ]",
    "#0000007104 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00992.19 ], Gyr [ 00000.24, 00003.35, -00002.87 ], Mag [ 00021.15, 00084.15, 00108.00 ]",
    "#0000007105 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00985.84 ], Gyr [ -00000.18, 00000.37, -00002.93 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007106 NoMotion-1. Scaled. Acc [ -00045.41, -00012.21, 00991.70 ], Gyr [ -00000.98, 00001.77, -00001.34 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007107 NoMotion-1. Scaled. Acc [ -00046.88, -00002.93, 00987.30 ], Gyr [ -00000.18, 00001.77, 00000.79 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007108 NoMotion-1. Scaled. Acc [ -00041.50, -00005.37, 00988.77 ], Gyr [ 00000.79, 00001.16, -00000.91 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007109 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00988.28 ], Gyr [ -00001.89, 00001.95, -00003.05 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007110 NoMotion-1. Scaled. Acc [ -00037.60, -00012.21, 00998.05 ], Gyr [ 00002.01, -00000.85, -00000.49 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007111 NoMotion-1. Scaled. Acc [ -00046.39, -00011.72, 01001.95 ], Gyr [ 00000.91, -00001.16, 00000.24 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007112 NoMotion-1. Scaled. Acc [ -00043.46, -00009.28, 00985.35 ], Gyr [ 00001.71, 00001.10, -00002.20 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007113 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00994.14 ], Gyr [ -00000.67, -00001.22, -00000.43 ], Mag [ 00018.75, 00083.55, 00105.75 ]",
    "#0000007114 NoMotion-1. Scaled. Acc [ -00032.71, -00006.35, 00988.28 ], Gyr [ 00001.10, 00002.93, -00002.32 ], Mag [ 00018.75, 00083.55, 00105.75 ]",
    "#0000007115 NoMotion-1. Scaled. Acc [ -00029.30, -00021.48, 00977.05 ], Gyr [ 00001.59, -00001.46, -00001.59 ], Mag [ 00020.40, 00085.20, 00106.65 ]",
    "#0000007116 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00990.72 ], Gyr [ 00000.43, 00002.68, 00000.61 ], Mag [ 00020.40, 00085.20, 00106.65 ]",
    "#0000007117 NoMotion-1. Scaled. Acc [ -00035.64, -00011.23, 00985.35 ], Gyr [ -00000.24, 00002.68, -00002.93 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000007118 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00982.42 ], Gyr [ 00000.85, -00000.67, -00001.59 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000007119 NoMotion-1. Scaled. Acc [ -00049.32, -00003.42, 00986.82 ], Gyr [ 00000.91, -00000.43, -00001.10 ], Mag [ 00020.10, 00084.45, 00106.65 ]",
    "#0000007120 NoMotion-1. Scaled. Acc [ -00045.90, -00017.09, 00995.61 ], Gyr [ -00000.06, 00002.01, -00002.20 ], Mag [ 00020.10, 00084.45, 00106.65 ]",
    "#0000007121 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00999.51 ], Gyr [ -00001.22, -00000.91, -00000.37 ], Mag [ 00020.85, 00082.95, 00107.40 ]",
    "#0000007122 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00986.82 ], Gyr [ 00002.74, 00000.91, -00001.59 ], Mag [ 00020.85, 00082.95, 00107.40 ]",
    "#0000007123 NoMotion-1. Scaled. Acc [ -00047.85, -00010.74, 00998.05 ], Gyr [ -00000.30, 00000.55, 00000.30 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007124 NoMotion-1. Scaled. Acc [ -00048.83, -00017.58, 00998.54 ], Gyr [ 00001.89, 00000.00, 00001.46 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007125 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 01006.84 ], Gyr [ -00001.52, 00002.01, -00002.93 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007126 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 01000.49 ], Gyr [ 00001.34, 00000.37, -00001.04 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007127 NoMotion-1. Scaled. Acc [ -00036.62, -00008.79, 00991.21 ], Gyr [ 00000.30, 00000.55, -00001.40 ], Mag [ 00020.10, 00085.20, 00108.00 ]",
    "#0000007128 NoMotion-1. Scaled. Acc [ -00040.04, -00013.18, 00991.21 ], Gyr [ -00000.49, 00001.71, -00000.55 ], Mag [ 00020.10, 00085.20, 00108.00 ]",
    "#0000007129 NoMotion-1. Scaled. Acc [ -00035.64, -00006.84, 00987.79 ], Gyr [ 00000.06, 00000.06, -00002.20 ], Mag [ 00018.60, 00083.40, 00109.05 ]",
    "#0000007130 NoMotion-1. Scaled. Acc [ -00030.27, 00000.98, 00990.72 ], Gyr [ 00001.40, 00000.55, -00002.13 ], Mag [ 00018.60, 00083.40, 00109.05 ]",
    "#0000007131 NoMotion-1. Scaled. Acc [ -00038.09, -00022.46, 00991.21 ], Gyr [ 00001.52, 00003.05, -00002.38 ], Mag [ 00020.10, 00085.20, 00108.45 ]",
    "#0000007132 NoMotion-1. Scaled. Acc [ -00040.53, -00002.44, 00990.72 ], Gyr [ 00000.85, 00001.65, -00002.38 ], Mag [ 00020.10, 00085.20, 00108.45 ]",
    "#0000007133 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.28 ], Gyr [ -00000.91, 00002.01, 00000.12 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007134 NoMotion-1. Scaled. Acc [ -00048.34, 00001.46, 01001.46 ], Gyr [ 00001.16, 00001.22, -00002.68 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007135 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00993.16 ], Gyr [ 00002.26, 00002.87, -00000.55 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007136 NoMotion-1. Scaled. Acc [ -00047.85, -00017.58, 00989.75 ], Gyr [ -00000.06, -00001.34, -00000.91 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007137 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 00988.77 ], Gyr [ 00002.01, 00001.65, -00000.98 ], Mag [ 00020.70, 00084.30, 00106.80 ]",
    "#0000007138 NoMotion-1. Scaled. Acc [ -00047.85, -00015.62, 00991.21 ], Gyr [ 00002.20, 00000.98, -00002.44 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007139 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 01001.95 ], Gyr [ -00001.16, 00000.55, -00002.50 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007140 NoMotion-1. Scaled. Acc [ -00040.53, -00003.91, 00990.23 ], Gyr [ 00000.91, 00000.98, -00001.65 ], Mag [ 00018.75, 00085.80, 00108.30 ]",
    "#0000007141 NoMotion-1. Scaled. Acc [ -00043.46, -00005.86, 00996.09 ], Gyr [ 00000.91, 00002.62, -00002.44 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007142 NoMotion-1. Scaled. Acc [ -00055.18, -00003.91, 00994.14 ], Gyr [ 00000.98, 00000.98, 00000.24 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007143 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00994.14 ], Gyr [ 00000.91, -00001.04, -00001.34 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007144 NoMotion-1. Scaled. Acc [ -00045.41, -00012.70, 00995.61 ], Gyr [ -00000.67, 00001.34, -00002.01 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007145 NoMotion-1. Scaled. Acc [ -00052.25, -00005.86, 00986.33 ], Gyr [ 00000.79, 00001.95, -00000.61 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007146 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 00981.45 ], Gyr [ -00000.06, 00000.43, -00000.55 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007147 NoMotion-1. Scaled. Acc [ -00045.41, -00009.28, 00983.89 ], Gyr [ 00002.87, 00001.34, -00003.05 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007148 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 01006.35 ], Gyr [ 00001.59, 00000.49, 00000.43 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007149 NoMotion-1. Scaled. Acc [ -00038.57, -00020.02, 00985.84 ], Gyr [ 00000.12, -00001.83, 00000.85 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007150 NoMotion-1. Scaled. Acc [ -00038.57, 00000.98, 00980.47 ], Gyr [ -00001.28, 00000.79, -00000.79 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007151 NoMotion-1. Scaled. Acc [ -00055.18, -00007.32, 00992.19 ], Gyr [ 00000.06, 00000.61, -00002.93 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007152 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 01000.00 ], Gyr [ 00000.18, 00003.96, -00002.07 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007153 NoMotion-1. Scaled. Acc [ -00049.32, -00008.30, 00989.75 ], Gyr [ 00002.01, 00000.43, -00001.10 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000007154 NoMotion-1. Scaled. Acc [ -00050.29, -00013.18, 00989.75 ], Gyr [ -00000.18, 00001.04, -00002.68 ], Mag [ 00019.05, 00085.20, 00107.40 ]",
    "#0000007155 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00988.77 ], Gyr [ 00001.04, 00000.79, -00002.13 ], Mag [ 00019.05, 00085.20, 00107.40 ]",
    "#0000007156 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 01000.00 ], Gyr [ 00001.16, 00001.89, 00000.85 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000007157 NoMotion-1. Scaled. Acc [ -00043.95, 00010.74, 00991.21 ], Gyr [ 00000.30, 00001.10, -00002.38 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000007158 NoMotion-1. Scaled. Acc [ -00045.90, -00002.44, 00995.12 ], Gyr [ -00001.65, 00002.13, -00001.52 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007159 NoMotion-1. Scaled. Acc [ -00029.30, -00009.28, 00986.82 ], Gyr [ 00000.30, 00005.06, -00002.07 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007160 NoMotion-1. Scaled. Acc [ -00037.60, -00016.11, 01002.93 ], Gyr [ 00001.22, -00000.18, -00001.89 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007161 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00992.68 ], Gyr [ 00001.04, 00003.66, -00000.18 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000007162 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00999.51 ], Gyr [ 00000.06, 00001.77, -00000.43 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000007163 NoMotion-1. Scaled. Acc [ -00046.39, 00000.98, 00984.38 ], Gyr [ -00001.71, 00001.16, -00000.73 ], Mag [ 00019.35, 00085.65, 00108.45 ]",
    "#0000007164 NoMotion-1. Scaled. Acc [ -00050.29, -00011.23, 00989.26 ], Gyr [ 00000.55, 00002.56, -00001.71 ], Mag [ 00019.35, 00085.65, 00108.45 ]",
    "#0000007165 NoMotion-1. Scaled. Acc [ -00029.30, -00013.18, 00993.65 ], Gyr [ 00001.04, 00001.46, -00003.48 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000007166 NoMotion-1. Scaled. Acc [ -00034.67, 00004.88, 00987.30 ], Gyr [ -00000.24, -00000.98, -00001.10 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000007167 NoMotion-1. Scaled. Acc [ -00037.60, -00001.46, 00992.19 ], Gyr [ -00000.73, 00001.04, -00000.30 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007168 NoMotion-1. Scaled. Acc [ -00040.53, -00007.32, 00991.70 ], Gyr [ -00000.85, 00001.77, -00001.22 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007169 NoMotion-1. Scaled. Acc [ -00052.73, -00017.58, 00990.72 ], Gyr [ 00000.12, 00001.52, -00001.16 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007170 NoMotion-1. Scaled. Acc [ -00042.97, -00006.84, 00989.26 ], Gyr [ 00001.04, 00000.49, 00000.12 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007171 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00990.72 ], Gyr [ 00001.77, 00000.79, -00001.22 ], Mag [ 00018.60, 00082.65, 00107.70 ]",
    "#0000007172 NoMotion-1. Scaled. Acc [ -00051.76, -00006.35, 00993.16 ], Gyr [ -00000.24, 00003.66, 00000.00 ], Mag [ 00018.60, 00082.65, 00107.70 ]",
    "#0000007173 NoMotion-1. Scaled. Acc [ -00031.25, -00001.46, 00986.82 ], Gyr [ 00000.67, 00000.98, -00002.68 ], Mag [ 00019.35, 00082.65, 00107.70 ]",
    "#0000007174 NoMotion-1. Scaled. Acc [ -00037.11, -00002.44, 00996.58 ], Gyr [ -00001.52, 00002.26, -00000.79 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007175 NoMotion-1. Scaled. Acc [ -00041.02, 00002.44, 00994.63 ], Gyr [ -00001.10, 00005.79, -00003.48 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007176 NoMotion-1. Scaled. Acc [ -00047.85, -00003.42, 00988.28 ], Gyr [ 00000.98, 00002.74, -00002.62 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007177 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00981.93 ], Gyr [ 00002.26, 00002.20, -00001.34 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007178 NoMotion-1. Scaled. Acc [ -00035.64, -00012.70, 00985.84 ], Gyr [ -00000.91, 00003.96, -00003.78 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007179 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00990.72 ], Gyr [ 00001.77, 00001.10, -00000.79 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007180 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00998.05 ], Gyr [ 00000.06, 00002.32, -00001.77 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007181 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00992.68 ], Gyr [ 00000.00, -00001.40, -00000.18 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007182 NoMotion-1. Scaled. Acc [ -00036.13, -00009.28, 00979.98 ], Gyr [ 00000.73, 00000.55, -00000.12 ], Mag [ 00020.40, 00082.95, 00106.65 ]",
    "#0000007183 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00980.96 ], Gyr [ 00000.85, 00001.04, -00001.46 ], Mag [ 00020.40, 00082.95, 00106.65 ]",
    "#0000007184 NoMotion-1. Scaled. Acc [ -00045.90, -00015.14, 00989.75 ], Gyr [ 00002.99, 00001.52, -00002.99 ], Mag [ 00017.70, 00083.25, 00107.85 ]",
    "#0000007185 NoMotion-1. Scaled. Acc [ -00043.95, 00002.93, 00996.09 ], Gyr [ 00000.61, 00000.67, -00001.40 ], Mag [ 00017.70, 00083.25, 00107.85 ]",
    "#0000007186 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 00974.12 ], Gyr [ -00001.46, -00000.98, -00002.13 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007187 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 00983.89 ], Gyr [ -00000.85, 00000.30, -00003.17 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007188 NoMotion-1. Scaled. Acc [ -00047.85, -00004.88, 00982.91 ], Gyr [ 00000.06, -00000.91, -00001.28 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007189 NoMotion-1. Scaled. Acc [ -00030.76, -00001.46, 00995.61 ], Gyr [ 00001.40, -00000.12, -00000.49 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007190 NoMotion-1. Scaled. Acc [ -00040.53, -00020.02, 00991.70 ], Gyr [ -00000.67, 00001.34, -00001.71 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000007191 NoMotion-1. Scaled. Acc [ -00047.36, -00010.74, 00988.28 ], Gyr [ -00000.49, 00004.33, -00002.99 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000007192 NoMotion-1. Scaled. Acc [ -00045.41, -00003.91, 00989.26 ], Gyr [ -00000.49, 00000.43, -00000.55 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007193 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00983.89 ], Gyr [ 00001.28, 00003.84, -00004.09 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007194 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00993.16 ], Gyr [ -00000.85, 00002.07, -00003.17 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007195 NoMotion-1. Scaled. Acc [ -00042.97, -00010.74, 00996.09 ], Gyr [ 00000.98, 00001.83, -00001.59 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007196 NoMotion-1. Scaled. Acc [ -00040.53, 00002.44, 00997.56 ], Gyr [ 00001.04, 00001.52, -00001.71 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007197 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00987.30 ], Gyr [ 00000.55, 00000.30, -00002.20 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007198 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 00984.86 ], Gyr [ 00002.50, 00000.91, -00002.01 ], Mag [ 00018.45, 00083.55, 00106.05 ]",
    "#0000007199 NoMotion-1. Scaled. Acc [ -00034.18, -00012.70, 00981.45 ], Gyr [ 00000.73, 00006.59, -00001.83 ], Mag [ 00018.45, 00083.55, 00106.05 ]",
    "#0000007200 NoMotion-1. Scaled. Acc [ -00046.88, -00008.30, 00979.49 ], Gyr [ 00000.12, 00003.05, -00001.89 ], Mag [ 00019.95, 00084.00, 00106.80 ]",
    "#0000007201 NoMotion-1. Scaled. Acc [ -00046.88, -00015.62, 00984.86 ], Gyr [ 00000.61, 00000.98, -00002.87 ], Mag [ 00019.95, 00084.00, 00106.80 ]",
    "#0000007202 NoMotion-1. Scaled. Acc [ -00040.04, -00011.23, 01002.93 ], Gyr [ 00002.44, 00000.00, -00000.43 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000007203 NoMotion-1. Scaled. Acc [ -00048.83, -00015.14, 01006.84 ], Gyr [ 00000.06, 00002.93, -00003.23 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000007204 NoMotion-1. Scaled. Acc [ -00045.90, -00008.79, 00985.84 ], Gyr [ -00000.18, 00004.82, -00002.20 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007205 NoMotion-1. Scaled. Acc [ -00044.43, -00005.37, 00986.82 ], Gyr [ -00000.30, -00000.24, -00000.18 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007206 NoMotion-1. Scaled. Acc [ -00042.97, -00000.49, 00989.75 ], Gyr [ -00000.24, 00002.99, -00001.28 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007207 NoMotion-1. Scaled. Acc [ -00052.25, -00009.77, 00997.07 ], Gyr [ 00000.30, 00002.56, -00002.07 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007208 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00995.61 ], Gyr [ -00001.22, 00002.07, -00002.56 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007209 NoMotion-1. Scaled. Acc [ -00037.11, -00006.84, 00984.38 ], Gyr [ 00000.00, 00000.00, -00000.85 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007210 NoMotion-1. Scaled. Acc [ -00045.41, -00011.23, 00983.40 ], Gyr [ 00000.73, -00000.79, -00001.22 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007211 NoMotion-1. Scaled. Acc [ -00043.95, 00004.88, 00986.33 ], Gyr [ 00000.67, 00002.20, -00000.18 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000007212 NoMotion-1. Scaled. Acc [ -00039.55, -00014.65, 00988.77 ], Gyr [ 00001.40, 00000.30, 00000.06 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007213 NoMotion-1. Scaled. Acc [ -00029.79, -00002.44, 00991.70 ], Gyr [ -00001.34, 00002.44, -00001.04 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007214 NoMotion-1. Scaled. Acc [ -00038.57, -00006.84, 00998.54 ], Gyr [ -00000.24, 00001.28, -00001.16 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007215 NoMotion-1. Scaled. Acc [ -00038.09, -00021.48, 00994.63 ], Gyr [ -00001.46, 00000.37, -00002.80 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007216 NoMotion-1. Scaled. Acc [ -00045.90, -00012.21, 00990.72 ], Gyr [ 00001.71, 00003.05, -00003.23 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007217 NoMotion-1. Scaled. Acc [ -00045.41, -00004.39, 00989.26 ], Gyr [ 00000.73, 00002.20, -00000.79 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007218 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00994.63 ], Gyr [ 00000.98, 00000.98, -00003.23 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007219 NoMotion-1. Scaled. Acc [ -00050.29, -00002.44, 00997.56 ], Gyr [ 00000.98, 00003.17, -00002.20 ], Mag [ 00020.25, 00084.75, 00106.80 ]",
    "#0000007220 NoMotion-1. Scaled. Acc [ -00048.34, -00003.42, 00996.58 ], Gyr [ 00001.52, -00000.61, -00000.98 ], Mag [ 00020.25, 00084.75, 00106.80 ]",
    "#0000007221 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 01001.46 ], Gyr [ 00001.28, 00001.89, 00001.77 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007222 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00999.51 ], Gyr [ -00000.37, 00001.83, -00001.89 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007223 NoMotion-1. Scaled. Acc [ -00025.88, -00013.67, 00986.33 ], Gyr [ 00000.98, -00000.49, -00000.12 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007224 NoMotion-1. Scaled. Acc [ -00044.92, -00009.77, 00993.16 ], Gyr [ 00001.22, 00000.37, -00000.12 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007225 NoMotion-1. Scaled. Acc [ -00035.64, -00011.72, 00981.45 ], Gyr [ 00001.16, 00002.56, -00001.89 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007226 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00988.28 ], Gyr [ -00001.22, 00003.29, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007227 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00989.26 ], Gyr [ 00000.18, 00003.35, 00000.00 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000007228 NoMotion-1. Scaled. Acc [ -00052.25, -00013.67, 00993.16 ], Gyr [ -00000.18, 00003.84, -00000.37 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000007229 NoMotion-1. Scaled. Acc [ -00048.34, -00021.97, 00984.38 ], Gyr [ -00001.10, 00001.65, 00000.00 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007230 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00998.05 ], Gyr [ 00001.40, 00001.83, -00002.32 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007231 NoMotion-1. Scaled. Acc [ -00037.60, 00000.98, 00995.12 ], Gyr [ 00001.28, -00000.98, -00001.89 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007232 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00989.75 ], Gyr [ -00001.04, 00003.41, -00001.95 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007233 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 00984.38 ], Gyr [ 00000.18, 00002.68, -00002.44 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007234 NoMotion-1. Scaled. Acc [ -00036.62, -00009.77, 00990.72 ], Gyr [ 00002.44, -00002.07, 00000.79 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000007235 NoMotion-1. Scaled. Acc [ -00041.02, 00004.88, 00993.65 ], Gyr [ 00001.52, 00001.10, -00000.61 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000007236 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00986.33 ], Gyr [ -00003.23, 00002.38, -00002.80 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007237 NoMotion-1. Scaled. Acc [ -00044.92, 00003.91, 00994.63 ], Gyr [ -00000.18, 00001.89, -00000.12 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007238 NoMotion-1. Scaled. Acc [ -00031.74, -00003.42, 00988.28 ], Gyr [ 00000.49, 00000.73, -00001.46 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007239 NoMotion-1. Scaled. Acc [ -00049.80, -00015.14, 01000.49 ], Gyr [ 00000.55, 00000.85, -00003.35 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007240 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00984.38 ], Gyr [ -00001.28, 00001.34, -00000.37 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007241 NoMotion-1. Scaled. Acc [ -00050.29, -00003.42, 00979.49 ], Gyr [ -00000.98, 00003.78, -00001.59 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007242 NoMotion-1. Scaled. Acc [ -00037.11, -00000.49, 00995.12 ], Gyr [ -00002.80, 00001.65, -00003.23 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000007243 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00989.75 ], Gyr [ -00000.12, 00000.49, -00001.22 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000007244 NoMotion-1. Scaled. Acc [ -00051.27, -00010.25, 00986.82 ], Gyr [ 00000.49, -00000.91, -00001.46 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000007245 NoMotion-1. Scaled. Acc [ -00026.86, -00023.44, 00990.72 ], Gyr [ 00000.73, -00000.24, -00000.98 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007246 NoMotion-1. Scaled. Acc [ -00057.62, -00007.32, 00994.63 ], Gyr [ 00001.40, 00001.28, -00000.49 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007247 NoMotion-1. Scaled. Acc [ -00055.66, 00006.35, 00994.14 ], Gyr [ 00000.00, 00001.04, -00000.12 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007248 NoMotion-1. Scaled. Acc [ -00048.83, -00005.37, 00991.21 ], Gyr [ 00000.37, 00000.85, -00002.50 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007249 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00998.54 ], Gyr [ 00001.52, 00001.46, -00001.65 ], Mag [ 00020.40, 00082.65, 00108.45 ]",
    "#0000007250 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00987.30 ], Gyr [ -00001.46, 00002.62, -00004.02 ], Mag [ 00020.40, 00082.65, 00108.45 ]",
    "#0000007251 NoMotion-1. Scaled. Acc [ -00039.55, -00004.39, 00993.16 ], Gyr [ -00000.91, 00002.50, -00001.40 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007252 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 00985.84 ], Gyr [ 00002.13, 00000.73, -00003.23 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007253 NoMotion-1. Scaled. Acc [ -00032.71, 00007.32, 00992.68 ], Gyr [ -00000.06, 00000.55, -00000.91 ], Mag [ 00018.30, 00083.70, 00107.70 ]",
    "#0000007254 NoMotion-1. Scaled. Acc [ -00046.39, -00019.04, 01003.91 ], Gyr [ 00000.73, 00001.46, 00002.01 ], Mag [ 00018.30, 00083.70, 00107.70 ]",
    "#0000007255 NoMotion-1. Scaled. Acc [ -00042.48, 00001.95, 00979.98 ], Gyr [ -00000.98, 00001.52, -00000.61 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007256 NoMotion-1. Scaled. Acc [ -00048.83, -00017.58, 00992.19 ], Gyr [ -00001.34, 00002.62, -00003.11 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007257 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00998.54 ], Gyr [ 00000.85, 00001.40, -00000.61 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007258 NoMotion-1. Scaled. Acc [ -00040.53, -00017.58, 00995.61 ], Gyr [ 00003.17, 00002.80, 00000.85 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007259 NoMotion-1. Scaled. Acc [ -00032.71, 00000.00, 00989.26 ], Gyr [ 00001.22, 00001.28, -00000.12 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000007260 NoMotion-1. Scaled. Acc [ -00045.90, -00013.67, 01005.37 ], Gyr [ 00000.06, -00002.07, 00000.06 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000007261 NoMotion-1. Scaled. Acc [ -00041.99, 00003.91, 00994.14 ], Gyr [ 00001.10, 00000.85, -00000.37 ], Mag [ 00020.85, 00082.20, 00108.00 ]",
    "#0000007262 NoMotion-1. Scaled. Acc [ -00053.22, -00008.79, 00993.65 ], Gyr [ -00000.06, 00000.00, -00000.55 ], Mag [ 00020.85, 00082.20, 00108.00 ]",
    "#0000007263 NoMotion-1. Scaled. Acc [ -00043.46, -00001.95, 00993.16 ], Gyr [ -00002.07, 00000.37, -00003.90 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000007264 NoMotion-1. Scaled. Acc [ -00043.95, 00005.37, 00984.86 ], Gyr [ -00000.12, 00000.91, -00004.02 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007265 NoMotion-1. Scaled. Acc [ -00043.95, 00002.44, 00983.40 ], Gyr [ 00000.85, 00001.89, -00003.41 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007266 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00978.52 ], Gyr [ -00000.49, 00001.10, -00002.80 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007267 NoMotion-1. Scaled. Acc [ -00033.69, -00000.98, 00990.23 ], Gyr [ 00000.00, -00000.98, -00002.50 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007268 NoMotion-1. Scaled. Acc [ -00031.25, -00011.23, 00993.65 ], Gyr [ 00000.12, 00003.54, -00001.89 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007269 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 00990.72 ], Gyr [ 00000.30, -00000.55, 00000.12 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007270 NoMotion-1. Scaled. Acc [ -00034.18, -00009.77, 00977.54 ], Gyr [ 00000.30, 00000.67, -00002.20 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007271 NoMotion-1. Scaled. Acc [ -00037.11, -00006.35, 00998.54 ], Gyr [ -00000.24, 00001.77, -00001.34 ], Mag [ 00021.90, 00084.15, 00108.75 ]",
    "#0000007272 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00997.56 ], Gyr [ 00000.06, 00000.85, -00001.65 ], Mag [ 00021.90, 00084.15, 00108.75 ]",
    "#0000007273 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00992.19 ], Gyr [ 00001.10, 00000.12, -00000.49 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007274 NoMotion-1. Scaled. Acc [ -00025.88, 00006.84, 00979.49 ], Gyr [ -00000.12, 00002.50, -00001.59 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007275 NoMotion-1. Scaled. Acc [ -00031.25, -00001.46, 00996.09 ], Gyr [ 00000.61, 00001.59, -00002.07 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007276 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00977.54 ], Gyr [ -00000.37, 00005.49, -00002.26 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007277 NoMotion-1. Scaled. Acc [ -00037.60, -00007.32, 00994.63 ], Gyr [ 00000.06, 00002.74, -00002.44 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007278 NoMotion-1. Scaled. Acc [ -00059.08, -00004.39, 00984.38 ], Gyr [ 00000.79, 00001.83, -00003.23 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007279 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00991.70 ], Gyr [ -00000.55, 00002.68, -00004.27 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007280 NoMotion-1. Scaled. Acc [ -00046.39, -00009.77, 00986.33 ], Gyr [ -00000.30, 00001.89, -00001.10 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007281 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00987.79 ], Gyr [ 00000.91, 00003.05, -00001.95 ], Mag [ 00021.15, 00083.70, 00108.45 ]",
    "#0000007282 NoMotion-1. Scaled. Acc [ -00051.76, -00009.77, 00998.54 ], Gyr [ -00001.65, 00003.66, -00001.16 ], Mag [ 00021.15, 00083.70, 00108.45 ]",
    "#0000007283 NoMotion-1. Scaled. Acc [ -00053.22, -00000.98, 00994.14 ], Gyr [ 00001.89, 00001.71, -00001.77 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007284 NoMotion-1. Scaled. Acc [ -00043.95, -00008.30, 00998.54 ], Gyr [ -00000.55, -00000.37, 00000.30 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007285 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 01001.95 ], Gyr [ 00001.04, 00001.22, -00000.91 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007286 NoMotion-1. Scaled. Acc [ -00036.62, -00012.21, 00992.19 ], Gyr [ 00001.16, 00001.10, -00001.46 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007287 NoMotion-1. Scaled. Acc [ -00041.99, 00000.98, 00994.63 ], Gyr [ -00000.24, 00001.34, -00000.43 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007288 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00987.79 ], Gyr [ 00001.34, 00000.55, -00002.32 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007289 NoMotion-1. Scaled. Acc [ -00056.15, -00016.60, 00981.93 ], Gyr [ 00000.37, 00002.26, -00001.40 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007290 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 01000.00 ], Gyr [ 00000.55, 00001.71, -00001.52 ], Mag [ 00020.10, 00084.00, 00107.85 ]",
    "#0000007291 NoMotion-1. Scaled. Acc [ -00045.90, -00012.70, 00989.75 ], Gyr [ -00000.06, 00005.37, 00000.24 ], Mag [ 00020.10, 00085.65, 00108.00 ]",
    "#0000007292 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00999.51 ], Gyr [ 00001.04, 00001.28, -00003.35 ], Mag [ 00021.00, 00084.00, 00108.00 ]",
    "#0000007293 NoMotion-1. Scaled. Acc [ -00050.78, -00008.79, 01000.00 ], Gyr [ 00000.37, 00003.84, -00003.23 ], Mag [ 00021.00, 00084.00, 00107.55 ]",
    "#0000007294 NoMotion-1. Scaled. Acc [ -00040.04, 00002.93, 00976.07 ], Gyr [ -00001.83, 00004.82, -00001.65 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007295 NoMotion-1. Scaled. Acc [ -00042.48, -00011.72, 00990.72 ], Gyr [ -00000.24, 00000.30, -00002.56 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007296 NoMotion-1. Scaled. Acc [ -00041.02, 00000.00, 00980.47 ], Gyr [ 00001.77, -00000.55, -00001.59 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007297 NoMotion-1. Scaled. Acc [ -00044.43, -00002.93, 00988.28 ], Gyr [ 00002.68, 00001.28, -00000.91 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007298 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00990.23 ], Gyr [ 00000.73, 00000.55, -00002.32 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007299 NoMotion-1. Scaled. Acc [ -00037.60, -00004.88, 00988.77 ], Gyr [ -00000.61, 00002.26, -00000.49 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007300 NoMotion-1. Scaled. Acc [ -00044.43, 00003.91, 00992.68 ], Gyr [ 00001.46, -00000.37, -00002.74 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007301 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 00991.21 ], Gyr [ -00000.85, -00001.52, 00000.98 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007302 NoMotion-1. Scaled. Acc [ -00034.18, -00008.79, 00990.72 ], Gyr [ -00002.20, 00001.65, -00003.23 ], Mag [ 00019.50, 00083.25, 00108.30 ]",
    "#0000007303 NoMotion-1. Scaled. Acc [ -00041.50, -00016.60, 00988.77 ], Gyr [ 00000.61, 00001.59, 00000.67 ], Mag [ 00019.05, 00082.65, 00108.45 ]",
    "#0000007304 NoMotion-1. Scaled. Acc [ -00036.13, 00000.98, 00987.79 ], Gyr [ 00000.49, 00003.35, -00000.49 ], Mag [ 00019.05, 00082.65, 00108.45 ]",
    "#0000007305 NoMotion-1. Scaled. Acc [ -00050.78, -00018.07, 00996.58 ], Gyr [ -00001.77, 00001.71, -00001.52 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007306 NoMotion-1. Scaled. Acc [ -00038.57, -00016.11, 00991.21 ], Gyr [ -00000.73, 00000.67, -00002.32 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007307 NoMotion-1. Scaled. Acc [ -00028.32, 00001.46, 00995.12 ], Gyr [ 00001.65, 00001.22, -00001.04 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007308 NoMotion-1. Scaled. Acc [ -00038.57, -00006.84, 00985.84 ], Gyr [ -00001.52, -00002.32, -00001.83 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007309 NoMotion-1. Scaled. Acc [ -00034.18, 00001.46, 00986.82 ], Gyr [ -00001.95, -00002.44, -00001.10 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007310 NoMotion-1. Scaled. Acc [ -00043.95, -00000.49, 00987.30 ], Gyr [ 00000.55, 00001.52, -00001.71 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000007311 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00987.79 ], Gyr [ -00000.06, 00000.00, -00001.34 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007312 NoMotion-1. Scaled. Acc [ -00053.22, -00013.67, 00984.86 ], Gyr [ 00001.34, 00001.34, -00003.05 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007313 NoMotion-1. Scaled. Acc [ -00041.50, -00020.51, 00990.72 ], Gyr [ 00003.05, 00002.26, 00000.67 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007314 NoMotion-1. Scaled. Acc [ -00036.62, 00001.46, 00987.30 ], Gyr [ 00000.43, 00001.95, -00003.96 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007315 NoMotion-1. Scaled. Acc [ -00036.62, -00005.86, 00986.33 ], Gyr [ -00000.67, 00000.91, -00001.77 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007316 NoMotion-1. Scaled. Acc [ -00045.90, 00000.00, 00996.09 ], Gyr [ -00000.24, 00002.32, -00002.93 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007317 NoMotion-1. Scaled. Acc [ -00035.64, -00020.02, 00994.14 ], Gyr [ -00000.91, -00000.43, -00003.23 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007318 NoMotion-1. Scaled. Acc [ -00055.18, -00015.62, 00987.30 ], Gyr [ -00000.55, -00000.24, 00000.98 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007319 NoMotion-1. Scaled. Acc [ -00042.97, 00004.39, 00991.21 ], Gyr [ -00000.06, 00003.17, -00002.01 ], Mag [ 00019.50, 00082.80, 00108.60 ]",
    "#0000007320 NoMotion-1. Scaled. Acc [ -00044.43, 00003.91, 00988.77 ], Gyr [ 00000.37, 00001.16, -00001.16 ], Mag [ 00017.85, 00082.20, 00105.60 ]",
    "#0000007321 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00980.96 ], Gyr [ 00000.73, -00001.83, 00000.91 ], Mag [ 00020.25, 00082.50, 00107.10 ]",
    "#0000007322 NoMotion-1. Scaled. Acc [ -00037.60, -00010.74, 00993.16 ], Gyr [ -00000.98, 00001.28, 00002.74 ], Mag [ 00020.25, 00082.50, 00107.10 ]",
    "#0000007323 NoMotion-1. Scaled. Acc [ -00031.25, -00004.39, 00987.30 ], Gyr [ 00000.37, 00001.40, 00000.67 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007324 NoMotion-1. Scaled. Acc [ -00035.16, -00010.25, 00999.51 ], Gyr [ 00000.55, 00000.43, -00002.99 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007325 NoMotion-1. Scaled. Acc [ -00049.80, 00000.00, 00988.28 ], Gyr [ 00001.22, 00001.59, 00000.73 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007326 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00992.19 ], Gyr [ -00000.79, 00001.22, -00003.11 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007327 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00987.79 ], Gyr [ -00000.55, 00002.01, -00004.39 ], Mag [ 00020.25, 00084.00, 00107.10 ]",
    "#0000007328 NoMotion-1. Scaled. Acc [ -00038.09, 00007.32, 00988.28 ], Gyr [ 00000.06, 00000.06, -00002.44 ], Mag [ 00020.25, 00084.00, 00107.10 ]",
    "#0000007329 NoMotion-1. Scaled. Acc [ -00052.25, -00012.70, 00986.82 ], Gyr [ 00001.65, 00000.91, -00001.16 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000007330 NoMotion-1. Scaled. Acc [ -00049.80, -00000.98, 00986.33 ], Gyr [ -00000.49, 00002.87, -00001.89 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000007331 NoMotion-1. Scaled. Acc [ -00040.53, -00021.48, 00994.14 ], Gyr [ -00000.12, 00003.11, -00003.66 ], Mag [ 00017.70, 00084.75, 00107.10 ]",
    "#0000007332 NoMotion-1. Scaled. Acc [ -00052.25, -00005.86, 00998.05 ], Gyr [ 00000.67, 00003.54, -00001.40 ], Mag [ 00017.70, 00084.75, 00107.10 ]",
    "#0000007333 NoMotion-1. Scaled. Acc [ -00039.06, 00005.86, 00983.40 ], Gyr [ 00001.22, 00002.50, 00000.49 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007334 NoMotion-1. Scaled. Acc [ -00028.81, -00008.79, 00994.63 ], Gyr [ 00000.67, 00000.24, -00001.89 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007335 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00992.19 ], Gyr [ 00001.40, 00002.01, -00003.84 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007336 NoMotion-1. Scaled. Acc [ -00054.20, -00018.07, 00987.30 ], Gyr [ 00001.71, 00003.29, 00001.10 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007337 NoMotion-1. Scaled. Acc [ -00040.04, -00006.35, 01001.46 ], Gyr [ 00002.07, 00000.30, -00001.10 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000007338 NoMotion-1. Scaled. Acc [ -00033.20, -00002.44, 00987.30 ], Gyr [ -00000.55, 00000.06, -00001.28 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000007339 NoMotion-1. Scaled. Acc [ -00052.25, -00018.55, 00987.79 ], Gyr [ 00002.26, 00002.13, 00000.24 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007340 NoMotion-1. Scaled. Acc [ -00032.23, -00005.86, 00984.86 ], Gyr [ -00000.61, 00002.68, -00001.46 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007341 NoMotion-1. Scaled. Acc [ -00051.27, 00000.00, 00984.86 ], Gyr [ -00000.37, 00003.29, -00001.40 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007342 NoMotion-1. Scaled. Acc [ -00051.76, -00007.81, 00987.79 ], Gyr [ -00000.12, 00000.85, -00002.56 ], Mag [ 00018.15, 00084.30, 00109.35 ]",
    "#0000007343 NoMotion-1. Scaled. Acc [ -00039.55, 00000.00, 00982.91 ], Gyr [ -00000.79, 00001.16, -00002.62 ], Mag [ 00018.15, 00084.30, 00109.35 ]",
    "#0000007344 NoMotion-1. Scaled. Acc [ -00040.04, -00007.81, 00987.30 ], Gyr [ -00000.18, 00000.55, -00000.61 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007345 NoMotion-1. Scaled. Acc [ -00044.43, -00001.46, 00991.70 ], Gyr [ 00002.93, 00000.67, 00000.37 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007346 NoMotion-1. Scaled. Acc [ -00047.36, -00007.32, 00983.89 ], Gyr [ 00001.28, -00000.73, 00000.49 ], Mag [ 00021.15, 00085.20, 00108.00 ]",
    "#0000007347 NoMotion-1. Scaled. Acc [ -00051.76, -00010.25, 00992.68 ], Gyr [ 00001.10, 00002.32, -00000.91 ], Mag [ 00021.15, 00085.20, 00108.00 ]",
    "#0000007348 NoMotion-1. Scaled. Acc [ -00039.55, 00000.49, 00985.35 ], Gyr [ -00000.18, 00003.90, -00001.40 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007349 NoMotion-1. Scaled. Acc [ -00055.66, -00007.81, 00998.05 ], Gyr [ 00000.37, -00000.67, -00001.71 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007350 NoMotion-1. Scaled. Acc [ -00030.27, -00007.32, 00986.82 ], Gyr [ 00001.89, 00001.71, -00000.79 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007351 NoMotion-1. Scaled. Acc [ -00041.50, -00002.44, 00983.40 ], Gyr [ 00000.98, 00003.78, -00003.60 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000007352 NoMotion-1. Scaled. Acc [ -00040.04, 00000.98, 00985.84 ], Gyr [ 00000.61, 00003.96, -00000.43 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000007353 NoMotion-1. Scaled. Acc [ -00045.41, 00006.84, 00996.58 ], Gyr [ 00000.24, 00001.52, -00003.35 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007354 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 00989.26 ], Gyr [ -00000.98, 00000.61, -00004.09 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007355 NoMotion-1. Scaled. Acc [ -00030.76, -00023.44, 00994.14 ], Gyr [ 00002.26, 00001.16, 00000.55 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007356 NoMotion-1. Scaled. Acc [ -00042.48, -00014.16, 00989.75 ], Gyr [ 00001.22, -00000.49, -00002.13 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007357 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00989.26 ], Gyr [ 00002.13, 00003.41, -00000.12 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007358 NoMotion-1. Scaled. Acc [ -00061.52, -00003.91, 01000.98 ], Gyr [ 00000.91, -00000.79, -00001.71 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007359 NoMotion-1. Scaled. Acc [ -00047.36, -00010.74, 00986.33 ], Gyr [ 00001.22, 00001.28, 00000.37 ], Mag [ 00020.10, 00082.65, 00107.40 ]",
    "#0000007360 NoMotion-1. Scaled. Acc [ -00055.66, -00012.70, 00989.26 ], Gyr [ 00001.95, -00000.79, 00001.22 ], Mag [ 00020.10, 00082.65, 00107.40 ]",
    "#0000007361 NoMotion-1. Scaled. Acc [ -00037.11, -00017.58, 00987.30 ], Gyr [ -00000.12, 00001.10, -00002.01 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007362 NoMotion-1. Scaled. Acc [ -00045.90, 00001.95, 00992.68 ], Gyr [ -00000.55, 00001.34, -00003.23 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007363 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00999.51 ], Gyr [ -00000.98, -00000.85, -00000.55 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000007364 NoMotion-1. Scaled. Acc [ -00040.53, -00012.21, 00980.96 ], Gyr [ -00001.16, 00002.44, -00001.77 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000007365 NoMotion-1. Scaled. Acc [ -00033.20, -00014.16, 00996.58 ], Gyr [ 00000.18, 00001.59, -00003.72 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007366 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00982.42 ], Gyr [ 00002.01, 00001.65, -00000.43 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007367 NoMotion-1. Scaled. Acc [ -00041.99, -00012.21, 00987.30 ], Gyr [ 00000.43, 00003.23, -00000.30 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007368 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00995.61 ], Gyr [ 00000.18, 00000.00, 00000.12 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007369 NoMotion-1. Scaled. Acc [ -00031.74, -00007.81, 01001.95 ], Gyr [ 00001.22, 00001.59, 00000.98 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007370 NoMotion-1. Scaled. Acc [ -00037.11, 00005.86, 00983.89 ], Gyr [ -00000.55, 00000.91, -00002.32 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007371 NoMotion-1. Scaled. Acc [ -00054.20, -00001.95, 00986.82 ], Gyr [ 00001.89, 00002.07, -00000.67 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007372 NoMotion-1. Scaled. Acc [ -00033.20, -00023.93, 00984.38 ], Gyr [ 00002.44, 00000.00, -00001.95 ], Mag [ 00019.65, 00083.40, 00106.95 ]",
    "#0000007373 NoMotion-1. Scaled. Acc [ -00040.53, -00021.00, 00998.05 ], Gyr [ 00001.83, 00000.61, -00004.45 ], Mag [ 00019.65, 00083.40, 00106.95 ]",
    "#0000007374 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00998.05 ], Gyr [ 00000.24, 00001.95, -00000.98 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007375 NoMotion-1. Scaled. Acc [ -00045.41, -00015.14, 00985.35 ], Gyr [ -00000.18, 00001.59, -00001.83 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007376 NoMotion-1. Scaled. Acc [ -00047.85, -00007.81, 00997.56 ], Gyr [ -00000.37, 00000.85, -00003.17 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007377 NoMotion-1. Scaled. Acc [ -00044.92, -00000.98, 00993.65 ], Gyr [ -00002.50, 00002.44, -00001.95 ], Mag [ 00019.95, 00082.05, 00108.30 ]",
    "#0000007378 NoMotion-1. Scaled. Acc [ -00041.02, -00012.70, 00981.45 ], Gyr [ -00000.91, 00002.50, -00002.50 ], Mag [ 00019.95, 00082.05, 00108.30 ]",
    "#0000007379 NoMotion-1. Scaled. Acc [ -00042.97, -00019.53, 00994.63 ], Gyr [ -00000.30, -00000.73, -00000.37 ], Mag [ 00017.85, 00084.90, 00109.05 ]",
    "#0000007380 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00982.42 ], Gyr [ 00004.51, -00000.18, 00000.00 ], Mag [ 00017.85, 00084.90, 00109.05 ]",
    "#0000007381 NoMotion-1. Scaled. Acc [ -00045.41, -00004.88, 00984.86 ], Gyr [ 00000.30, 00003.17, -00002.26 ], Mag [ 00020.25, 00082.80, 00107.85 ]",
    "#0000007382 NoMotion-1. Scaled. Acc [ -00041.02, 00002.44, 00994.14 ], Gyr [ 00001.16, 00001.10, -00002.01 ], Mag [ 00020.25, 00082.80, 00107.85 ]",
    "#0000007383 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00983.40 ], Gyr [ 00000.24, -00002.13, 00000.30 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007384 NoMotion-1. Scaled. Acc [ -00040.53, -00004.88, 01003.91 ], Gyr [ 00001.89, 00001.16, -00000.43 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007385 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00982.91 ], Gyr [ -00000.12, 00000.55, -00003.17 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007386 NoMotion-1. Scaled. Acc [ -00048.83, 00004.39, 00999.51 ], Gyr [ 00002.26, 00000.43, -00001.16 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007387 NoMotion-1. Scaled. Acc [ -00035.64, -00006.35, 00990.72 ], Gyr [ 00000.37, 00003.29, -00001.34 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007388 NoMotion-1. Scaled. Acc [ -00035.64, -00001.95, 00987.30 ], Gyr [ 00000.43, 00000.18, -00000.30 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007389 NoMotion-1. Scaled. Acc [ -00039.55, 00004.88, 00993.65 ], Gyr [ 00000.61, 00004.51, -00002.68 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007390 NoMotion-1. Scaled. Acc [ -00047.85, -00009.77, 00985.35 ], Gyr [ 00000.43, 00000.06, -00003.90 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007391 NoMotion-1. Scaled. Acc [ -00040.04, -00008.30, 00983.40 ], Gyr [ -00000.24, 00002.32, -00002.62 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007392 NoMotion-1. Scaled. Acc [ -00034.67, -00012.21, 00997.56 ], Gyr [ 00001.28, -00001.34, 00001.04 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007393 NoMotion-1. Scaled. Acc [ -00038.57, -00013.18, 00978.52 ], Gyr [ 00000.73, 00000.91, -00000.73 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007394 NoMotion-1. Scaled. Acc [ -00042.48, -00006.35, 00995.12 ], Gyr [ 00000.06, 00000.91, -00001.46 ], Mag [ 00020.40, 00083.40, 00107.70 ]",
    "#0000007395 NoMotion-1. Scaled. Acc [ -00042.48, -00002.93, 00997.07 ], Gyr [ 00002.01, -00000.73, 00000.85 ], Mag [ 00020.40, 00083.40, 00107.70 ]",
    "#0000007396 NoMotion-1. Scaled. Acc [ -00037.60, -00014.16, 01004.88 ], Gyr [ 00000.61, 00001.10, -00000.73 ], Mag [ 00020.70, 00084.00, 00107.85 ]",
    "#0000007397 NoMotion-1. Scaled. Acc [ -00042.97, -00011.23, 00995.61 ], Gyr [ 00001.46, 00001.34, -00000.55 ], Mag [ 00020.70, 00084.00, 00107.85 ]",
    "#0000007398 NoMotion-1. Scaled. Acc [ -00048.34, -00014.65, 00995.12 ], Gyr [ -00000.18, 00001.83, -00002.07 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000007399 NoMotion-1. Scaled. Acc [ -00048.83, -00016.60, 00999.02 ], Gyr [ 00001.10, 00001.28, -00003.72 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000007400 NoMotion-1. Scaled. Acc [ -00041.50, -00013.67, 00998.05 ], Gyr [ 00000.55, 00001.89, -00001.65 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007401 NoMotion-1. Scaled. Acc [ -00047.85, 00009.77, 00991.70 ], Gyr [ -00001.71, 00003.05, -00001.83 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007402 NoMotion-1. Scaled. Acc [ -00037.60, -00026.37, 01001.46 ], Gyr [ 00000.12, 00000.43, 00001.28 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007403 NoMotion-1. Scaled. Acc [ -00035.64, -00011.23, 00992.19 ], Gyr [ -00000.37, 00003.60, -00002.62 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007404 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 01001.95 ], Gyr [ 00001.10, 00002.13, -00000.61 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007405 NoMotion-1. Scaled. Acc [ -00051.27, -00006.35, 00989.26 ], Gyr [ 00001.52, 00001.16, 00002.68 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007406 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 00981.93 ], Gyr [ -00000.18, 00002.20, -00000.24 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007407 NoMotion-1. Scaled. Acc [ -00037.60, -00014.16, 00982.42 ], Gyr [ 00002.26, 00001.46, -00003.41 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007408 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00992.68 ], Gyr [ -00000.30, -00000.55, -00001.34 ], Mag [ 00019.95, 00084.75, 00108.60 ]",
    "#0000007409 NoMotion-1. Scaled. Acc [ -00036.13, -00001.95, 00994.63 ], Gyr [ 00001.71, 00001.34, -00001.95 ], Mag [ 00019.95, 00084.75, 00108.60 ]",
    "#0000007410 NoMotion-1. Scaled. Acc [ -00033.20, -00012.21, 00992.19 ], Gyr [ 00003.35, 00002.38, 00000.24 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007411 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 01000.98 ], Gyr [ 00001.46, -00000.30, -00000.85 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007412 NoMotion-1. Scaled. Acc [ -00046.88, -00017.58, 00991.70 ], Gyr [ 00001.04, -00000.55, -00002.68 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007413 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00990.72 ], Gyr [ 00000.00, 00003.05, -00000.85 ], Mag [ 00020.40, 00084.90, 00107.40 ]",
    "#0000007414 NoMotion-1. Scaled. Acc [ -00036.62, -00001.46, 00986.33 ], Gyr [ 00001.77, 00001.40, -00003.90 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007415 NoMotion-1. Scaled. Acc [ -00059.57, 00001.46, 00988.77 ], Gyr [ 00000.43, 00003.90, -00000.12 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007416 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00991.70 ], Gyr [ 00001.10, 00000.12, -00000.73 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007417 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00993.16 ], Gyr [ 00000.98, 00004.63, -00003.41 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007418 NoMotion-1. Scaled. Acc [ -00044.43, -00004.39, 00993.65 ], Gyr [ 00000.00, 00003.17, -00001.16 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007419 NoMotion-1. Scaled. Acc [ -00061.04, -00011.72, 00992.68 ], Gyr [ -00000.24, 00000.06, -00002.26 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007420 NoMotion-1. Scaled. Acc [ -00048.83, -00003.91, 00993.65 ], Gyr [ 00000.79, 00001.77, -00001.59 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007421 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00989.26 ], Gyr [ 00000.79, -00000.43, -00000.98 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007422 NoMotion-1. Scaled. Acc [ -00038.57, -00021.97, 00993.65 ], Gyr [ -00001.95, 00003.17, -00002.44 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000007423 NoMotion-1. Scaled. Acc [ -00042.48, -00009.77, 00984.86 ], Gyr [ -00002.50, 00000.67, -00002.80 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000007424 NoMotion-1. Scaled. Acc [ -00042.97, -00000.98, 00990.23 ], Gyr [ 00001.77, 00001.34, -00003.48 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007425 NoMotion-1. Scaled. Acc [ -00047.85, -00012.21, 01005.86 ], Gyr [ 00002.20, -00000.12, -00001.34 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007426 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 01000.98 ], Gyr [ -00000.24, -00000.55, -00002.56 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000007427 NoMotion-1. Scaled. Acc [ -00047.85, -00008.79, 00984.86 ], Gyr [ 00000.98, 00001.89, -00002.93 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000007428 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00992.68 ], Gyr [ 00001.83, 00000.67, -00000.67 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007429 NoMotion-1. Scaled. Acc [ -00048.34, -00000.49, 00995.61 ], Gyr [ 00000.55, 00001.59, 00000.06 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007430 NoMotion-1. Scaled. Acc [ -00041.50, -00001.46, 00986.82 ], Gyr [ -00000.12, 00002.62, -00000.24 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007431 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 01000.49 ], Gyr [ 00001.59, 00003.84, -00001.28 ], Mag [ 00019.65, 00085.65, 00106.95 ]",
    "#0000007432 NoMotion-1. Scaled. Acc [ -00053.22, -00009.77, 00975.59 ], Gyr [ 00001.59, 00003.05, -00001.16 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007433 NoMotion-1. Scaled. Acc [ -00044.43, -00013.18, 00986.82 ], Gyr [ -00000.67, 00001.59, -00000.30 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007434 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00984.38 ], Gyr [ 00000.55, 00000.18, 00000.06 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007435 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00987.30 ], Gyr [ 00000.61, -00000.30, 00000.55 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007436 NoMotion-1. Scaled. Acc [ -00040.53, -00009.77, 00983.89 ], Gyr [ -00001.77, 00000.00, -00001.77 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007437 NoMotion-1. Scaled. Acc [ -00037.11, -00007.81, 00995.12 ], Gyr [ 00000.73, 00000.73, -00003.66 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007438 NoMotion-1. Scaled. Acc [ -00054.69, -00009.77, 00984.38 ], Gyr [ 00000.37, 00002.13, -00003.11 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000007439 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00997.56 ], Gyr [ -00000.98, 00001.34, -00004.02 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000007440 NoMotion-1. Scaled. Acc [ -00036.62, -00011.72, 00998.05 ], Gyr [ 00002.07, 00001.28, -00001.52 ], Mag [ 00021.00, 00083.25, 00108.30 ]",
    "#0000007441 NoMotion-1. Scaled. Acc [ -00051.76, -00014.16, 00978.52 ], Gyr [ -00001.40, 00002.44, -00002.07 ], Mag [ 00021.00, 00083.25, 00108.30 ]",
    "#0000007442 NoMotion-1. Scaled. Acc [ -00050.78, -00005.86, 00992.68 ], Gyr [ 00000.00, 00001.22, -00002.74 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000007443 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00984.86 ], Gyr [ 00003.11, -00002.13, -00002.38 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000007444 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 00995.12 ], Gyr [ 00001.59, -00002.56, -00001.22 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007445 NoMotion-1. Scaled. Acc [ -00043.95, -00015.14, 00983.89 ], Gyr [ 00002.07, 00004.27, -00002.50 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007446 NoMotion-1. Scaled. Acc [ -00049.80, 00002.44, 00985.35 ], Gyr [ 00001.89, -00000.85, 00000.61 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007447 NoMotion-1. Scaled. Acc [ -00050.29, -00007.81, 00994.63 ], Gyr [ -00000.12, -00001.22, -00002.01 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000007448 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00995.12 ], Gyr [ 00000.49, 00000.91, -00001.16 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000007449 NoMotion-1. Scaled. Acc [ -00031.74, 00006.84, 00988.28 ], Gyr [ 00000.85, 00002.50, -00002.01 ], Mag [ 00020.10, 00084.45, 00108.75 ]",
    "#0000007450 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00988.28 ], Gyr [ 00001.59, 00001.22, -00002.32 ], Mag [ 00020.10, 00084.45, 00108.75 ]",
    "#0000007451 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00984.38 ], Gyr [ -00000.55, -00000.37, -00002.74 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000007452 NoMotion-1. Scaled. Acc [ -00041.50, -00004.39, 00990.23 ], Gyr [ -00000.30, 00003.72, -00002.93 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007453 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 00984.38 ], Gyr [ -00000.79, 00002.20, 00000.24 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007454 NoMotion-1. Scaled. Acc [ -00049.80, -00004.88, 00980.96 ], Gyr [ 00002.13, 00000.61, -00002.99 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007455 NoMotion-1. Scaled. Acc [ -00038.09, -00006.84, 00993.65 ], Gyr [ 00000.85, 00001.16, -00001.22 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007456 NoMotion-1. Scaled. Acc [ -00053.22, -00017.58, 00991.70 ], Gyr [ 00000.37, -00001.52, 00000.61 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007457 NoMotion-1. Scaled. Acc [ -00049.80, -00003.91, 00987.30 ], Gyr [ 00002.07, 00001.10, 00000.24 ], Mag [ 00019.50, 00084.00, 00107.55 ]",
    "#0000007458 NoMotion-1. Scaled. Acc [ -00041.02, -00003.42, 00996.09 ], Gyr [ 00002.87, 00001.16, 00001.10 ], Mag [ 00019.50, 00084.00, 00107.55 ]",
    "#0000007459 NoMotion-1. Scaled. Acc [ -00052.73, -00002.44, 00993.65 ], Gyr [ -00000.12, 00002.20, -00003.29 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007460 NoMotion-1. Scaled. Acc [ -00021.48, -00016.11, 01008.79 ], Gyr [ -00000.79, 00001.46, -00000.61 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007461 NoMotion-1. Scaled. Acc [ -00048.83, -00008.30, 00980.47 ], Gyr [ -00000.61, 00002.93, 00000.06 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007462 NoMotion-1. Scaled. Acc [ -00036.13, -00018.55, 00989.75 ], Gyr [ -00001.77, 00002.99, -00001.65 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007463 NoMotion-1. Scaled. Acc [ -00056.15, -00003.91, 00989.75 ], Gyr [ -00000.49, 00001.71, 00002.20 ], Mag [ 00020.25, 00084.00, 00109.65 ]",
    "#0000007464 NoMotion-1. Scaled. Acc [ -00054.69, -00000.49, 00995.12 ], Gyr [ 00001.40, 00000.18, -00002.93 ], Mag [ 00018.30, 00082.65, 00105.90 ]",
    "#0000007465 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00981.93 ], Gyr [ 00002.38, 00003.11, -00001.71 ], Mag [ 00018.30, 00082.65, 00105.90 ]",
    "#0000007466 NoMotion-1. Scaled. Acc [ -00047.85, -00015.62, 00985.35 ], Gyr [ -00000.79, 00000.73, 00000.24 ], Mag [ 00019.95, 00085.80, 00108.30 ]",
    "#0000007467 NoMotion-1. Scaled. Acc [ -00029.79, -00001.95, 00990.72 ], Gyr [ -00000.55, 00000.24, -00002.13 ], Mag [ 00019.95, 00085.80, 00108.30 ]",
    "#0000007468 NoMotion-1. Scaled. Acc [ -00046.39, -00007.81, 00986.82 ], Gyr [ 00001.95, 00001.77, -00002.80 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007469 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00997.07 ], Gyr [ -00000.73, -00000.06, -00003.35 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007470 NoMotion-1. Scaled. Acc [ -00034.67, 00000.98, 00984.86 ], Gyr [ -00000.91, 00001.52, -00000.85 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007471 NoMotion-1. Scaled. Acc [ -00055.18, -00007.32, 00988.77 ], Gyr [ 00001.28, 00001.95, -00001.52 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007472 NoMotion-1. Scaled. Acc [ -00037.60, -00002.44, 01000.98 ], Gyr [ 00001.89, 00001.10, -00001.52 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000007473 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00992.68 ], Gyr [ 00002.01, 00000.85, -00002.44 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000007474 NoMotion-1. Scaled. Acc [ -00043.95, -00015.62, 00993.16 ], Gyr [ 00000.91, 00004.09, -00000.24 ], Mag [ 00020.70, 00084.00, 00106.80 ]",
    "#0000007475 NoMotion-1. Scaled. Acc [ -00047.36, -00008.30, 01001.95 ], Gyr [ 00000.06, 00003.05, 00000.55 ], Mag [ 00020.70, 00084.00, 00106.80 ]",
    "#0000007476 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00981.45 ], Gyr [ -00000.55, 00002.38, -00001.40 ], Mag [ 00020.10, 00084.45, 00108.00 ]",
    "#0000007477 NoMotion-1. Scaled. Acc [ -00044.92, -00010.74, 00985.35 ], Gyr [ 00000.55, 00000.85, -00001.52 ], Mag [ 00020.10, 00084.45, 00108.00 ]",
    "#0000007478 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 01001.95 ], Gyr [ -00001.22, 00002.68, -00001.52 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007479 NoMotion-1. Scaled. Acc [ -00047.85, 00007.81, 00986.82 ], Gyr [ 00001.52, 00000.79, -00000.37 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007480 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00989.75 ], Gyr [ 00000.98, 00000.43, -00000.49 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007481 NoMotion-1. Scaled. Acc [ -00054.69, -00014.16, 00985.84 ], Gyr [ 00001.65, 00001.59, 00000.73 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007482 NoMotion-1. Scaled. Acc [ -00046.88, 00003.91, 00997.07 ], Gyr [ 00001.83, -00000.55, -00002.32 ], Mag [ 00019.35, 00082.20, 00106.95 ]",
    "#0000007483 NoMotion-1. Scaled. Acc [ -00049.32, 00000.49, 00985.35 ], Gyr [ 00002.26, 00000.06, -00001.04 ], Mag [ 00020.40, 00082.20, 00108.75 ]",
    "#0000007484 NoMotion-1. Scaled. Acc [ -00047.36, -00000.98, 00998.54 ], Gyr [ 00000.61, -00001.28, -00003.72 ], Mag [ 00020.40, 00082.20, 00108.75 ]",
    "#0000007485 NoMotion-1. Scaled. Acc [ -00041.02, -00000.49, 00988.77 ], Gyr [ -00001.28, 00002.26, -00003.41 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000007486 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00988.77 ], Gyr [ 00000.24, 00001.52, -00002.44 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000007487 NoMotion-1. Scaled. Acc [ -00039.06, -00002.93, 00979.49 ], Gyr [ 00000.98, 00003.11, -00001.59 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007488 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00984.86 ], Gyr [ 00000.24, 00002.01, -00002.80 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007489 NoMotion-1. Scaled. Acc [ -00045.90, 00000.49, 00985.84 ], Gyr [ -00000.43, 00003.23, -00001.65 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007490 NoMotion-1. Scaled. Acc [ -00036.13, -00010.74, 00992.68 ], Gyr [ -00000.06, 00000.18, 00000.49 ], Mag [ 00021.00, 00084.30, 00108.30 ]",
    "#0000007491 NoMotion-1. Scaled. Acc [ -00049.80, -00005.37, 00996.09 ], Gyr [ 00001.95, 00001.65, 00001.89 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007492 NoMotion-1. Scaled. Acc [ -00037.60, -00002.93, 00986.33 ], Gyr [ 00001.04, 00000.37, -00000.73 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007493 NoMotion-1. Scaled. Acc [ -00039.55, -00009.28, 00987.79 ], Gyr [ 00002.07, 00000.91, -00001.10 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007494 NoMotion-1. Scaled. Acc [ -00052.73, -00019.53, 00986.33 ], Gyr [ 00002.68, 00001.22, -00000.91 ], Mag [ 00020.70, 00084.30, 00107.85 ]",
    "#0000007495 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 01002.93 ], Gyr [ -00000.18, 00000.24, -00001.10 ], Mag [ 00020.70, 00084.30, 00107.85 ]",
    "#0000007496 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00987.30 ], Gyr [ 00000.67, -00002.13, -00000.24 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007497 NoMotion-1. Scaled. Acc [ -00041.50, 00004.39, 00990.72 ], Gyr [ 00001.77, 00001.52, -00001.52 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007498 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00993.65 ], Gyr [ -00000.30, 00004.51, -00002.07 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007499 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00991.70 ], Gyr [ -00000.55, -00000.67, -00003.96 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007500 NoMotion-1. Scaled. Acc [ -00049.80, -00002.93, 00994.63 ], Gyr [ 00001.52, -00000.61, -00001.95 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007501 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00980.47 ], Gyr [ 00001.10, 00002.50, -00003.66 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007502 NoMotion-1. Scaled. Acc [ -00049.80, -00001.95, 00994.14 ], Gyr [ 00000.37, -00000.49, -00002.20 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007503 NoMotion-1. Scaled. Acc [ -00049.32, -00010.25, 00984.86 ], Gyr [ -00001.34, 00001.71, -00002.44 ], Mag [ 00018.75, 00082.05, 00107.55 ]",
    "#0000007504 NoMotion-1. Scaled. Acc [ -00035.64, 00001.95, 00984.86 ], Gyr [ -00000.49, 00000.18, 00000.61 ], Mag [ 00018.75, 00082.05, 00107.55 ]",
    "#0000007505 NoMotion-1. Scaled. Acc [ -00041.02, -00019.53, 00987.79 ], Gyr [ 00001.77, -00002.50, -00000.43 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007506 NoMotion-1. Scaled. Acc [ -00050.29, -00005.86, 00985.84 ], Gyr [ 00001.40, 00001.16, 00000.00 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007507 NoMotion-1. Scaled. Acc [ -00037.60, -00001.46, 00991.21 ], Gyr [ 00001.34, 00001.83, -00000.85 ], Mag [ 00020.10, 00084.90, 00107.70 ]",
    "#0000007508 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00993.16 ], Gyr [ 00002.07, 00001.16, 00000.49 ], Mag [ 00020.10, 00084.90, 00107.70 ]",
    "#0000007509 NoMotion-1. Scaled. Acc [ -00052.25, 00000.00, 01000.49 ], Gyr [ 00001.28, 00002.93, -00002.99 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007510 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 00993.65 ], Gyr [ -00000.12, 00002.38, -00000.85 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007511 NoMotion-1. Scaled. Acc [ -00039.55, -00004.88, 00971.19 ], Gyr [ 00000.49, 00000.98, -00001.28 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007512 NoMotion-1. Scaled. Acc [ -00043.95, -00016.11, 00984.86 ], Gyr [ 00000.00, 00002.68, -00002.26 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007513 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00989.26 ], Gyr [ -00000.79, 00004.02, -00000.43 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000007514 NoMotion-1. Scaled. Acc [ -00049.32, -00012.21, 01010.25 ], Gyr [ 00001.10, 00004.21, -00004.21 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000007515 NoMotion-1. Scaled. Acc [ -00038.09, 00005.37, 01000.00 ], Gyr [ -00000.79, 00004.39, -00001.16 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000007516 NoMotion-1. Scaled. Acc [ -00047.36, -00021.97, 00994.63 ], Gyr [ 00000.73, 00000.24, -00000.61 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000007517 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.28 ], Gyr [ 00001.52, 00002.32, -00001.04 ], Mag [ 00019.95, 00081.75, 00107.10 ]",
    "#0000007518 NoMotion-1. Scaled. Acc [ -00043.46, -00021.97, 00984.86 ], Gyr [ 00000.91, 00000.91, -00002.26 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007519 NoMotion-1. Scaled. Acc [ -00058.59, 00000.98, 00989.75 ], Gyr [ 00000.24, -00000.30, -00003.54 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007520 NoMotion-1. Scaled. Acc [ -00046.39, -00013.18, 00995.12 ], Gyr [ 00000.85, 00000.12, -00001.46 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007521 NoMotion-1. Scaled. Acc [ -00039.55, -00013.18, 00989.75 ], Gyr [ 00000.85, 00000.49, -00000.67 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007522 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00996.09 ], Gyr [ -00000.73, 00000.55, -00000.98 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007523 NoMotion-1. Scaled. Acc [ -00037.60, -00000.98, 00981.45 ], Gyr [ 00000.06, 00002.80, -00001.28 ], Mag [ 00019.05, 00083.70, 00108.45 ]",
    "#0000007524 NoMotion-1. Scaled. Acc [ -00050.29, -00015.62, 00994.14 ], Gyr [ 00000.85, -00000.30, -00002.01 ], Mag [ 00019.05, 00083.70, 00108.45 ]",
    "#0000007525 NoMotion-1. Scaled. Acc [ -00043.46, -00015.62, 00988.77 ], Gyr [ -00000.30, 00001.71, -00001.52 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007526 NoMotion-1. Scaled. Acc [ -00036.13, 00000.49, 00994.14 ], Gyr [ -00000.73, 00004.57, -00000.73 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007527 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 00994.14 ], Gyr [ 00001.52, 00001.71, -00004.02 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007528 NoMotion-1. Scaled. Acc [ -00045.41, -00001.46, 00985.35 ], Gyr [ 00001.71, 00001.71, 00001.04 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007529 NoMotion-1. Scaled. Acc [ -00042.48, -00001.95, 00994.14 ], Gyr [ -00000.79, 00001.28, -00003.11 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007530 NoMotion-1. Scaled. Acc [ -00048.83, -00011.23, 00982.91 ], Gyr [ 00000.85, 00002.01, 00000.55 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007531 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00989.75 ], Gyr [ 00001.28, -00001.59, -00003.17 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000007532 NoMotion-1. Scaled. Acc [ -00040.53, -00013.18, 00997.56 ], Gyr [ 00001.89, 00001.77, -00000.85 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000007533 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00992.68 ], Gyr [ -00000.91, -00000.85, 00000.55 ], Mag [ 00021.60, 00083.40, 00108.45 ]",
    "#0000007534 NoMotion-1. Scaled. Acc [ -00035.16, -00005.37, 00982.42 ], Gyr [ 00001.34, 00003.17, -00002.32 ], Mag [ 00021.60, 00083.40, 00108.45 ]",
    "#0000007535 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00993.16 ], Gyr [ 00000.43, 00002.56, 00001.34 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007536 NoMotion-1. Scaled. Acc [ -00048.83, 00001.95, 00984.38 ], Gyr [ 00002.26, -00001.89, 00001.40 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007537 NoMotion-1. Scaled. Acc [ -00050.29, -00008.79, 00991.21 ], Gyr [ -00001.34, 00001.89, 00001.89 ], Mag [ 00020.70, 00085.50, 00107.55 ]",
    "#0000007538 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00989.26 ], Gyr [ 00002.26, 00002.13, -00002.87 ], Mag [ 00020.70, 00085.50, 00107.55 ]",
    "#0000007539 NoMotion-1. Scaled. Acc [ -00039.06, -00019.04, 00999.02 ], Gyr [ -00001.28, 00000.43, -00001.83 ], Mag [ 00020.70, 00085.50, 00107.55 ]",
    "#0000007540 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 00996.58 ], Gyr [ 00001.71, 00001.40, -00000.98 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007541 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00992.19 ], Gyr [ 00000.55, 00002.50, -00001.16 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007542 NoMotion-1. Scaled. Acc [ -00056.15, -00001.95, 00982.42 ], Gyr [ 00000.00, 00004.02, 00000.00 ], Mag [ 00020.25, 00083.55, 00106.50 ]",
    "#0000007543 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00982.42 ], Gyr [ 00000.06, 00003.41, -00002.38 ], Mag [ 00020.25, 00083.55, 00106.50 ]",
    "#0000007544 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 01000.00 ], Gyr [ 00001.46, -00000.37, -00001.65 ], Mag [ 00020.10, 00084.90, 00105.60 ]",
    "#0000007545 NoMotion-1. Scaled. Acc [ -00027.83, -00019.53, 00996.09 ], Gyr [ -00000.43, 00000.00, -00000.98 ], Mag [ 00020.10, 00084.90, 00105.60 ]",
    "#0000007546 NoMotion-1. Scaled. Acc [ -00026.86, -00015.14, 00990.23 ], Gyr [ -00000.12, 00001.71, 00002.44 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007547 NoMotion-1. Scaled. Acc [ -00041.02, -00014.16, 00981.93 ], Gyr [ 00000.43, -00000.49, 00000.37 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007548 NoMotion-1. Scaled. Acc [ -00052.25, -00013.67, 00989.75 ], Gyr [ 00001.71, -00000.79, -00002.13 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007549 NoMotion-1. Scaled. Acc [ -00041.02, -00001.95, 00986.33 ], Gyr [ -00000.98, 00000.85, -00000.55 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007550 NoMotion-1. Scaled. Acc [ -00031.74, -00008.30, 00985.84 ], Gyr [ 00000.55, 00001.46, -00001.71 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007551 NoMotion-1. Scaled. Acc [ -00038.09, -00009.77, 00980.96 ], Gyr [ -00000.43, 00001.40, -00002.26 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007552 NoMotion-1. Scaled. Acc [ -00042.48, -00018.55, 00994.63 ], Gyr [ 00002.01, 00002.62, -00000.61 ], Mag [ 00021.15, 00083.40, 00107.40 ]",
    "#0000007553 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 01000.00 ], Gyr [ -00000.98, 00001.10, -00002.93 ], Mag [ 00021.15, 00083.40, 00107.40 ]",
    "#0000007554 NoMotion-1. Scaled. Acc [ -00051.76, -00013.67, 00983.40 ], Gyr [ -00002.07, 00004.45, 00000.61 ], Mag [ 00019.50, 00085.05, 00107.10 ]",
    "#0000007555 NoMotion-1. Scaled. Acc [ -00050.29, -00013.67, 00991.70 ], Gyr [ 00000.30, 00001.28, -00002.68 ], Mag [ 00019.50, 00085.05, 00107.10 ]",
    "#0000007556 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 00992.68 ], Gyr [ 00002.07, 00002.26, -00002.01 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007557 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00996.58 ], Gyr [ 00001.59, -00000.24, -00002.62 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007558 NoMotion-1. Scaled. Acc [ -00031.74, -00006.84, 00997.07 ], Gyr [ 00000.55, 00001.95, -00000.79 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007559 NoMotion-1. Scaled. Acc [ -00036.62, -00006.84, 01004.88 ], Gyr [ 00000.06, 00000.91, -00000.43 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007560 NoMotion-1. Scaled. Acc [ -00037.11, -00008.30, 00986.33 ], Gyr [ 00001.40, -00001.04, -00002.74 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007561 NoMotion-1. Scaled. Acc [ -00045.90, -00009.77, 00996.58 ], Gyr [ 00001.71, 00000.85, 00000.55 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007562 NoMotion-1. Scaled. Acc [ -00058.11, -00007.32, 01007.32 ], Gyr [ -00002.13, -00000.98, -00001.40 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007563 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00991.21 ], Gyr [ 00000.61, 00002.56, -00003.96 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007564 NoMotion-1. Scaled. Acc [ -00041.02, -00010.25, 00982.42 ], Gyr [ -00000.18, -00000.37, -00002.56 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007565 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 00978.03 ], Gyr [ -00000.73, 00002.20, -00002.50 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007566 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00996.09 ], Gyr [ -00001.10, 00001.16, -00005.30 ], Mag [ 00019.20, 00084.75, 00107.10 ]",
    "#0000007567 NoMotion-1. Scaled. Acc [ -00039.06, 00006.35, 00982.91 ], Gyr [ 00000.55, 00001.10, -00001.28 ], Mag [ 00018.60, 00084.45, 00106.95 ]",
    "#0000007568 NoMotion-1. Scaled. Acc [ -00051.27, -00017.09, 00986.33 ], Gyr [ -00000.55, 00002.13, -00002.38 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007569 NoMotion-1. Scaled. Acc [ -00049.80, 00000.00, 00981.93 ], Gyr [ 00001.46, 00004.09, 00000.43 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007570 NoMotion-1. Scaled. Acc [ -00059.08, -00013.67, 00991.70 ], Gyr [ 00001.95, 00000.30, -00001.22 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007571 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00998.05 ], Gyr [ 00001.04, 00000.49, -00003.84 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007572 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00999.02 ], Gyr [ -00001.04, 00004.27, 00000.00 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007573 NoMotion-1. Scaled. Acc [ -00038.57, -00004.39, 00986.82 ], Gyr [ -00000.12, 00002.01, -00002.50 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007574 NoMotion-1. Scaled. Acc [ -00037.11, -00014.65, 00984.86 ], Gyr [ 00000.00, 00002.93, -00000.98 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000007575 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00997.07 ], Gyr [ -00000.12, 00003.54, -00001.04 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007576 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 01000.00 ], Gyr [ -00001.89, 00002.13, 00000.30 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007577 NoMotion-1. Scaled. Acc [ -00041.50, -00014.65, 00992.68 ], Gyr [ 00000.06, 00000.61, -00001.95 ], Mag [ 00019.65, 00085.65, 00108.45 ]",
    "#0000007578 NoMotion-1. Scaled. Acc [ -00043.46, -00010.74, 01001.95 ], Gyr [ 00000.24, 00000.85, -00001.10 ], Mag [ 00019.65, 00085.65, 00108.45 ]",
    "#0000007579 NoMotion-1. Scaled. Acc [ -00057.13, -00002.93, 00993.65 ], Gyr [ 00001.16, 00000.12, -00001.52 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007580 NoMotion-1. Scaled. Acc [ -00053.71, -00009.28, 00997.56 ], Gyr [ -00000.06, -00001.10, -00002.74 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007581 NoMotion-1. Scaled. Acc [ -00038.57, -00015.62, 00993.16 ], Gyr [ -00001.04, 00000.73, -00000.79 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007582 NoMotion-1. Scaled. Acc [ -00040.04, -00018.55, 00989.75 ], Gyr [ 00001.46, -00000.49, 00000.24 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007583 NoMotion-1. Scaled. Acc [ -00042.97, -00004.39, 00990.72 ], Gyr [ 00000.49, -00002.13, -00001.34 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000007584 NoMotion-1. Scaled. Acc [ -00057.62, -00001.46, 00986.33 ], Gyr [ -00000.12, 00001.59, -00001.77 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000007585 NoMotion-1. Scaled. Acc [ -00049.80, -00016.11, 00996.58 ], Gyr [ 00000.00, -00001.04, -00002.50 ], Mag [ 00019.35, 00082.95, 00109.05 ]",
    "#0000007586 NoMotion-1. Scaled. Acc [ -00050.78, -00013.18, 00990.72 ], Gyr [ 00002.26, -00001.10, -00001.65 ], Mag [ 00019.35, 00083.40, 00106.20 ]",
    "#0000007587 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 01000.98 ], Gyr [ 00001.22, 00001.04, -00000.43 ], Mag [ 00019.35, 00083.40, 00106.20 ]",
    "#0000007588 NoMotion-1. Scaled. Acc [ -00047.85, -00013.67, 01000.49 ], Gyr [ -00000.18, 00002.26, -00002.56 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007589 NoMotion-1. Scaled. Acc [ -00049.32, -00003.91, 00976.56 ], Gyr [ 00001.95, 00000.85, -00002.32 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007590 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00984.86 ], Gyr [ -00000.91, -00000.12, -00003.11 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007591 NoMotion-1. Scaled. Acc [ -00041.99, -00012.70, 00993.65 ], Gyr [ 00000.67, 00001.77, -00003.05 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007592 NoMotion-1. Scaled. Acc [ -00045.90, -00015.62, 00995.12 ], Gyr [ 00001.52, -00000.06, -00000.73 ], Mag [ 00018.60, 00084.90, 00108.00 ]",
    "#0000007593 NoMotion-1. Scaled. Acc [ -00047.85, -00011.23, 00990.23 ], Gyr [ 00000.85, -00000.73, -00001.22 ], Mag [ 00019.05, 00082.95, 00108.00 ]",
    "#0000007594 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00988.28 ], Gyr [ -00000.12, 00004.02, -00003.05 ], Mag [ 00019.05, 00082.95, 00108.00 ]",
    "#0000007595 NoMotion-1. Scaled. Acc [ -00034.67, -00014.16, 01002.44 ], Gyr [ 00000.79, -00000.79, -00002.44 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007596 NoMotion-1. Scaled. Acc [ -00039.55, 00003.91, 00983.89 ], Gyr [ 00000.98, -00000.73, -00000.37 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007597 NoMotion-1. Scaled. Acc [ -00035.16, -00005.37, 00993.16 ], Gyr [ 00000.30, 00000.18, -00000.79 ], Mag [ 00018.75, 00084.75, 00107.55 ]",
    "#0000007598 NoMotion-1. Scaled. Acc [ -00040.53, -00014.65, 00989.75 ], Gyr [ -00002.38, 00002.07, -00002.13 ], Mag [ 00018.75, 00084.75, 00107.55 ]",
    "#0000007599 NoMotion-1. Scaled. Acc [ -00040.04, -00002.44, 00986.82 ], Gyr [ 00000.30, 00001.04, -00001.89 ], Mag [ 00018.75, 00084.75, 00107.55 ]",
    "#0000007600 NoMotion-1. Scaled. Acc [ -00040.04, -00003.91, 00988.77 ], Gyr [ 00001.77, 00002.93, -00001.04 ], Mag [ 00020.85, 00083.70, 00107.40 ]",
    "#0000007601 NoMotion-1. Scaled. Acc [ -00035.16, -00005.86, 00997.07 ], Gyr [ 00001.04, 00001.59, 00000.00 ], Mag [ 00020.85, 00083.70, 00107.40 ]",
    "#0000007602 NoMotion-1. Scaled. Acc [ -00033.20, 00006.35, 00998.54 ], Gyr [ 00000.85, 00002.01, -00003.72 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007603 NoMotion-1. Scaled. Acc [ -00042.97, 00002.44, 00982.91 ], Gyr [ 00000.37, 00001.46, -00002.38 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007604 NoMotion-1. Scaled. Acc [ -00036.13, -00005.37, 00989.26 ], Gyr [ 00001.46, 00002.62, -00002.20 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007605 NoMotion-1. Scaled. Acc [ -00036.13, -00016.60, 00996.09 ], Gyr [ 00000.43, 00002.99, -00000.98 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007606 NoMotion-1. Scaled. Acc [ -00037.60, -00011.23, 00996.09 ], Gyr [ 00000.37, 00000.12, -00001.16 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007607 NoMotion-1. Scaled. Acc [ -00057.13, 00001.95, 00987.79 ], Gyr [ 00001.89, 00001.46, -00002.20 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007608 NoMotion-1. Scaled. Acc [ -00036.13, -00001.46, 00983.89 ], Gyr [ -00000.37, 00002.13, -00002.01 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007609 NoMotion-1. Scaled. Acc [ -00044.92, 00004.88, 00994.63 ], Gyr [ 00000.61, 00003.48, -00004.51 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007610 NoMotion-1. Scaled. Acc [ -00044.43, -00017.58, 00996.58 ], Gyr [ 00001.71, 00000.55, -00000.73 ], Mag [ 00020.10, 00083.70, 00106.95 ]",
    "#0000007611 NoMotion-1. Scaled. Acc [ -00039.06, -00012.70, 01002.44 ], Gyr [ -00000.30, 00000.85, -00000.61 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007612 NoMotion-1. Scaled. Acc [ -00040.04, -00005.37, 00983.89 ], Gyr [ -00000.49, -00000.43, -00002.80 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007613 NoMotion-1. Scaled. Acc [ -00034.67, -00007.81, 00985.84 ], Gyr [ 00000.30, 00001.04, -00001.52 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007614 NoMotion-1. Scaled. Acc [ -00029.79, -00009.77, 01007.32 ], Gyr [ -00000.24, 00002.87, -00001.46 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007615 NoMotion-1. Scaled. Acc [ -00036.13, 00001.95, 01001.46 ], Gyr [ 00000.12, 00002.62, -00000.98 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007616 NoMotion-1. Scaled. Acc [ -00047.36, 00002.44, 00994.14 ], Gyr [ -00000.91, 00002.80, -00004.09 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007617 NoMotion-1. Scaled. Acc [ -00045.90, -00005.37, 00983.40 ], Gyr [ 00000.98, 00001.77, 00000.43 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007618 NoMotion-1. Scaled. Acc [ -00043.46, -00004.39, 00999.02 ], Gyr [ 00002.07, 00001.77, 00000.91 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007619 NoMotion-1. Scaled. Acc [ -00041.50, -00016.11, 00997.56 ], Gyr [ -00001.16, -00000.73, -00000.55 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007620 NoMotion-1. Scaled. Acc [ -00053.71, -00011.72, 00990.72 ], Gyr [ 00000.30, 00001.95, -00003.11 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007621 NoMotion-1. Scaled. Acc [ -00052.73, -00009.28, 00991.21 ], Gyr [ 00000.85, 00000.12, 00002.38 ], Mag [ 00019.35, 00082.95, 00109.05 ]",
    "#0000007622 NoMotion-1. Scaled. Acc [ -00034.67, -00001.95, 00983.89 ], Gyr [ 00001.22, 00002.38, -00001.71 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000007623 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00983.40 ], Gyr [ 00001.16, 00002.26, -00003.72 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000007624 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00981.45 ], Gyr [ -00001.52, 00000.67, -00001.95 ], Mag [ 00018.45, 00083.55, 00107.55 ]",
    "#0000007625 NoMotion-1. Scaled. Acc [ -00040.53, 00000.49, 00990.23 ], Gyr [ -00001.46, 00002.26, -00000.98 ], Mag [ 00018.45, 00084.75, 00105.75 ]",
    "#0000007626 NoMotion-1. Scaled. Acc [ -00032.71, -00015.62, 00981.45 ], Gyr [ 00000.18, 00003.72, -00000.67 ], Mag [ 00018.45, 00084.75, 00105.75 ]",
    "#0000007627 NoMotion-1. Scaled. Acc [ -00054.69, -00009.77, 01000.00 ], Gyr [ 00000.24, 00000.79, -00001.46 ], Mag [ 00018.45, 00084.75, 00105.75 ]",
    "#0000007628 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00988.77 ], Gyr [ 00000.49, 00002.93, -00000.98 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000007629 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00987.30 ], Gyr [ -00000.49, 00001.71, -00001.52 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000007630 NoMotion-1. Scaled. Acc [ -00053.22, -00007.32, 00992.68 ], Gyr [ 00000.61, 00001.89, 00000.37 ], Mag [ 00020.40, 00082.95, 00106.20 ]",
    "#0000007631 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00996.09 ], Gyr [ 00000.55, 00000.73, -00001.04 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007632 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00994.63 ], Gyr [ 00000.24, 00003.90, -00001.40 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007633 NoMotion-1. Scaled. Acc [ -00033.69, 00002.93, 00991.70 ], Gyr [ 00002.50, 00002.01, -00001.52 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007634 NoMotion-1. Scaled. Acc [ -00063.96, -00005.86, 00994.63 ], Gyr [ 00002.26, -00000.55, 00001.28 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007635 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00978.03 ], Gyr [ 00001.04, 00001.04, -00002.26 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007636 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 01005.37 ], Gyr [ -00001.71, 00000.12, -00003.29 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007637 NoMotion-1. Scaled. Acc [ -00040.04, -00004.39, 00984.86 ], Gyr [ 00000.12, 00001.16, 00000.43 ], Mag [ 00020.70, 00084.00, 00109.35 ]",
    "#0000007638 NoMotion-1. Scaled. Acc [ -00042.97, -00004.39, 00983.89 ], Gyr [ 00001.28, 00001.28, 00001.52 ], Mag [ 00020.70, 00084.00, 00109.35 ]",
    "#0000007639 NoMotion-1. Scaled. Acc [ -00033.69, -00005.86, 00978.52 ], Gyr [ -00000.43, 00001.46, -00001.59 ], Mag [ 00019.95, 00082.50, 00107.85 ]",
    "#0000007640 NoMotion-1. Scaled. Acc [ -00039.55, 00000.00, 00988.77 ], Gyr [ 00002.13, 00000.73, -00003.35 ], Mag [ 00019.95, 00082.50, 00107.85 ]",
    "#0000007641 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00986.33 ], Gyr [ -00000.91, 00001.34, -00000.98 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007642 NoMotion-1. Scaled. Acc [ -00049.80, -00010.74, 00983.89 ], Gyr [ 00000.00, 00001.71, -00003.41 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007643 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00988.77 ], Gyr [ -00000.73, 00002.44, -00002.50 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007644 NoMotion-1. Scaled. Acc [ -00034.67, -00006.35, 00990.23 ], Gyr [ 00000.24, 00001.89, -00000.67 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007645 NoMotion-1. Scaled. Acc [ -00044.43, 00000.49, 00988.77 ], Gyr [ 00000.43, -00000.12, -00002.13 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007646 NoMotion-1. Scaled. Acc [ -00048.34, 00000.00, 00991.70 ], Gyr [ 00002.01, -00000.43, -00000.43 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007647 NoMotion-1. Scaled. Acc [ -00048.34, -00002.93, 00986.82 ], Gyr [ 00000.67, -00001.10, -00002.62 ], Mag [ 00019.65, 00082.65, 00108.45 ]",
    "#0000007648 NoMotion-1. Scaled. Acc [ -00042.48, -00014.65, 00982.91 ], Gyr [ 00000.79, -00000.12, 00000.30 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000007649 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00988.28 ], Gyr [ -00001.16, 00003.96, -00004.09 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000007650 NoMotion-1. Scaled. Acc [ -00047.85, -00004.88, 00995.61 ], Gyr [ 00001.89, 00003.29, -00002.07 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007651 NoMotion-1. Scaled. Acc [ -00046.88, 00000.98, 00987.30 ], Gyr [ -00001.40, 00001.65, -00000.79 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007652 NoMotion-1. Scaled. Acc [ -00050.78, -00011.23, 00988.77 ], Gyr [ -00000.18, 00003.66, -00002.13 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007653 NoMotion-1. Scaled. Acc [ -00048.83, 00000.49, 00997.07 ], Gyr [ 00001.59, 00001.10, -00000.73 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007654 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00983.40 ], Gyr [ 00001.10, -00000.61, -00001.22 ], Mag [ 00019.65, 00082.65, 00106.65 ]",
    "#0000007655 NoMotion-1. Scaled. Acc [ -00035.64, -00008.30, 00994.63 ], Gyr [ 00001.04, 00000.85, 00001.04 ], Mag [ 00019.65, 00082.65, 00106.65 ]",
    "#0000007656 NoMotion-1. Scaled. Acc [ -00050.78, -00006.84, 00992.19 ], Gyr [ 00000.67, 00002.99, -00001.83 ], Mag [ 00020.40, 00082.65, 00106.95 ]",
    "#0000007657 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00995.12 ], Gyr [ 00002.50, 00000.55, -00002.20 ], Mag [ 00020.40, 00082.65, 00106.95 ]",
    "#0000007658 NoMotion-1. Scaled. Acc [ -00032.23, -00023.44, 00978.03 ], Gyr [ 00000.85, -00002.38, -00000.67 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007659 NoMotion-1. Scaled. Acc [ -00043.95, -00004.88, 00988.28 ], Gyr [ 00002.68, 00001.65, 00000.00 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007660 NoMotion-1. Scaled. Acc [ -00032.23, -00010.25, 00991.70 ], Gyr [ -00000.61, -00000.79, 00000.18 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007661 NoMotion-1. Scaled. Acc [ -00045.90, -00008.79, 00997.56 ], Gyr [ -00001.04, 00000.67, -00001.16 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007662 NoMotion-1. Scaled. Acc [ -00047.85, 00000.98, 00998.54 ], Gyr [ 00000.79, -00000.55, -00000.98 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007663 NoMotion-1. Scaled. Acc [ -00046.39, -00012.70, 00996.09 ], Gyr [ -00000.73, 00001.77, -00003.48 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007664 NoMotion-1. Scaled. Acc [ -00041.02, -00012.21, 00985.84 ], Gyr [ -00001.16, 00001.10, -00002.07 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007665 NoMotion-1. Scaled. Acc [ -00048.83, -00011.23, 00984.38 ], Gyr [ 00001.10, -00001.95, -00001.52 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007666 NoMotion-1. Scaled. Acc [ -00042.48, -00005.86, 00997.07 ], Gyr [ 00001.22, 00001.22, -00001.28 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007667 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00991.70 ], Gyr [ 00000.91, 00001.52, 00000.67 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007668 NoMotion-1. Scaled. Acc [ -00043.46, -00014.16, 00987.79 ], Gyr [ -00000.73, 00001.71, 00000.00 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007669 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00994.14 ], Gyr [ 00002.74, 00000.98, -00000.55 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007670 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00999.51 ], Gyr [ 00002.62, -00000.12, -00002.87 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000007671 NoMotion-1. Scaled. Acc [ -00061.04, -00005.37, 01002.44 ], Gyr [ 00001.52, 00001.83, -00002.07 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000007672 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00984.38 ], Gyr [ 00000.24, 00001.46, -00001.04 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007673 NoMotion-1. Scaled. Acc [ -00040.53, -00009.77, 00983.89 ], Gyr [ 00000.43, 00002.13, 00000.49 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007674 NoMotion-1. Scaled. Acc [ -00039.55, -00010.74, 00982.91 ], Gyr [ 00000.67, 00004.76, -00003.96 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007675 NoMotion-1. Scaled. Acc [ -00040.04, -00006.84, 01003.42 ], Gyr [ 00001.89, -00000.43, -00000.37 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007676 NoMotion-1. Scaled. Acc [ -00056.15, -00009.28, 00991.70 ], Gyr [ -00000.49, 00002.74, -00002.44 ], Mag [ 00019.65, 00084.15, 00108.75 ]",
    "#0000007677 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00983.40 ], Gyr [ 00001.95, -00000.61, -00001.65 ], Mag [ 00019.65, 00084.15, 00108.75 ]",
    "#0000007678 NoMotion-1. Scaled. Acc [ -00044.43, -00013.67, 00991.21 ], Gyr [ 00001.46, 00000.37, -00000.61 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000007679 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 00993.65 ], Gyr [ 00000.49, 00001.16, -00002.80 ], Mag [ 00018.45, 00083.55, 00106.50 ]",
    "#0000007680 NoMotion-1. Scaled. Acc [ -00050.78, -00014.16, 00983.89 ], Gyr [ 00002.62, 00001.89, -00001.34 ], Mag [ 00018.45, 00083.55, 00106.50 ]",
    "#0000007681 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00991.70 ], Gyr [ 00002.50, 00003.66, -00003.54 ], Mag [ 00018.45, 00083.55, 00106.50 ]",
    "#0000007682 NoMotion-1. Scaled. Acc [ -00051.76, -00013.67, 00985.35 ], Gyr [ -00001.04, 00000.43, -00002.32 ], Mag [ 00018.60, 00082.20, 00108.45 ]",
    "#0000007683 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00997.56 ], Gyr [ 00000.79, 00001.89, -00001.71 ], Mag [ 00019.05, 00084.15, 00107.70 ]",
    "#0000007684 NoMotion-1. Scaled. Acc [ -00053.22, -00003.91, 00997.56 ], Gyr [ 00000.55, 00002.99, -00002.50 ], Mag [ 00019.05, 00084.15, 00107.70 ]",
    "#0000007685 NoMotion-1. Scaled. Acc [ -00031.74, -00003.42, 00990.23 ], Gyr [ 00001.10, 00000.55, -00003.35 ], Mag [ 00018.45, 00083.25, 00106.50 ]",
    "#0000007686 NoMotion-1. Scaled. Acc [ -00045.41, 00002.93, 00999.51 ], Gyr [ -00001.22, 00002.56, -00001.65 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007687 NoMotion-1. Scaled. Acc [ -00042.48, -00024.41, 00991.21 ], Gyr [ 00000.43, 00000.55, 00002.56 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007688 NoMotion-1. Scaled. Acc [ -00033.20, 00004.88, 00985.35 ], Gyr [ -00001.04, 00001.16, -00001.83 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007689 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 00991.21 ], Gyr [ 00000.06, 00003.29, -00001.59 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007690 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00994.14 ], Gyr [ 00001.28, 00002.07, 00000.06 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007691 NoMotion-1. Scaled. Acc [ -00038.09, -00005.86, 00989.26 ], Gyr [ 00001.52, -00000.85, 00000.91 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007692 NoMotion-1. Scaled. Acc [ -00039.06, -00015.62, 00986.33 ], Gyr [ -00001.46, 00004.09, -00001.28 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000007693 NoMotion-1. Scaled. Acc [ -00044.92, 00009.28, 00998.05 ], Gyr [ -00000.79, 00002.68, -00002.01 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000007694 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00990.23 ], Gyr [ 00000.18, 00000.85, -00003.60 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000007695 NoMotion-1. Scaled. Acc [ -00036.62, -00007.81, 00984.86 ], Gyr [ 00001.04, 00001.16, -00001.95 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000007696 NoMotion-1. Scaled. Acc [ -00041.99, -00003.42, 00998.05 ], Gyr [ 00000.61, 00000.18, -00002.26 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007697 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00991.21 ], Gyr [ 00000.30, 00004.63, -00000.37 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007698 NoMotion-1. Scaled. Acc [ -00051.27, -00014.65, 00996.09 ], Gyr [ -00001.40, 00003.11, -00001.71 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007699 NoMotion-1. Scaled. Acc [ -00048.83, 00003.42, 00997.07 ], Gyr [ -00000.49, 00002.74, -00000.79 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007700 NoMotion-1. Scaled. Acc [ -00045.90, -00004.88, 01000.98 ], Gyr [ 00000.67, 00001.04, -00003.05 ], Mag [ 00019.95, 00083.25, 00107.55 ]",
    "#0000007701 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00980.47 ], Gyr [ -00000.12, 00002.01, -00000.30 ], Mag [ 00019.95, 00083.25, 00107.55 ]",
    "#0000007702 NoMotion-1. Scaled. Acc [ -00037.11, -00014.16, 00994.63 ], Gyr [ -00000.24, 00000.67, -00003.17 ], Mag [ 00018.75, 00084.00, 00108.60 ]",
    "#0000007703 NoMotion-1. Scaled. Acc [ -00043.95, -00013.67, 00984.86 ], Gyr [ 00000.55, -00000.06, -00001.59 ], Mag [ 00018.75, 00084.00, 00108.60 ]",
    "#0000007704 NoMotion-1. Scaled. Acc [ -00034.18, -00017.09, 00987.30 ], Gyr [ 00002.44, 00001.95, -00003.48 ], Mag [ 00018.75, 00083.25, 00106.50 ]",
    "#0000007705 NoMotion-1. Scaled. Acc [ -00038.09, -00019.04, 00972.66 ], Gyr [ 00000.55, 00000.49, 00002.38 ], Mag [ 00017.55, 00083.70, 00107.40 ]",
    "#0000007706 NoMotion-1. Scaled. Acc [ -00044.43, -00019.04, 00988.77 ], Gyr [ -00000.67, 00002.80, -00003.90 ], Mag [ 00017.55, 00083.70, 00107.40 ]",
    "#0000007707 NoMotion-1. Scaled. Acc [ -00037.60, -00010.74, 00983.40 ], Gyr [ 00002.93, -00001.89, 00000.12 ], Mag [ 00017.55, 00083.70, 00107.40 ]",
    "#0000007708 NoMotion-1. Scaled. Acc [ -00042.97, -00008.30, 00985.84 ], Gyr [ 00001.10, 00001.71, -00000.24 ], Mag [ 00019.65, 00085.65, 00109.50 ]",
    "#0000007709 NoMotion-1. Scaled. Acc [ -00035.64, -00006.84, 00998.05 ], Gyr [ 00000.98, 00000.43, -00001.46 ], Mag [ 00019.65, 00085.65, 00109.50 ]",
    "#0000007710 NoMotion-1. Scaled. Acc [ -00032.23, -00012.21, 00984.86 ], Gyr [ 00000.18, 00000.30, -00000.91 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000007711 NoMotion-1. Scaled. Acc [ -00048.34, -00012.21, 00991.70 ], Gyr [ -00000.98, 00005.67, -00003.54 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000007712 NoMotion-1. Scaled. Acc [ -00046.39, -00006.84, 00999.51 ], Gyr [ 00000.98, 00004.76, -00003.66 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000007713 NoMotion-1. Scaled. Acc [ -00032.23, -00007.81, 00977.05 ], Gyr [ 00000.00, 00002.44, -00001.28 ], Mag [ 00018.75, 00083.25, 00107.10 ]",
    "#0000007714 NoMotion-1. Scaled. Acc [ -00037.11, -00008.30, 00989.75 ], Gyr [ 00000.12, 00000.43, -00002.13 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007715 NoMotion-1. Scaled. Acc [ -00032.23, -00006.35, 00995.61 ], Gyr [ -00001.04, 00001.16, -00000.98 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007716 NoMotion-1. Scaled. Acc [ -00054.69, -00025.88, 00991.21 ], Gyr [ 00000.98, 00000.79, 00001.89 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007717 NoMotion-1. Scaled. Acc [ -00051.27, 00003.91, 00994.14 ], Gyr [ 00003.23, 00000.55, -00001.95 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007718 NoMotion-1. Scaled. Acc [ -00046.39, -00006.84, 01007.32 ], Gyr [ -00000.37, 00000.55, -00001.59 ], Mag [ 00019.20, 00084.75, 00107.85 ]",
    "#0000007719 NoMotion-1. Scaled. Acc [ -00055.18, 00000.00, 00991.21 ], Gyr [ 00002.80, 00000.49, -00001.28 ], Mag [ 00019.20, 00084.75, 00107.85 ]",
    "#0000007720 NoMotion-1. Scaled. Acc [ -00055.18, -00020.02, 00989.75 ], Gyr [ 00001.59, 00000.12, -00002.44 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007721 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00997.07 ], Gyr [ -00001.34, 00001.28, -00001.59 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007722 NoMotion-1. Scaled. Acc [ -00024.90, -00008.79, 00988.28 ], Gyr [ 00000.30, 00000.67, -00000.43 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007723 NoMotion-1. Scaled. Acc [ -00047.85, 00007.81, 00992.68 ], Gyr [ 00000.61, 00001.10, -00002.13 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007724 NoMotion-1. Scaled. Acc [ -00061.52, -00026.37, 00995.61 ], Gyr [ 00000.85, 00002.93, 00000.85 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007725 NoMotion-1. Scaled. Acc [ -00041.02, 00009.77, 00996.09 ], Gyr [ 00000.18, 00002.13, -00001.83 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007726 NoMotion-1. Scaled. Acc [ -00038.09, -00007.32, 00996.58 ], Gyr [ 00001.46, 00001.83, -00000.37 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007727 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00993.65 ], Gyr [ 00001.89, 00002.80, -00001.46 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000007728 NoMotion-1. Scaled. Acc [ -00049.32, -00009.77, 00989.26 ], Gyr [ 00002.01, 00000.67, -00001.22 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000007729 NoMotion-1. Scaled. Acc [ -00042.97, -00004.88, 00996.09 ], Gyr [ -00000.61, 00002.38, -00001.28 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007730 NoMotion-1. Scaled. Acc [ -00053.71, 00002.44, 00986.82 ], Gyr [ 00001.95, 00000.24, -00000.43 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007731 NoMotion-1. Scaled. Acc [ -00043.95, -00015.14, 00985.84 ], Gyr [ 00001.10, 00002.56, 00000.00 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007732 NoMotion-1. Scaled. Acc [ -00034.18, -00018.07, 00995.12 ], Gyr [ 00002.62, -00000.37, -00002.44 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007733 NoMotion-1. Scaled. Acc [ -00035.64, -00014.65, 00989.75 ], Gyr [ 00002.44, -00000.67, 00001.04 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007734 NoMotion-1. Scaled. Acc [ -00038.57, -00004.39, 00982.42 ], Gyr [ 00000.06, 00002.38, -00000.67 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007735 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 01000.00 ], Gyr [ 00002.01, 00002.13, -00002.38 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007736 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00977.05 ], Gyr [ 00000.37, 00000.61, -00002.20 ], Mag [ 00020.70, 00082.80, 00108.60 ]",
    "#0000007737 NoMotion-1. Scaled. Acc [ -00036.62, -00007.81, 00986.33 ], Gyr [ 00000.00, -00000.37, 00000.55 ], Mag [ 00020.70, 00082.80, 00108.60 ]",
    "#0000007738 NoMotion-1. Scaled. Acc [ -00060.06, -00014.65, 00995.61 ], Gyr [ -00000.85, 00002.32, -00004.21 ], Mag [ 00019.95, 00082.80, 00108.60 ]",
    "#0000007739 NoMotion-1. Scaled. Acc [ -00060.06, -00016.60, 00990.23 ], Gyr [ 00000.12, 00001.95, -00002.80 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000007740 NoMotion-1. Scaled. Acc [ -00052.25, -00003.42, 00986.82 ], Gyr [ 00001.46, -00000.49, -00000.79 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000007741 NoMotion-1. Scaled. Acc [ -00048.34, 00002.44, 00990.23 ], Gyr [ 00002.68, 00002.93, -00000.43 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007742 NoMotion-1. Scaled. Acc [ -00056.15, 00005.86, 00988.28 ], Gyr [ -00000.91, 00000.73, -00004.39 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007743 NoMotion-1. Scaled. Acc [ -00032.71, -00005.37, 00986.33 ], Gyr [ 00001.28, 00001.65, -00002.74 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007744 NoMotion-1. Scaled. Acc [ -00045.90, 00002.93, 00990.72 ], Gyr [ 00000.18, 00001.65, -00001.71 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007745 NoMotion-1. Scaled. Acc [ -00038.57, -00007.32, 00982.91 ], Gyr [ -00001.22, 00002.01, -00001.28 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007746 NoMotion-1. Scaled. Acc [ -00056.64, -00010.74, 00985.84 ], Gyr [ 00000.43, 00000.98, -00003.29 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007747 NoMotion-1. Scaled. Acc [ -00049.32, -00004.39, 00995.12 ], Gyr [ 00001.77, 00000.55, -00001.71 ], Mag [ 00019.50, 00084.30, 00107.85 ]",
    "#0000007748 NoMotion-1. Scaled. Acc [ -00039.55, -00003.42, 00985.84 ], Gyr [ 00000.18, 00004.88, -00002.13 ], Mag [ 00019.50, 00084.30, 00107.85 ]",
    "#0000007749 NoMotion-1. Scaled. Acc [ -00048.83, 00009.28, 00985.84 ], Gyr [ 00000.43, 00002.38, -00000.30 ], Mag [ 00019.20, 00084.30, 00107.85 ]",
    "#0000007750 NoMotion-1. Scaled. Acc [ -00025.88, -00001.46, 00987.79 ], Gyr [ -00001.22, 00003.66, -00003.90 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000007751 NoMotion-1. Scaled. Acc [ -00027.83, -00005.37, 00990.23 ], Gyr [ -00001.59, 00002.56, 00001.40 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000007752 NoMotion-1. Scaled. Acc [ -00046.88, -00017.09, 00988.77 ], Gyr [ -00000.24, 00002.26, -00003.05 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000007753 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00997.56 ], Gyr [ 00000.85, 00004.09, -00000.73 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000007754 NoMotion-1. Scaled. Acc [ -00036.62, -00017.58, 00986.82 ], Gyr [ 00002.20, -00000.49, -00003.05 ], Mag [ 00018.75, 00082.50, 00107.10 ]",
    "#0000007755 NoMotion-1. Scaled. Acc [ -00057.13, -00009.28, 00997.56 ], Gyr [ 00001.40, 00000.91, -00000.85 ], Mag [ 00018.75, 00082.50, 00107.10 ]",
    "#0000007756 NoMotion-1. Scaled. Acc [ -00038.57, 00000.98, 00995.12 ], Gyr [ 00000.73, 00002.68, -00001.83 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007757 NoMotion-1. Scaled. Acc [ -00053.71, -00002.44, 00992.68 ], Gyr [ -00000.37, -00000.12, -00002.13 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007758 NoMotion-1. Scaled. Acc [ -00051.27, -00015.14, 00988.28 ], Gyr [ 00002.07, 00002.99, 00000.37 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007759 NoMotion-1. Scaled. Acc [ -00048.83, -00013.67, 00991.21 ], Gyr [ 00003.05, 00000.24, -00002.07 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007760 NoMotion-1. Scaled. Acc [ -00049.80, -00008.30, 00986.33 ], Gyr [ 00000.61, 00000.24, -00002.26 ], Mag [ 00019.35, 00082.65, 00106.65 ]",
    "#0000007761 NoMotion-1. Scaled. Acc [ -00037.60, 00004.88, 00979.98 ], Gyr [ -00000.12, 00000.85, -00003.72 ], Mag [ 00019.35, 00082.65, 00106.65 ]",
    "#0000007762 NoMotion-1. Scaled. Acc [ -00049.80, -00017.58, 01004.88 ], Gyr [ -00000.55, 00001.59, -00003.66 ], Mag [ 00019.35, 00082.65, 00106.65 ]",
    "#0000007763 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00997.07 ], Gyr [ 00001.59, 00002.68, -00001.83 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000007764 NoMotion-1. Scaled. Acc [ -00046.88, -00012.70, 01005.37 ], Gyr [ -00000.91, 00001.28, -00000.55 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000007765 NoMotion-1. Scaled. Acc [ -00029.30, -00013.67, 00985.35 ], Gyr [ 00000.37, 00001.65, -00002.56 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000007766 NoMotion-1. Scaled. Acc [ -00042.97, -00019.04, 00991.21 ], Gyr [ 00000.91, 00003.05, -00000.24 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000007767 NoMotion-1. Scaled. Acc [ -00054.20, 00000.49, 00986.82 ], Gyr [ 00000.91, 00002.26, -00000.49 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007768 NoMotion-1. Scaled. Acc [ -00041.99, 00003.91, 00982.91 ], Gyr [ 00001.40, 00001.77, -00002.01 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007769 NoMotion-1. Scaled. Acc [ -00041.50, 00001.46, 00997.07 ], Gyr [ 00001.28, 00000.91, -00002.20 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007770 NoMotion-1. Scaled. Acc [ -00034.67, -00002.44, 00992.68 ], Gyr [ 00000.73, 00001.34, -00002.01 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007771 NoMotion-1. Scaled. Acc [ -00045.41, -00016.60, 00986.33 ], Gyr [ 00001.34, 00000.73, -00000.79 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007772 NoMotion-1. Scaled. Acc [ -00039.06, -00003.42, 00995.61 ], Gyr [ 00000.91, 00001.16, -00002.93 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000007773 NoMotion-1. Scaled. Acc [ -00051.27, -00003.91, 00988.77 ], Gyr [ 00000.24, 00001.16, -00000.49 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000007774 NoMotion-1. Scaled. Acc [ -00042.97, -00016.60, 00985.84 ], Gyr [ -00000.49, 00000.55, -00002.62 ], Mag [ 00020.40, 00084.90, 00107.70 ]",
    "#0000007775 NoMotion-1. Scaled. Acc [ -00040.04, -00007.81, 00994.63 ], Gyr [ 00000.00, -00000.49, -00002.32 ], Mag [ 00020.40, 00084.90, 00107.70 ]",
    "#0000007776 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00993.16 ], Gyr [ -00000.12, 00000.98, -00001.83 ], Mag [ 00020.25, 00084.00, 00106.80 ]",
    "#0000007777 NoMotion-1. Scaled. Acc [ -00047.85, -00013.67, 00986.33 ], Gyr [ 00001.95, 00003.78, 00000.00 ], Mag [ 00020.25, 00084.00, 00106.80 ]",
    "#0000007778 NoMotion-1. Scaled. Acc [ -00059.57, -00010.25, 00989.75 ], Gyr [ 00000.24, 00002.32, -00002.68 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000007779 NoMotion-1. Scaled. Acc [ -00053.71, -00007.81, 00988.77 ], Gyr [ 00000.55, 00000.79, -00000.12 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000007780 NoMotion-1. Scaled. Acc [ -00056.15, -00020.51, 00993.16 ], Gyr [ 00001.04, 00001.59, -00001.77 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000007781 NoMotion-1. Scaled. Acc [ -00050.78, -00012.21, 00987.30 ], Gyr [ 00002.87, 00000.49, -00000.30 ], Mag [ 00018.45, 00082.80, 00107.55 ]",
    "#0000007782 NoMotion-1. Scaled. Acc [ -00049.80, -00010.25, 00990.23 ], Gyr [ 00001.65, 00001.22, -00000.12 ], Mag [ 00018.45, 00082.80, 00107.55 ]",
    "#0000007783 NoMotion-1. Scaled. Acc [ -00032.71, -00002.93, 00989.26 ], Gyr [ 00002.68, -00001.89, -00001.52 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007784 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00981.93 ], Gyr [ -00001.10, 00001.46, -00000.43 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007785 NoMotion-1. Scaled. Acc [ -00046.88, -00004.88, 00996.09 ], Gyr [ -00000.37, 00002.87, -00003.54 ], Mag [ 00018.60, 00082.65, 00106.65 ]",
    "#0000007786 NoMotion-1. Scaled. Acc [ -00042.48, -00010.25, 00989.75 ], Gyr [ -00000.30, -00001.59, -00000.24 ], Mag [ 00018.60, 00082.65, 00106.65 ]",
    "#0000007787 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00991.21 ], Gyr [ 00002.20, -00000.06, -00001.22 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000007788 NoMotion-1. Scaled. Acc [ -00047.36, -00001.95, 00998.54 ], Gyr [ -00001.46, 00001.40, -00001.52 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000007789 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 00986.33 ], Gyr [ 00001.71, 00006.89, -00003.78 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000007790 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00995.61 ], Gyr [ 00000.67, 00002.38, -00003.66 ], Mag [ 00018.60, 00083.70, 00109.05 ]",
    "#0000007791 NoMotion-1. Scaled. Acc [ -00051.27, -00012.21, 00997.07 ], Gyr [ 00001.28, 00001.40, 00000.24 ], Mag [ 00020.70, 00082.50, 00106.50 ]",
    "#0000007792 NoMotion-1. Scaled. Acc [ -00046.39, -00012.21, 00989.75 ], Gyr [ 00001.77, 00001.04, -00001.34 ], Mag [ 00020.70, 00082.50, 00106.50 ]",
    "#0000007793 NoMotion-1. Scaled. Acc [ -00053.22, -00011.23, 00987.79 ], Gyr [ 00001.10, 00000.67, -00000.98 ], Mag [ 00019.95, 00083.25, 00107.10 ]",
    "#0000007794 NoMotion-1. Scaled. Acc [ -00042.97, -00006.35, 00983.89 ], Gyr [ 00000.00, 00001.77, -00003.05 ], Mag [ 00019.95, 00083.25, 00107.10 ]",
    "#0000007795 NoMotion-1. Scaled. Acc [ -00041.02, 00001.46, 00984.38 ], Gyr [ 00001.89, 00001.40, 00001.83 ], Mag [ 00019.95, 00083.25, 00107.10 ]",
    "#0000007796 NoMotion-1. Scaled. Acc [ -00039.06, -00021.97, 00986.33 ], Gyr [ -00000.79, 00002.32, -00001.65 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000007797 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00985.35 ], Gyr [ -00000.79, 00000.98, -00001.52 ], Mag [ 00017.70, 00084.30, 00108.30 ]",
    "#0000007798 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 01005.86 ], Gyr [ -00001.04, 00003.29, -00002.44 ], Mag [ 00017.70, 00084.30, 00108.30 ]",
    "#0000007799 NoMotion-1. Scaled. Acc [ -00033.69, -00008.30, 00987.30 ], Gyr [ -00000.24, 00002.01, 00000.79 ], Mag [ 00017.70, 00084.30, 00108.30 ]",
    "#0000007800 NoMotion-1. Scaled. Acc [ -00043.46, -00011.72, 00999.02 ], Gyr [ 00000.24, 00002.62, -00004.15 ], Mag [ 00018.75, 00084.00, 00107.55 ]",
    "#0000007801 NoMotion-1. Scaled. Acc [ -00038.57, 00005.86, 00992.19 ], Gyr [ -00000.55, 00002.62, -00004.76 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007802 NoMotion-1. Scaled. Acc [ -00042.97, 00005.86, 00986.33 ], Gyr [ 00000.30, 00000.61, -00002.74 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007803 NoMotion-1. Scaled. Acc [ -00050.78, -00013.67, 01002.93 ], Gyr [ 00000.06, -00000.67, -00001.28 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007804 NoMotion-1. Scaled. Acc [ -00031.25, -00003.91, 00991.70 ], Gyr [ 00001.28, 00001.95, -00000.79 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007805 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00980.47 ], Gyr [ 00001.59, 00002.62, 00001.77 ], Mag [ 00019.65, 00084.90, 00107.70 ]",
    "#0000007806 NoMotion-1. Scaled. Acc [ -00040.53, 00003.42, 00989.26 ], Gyr [ 00002.80, 00002.26, -00001.28 ], Mag [ 00019.65, 00084.90, 00107.70 ]",
    "#0000007807 NoMotion-1. Scaled. Acc [ -00026.86, -00003.91, 00983.89 ], Gyr [ 00001.34, -00000.91, -00000.18 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007808 NoMotion-1. Scaled. Acc [ -00041.99, -00004.39, 00989.26 ], Gyr [ -00001.04, 00000.49, -00000.12 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007809 NoMotion-1. Scaled. Acc [ -00047.36, -00002.44, 00998.05 ], Gyr [ -00000.67, 00001.16, -00002.38 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000007810 NoMotion-1. Scaled. Acc [ -00039.55, -00008.30, 00998.05 ], Gyr [ 00001.10, 00000.85, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000007811 NoMotion-1. Scaled. Acc [ -00057.62, -00004.39, 00991.70 ], Gyr [ 00000.18, 00001.16, -00000.79 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007812 NoMotion-1. Scaled. Acc [ -00037.60, -00011.23, 00982.91 ], Gyr [ -00000.79, 00002.62, -00003.05 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007813 NoMotion-1. Scaled. Acc [ -00041.02, 00000.98, 00994.14 ], Gyr [ -00002.13, 00002.99, -00001.59 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007814 NoMotion-1. Scaled. Acc [ -00047.36, -00016.60, 00986.82 ], Gyr [ 00000.43, 00001.28, -00000.30 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007815 NoMotion-1. Scaled. Acc [ -00047.36, -00016.11, 00990.72 ], Gyr [ 00002.50, 00000.67, -00002.74 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007816 NoMotion-1. Scaled. Acc [ -00037.60, -00000.98, 00981.45 ], Gyr [ 00000.55, 00003.41, 00000.06 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007817 NoMotion-1. Scaled. Acc [ -00039.55, -00016.11, 00999.02 ], Gyr [ -00000.79, 00001.40, -00002.50 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007818 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00993.16 ], Gyr [ 00002.38, 00000.00, -00002.26 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007819 NoMotion-1. Scaled. Acc [ -00033.69, -00004.39, 01002.93 ], Gyr [ 00000.18, -00000.98, 00000.85 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007820 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00985.35 ], Gyr [ 00000.37, 00000.24, -00001.77 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007821 NoMotion-1. Scaled. Acc [ -00055.18, -00015.14, 00985.84 ], Gyr [ 00000.43, -00001.65, -00002.38 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007822 NoMotion-1. Scaled. Acc [ -00045.90, -00009.28, 01000.98 ], Gyr [ 00001.40, -00001.40, 00002.20 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007823 NoMotion-1. Scaled. Acc [ -00038.09, -00013.18, 00991.21 ], Gyr [ -00001.34, 00000.91, -00003.84 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007824 NoMotion-1. Scaled. Acc [ -00053.22, 00008.30, 00991.70 ], Gyr [ -00001.71, 00003.84, 00000.30 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007825 NoMotion-1. Scaled. Acc [ -00048.34, -00002.44, 00993.16 ], Gyr [ -00000.12, 00003.54, -00001.52 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007826 NoMotion-1. Scaled. Acc [ -00039.06, -00007.32, 00991.70 ], Gyr [ 00002.44, 00002.87, -00000.43 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007827 NoMotion-1. Scaled. Acc [ -00039.55, -00010.74, 00999.02 ], Gyr [ -00000.18, 00001.28, -00000.24 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007828 NoMotion-1. Scaled. Acc [ -00029.30, -00009.77, 00988.77 ], Gyr [ 00001.10, 00001.52, 00000.43 ], Mag [ 00019.35, 00084.45, 00107.40 ]",
    "#0000007829 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 01000.98 ], Gyr [ 00000.55, 00001.40, -00001.95 ], Mag [ 00019.50, 00082.50, 00106.80 ]",
    "#0000007830 NoMotion-1. Scaled. Acc [ -00031.74, -00003.91, 00988.77 ], Gyr [ 00002.62, 00001.34, -00001.16 ], Mag [ 00019.50, 00082.50, 00106.80 ]",
    "#0000007831 NoMotion-1. Scaled. Acc [ -00043.95, -00004.88, 00995.61 ], Gyr [ -00000.37, 00001.65, -00000.98 ], Mag [ 00019.50, 00082.50, 00106.80 ]",
    "#0000007832 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00984.38 ], Gyr [ 00001.28, 00003.60, 00000.24 ], Mag [ 00019.50, 00082.80, 00106.80 ]",
    "#0000007833 NoMotion-1. Scaled. Acc [ -00038.09, -00008.30, 00990.23 ], Gyr [ -00000.18, 00000.55, -00003.05 ], Mag [ 00019.50, 00082.80, 00106.80 ]",
    "#0000007834 NoMotion-1. Scaled. Acc [ -00037.60, -00001.95, 00997.56 ], Gyr [ -00000.79, 00000.49, -00000.55 ], Mag [ 00017.55, 00083.40, 00107.40 ]",
    "#0000007835 NoMotion-1. Scaled. Acc [ -00043.95, -00002.93, 00981.93 ], Gyr [ 00002.50, 00004.27, -00000.55 ], Mag [ 00017.55, 00083.40, 00107.40 ]",
    "#0000007836 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00990.23 ], Gyr [ 00000.49, 00004.27, -00002.87 ], Mag [ 00017.55, 00083.40, 00107.40 ]",
    "#0000007837 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 00988.28 ], Gyr [ -00001.77, 00000.85, -00003.17 ], Mag [ 00020.70, 00084.75, 00108.90 ]",
    "#0000007838 NoMotion-1. Scaled. Acc [ -00048.34, -00017.58, 00979.98 ], Gyr [ 00001.52, 00001.04, -00000.18 ], Mag [ 00020.70, 00084.75, 00108.90 ]",
    "#0000007839 NoMotion-1. Scaled. Acc [ -00039.55, 00005.86, 00992.19 ], Gyr [ -00000.85, 00001.04, -00000.06 ], Mag [ 00019.95, 00083.55, 00107.10 ]",
    "#0000007840 NoMotion-1. Scaled. Acc [ -00056.64, -00002.93, 00992.19 ], Gyr [ 00000.43, 00002.99, -00001.77 ], Mag [ 00020.40, 00083.55, 00107.10 ]",
    "#0000007841 NoMotion-1. Scaled. Acc [ -00036.13, -00005.86, 00984.86 ], Gyr [ 00001.16, -00002.01, 00000.85 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007842 NoMotion-1. Scaled. Acc [ -00047.85, -00007.32, 00996.58 ], Gyr [ 00001.71, -00001.28, -00001.34 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007843 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00988.77 ], Gyr [ 00000.12, 00001.77, -00002.26 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007844 NoMotion-1. Scaled. Acc [ -00048.34, -00012.70, 00999.51 ], Gyr [ 00001.65, -00001.65, 00000.67 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007845 NoMotion-1. Scaled. Acc [ -00049.80, -00006.35, 00987.30 ], Gyr [ 00002.13, -00000.18, -00002.13 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007846 NoMotion-1. Scaled. Acc [ -00041.50, 00001.95, 00980.47 ], Gyr [ 00000.24, 00002.93, -00000.98 ], Mag [ 00019.95, 00084.00, 00106.50 ]",
    "#0000007847 NoMotion-1. Scaled. Acc [ -00034.67, 00000.98, 00987.79 ], Gyr [ -00000.18, 00005.85, -00002.44 ], Mag [ 00019.95, 00084.00, 00106.50 ]",
    "#0000007848 NoMotion-1. Scaled. Acc [ -00040.53, -00014.65, 00990.23 ], Gyr [ 00000.49, 00001.89, -00001.34 ], Mag [ 00021.00, 00085.05, 00109.35 ]",
    "#0000007849 NoMotion-1. Scaled. Acc [ -00051.27, -00009.28, 00991.70 ], Gyr [ 00000.91, 00002.20, -00001.40 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007850 NoMotion-1. Scaled. Acc [ -00048.34, -00001.95, 01000.49 ], Gyr [ 00000.30, -00000.24, 00000.98 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007851 NoMotion-1. Scaled. Acc [ -00040.53, -00001.95, 00989.75 ], Gyr [ 00000.73, 00002.07, -00002.07 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000007852 NoMotion-1. Scaled. Acc [ -00045.90, -00001.46, 01000.49 ], Gyr [ 00000.00, 00001.28, -00000.73 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000007853 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 01001.46 ], Gyr [ 00001.16, 00000.91, -00002.80 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000007854 NoMotion-1. Scaled. Acc [ -00036.13, -00006.84, 00983.89 ], Gyr [ 00002.62, 00002.07, -00002.44 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007855 NoMotion-1. Scaled. Acc [ -00046.39, -00010.74, 00989.26 ], Gyr [ 00001.40, 00000.24, -00002.26 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007856 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00994.63 ], Gyr [ 00000.30, 00001.52, -00001.04 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000007857 NoMotion-1. Scaled. Acc [ -00036.13, -00009.77, 00990.23 ], Gyr [ 00000.43, 00003.17, -00000.91 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000007858 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00985.35 ], Gyr [ 00000.12, 00007.32, -00001.77 ], Mag [ 00018.60, 00084.90, 00107.70 ]",
    "#0000007859 NoMotion-1. Scaled. Acc [ -00039.55, -00007.81, 00998.05 ], Gyr [ -00000.61, 00001.52, -00000.79 ], Mag [ 00018.60, 00084.90, 00107.70 ]",
    "#0000007860 NoMotion-1. Scaled. Acc [ -00038.09, -00002.93, 00980.47 ], Gyr [ 00001.89, 00000.55, 00000.79 ], Mag [ 00020.10, 00083.70, 00105.90 ]",
    "#0000007861 NoMotion-1. Scaled. Acc [ -00037.60, -00011.72, 00989.26 ], Gyr [ 00001.10, 00001.40, -00003.48 ], Mag [ 00020.10, 00083.70, 00105.90 ]",
    "#0000007862 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00996.58 ], Gyr [ 00001.22, 00002.56, 00000.06 ], Mag [ 00020.10, 00083.70, 00105.90 ]",
    "#0000007863 NoMotion-1. Scaled. Acc [ -00022.95, -00011.23, 00978.03 ], Gyr [ 00002.56, 00001.34, 00001.16 ], Mag [ 00021.30, 00083.55, 00108.30 ]",
    "#0000007864 NoMotion-1. Scaled. Acc [ -00044.43, -00007.81, 01006.35 ], Gyr [ 00001.65, 00002.74, -00004.45 ], Mag [ 00021.30, 00083.55, 00108.30 ]",
    "#0000007865 NoMotion-1. Scaled. Acc [ -00030.27, -00001.95, 00983.89 ], Gyr [ 00001.04, 00001.77, 00000.73 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007866 NoMotion-1. Scaled. Acc [ -00040.53, 00004.88, 00989.26 ], Gyr [ 00000.43, 00001.83, -00002.13 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007867 NoMotion-1. Scaled. Acc [ -00045.90, -00001.95, 00978.52 ], Gyr [ 00000.00, 00001.52, 00000.91 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000007868 NoMotion-1. Scaled. Acc [ -00043.46, -00005.86, 00989.75 ], Gyr [ 00001.04, -00001.95, -00000.61 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000007869 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00985.84 ], Gyr [ 00001.22, 00002.93, 00000.00 ], Mag [ 00019.95, 00082.05, 00107.85 ]",
    "#0000007870 NoMotion-1. Scaled. Acc [ -00043.46, -00000.49, 00993.65 ], Gyr [ 00000.37, 00001.46, -00003.72 ], Mag [ 00019.95, 00082.05, 00107.85 ]",
    "#0000007871 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00997.07 ], Gyr [ 00000.98, 00001.46, -00001.28 ], Mag [ 00019.95, 00082.05, 00107.85 ]",
    "#0000007872 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00991.21 ], Gyr [ 00000.61, 00001.71, -00000.37 ], Mag [ 00019.50, 00084.00, 00105.75 ]",
    "#0000007873 NoMotion-1. Scaled. Acc [ -00033.20, -00011.23, 00986.33 ], Gyr [ -00000.55, 00000.98, -00003.05 ], Mag [ 00019.50, 00084.00, 00105.75 ]",
    "#0000007874 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00995.61 ], Gyr [ -00001.28, 00001.40, 00000.12 ], Mag [ 00020.25, 00085.05, 00107.10 ]",
    "#0000007875 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00999.51 ], Gyr [ 00000.79, 00001.95, -00000.79 ], Mag [ 00020.25, 00085.05, 00107.10 ]",
    "#0000007876 NoMotion-1. Scaled. Acc [ -00038.09, -00002.93, 00994.63 ], Gyr [ 00001.71, 00001.46, -00001.89 ], Mag [ 00020.25, 00085.05, 00107.10 ]",
    "#0000007877 NoMotion-1. Scaled. Acc [ -00048.34, 00000.49, 00992.19 ], Gyr [ 00000.55, 00002.13, -00003.11 ], Mag [ 00018.15, 00082.80, 00107.85 ]",
    "#0000007878 NoMotion-1. Scaled. Acc [ -00047.36, -00013.18, 00994.63 ], Gyr [ -00000.18, 00001.40, -00002.32 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007879 NoMotion-1. Scaled. Acc [ -00052.73, -00003.91, 00983.89 ], Gyr [ -00000.61, 00003.41, -00000.73 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007880 NoMotion-1. Scaled. Acc [ -00050.78, -00016.60, 00999.02 ], Gyr [ -00000.24, 00002.26, -00001.10 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007881 NoMotion-1. Scaled. Acc [ -00032.71, -00012.21, 01006.35 ], Gyr [ 00001.52, 00002.01, -00000.30 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007882 NoMotion-1. Scaled. Acc [ -00040.04, -00014.16, 00992.19 ], Gyr [ 00000.37, -00000.43, -00000.61 ], Mag [ 00018.45, 00083.55, 00107.55 ]",
    "#0000007883 NoMotion-1. Scaled. Acc [ -00042.97, -00000.49, 00985.84 ], Gyr [ 00001.28, 00000.67, -00000.79 ], Mag [ 00018.45, 00083.55, 00107.55 ]",
    "#0000007884 NoMotion-1. Scaled. Acc [ -00047.36, -00015.62, 00987.79 ], Gyr [ 00002.68, 00000.98, 00000.43 ], Mag [ 00020.40, 00082.95, 00108.45 ]",
    "#0000007885 NoMotion-1. Scaled. Acc [ -00035.16, -00004.88, 00990.23 ], Gyr [ 00001.71, 00002.01, -00000.55 ], Mag [ 00020.40, 00082.95, 00108.45 ]",
    "#0000007886 NoMotion-1. Scaled. Acc [ -00035.64, -00007.32, 00999.02 ], Gyr [ -00000.18, 00002.26, -00003.29 ], Mag [ 00018.45, 00084.75, 00108.90 ]",
    "#0000007887 NoMotion-1. Scaled. Acc [ -00041.50, -00002.44, 00984.86 ], Gyr [ -00000.43, 00003.41, -00001.04 ], Mag [ 00018.45, 00084.75, 00108.90 ]",
    "#0000007888 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00990.72 ], Gyr [ -00001.77, 00000.73, -00002.50 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007889 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00996.58 ], Gyr [ 00000.06, 00003.23, -00002.13 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007890 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00984.38 ], Gyr [ -00001.46, 00001.65, -00002.99 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007891 NoMotion-1. Scaled. Acc [ -00045.41, 00000.00, 00995.12 ], Gyr [ 00000.49, 00002.50, 00000.73 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007892 NoMotion-1. Scaled. Acc [ -00049.80, -00012.21, 00990.72 ], Gyr [ 00001.46, 00001.52, -00001.77 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007893 NoMotion-1. Scaled. Acc [ -00038.09, -00009.77, 00998.54 ], Gyr [ 00000.49, 00002.62, -00001.34 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007894 NoMotion-1. Scaled. Acc [ -00051.76, -00004.39, 00987.79 ], Gyr [ -00000.06, 00001.95, -00001.71 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007895 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 00979.00 ], Gyr [ 00001.71, 00000.49, -00001.52 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000007896 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00986.82 ], Gyr [ 00001.16, 00000.49, -00000.98 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000007897 NoMotion-1. Scaled. Acc [ -00046.39, -00012.21, 00992.68 ], Gyr [ -00000.37, 00002.38, -00002.13 ], Mag [ 00021.00, 00082.80, 00107.85 ]",
    "#0000007898 NoMotion-1. Scaled. Acc [ -00036.13, -00012.70, 00989.26 ], Gyr [ 00001.16, 00000.98, 00000.55 ], Mag [ 00021.00, 00082.80, 00107.85 ]",
    "#0000007899 NoMotion-1. Scaled. Acc [ -00042.48, -00008.30, 00999.51 ], Gyr [ -00000.43, -00000.91, 00000.18 ], Mag [ 00021.00, 00082.80, 00107.85 ]",
    "#0000007900 NoMotion-1. Scaled. Acc [ -00035.16, -00003.42, 00989.75 ], Gyr [ 00000.55, 00002.13, -00001.34 ], Mag [ 00019.05, 00082.65, 00108.75 ]",
    "#0000007901 NoMotion-1. Scaled. Acc [ -00044.43, -00007.32, 00989.75 ], Gyr [ 00000.55, 00001.40, -00000.79 ], Mag [ 00019.05, 00082.65, 00108.75 ]",
    "#0000007902 NoMotion-1. Scaled. Acc [ -00060.55, -00008.79, 00991.21 ], Gyr [ -00000.43, 00003.41, -00003.05 ], Mag [ 00021.00, 00083.25, 00107.85 ]",
    "#0000007903 NoMotion-1. Scaled. Acc [ -00041.99, -00014.65, 00992.68 ], Gyr [ 00001.89, 00004.02, -00004.02 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000007904 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 01002.93 ], Gyr [ -00000.18, 00003.05, -00000.85 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000007905 NoMotion-1. Scaled. Acc [ -00040.04, -00000.49, 00999.51 ], Gyr [ -00000.43, 00000.73, -00001.28 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000007906 NoMotion-1. Scaled. Acc [ -00042.97, -00014.65, 00990.72 ], Gyr [ 00003.17, 00000.00, -00002.56 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007907 NoMotion-1. Scaled. Acc [ -00034.67, -00016.11, 00990.72 ], Gyr [ 00001.34, 00000.61, -00001.34 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007908 NoMotion-1. Scaled. Acc [ -00039.06, -00007.32, 00997.07 ], Gyr [ 00001.59, -00000.79, 00000.73 ], Mag [ 00020.25, 00084.30, 00107.55 ]",
    "#0000007909 NoMotion-1. Scaled. Acc [ -00039.06, -00015.14, 00976.56 ], Gyr [ -00001.83, 00001.40, -00004.39 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000007910 NoMotion-1. Scaled. Acc [ -00056.64, -00018.07, 00999.02 ], Gyr [ -00001.95, 00003.41, -00001.65 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000007911 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 01001.95 ], Gyr [ -00001.83, 00002.50, 00000.55 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000007912 NoMotion-1. Scaled. Acc [ -00056.15, -00011.23, 00995.12 ], Gyr [ -00001.10, -00002.38, -00000.55 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000007913 NoMotion-1. Scaled. Acc [ -00030.27, 00003.42, 00998.05 ], Gyr [ -00000.30, 00002.87, -00003.90 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000007914 NoMotion-1. Scaled. Acc [ -00057.62, -00010.74, 00995.61 ], Gyr [ 00001.16, 00002.38, -00000.37 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007915 NoMotion-1. Scaled. Acc [ -00040.53, -00011.72, 00998.54 ], Gyr [ 00000.24, 00000.98, -00002.20 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007916 NoMotion-1. Scaled. Acc [ -00056.15, -00016.11, 00997.07 ], Gyr [ 00002.38, 00003.48, 00001.59 ], Mag [ 00019.95, 00083.55, 00108.90 ]",
    "#0000007917 NoMotion-1. Scaled. Acc [ -00038.57, -00007.81, 00991.70 ], Gyr [ 00003.11, -00000.24, -00000.98 ], Mag [ 00019.95, 00083.55, 00108.90 ]",
    "#0000007918 NoMotion-1. Scaled. Acc [ -00046.39, -00012.21, 00995.61 ], Gyr [ 00001.65, 00002.38, -00003.48 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007919 NoMotion-1. Scaled. Acc [ -00041.99, -00007.81, 00993.16 ], Gyr [ 00002.56, 00001.83, 00000.55 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007920 NoMotion-1. Scaled. Acc [ -00033.20, -00007.32, 01002.44 ], Gyr [ 00000.85, -00001.83, 00000.30 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007921 NoMotion-1. Scaled. Acc [ -00039.06, -00004.39, 00988.28 ], Gyr [ 00000.73, 00002.07, -00003.72 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007922 NoMotion-1. Scaled. Acc [ -00042.48, -00005.37, 00979.00 ], Gyr [ -00000.79, 00001.28, -00001.89 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007923 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 01005.86 ], Gyr [ -00000.24, 00002.62, -00003.41 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007924 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 01000.00 ], Gyr [ 00002.20, 00000.12, 00000.06 ], Mag [ 00020.25, 00082.80, 00107.10 ]",
    "#0000007925 NoMotion-1. Scaled. Acc [ -00041.50, 00003.42, 00995.61 ], Gyr [ 00000.91, 00000.43, -00003.41 ], Mag [ 00020.25, 00082.80, 00107.10 ]",
    "#0000007926 NoMotion-1. Scaled. Acc [ -00050.78, -00001.46, 01002.93 ], Gyr [ 00000.91, -00000.30, -00001.10 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000007927 NoMotion-1. Scaled. Acc [ -00043.95, -00011.23, 00987.79 ], Gyr [ -00001.22, 00001.10, -00001.65 ], Mag [ 00019.20, 00082.80, 00108.60 ]",
    "#0000007928 NoMotion-1. Scaled. Acc [ -00044.43, -00002.93, 00992.19 ], Gyr [ 00000.55, 00000.61, -00002.87 ], Mag [ 00019.20, 00082.80, 00108.60 ]",
    "#0000007929 NoMotion-1. Scaled. Acc [ -00043.95, 00000.49, 00988.28 ], Gyr [ 00001.04, 00000.79, -00001.59 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007930 NoMotion-1. Scaled. Acc [ -00024.41, -00004.88, 00980.47 ], Gyr [ -00000.37, 00000.24, -00000.85 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007931 NoMotion-1. Scaled. Acc [ -00048.34, -00007.81, 00993.65 ], Gyr [ -00002.13, 00001.95, -00000.43 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007932 NoMotion-1. Scaled. Acc [ -00046.88, -00000.98, 00991.21 ], Gyr [ 00000.98, 00000.55, -00002.44 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007933 NoMotion-1. Scaled. Acc [ -00053.22, 00001.46, 00981.45 ], Gyr [ 00001.46, -00000.06, -00000.55 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007934 NoMotion-1. Scaled. Acc [ -00053.22, -00019.53, 00981.45 ], Gyr [ 00000.37, 00002.20, -00003.11 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007935 NoMotion-1. Scaled. Acc [ -00044.92, -00005.37, 00993.16 ], Gyr [ 00001.28, 00001.52, -00002.87 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007936 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00986.82 ], Gyr [ -00000.55, 00000.67, -00001.83 ], Mag [ 00020.10, 00083.70, 00106.20 ]",
    "#0000007937 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00995.61 ], Gyr [ 00000.30, 00002.38, -00001.40 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007938 NoMotion-1. Scaled. Acc [ -00041.02, -00005.86, 00991.21 ], Gyr [ 00003.35, 00000.98, -00000.30 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007939 NoMotion-1. Scaled. Acc [ -00035.64, -00008.30, 00991.21 ], Gyr [ 00001.34, -00000.98, 00002.68 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007940 NoMotion-1. Scaled. Acc [ -00041.50, 00002.93, 00990.23 ], Gyr [ -00000.24, 00003.17, -00003.60 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007941 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00991.21 ], Gyr [ 00000.98, 00001.16, 00001.16 ], Mag [ 00018.75, 00084.30, 00107.10 ]",
    "#0000007942 NoMotion-1. Scaled. Acc [ -00047.85, -00011.72, 00975.59 ], Gyr [ 00000.67, 00003.35, 00000.18 ], Mag [ 00018.75, 00084.30, 00107.10 ]",
    "#0000007943 NoMotion-1. Scaled. Acc [ -00040.04, -00010.74, 00994.63 ], Gyr [ 00001.59, 00001.89, -00000.61 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007944 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00980.47 ], Gyr [ -00000.37, 00002.38, -00001.83 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007945 NoMotion-1. Scaled. Acc [ -00045.90, -00017.09, 00984.86 ], Gyr [ 00001.83, 00000.37, 00000.06 ], Mag [ 00019.20, 00084.75, 00107.85 ]",
    "#0000007946 NoMotion-1. Scaled. Acc [ -00060.55, 00011.23, 00988.28 ], Gyr [ 00000.67, 00002.93, -00002.56 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007947 NoMotion-1. Scaled. Acc [ -00041.99, 00001.95, 00994.14 ], Gyr [ 00000.61, -00000.30, -00003.96 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007948 NoMotion-1. Scaled. Acc [ -00040.53, -00009.28, 00988.28 ], Gyr [ 00000.73, 00001.89, -00001.04 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007949 NoMotion-1. Scaled. Acc [ -00040.53, 00006.84, 00989.75 ], Gyr [ -00000.67, 00004.09, -00003.72 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007950 NoMotion-1. Scaled. Acc [ -00039.06, -00017.09, 00979.98 ], Gyr [ 00001.71, 00000.55, -00001.52 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007951 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00992.68 ], Gyr [ 00001.59, -00000.61, -00001.65 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007952 NoMotion-1. Scaled. Acc [ -00046.88, -00003.42, 00998.05 ], Gyr [ -00000.98, 00000.73, 00000.37 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007953 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 01002.44 ], Gyr [ 00002.99, -00000.55, -00000.30 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007954 NoMotion-1. Scaled. Acc [ -00044.43, -00002.44, 00997.56 ], Gyr [ 00001.16, -00001.04, -00000.67 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000007955 NoMotion-1. Scaled. Acc [ -00049.80, 00005.37, 00989.26 ], Gyr [ -00000.37, 00002.80, -00002.20 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000007956 NoMotion-1. Scaled. Acc [ -00046.39, -00011.72, 00994.63 ], Gyr [ 00002.01, -00000.98, -00001.89 ], Mag [ 00019.05, 00082.65, 00109.05 ]",
    "#0000007957 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00995.61 ], Gyr [ -00000.91, 00000.30, -00001.40 ], Mag [ 00019.05, 00082.65, 00109.05 ]",
    "#0000007958 NoMotion-1. Scaled. Acc [ -00050.29, -00009.77, 00989.26 ], Gyr [ 00001.28, -00001.28, 00001.40 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007959 NoMotion-1. Scaled. Acc [ -00047.85, -00014.16, 00994.14 ], Gyr [ -00000.85, 00000.30, -00004.51 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007960 NoMotion-1. Scaled. Acc [ -00044.43, -00011.23, 00995.12 ], Gyr [ 00001.34, 00001.40, -00001.77 ], Mag [ 00020.25, 00084.75, 00107.10 ]",
    "#0000007961 NoMotion-1. Scaled. Acc [ -00057.13, -00013.67, 00985.84 ], Gyr [ 00000.18, 00002.93, -00006.22 ], Mag [ 00020.25, 00084.75, 00107.10 ]",
    "#0000007962 NoMotion-1. Scaled. Acc [ -00038.09, -00002.44, 00987.79 ], Gyr [ -00000.73, 00001.34, -00000.43 ], Mag [ 00020.25, 00084.75, 00107.10 ]",
    "#0000007963 NoMotion-1. Scaled. Acc [ -00042.48, -00003.91, 00994.14 ], Gyr [ -00000.24, -00001.59, -00003.29 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007964 NoMotion-1. Scaled. Acc [ -00037.60, -00010.25, 01001.95 ], Gyr [ 00000.43, 00001.10, -00000.98 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007965 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00998.54 ], Gyr [ 00001.52, 00002.26, -00001.77 ], Mag [ 00021.15, 00081.15, 00107.70 ]",
    "#0000007966 NoMotion-1. Scaled. Acc [ -00040.04, -00009.77, 00987.79 ], Gyr [ -00000.49, 00001.46, -00004.76 ], Mag [ 00021.15, 00081.15, 00107.70 ]",
    "#0000007967 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00992.68 ], Gyr [ -00000.49, -00000.55, 00000.91 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007968 NoMotion-1. Scaled. Acc [ -00049.32, -00001.95, 00986.82 ], Gyr [ 00002.01, 00004.09, 00000.18 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007969 NoMotion-1. Scaled. Acc [ -00044.43, -00016.60, 00988.28 ], Gyr [ -00000.49, 00002.80, -00000.85 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007970 NoMotion-1. Scaled. Acc [ -00050.78, -00004.39, 00996.58 ], Gyr [ 00002.32, 00001.34, -00001.28 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007971 NoMotion-1. Scaled. Acc [ -00042.97, -00016.11, 00982.91 ], Gyr [ 00000.61, 00002.56, -00001.83 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007972 NoMotion-1. Scaled. Acc [ -00059.08, -00004.39, 00983.89 ], Gyr [ 00001.34, 00000.55, -00003.11 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007973 NoMotion-1. Scaled. Acc [ -00046.39, 00003.91, 00990.23 ], Gyr [ -00001.52, 00001.71, -00001.16 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007974 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00992.68 ], Gyr [ 00002.44, 00003.72, -00003.05 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007975 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00999.02 ], Gyr [ 00000.98, 00000.85, -00003.35 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007976 NoMotion-1. Scaled. Acc [ -00039.06, -00015.62, 00993.65 ], Gyr [ 00001.34, -00001.71, -00001.89 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007977 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 01000.00 ], Gyr [ 00000.24, 00000.55, -00001.65 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007978 NoMotion-1. Scaled. Acc [ -00047.85, -00017.09, 00993.16 ], Gyr [ -00000.30, 00001.22, -00004.39 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007979 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00987.79 ], Gyr [ -00000.55, 00001.22, -00002.13 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000007980 NoMotion-1. Scaled. Acc [ -00041.99, -00005.37, 00983.89 ], Gyr [ 00002.26, 00002.99, -00000.24 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000007981 NoMotion-1. Scaled. Acc [ -00044.43, -00008.30, 00997.07 ], Gyr [ 00000.98, 00000.91, -00000.85 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000007982 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00993.16 ], Gyr [ 00001.34, -00002.01, -00001.22 ], Mag [ 00019.95, 00084.00, 00106.50 ]",
    "#0000007983 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00991.21 ], Gyr [ 00000.12, 00002.44, -00001.71 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007984 NoMotion-1. Scaled. Acc [ -00040.04, -00010.25, 00987.79 ], Gyr [ 00001.71, 00000.67, -00001.46 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007985 NoMotion-1. Scaled. Acc [ -00031.25, -00016.60, 01000.00 ], Gyr [ -00001.10, 00001.52, -00002.32 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007986 NoMotion-1. Scaled. Acc [ -00053.22, -00002.44, 01000.49 ], Gyr [ -00000.67, -00000.67, -00000.73 ], Mag [ 00020.70, 00083.55, 00107.85 ]",
    "#0000007987 NoMotion-1. Scaled. Acc [ -00032.71, -00005.37, 01005.37 ], Gyr [ 00000.79, 00001.95, -00001.89 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007988 NoMotion-1. Scaled. Acc [ -00041.02, -00004.39, 00989.26 ], Gyr [ 00002.01, 00000.61, -00001.77 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007989 NoMotion-1. Scaled. Acc [ -00039.55, -00001.46, 00989.75 ], Gyr [ 00002.07, 00000.18, -00000.55 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007990 NoMotion-1. Scaled. Acc [ -00044.43, -00014.65, 00988.77 ], Gyr [ 00000.43, 00002.07, -00002.26 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007991 NoMotion-1. Scaled. Acc [ -00055.66, -00000.49, 00982.91 ], Gyr [ 00001.71, 00001.04, -00003.66 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007992 NoMotion-1. Scaled. Acc [ -00041.99, -00009.77, 00989.26 ], Gyr [ 00000.30, 00000.06, 00000.00 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007993 NoMotion-1. Scaled. Acc [ -00039.06, -00014.65, 00987.30 ], Gyr [ -00002.62, -00000.06, 00000.49 ], Mag [ 00018.60, 00083.70, 00107.40 ]",
    "#0000007994 NoMotion-1. Scaled. Acc [ -00044.43, -00003.91, 00998.05 ], Gyr [ -00000.55, -00003.05, -00000.37 ], Mag [ 00018.60, 00083.70, 00107.40 ]",
    "#0000007995 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00995.61 ], Gyr [ 00000.43, 00003.35, -00001.34 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007996 NoMotion-1. Scaled. Acc [ -00046.88, -00019.04, 00988.77 ], Gyr [ -00000.67, -00001.28, 00000.73 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007997 NoMotion-1. Scaled. Acc [ -00044.43, -00011.23, 00990.23 ], Gyr [ 00001.22, 00001.28, -00002.87 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000007998 NoMotion-1. Scaled. Acc [ -00046.39, -00002.44, 00980.96 ], Gyr [ -00001.95, 00002.68, -00003.96 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000007999 NoMotion-1. Scaled. Acc [ -00056.15, -00006.35, 00995.12 ], Gyr [ 00001.22, 00002.13, -00001.52 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000008000 NoMotion-1. Scaled. Acc [ -00044.92, -00011.72, 01000.00 ], Gyr [ -00000.85, 00003.48, -00000.12 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000008001 NoMotion-1. Scaled. Acc [ -00051.27, -00010.74, 00979.00 ], Gyr [ 00001.77, 00000.24, -00000.49 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000008002 NoMotion-1. Scaled. Acc [ -00041.50, -00018.07, 01006.35 ], Gyr [ -00000.79, 00001.77, -00002.56 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000008003 NoMotion-1. Scaled. Acc [ -00027.34, -00007.32, 00999.02 ], Gyr [ 00000.30, 00001.10, 00000.61 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000008004 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00992.68 ], Gyr [ 00001.40, 00001.04, 00000.24 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000008005 NoMotion-1. Scaled. Acc [ -00045.41, -00001.46, 00993.16 ], Gyr [ -00000.24, -00000.43, -00001.83 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000008006 NoMotion-1. Scaled. Acc [ -00040.53, -00008.79, 00987.79 ], Gyr [ 00001.34, 00000.61, -00002.38 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000008007 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00982.91 ], Gyr [ 00000.73, 00002.38, -00003.54 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000008008 NoMotion-1. Scaled. Acc [ -00043.95, -00006.84, 00994.14 ], Gyr [ 00000.43, 00001.22, 00000.73 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000008009 NoMotion-1. Scaled. Acc [ -00040.53, -00004.88, 00996.09 ], Gyr [ -00001.65, 00005.37, -00002.32 ], Mag [ 00018.15, 00083.55, 00107.85 ]",
    "#0000008010 NoMotion-1. Scaled. Acc [ -00046.39, 00000.98, 00989.75 ], Gyr [ -00001.10, 00003.29, -00000.12 ], Mag [ 00018.15, 00083.55, 00107.85 ]",
    "#0000008011 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00982.42 ], Gyr [ 00000.37, 00000.18, -00001.46 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000008012 NoMotion-1. Scaled. Acc [ -00057.62, -00013.67, 00993.16 ], Gyr [ 00001.22, 00004.45, -00001.95 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000008013 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00999.02 ], Gyr [ 00000.49, 00001.71, -00002.93 ], Mag [ 00021.00, 00082.80, 00107.10 ]",
    "#0000008014 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00986.33 ], Gyr [ -00000.73, 00001.59, -00001.22 ], Mag [ 00020.10, 00082.80, 00107.10 ]",
    "#0000008015 NoMotion-1. Scaled. Acc [ -00041.99, 00004.88, 00985.84 ], Gyr [ 00000.49, 00001.89, -00001.16 ], Mag [ 00020.10, 00084.90, 00108.45 ]",
    "#0000008016 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00978.52 ], Gyr [ 00002.20, 00000.24, 00000.00 ], Mag [ 00020.70, 00083.25, 00106.50 ]",
    "#0000008017 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00994.14 ], Gyr [ 00000.49, 00003.90, -00002.32 ], Mag [ 00020.70, 00083.25, 00106.50 ]",
    "#0000008018 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00988.28 ], Gyr [ 00001.16, 00000.91, 00001.28 ], Mag [ 00020.70, 00083.25, 00106.50 ]",
    "#0000008019 NoMotion-1. Scaled. Acc [ -00056.64, -00000.49, 00994.14 ], Gyr [ 00000.85, 00001.83, -00003.54 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008020 NoMotion-1. Scaled. Acc [ -00048.34, 00000.00, 00996.58 ], Gyr [ -00001.95, 00002.50, 00000.00 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008021 NoMotion-1. Scaled. Acc [ -00051.76, -00016.60, 01003.91 ], Gyr [ 00000.18, 00002.38, -00001.65 ], Mag [ 00019.65, 00084.45, 00106.65 ]",
    "#0000008022 NoMotion-1. Scaled. Acc [ -00052.25, -00001.46, 00985.84 ], Gyr [ 00000.12, 00003.41, -00001.04 ], Mag [ 00019.65, 00084.45, 00106.65 ]",
    "#0000008023 NoMotion-1. Scaled. Acc [ -00048.34, -00013.18, 00999.51 ], Gyr [ -00000.49, 00004.27, -00003.72 ], Mag [ 00019.65, 00084.45, 00106.65 ]",
    "#0000008024 NoMotion-1. Scaled. Acc [ -00059.08, -00005.86, 00999.02 ], Gyr [ -00000.85, 00000.18, -00000.55 ], Mag [ 00021.00, 00084.30, 00107.10 ]",
    "#0000008025 NoMotion-1. Scaled. Acc [ -00043.46, 00000.49, 00985.84 ], Gyr [ 00002.50, -00000.30, -00000.24 ], Mag [ 00021.00, 00084.30, 00107.10 ]",
    "#0000008026 NoMotion-1. Scaled. Acc [ -00042.48, -00009.77, 00980.96 ], Gyr [ 00000.85, 00000.49, -00002.80 ], Mag [ 00019.65, 00084.45, 00107.70 ]",
    "#0000008027 NoMotion-1. Scaled. Acc [ -00042.48, -00003.42, 00992.68 ], Gyr [ 00001.16, 00001.65, -00001.16 ], Mag [ 00020.10, 00082.65, 00107.70 ]",
    "#0000008028 NoMotion-1. Scaled. Acc [ -00032.71, -00003.91, 00980.96 ], Gyr [ -00000.37, -00000.43, -00004.51 ], Mag [ 00020.10, 00082.65, 00107.70 ]",
    "#0000008029 NoMotion-1. Scaled. Acc [ -00049.32, -00009.77, 00981.45 ], Gyr [ 00001.16, 00001.40, -00000.06 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008030 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 01003.91 ], Gyr [ -00000.06, -00001.28, -00000.18 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008031 NoMotion-1. Scaled. Acc [ -00052.73, -00004.39, 00997.56 ], Gyr [ -00000.67, 00001.46, 00000.06 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008032 NoMotion-1. Scaled. Acc [ -00039.06, 00002.93, 00989.26 ], Gyr [ 00000.12, 00002.20, -00002.13 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008033 NoMotion-1. Scaled. Acc [ -00044.92, -00017.09, 00996.58 ], Gyr [ -00001.28, 00002.74, -00001.77 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008034 NoMotion-1. Scaled. Acc [ -00049.32, -00013.18, 00996.09 ], Gyr [ -00000.30, 00002.50, -00002.38 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008035 NoMotion-1. Scaled. Acc [ -00061.52, 00009.28, 00988.28 ], Gyr [ -00000.73, 00000.18, -00002.44 ], Mag [ 00019.65, 00083.40, 00108.75 ]",
    "#0000008036 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00985.35 ], Gyr [ 00000.24, -00000.12, -00000.55 ], Mag [ 00019.65, 00083.40, 00108.75 ]",
    "#0000008037 NoMotion-1. Scaled. Acc [ -00050.29, -00003.42, 00985.84 ], Gyr [ 00001.34, 00000.49, 00000.12 ], Mag [ 00019.50, 00083.25, 00106.80 ]",
    "#0000008038 NoMotion-1. Scaled. Acc [ -00037.11, -00000.98, 00979.98 ], Gyr [ 00001.46, 00002.44, 00000.00 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008039 NoMotion-1. Scaled. Acc [ -00054.20, 00003.42, 00994.63 ], Gyr [ 00001.65, 00001.34, -00002.80 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008040 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00985.35 ], Gyr [ 00001.71, 00001.16, -00002.62 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008041 NoMotion-1. Scaled. Acc [ -00051.27, -00013.18, 00992.19 ], Gyr [ 00000.98, 00001.77, -00000.91 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008042 NoMotion-1. Scaled. Acc [ -00033.69, -00011.23, 00996.58 ], Gyr [ 00001.40, 00001.34, -00001.22 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008043 NoMotion-1. Scaled. Acc [ -00038.09, -00007.32, 00990.23 ], Gyr [ 00001.34, -00000.37, -00001.40 ], Mag [ 00019.20, 00084.00, 00107.55 ]",
    "#0000008044 NoMotion-1. Scaled. Acc [ -00052.73, -00008.30, 00987.30 ], Gyr [ 00000.79, -00000.61, -00001.71 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008045 NoMotion-1. Scaled. Acc [ -00047.85, -00016.11, 00991.21 ], Gyr [ 00000.12, 00001.52, -00002.44 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008046 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00989.26 ], Gyr [ -00000.37, 00002.07, -00001.28 ], Mag [ 00019.50, 00084.15, 00106.95 ]",
    "#0000008047 NoMotion-1. Scaled. Acc [ -00032.71, -00008.30, 00990.23 ], Gyr [ 00001.28, 00000.79, -00001.71 ], Mag [ 00019.50, 00081.75, 00107.55 ]",
    "#0000008048 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00987.30 ], Gyr [ 00001.22, 00001.04, -00002.50 ], Mag [ 00019.50, 00081.75, 00107.55 ]",
    "#0000008049 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 00987.79 ], Gyr [ -00001.28, -00000.12, -00000.67 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000008050 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00989.26 ], Gyr [ 00000.55, 00000.98, -00001.16 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000008051 NoMotion-1. Scaled. Acc [ -00030.76, -00016.60, 00989.26 ], Gyr [ -00000.67, 00003.35, -00002.38 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000008052 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00995.61 ], Gyr [ -00001.16, 00001.52, 00000.24 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000008053 NoMotion-1. Scaled. Acc [ -00045.90, -00000.49, 00979.98 ], Gyr [ -00000.43, 00000.67, -00003.60 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008054 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00995.12 ], Gyr [ 00001.10, 00001.04, -00001.77 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008055 NoMotion-1. Scaled. Acc [ -00046.88, -00000.49, 00983.40 ], Gyr [ 00000.79, 00001.04, -00000.73 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008056 NoMotion-1. Scaled. Acc [ -00042.97, -00001.46, 00993.65 ], Gyr [ 00000.24, 00001.28, -00000.24 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008057 NoMotion-1. Scaled. Acc [ -00043.95, -00006.35, 00996.09 ], Gyr [ 00001.04, 00003.84, -00001.40 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008058 NoMotion-1. Scaled. Acc [ -00041.99, -00011.72, 00990.23 ], Gyr [ -00002.38, 00001.52, -00001.46 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008059 NoMotion-1. Scaled. Acc [ -00033.69, -00020.02, 00987.79 ], Gyr [ 00000.37, 00001.10, -00001.59 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008060 NoMotion-1. Scaled. Acc [ -00044.92, -00001.95, 00983.89 ], Gyr [ -00000.73, 00001.83, -00003.23 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008061 NoMotion-1. Scaled. Acc [ -00035.64, -00008.30, 00990.72 ], Gyr [ 00001.52, 00002.62, -00000.49 ], Mag [ 00018.75, 00084.75, 00108.30 ]",
    "#0000008062 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00985.84 ], Gyr [ 00001.34, 00001.89, -00002.07 ], Mag [ 00018.75, 00084.75, 00108.30 ]",
    "#0000008063 NoMotion-1. Scaled. Acc [ -00054.20, -00000.49, 00994.63 ], Gyr [ 00002.50, 00001.59, -00001.89 ], Mag [ 00019.95, 00083.55, 00107.10 ]",
    "#0000008064 NoMotion-1. Scaled. Acc [ -00051.76, -00004.39, 00993.16 ], Gyr [ 00000.30, 00003.35, -00000.06 ], Mag [ 00019.95, 00083.55, 00107.10 ]",
    "#0000008065 NoMotion-1. Scaled. Acc [ -00051.76, -00015.14, 00992.68 ], Gyr [ 00000.55, -00002.07, -00001.22 ], Mag [ 00019.65, 00082.65, 00107.70 ]",
    "#0000008066 NoMotion-1. Scaled. Acc [ -00046.88, 00001.46, 00987.30 ], Gyr [ 00001.40, 00002.80, -00001.40 ], Mag [ 00019.65, 00082.65, 00107.70 ]",
    "#0000008067 NoMotion-1. Scaled. Acc [ -00037.11, -00009.28, 00992.19 ], Gyr [ 00002.38, 00000.55, -00001.34 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008068 NoMotion-1. Scaled. Acc [ -00044.92, 00003.91, 00995.12 ], Gyr [ 00001.65, 00001.22, 00000.67 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008069 NoMotion-1. Scaled. Acc [ -00040.04, 00002.93, 00992.19 ], Gyr [ -00000.49, 00003.41, -00002.01 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008070 NoMotion-1. Scaled. Acc [ -00036.62, -00007.81, 00995.61 ], Gyr [ 00001.10, 00001.10, -00000.18 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008071 NoMotion-1. Scaled. Acc [ -00050.29, -00019.04, 00980.96 ], Gyr [ 00002.32, 00004.09, -00000.91 ], Mag [ 00020.10, 00082.20, 00106.65 ]",
    "#0000008072 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 00995.12 ], Gyr [ -00000.24, 00002.99, -00002.01 ], Mag [ 00020.10, 00082.20, 00106.65 ]",
    "#0000008073 NoMotion-1. Scaled. Acc [ -00035.64, 00003.42, 00985.35 ], Gyr [ -00001.40, 00002.26, -00003.54 ], Mag [ 00019.05, 00082.95, 00108.75 ]",
    "#0000008074 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00990.72 ], Gyr [ 00000.73, 00004.94, 00001.10 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000008075 NoMotion-1. Scaled. Acc [ -00053.22, -00009.77, 00990.72 ], Gyr [ -00000.73, 00003.05, -00002.32 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008076 NoMotion-1. Scaled. Acc [ -00041.02, -00017.58, 00994.63 ], Gyr [ -00000.55, -00000.43, -00001.10 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008077 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00993.16 ], Gyr [ 00000.24, 00001.34, -00002.38 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008078 NoMotion-1. Scaled. Acc [ -00038.09, 00001.46, 01005.86 ], Gyr [ -00001.46, 00003.96, -00004.33 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008079 NoMotion-1. Scaled. Acc [ -00060.55, -00016.11, 01000.49 ], Gyr [ 00001.28, 00000.61, 00001.10 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008080 NoMotion-1. Scaled. Acc [ -00065.92, -00009.28, 00991.70 ], Gyr [ 00000.43, -00000.55, -00001.59 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008081 NoMotion-1. Scaled. Acc [ -00043.46, -00011.72, 00997.56 ], Gyr [ 00002.26, -00000.06, -00000.30 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008082 NoMotion-1. Scaled. Acc [ -00037.60, -00001.95, 00990.72 ], Gyr [ -00001.46, 00001.04, -00001.95 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008083 NoMotion-1. Scaled. Acc [ -00046.88, -00000.49, 00990.72 ], Gyr [ 00000.06, 00002.62, -00002.56 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008084 NoMotion-1. Scaled. Acc [ -00046.39, -00016.60, 00992.68 ], Gyr [ -00000.24, 00002.01, -00000.98 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008085 NoMotion-1. Scaled. Acc [ -00039.06, 00006.35, 00992.68 ], Gyr [ -00000.24, 00004.39, -00000.79 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008086 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 01005.37 ], Gyr [ -00000.24, 00001.83, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008087 NoMotion-1. Scaled. Acc [ -00048.34, -00016.11, 01005.86 ], Gyr [ -00001.83, 00001.52, 00000.18 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008088 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00993.65 ], Gyr [ -00001.10, 00000.06, -00003.05 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008089 NoMotion-1. Scaled. Acc [ -00055.66, -00011.72, 00998.54 ], Gyr [ 00000.12, -00001.10, -00002.01 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008090 NoMotion-1. Scaled. Acc [ -00047.85, -00014.65, 00980.96 ], Gyr [ -00000.37, 00001.95, -00002.32 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000008091 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00984.38 ], Gyr [ 00002.44, 00000.12, -00000.85 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000008092 NoMotion-1. Scaled. Acc [ -00040.53, -00007.81, 00994.14 ], Gyr [ 00001.22, 00000.79, -00001.65 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008093 NoMotion-1. Scaled. Acc [ -00053.22, -00000.98, 00998.05 ], Gyr [ 00002.20, -00000.30, -00000.30 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008094 NoMotion-1. Scaled. Acc [ -00044.43, -00007.32, 00993.16 ], Gyr [ 00001.40, 00001.71, -00004.02 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008095 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00991.70 ], Gyr [ 00000.30, 00004.02, -00001.52 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008096 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 01002.93 ], Gyr [ 00001.65, -00001.16, -00001.95 ], Mag [ 00019.05, 00082.20, 00108.00 ]",
    "#0000008097 NoMotion-1. Scaled. Acc [ -00047.36, -00010.25, 00987.79 ], Gyr [ 00000.67, 00001.04, -00000.91 ], Mag [ 00019.05, 00082.20, 00108.00 ]",
    "#0000008098 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00998.54 ], Gyr [ 00001.04, 00000.55, -00002.56 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000008099 NoMotion-1. Scaled. Acc [ -00029.79, -00003.42, 01002.93 ], Gyr [ -00000.30, 00001.65, -00002.99 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000008100 NoMotion-1. Scaled. Acc [ -00059.57, -00003.42, 00981.45 ], Gyr [ 00000.49, 00000.67, -00000.12 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000008101 NoMotion-1. Scaled. Acc [ -00045.41, -00003.91, 00995.12 ], Gyr [ 00000.49, 00000.18, -00004.02 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000008102 NoMotion-1. Scaled. Acc [ -00046.88, 00002.93, 00985.35 ], Gyr [ 00001.52, 00000.98, -00002.80 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008103 NoMotion-1. Scaled. Acc [ -00034.18, -00008.79, 00992.68 ], Gyr [ 00001.10, 00002.62, -00002.68 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008104 NoMotion-1. Scaled. Acc [ -00051.76, -00010.74, 00989.26 ], Gyr [ 00002.01, 00001.28, -00000.06 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008105 NoMotion-1. Scaled. Acc [ -00040.04, -00010.74, 00990.23 ], Gyr [ 00000.98, 00002.38, -00002.20 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008106 NoMotion-1. Scaled. Acc [ -00040.04, -00014.65, 01012.21 ], Gyr [ -00000.37, 00003.41, -00001.16 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008107 NoMotion-1. Scaled. Acc [ -00043.95, -00009.77, 00988.77 ], Gyr [ -00000.12, 00000.85, -00002.44 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008108 NoMotion-1. Scaled. Acc [ -00045.41, -00015.62, 00987.30 ], Gyr [ 00001.46, 00002.56, -00002.44 ], Mag [ 00018.75, 00084.00, 00106.05 ]",
    "#0000008109 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00980.96 ], Gyr [ 00000.85, 00003.66, -00001.77 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008110 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00979.98 ], Gyr [ 00000.24, -00000.37, -00001.77 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008111 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00994.14 ], Gyr [ 00000.24, 00003.90, -00001.71 ], Mag [ 00018.30, 00081.90, 00108.00 ]",
    "#0000008112 NoMotion-1. Scaled. Acc [ -00047.36, -00018.07, 00989.26 ], Gyr [ -00000.24, 00000.18, -00000.55 ], Mag [ 00018.30, 00081.90, 00108.00 ]",
    "#0000008113 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00985.35 ], Gyr [ 00000.61, 00000.43, -00002.32 ], Mag [ 00018.30, 00081.90, 00108.00 ]",
    "#0000008114 NoMotion-1. Scaled. Acc [ -00048.83, -00016.11, 00984.86 ], Gyr [ 00000.49, 00000.85, -00000.06 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008115 NoMotion-1. Scaled. Acc [ -00042.48, -00008.79, 00996.58 ], Gyr [ -00000.06, 00001.77, -00001.28 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008116 NoMotion-1. Scaled. Acc [ -00052.25, -00003.91, 00990.72 ], Gyr [ 00002.01, 00001.46, -00001.28 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000008117 NoMotion-1. Scaled. Acc [ -00041.50, -00016.60, 00993.16 ], Gyr [ -00000.49, 00000.37, -00000.24 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000008118 NoMotion-1. Scaled. Acc [ -00042.48, -00010.74, 00989.26 ], Gyr [ 00001.40, 00001.16, -00000.37 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000008119 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 01008.79 ], Gyr [ -00001.52, 00000.12, -00001.28 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000008120 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00988.28 ], Gyr [ -00001.83, 00000.12, 00000.24 ], Mag [ 00020.40, 00084.45, 00109.05 ]",
    "#0000008121 NoMotion-1. Scaled. Acc [ -00054.20, -00000.98, 00979.49 ], Gyr [ 00000.43, -00000.06, -00000.85 ], Mag [ 00020.40, 00084.45, 00109.05 ]",
    "#0000008122 NoMotion-1. Scaled. Acc [ -00045.41, -00007.81, 00984.86 ], Gyr [ -00000.73, -00000.98, -00001.28 ], Mag [ 00020.40, 00084.45, 00109.05 ]",
    "#0000008123 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 01003.42 ], Gyr [ -00000.73, 00001.83, -00001.10 ], Mag [ 00020.10, 00082.65, 00106.65 ]",
    "#0000008124 NoMotion-1. Scaled. Acc [ -00046.39, -00013.18, 00986.82 ], Gyr [ -00000.24, -00000.30, 00000.37 ], Mag [ 00020.10, 00082.65, 00106.65 ]",
    "#0000008125 NoMotion-1. Scaled. Acc [ -00052.25, 00002.44, 00995.12 ], Gyr [ -00000.73, 00001.59, -00001.22 ], Mag [ 00020.10, 00082.65, 00106.65 ]",
    "#0000008126 NoMotion-1. Scaled. Acc [ -00044.92, -00018.07, 00976.56 ], Gyr [ 00000.79, 00002.32, -00002.26 ], Mag [ 00019.35, 00084.15, 00106.65 ]",
    "#0000008127 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00993.16 ], Gyr [ 00001.16, 00000.30, 00003.17 ], Mag [ 00019.50, 00084.30, 00108.30 ]",
    "#0000008128 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00992.19 ], Gyr [ -00001.77, 00004.51, -00001.83 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008129 NoMotion-1. Scaled. Acc [ -00042.48, -00012.21, 01001.46 ], Gyr [ 00001.65, -00001.16, -00000.85 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008130 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00999.51 ], Gyr [ 00002.01, -00000.55, -00000.06 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008131 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00989.75 ], Gyr [ 00001.52, 00002.80, -00000.55 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008132 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00991.70 ], Gyr [ 00000.98, 00002.50, -00002.13 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008133 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00998.54 ], Gyr [ -00000.61, 00001.95, 00000.18 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008134 NoMotion-1. Scaled. Acc [ -00044.43, -00010.74, 00987.30 ], Gyr [ 00001.71, 00001.34, -00000.49 ], Mag [ 00018.60, 00084.15, 00107.40 ]",
    "#0000008135 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 00979.00 ], Gyr [ 00000.55, 00000.61, -00002.07 ], Mag [ 00018.60, 00084.15, 00107.40 ]",
    "#0000008136 NoMotion-1. Scaled. Acc [ -00044.43, -00019.53, 00985.35 ], Gyr [ -00000.91, 00001.59, -00001.34 ], Mag [ 00019.05, 00082.95, 00108.75 ]",
    "#0000008137 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00994.63 ], Gyr [ 00001.83, 00001.10, -00002.13 ], Mag [ 00019.05, 00082.95, 00108.75 ]",
    "#0000008138 NoMotion-1. Scaled. Acc [ -00044.92, -00001.46, 00990.72 ], Gyr [ 00002.99, 00001.83, -00003.23 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008139 NoMotion-1. Scaled. Acc [ -00055.66, -00013.67, 01000.00 ], Gyr [ 00001.16, 00000.91, 00000.85 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008140 NoMotion-1. Scaled. Acc [ -00049.80, -00000.98, 00978.03 ], Gyr [ -00000.18, 00002.20, 00001.65 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008141 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00986.33 ], Gyr [ 00000.55, -00000.37, -00001.10 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008142 NoMotion-1. Scaled. Acc [ -00034.18, -00006.84, 00989.26 ], Gyr [ 00001.59, -00002.26, -00001.28 ], Mag [ 00019.50, 00082.50, 00106.50 ]",
    "#0000008143 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00987.79 ], Gyr [ -00001.28, 00002.93, -00002.38 ], Mag [ 00017.55, 00082.95, 00108.00 ]",
    "#0000008144 NoMotion-1. Scaled. Acc [ -00050.29, -00010.25, 00994.14 ], Gyr [ 00001.95, 00000.49, -00002.26 ], Mag [ 00017.55, 00082.95, 00108.00 ]",
    "#0000008145 NoMotion-1. Scaled. Acc [ -00053.22, -00019.04, 00984.86 ], Gyr [ 00001.89, 00000.98, -00001.34 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008146 NoMotion-1. Scaled. Acc [ -00053.71, -00010.74, 00997.07 ], Gyr [ 00000.18, 00003.05, -00003.72 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008147 NoMotion-1. Scaled. Acc [ -00046.88, -00006.84, 01000.00 ], Gyr [ 00002.20, 00001.22, -00001.65 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008148 NoMotion-1. Scaled. Acc [ -00046.39, -00015.62, 00989.75 ], Gyr [ -00002.38, 00001.46, -00001.52 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008149 NoMotion-1. Scaled. Acc [ -00049.80, -00015.62, 01000.49 ], Gyr [ -00000.61, 00003.05, -00003.05 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008150 NoMotion-1. Scaled. Acc [ -00052.25, -00011.23, 00990.72 ], Gyr [ 00002.44, -00001.83, -00001.04 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008151 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00986.33 ], Gyr [ 00001.77, 00001.16, -00001.52 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000008152 NoMotion-1. Scaled. Acc [ -00044.92, -00002.44, 00989.75 ], Gyr [ 00001.59, 00004.45, 00000.24 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000008153 NoMotion-1. Scaled. Acc [ -00047.36, 00003.42, 00987.30 ], Gyr [ -00000.30, 00000.49, -00001.89 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000008154 NoMotion-1. Scaled. Acc [ -00035.64, -00010.25, 00986.33 ], Gyr [ 00001.71, -00001.16, -00000.98 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000008155 NoMotion-1. Scaled. Acc [ -00051.27, -00007.81, 00973.14 ], Gyr [ 00002.74, 00000.61, 00001.22 ], Mag [ 00019.65, 00083.70, 00109.50 ]",
    "#0000008156 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00998.05 ], Gyr [ 00000.18, 00001.46, -00002.74 ], Mag [ 00019.65, 00083.70, 00109.50 ]",
    "#0000008157 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00985.35 ], Gyr [ 00000.67, 00000.79, -00002.07 ], Mag [ 00018.15, 00082.05, 00105.75 ]",
    "#0000008158 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00995.61 ], Gyr [ 00001.40, 00001.46, -00003.17 ], Mag [ 00018.15, 00082.05, 00105.75 ]",
    "#0000008159 NoMotion-1. Scaled. Acc [ -00040.53, 00000.00, 00993.65 ], Gyr [ -00000.79, 00000.12, -00000.61 ], Mag [ 00018.15, 00082.05, 00105.75 ]",
    "#0000008160 NoMotion-1. Scaled. Acc [ -00056.64, -00013.67, 00985.35 ], Gyr [ -00000.73, 00001.22, 00000.91 ], Mag [ 00019.50, 00086.25, 00108.90 ]",
    "#0000008161 NoMotion-1. Scaled. Acc [ -00043.46, -00006.84, 00995.12 ], Gyr [ -00000.43, 00001.89, 00000.67 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000008162 NoMotion-1. Scaled. Acc [ -00057.13, -00010.74, 00982.91 ], Gyr [ 00002.13, -00001.71, -00001.71 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000008163 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00988.77 ], Gyr [ -00000.37, 00002.07, -00000.98 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000008164 NoMotion-1. Scaled. Acc [ -00039.55, -00003.91, 00978.52 ], Gyr [ -00001.04, 00001.10, -00002.13 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000008165 NoMotion-1. Scaled. Acc [ -00039.06, -00011.23, 01001.46 ], Gyr [ 00001.40, 00000.00, -00001.83 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000008166 NoMotion-1. Scaled. Acc [ -00056.64, -00009.77, 00992.19 ], Gyr [ 00002.01, 00000.85, 00000.49 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000008167 NoMotion-1. Scaled. Acc [ -00043.46, -00017.09, 00990.72 ], Gyr [ -00000.06, 00000.91, -00001.65 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000008168 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 01000.49 ], Gyr [ -00000.43, 00002.38, -00001.89 ], Mag [ 00020.25, 00085.50, 00107.10 ]",
    "#0000008169 NoMotion-1. Scaled. Acc [ -00057.13, -00006.35, 00988.28 ], Gyr [ 00001.10, 00000.79, 00000.49 ], Mag [ 00020.25, 00085.50, 00107.10 ]",
    "#0000008170 NoMotion-1. Scaled. Acc [ -00034.67, -00012.70, 00998.54 ], Gyr [ -00000.24, 00002.20, -00004.15 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000008171 NoMotion-1. Scaled. Acc [ -00047.85, -00003.91, 00989.26 ], Gyr [ 00000.37, 00002.56, -00000.73 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000008172 NoMotion-1. Scaled. Acc [ -00050.29, -00013.18, 00983.40 ], Gyr [ -00002.20, 00002.62, -00002.93 ], Mag [ 00020.10, 00082.95, 00106.95 ]",
    "#0000008173 NoMotion-1. Scaled. Acc [ -00040.53, -00012.21, 00991.70 ], Gyr [ 00001.65, -00000.73, -00000.85 ], Mag [ 00020.10, 00082.95, 00106.95 ]",
    "#0000008174 NoMotion-1. Scaled. Acc [ -00045.41, -00021.00, 00997.07 ], Gyr [ -00000.24, 00000.18, -00001.10 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000008175 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00984.38 ], Gyr [ -00000.79, 00001.46, 00000.73 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000008176 NoMotion-1. Scaled. Acc [ -00044.43, -00012.70, 00988.28 ], Gyr [ 00000.49, 00000.49, -00001.83 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000008177 NoMotion-1. Scaled. Acc [ -00033.69, -00000.98, 00988.28 ], Gyr [ 00001.52, 00001.71, 00000.18 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000008178 NoMotion-1. Scaled. Acc [ -00040.04, -00002.44, 00998.54 ], Gyr [ 00002.13, -00001.28, -00000.37 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000008179 NoMotion-1. Scaled. Acc [ -00042.97, -00008.30, 00994.14 ], Gyr [ -00000.24, 00001.95, -00002.26 ], Mag [ 00020.70, 00082.80, 00107.10 ]",
    "#0000008180 NoMotion-1. Scaled. Acc [ -00039.55, -00001.95, 00983.89 ], Gyr [ 00000.73, 00003.05, 00000.24 ], Mag [ 00020.70, 00082.80, 00107.10 ]",
    "#0000008181 NoMotion-1. Scaled. Acc [ -00043.46, -00011.72, 00993.65 ], Gyr [ -00000.91, 00000.49, -00003.11 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008182 NoMotion-1. Scaled. Acc [ -00050.78, -00010.25, 00983.89 ], Gyr [ 00000.12, 00000.73, 00000.30 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008183 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00982.42 ], Gyr [ 00000.67, 00003.54, -00002.56 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008184 NoMotion-1. Scaled. Acc [ -00042.97, -00008.30, 00993.65 ], Gyr [ 00000.43, 00003.05, 00000.12 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008185 NoMotion-1. Scaled. Acc [ -00051.76, -00000.98, 00990.23 ], Gyr [ 00002.93, 00001.22, 00000.73 ], Mag [ 00019.20, 00084.00, 00106.50 ]",
    "#0000008186 NoMotion-1. Scaled. Acc [ -00041.50, -00010.74, 00995.61 ], Gyr [ 00000.91, 00002.20, -00001.28 ], Mag [ 00019.20, 00084.00, 00106.50 ]",
    "#0000008187 NoMotion-1. Scaled. Acc [ -00037.11, -00013.67, 00991.70 ], Gyr [ -00000.30, 00001.40, -00002.01 ], Mag [ 00021.30, 00082.80, 00107.85 ]",
    "#0000008188 NoMotion-1. Scaled. Acc [ -00036.62, -00014.16, 00992.19 ], Gyr [ 00000.06, 00001.83, 00000.30 ], Mag [ 00021.30, 00082.80, 00107.85 ]",
    "#0000008189 NoMotion-1. Scaled. Acc [ -00035.16, -00010.74, 00986.33 ], Gyr [ 00001.22, -00000.55, -00000.55 ], Mag [ 00021.00, 00084.00, 00108.90 ]",
    "#0000008190 NoMotion-1. Scaled. Acc [ -00037.60, -00017.09, 00972.17 ], Gyr [ 00000.00, 00000.12, -00002.07 ], Mag [ 00021.00, 00084.00, 00108.90 ]",
    "#0000008191 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 01011.23 ], Gyr [ 00000.06, 00001.89, 00000.67 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008192 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00990.23 ], Gyr [ -00000.67, 00001.77, -00003.23 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008193 NoMotion-1. Scaled. Acc [ -00040.53, -00014.16, 00987.79 ], Gyr [ 00000.55, 00004.45, -00000.61 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008194 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00996.58 ], Gyr [ -00000.37, 00002.68, 00001.22 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008195 NoMotion-1. Scaled. Acc [ -00040.53, -00013.67, 00995.12 ], Gyr [ 00000.18, 00002.56, -00002.93 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000008196 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 00996.09 ], Gyr [ -00000.98, 00002.50, -00002.56 ], Mag [ 00020.25, 00082.05, 00106.80 ]",
    "#0000008197 NoMotion-1. Scaled. Acc [ -00055.66, -00000.98, 00993.16 ], Gyr [ 00001.40, 00000.91, -00001.04 ], Mag [ 00020.25, 00082.05, 00106.80 ]",
    "#0000008198 NoMotion-1. Scaled. Acc [ -00048.83, -00010.74, 00986.33 ], Gyr [ 00000.67, -00001.16, 00000.18 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000008199 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 01001.46 ], Gyr [ -00000.98, 00005.18, -00001.16 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008200 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00998.05 ], Gyr [ 00001.10, 00002.38, -00002.20 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008201 NoMotion-1. Scaled. Acc [ -00042.97, -00014.65, 00986.33 ], Gyr [ 00000.30, 00002.93, -00001.04 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008202 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00989.75 ], Gyr [ -00000.30, 00000.73, -00001.95 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008203 NoMotion-1. Scaled. Acc [ -00027.34, -00016.11, 00990.23 ], Gyr [ 00000.91, 00001.52, 00002.13 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000008204 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00989.26 ], Gyr [ 00000.12, 00002.01, -00001.28 ], Mag [ 00019.20, 00084.00, 00108.60 ]",
    "#0000008205 NoMotion-1. Scaled. Acc [ -00039.06, -00009.28, 00986.82 ], Gyr [ -00000.73, 00000.79, -00001.65 ], Mag [ 00019.20, 00084.00, 00108.60 ]",
    "#0000008206 NoMotion-1. Scaled. Acc [ -00042.97, 00000.98, 00983.40 ], Gyr [ -00000.67, 00002.87, -00004.51 ], Mag [ 00019.20, 00084.00, 00108.60 ]",
    "#0000008207 NoMotion-1. Scaled. Acc [ -00051.76, -00005.86, 00985.84 ], Gyr [ -00000.12, 00003.54, 00000.43 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008208 NoMotion-1. Scaled. Acc [ -00036.62, -00011.23, 00999.02 ], Gyr [ -00000.18, 00002.20, -00001.40 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008209 NoMotion-1. Scaled. Acc [ -00038.57, 00001.46, 00991.70 ], Gyr [ 00000.73, 00003.05, -00001.83 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008210 NoMotion-1. Scaled. Acc [ -00045.41, -00002.44, 00993.16 ], Gyr [ 00000.06, 00005.79, -00004.45 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008211 NoMotion-1. Scaled. Acc [ -00041.99, -00013.67, 00982.42 ], Gyr [ 00000.49, -00002.38, -00001.95 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008212 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00990.23 ], Gyr [ 00002.20, 00002.26, 00001.59 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008213 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00989.75 ], Gyr [ 00001.46, 00001.04, -00001.40 ], Mag [ 00019.35, 00084.45, 00106.65 ]",
    "#0000008214 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00994.14 ], Gyr [ 00001.65, 00002.99, 00002.13 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000008215 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00992.19 ], Gyr [ -00000.61, 00003.17, -00003.60 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000008216 NoMotion-1. Scaled. Acc [ -00038.57, -00003.42, 00988.28 ], Gyr [ -00000.06, 00002.01, -00000.18 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000008217 NoMotion-1. Scaled. Acc [ -00040.04, -00009.28, 00991.21 ], Gyr [ 00001.89, 00000.85, -00001.89 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000008218 NoMotion-1. Scaled. Acc [ -00049.32, -00019.53, 00984.38 ], Gyr [ 00001.59, 00003.05, -00000.18 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008219 NoMotion-1. Scaled. Acc [ -00049.32, -00026.37, 00987.79 ], Gyr [ -00000.79, 00002.20, -00001.83 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008220 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00992.19 ], Gyr [ -00000.06, 00002.93, -00002.44 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008221 NoMotion-1. Scaled. Acc [ -00048.34, -00007.81, 00985.35 ], Gyr [ 00001.52, -00000.73, 00001.04 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008222 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00995.61 ], Gyr [ -00002.62, -00000.06, -00004.39 ], Mag [ 00019.95, 00084.00, 00107.85 ]",
    "#0000008223 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00998.54 ], Gyr [ -00001.65, 00001.71, -00000.91 ], Mag [ 00019.95, 00084.00, 00107.85 ]",
    "#0000008224 NoMotion-1. Scaled. Acc [ -00034.67, -00001.95, 00988.77 ], Gyr [ -00000.91, 00000.55, -00000.67 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000008225 NoMotion-1. Scaled. Acc [ -00040.04, -00011.72, 00988.28 ], Gyr [ 00001.77, 00002.20, -00001.10 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000008226 NoMotion-1. Scaled. Acc [ -00040.04, -00009.77, 00991.21 ], Gyr [ 00002.50, 00001.65, -00002.93 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000008227 NoMotion-1. Scaled. Acc [ -00054.20, -00002.93, 00991.70 ], Gyr [ 00001.83, 00000.61, 00000.00 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008228 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00998.54 ], Gyr [ -00001.52, 00002.74, -00002.20 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008229 NoMotion-1. Scaled. Acc [ -00035.64, -00014.65, 00993.65 ], Gyr [ 00000.49, 00001.46, -00002.44 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008230 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00995.12 ], Gyr [ 00002.01, -00002.32, -00001.95 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008231 NoMotion-1. Scaled. Acc [ -00043.46, -00001.95, 01000.00 ], Gyr [ 00000.67, 00003.23, -00001.71 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008232 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00997.56 ], Gyr [ 00001.16, 00002.26, -00003.66 ], Mag [ 00019.50, 00084.30, 00107.85 ]",
    "#0000008233 NoMotion-1. Scaled. Acc [ -00049.80, -00001.46, 00981.93 ], Gyr [ 00001.71, 00000.91, -00001.65 ], Mag [ 00019.50, 00084.30, 00108.30 ]",
    "#0000008234 NoMotion-1. Scaled. Acc [ -00047.36, 00007.32, 00981.93 ], Gyr [ 00001.40, 00000.98, -00001.89 ], Mag [ 00019.05, 00083.40, 00108.30 ]",
    "#0000008235 NoMotion-1. Scaled. Acc [ -00062.99, -00012.70, 00990.72 ], Gyr [ -00000.43, 00003.41, -00003.48 ], Mag [ 00019.05, 00083.40, 00106.65 ]",
    "#0000008236 NoMotion-1. Scaled. Acc [ -00046.88, -00005.37, 00995.61 ], Gyr [ -00000.85, 00002.74, -00001.95 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000008237 NoMotion-1. Scaled. Acc [ -00045.90, -00001.46, 00994.63 ], Gyr [ -00001.16, 00000.43, 00001.10 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000008238 NoMotion-1. Scaled. Acc [ -00044.92, -00015.14, 00993.16 ], Gyr [ -00000.91, -00000.30, 00000.43 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000008239 NoMotion-1. Scaled. Acc [ -00039.06, -00009.77, 00998.05 ], Gyr [ 00000.79, 00001.28, -00001.28 ], Mag [ 00020.25, 00084.00, 00109.35 ]",
    "#0000008240 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00996.09 ], Gyr [ 00000.67, 00003.84, -00001.89 ], Mag [ 00018.60, 00082.65, 00105.90 ]",
    "#0000008241 NoMotion-1. Scaled. Acc [ -00041.02, -00003.42, 00993.16 ], Gyr [ -00000.30, 00004.39, -00003.35 ], Mag [ 00018.60, 00082.65, 00105.90 ]",
    "#0000008242 NoMotion-1. Scaled. Acc [ -00051.76, -00004.39, 00993.65 ], Gyr [ 00002.44, -00000.18, -00001.52 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008243 NoMotion-1. Scaled. Acc [ -00051.76, -00000.98, 00988.28 ], Gyr [ 00000.85, 00003.05, -00003.23 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008244 NoMotion-1. Scaled. Acc [ -00040.04, -00010.74, 00989.26 ], Gyr [ 00000.18, 00001.83, -00001.59 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008245 NoMotion-1. Scaled. Acc [ -00041.99, -00018.07, 00986.82 ], Gyr [ 00002.68, 00000.85, -00003.35 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008246 NoMotion-1. Scaled. Acc [ -00048.34, 00001.95, 00996.58 ], Gyr [ 00001.52, -00000.06, -00002.44 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000008247 NoMotion-1. Scaled. Acc [ -00039.55, -00003.91, 00986.82 ], Gyr [ 00000.67, 00001.10, -00002.07 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000008248 NoMotion-1. Scaled. Acc [ -00040.04, -00001.95, 00984.38 ], Gyr [ 00000.30, -00001.34, -00001.16 ], Mag [ 00018.60, 00083.40, 00107.70 ]",
    "#0000008249 NoMotion-1. Scaled. Acc [ -00054.69, -00007.81, 01005.37 ], Gyr [ -00000.30, 00003.90, -00004.45 ], Mag [ 00018.60, 00084.45, 00107.70 ]",
    "#0000008250 NoMotion-1. Scaled. Acc [ -00040.53, -00009.28, 00995.12 ], Gyr [ 00000.55, 00000.30, 00000.61 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008251 NoMotion-1. Scaled. Acc [ -00055.18, -00014.65, 00993.65 ], Gyr [ 00002.20, 00000.30, -00000.49 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008252 NoMotion-1. Scaled. Acc [ -00039.55, 00005.37, 00983.89 ], Gyr [ 00001.40, 00000.61, -00001.77 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008253 NoMotion-1. Scaled. Acc [ -00060.06, -00016.11, 00988.77 ], Gyr [ 00000.91, 00001.77, -00002.44 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008254 NoMotion-1. Scaled. Acc [ -00047.36, -00004.39, 00996.09 ], Gyr [ 00000.00, 00000.79, -00001.71 ], Mag [ 00020.70, 00083.25, 00107.55 ]",
    "#0000008255 NoMotion-1. Scaled. Acc [ -00046.88, -00011.23, 00997.56 ], Gyr [ 00004.02, 00001.28, -00001.10 ], Mag [ 00020.70, 00083.25, 00107.55 ]",
    "#0000008256 NoMotion-1. Scaled. Acc [ -00034.18, -00013.18, 00997.56 ], Gyr [ -00000.06, 00002.44, -00001.65 ], Mag [ 00019.20, 00084.00, 00107.55 ]",
    "#0000008257 NoMotion-1. Scaled. Acc [ -00040.53, -00016.60, 00982.42 ], Gyr [ -00000.06, 00001.28, -00003.11 ], Mag [ 00019.20, 00084.00, 00107.55 ]",
    "#0000008258 NoMotion-1. Scaled. Acc [ -00036.13, -00003.91, 00994.14 ], Gyr [ 00001.22, 00001.95, 00001.28 ], Mag [ 00018.75, 00082.50, 00106.50 ]",
    "#0000008259 NoMotion-1. Scaled. Acc [ -00049.80, -00007.81, 01000.98 ], Gyr [ 00000.37, -00000.67, 00000.98 ], Mag [ 00018.75, 00082.50, 00106.50 ]",
    "#0000008260 NoMotion-1. Scaled. Acc [ -00045.90, 00005.86, 00989.26 ], Gyr [ -00000.12, 00000.00, -00003.48 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008261 NoMotion-1. Scaled. Acc [ -00046.39, -00017.09, 00988.77 ], Gyr [ 00000.24, 00002.56, -00001.40 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008262 NoMotion-1. Scaled. Acc [ -00040.04, -00006.84, 00991.21 ], Gyr [ 00002.50, 00000.98, 00000.00 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008263 NoMotion-1. Scaled. Acc [ -00041.99, -00005.37, 00978.03 ], Gyr [ 00000.98, -00000.24, 00001.04 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008264 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00982.42 ], Gyr [ 00001.71, 00001.95, -00003.29 ], Mag [ 00019.95, 00082.80, 00106.50 ]",
    "#0000008265 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00996.09 ], Gyr [ 00000.06, 00002.32, -00000.43 ], Mag [ 00019.95, 00082.80, 00106.50 ]",
    "#0000008266 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00990.23 ], Gyr [ -00000.30, 00001.16, -00000.79 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008267 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 01001.46 ], Gyr [ 00000.18, 00003.60, -00002.26 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008268 NoMotion-1. Scaled. Acc [ -00053.22, -00012.21, 00994.14 ], Gyr [ -00000.98, -00000.12, -00003.78 ], Mag [ 00019.50, 00082.80, 00108.60 ]",
    "#0000008269 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00989.75 ], Gyr [ -00000.55, 00002.13, 00001.46 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008270 NoMotion-1. Scaled. Acc [ -00041.99, -00011.72, 00993.65 ], Gyr [ -00001.28, -00000.12, -00001.59 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008271 NoMotion-1. Scaled. Acc [ -00047.85, -00009.77, 00975.59 ], Gyr [ 00000.85, -00000.30, -00001.46 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008272 NoMotion-1. Scaled. Acc [ -00042.97, 00000.98, 00987.79 ], Gyr [ -00001.46, 00000.98, -00002.13 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008273 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00994.63 ], Gyr [ 00000.55, 00001.04, -00003.17 ], Mag [ 00019.35, 00083.70, 00108.75 ]",
    "#0000008274 NoMotion-1. Scaled. Acc [ -00036.13, -00009.77, 00983.40 ], Gyr [ -00000.18, -00001.28, 00001.40 ], Mag [ 00019.35, 00083.70, 00108.75 ]",
    "#0000008275 NoMotion-1. Scaled. Acc [ -00052.25, -00006.35, 01003.42 ], Gyr [ 00001.28, -00001.59, -00001.52 ], Mag [ 00018.15, 00081.75, 00106.80 ]",
    "#0000008276 NoMotion-1. Scaled. Acc [ -00045.41, -00018.07, 00987.30 ], Gyr [ 00001.59, -00001.52, -00001.04 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000008277 NoMotion-1. Scaled. Acc [ -00050.29, -00013.67, 00984.86 ], Gyr [ -00001.89, 00002.44, -00002.68 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000008278 NoMotion-1. Scaled. Acc [ -00041.50, -00011.23, 00987.79 ], Gyr [ 00001.52, -00000.43, 00000.24 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008279 NoMotion-1. Scaled. Acc [ -00034.67, -00010.25, 00995.61 ], Gyr [ 00002.13, 00001.22, -00001.59 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008280 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00979.49 ], Gyr [ -00001.10, 00005.00, -00003.48 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008281 NoMotion-1. Scaled. Acc [ -00043.95, 00001.95, 00986.33 ], Gyr [ -00001.10, 00001.77, -00002.32 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008282 NoMotion-1. Scaled. Acc [ -00049.32, -00003.91, 00982.91 ], Gyr [ 00001.16, 00001.46, -00002.80 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008283 NoMotion-1. Scaled. Acc [ -00049.32, -00011.72, 00988.77 ], Gyr [ 00001.40, 00000.30, 00000.43 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008284 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00988.28 ], Gyr [ 00000.61, 00001.46, -00000.91 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008285 NoMotion-1. Scaled. Acc [ -00049.32, -00023.44, 00988.77 ], Gyr [ -00000.85, 00001.59, -00001.95 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008286 NoMotion-1. Scaled. Acc [ -00033.69, -00015.14, 00986.33 ], Gyr [ 00000.73, 00000.55, 00003.11 ], Mag [ 00019.35, 00083.70, 00106.95 ]",
    "#0000008287 NoMotion-1. Scaled. Acc [ -00031.74, -00002.93, 00985.84 ], Gyr [ 00000.18, 00000.98, 00001.46 ], Mag [ 00019.05, 00083.40, 00108.45 ]",
    "#0000008288 NoMotion-1. Scaled. Acc [ -00041.50, -00013.67, 00993.65 ], Gyr [ 00000.79, 00000.43, -00002.26 ], Mag [ 00019.05, 00083.40, 00108.45 ]",
    "#0000008289 NoMotion-1. Scaled. Acc [ -00040.04, -00019.04, 00997.56 ], Gyr [ 00001.95, -00001.34, -00001.28 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000008290 NoMotion-1. Scaled. Acc [ -00053.22, -00017.58, 00977.05 ], Gyr [ 00002.32, 00001.77, -00000.06 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000008291 NoMotion-1. Scaled. Acc [ -00040.04, -00009.28, 00993.16 ], Gyr [ -00000.37, 00000.98, -00002.93 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008292 NoMotion-1. Scaled. Acc [ -00045.90, -00002.44, 00979.49 ], Gyr [ 00002.13, -00001.10, -00000.37 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008293 NoMotion-1. Scaled. Acc [ -00054.69, -00011.72, 00990.72 ], Gyr [ -00000.18, 00002.93, 00001.28 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008294 NoMotion-1. Scaled. Acc [ -00044.92, -00006.35, 00995.61 ], Gyr [ -00000.98, -00000.79, -00002.26 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008295 NoMotion-1. Scaled. Acc [ -00046.88, -00012.21, 00988.77 ], Gyr [ 00000.00, 00002.32, -00001.16 ], Mag [ 00020.40, 00084.15, 00106.95 ]",
    "#0000008296 NoMotion-1. Scaled. Acc [ -00044.43, -00003.42, 00996.58 ], Gyr [ 00000.12, 00001.95, -00002.80 ], Mag [ 00020.40, 00084.15, 00106.95 ]",
    "#0000008297 NoMotion-1. Scaled. Acc [ -00031.25, -00016.60, 00999.51 ], Gyr [ 00000.55, 00002.87, -00001.22 ], Mag [ 00021.00, 00084.00, 00106.50 ]",
    "#0000008298 NoMotion-1. Scaled. Acc [ -00046.39, -00019.53, 00987.30 ], Gyr [ -00000.24, 00002.68, -00001.89 ], Mag [ 00021.00, 00084.00, 00106.50 ]",
    "#0000008299 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00991.21 ], Gyr [ 00002.26, 00000.91, 00000.37 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008300 NoMotion-1. Scaled. Acc [ -00035.16, -00013.67, 00979.49 ], Gyr [ 00001.22, 00001.52, -00001.22 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008301 NoMotion-1. Scaled. Acc [ -00045.41, -00009.77, 00986.33 ], Gyr [ 00002.93, 00000.43, -00000.49 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008302 NoMotion-1. Scaled. Acc [ -00036.62, 00001.95, 00987.30 ], Gyr [ 00000.24, 00000.12, -00003.11 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008303 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00998.05 ], Gyr [ 00002.44, 00000.49, -00000.67 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008304 NoMotion-1. Scaled. Acc [ -00039.55, -00022.46, 00982.91 ], Gyr [ 00001.22, 00000.37, 00000.37 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008305 NoMotion-1. Scaled. Acc [ -00028.32, -00007.32, 00998.05 ], Gyr [ -00000.85, -00000.37, -00002.20 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000008306 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 00986.82 ], Gyr [ 00000.55, 00000.06, -00001.59 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000008307 NoMotion-1. Scaled. Acc [ -00035.16, -00015.14, 00989.75 ], Gyr [ -00000.24, 00002.56, -00000.79 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000008308 NoMotion-1. Scaled. Acc [ -00035.16, 00004.88, 00993.16 ], Gyr [ -00002.50, 00001.83, -00002.01 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000008309 NoMotion-1. Scaled. Acc [ -00041.99, 00000.98, 00999.02 ], Gyr [ 00000.67, 00002.26, -00001.89 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008310 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00979.49 ], Gyr [ 00000.55, 00001.52, -00000.37 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008311 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00988.28 ], Gyr [ -00000.12, -00000.61, -00001.16 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008312 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00993.65 ], Gyr [ 00001.04, -00000.61, -00003.17 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008313 NoMotion-1. Scaled. Acc [ -00052.25, -00003.91, 01001.95 ], Gyr [ 00002.93, 00000.43, 00000.30 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008314 NoMotion-1. Scaled. Acc [ -00058.59, -00010.25, 00993.16 ], Gyr [ -00000.12, -00000.61, 00000.06 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008315 NoMotion-1. Scaled. Acc [ -00037.60, -00017.58, 00997.07 ], Gyr [ 00000.98, 00002.20, 00000.12 ], Mag [ 00018.60, 00084.15, 00106.20 ]",
    "#0000008316 NoMotion-1. Scaled. Acc [ -00053.22, -00003.91, 01004.88 ], Gyr [ 00000.49, 00001.65, -00001.22 ], Mag [ 00018.60, 00084.15, 00106.20 ]",
    "#0000008317 NoMotion-1. Scaled. Acc [ -00031.74, -00004.39, 00983.89 ], Gyr [ 00000.85, 00002.62, -00002.38 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000008318 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00984.86 ], Gyr [ -00000.61, 00002.07, -00001.34 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000008319 NoMotion-1. Scaled. Acc [ -00046.88, -00016.11, 01000.49 ], Gyr [ 00000.18, 00002.32, -00000.79 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008320 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00981.45 ], Gyr [ 00000.85, 00005.12, -00002.01 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008321 NoMotion-1. Scaled. Acc [ -00038.09, 00001.46, 00994.63 ], Gyr [ 00000.06, 00003.66, -00002.68 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008322 NoMotion-1. Scaled. Acc [ -00028.32, -00009.77, 00993.16 ], Gyr [ 00001.46, 00002.01, -00003.35 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008323 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00992.68 ], Gyr [ 00002.32, 00000.85, -00001.10 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000008324 NoMotion-1. Scaled. Acc [ -00039.55, -00016.60, 01000.49 ], Gyr [ 00001.16, 00001.95, 00001.77 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000008325 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00997.07 ], Gyr [ -00001.46, 00000.98, -00003.29 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008326 NoMotion-1. Scaled. Acc [ -00040.53, -00015.62, 00994.14 ], Gyr [ 00001.22, -00000.18, -00001.65 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008327 NoMotion-1. Scaled. Acc [ -00047.85, 00000.98, 00981.45 ], Gyr [ 00001.34, -00000.98, -00000.61 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000008328 NoMotion-1. Scaled. Acc [ -00044.43, -00003.42, 00980.47 ], Gyr [ -00000.55, 00000.98, 00000.67 ], Mag [ 00019.50, 00084.75, 00107.55 ]",
    "#0000008329 NoMotion-1. Scaled. Acc [ -00038.57, -00010.25, 00974.12 ], Gyr [ 00000.55, 00001.10, -00001.83 ], Mag [ 00019.50, 00084.75, 00107.55 ]",
    "#0000008330 NoMotion-1. Scaled. Acc [ -00040.53, -00010.74, 01001.95 ], Gyr [ -00001.65, 00002.38, -00001.52 ], Mag [ 00019.50, 00084.75, 00107.55 ]",
    "#0000008331 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00994.63 ], Gyr [ -00000.24, 00000.37, -00001.52 ], Mag [ 00021.15, 00084.15, 00107.70 ]",
    "#0000008332 NoMotion-1. Scaled. Acc [ -00040.53, -00007.81, 00988.77 ], Gyr [ -00000.37, 00000.49, -00003.17 ], Mag [ 00021.15, 00084.15, 00107.70 ]",
    "#0000008333 NoMotion-1. Scaled. Acc [ -00053.71, -00004.88, 00993.16 ], Gyr [ -00001.28, 00001.16, -00002.07 ], Mag [ 00020.25, 00084.15, 00107.70 ]",
    "#0000008334 NoMotion-1. Scaled. Acc [ -00047.36, -00002.44, 00993.65 ], Gyr [ 00000.37, 00002.32, -00001.71 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008335 NoMotion-1. Scaled. Acc [ -00047.36, -00009.77, 00996.09 ], Gyr [ 00000.91, 00001.22, -00001.89 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008336 NoMotion-1. Scaled. Acc [ -00041.99, -00004.88, 00981.93 ], Gyr [ 00000.98, 00002.74, -00003.23 ], Mag [ 00020.10, 00082.20, 00106.95 ]",
    "#0000008337 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00987.30 ], Gyr [ -00000.55, 00000.79, -00002.01 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008338 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00987.79 ], Gyr [ 00000.98, 00000.18, -00001.52 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008339 NoMotion-1. Scaled. Acc [ -00036.13, -00000.98, 00992.19 ], Gyr [ -00000.30, -00000.37, 00000.37 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008340 NoMotion-1. Scaled. Acc [ -00041.50, -00010.25, 00991.70 ], Gyr [ 00001.83, 00001.40, -00000.73 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008341 NoMotion-1. Scaled. Acc [ -00033.69, -00011.72, 00987.79 ], Gyr [ -00000.79, 00001.95, -00003.17 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000008342 NoMotion-1. Scaled. Acc [ -00048.34, 00004.39, 00980.96 ], Gyr [ -00000.49, 00004.02, -00001.71 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000008343 NoMotion-1. Scaled. Acc [ -00033.69, -00007.32, 00986.82 ], Gyr [ 00001.77, 00002.01, -00000.61 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008344 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00993.16 ], Gyr [ 00001.65, 00000.37, -00000.85 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008345 NoMotion-1. Scaled. Acc [ -00043.95, 00009.77, 00985.84 ], Gyr [ -00000.06, 00001.34, -00003.48 ], Mag [ 00020.70, 00083.55, 00107.85 ]",
    "#0000008346 NoMotion-1. Scaled. Acc [ -00032.71, -00003.91, 00993.65 ], Gyr [ 00000.61, 00002.74, -00002.93 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008347 NoMotion-1. Scaled. Acc [ -00046.39, -00030.76, 00992.19 ], Gyr [ 00000.91, 00000.12, -00001.77 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008348 NoMotion-1. Scaled. Acc [ -00045.90, -00011.72, 00998.54 ], Gyr [ 00000.79, 00002.38, -00002.44 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008349 NoMotion-1. Scaled. Acc [ -00039.55, -00011.23, 00984.38 ], Gyr [ 00001.52, 00000.73, -00000.12 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008350 NoMotion-1. Scaled. Acc [ -00046.88, -00006.84, 00990.23 ], Gyr [ -00001.71, 00001.28, -00003.29 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000008351 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00990.72 ], Gyr [ -00000.24, 00000.43, 00000.06 ], Mag [ 00017.70, 00084.00, 00107.55 ]",
    "#0000008352 NoMotion-1. Scaled. Acc [ -00050.29, -00014.65, 00995.12 ], Gyr [ 00000.85, 00000.85, -00000.18 ], Mag [ 00020.70, 00082.80, 00106.50 ]",
    "#0000008353 NoMotion-1. Scaled. Acc [ -00046.88, -00020.02, 00997.07 ], Gyr [ 00000.30, 00000.79, -00004.39 ], Mag [ 00020.70, 00082.80, 00106.50 ]",
    "#0000008354 NoMotion-1. Scaled. Acc [ -00036.62, -00006.84, 00995.61 ], Gyr [ -00000.91, 00000.98, -00002.80 ], Mag [ 00020.70, 00082.80, 00106.50 ]",
    "#0000008355 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00991.70 ], Gyr [ -00000.85, 00001.34, -00003.72 ], Mag [ 00020.70, 00083.25, 00107.85 ]",
    "#0000008356 NoMotion-1. Scaled. Acc [ -00039.06, 00000.49, 00994.14 ], Gyr [ 00000.37, -00000.73, 00000.91 ], Mag [ 00020.70, 00083.25, 00107.85 ]",
    "#0000008357 NoMotion-1. Scaled. Acc [ -00040.53, 00001.95, 00989.75 ], Gyr [ -00000.73, 00001.89, -00002.13 ], Mag [ 00018.60, 00083.70, 00108.45 ]",
    "#0000008358 NoMotion-1. Scaled. Acc [ -00040.53, -00006.35, 00986.33 ], Gyr [ -00000.73, 00003.90, -00003.72 ], Mag [ 00018.60, 00083.70, 00108.45 ]",
    "#0000008359 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 00982.91 ], Gyr [ 00000.37, 00002.68, -00002.13 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000008360 NoMotion-1. Scaled. Acc [ -00036.13, -00004.88, 00992.68 ], Gyr [ 00001.77, -00001.16, -00003.66 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000008361 NoMotion-1. Scaled. Acc [ -00041.02, -00006.84, 01000.98 ], Gyr [ 00001.28, 00002.07, -00000.37 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008362 NoMotion-1. Scaled. Acc [ -00048.34, -00009.28, 00984.86 ], Gyr [ 00001.40, -00003.11, -00000.12 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008363 NoMotion-1. Scaled. Acc [ -00044.92, -00000.49, 00983.40 ], Gyr [ 00001.52, 00002.80, -00000.30 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008364 NoMotion-1. Scaled. Acc [ -00058.11, -00004.88, 00989.75 ], Gyr [ -00000.18, 00002.32, -00003.48 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008365 NoMotion-1. Scaled. Acc [ -00049.80, -00010.74, 00993.65 ], Gyr [ 00000.79, -00000.30, -00002.26 ], Mag [ 00018.60, 00083.70, 00107.40 ]",
    "#0000008366 NoMotion-1. Scaled. Acc [ -00035.64, -00009.77, 00998.54 ], Gyr [ 00001.59, 00003.17, -00000.06 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008367 NoMotion-1. Scaled. Acc [ -00043.95, -00010.25, 01000.00 ], Gyr [ -00001.10, 00000.61, -00002.56 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008368 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 00986.33 ], Gyr [ 00000.55, 00000.67, -00002.50 ], Mag [ 00020.85, 00084.00, 00107.85 ]",
    "#0000008369 NoMotion-1. Scaled. Acc [ -00041.99, 00000.49, 00987.30 ], Gyr [ -00000.43, 00002.50, -00001.46 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000008370 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00987.79 ], Gyr [ 00000.43, 00001.10, -00001.71 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008371 NoMotion-1. Scaled. Acc [ -00036.62, 00006.35, 00989.26 ], Gyr [ 00001.28, -00000.85, -00002.44 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008372 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00981.45 ], Gyr [ -00000.98, 00001.95, -00001.89 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008373 NoMotion-1. Scaled. Acc [ -00056.64, -00001.46, 00999.02 ], Gyr [ -00002.74, 00003.66, -00000.79 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008374 NoMotion-1. Scaled. Acc [ -00052.73, -00005.37, 00990.72 ], Gyr [ 00000.79, 00001.34, 00000.18 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000008375 NoMotion-1. Scaled. Acc [ -00033.20, -00010.25, 00991.70 ], Gyr [ 00000.18, 00000.55, 00000.91 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000008376 NoMotion-1. Scaled. Acc [ -00047.85, -00002.93, 00989.75 ], Gyr [ 00001.59, 00002.07, -00002.62 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008377 NoMotion-1. Scaled. Acc [ -00046.39, -00006.84, 00989.26 ], Gyr [ 00000.73, 00001.16, -00000.43 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008378 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00994.14 ], Gyr [ 00002.01, 00001.04, 00000.00 ], Mag [ 00019.95, 00084.30, 00106.80 ]",
    "#0000008379 NoMotion-1. Scaled. Acc [ -00050.78, -00003.42, 00979.00 ], Gyr [ 00000.98, -00000.98, 00001.22 ], Mag [ 00019.95, 00084.30, 00106.80 ]",
    "#0000008380 NoMotion-1. Scaled. Acc [ -00047.85, -00000.49, 00993.65 ], Gyr [ 00001.77, 00002.62, -00002.26 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008381 NoMotion-1. Scaled. Acc [ -00039.55, -00012.21, 00987.30 ], Gyr [ -00000.43, 00002.87, -00003.66 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008382 NoMotion-1. Scaled. Acc [ -00039.06, -00003.91, 00977.54 ], Gyr [ -00000.37, 00000.06, -00001.10 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008383 NoMotion-1. Scaled. Acc [ -00037.11, -00008.79, 00994.63 ], Gyr [ 00001.46, 00000.37, -00001.10 ], Mag [ 00018.75, 00083.55, 00106.50 ]",
    "#0000008384 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00987.30 ], Gyr [ -00000.79, 00002.68, -00004.45 ], Mag [ 00018.75, 00083.55, 00106.50 ]",
    "#0000008385 NoMotion-1. Scaled. Acc [ -00046.88, 00000.49, 00990.72 ], Gyr [ 00000.37, 00005.06, 00001.89 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008386 NoMotion-1. Scaled. Acc [ -00028.81, 00002.44, 00991.21 ], Gyr [ 00001.77, 00000.73, -00002.20 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008387 NoMotion-1. Scaled. Acc [ -00042.97, -00013.67, 01005.37 ], Gyr [ 00001.65, -00001.65, -00001.52 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000008388 NoMotion-1. Scaled. Acc [ -00042.48, -00008.79, 01002.44 ], Gyr [ 00000.98, 00000.85, -00000.49 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008389 NoMotion-1. Scaled. Acc [ -00042.48, -00015.14, 01001.95 ], Gyr [ -00001.28, 00003.29, -00000.73 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008390 NoMotion-1. Scaled. Acc [ -00051.27, -00007.32, 00988.77 ], Gyr [ 00000.55, 00001.65, -00004.39 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008391 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00991.70 ], Gyr [ 00001.04, 00002.68, -00000.79 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008392 NoMotion-1. Scaled. Acc [ -00046.39, -00013.67, 00994.63 ], Gyr [ -00000.18, -00000.37, -00000.24 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000008393 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00986.33 ], Gyr [ 00000.91, 00002.44, -00001.46 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000008394 NoMotion-1. Scaled. Acc [ -00035.16, -00003.42, 00998.05 ], Gyr [ -00000.12, -00000.73, -00003.05 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000008395 NoMotion-1. Scaled. Acc [ -00037.11, -00003.42, 00992.19 ], Gyr [ -00001.16, -00001.83, -00000.67 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000008396 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00988.28 ], Gyr [ -00000.12, 00001.46, -00002.44 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000008397 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00990.72 ], Gyr [ -00001.95, 00003.84, -00000.73 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000008398 NoMotion-1. Scaled. Acc [ -00038.57, -00010.74, 00975.59 ], Gyr [ -00001.16, 00000.79, -00001.95 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008399 NoMotion-1. Scaled. Acc [ -00046.39, -00008.30, 00971.19 ], Gyr [ 00001.59, 00001.22, -00001.46 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008400 NoMotion-1. Scaled. Acc [ -00049.80, -00017.09, 00981.45 ], Gyr [ 00000.30, 00000.37, -00004.57 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008401 NoMotion-1. Scaled. Acc [ -00055.18, -00011.23, 00990.23 ], Gyr [ 00001.16, 00001.52, -00001.95 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008402 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00988.77 ], Gyr [ 00001.77, 00000.43, -00002.01 ], Mag [ 00018.45, 00085.05, 00107.85 ]",
    "#0000008403 NoMotion-1. Scaled. Acc [ -00048.34, -00011.23, 00995.61 ], Gyr [ 00002.20, 00000.98, 00001.04 ], Mag [ 00019.65, 00084.45, 00107.70 ]",
    "#0000008404 NoMotion-1. Scaled. Acc [ -00031.25, -00002.44, 00984.86 ], Gyr [ 00000.00, 00000.67, -00002.01 ], Mag [ 00019.65, 00084.45, 00107.70 ]",
    "#0000008405 NoMotion-1. Scaled. Acc [ -00031.74, -00007.32, 00985.84 ], Gyr [ 00000.43, -00000.06, -00003.05 ], Mag [ 00019.35, 00082.65, 00108.45 ]",
    "#0000008406 NoMotion-1. Scaled. Acc [ -00039.55, -00013.67, 00996.58 ], Gyr [ 00000.55, 00001.16, -00004.51 ], Mag [ 00019.35, 00082.65, 00108.45 ]",
    "#0000008407 NoMotion-1. Scaled. Acc [ -00049.32, -00001.95, 00988.77 ], Gyr [ 00001.10, 00001.22, -00000.12 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008408 NoMotion-1. Scaled. Acc [ -00046.39, 00003.42, 00999.02 ], Gyr [ -00000.61, 00002.87, -00002.62 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008409 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00988.28 ], Gyr [ -00000.18, 00000.91, -00001.77 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008410 NoMotion-1. Scaled. Acc [ -00034.67, -00018.55, 00993.16 ], Gyr [ 00000.49, -00000.37, 00001.28 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008411 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00995.12 ], Gyr [ 00000.43, 00000.55, -00000.43 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008412 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00980.96 ], Gyr [ -00000.30, 00002.01, -00000.67 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008413 NoMotion-1. Scaled. Acc [ -00049.32, -00014.16, 00994.63 ], Gyr [ 00000.12, 00000.61, -00001.89 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008414 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00983.89 ], Gyr [ 00000.06, 00000.37, -00000.24 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008415 NoMotion-1. Scaled. Acc [ -00040.53, 00004.39, 00997.56 ], Gyr [ 00001.04, 00002.50, -00000.37 ], Mag [ 00021.15, 00083.40, 00108.00 ]",
    "#0000008416 NoMotion-1. Scaled. Acc [ -00042.97, -00012.21, 00987.30 ], Gyr [ 00003.05, -00000.30, -00000.18 ], Mag [ 00021.15, 00083.40, 00108.00 ]",
    "#0000008417 NoMotion-1. Scaled. Acc [ -00049.80, -00013.18, 00987.30 ], Gyr [ 00002.20, 00003.11, 00000.49 ], Mag [ 00019.20, 00084.30, 00109.35 ]",
    "#0000008418 NoMotion-1. Scaled. Acc [ -00037.11, 00004.39, 00992.19 ], Gyr [ 00000.91, 00000.73, -00000.49 ], Mag [ 00019.20, 00084.30, 00109.35 ]",
    "#0000008419 NoMotion-1. Scaled. Acc [ -00055.66, 00001.95, 01000.00 ], Gyr [ 00001.34, 00004.15, -00002.62 ], Mag [ 00019.20, 00084.30, 00109.35 ]",
    "#0000008420 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 00990.23 ], Gyr [ -00000.43, 00002.20, -00002.99 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008421 NoMotion-1. Scaled. Acc [ -00043.46, -00010.74, 00982.42 ], Gyr [ -00000.73, -00001.10, -00001.59 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008422 NoMotion-1. Scaled. Acc [ -00037.60, -00003.91, 00989.75 ], Gyr [ -00000.43, 00000.85, -00000.98 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008423 NoMotion-1. Scaled. Acc [ -00044.92, -00002.44, 00998.05 ], Gyr [ 00000.12, 00000.37, -00004.02 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008424 NoMotion-1. Scaled. Acc [ -00035.64, -00001.95, 00992.68 ], Gyr [ 00000.43, 00002.20, -00000.24 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000008425 NoMotion-1. Scaled. Acc [ -00052.73, 00005.37, 00989.75 ], Gyr [ 00000.79, 00003.11, -00002.20 ], Mag [ 00020.25, 00082.50, 00108.60 ]",
    "#0000008426 NoMotion-1. Scaled. Acc [ -00043.46, -00013.18, 00998.05 ], Gyr [ 00000.79, -00000.43, 00000.73 ], Mag [ 00020.25, 00082.50, 00108.60 ]",
    "#0000008427 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00992.68 ], Gyr [ 00000.91, -00000.06, 00000.30 ], Mag [ 00020.25, 00082.50, 00108.60 ]",
    "#0000008428 NoMotion-1. Scaled. Acc [ -00032.71, 00003.91, 00995.12 ], Gyr [ 00001.04, -00000.18, -00001.10 ], Mag [ 00018.75, 00083.55, 00107.85 ]",
    "#0000008429 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00995.12 ], Gyr [ 00001.28, -00000.55, -00001.22 ], Mag [ 00018.75, 00083.55, 00107.85 ]",
    "#0000008430 NoMotion-1. Scaled. Acc [ -00044.92, -00001.95, 00987.30 ], Gyr [ -00001.59, -00001.16, 00000.12 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000008431 NoMotion-1. Scaled. Acc [ -00059.08, 00002.44, 00992.68 ], Gyr [ 00001.22, -00001.89, 00000.30 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000008432 NoMotion-1. Scaled. Acc [ -00040.04, -00008.30, 00993.65 ], Gyr [ -00000.91, 00000.79, -00002.74 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000008433 NoMotion-1. Scaled. Acc [ -00041.99, -00017.58, 00986.33 ], Gyr [ -00000.18, 00002.13, -00002.50 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000008434 NoMotion-1. Scaled. Acc [ -00049.80, -00017.58, 00993.65 ], Gyr [ -00001.16, 00002.87, -00003.05 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000008435 NoMotion-1. Scaled. Acc [ -00034.18, -00009.28, 00993.16 ], Gyr [ 00000.73, 00000.73, -00000.12 ], Mag [ 00018.75, 00083.25, 00107.85 ]",
    "#0000008436 NoMotion-1. Scaled. Acc [ -00050.78, -00011.72, 00988.77 ], Gyr [ -00000.24, 00002.13, 00000.43 ], Mag [ 00018.75, 00083.25, 00107.85 ]",
    "#0000008437 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 00999.02 ], Gyr [ -00000.79, 00002.07, -00002.80 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000008438 NoMotion-1. Scaled. Acc [ -00040.04, -00015.62, 01000.00 ], Gyr [ 00002.26, 00002.07, -00001.28 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008439 NoMotion-1. Scaled. Acc [ -00051.76, -00009.28, 00985.84 ], Gyr [ 00002.99, 00001.52, -00001.40 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008440 NoMotion-1. Scaled. Acc [ -00054.20, -00007.32, 00992.68 ], Gyr [ 00001.46, 00001.40, -00001.34 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008441 NoMotion-1. Scaled. Acc [ -00045.90, -00000.98, 00988.77 ], Gyr [ 00000.85, 00001.04, 00000.06 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008442 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00993.16 ], Gyr [ 00000.43, 00000.61, -00000.67 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008443 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00994.63 ], Gyr [ 00000.06, 00001.83, -00001.89 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008444 NoMotion-1. Scaled. Acc [ -00051.76, -00015.14, 00989.26 ], Gyr [ -00001.28, 00004.09, -00001.52 ], Mag [ 00020.10, 00085.20, 00106.65 ]",
    "#0000008445 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00989.26 ], Gyr [ 00000.67, 00002.44, -00001.46 ], Mag [ 00020.10, 00085.20, 00106.65 ]",
    "#0000008446 NoMotion-1. Scaled. Acc [ -00040.04, -00025.88, 00991.70 ], Gyr [ -00000.98, 00003.17, -00003.54 ], Mag [ 00018.60, 00082.20, 00106.95 ]",
    "#0000008447 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00988.77 ], Gyr [ 00000.73, 00001.22, -00000.85 ], Mag [ 00018.60, 00082.20, 00106.95 ]",
    "#0000008448 NoMotion-1. Scaled. Acc [ -00041.99, -00009.77, 00980.96 ], Gyr [ 00000.24, 00001.52, -00002.68 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008449 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00990.23 ], Gyr [ 00000.67, 00000.37, -00001.52 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008450 NoMotion-1. Scaled. Acc [ -00049.80, -00011.72, 00984.38 ], Gyr [ -00001.65, 00002.26, -00003.90 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008451 NoMotion-1. Scaled. Acc [ -00038.57, 00001.95, 00990.72 ], Gyr [ 00002.07, 00004.27, -00000.67 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008452 NoMotion-1. Scaled. Acc [ -00038.09, -00013.67, 00982.91 ], Gyr [ 00001.59, -00000.49, -00001.65 ], Mag [ 00019.05, 00084.15, 00108.75 ]",
    "#0000008453 NoMotion-1. Scaled. Acc [ -00044.92, -00000.49, 01002.93 ], Gyr [ 00001.52, 00002.38, -00000.43 ], Mag [ 00019.05, 00084.15, 00108.75 ]",
    "#0000008454 NoMotion-1. Scaled. Acc [ -00039.55, -00002.93, 00991.21 ], Gyr [ 00000.61, 00001.77, -00003.35 ], Mag [ 00021.30, 00084.00, 00108.30 ]",
    "#0000008455 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00986.33 ], Gyr [ 00001.04, -00000.37, -00000.61 ], Mag [ 00021.30, 00084.00, 00108.30 ]",
    "#0000008456 NoMotion-1. Scaled. Acc [ -00045.90, -00003.42, 01001.95 ], Gyr [ 00000.91, 00001.04, -00000.49 ], Mag [ 00021.15, 00082.65, 00108.45 ]",
    "#0000008457 NoMotion-1. Scaled. Acc [ -00040.04, 00001.95, 00992.68 ], Gyr [ 00000.06, 00001.28, -00003.78 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000008458 NoMotion-1. Scaled. Acc [ -00045.90, -00002.93, 00993.16 ], Gyr [ -00000.30, 00002.62, -00000.67 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000008459 NoMotion-1. Scaled. Acc [ -00035.64, -00007.81, 00990.72 ], Gyr [ 00000.49, 00000.24, -00000.79 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000008460 NoMotion-1. Scaled. Acc [ -00041.50, 00000.98, 00995.61 ], Gyr [ 00001.34, 00003.29, -00003.17 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000008461 NoMotion-1. Scaled. Acc [ -00054.20, -00002.93, 00984.38 ], Gyr [ 00000.49, 00000.67, -00001.34 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000008462 NoMotion-1. Scaled. Acc [ -00042.97, -00008.79, 00986.82 ], Gyr [ 00001.77, 00001.52, -00001.04 ], Mag [ 00019.20, 00083.55, 00108.30 ]",
    "#0000008463 NoMotion-1. Scaled. Acc [ -00041.99, -00014.16, 00991.70 ], Gyr [ 00001.65, 00004.09, -00000.98 ], Mag [ 00019.20, 00083.55, 00108.30 ]",
    "#0000008464 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00979.98 ], Gyr [ 00000.91, 00000.37, -00000.06 ], Mag [ 00018.75, 00084.75, 00108.60 ]",
    "#0000008465 NoMotion-1. Scaled. Acc [ -00059.08, -00000.49, 00997.07 ], Gyr [ 00003.17, 00000.18, -00000.73 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008466 NoMotion-1. Scaled. Acc [ -00048.34, -00011.23, 00988.77 ], Gyr [ 00000.91, 00001.89, -00002.93 ], Mag [ 00019.50, 00081.75, 00106.80 ]",
    "#0000008467 NoMotion-1. Scaled. Acc [ -00044.43, -00011.72, 00984.38 ], Gyr [ -00001.04, 00002.56, -00001.10 ], Mag [ 00019.50, 00081.75, 00106.80 ]",
    "#0000008468 NoMotion-1. Scaled. Acc [ -00038.09, -00005.37, 00980.96 ], Gyr [ -00000.49, 00000.85, -00004.39 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008469 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00992.68 ], Gyr [ 00000.37, 00001.34, -00001.22 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008470 NoMotion-1. Scaled. Acc [ -00059.08, 00001.46, 00994.63 ], Gyr [ 00000.55, 00002.99, -00003.96 ], Mag [ 00020.10, 00081.45, 00107.40 ]",
    "#0000008471 NoMotion-1. Scaled. Acc [ -00041.50, 00000.00, 00999.51 ], Gyr [ 00000.37, 00003.35, -00001.34 ], Mag [ 00020.10, 00081.45, 00107.40 ]",
    "#0000008472 NoMotion-1. Scaled. Acc [ -00063.48, -00012.21, 00990.72 ], Gyr [ 00000.98, 00000.67, -00001.59 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000008473 NoMotion-1. Scaled. Acc [ -00045.41, -00017.58, 00991.70 ], Gyr [ 00000.30, 00001.83, -00001.16 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000008474 NoMotion-1. Scaled. Acc [ -00047.85, -00019.53, 00994.14 ], Gyr [ 00002.20, 00001.52, -00001.65 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000008475 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00990.23 ], Gyr [ 00001.22, -00000.98, -00000.24 ], Mag [ 00018.60, 00084.90, 00108.00 ]",
    "#0000008476 NoMotion-1. Scaled. Acc [ -00041.50, -00014.65, 01004.39 ], Gyr [ 00001.34, 00001.22, 00000.85 ], Mag [ 00018.60, 00084.90, 00108.00 ]",
    "#0000008477 NoMotion-1. Scaled. Acc [ -00059.57, -00010.74, 00999.02 ], Gyr [ 00002.62, 00003.35, -00002.93 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008478 NoMotion-1. Scaled. Acc [ -00034.67, -00011.72, 00990.72 ], Gyr [ 00000.85, -00000.30, -00001.34 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008479 NoMotion-1. Scaled. Acc [ -00041.99, -00010.25, 00990.23 ], Gyr [ -00001.59, 00001.71, -00001.10 ], Mag [ 00019.65, 00084.45, 00109.50 ]",
    "#0000008480 NoMotion-1. Scaled. Acc [ -00038.57, -00016.11, 00989.26 ], Gyr [ 00001.34, 00001.22, -00002.93 ], Mag [ 00019.65, 00084.45, 00109.50 ]",
    "#0000008481 NoMotion-1. Scaled. Acc [ -00032.71, -00009.77, 00999.51 ], Gyr [ 00002.13, -00000.18, -00000.12 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008482 NoMotion-1. Scaled. Acc [ -00052.25, -00013.18, 00989.75 ], Gyr [ 00000.91, 00004.39, -00003.41 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008483 NoMotion-1. Scaled. Acc [ -00052.73, -00000.49, 00998.54 ], Gyr [ 00000.30, 00002.26, -00000.79 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008484 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00997.07 ], Gyr [ -00000.12, 00003.17, -00001.22 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008485 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 00992.68 ], Gyr [ 00001.89, 00002.68, -00000.12 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008486 NoMotion-1. Scaled. Acc [ -00050.78, -00010.25, 01006.35 ], Gyr [ 00000.55, -00000.24, -00000.06 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008487 NoMotion-1. Scaled. Acc [ -00047.85, -00017.09, 00990.72 ], Gyr [ -00000.12, 00000.30, -00000.24 ], Mag [ 00018.75, 00082.50, 00108.30 ]",
    "#0000008488 NoMotion-1. Scaled. Acc [ -00036.62, -00002.44, 00987.30 ], Gyr [ 00001.59, 00001.34, -00001.28 ], Mag [ 00018.75, 00082.50, 00108.30 ]",
    "#0000008489 NoMotion-1. Scaled. Acc [ -00041.02, -00003.91, 00996.09 ], Gyr [ 00002.50, 00000.00, 00000.18 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008490 NoMotion-1. Scaled. Acc [ -00036.13, -00014.65, 00994.14 ], Gyr [ -00000.06, -00000.85, -00000.24 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008491 NoMotion-1. Scaled. Acc [ -00036.13, -00013.67, 00998.05 ], Gyr [ -00001.04, 00004.09, -00001.52 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008492 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00985.84 ], Gyr [ -00000.49, 00001.89, -00000.55 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008493 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00993.65 ], Gyr [ -00001.77, -00000.12, -00001.34 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000008494 NoMotion-1. Scaled. Acc [ -00051.27, -00014.16, 00979.98 ], Gyr [ 00001.77, -00000.49, -00000.37 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000008495 NoMotion-1. Scaled. Acc [ -00044.92, -00005.86, 00990.23 ], Gyr [ -00000.12, 00002.01, 00000.43 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000008496 NoMotion-1. Scaled. Acc [ -00039.06, -00005.86, 00982.91 ], Gyr [ 00000.79, 00000.61, -00003.05 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000008497 NoMotion-1. Scaled. Acc [ -00051.76, -00005.37, 00993.16 ], Gyr [ 00000.61, 00004.27, -00001.04 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008498 NoMotion-1. Scaled. Acc [ -00037.60, -00006.35, 00981.45 ], Gyr [ 00001.28, 00000.98, -00000.61 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008499 NoMotion-1. Scaled. Acc [ -00041.02, -00004.88, 00974.12 ], Gyr [ -00000.12, 00001.28, -00001.10 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008500 NoMotion-1. Scaled. Acc [ -00050.78, -00008.79, 00992.68 ], Gyr [ 00000.67, 00001.10, -00002.32 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008501 NoMotion-1. Scaled. Acc [ -00034.18, -00013.67, 00989.26 ], Gyr [ 00001.28, 00002.80, -00000.43 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008502 NoMotion-1. Scaled. Acc [ -00048.34, -00012.70, 00993.65 ], Gyr [ 00001.46, 00001.34, -00004.21 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008503 NoMotion-1. Scaled. Acc [ -00049.80, -00018.07, 00984.38 ], Gyr [ 00000.12, 00000.18, 00001.89 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000008504 NoMotion-1. Scaled. Acc [ -00048.34, -00010.74, 00995.12 ], Gyr [ -00000.06, 00002.44, -00002.20 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000008505 NoMotion-1. Scaled. Acc [ -00054.69, -00012.21, 00988.77 ], Gyr [ 00000.24, 00001.34, -00002.20 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000008506 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00997.07 ], Gyr [ 00001.95, 00000.79, -00001.34 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000008507 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00993.65 ], Gyr [ 00001.65, 00001.22, -00002.26 ], Mag [ 00020.25, 00082.80, 00108.30 ]",
    "#0000008508 NoMotion-1. Scaled. Acc [ -00041.02, -00003.91, 00977.54 ], Gyr [ 00000.00, 00002.01, -00000.49 ], Mag [ 00020.25, 00082.80, 00108.30 ]",
    "#0000008509 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00987.30 ], Gyr [ 00001.40, 00000.43, -00005.49 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008510 NoMotion-1. Scaled. Acc [ -00035.16, -00008.30, 00996.58 ], Gyr [ 00000.67, 00001.59, -00002.13 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008511 NoMotion-1. Scaled. Acc [ -00049.32, -00014.16, 00994.63 ], Gyr [ -00002.32, 00000.79, -00000.06 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008512 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00991.70 ], Gyr [ -00000.98, -00000.73, -00001.89 ], Mag [ 00019.50, 00083.55, 00106.50 ]",
    "#0000008513 NoMotion-1. Scaled. Acc [ -00037.11, -00012.70, 00987.30 ], Gyr [ 00000.43, 00001.46, 00000.12 ], Mag [ 00019.50, 00083.55, 00106.50 ]",
    "#0000008514 NoMotion-1. Scaled. Acc [ -00044.92, -00016.60, 00990.72 ], Gyr [ 00002.56, 00001.95, -00000.12 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000008515 NoMotion-1. Scaled. Acc [ -00047.85, -00019.53, 00984.86 ], Gyr [ 00001.04, -00000.30, -00002.01 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000008516 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00989.26 ], Gyr [ -00000.18, 00003.23, -00001.59 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000008517 NoMotion-1. Scaled. Acc [ -00042.97, -00003.42, 00985.35 ], Gyr [ 00001.22, 00000.49, -00001.71 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000008518 NoMotion-1. Scaled. Acc [ -00033.69, -00007.81, 00978.52 ], Gyr [ 00001.52, 00000.67, 00000.00 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000008519 NoMotion-1. Scaled. Acc [ -00031.25, -00015.62, 00996.09 ], Gyr [ -00001.04, 00000.18, -00003.05 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000008520 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00998.05 ], Gyr [ -00001.16, 00004.09, 00000.55 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008521 NoMotion-1. Scaled. Acc [ -00053.22, -00006.35, 00995.61 ], Gyr [ 00000.24, 00002.74, -00002.01 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008522 NoMotion-1. Scaled. Acc [ -00040.53, -00006.84, 00995.12 ], Gyr [ 00000.98, 00002.87, -00001.28 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008523 NoMotion-1. Scaled. Acc [ -00043.95, -00006.84, 00989.75 ], Gyr [ 00000.06, 00001.65, 00000.85 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008524 NoMotion-1. Scaled. Acc [ -00048.34, 00000.00, 00988.77 ], Gyr [ -00000.49, 00002.62, -00001.52 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000008525 NoMotion-1. Scaled. Acc [ -00034.67, -00016.11, 00991.21 ], Gyr [ 00001.22, 00000.43, -00002.44 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000008526 NoMotion-1. Scaled. Acc [ -00062.01, -00008.30, 00996.09 ], Gyr [ 00003.23, -00001.04, 00000.67 ], Mag [ 00018.75, 00084.30, 00109.65 ]",
    "#0000008527 NoMotion-1. Scaled. Acc [ -00043.95, 00001.95, 00987.30 ], Gyr [ 00001.89, -00000.06, -00000.79 ], Mag [ 00018.75, 00084.30, 00109.65 ]",
    "#0000008528 NoMotion-1. Scaled. Acc [ -00034.67, -00007.81, 00985.84 ], Gyr [ 00000.12, -00001.16, 00000.43 ], Mag [ 00018.75, 00084.30, 00109.65 ]",
    "#0000008529 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00979.98 ], Gyr [ 00001.95, 00001.65, 00001.04 ], Mag [ 00021.30, 00083.25, 00107.85 ]",
    "#0000008530 NoMotion-1. Scaled. Acc [ -00048.83, -00004.88, 00990.72 ], Gyr [ 00001.40, 00001.95, -00002.07 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008531 NoMotion-1. Scaled. Acc [ -00038.57, -00015.62, 00988.77 ], Gyr [ -00001.22, 00001.34, -00000.06 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008532 NoMotion-1. Scaled. Acc [ -00042.48, -00002.44, 00999.51 ], Gyr [ -00001.28, 00001.46, 00000.37 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008533 NoMotion-1. Scaled. Acc [ -00035.64, -00013.67, 00996.09 ], Gyr [ 00000.30, 00001.83, -00002.07 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000008534 NoMotion-1. Scaled. Acc [ -00041.02, -00005.37, 00986.82 ], Gyr [ 00000.00, -00001.34, -00001.77 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000008535 NoMotion-1. Scaled. Acc [ -00041.99, -00015.62, 00987.79 ], Gyr [ 00001.34, 00001.40, 00000.24 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008536 NoMotion-1. Scaled. Acc [ -00041.99, -00014.16, 00995.61 ], Gyr [ 00000.91, 00004.63, -00002.93 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008537 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00984.38 ], Gyr [ 00003.11, 00002.38, -00001.22 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008538 NoMotion-1. Scaled. Acc [ -00049.32, -00004.88, 00995.61 ], Gyr [ 00001.22, 00000.91, -00002.32 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008539 NoMotion-1. Scaled. Acc [ -00050.78, 00002.44, 00998.54 ], Gyr [ 00001.34, 00002.07, -00001.46 ], Mag [ 00019.95, 00082.80, 00107.85 ]",
    "#0000008540 NoMotion-1. Scaled. Acc [ -00044.92, -00007.81, 00988.28 ], Gyr [ 00002.01, 00001.10, -00000.18 ], Mag [ 00021.30, 00083.55, 00107.85 ]",
    "#0000008541 NoMotion-1. Scaled. Acc [ -00028.81, -00001.46, 00988.28 ], Gyr [ -00000.37, 00000.00, -00003.29 ], Mag [ 00021.30, 00083.55, 00107.85 ]",
    "#0000008542 NoMotion-1. Scaled. Acc [ -00047.85, -00004.39, 00982.91 ], Gyr [ 00001.10, 00001.34, -00001.71 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000008543 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00978.03 ], Gyr [ 00000.30, 00002.07, -00001.77 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000008544 NoMotion-1. Scaled. Acc [ -00061.04, -00008.79, 00996.09 ], Gyr [ 00000.18, 00001.65, -00003.54 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008545 NoMotion-1. Scaled. Acc [ -00047.85, -00001.46, 00993.16 ], Gyr [ 00000.18, 00001.28, -00001.83 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008546 NoMotion-1. Scaled. Acc [ -00036.62, -00017.58, 01004.39 ], Gyr [ -00000.12, 00002.07, -00000.98 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008547 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00981.93 ], Gyr [ -00000.55, 00003.17, -00002.07 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008548 NoMotion-1. Scaled. Acc [ -00048.34, -00002.93, 00989.75 ], Gyr [ 00001.46, 00000.00, -00001.04 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000008549 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 00991.70 ], Gyr [ 00000.91, 00001.52, -00001.28 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000008550 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00995.61 ], Gyr [ 00001.59, 00002.20, -00001.95 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000008551 NoMotion-1. Scaled. Acc [ -00041.99, -00002.44, 00993.65 ], Gyr [ 00001.40, -00001.59, -00000.43 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008552 NoMotion-1. Scaled. Acc [ -00038.57, -00004.39, 00996.58 ], Gyr [ 00002.13, 00001.89, -00002.44 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008553 NoMotion-1. Scaled. Acc [ -00048.34, -00001.95, 00995.12 ], Gyr [ -00000.55, 00001.52, 00000.30 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008554 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00989.26 ], Gyr [ 00002.07, 00002.07, -00000.73 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008555 NoMotion-1. Scaled. Acc [ -00042.97, 00002.93, 00979.98 ], Gyr [ 00000.43, 00001.59, -00001.65 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008556 NoMotion-1. Scaled. Acc [ -00051.27, -00008.79, 00985.84 ], Gyr [ 00000.37, 00002.13, 00000.06 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008557 NoMotion-1. Scaled. Acc [ -00055.18, -00011.72, 00994.14 ], Gyr [ 00001.59, 00001.40, -00002.38 ], Mag [ 00020.40, 00082.20, 00107.40 ]",
    "#0000008558 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00987.79 ], Gyr [ -00000.61, 00001.28, -00001.65 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000008559 NoMotion-1. Scaled. Acc [ -00039.55, -00013.67, 00987.30 ], Gyr [ -00001.22, 00001.28, -00002.93 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000008560 NoMotion-1. Scaled. Acc [ -00053.22, 00002.44, 00987.30 ], Gyr [ -00000.49, 00001.46, -00002.50 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000008561 NoMotion-1. Scaled. Acc [ -00041.99, -00002.93, 00989.26 ], Gyr [ 00001.22, 00002.07, -00000.43 ], Mag [ 00019.50, 00082.80, 00108.90 ]",
    "#0000008562 NoMotion-1. Scaled. Acc [ -00049.80, -00008.79, 00990.23 ], Gyr [ 00000.06, 00001.65, -00004.57 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008563 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00981.93 ], Gyr [ 00000.91, -00000.79, -00003.11 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008564 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00978.52 ], Gyr [ 00001.65, -00001.52, -00000.49 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008565 NoMotion-1. Scaled. Acc [ -00033.69, -00011.23, 00994.63 ], Gyr [ 00001.52, 00000.98, -00002.32 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008566 NoMotion-1. Scaled. Acc [ -00045.90, -00001.95, 00993.16 ], Gyr [ -00000.73, 00001.83, -00002.50 ], Mag [ 00017.70, 00082.80, 00107.10 ]",
    "#0000008567 NoMotion-1. Scaled. Acc [ -00042.48, 00007.81, 00991.21 ], Gyr [ 00001.10, 00002.01, -00000.55 ], Mag [ 00017.70, 00082.80, 00107.10 ]",
    "#0000008568 NoMotion-1. Scaled. Acc [ -00041.99, -00018.07, 00977.54 ], Gyr [ 00002.38, 00001.22, -00001.77 ], Mag [ 00017.70, 00082.80, 00107.10 ]",
    "#0000008569 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00992.19 ], Gyr [ -00001.10, 00001.52, -00002.74 ], Mag [ 00019.35, 00081.90, 00106.65 ]",
    "#0000008570 NoMotion-1. Scaled. Acc [ -00037.60, -00007.32, 00988.28 ], Gyr [ 00001.04, 00004.02, -00002.07 ], Mag [ 00019.35, 00081.90, 00106.65 ]",
    "#0000008571 NoMotion-1. Scaled. Acc [ -00049.80, -00024.90, 00991.70 ], Gyr [ -00000.79, 00000.91, -00000.85 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000008572 NoMotion-1. Scaled. Acc [ -00046.39, -00024.41, 00995.61 ], Gyr [ 00000.67, 00002.87, -00001.46 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000008573 NoMotion-1. Scaled. Acc [ -00043.95, -00009.77, 00992.68 ], Gyr [ -00000.79, -00000.30, -00000.49 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008574 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00985.35 ], Gyr [ 00001.95, 00002.93, -00002.32 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008575 NoMotion-1. Scaled. Acc [ -00039.55, -00004.88, 00989.75 ], Gyr [ 00000.43, 00002.99, -00002.74 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008576 NoMotion-1. Scaled. Acc [ -00051.76, -00001.46, 00995.12 ], Gyr [ 00001.77, 00002.44, -00002.50 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008577 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00987.79 ], Gyr [ 00001.10, 00000.06, -00002.32 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008578 NoMotion-1. Scaled. Acc [ -00034.18, -00013.67, 00985.84 ], Gyr [ -00001.04, 00001.40, -00000.12 ], Mag [ 00019.95, 00082.80, 00107.85 ]",
    "#0000008579 NoMotion-1. Scaled. Acc [ -00046.88, -00014.65, 00988.28 ], Gyr [ -00000.73, 00002.80, -00000.98 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000008580 NoMotion-1. Scaled. Acc [ -00042.97, -00014.16, 00991.70 ], Gyr [ 00000.18, 00000.49, -00002.26 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008581 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00981.93 ], Gyr [ -00000.91, -00000.49, -00001.16 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008582 NoMotion-1. Scaled. Acc [ -00052.25, 00004.39, 00991.21 ], Gyr [ 00000.85, 00000.91, -00002.13 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008583 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00986.82 ], Gyr [ -00001.71, 00002.07, 00000.06 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008584 NoMotion-1. Scaled. Acc [ -00045.41, -00009.28, 00992.19 ], Gyr [ 00000.73, 00002.32, 00000.18 ], Mag [ 00017.55, 00083.70, 00106.65 ]",
    "#0000008585 NoMotion-1. Scaled. Acc [ -00032.71, -00013.67, 00985.84 ], Gyr [ 00001.04, 00004.09, -00000.91 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000008586 NoMotion-1. Scaled. Acc [ -00042.97, -00015.14, 00989.75 ], Gyr [ 00000.49, 00000.43, -00001.89 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000008587 NoMotion-1. Scaled. Acc [ -00046.88, -00001.95, 00995.61 ], Gyr [ 00002.01, 00001.46, -00003.35 ], Mag [ 00020.70, 00085.80, 00107.55 ]",
    "#0000008588 NoMotion-1. Scaled. Acc [ -00041.50, -00023.44, 00986.33 ], Gyr [ 00001.65, 00000.43, -00000.55 ], Mag [ 00020.70, 00085.80, 00107.55 ]",
    "#0000008589 NoMotion-1. Scaled. Acc [ -00042.48, -00011.72, 00987.79 ], Gyr [ 00002.38, -00000.85, -00002.13 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008590 NoMotion-1. Scaled. Acc [ -00045.90, -00020.51, 00995.61 ], Gyr [ -00000.12, 00000.43, -00003.66 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008591 NoMotion-1. Scaled. Acc [ -00052.25, -00008.79, 00993.65 ], Gyr [ -00001.28, 00000.24, -00002.44 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008592 NoMotion-1. Scaled. Acc [ -00035.16, -00005.86, 00991.21 ], Gyr [ 00000.91, -00001.04, -00000.67 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008593 NoMotion-1. Scaled. Acc [ -00038.09, -00017.09, 00986.82 ], Gyr [ 00000.18, 00003.05, -00002.87 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000008594 NoMotion-1. Scaled. Acc [ -00036.13, 00005.37, 00995.12 ], Gyr [ -00000.43, 00002.50, -00000.67 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000008595 NoMotion-1. Scaled. Acc [ -00044.92, -00002.44, 00977.54 ], Gyr [ 00001.04, 00000.37, -00002.99 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000008596 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00996.58 ], Gyr [ -00001.16, 00000.06, -00000.91 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000008597 NoMotion-1. Scaled. Acc [ -00055.18, -00014.16, 00991.21 ], Gyr [ 00001.40, -00000.24, -00001.10 ], Mag [ 00019.65, 00082.95, 00108.45 ]",
    "#0000008598 NoMotion-1. Scaled. Acc [ -00043.46, 00002.44, 00996.09 ], Gyr [ -00001.04, 00000.24, -00003.05 ], Mag [ 00019.65, 00082.95, 00108.45 ]",
    "#0000008599 NoMotion-1. Scaled. Acc [ -00047.36, -00018.55, 00983.40 ], Gyr [ -00000.30, 00000.24, 00004.45 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008600 NoMotion-1. Scaled. Acc [ -00048.83, -00012.21, 00987.30 ], Gyr [ -00000.43, -00001.52, -00002.74 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008601 NoMotion-1. Scaled. Acc [ -00046.88, -00011.23, 00987.79 ], Gyr [ -00001.10, 00003.35, -00001.89 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008602 NoMotion-1. Scaled. Acc [ -00035.16, -00017.09, 00985.35 ], Gyr [ 00000.37, 00002.01, -00001.89 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008603 NoMotion-1. Scaled. Acc [ -00033.20, -00006.35, 00989.26 ], Gyr [ 00002.80, 00000.30, -00002.13 ], Mag [ 00020.25, 00082.05, 00109.35 ]",
    "#0000008604 NoMotion-1. Scaled. Acc [ -00044.92, -00008.79, 00982.42 ], Gyr [ 00001.65, -00001.10, -00000.55 ], Mag [ 00020.70, 00084.75, 00108.60 ]",
    "#0000008605 NoMotion-1. Scaled. Acc [ -00046.39, -00005.37, 00988.28 ], Gyr [ -00002.13, 00001.28, -00003.41 ], Mag [ 00020.70, 00084.75, 00108.60 ]",
    "#0000008606 NoMotion-1. Scaled. Acc [ -00040.04, -00019.04, 00989.75 ], Gyr [ -00000.49, 00002.50, -00001.22 ], Mag [ 00019.65, 00084.15, 00108.75 ]",
    "#0000008607 NoMotion-1. Scaled. Acc [ -00037.11, -00013.18, 00998.05 ], Gyr [ 00000.43, 00001.95, 00000.67 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008608 NoMotion-1. Scaled. Acc [ -00055.18, -00011.23, 00988.77 ], Gyr [ 00000.85, 00000.30, -00000.61 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008609 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 00981.45 ], Gyr [ 00001.83, 00002.93, -00000.67 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008610 NoMotion-1. Scaled. Acc [ -00052.73, -00013.18, 00983.89 ], Gyr [ 00001.34, 00001.46, -00002.26 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008611 NoMotion-1. Scaled. Acc [ -00043.95, -00006.35, 00985.84 ], Gyr [ 00000.79, -00001.04, -00001.52 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008612 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00988.77 ], Gyr [ 00003.05, -00000.55, -00001.28 ], Mag [ 00019.05, 00082.65, 00108.00 ]",
    "#0000008613 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 00992.19 ], Gyr [ 00000.18, 00001.28, -00001.77 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008614 NoMotion-1. Scaled. Acc [ -00048.34, -00008.79, 00990.23 ], Gyr [ 00002.13, 00000.24, -00000.85 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008615 NoMotion-1. Scaled. Acc [ -00041.50, -00006.84, 00987.30 ], Gyr [ 00000.55, 00000.43, 00000.49 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000008616 NoMotion-1. Scaled. Acc [ -00053.22, -00019.04, 00986.82 ], Gyr [ 00000.91, 00000.67, -00000.49 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000008617 NoMotion-1. Scaled. Acc [ -00049.80, 00004.39, 00985.35 ], Gyr [ -00000.06, 00001.10, -00001.52 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008618 NoMotion-1. Scaled. Acc [ -00032.23, -00001.46, 00979.98 ], Gyr [ -00000.85, 00001.22, -00001.65 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008619 NoMotion-1. Scaled. Acc [ -00045.41, -00008.30, 00987.30 ], Gyr [ -00000.61, 00001.04, -00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008620 NoMotion-1. Scaled. Acc [ -00038.57, -00009.77, 00993.16 ], Gyr [ 00000.67, 00001.77, -00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008621 NoMotion-1. Scaled. Acc [ -00055.18, -00012.21, 00977.54 ], Gyr [ 00002.07, 00003.48, 00000.43 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000008622 NoMotion-1. Scaled. Acc [ -00036.62, -00010.74, 00995.12 ], Gyr [ 00000.12, 00001.40, -00003.35 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000008623 NoMotion-1. Scaled. Acc [ -00033.20, -00004.39, 00977.05 ], Gyr [ -00001.89, 00002.62, -00002.07 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008624 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00984.86 ], Gyr [ 00001.10, -00000.12, -00001.28 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000008625 NoMotion-1. Scaled. Acc [ -00041.50, -00007.81, 00992.19 ], Gyr [ 00001.83, 00001.16, -00000.61 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000008626 NoMotion-1. Scaled. Acc [ -00049.80, -00005.86, 00987.79 ], Gyr [ 00001.10, 00000.18, -00000.24 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008627 NoMotion-1. Scaled. Acc [ -00036.62, -00001.95, 00993.65 ], Gyr [ -00001.16, 00002.56, 00000.30 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008628 NoMotion-1. Scaled. Acc [ -00041.99, -00014.65, 00992.68 ], Gyr [ 00001.22, 00000.18, 00000.73 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008629 NoMotion-1. Scaled. Acc [ -00060.55, -00017.09, 00987.30 ], Gyr [ 00001.04, -00000.24, -00001.89 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008630 NoMotion-1. Scaled. Acc [ -00040.53, -00017.58, 00998.05 ], Gyr [ 00000.85, 00002.87, -00001.65 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000008631 NoMotion-1. Scaled. Acc [ -00050.78, -00002.44, 00985.35 ], Gyr [ -00002.01, 00003.66, -00000.24 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000008632 NoMotion-1. Scaled. Acc [ -00041.99, -00007.32, 00993.65 ], Gyr [ -00001.59, 00001.95, -00000.91 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000008633 NoMotion-1. Scaled. Acc [ -00048.34, -00023.44, 00975.59 ], Gyr [ -00001.04, 00002.20, -00001.71 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000008634 NoMotion-1. Scaled. Acc [ -00046.88, -00005.37, 00994.14 ], Gyr [ 00000.73, 00002.07, -00001.77 ], Mag [ 00018.15, 00083.55, 00107.10 ]",
    "#0000008635 NoMotion-1. Scaled. Acc [ -00047.36, -00013.18, 00989.26 ], Gyr [ 00000.00, 00000.61, -00001.40 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008636 NoMotion-1. Scaled. Acc [ -00048.34, -00003.91, 01000.00 ], Gyr [ 00001.46, -00001.10, -00000.85 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008637 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00990.72 ], Gyr [ -00000.43, -00001.65, -00000.85 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008638 NoMotion-1. Scaled. Acc [ -00052.25, -00019.04, 00987.79 ], Gyr [ -00001.40, 00003.72, -00001.89 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008639 NoMotion-1. Scaled. Acc [ -00043.95, -00005.86, 00981.45 ], Gyr [ -00000.37, -00001.89, -00001.71 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008640 NoMotion-1. Scaled. Acc [ -00055.66, 00005.37, 00994.14 ], Gyr [ 00003.60, 00000.00, 00001.40 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000008641 NoMotion-1. Scaled. Acc [ -00038.09, -00014.16, 00994.14 ], Gyr [ -00000.61, 00000.30, -00004.88 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008642 NoMotion-1. Scaled. Acc [ -00040.53, -00015.62, 00983.89 ], Gyr [ -00000.30, 00001.46, -00000.85 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008643 NoMotion-1. Scaled. Acc [ -00053.71, -00011.72, 00991.70 ], Gyr [ 00001.40, 00003.17, -00000.24 ], Mag [ 00019.65, 00082.95, 00107.40 ]",
    "#0000008644 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00990.72 ], Gyr [ -00000.18, 00001.28, 00000.91 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008645 NoMotion-1. Scaled. Acc [ -00033.69, 00008.30, 00990.23 ], Gyr [ -00000.91, 00000.91, -00003.41 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008646 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 01000.49 ], Gyr [ -00000.49, 00002.68, -00001.34 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008647 NoMotion-1. Scaled. Acc [ -00035.16, -00012.70, 00986.33 ], Gyr [ -00000.67, 00003.48, -00002.44 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008648 NoMotion-1. Scaled. Acc [ -00044.43, -00008.30, 00990.23 ], Gyr [ 00000.49, 00002.50, -00001.77 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000008649 NoMotion-1. Scaled. Acc [ -00041.99, -00007.81, 00987.30 ], Gyr [ 00002.56, 00001.34, 00000.61 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000008650 NoMotion-1. Scaled. Acc [ -00036.13, -00001.46, 00992.68 ], Gyr [ -00000.55, 00001.04, -00003.78 ], Mag [ 00018.75, 00082.50, 00107.55 ]",
    "#0000008651 NoMotion-1. Scaled. Acc [ -00041.50, 00002.44, 00992.68 ], Gyr [ 00001.46, 00002.56, 00001.52 ], Mag [ 00018.75, 00082.50, 00107.55 ]",
    "#0000008652 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00988.28 ], Gyr [ -00000.55, 00000.73, 00000.18 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008653 NoMotion-1. Scaled. Acc [ -00051.27, -00001.46, 00983.89 ], Gyr [ 00000.79, 00003.84, -00001.71 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008654 NoMotion-1. Scaled. Acc [ -00056.64, -00000.98, 00988.77 ], Gyr [ 00001.34, 00000.73, -00002.68 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008655 NoMotion-1. Scaled. Acc [ -00060.55, -00008.79, 00986.82 ], Gyr [ -00000.73, 00002.56, -00003.41 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008656 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00994.63 ], Gyr [ 00002.62, -00000.30, -00002.32 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008657 NoMotion-1. Scaled. Acc [ -00043.95, -00015.62, 00998.05 ], Gyr [ -00001.10, 00000.24, -00002.50 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008658 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00981.93 ], Gyr [ 00000.06, 00002.62, 00000.24 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008659 NoMotion-1. Scaled. Acc [ -00043.46, -00005.37, 00994.14 ], Gyr [ 00000.00, 00003.96, -00001.77 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008660 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00990.72 ], Gyr [ -00000.43, 00002.44, -00003.84 ], Mag [ 00020.10, 00082.65, 00106.95 ]",
    "#0000008661 NoMotion-1. Scaled. Acc [ -00052.73, -00010.25, 00987.79 ], Gyr [ 00002.38, 00002.13, 00000.73 ], Mag [ 00020.10, 00082.65, 00106.95 ]",
    "#0000008662 NoMotion-1. Scaled. Acc [ -00043.95, 00001.95, 00997.07 ], Gyr [ 00000.12, 00001.71, 00001.16 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000008663 NoMotion-1. Scaled. Acc [ -00051.27, -00008.79, 00991.21 ], Gyr [ 00001.65, 00002.99, -00000.55 ], Mag [ 00019.20, 00083.55, 00108.30 ]",
    "#0000006374 NoMotion-1. Scaled. Acc [ -00050.29, -00005.37, 00999.02 ], Gyr [ 00000.12, -00002.44, -00002.50 ], Mag [ 00019.65, 00083.40, 00108.45 ]",
    "#0000006375 NoMotion-1. Scaled. Acc [ -00033.20, -00005.86, 00982.42 ], Gyr [ 00002.68, 00000.79, -00001.28 ], Mag [ 00019.65, 00083.40, 00108.45 ]",
    "#0000006376 NoMotion-1. Scaled. Acc [ -00037.60, 00000.49, 00976.07 ], Gyr [ -00000.24, 00000.73, -00000.18 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006377 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00997.07 ], Gyr [ -00000.43, 00001.40, -00002.32 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006378 NoMotion-1. Scaled. Acc [ -00047.36, -00003.91, 00987.79 ], Gyr [ 00000.49, 00001.59, 00000.12 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000006379 NoMotion-1. Scaled. Acc [ -00034.18, -00001.46, 00984.38 ], Gyr [ 00001.65, 00002.32, 00000.67 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000006380 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00988.77 ], Gyr [ -00000.06, 00003.78, -00002.07 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006381 NoMotion-1. Scaled. Acc [ -00039.06, 00004.39, 01001.95 ], Gyr [ 00000.49, 00001.16, -00002.13 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006382 NoMotion-1. Scaled. Acc [ -00052.73, -00009.28, 00992.68 ], Gyr [ 00000.37, 00000.98, -00001.10 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006383 NoMotion-1. Scaled. Acc [ -00039.55, -00012.70, 00983.40 ], Gyr [ 00001.28, 00000.37, -00002.74 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006384 NoMotion-1. Scaled. Acc [ -00050.78, -00008.30, 00990.72 ], Gyr [ 00000.43, 00001.34, -00002.38 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006385 NoMotion-1. Scaled. Acc [ -00052.25, 00008.30, 01009.28 ], Gyr [ 00001.16, -00001.71, -00002.01 ], Mag [ 00019.95, 00084.30, 00108.90 ]",
    "#0000006386 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00998.54 ], Gyr [ 00000.30, -00000.30, -00000.37 ], Mag [ 00019.95, 00084.30, 00108.90 ]",
    "#0000006387 NoMotion-1. Scaled. Acc [ -00041.50, -00017.09, 00988.28 ], Gyr [ 00001.46, 00000.91, -00000.91 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000006388 NoMotion-1. Scaled. Acc [ -00038.09, -00003.42, 00997.07 ], Gyr [ -00000.79, 00001.71, 00000.61 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000006389 NoMotion-1. Scaled. Acc [ -00030.76, -00013.67, 00990.23 ], Gyr [ 00000.61, 00000.67, -00000.73 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006390 NoMotion-1. Scaled. Acc [ -00038.57, -00003.42, 00988.77 ], Gyr [ -00000.18, 00002.50, 00000.24 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006391 NoMotion-1. Scaled. Acc [ -00037.11, -00020.02, 00992.19 ], Gyr [ 00000.12, 00001.04, -00000.91 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006392 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 00979.00 ], Gyr [ -00000.37, 00004.02, -00000.37 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006393 NoMotion-1. Scaled. Acc [ -00036.62, -00012.21, 00997.56 ], Gyr [ -00001.95, 00001.77, -00000.91 ], Mag [ 00020.40, 00084.90, 00106.20 ]",
    "#0000006394 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00996.58 ], Gyr [ 00001.04, -00000.43, -00001.89 ], Mag [ 00020.40, 00084.90, 00106.20 ]",
    "#0000006395 NoMotion-1. Scaled. Acc [ -00037.60, -00019.04, 00991.21 ], Gyr [ -00000.55, 00001.52, -00003.84 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000006396 NoMotion-1. Scaled. Acc [ -00036.62, -00011.72, 00996.58 ], Gyr [ 00000.24, 00002.93, -00002.01 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000006397 NoMotion-1. Scaled. Acc [ -00039.06, 00000.00, 00993.65 ], Gyr [ 00001.95, 00000.55, -00002.44 ], Mag [ 00020.40, 00084.90, 00108.75 ]",
    "#0000006398 NoMotion-1. Scaled. Acc [ -00038.09, -00011.72, 00984.86 ], Gyr [ 00001.52, 00000.00, -00001.65 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006399 NoMotion-1. Scaled. Acc [ -00038.09, -00016.60, 00984.38 ], Gyr [ 00000.73, 00002.68, -00003.17 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006400 NoMotion-1. Scaled. Acc [ -00041.50, -00021.97, 00990.23 ], Gyr [ -00001.28, 00000.67, 00000.37 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006401 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00981.93 ], Gyr [ 00000.18, 00004.39, -00000.98 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006402 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00995.61 ], Gyr [ 00001.04, 00001.59, -00001.89 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006403 NoMotion-1. Scaled. Acc [ -00039.55, 00000.49, 00991.21 ], Gyr [ 00001.04, -00000.85, 00000.79 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006404 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00989.26 ], Gyr [ -00000.67, 00001.16, -00002.07 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006405 NoMotion-1. Scaled. Acc [ -00036.13, 00000.98, 00981.45 ], Gyr [ 00000.18, 00000.67, -00001.71 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006406 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00989.26 ], Gyr [ -00001.34, 00003.11, -00003.11 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006407 NoMotion-1. Scaled. Acc [ -00036.62, -00003.91, 00984.38 ], Gyr [ 00002.68, -00000.24, -00001.34 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006408 NoMotion-1. Scaled. Acc [ -00026.37, -00011.72, 00993.16 ], Gyr [ 00001.40, 00002.50, -00003.60 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006409 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00986.82 ], Gyr [ 00000.49, 00000.98, -00000.73 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006410 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 01000.49 ], Gyr [ 00001.52, 00000.43, 00000.18 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006411 NoMotion-1. Scaled. Acc [ -00037.11, -00002.93, 00998.05 ], Gyr [ 00000.85, -00000.24, -00001.34 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006412 NoMotion-1. Scaled. Acc [ -00042.48, -00006.84, 00989.26 ], Gyr [ 00003.35, 00002.26, -00004.39 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006413 NoMotion-1. Scaled. Acc [ -00038.09, -00009.28, 00982.42 ], Gyr [ 00001.77, 00000.30, -00001.22 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006414 NoMotion-1. Scaled. Acc [ -00030.76, -00002.93, 00996.58 ], Gyr [ 00000.37, 00000.49, -00001.40 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006415 NoMotion-1. Scaled. Acc [ -00038.09, -00024.90, 00986.82 ], Gyr [ 00002.01, 00000.00, -00000.79 ], Mag [ 00018.60, 00085.20, 00109.05 ]",
    "#0000006416 NoMotion-1. Scaled. Acc [ -00038.57, -00012.21, 01000.00 ], Gyr [ 00001.10, 00000.85, -00002.01 ], Mag [ 00018.60, 00085.20, 00109.05 ]",
    "#0000006417 NoMotion-1. Scaled. Acc [ -00036.13, -00014.16, 00985.35 ], Gyr [ 00001.71, 00003.78, -00002.13 ], Mag [ 00018.60, 00083.40, 00108.45 ]",
    "#0000006418 NoMotion-1. Scaled. Acc [ -00026.37, 00000.00, 00979.49 ], Gyr [ 00001.28, -00001.40, -00000.85 ], Mag [ 00018.60, 00083.40, 00108.45 ]",
    "#0000006419 NoMotion-1. Scaled. Acc [ -00039.06, -00011.23, 00992.68 ], Gyr [ 00001.10, 00000.49, -00002.07 ], Mag [ 00019.05, 00082.95, 00108.45 ]",
    "#0000006420 NoMotion-1. Scaled. Acc [ -00039.55, -00004.39, 00994.14 ], Gyr [ 00000.30, 00000.79, -00002.74 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006421 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00997.07 ], Gyr [ -00001.28, 00003.48, -00002.50 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006422 NoMotion-1. Scaled. Acc [ -00034.18, -00019.53, 00996.58 ], Gyr [ 00002.26, -00000.37, -00001.59 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006423 NoMotion-1. Scaled. Acc [ -00027.83, 00000.49, 00979.98 ], Gyr [ 00002.26, -00000.55, -00000.67 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000006424 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00991.21 ], Gyr [ 00002.50, 00001.52, -00000.30 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000006425 NoMotion-1. Scaled. Acc [ -00055.18, -00014.16, 01007.32 ], Gyr [ 00001.71, 00001.04, -00001.16 ], Mag [ 00019.95, 00084.75, 00106.50 ]",
    "#0000006426 NoMotion-1. Scaled. Acc [ -00050.29, -00010.25, 00988.28 ], Gyr [ 00001.71, 00000.18, -00000.43 ], Mag [ 00019.95, 00084.75, 00106.50 ]",
    "#0000006427 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00988.28 ], Gyr [ 00002.07, 00000.67, 00000.18 ], Mag [ 00020.70, 00082.05, 00109.65 ]",
    "#0000006428 NoMotion-1. Scaled. Acc [ -00044.43, -00010.74, 00990.72 ], Gyr [ -00000.85, 00000.43, -00000.91 ], Mag [ 00020.70, 00082.05, 00109.65 ]",
    "#0000006429 NoMotion-1. Scaled. Acc [ -00045.41, -00011.72, 00992.68 ], Gyr [ 00001.28, 00003.72, -00003.48 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006430 NoMotion-1. Scaled. Acc [ -00046.88, -00010.25, 00992.19 ], Gyr [ -00000.91, 00002.74, -00001.83 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006431 NoMotion-1. Scaled. Acc [ -00049.32, -00001.46, 00992.68 ], Gyr [ 00001.52, 00002.80, -00001.46 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006432 NoMotion-1. Scaled. Acc [ -00053.71, -00005.86, 00984.86 ], Gyr [ 00001.83, 00002.38, -00002.74 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006433 NoMotion-1. Scaled. Acc [ -00045.41, -00013.18, 00989.75 ], Gyr [ 00001.89, 00002.56, -00003.11 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006434 NoMotion-1. Scaled. Acc [ -00045.41, -00005.86, 00995.61 ], Gyr [ 00000.30, -00002.68, 00000.67 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006435 NoMotion-1. Scaled. Acc [ -00047.85, -00003.91, 00995.12 ], Gyr [ 00002.20, -00000.37, -00001.34 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000006436 NoMotion-1. Scaled. Acc [ -00048.83, -00014.16, 00979.49 ], Gyr [ 00000.98, 00001.10, -00002.26 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000006437 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 01006.84 ], Gyr [ 00001.83, 00001.59, -00002.93 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000006438 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00979.49 ], Gyr [ 00001.77, 00000.91, 00001.65 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000006439 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00988.77 ], Gyr [ 00000.18, 00000.79, 00001.28 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006440 NoMotion-1. Scaled. Acc [ -00048.83, -00009.28, 00987.30 ], Gyr [ 00001.46, -00000.55, 00000.24 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006441 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00994.63 ], Gyr [ -00000.67, 00001.10, -00002.62 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006442 NoMotion-1. Scaled. Acc [ -00038.57, -00012.21, 00998.05 ], Gyr [ -00001.28, 00001.04, -00002.74 ], Mag [ 00020.10, 00084.90, 00108.00 ]",
    "#0000006443 NoMotion-1. Scaled. Acc [ -00029.79, -00009.28, 00986.82 ], Gyr [ -00000.30, 00003.23, -00002.68 ], Mag [ 00020.10, 00084.90, 00108.00 ]",
    "#0000006444 NoMotion-1. Scaled. Acc [ -00043.46, -00010.25, 00986.82 ], Gyr [ 00001.83, -00002.07, -00001.95 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006445 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00992.68 ], Gyr [ 00000.61, 00001.16, -00001.59 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006446 NoMotion-1. Scaled. Acc [ -00048.83, -00002.93, 01004.39 ], Gyr [ 00002.62, -00000.85, 00001.34 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006447 NoMotion-1. Scaled. Acc [ -00044.92, -00011.72, 01003.42 ], Gyr [ 00000.91, 00000.00, -00002.93 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006448 NoMotion-1. Scaled. Acc [ -00046.39, -00010.74, 00989.26 ], Gyr [ 00002.01, 00001.40, -00002.13 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000006449 NoMotion-1. Scaled. Acc [ -00041.99, 00001.46, 00988.77 ], Gyr [ 00000.91, -00000.37, -00001.83 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000006450 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00995.12 ], Gyr [ 00002.07, 00001.28, -00002.38 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000006451 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00989.26 ], Gyr [ 00000.55, 00002.62, -00001.71 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000006452 NoMotion-1. Scaled. Acc [ -00043.95, 00001.46, 00983.40 ], Gyr [ -00000.91, 00001.83, -00002.68 ], Mag [ 00020.85, 00084.45, 00108.75 ]",
    "#0000006453 NoMotion-1. Scaled. Acc [ -00043.46, -00004.39, 01000.98 ], Gyr [ 00002.50, 00001.46, -00002.44 ], Mag [ 00020.85, 00084.45, 00108.75 ]",
    "#0000006454 NoMotion-1. Scaled. Acc [ -00042.48, 00000.98, 00997.56 ], Gyr [ 00000.24, 00002.13, -00004.09 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006455 NoMotion-1. Scaled. Acc [ -00051.76, -00007.81, 00979.00 ], Gyr [ 00000.12, 00003.90, -00004.57 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006456 NoMotion-1. Scaled. Acc [ -00048.83, -00020.51, 00999.51 ], Gyr [ 00001.95, 00002.13, -00002.20 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006457 NoMotion-1. Scaled. Acc [ -00039.06, -00007.81, 00987.30 ], Gyr [ 00002.44, -00001.46, -00002.74 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006458 NoMotion-1. Scaled. Acc [ -00034.18, -00015.62, 00999.02 ], Gyr [ 00000.24, 00001.04, -00003.23 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006459 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00983.89 ], Gyr [ -00001.16, 00003.84, 00000.49 ], Mag [ 00018.75, 00083.55, 00107.10 ]",
    "#0000006460 NoMotion-1. Scaled. Acc [ -00055.66, -00011.23, 00994.63 ], Gyr [ 00002.01, 00001.83, -00000.91 ], Mag [ 00018.75, 00083.55, 00107.10 ]",
    "#0000006461 NoMotion-1. Scaled. Acc [ -00047.36, -00001.95, 00996.09 ], Gyr [ 00002.26, -00001.65, -00000.18 ], Mag [ 00019.05, 00084.15, 00105.90 ]",
    "#0000006462 NoMotion-1. Scaled. Acc [ -00054.69, -00005.86, 00998.54 ], Gyr [ 00002.26, 00001.77, 00000.24 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006463 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00992.19 ], Gyr [ 00001.22, 00002.13, -00001.04 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006464 NoMotion-1. Scaled. Acc [ -00041.50, -00013.18, 00988.77 ], Gyr [ 00000.06, 00002.74, -00003.72 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006465 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 01007.32 ], Gyr [ -00000.73, -00000.37, -00002.01 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006466 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00989.75 ], Gyr [ 00000.61, 00003.23, -00002.80 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006467 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00989.26 ], Gyr [ -00000.18, 00001.34, -00000.85 ], Mag [ 00021.15, 00084.45, 00106.20 ]",
    "#0000006468 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00999.51 ], Gyr [ 00001.46, 00002.38, -00000.49 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006469 NoMotion-1. Scaled. Acc [ -00033.69, -00006.35, 00988.77 ], Gyr [ 00002.01, 00000.49, -00001.16 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006470 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 01000.00 ], Gyr [ 00000.73, 00002.38, -00002.44 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006471 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 00994.63 ], Gyr [ 00000.79, 00000.85, -00003.72 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006472 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00988.77 ], Gyr [ 00000.73, 00000.49, 00000.61 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006473 NoMotion-1. Scaled. Acc [ -00039.06, -00007.81, 00980.47 ], Gyr [ 00001.34, 00003.96, -00002.20 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006474 NoMotion-1. Scaled. Acc [ -00043.95, 00004.39, 00998.54 ], Gyr [ -00000.37, 00001.59, -00000.30 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006475 NoMotion-1. Scaled. Acc [ -00038.57, -00010.74, 00994.14 ], Gyr [ -00001.10, -00000.30, -00003.05 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000006476 NoMotion-1. Scaled. Acc [ -00057.13, -00013.18, 01004.88 ], Gyr [ -00000.30, -00000.12, -00002.62 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000006477 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00998.54 ], Gyr [ -00001.04, 00002.07, -00001.10 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000006478 NoMotion-1. Scaled. Acc [ -00053.22, -00009.28, 00992.19 ], Gyr [ -00001.71, 00000.67, -00003.90 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000006479 NoMotion-1. Scaled. Acc [ -00040.53, 00003.42, 00995.61 ], Gyr [ 00000.91, 00003.48, -00001.77 ], Mag [ 00018.45, 00084.75, 00107.85 ]",
    "#0000006480 NoMotion-1. Scaled. Acc [ -00035.64, -00003.42, 00998.05 ], Gyr [ -00001.59, 00001.59, -00002.07 ], Mag [ 00018.45, 00084.75, 00107.85 ]",
    "#0000006481 NoMotion-1. Scaled. Acc [ -00047.85, -00023.93, 00991.21 ], Gyr [ 00000.55, 00002.26, -00000.73 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006482 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00996.09 ], Gyr [ -00000.55, 00001.83, -00000.18 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006483 NoMotion-1. Scaled. Acc [ -00048.34, -00001.46, 00984.38 ], Gyr [ -00001.22, 00003.35, -00002.68 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006484 NoMotion-1. Scaled. Acc [ -00039.55, -00015.62, 00994.63 ], Gyr [ 00001.10, -00000.37, -00001.77 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006485 NoMotion-1. Scaled. Acc [ -00041.50, -00018.55, 00989.26 ], Gyr [ -00000.24, 00001.77, 00000.24 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000006486 NoMotion-1. Scaled. Acc [ -00059.57, -00010.25, 00998.54 ], Gyr [ 00000.79, 00000.06, -00001.16 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000006487 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00982.42 ], Gyr [ 00000.79, 00000.73, -00002.68 ], Mag [ 00019.35, 00084.45, 00108.75 ]",
    "#0000006488 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00995.12 ], Gyr [ 00001.89, -00000.49, -00002.26 ], Mag [ 00019.35, 00084.45, 00108.75 ]",
    "#0000006489 NoMotion-1. Scaled. Acc [ -00047.85, -00015.14, 00988.77 ], Gyr [ 00001.83, 00000.49, -00000.67 ], Mag [ 00020.40, 00083.70, 00106.20 ]",
    "#0000006490 NoMotion-1. Scaled. Acc [ -00047.85, 00000.49, 00994.63 ], Gyr [ 00000.37, 00002.38, -00002.87 ], Mag [ 00020.40, 00083.70, 00106.20 ]",
    "#0000006491 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00991.21 ], Gyr [ 00000.79, 00002.07, -00002.01 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006492 NoMotion-1. Scaled. Acc [ -00029.30, 00005.37, 00989.75 ], Gyr [ -00000.49, 00000.85, -00000.24 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006493 NoMotion-1. Scaled. Acc [ -00050.78, -00000.98, 00996.09 ], Gyr [ 00001.89, 00000.98, -00003.05 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006494 NoMotion-1. Scaled. Acc [ -00043.46, -00017.09, 01001.46 ], Gyr [ 00001.65, 00001.40, 00000.24 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006495 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00991.70 ], Gyr [ -00001.40, 00001.83, 00000.67 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000006496 NoMotion-1. Scaled. Acc [ -00047.36, -00007.32, 00979.98 ], Gyr [ 00002.80, 00004.15, 00000.43 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000006497 NoMotion-1. Scaled. Acc [ -00043.46, -00007.32, 00992.68 ], Gyr [ 00000.49, 00000.91, -00002.32 ], Mag [ 00021.60, 00084.45, 00108.00 ]",
    "#0000006498 NoMotion-1. Scaled. Acc [ -00048.34, 00000.49, 00986.33 ], Gyr [ 00001.95, 00001.71, -00001.65 ], Mag [ 00021.60, 00084.45, 00108.00 ]",
    "#0000006499 NoMotion-1. Scaled. Acc [ -00037.11, -00005.37, 00993.65 ], Gyr [ 00000.55, 00001.95, 00001.52 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006500 NoMotion-1. Scaled. Acc [ -00032.23, -00016.11, 00990.72 ], Gyr [ 00001.71, 00000.98, -00001.40 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006501 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00991.70 ], Gyr [ 00001.28, -00000.79, -00000.79 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006502 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00989.75 ], Gyr [ -00001.52, 00003.35, -00002.87 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006503 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00990.72 ], Gyr [ -00000.79, 00003.29, -00002.20 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000006504 NoMotion-1. Scaled. Acc [ -00047.36, -00010.25, 00993.65 ], Gyr [ -00000.12, 00001.04, -00002.99 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000006505 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00985.84 ], Gyr [ 00000.37, 00001.83, -00001.59 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000006506 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00996.58 ], Gyr [ 00001.59, 00002.93, -00002.50 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000006507 NoMotion-1. Scaled. Acc [ -00037.60, -00005.37, 00981.93 ], Gyr [ 00000.24, 00001.34, -00001.65 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000006508 NoMotion-1. Scaled. Acc [ -00049.32, -00002.93, 00996.09 ], Gyr [ -00000.73, 00000.73, -00003.11 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000006509 NoMotion-1. Scaled. Acc [ -00040.53, -00000.98, 00989.75 ], Gyr [ 00000.55, 00001.77, -00000.43 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006510 NoMotion-1. Scaled. Acc [ -00033.20, -00012.21, 01006.84 ], Gyr [ -00000.55, -00002.20, -00001.95 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006511 NoMotion-1. Scaled. Acc [ -00031.25, -00001.95, 00984.86 ], Gyr [ -00001.83, 00001.71, 00000.24 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006512 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00990.23 ], Gyr [ -00001.22, 00000.98, -00001.83 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006513 NoMotion-1. Scaled. Acc [ -00035.64, -00001.46, 00989.75 ], Gyr [ 00000.00, 00002.38, -00000.79 ], Mag [ 00019.35, 00084.15, 00106.65 ]",
    "#0000006514 NoMotion-1. Scaled. Acc [ -00054.20, -00008.30, 00991.70 ], Gyr [ 00002.80, 00003.66, -00000.85 ], Mag [ 00019.95, 00084.30, 00107.55 ]",
    "#0000006515 NoMotion-1. Scaled. Acc [ -00031.74, -00007.32, 00984.86 ], Gyr [ 00000.67, 00000.18, -00001.22 ], Mag [ 00019.95, 00084.30, 00107.55 ]",
    "#0000006516 NoMotion-1. Scaled. Acc [ -00045.41, 00002.93, 00986.82 ], Gyr [ 00001.22, 00000.91, -00001.10 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000006517 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00997.56 ], Gyr [ -00000.06, -00000.12, 00000.67 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006518 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00989.26 ], Gyr [ 00001.71, 00001.71, 00000.00 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006519 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00998.54 ], Gyr [ -00001.16, 00001.95, -00003.41 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006520 NoMotion-1. Scaled. Acc [ -00041.99, 00001.95, 00990.23 ], Gyr [ -00000.73, 00000.06, -00001.28 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006521 NoMotion-1. Scaled. Acc [ -00034.67, -00008.30, 00975.59 ], Gyr [ 00001.89, 00000.49, 00000.85 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006522 NoMotion-1. Scaled. Acc [ -00044.43, -00008.79, 00985.35 ], Gyr [ 00000.24, 00001.22, -00003.05 ], Mag [ 00019.50, 00082.05, 00107.10 ]",
    "#0000006523 NoMotion-1. Scaled. Acc [ -00044.92, -00007.32, 00994.63 ], Gyr [ 00000.00, 00003.29, -00004.27 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006524 NoMotion-1. Scaled. Acc [ -00031.74, -00006.84, 00993.65 ], Gyr [ 00002.26, 00001.59, -00000.18 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006525 NoMotion-1. Scaled. Acc [ -00043.46, -00016.11, 00982.91 ], Gyr [ 00000.98, -00000.43, -00002.20 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000006526 NoMotion-1. Scaled. Acc [ -00052.25, -00007.81, 00997.07 ], Gyr [ -00000.55, -00000.55, -00001.22 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000006527 NoMotion-1. Scaled. Acc [ -00056.64, 00000.49, 00995.61 ], Gyr [ 00000.67, 00002.01, 00000.79 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006528 NoMotion-1. Scaled. Acc [ -00043.46, -00016.11, 00993.65 ], Gyr [ 00000.18, 00002.80, -00002.32 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006529 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00994.63 ], Gyr [ -00000.55, 00000.67, -00000.49 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006530 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00987.30 ], Gyr [ 00000.67, 00000.43, -00000.98 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006531 NoMotion-1. Scaled. Acc [ -00030.76, -00003.42, 00993.65 ], Gyr [ -00000.06, 00002.26, -00000.85 ], Mag [ 00019.95, 00083.25, 00105.75 ]",
    "#0000006532 NoMotion-1. Scaled. Acc [ -00046.88, 00002.93, 00980.47 ], Gyr [ 00001.04, -00000.37, -00002.38 ], Mag [ 00019.95, 00083.25, 00105.75 ]",
    "#0000006533 NoMotion-1. Scaled. Acc [ -00048.83, -00006.84, 00988.77 ], Gyr [ 00001.10, 00001.65, -00001.28 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006534 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00991.70 ], Gyr [ -00000.49, 00000.73, -00002.13 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006535 NoMotion-1. Scaled. Acc [ -00065.92, 00005.37, 00985.84 ], Gyr [ 00001.83, 00001.65, -00000.06 ], Mag [ 00020.40, 00083.70, 00108.75 ]",
    "#0000006536 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 00995.12 ], Gyr [ 00002.80, 00000.79, -00000.61 ], Mag [ 00020.40, 00083.70, 00108.75 ]",
    "#0000006537 NoMotion-1. Scaled. Acc [ -00044.43, 00000.98, 00991.21 ], Gyr [ 00000.67, 00000.73, 00000.67 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006538 NoMotion-1. Scaled. Acc [ -00048.34, 00005.86, 00990.72 ], Gyr [ 00001.34, 00002.56, 00000.91 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006539 NoMotion-1. Scaled. Acc [ -00044.92, -00005.86, 01000.98 ], Gyr [ 00000.98, 00002.56, -00002.26 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006540 NoMotion-1. Scaled. Acc [ -00044.92, -00018.07, 00987.79 ], Gyr [ 00000.43, 00003.17, -00002.80 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000006541 NoMotion-1. Scaled. Acc [ -00044.92, -00005.37, 00990.23 ], Gyr [ 00001.40, -00001.04, -00001.34 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000006542 NoMotion-1. Scaled. Acc [ -00043.95, -00000.98, 00996.09 ], Gyr [ 00000.37, 00000.06, -00002.93 ], Mag [ 00019.05, 00083.40, 00106.65 ]",
    "#0000006543 NoMotion-1. Scaled. Acc [ -00048.34, -00002.44, 00992.68 ], Gyr [ 00001.16, -00000.18, -00000.12 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006544 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00984.86 ], Gyr [ 00001.46, 00000.98, -00000.67 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006545 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00997.07 ], Gyr [ 00000.30, 00001.04, -00002.07 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006546 NoMotion-1. Scaled. Acc [ -00052.25, -00020.02, 00988.77 ], Gyr [ 00000.37, 00003.29, -00002.38 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006547 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00989.26 ], Gyr [ 00000.67, 00003.05, -00001.89 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006548 NoMotion-1. Scaled. Acc [ -00052.25, 00008.30, 00984.86 ], Gyr [ 00001.10, 00002.01, -00001.71 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006549 NoMotion-1. Scaled. Acc [ -00042.97, -00000.98, 00988.28 ], Gyr [ 00001.40, 00003.60, -00002.62 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006550 NoMotion-1. Scaled. Acc [ -00057.62, -00003.91, 00987.79 ], Gyr [ 00001.59, -00000.55, -00002.68 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006551 NoMotion-1. Scaled. Acc [ -00042.48, -00015.14, 00985.35 ], Gyr [ 00000.49, 00000.73, -00003.48 ], Mag [ 00021.15, 00083.70, 00106.65 ]",
    "#0000006552 NoMotion-1. Scaled. Acc [ -00038.57, -00009.28, 00991.21 ], Gyr [ 00000.55, 00002.07, -00001.71 ], Mag [ 00019.35, 00084.45, 00107.40 ]",
    "#0000006553 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00983.89 ], Gyr [ 00000.49, 00002.44, 00000.30 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006554 NoMotion-1. Scaled. Acc [ -00061.52, -00019.04, 01000.00 ], Gyr [ -00000.91, 00001.65, -00004.45 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006555 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00987.79 ], Gyr [ 00000.37, 00003.84, 00001.59 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006556 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 01001.46 ], Gyr [ 00002.26, 00003.54, -00001.89 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006557 NoMotion-1. Scaled. Acc [ -00034.67, -00005.86, 00990.23 ], Gyr [ 00000.61, -00001.59, -00001.95 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000006558 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00990.23 ], Gyr [ 00000.55, 00001.28, -00002.74 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000006559 NoMotion-1. Scaled. Acc [ -00040.04, -00012.70, 00995.12 ], Gyr [ 00001.52, 00001.89, 00000.79 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006560 NoMotion-1. Scaled. Acc [ -00048.83, -00010.25, 00987.30 ], Gyr [ 00001.16, 00002.80, 00000.12 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006561 NoMotion-1. Scaled. Acc [ -00052.73, -00007.32, 00991.70 ], Gyr [ 00001.46, -00001.16, -00004.39 ], Mag [ 00019.50, 00084.75, 00108.90 ]",
    "#0000006562 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00993.65 ], Gyr [ -00000.49, 00000.55, 00000.00 ], Mag [ 00019.50, 00084.75, 00108.90 ]",
    "#0000006563 NoMotion-1. Scaled. Acc [ -00049.32, -00020.02, 00993.16 ], Gyr [ -00000.49, 00001.34, -00002.56 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006564 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00991.21 ], Gyr [ 00001.52, -00000.12, 00000.79 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006565 NoMotion-1. Scaled. Acc [ -00043.46, -00003.91, 00991.21 ], Gyr [ 00000.43, 00002.13, -00002.01 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006566 NoMotion-1. Scaled. Acc [ -00039.06, -00010.74, 00986.82 ], Gyr [ 00000.00, 00004.09, -00000.98 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000006567 NoMotion-1. Scaled. Acc [ -00051.27, -00002.44, 00992.19 ], Gyr [ 00001.28, -00000.37, -00001.46 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000006568 NoMotion-1. Scaled. Acc [ -00039.06, -00003.42, 00977.54 ], Gyr [ 00000.43, 00000.61, -00003.54 ], Mag [ 00018.60, 00082.65, 00109.50 ]",
    "#0000006569 NoMotion-1. Scaled. Acc [ -00032.23, -00009.77, 00982.91 ], Gyr [ -00000.18, 00000.73, -00002.68 ], Mag [ 00018.60, 00082.65, 00109.50 ]",
    "#0000006570 NoMotion-1. Scaled. Acc [ -00042.97, -00021.00, 01004.39 ], Gyr [ -00000.24, 00001.04, -00002.01 ], Mag [ 00019.05, 00084.90, 00108.00 ]",
    "#0000006571 NoMotion-1. Scaled. Acc [ -00042.97, -00003.42, 01001.95 ], Gyr [ 00000.37, -00000.61, -00000.49 ], Mag [ 00019.05, 00084.90, 00108.00 ]",
    "#0000006572 NoMotion-1. Scaled. Acc [ -00035.64, 00009.28, 00991.21 ], Gyr [ 00001.10, 00003.84, -00000.24 ], Mag [ 00020.85, 00081.90, 00107.40 ]",
    "#0000006573 NoMotion-1. Scaled. Acc [ -00043.46, -00017.58, 00993.16 ], Gyr [ 00000.49, 00002.07, -00000.98 ], Mag [ 00020.85, 00081.90, 00107.40 ]",
    "#0000006574 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00988.77 ], Gyr [ 00000.67, 00002.13, -00003.23 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000006575 NoMotion-1. Scaled. Acc [ -00039.06, -00000.98, 00980.47 ], Gyr [ 00000.06, 00003.05, -00002.74 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000006576 NoMotion-1. Scaled. Acc [ -00051.27, -00006.84, 00982.91 ], Gyr [ 00000.37, -00000.79, -00000.12 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000006577 NoMotion-1. Scaled. Acc [ -00039.55, 00003.42, 00992.68 ], Gyr [ 00000.67, 00004.70, -00001.40 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000006578 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00992.68 ], Gyr [ -00001.95, 00001.83, -00002.44 ], Mag [ 00019.05, 00084.90, 00106.95 ]",
    "#0000006579 NoMotion-1. Scaled. Acc [ -00039.06, -00009.77, 00998.54 ], Gyr [ 00000.37, 00003.05, -00002.87 ], Mag [ 00019.05, 00084.90, 00106.95 ]",
    "#0000006580 NoMotion-1. Scaled. Acc [ -00041.99, 00002.44, 00972.66 ], Gyr [ 00000.79, 00000.79, -00003.29 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006581 NoMotion-1. Scaled. Acc [ -00051.76, -00009.28, 00988.28 ], Gyr [ 00000.30, 00001.22, 00001.16 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006582 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00984.86 ], Gyr [ 00002.26, 00003.29, 00000.43 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006583 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00976.56 ], Gyr [ 00000.73, 00003.66, 00000.30 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006584 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00990.72 ], Gyr [ 00001.52, 00001.71, -00000.91 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006585 NoMotion-1. Scaled. Acc [ -00041.02, -00002.44, 00993.65 ], Gyr [ 00000.30, 00002.93, -00001.10 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006586 NoMotion-1. Scaled. Acc [ -00030.27, 00005.37, 00993.16 ], Gyr [ 00001.10, 00002.50, -00002.44 ], Mag [ 00019.65, 00083.40, 00106.20 ]",
    "#0000006587 NoMotion-1. Scaled. Acc [ -00050.29, 00001.95, 00985.84 ], Gyr [ 00001.10, 00001.89, -00003.66 ], Mag [ 00019.65, 00083.40, 00106.20 ]",
    "#0000006588 NoMotion-1. Scaled. Acc [ -00042.48, 00011.23, 00996.09 ], Gyr [ -00000.43, 00001.52, -00003.23 ], Mag [ 00019.65, 00084.15, 00108.00 ]",
    "#0000006589 NoMotion-1. Scaled. Acc [ -00034.18, -00002.93, 00982.91 ], Gyr [ 00000.00, 00000.98, -00000.79 ], Mag [ 00019.65, 00084.15, 00108.00 ]",
    "#0000006590 NoMotion-1. Scaled. Acc [ -00054.20, -00007.81, 00991.70 ], Gyr [ 00000.91, 00004.76, -00001.16 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006591 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00991.21 ], Gyr [ 00000.12, 00002.32, -00001.65 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006592 NoMotion-1. Scaled. Acc [ -00043.95, -00008.79, 00990.72 ], Gyr [ -00000.30, 00001.65, -00003.66 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006593 NoMotion-1. Scaled. Acc [ -00035.16, 00002.44, 00983.89 ], Gyr [ 00000.85, 00002.99, -00001.28 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000006594 NoMotion-1. Scaled. Acc [ -00047.36, -00007.81, 00993.16 ], Gyr [ -00000.06, -00000.73, -00000.18 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000006595 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00996.09 ], Gyr [ 00001.83, 00001.22, -00002.13 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006596 NoMotion-1. Scaled. Acc [ -00044.43, -00011.72, 00997.56 ], Gyr [ -00000.55, 00003.90, -00002.26 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006597 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00983.89 ], Gyr [ 00000.67, 00000.06, 00001.10 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006598 NoMotion-1. Scaled. Acc [ -00036.13, -00013.67, 00989.75 ], Gyr [ -00000.61, 00001.89, 00000.12 ], Mag [ 00019.35, 00083.70, 00106.95 ]",
    "#0000006599 NoMotion-1. Scaled. Acc [ -00035.64, -00015.14, 00994.14 ], Gyr [ 00001.40, 00001.22, -00002.68 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006600 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00990.72 ], Gyr [ -00001.22, 00002.68, -00000.06 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006601 NoMotion-1. Scaled. Acc [ -00055.66, -00005.37, 00982.42 ], Gyr [ 00003.05, 00000.00, -00002.44 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006602 NoMotion-1. Scaled. Acc [ -00042.97, -00002.44, 00993.65 ], Gyr [ 00001.52, 00002.13, -00000.91 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006603 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 00994.63 ], Gyr [ 00000.06, 00002.80, -00000.43 ], Mag [ 00019.20, 00085.05, 00107.55 ]",
    "#0000006604 NoMotion-1. Scaled. Acc [ -00042.48, -00003.42, 00986.82 ], Gyr [ -00002.68, 00003.72, -00002.38 ], Mag [ 00018.60, 00083.40, 00108.75 ]",
    "#0000006605 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00992.68 ], Gyr [ 00000.67, 00001.89, -00003.84 ], Mag [ 00018.60, 00083.40, 00108.75 ]",
    "#0000006606 NoMotion-1. Scaled. Acc [ -00052.73, -00014.65, 00984.38 ], Gyr [ -00000.55, 00001.52, -00000.30 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000006607 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00994.63 ], Gyr [ 00002.50, 00001.52, 00000.73 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000006608 NoMotion-1. Scaled. Acc [ -00035.64, -00004.88, 00996.09 ], Gyr [ 00000.85, 00003.05, -00001.52 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006609 NoMotion-1. Scaled. Acc [ -00035.16, -00003.91, 01008.30 ], Gyr [ 00001.40, -00001.52, -00002.50 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006610 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00990.72 ], Gyr [ 00001.83, 00001.46, -00000.24 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006611 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 00993.65 ], Gyr [ 00000.85, -00000.67, 00000.85 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006612 NoMotion-1. Scaled. Acc [ -00059.08, -00002.44, 00986.82 ], Gyr [ -00000.55, 00002.26, -00005.85 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006613 NoMotion-1. Scaled. Acc [ -00034.18, -00002.44, 00994.63 ], Gyr [ -00000.61, 00003.17, 00000.49 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006614 NoMotion-1. Scaled. Acc [ -00045.41, -00005.37, 00986.33 ], Gyr [ -00000.49, -00001.89, 00000.06 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006615 NoMotion-1. Scaled. Acc [ -00046.39, -00002.93, 00989.75 ], Gyr [ -00000.85, 00001.95, -00003.17 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006616 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00986.33 ], Gyr [ -00000.24, 00002.50, -00000.79 ], Mag [ 00017.55, 00081.45, 00107.70 ]",
    "#0000006617 NoMotion-1. Scaled. Acc [ -00043.46, -00017.58, 00995.12 ], Gyr [ -00000.73, 00001.04, -00004.21 ], Mag [ 00017.55, 00081.45, 00107.70 ]",
    "#0000006618 NoMotion-1. Scaled. Acc [ -00036.62, -00005.37, 00991.21 ], Gyr [ 00000.98, 00003.66, -00000.37 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006619 NoMotion-1. Scaled. Acc [ -00040.53, -00011.72, 00992.68 ], Gyr [ -00000.06, -00001.16, -00000.73 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006620 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00994.14 ], Gyr [ 00000.06, -00000.49, -00000.91 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006621 NoMotion-1. Scaled. Acc [ -00036.62, -00014.65, 00995.61 ], Gyr [ 00002.44, 00003.23, -00000.55 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006622 NoMotion-1. Scaled. Acc [ -00044.43, -00001.95, 00997.56 ], Gyr [ 00000.67, -00000.18, -00001.71 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006623 NoMotion-1. Scaled. Acc [ -00046.39, 00001.95, 00984.86 ], Gyr [ 00001.40, 00001.59, -00001.77 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000006624 NoMotion-1. Scaled. Acc [ -00036.62, -00015.62, 00976.56 ], Gyr [ 00000.67, 00001.22, -00001.04 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006625 NoMotion-1. Scaled. Acc [ -00044.92, -00017.58, 01005.86 ], Gyr [ 00001.10, 00001.46, 00000.43 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006626 NoMotion-1. Scaled. Acc [ -00046.88, -00016.60, 00991.70 ], Gyr [ 00002.01, 00000.43, -00003.78 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006627 NoMotion-1. Scaled. Acc [ -00040.04, -00011.23, 00995.12 ], Gyr [ -00002.07, 00001.10, -00002.74 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006628 NoMotion-1. Scaled. Acc [ -00033.20, -00003.91, 00998.54 ], Gyr [ 00000.55, 00000.49, 00001.34 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006629 NoMotion-1. Scaled. Acc [ -00041.02, -00001.95, 00996.09 ], Gyr [ 00000.37, 00000.67, -00002.62 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006630 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00993.16 ], Gyr [ 00000.06, 00003.11, -00003.78 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000006631 NoMotion-1. Scaled. Acc [ -00037.60, -00002.44, 00990.23 ], Gyr [ -00000.91, 00003.66, -00001.46 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000006632 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 00994.63 ], Gyr [ 00001.04, 00001.22, 00000.61 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000006633 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00992.19 ], Gyr [ 00001.83, 00001.04, -00002.32 ], Mag [ 00019.35, 00084.90, 00108.00 ]",
    "#0000006634 NoMotion-1. Scaled. Acc [ -00045.90, -00014.65, 00996.58 ], Gyr [ -00000.37, 00001.52, -00000.79 ], Mag [ 00019.35, 00084.90, 00108.00 ]",
    "#0000006635 NoMotion-1. Scaled. Acc [ -00054.69, -00006.84, 00987.30 ], Gyr [ 00000.67, 00000.06, 00000.55 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006636 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00982.42 ], Gyr [ -00000.24, 00000.67, -00000.24 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006637 NoMotion-1. Scaled. Acc [ -00046.88, -00001.46, 00983.40 ], Gyr [ 00000.49, 00000.49, -00001.16 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006638 NoMotion-1. Scaled. Acc [ -00052.73, -00002.93, 00982.42 ], Gyr [ -00000.43, 00003.05, -00003.54 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006639 NoMotion-1. Scaled. Acc [ -00035.16, -00007.81, 00989.26 ], Gyr [ -00000.79, 00000.85, -00004.02 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000006640 NoMotion-1. Scaled. Acc [ -00026.37, 00001.95, 00978.52 ], Gyr [ 00000.79, -00002.07, -00001.46 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000006641 NoMotion-1. Scaled. Acc [ -00048.34, -00007.32, 00999.51 ], Gyr [ -00000.79, 00002.99, 00000.30 ], Mag [ 00019.35, 00082.95, 00109.50 ]",
    "#0000006642 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00985.35 ], Gyr [ 00000.24, 00002.32, -00002.74 ], Mag [ 00019.35, 00082.95, 00109.50 ]",
    "#0000006643 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00995.12 ], Gyr [ -00000.24, 00002.74, -00003.48 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000006644 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00994.63 ], Gyr [ 00000.06, 00000.49, -00003.05 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000006645 NoMotion-1. Scaled. Acc [ -00033.20, 00008.79, 00980.96 ], Gyr [ 00001.95, 00003.29, -00001.65 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006646 NoMotion-1. Scaled. Acc [ -00041.50, -00011.23, 00994.63 ], Gyr [ 00001.71, -00001.04, 00000.00 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006647 NoMotion-1. Scaled. Acc [ -00053.71, -00012.70, 00986.33 ], Gyr [ 00002.07, 00000.37, 00000.06 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006648 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00983.40 ], Gyr [ 00000.61, 00002.99, -00001.52 ], Mag [ 00020.25, 00081.75, 00107.40 ]",
    "#0000006649 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00984.38 ], Gyr [ 00002.50, 00001.28, -00002.26 ], Mag [ 00020.25, 00081.75, 00106.50 ]",
    "#0000006650 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00999.51 ], Gyr [ 00001.83, -00000.85, -00001.83 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006651 NoMotion-1. Scaled. Acc [ -00042.97, -00011.23, 00988.28 ], Gyr [ 00000.98, -00000.98, -00000.12 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006652 NoMotion-1. Scaled. Acc [ -00049.80, -00003.42, 00996.58 ], Gyr [ -00000.06, 00003.05, -00001.34 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006653 NoMotion-1. Scaled. Acc [ -00052.25, -00008.79, 00985.35 ], Gyr [ -00001.34, 00001.52, -00001.46 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000006654 NoMotion-1. Scaled. Acc [ -00041.02, 00004.88, 00993.16 ], Gyr [ 00000.79, 00001.28, -00002.56 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006655 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00987.30 ], Gyr [ -00002.20, 00000.98, -00000.61 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006656 NoMotion-1. Scaled. Acc [ -00024.41, -00006.35, 00986.33 ], Gyr [ 00000.49, 00002.93, -00000.85 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006657 NoMotion-1. Scaled. Acc [ -00039.55, -00003.42, 00988.77 ], Gyr [ -00000.24, 00003.29, -00001.16 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006658 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 00997.07 ], Gyr [ 00002.26, 00001.04, -00000.12 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006659 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00993.16 ], Gyr [ 00001.46, 00000.30, -00000.43 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006660 NoMotion-1. Scaled. Acc [ -00041.50, -00003.42, 00991.70 ], Gyr [ 00000.12, 00004.51, -00003.60 ], Mag [ 00018.60, 00084.15, 00109.05 ]",
    "#0000006661 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00984.86 ], Gyr [ 00000.49, 00000.61, -00000.43 ], Mag [ 00018.60, 00084.15, 00109.05 ]",
    "#0000006662 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00991.21 ], Gyr [ 00002.44, -00000.06, -00000.43 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000006663 NoMotion-1. Scaled. Acc [ -00049.32, -00015.62, 01000.98 ], Gyr [ -00001.28, 00002.68, -00000.98 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006664 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 01005.37 ], Gyr [ 00001.40, 00002.56, -00003.11 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006665 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00991.21 ], Gyr [ -00000.37, 00001.52, -00000.91 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006666 NoMotion-1. Scaled. Acc [ -00041.02, -00006.35, 00998.05 ], Gyr [ 00001.04, -00000.30, 00000.37 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006667 NoMotion-1. Scaled. Acc [ -00035.64, -00000.98, 00990.72 ], Gyr [ -00002.20, 00002.74, -00002.38 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000006668 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 00983.89 ], Gyr [ 00001.10, 00004.09, -00001.71 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000006669 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00993.16 ], Gyr [ 00000.73, 00001.04, -00000.98 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006670 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 00987.79 ], Gyr [ 00002.99, 00002.50, -00002.44 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006671 NoMotion-1. Scaled. Acc [ -00051.27, -00012.21, 00998.05 ], Gyr [ 00001.83, 00001.46, 00000.06 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006672 NoMotion-1. Scaled. Acc [ -00037.60, -00006.35, 00994.63 ], Gyr [ 00000.06, -00002.01, -00001.34 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006673 NoMotion-1. Scaled. Acc [ -00041.99, 00001.46, 01000.98 ], Gyr [ 00001.95, 00000.43, -00000.06 ], Mag [ 00018.15, 00085.05, 00108.90 ]",
    "#0000006674 NoMotion-1. Scaled. Acc [ -00048.34, -00008.30, 00984.86 ], Gyr [ 00001.59, -00000.49, -00001.34 ], Mag [ 00018.15, 00085.05, 00108.90 ]",
    "#0000006675 NoMotion-1. Scaled. Acc [ -00042.48, -00012.21, 00987.30 ], Gyr [ 00000.00, 00001.83, -00002.68 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000006676 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00987.79 ], Gyr [ 00001.83, 00000.37, -00002.99 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000006677 NoMotion-1. Scaled. Acc [ -00041.99, -00003.42, 00985.35 ], Gyr [ 00000.61, 00000.91, -00001.65 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006678 NoMotion-1. Scaled. Acc [ -00039.06, -00000.49, 00991.21 ], Gyr [ -00000.49, 00004.15, 00000.43 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006679 NoMotion-1. Scaled. Acc [ -00041.02, -00002.44, 00995.61 ], Gyr [ -00001.83, 00001.71, -00002.20 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006680 NoMotion-1. Scaled. Acc [ -00062.50, 00000.98, 00991.21 ], Gyr [ 00000.61, -00000.37, -00002.74 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006681 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00982.42 ], Gyr [ 00001.95, 00002.32, -00002.26 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006682 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00991.21 ], Gyr [ 00003.11, -00001.10, 00000.43 ], Mag [ 00018.15, 00085.05, 00107.55 ]",
    "#0000006683 NoMotion-1. Scaled. Acc [ -00051.27, -00013.18, 00997.07 ], Gyr [ 00001.16, 00001.83, -00001.89 ], Mag [ 00019.20, 00083.55, 00107.10 ]",
    "#0000006684 NoMotion-1. Scaled. Acc [ -00044.43, 00005.86, 00993.16 ], Gyr [ 00001.22, -00001.34, 00000.73 ], Mag [ 00019.20, 00083.55, 00107.10 ]",
    "#0000006685 NoMotion-1. Scaled. Acc [ -00028.81, -00001.95, 00986.82 ], Gyr [ 00000.73, 00000.30, 00001.65 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000006686 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00989.75 ], Gyr [ 00001.77, -00000.85, -00001.40 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000006687 NoMotion-1. Scaled. Acc [ -00049.32, -00004.39, 00991.21 ], Gyr [ -00000.12, 00000.91, -00001.16 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006688 NoMotion-1. Scaled. Acc [ -00039.06, 00000.00, 00996.58 ], Gyr [ 00000.37, 00002.01, -00001.04 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006689 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00986.82 ], Gyr [ 00000.18, 00002.01, -00001.52 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006690 NoMotion-1. Scaled. Acc [ -00040.53, -00018.55, 00994.14 ], Gyr [ 00000.37, 00000.43, -00001.95 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006691 NoMotion-1. Scaled. Acc [ -00025.39, -00016.11, 01003.91 ], Gyr [ -00000.43, 00001.71, -00000.98 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000006692 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00990.23 ], Gyr [ -00000.24, 00001.28, -00002.32 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000006693 NoMotion-1. Scaled. Acc [ -00040.53, -00013.67, 01003.42 ], Gyr [ 00000.49, 00002.38, -00000.73 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000006694 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00981.93 ], Gyr [ 00001.10, 00002.32, -00000.49 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000006695 NoMotion-1. Scaled. Acc [ -00036.13, 00001.46, 00998.05 ], Gyr [ -00000.24, 00003.66, 00000.98 ], Mag [ 00019.65, 00083.70, 00108.00 ]",
    "#0000006696 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 01008.79 ], Gyr [ 00001.77, 00001.28, -00003.23 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006697 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00994.14 ], Gyr [ 00002.26, 00000.73, -00001.59 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006698 NoMotion-1. Scaled. Acc [ -00055.18, -00005.86, 01000.98 ], Gyr [ 00002.74, -00000.30, 00000.18 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006699 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00997.56 ], Gyr [ 00001.46, 00000.67, 00000.79 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006700 NoMotion-1. Scaled. Acc [ -00050.78, -00010.74, 00994.63 ], Gyr [ -00001.04, 00000.98, -00000.55 ], Mag [ 00018.60, 00085.20, 00105.90 ]",
    "#0000006701 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00995.61 ], Gyr [ 00000.67, 00002.07, 00001.22 ], Mag [ 00018.60, 00085.20, 00105.90 ]",
    "#0000006702 NoMotion-1. Scaled. Acc [ -00038.09, -00006.35, 00985.35 ], Gyr [ -00000.37, 00002.80, -00000.18 ], Mag [ 00020.40, 00084.45, 00106.95 ]",
    "#0000006703 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00986.82 ], Gyr [ 00000.24, 00003.35, -00004.21 ], Mag [ 00020.40, 00084.45, 00106.95 ]",
    "#0000006704 NoMotion-1. Scaled. Acc [ -00042.48, -00021.97, 00993.16 ], Gyr [ 00000.30, 00002.56, 00001.16 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000006705 NoMotion-1. Scaled. Acc [ -00051.27, -00003.91, 00994.63 ], Gyr [ 00002.26, 00001.71, -00001.34 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000006706 NoMotion-1. Scaled. Acc [ -00045.90, 00004.39, 00992.68 ], Gyr [ 00000.30, 00004.94, -00001.83 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006707 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00989.26 ], Gyr [ -00000.18, 00006.10, -00004.21 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006708 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00993.65 ], Gyr [ -00000.55, 00000.37, 00001.16 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006709 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00995.61 ], Gyr [ 00000.43, 00001.52, -00001.52 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006710 NoMotion-1. Scaled. Acc [ -00047.85, -00010.25, 00983.89 ], Gyr [ 00000.55, 00000.79, -00004.21 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006711 NoMotion-1. Scaled. Acc [ -00053.22, -00007.81, 00998.54 ], Gyr [ 00001.65, 00000.12, -00000.37 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006712 NoMotion-1. Scaled. Acc [ -00032.71, -00004.39, 00997.56 ], Gyr [ -00000.91, 00001.40, -00002.93 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006713 NoMotion-1. Scaled. Acc [ -00038.57, -00000.98, 00990.23 ], Gyr [ 00001.46, 00000.91, -00000.55 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006714 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 00991.21 ], Gyr [ 00000.55, 00000.98, -00001.28 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006715 NoMotion-1. Scaled. Acc [ -00041.02, -00014.65, 00983.40 ], Gyr [ 00000.49, 00001.77, 00001.04 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006716 NoMotion-1. Scaled. Acc [ -00048.34, -00024.41, 01000.00 ], Gyr [ 00000.67, 00000.79, -00000.98 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006717 NoMotion-1. Scaled. Acc [ -00031.74, -00016.11, 00995.12 ], Gyr [ -00000.67, 00002.07, 00000.00 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006718 NoMotion-1. Scaled. Acc [ -00041.50, 00003.91, 00993.16 ], Gyr [ 00000.06, 00001.83, -00000.91 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006719 NoMotion-1. Scaled. Acc [ -00046.88, -00008.30, 00992.19 ], Gyr [ 00001.04, 00003.84, -00001.95 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006720 NoMotion-1. Scaled. Acc [ -00055.18, -00018.55, 00991.21 ], Gyr [ 00000.67, 00001.89, -00002.87 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006721 NoMotion-1. Scaled. Acc [ -00021.97, -00003.42, 00997.56 ], Gyr [ 00000.37, 00001.52, 00001.22 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006722 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00978.03 ], Gyr [ 00001.34, 00000.91, -00002.07 ], Mag [ 00020.85, 00081.90, 00107.70 ]",
    "#0000006723 NoMotion-1. Scaled. Acc [ -00035.64, -00002.93, 00994.14 ], Gyr [ 00001.10, 00000.55, -00000.24 ], Mag [ 00020.85, 00081.90, 00107.70 ]",
    "#0000006724 NoMotion-1. Scaled. Acc [ -00040.53, -00006.35, 01004.39 ], Gyr [ 00000.55, 00003.17, -00001.83 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006725 NoMotion-1. Scaled. Acc [ -00053.71, -00006.35, 00997.56 ], Gyr [ 00000.37, 00000.91, -00001.95 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006726 NoMotion-1. Scaled. Acc [ -00043.46, -00005.37, 00986.33 ], Gyr [ -00002.20, -00001.16, -00002.68 ], Mag [ 00020.10, 00084.45, 00108.45 ]",
    "#0000006727 NoMotion-1. Scaled. Acc [ -00040.53, -00016.11, 00987.30 ], Gyr [ 00002.20, -00000.73, -00001.95 ], Mag [ 00020.10, 00084.45, 00108.45 ]",
    "#0000006728 NoMotion-1. Scaled. Acc [ -00047.36, -00014.16, 00999.51 ], Gyr [ 00000.18, 00002.50, -00001.95 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006729 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00987.79 ], Gyr [ -00000.06, 00001.04, -00002.93 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006730 NoMotion-1. Scaled. Acc [ -00043.95, -00013.67, 00993.65 ], Gyr [ -00001.59, 00002.87, -00000.30 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006731 NoMotion-1. Scaled. Acc [ -00039.55, -00007.32, 00988.77 ], Gyr [ -00001.10, 00004.09, -00001.52 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006732 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00985.35 ], Gyr [ 00000.06, 00003.78, -00002.13 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000006733 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00987.30 ], Gyr [ 00000.18, -00000.12, 00000.06 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006734 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00996.58 ], Gyr [ 00000.61, 00001.34, 00000.85 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006735 NoMotion-1. Scaled. Acc [ -00042.48, 00000.49, 00991.21 ], Gyr [ -00000.55, 00002.80, -00001.22 ], Mag [ 00018.75, 00084.00, 00108.90 ]",
    "#0000006736 NoMotion-1. Scaled. Acc [ -00032.71, -00010.25, 01000.49 ], Gyr [ 00001.16, 00001.34, -00001.89 ], Mag [ 00018.75, 00084.00, 00108.90 ]",
    "#0000006737 NoMotion-1. Scaled. Acc [ -00035.16, -00012.21, 00994.14 ], Gyr [ 00000.67, 00002.20, -00002.80 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006738 NoMotion-1. Scaled. Acc [ -00048.83, -00011.72, 00999.02 ], Gyr [ 00001.40, 00002.50, 00001.40 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006739 NoMotion-1. Scaled. Acc [ -00038.57, -00011.23, 01002.93 ], Gyr [ -00000.06, 00002.99, -00002.50 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006740 NoMotion-1. Scaled. Acc [ -00050.78, -00005.37, 01001.95 ], Gyr [ -00001.65, 00003.96, -00000.55 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006741 NoMotion-1. Scaled. Acc [ -00038.57, -00014.16, 01000.00 ], Gyr [ 00000.43, -00000.67, -00001.59 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000006742 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00997.56 ], Gyr [ -00000.79, 00003.48, -00001.22 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000006743 NoMotion-1. Scaled. Acc [ -00034.18, -00004.39, 01001.46 ], Gyr [ 00001.52, 00002.80, -00000.73 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006744 NoMotion-1. Scaled. Acc [ -00041.99, 00005.86, 00989.75 ], Gyr [ 00001.52, 00001.89, -00002.07 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006745 NoMotion-1. Scaled. Acc [ -00040.04, -00003.91, 00997.56 ], Gyr [ 00000.61, 00000.12, -00001.95 ], Mag [ 00020.70, 00084.30, 00109.35 ]",
    "#0000006746 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 01002.44 ], Gyr [ 00000.30, 00000.24, -00001.83 ], Mag [ 00020.70, 00084.30, 00109.35 ]",
    "#0000006747 NoMotion-1. Scaled. Acc [ -00033.20, -00009.77, 00994.14 ], Gyr [ -00000.73, 00002.44, -00001.22 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006748 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00987.79 ], Gyr [ 00000.61, -00001.83, -00000.24 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006749 NoMotion-1. Scaled. Acc [ -00040.04, -00018.07, 00994.63 ], Gyr [ 00000.49, 00000.43, -00000.67 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006750 NoMotion-1. Scaled. Acc [ -00042.48, -00017.58, 00989.75 ], Gyr [ 00000.43, -00000.30, -00001.77 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006751 NoMotion-1. Scaled. Acc [ -00046.39, -00007.81, 00995.61 ], Gyr [ -00000.30, 00003.60, -00003.66 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006752 NoMotion-1. Scaled. Acc [ -00048.34, -00020.02, 00991.70 ], Gyr [ 00002.38, 00000.73, 00000.37 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006753 NoMotion-1. Scaled. Acc [ -00045.90, -00011.72, 00988.28 ], Gyr [ 00001.10, -00000.85, -00002.38 ], Mag [ 00020.70, 00083.25, 00107.10 ]",
    "#0000006754 NoMotion-1. Scaled. Acc [ -00031.25, -00005.37, 00977.54 ], Gyr [ 00001.22, -00000.85, -00000.06 ], Mag [ 00020.70, 00083.25, 00107.10 ]",
    "#0000006755 NoMotion-1. Scaled. Acc [ -00033.20, -00003.91, 00983.40 ], Gyr [ 00000.67, 00002.07, -00003.41 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006756 NoMotion-1. Scaled. Acc [ -00035.64, -00005.37, 00994.63 ], Gyr [ 00000.12, 00000.12, -00001.34 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006757 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 01006.84 ], Gyr [ -00000.55, 00001.77, -00002.80 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006758 NoMotion-1. Scaled. Acc [ -00039.55, 00001.46, 00984.86 ], Gyr [ 00000.18, 00002.80, -00001.77 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006759 NoMotion-1. Scaled. Acc [ -00051.27, -00013.67, 00993.65 ], Gyr [ 00000.67, 00001.16, -00000.67 ], Mag [ 00021.30, 00084.75, 00107.10 ]",
    "#0000006760 NoMotion-1. Scaled. Acc [ -00046.88, -00010.25, 00996.09 ], Gyr [ -00000.30, -00000.79, -00001.71 ], Mag [ 00021.30, 00084.75, 00107.10 ]",
    "#0000006761 NoMotion-1. Scaled. Acc [ -00058.11, -00002.44, 00978.03 ], Gyr [ 00000.12, 00002.38, -00000.91 ], Mag [ 00019.95, 00084.00, 00105.45 ]",
    "#0000006762 NoMotion-1. Scaled. Acc [ -00040.53, -00002.44, 01000.98 ], Gyr [ 00002.68, 00002.68, -00002.07 ], Mag [ 00019.95, 00084.00, 00105.45 ]",
    "#0000006763 NoMotion-1. Scaled. Acc [ -00030.76, 00000.49, 00988.77 ], Gyr [ 00002.13, 00001.22, 00000.06 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000006764 NoMotion-1. Scaled. Acc [ -00030.76, -00013.18, 01000.98 ], Gyr [ 00002.26, -00000.12, 00000.06 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000006765 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00993.65 ], Gyr [ 00000.85, 00002.07, -00001.77 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006766 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00987.30 ], Gyr [ 00002.93, -00000.91, -00002.44 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006767 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00995.61 ], Gyr [ 00001.95, 00003.90, -00002.44 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006768 NoMotion-1. Scaled. Acc [ -00045.90, -00002.93, 00992.68 ], Gyr [ -00000.06, 00002.13, -00000.30 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006769 NoMotion-1. Scaled. Acc [ -00054.69, -00004.88, 00994.14 ], Gyr [ 00001.22, 00001.22, -00002.74 ], Mag [ 00019.50, 00084.30, 00107.55 ]",
    "#0000006770 NoMotion-1. Scaled. Acc [ -00054.20, -00006.35, 00992.19 ], Gyr [ -00002.07, 00001.89, 00000.18 ], Mag [ 00019.50, 00084.30, 00107.55 ]",
    "#0000006771 NoMotion-1. Scaled. Acc [ -00031.74, -00002.44, 00988.77 ], Gyr [ -00000.30, 00002.50, -00003.29 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000006772 NoMotion-1. Scaled. Acc [ -00046.88, 00001.46, 00988.28 ], Gyr [ 00000.98, 00002.20, 00000.24 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000006773 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00987.79 ], Gyr [ 00002.93, -00001.22, 00000.00 ], Mag [ 00019.35, 00083.70, 00106.65 ]",
    "#0000006774 NoMotion-1. Scaled. Acc [ -00047.85, 00005.37, 00983.40 ], Gyr [ 00000.73, -00000.67, 00000.12 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006775 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00988.28 ], Gyr [ 00002.93, -00000.91, -00002.80 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006776 NoMotion-1. Scaled. Acc [ -00046.39, 00001.46, 00993.16 ], Gyr [ 00000.85, 00000.00, -00000.30 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006777 NoMotion-1. Scaled. Acc [ -00038.57, -00018.07, 00990.23 ], Gyr [ -00002.13, 00000.37, -00001.16 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006778 NoMotion-1. Scaled. Acc [ -00042.97, 00012.70, 00994.63 ], Gyr [ 00001.65, 00002.26, -00000.49 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006779 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00994.63 ], Gyr [ 00000.49, 00003.11, -00001.04 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006780 NoMotion-1. Scaled. Acc [ -00034.18, -00021.00, 00994.63 ], Gyr [ 00000.55, 00002.74, -00002.87 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006781 NoMotion-1. Scaled. Acc [ -00032.23, -00006.84, 01003.42 ], Gyr [ 00000.24, 00004.33, -00001.83 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006782 NoMotion-1. Scaled. Acc [ -00052.73, -00007.81, 00987.79 ], Gyr [ -00000.85, 00000.06, -00003.54 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006783 NoMotion-1. Scaled. Acc [ -00041.02, 00000.98, 00986.33 ], Gyr [ 00001.28, 00000.06, -00002.44 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006784 NoMotion-1. Scaled. Acc [ -00040.04, -00017.09, 01000.98 ], Gyr [ -00000.49, 00000.79, 00000.37 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000006785 NoMotion-1. Scaled. Acc [ -00054.20, -00005.37, 01002.93 ], Gyr [ 00001.04, 00001.10, -00002.01 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000006786 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00992.19 ], Gyr [ -00000.12, 00001.10, -00000.85 ], Mag [ 00020.40, 00084.45, 00107.70 ]",
    "#0000006787 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00994.63 ], Gyr [ 00001.59, -00000.06, -00001.10 ], Mag [ 00020.40, 00084.45, 00107.70 ]",
    "#0000006788 NoMotion-1. Scaled. Acc [ -00045.41, 00004.39, 00984.38 ], Gyr [ 00001.04, 00000.12, -00003.11 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000006789 NoMotion-1. Scaled. Acc [ -00031.74, 00006.84, 01000.98 ], Gyr [ 00000.18, -00001.04, 00000.67 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006790 NoMotion-1. Scaled. Acc [ -00053.71, -00014.65, 00992.19 ], Gyr [ 00000.12, 00001.10, -00002.68 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006791 NoMotion-1. Scaled. Acc [ -00046.39, -00008.30, 00993.65 ], Gyr [ 00000.18, 00002.38, -00002.07 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006792 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00982.42 ], Gyr [ -00001.10, 00001.77, -00002.93 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000006793 NoMotion-1. Scaled. Acc [ -00047.36, -00006.35, 00999.51 ], Gyr [ -00000.73, 00002.07, -00000.37 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000006794 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00984.86 ], Gyr [ 00001.52, -00000.49, -00002.01 ], Mag [ 00018.75, 00084.75, 00106.80 ]",
    "#0000006795 NoMotion-1. Scaled. Acc [ -00052.73, -00007.32, 00993.16 ], Gyr [ -00000.43, 00000.85, -00002.20 ], Mag [ 00018.75, 00084.75, 00106.80 ]",
    "#0000006796 NoMotion-1. Scaled. Acc [ -00058.59, -00003.42, 00995.12 ], Gyr [ 00000.00, 00000.73, -00000.43 ], Mag [ 00019.35, 00085.20, 00107.70 ]",
    "#0000006797 NoMotion-1. Scaled. Acc [ -00057.13, -00014.65, 00991.70 ], Gyr [ 00000.30, 00001.77, -00001.22 ], Mag [ 00019.35, 00085.20, 00107.70 ]",
    "#0000006798 NoMotion-1. Scaled. Acc [ -00044.43, -00007.81, 00990.72 ], Gyr [ 00001.16, 00001.04, -00001.89 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006799 NoMotion-1. Scaled. Acc [ -00038.57, -00008.30, 00998.54 ], Gyr [ 00000.30, 00000.24, -00001.77 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006800 NoMotion-1. Scaled. Acc [ -00034.18, -00009.28, 00989.75 ], Gyr [ 00000.06, 00002.38, -00002.01 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006801 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00992.19 ], Gyr [ 00000.85, 00001.28, -00000.49 ], Mag [ 00019.50, 00083.55, 00108.60 ]",
    "#0000006802 NoMotion-1. Scaled. Acc [ -00044.43, -00001.46, 00983.40 ], Gyr [ 00001.10, 00003.72, -00001.83 ], Mag [ 00019.50, 00083.55, 00108.60 ]",
    "#0000006803 NoMotion-1. Scaled. Acc [ -00041.50, -00008.79, 00984.86 ], Gyr [ -00000.85, 00004.15, 00000.55 ], Mag [ 00020.40, 00083.55, 00108.60 ]",
    "#0000006804 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00999.51 ], Gyr [ -00000.85, 00003.41, -00003.48 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000006805 NoMotion-1. Scaled. Acc [ -00055.18, -00011.72, 00990.72 ], Gyr [ -00001.77, 00002.56, -00001.22 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006806 NoMotion-1. Scaled. Acc [ -00048.83, 00001.46, 00995.61 ], Gyr [ 00000.37, 00002.26, -00002.62 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006807 NoMotion-1. Scaled. Acc [ -00038.57, -00001.46, 01003.91 ], Gyr [ 00000.37, 00001.65, -00002.32 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006808 NoMotion-1. Scaled. Acc [ -00035.16, 00001.95, 00991.70 ], Gyr [ 00001.59, 00000.30, 00000.67 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006809 NoMotion-1. Scaled. Acc [ -00045.41, 00001.95, 00993.16 ], Gyr [ 00001.34, -00000.85, -00000.61 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006810 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00996.09 ], Gyr [ 00002.44, 00001.04, 00000.55 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000006811 NoMotion-1. Scaled. Acc [ -00052.25, -00011.23, 01000.49 ], Gyr [ 00001.52, 00002.32, -00000.85 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000006812 NoMotion-1. Scaled. Acc [ -00035.16, -00012.21, 00988.77 ], Gyr [ -00000.18, 00002.62, -00001.46 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000006813 NoMotion-1. Scaled. Acc [ -00042.48, -00017.09, 00991.70 ], Gyr [ 00001.40, 00001.16, -00001.10 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000006814 NoMotion-1. Scaled. Acc [ -00041.50, -00003.91, 01000.49 ], Gyr [ 00002.01, -00000.06, -00000.43 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000006815 NoMotion-1. Scaled. Acc [ -00040.04, -00017.09, 00990.23 ], Gyr [ 00002.44, 00001.34, -00003.05 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000006816 NoMotion-1. Scaled. Acc [ -00048.34, -00004.39, 00978.52 ], Gyr [ -00000.24, 00000.79, -00003.96 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006817 NoMotion-1. Scaled. Acc [ -00049.32, -00001.46, 00995.61 ], Gyr [ 00000.06, 00001.16, -00001.34 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006818 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 01003.91 ], Gyr [ 00000.43, 00002.68, -00002.68 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006819 NoMotion-1. Scaled. Acc [ -00044.92, -00006.35, 00992.68 ], Gyr [ 00000.85, 00004.02, -00004.09 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006820 NoMotion-1. Scaled. Acc [ -00047.85, -00004.39, 00996.09 ], Gyr [ 00000.00, 00001.34, -00001.65 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000006821 NoMotion-1. Scaled. Acc [ -00050.78, -00006.84, 00983.89 ], Gyr [ -00001.04, -00000.24, -00001.83 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006822 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00992.68 ], Gyr [ 00001.16, 00001.46, -00002.01 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006823 NoMotion-1. Scaled. Acc [ -00043.46, -00003.42, 00994.14 ], Gyr [ 00001.89, 00001.16, 00000.61 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006824 NoMotion-1. Scaled. Acc [ -00052.73, -00001.95, 00993.65 ], Gyr [ 00003.84, 00000.24, -00001.40 ], Mag [ 00019.35, 00082.95, 00106.20 ]",
    "#0000006825 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00989.75 ], Gyr [ 00000.61, 00000.67, -00001.65 ], Mag [ 00019.35, 00082.95, 00106.20 ]",
    "#0000006826 NoMotion-1. Scaled. Acc [ -00039.55, 00002.93, 00985.35 ], Gyr [ 00001.04, 00000.12, -00002.80 ], Mag [ 00019.20, 00085.05, 00108.30 ]",
    "#0000006827 NoMotion-1. Scaled. Acc [ -00038.09, -00009.28, 00995.12 ], Gyr [ -00000.79, 00002.62, -00001.28 ], Mag [ 00019.20, 00085.05, 00108.30 ]",
    "#0000006828 NoMotion-1. Scaled. Acc [ -00050.78, -00006.35, 00993.65 ], Gyr [ 00001.46, 00002.80, -00003.96 ], Mag [ 00021.60, 00084.45, 00109.05 ]",
    "#0000006829 NoMotion-1. Scaled. Acc [ -00040.04, -00004.88, 00985.84 ], Gyr [ -00001.59, 00001.04, -00002.50 ], Mag [ 00021.60, 00084.45, 00109.05 ]",
    "#0000006830 NoMotion-1. Scaled. Acc [ -00057.13, -00004.39, 00992.68 ], Gyr [ -00001.59, 00002.50, -00000.85 ], Mag [ 00020.70, 00085.05, 00108.30 ]",
    "#0000006831 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00985.35 ], Gyr [ -00001.16, 00002.93, -00000.98 ], Mag [ 00020.70, 00085.05, 00108.30 ]",
    "#0000006832 NoMotion-1. Scaled. Acc [ -00040.04, -00020.51, 00984.38 ], Gyr [ -00000.24, 00001.95, -00002.80 ], Mag [ 00021.00, 00085.50, 00107.55 ]",
    "#0000006833 NoMotion-1. Scaled. Acc [ -00046.39, -00012.70, 00991.70 ], Gyr [ -00000.12, 00003.41, -00001.52 ], Mag [ 00021.00, 00085.50, 00107.55 ]",
    "#0000006834 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00986.82 ], Gyr [ 00000.00, 00002.50, -00003.72 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006835 NoMotion-1. Scaled. Acc [ -00039.55, -00021.00, 00985.84 ], Gyr [ 00001.16, 00000.85, -00001.77 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006836 NoMotion-1. Scaled. Acc [ -00043.46, -00006.35, 00984.38 ], Gyr [ 00001.52, 00000.79, -00001.40 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006837 NoMotion-1. Scaled. Acc [ -00041.50, -00001.46, 00989.26 ], Gyr [ -00001.65, 00002.56, -00001.40 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006838 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00982.91 ], Gyr [ 00001.59, 00000.67, -00000.73 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006839 NoMotion-1. Scaled. Acc [ -00054.20, 00000.98, 00996.58 ], Gyr [ 00000.61, 00001.04, -00001.16 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006840 NoMotion-1. Scaled. Acc [ -00049.32, -00009.28, 00990.23 ], Gyr [ -00000.24, 00001.77, -00001.77 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006841 NoMotion-1. Scaled. Acc [ -00047.36, -00000.98, 00999.51 ], Gyr [ 00000.49, 00001.52, -00000.67 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006842 NoMotion-1. Scaled. Acc [ -00040.53, -00025.39, 00994.63 ], Gyr [ -00002.01, 00002.87, -00000.37 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000006843 NoMotion-1. Scaled. Acc [ -00042.97, -00014.16, 00998.05 ], Gyr [ 00000.12, 00003.72, -00002.56 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000006844 NoMotion-1. Scaled. Acc [ -00037.60, -00010.25, 00994.63 ], Gyr [ 00000.55, 00004.39, -00001.95 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006845 NoMotion-1. Scaled. Acc [ -00044.43, 00000.49, 00992.19 ], Gyr [ -00000.49, 00000.43, -00003.54 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006846 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00991.21 ], Gyr [ 00002.07, 00000.24, -00001.52 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006847 NoMotion-1. Scaled. Acc [ -00037.60, -00003.91, 00984.86 ], Gyr [ 00001.22, 00002.93, -00002.68 ], Mag [ 00019.50, 00083.55, 00108.90 ]",
    "#0000006848 NoMotion-1. Scaled. Acc [ -00047.85, -00019.04, 00996.58 ], Gyr [ 00000.67, 00001.95, 00000.24 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006849 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00987.30 ], Gyr [ -00000.37, -00000.73, -00001.59 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006850 NoMotion-1. Scaled. Acc [ -00034.18, 00003.42, 00979.49 ], Gyr [ 00000.49, -00000.37, -00000.85 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006851 NoMotion-1. Scaled. Acc [ -00041.50, 00001.46, 00995.61 ], Gyr [ 00001.10, 00002.32, 00000.55 ], Mag [ 00019.35, 00082.20, 00107.40 ]",
    "#0000006852 NoMotion-1. Scaled. Acc [ -00042.48, 00008.79, 00989.75 ], Gyr [ -00000.24, 00001.95, -00002.99 ], Mag [ 00019.35, 00082.20, 00107.40 ]",
    "#0000006853 NoMotion-1. Scaled. Acc [ -00044.43, 00003.42, 00981.45 ], Gyr [ 00000.91, 00002.32, -00003.66 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006854 NoMotion-1. Scaled. Acc [ -00041.99, -00010.25, 00998.05 ], Gyr [ 00001.52, 00000.37, -00003.05 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006855 NoMotion-1. Scaled. Acc [ -00044.92, -00023.44, 00978.03 ], Gyr [ 00002.50, -00000.43, -00000.30 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006856 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 00994.14 ], Gyr [ 00000.85, 00000.79, -00003.54 ], Mag [ 00019.50, 00085.05, 00107.85 ]",
    "#0000006857 NoMotion-1. Scaled. Acc [ -00031.25, -00007.81, 00996.09 ], Gyr [ -00000.12, 00000.85, -00002.87 ], Mag [ 00019.50, 00085.05, 00107.85 ]",
    "#0000006858 NoMotion-1. Scaled. Acc [ -00041.02, -00007.81, 00990.23 ], Gyr [ -00000.43, 00000.55, -00002.01 ], Mag [ 00020.85, 00083.70, 00107.70 ]",
    "#0000006859 NoMotion-1. Scaled. Acc [ -00038.09, -00008.79, 00989.75 ], Gyr [ 00000.98, 00000.55, -00001.46 ], Mag [ 00020.85, 00083.70, 00107.70 ]",
    "#0000006860 NoMotion-1. Scaled. Acc [ -00041.50, -00003.42, 00984.38 ], Gyr [ 00002.99, 00002.32, -00001.71 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000006861 NoMotion-1. Scaled. Acc [ -00039.55, 00002.93, 01002.93 ], Gyr [ -00001.52, 00000.98, -00001.77 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000006862 NoMotion-1. Scaled. Acc [ -00029.30, -00006.84, 00990.23 ], Gyr [ 00000.43, 00001.52, -00002.07 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006863 NoMotion-1. Scaled. Acc [ -00056.15, -00004.39, 00994.14 ], Gyr [ 00001.28, 00001.52, 00001.04 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006864 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00991.70 ], Gyr [ 00000.18, 00000.06, -00001.34 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006865 NoMotion-1. Scaled. Acc [ -00047.36, -00009.77, 00991.70 ], Gyr [ -00000.30, 00001.10, -00003.29 ], Mag [ 00018.30, 00082.95, 00106.65 ]",
    "#0000006866 NoMotion-1. Scaled. Acc [ -00039.55, -00020.51, 00992.19 ], Gyr [ 00000.55, 00001.71, -00004.27 ], Mag [ 00018.30, 00082.95, 00106.65 ]",
    "#0000006867 NoMotion-1. Scaled. Acc [ -00045.41, -00010.25, 01002.93 ], Gyr [ 00000.24, -00000.91, -00004.39 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000006868 NoMotion-1. Scaled. Acc [ -00042.48, -00005.86, 00982.91 ], Gyr [ 00000.00, 00000.18, 00000.18 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000006869 NoMotion-1. Scaled. Acc [ -00042.97, -00012.21, 00990.72 ], Gyr [ 00000.73, 00003.05, -00002.20 ], Mag [ 00019.20, 00084.30, 00108.30 ]",
    "#0000006870 NoMotion-1. Scaled. Acc [ -00042.48, -00002.44, 00986.33 ], Gyr [ -00001.34, 00002.80, -00003.54 ], Mag [ 00019.20, 00084.30, 00108.30 ]",
    "#0000006871 NoMotion-1. Scaled. Acc [ -00051.27, -00001.95, 01005.37 ], Gyr [ -00000.06, 00000.12, -00001.83 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006872 NoMotion-1. Scaled. Acc [ -00045.90, 00003.42, 00998.54 ], Gyr [ 00002.62, 00000.79, -00001.16 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006873 NoMotion-1. Scaled. Acc [ -00045.41, -00014.16, 00983.89 ], Gyr [ -00001.46, -00001.10, -00004.21 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006874 NoMotion-1. Scaled. Acc [ -00037.11, -00020.51, 00993.65 ], Gyr [ 00000.73, 00000.67, 00001.16 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006875 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00985.35 ], Gyr [ 00001.52, -00000.12, -00001.46 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006876 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 01002.93 ], Gyr [ 00001.52, 00000.24, -00000.43 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000006877 NoMotion-1. Scaled. Acc [ -00041.02, -00005.86, 00984.38 ], Gyr [ -00000.30, 00000.43, 00000.43 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000006878 NoMotion-1. Scaled. Acc [ -00045.41, 00007.32, 00977.54 ], Gyr [ -00000.79, 00000.24, -00002.20 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006879 NoMotion-1. Scaled. Acc [ -00044.92, -00007.81, 00985.35 ], Gyr [ 00001.77, 00000.73, -00002.62 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006880 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00990.72 ], Gyr [ -00000.06, 00001.16, -00000.49 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006881 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00981.93 ], Gyr [ -00000.37, 00001.71, -00003.54 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006882 NoMotion-1. Scaled. Acc [ -00038.09, -00006.84, 00980.47 ], Gyr [ 00002.93, -00001.04, 00001.34 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006883 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00986.82 ], Gyr [ 00000.49, 00002.26, -00001.59 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006884 NoMotion-1. Scaled. Acc [ -00041.50, -00017.58, 00983.40 ], Gyr [ 00000.43, 00000.49, 00000.00 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006885 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00986.82 ], Gyr [ 00000.91, 00000.37, 00001.16 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006886 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 01005.37 ], Gyr [ 00001.04, 00002.44, 00001.89 ], Mag [ 00021.15, 00084.15, 00109.50 ]",
    "#0000006887 NoMotion-1. Scaled. Acc [ -00035.16, -00007.81, 00996.58 ], Gyr [ 00000.49, 00002.20, 00000.30 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006888 NoMotion-1. Scaled. Acc [ -00049.32, -00014.65, 00993.65 ], Gyr [ 00001.52, 00002.01, -00001.46 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006889 NoMotion-1. Scaled. Acc [ -00044.43, -00020.02, 01002.44 ], Gyr [ 00003.17, 00003.17, 00000.30 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006890 NoMotion-1. Scaled. Acc [ -00031.25, -00004.88, 00986.82 ], Gyr [ 00000.91, 00001.04, -00002.93 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006891 NoMotion-1. Scaled. Acc [ -00050.78, -00018.07, 00982.91 ], Gyr [ 00001.77, 00000.79, 00002.01 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006892 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00992.19 ], Gyr [ -00000.91, 00002.07, -00002.50 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000006893 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00989.75 ], Gyr [ -00000.55, 00003.29, -00002.07 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000006894 NoMotion-1. Scaled. Acc [ -00051.27, -00003.42, 00988.77 ], Gyr [ 00001.16, 00000.91, -00003.11 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000006895 NoMotion-1. Scaled. Acc [ -00051.76, -00003.42, 00989.26 ], Gyr [ 00000.06, 00001.16, -00000.43 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000006896 NoMotion-1. Scaled. Acc [ -00038.09, -00008.30, 00997.56 ], Gyr [ -00001.04, 00001.04, -00002.80 ], Mag [ 00018.45, 00084.00, 00108.30 ]",
    "#0000006897 NoMotion-1. Scaled. Acc [ -00039.06, 00001.46, 01000.49 ], Gyr [ 00001.34, -00000.18, -00001.10 ], Mag [ 00018.45, 00084.00, 00108.30 ]",
    "#0000006898 NoMotion-1. Scaled. Acc [ -00053.71, -00020.02, 00989.26 ], Gyr [ 00000.98, 00001.04, -00002.01 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006899 NoMotion-1. Scaled. Acc [ -00041.99, -00015.14, 00999.51 ], Gyr [ 00000.06, 00001.16, -00002.62 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006900 NoMotion-1. Scaled. Acc [ -00050.78, 00001.46, 00995.61 ], Gyr [ -00000.98, 00002.93, -00000.43 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006901 NoMotion-1. Scaled. Acc [ -00035.64, -00003.42, 00986.33 ], Gyr [ -00000.43, 00001.59, -00001.22 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006902 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00998.54 ], Gyr [ -00001.71, 00002.44, -00003.41 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006903 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00988.28 ], Gyr [ 00001.52, 00002.26, -00002.26 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006904 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00991.21 ], Gyr [ -00000.79, 00001.65, 00001.83 ], Mag [ 00019.05, 00082.95, 00106.95 ]",
    "#0000006905 NoMotion-1. Scaled. Acc [ -00038.09, -00016.11, 00998.54 ], Gyr [ -00000.43, 00002.20, -00000.91 ], Mag [ 00019.05, 00082.95, 00106.95 ]",
    "#0000006906 NoMotion-1. Scaled. Acc [ -00040.04, 00001.95, 00997.07 ], Gyr [ 00000.67, 00002.68, 00001.10 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006907 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00990.23 ], Gyr [ 00002.62, 00002.13, -00002.13 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006908 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00982.91 ], Gyr [ 00002.50, -00001.28, 00003.11 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006909 NoMotion-1. Scaled. Acc [ -00043.95, -00012.21, 00979.49 ], Gyr [ 00000.43, 00002.44, -00000.55 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006910 NoMotion-1. Scaled. Acc [ -00044.43, 00000.98, 00988.28 ], Gyr [ 00000.85, 00002.13, -00000.49 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006911 NoMotion-1. Scaled. Acc [ -00031.25, -00012.70, 00989.75 ], Gyr [ 00001.95, 00001.95, -00001.10 ], Mag [ 00019.95, 00082.80, 00106.05 ]",
    "#0000006912 NoMotion-1. Scaled. Acc [ -00045.90, -00018.07, 00993.65 ], Gyr [ 00000.79, 00001.10, -00001.95 ], Mag [ 00019.95, 00082.80, 00106.05 ]",
    "#0000006913 NoMotion-1. Scaled. Acc [ -00042.97, 00004.39, 00983.89 ], Gyr [ -00000.18, 00005.67, -00002.74 ], Mag [ 00020.70, 00083.55, 00106.80 ]",
    "#0000006914 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00996.09 ], Gyr [ 00000.00, 00001.16, -00001.83 ], Mag [ 00020.70, 00083.55, 00106.80 ]",
    "#0000006915 NoMotion-1. Scaled. Acc [ -00044.43, 00003.42, 00990.23 ], Gyr [ -00001.04, 00000.79, -00000.49 ], Mag [ 00018.75, 00085.80, 00106.80 ]",
    "#0000006916 NoMotion-1. Scaled. Acc [ -00034.18, -00002.93, 00991.21 ], Gyr [ 00000.85, -00000.61, -00000.55 ], Mag [ 00018.75, 00085.80, 00106.80 ]",
    "#0000006917 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00989.26 ], Gyr [ -00000.30, 00003.60, -00000.85 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006918 NoMotion-1. Scaled. Acc [ -00053.22, -00010.25, 01003.91 ], Gyr [ -00001.22, -00000.06, -00002.74 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006919 NoMotion-1. Scaled. Acc [ -00038.09, -00001.95, 00981.93 ], Gyr [ 00002.74, 00000.85, -00000.24 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006920 NoMotion-1. Scaled. Acc [ -00035.16, -00010.74, 00995.12 ], Gyr [ 00000.79, -00001.40, -00002.80 ], Mag [ 00019.65, 00084.90, 00108.00 ]",
    "#0000006921 NoMotion-1. Scaled. Acc [ -00042.48, -00011.23, 00994.63 ], Gyr [ -00001.52, 00003.84, -00001.59 ], Mag [ 00019.65, 00084.90, 00108.00 ]",
    "#0000006922 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00993.16 ], Gyr [ 00002.44, -00000.12, -00001.89 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000006923 NoMotion-1. Scaled. Acc [ -00039.55, -00008.30, 00997.07 ], Gyr [ 00000.43, 00002.01, 00002.32 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000006924 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 00990.23 ], Gyr [ 00000.06, 00001.59, -00002.50 ], Mag [ 00018.45, 00085.50, 00107.10 ]",
    "#0000006925 NoMotion-1. Scaled. Acc [ -00043.95, 00003.42, 00991.70 ], Gyr [ 00003.66, 00001.22, 00001.22 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006926 NoMotion-1. Scaled. Acc [ -00041.50, -00015.14, 00988.77 ], Gyr [ -00000.61, 00003.23, -00002.99 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006927 NoMotion-1. Scaled. Acc [ -00043.95, -00010.74, 00993.16 ], Gyr [ -00001.28, 00004.88, -00002.01 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006928 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00993.65 ], Gyr [ 00001.46, -00000.91, -00001.65 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006929 NoMotion-1. Scaled. Acc [ -00041.99, -00013.18, 00989.26 ], Gyr [ -00000.73, 00002.32, -00002.32 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006930 NoMotion-1. Scaled. Acc [ -00041.99, -00004.39, 00992.19 ], Gyr [ -00000.30, 00002.44, -00002.13 ], Mag [ 00019.20, 00083.25, 00108.30 ]",
    "#0000006931 NoMotion-1. Scaled. Acc [ -00055.18, -00002.44, 00982.42 ], Gyr [ 00000.30, 00001.28, -00001.22 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000006932 NoMotion-1. Scaled. Acc [ -00051.27, -00009.28, 00987.30 ], Gyr [ 00001.40, -00000.24, 00001.83 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000006933 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00993.16 ], Gyr [ -00000.12, 00005.00, -00002.44 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006934 NoMotion-1. Scaled. Acc [ -00033.20, -00005.86, 00986.33 ], Gyr [ -00000.06, 00001.83, -00003.90 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006935 NoMotion-1. Scaled. Acc [ -00034.18, -00010.74, 00988.28 ], Gyr [ 00000.30, 00001.28, 00001.95 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006936 NoMotion-1. Scaled. Acc [ -00059.57, -00004.88, 00991.21 ], Gyr [ 00001.59, 00001.89, -00002.93 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006937 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00997.07 ], Gyr [ 00000.73, -00001.22, 00000.24 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006938 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 01000.00 ], Gyr [ -00000.73, 00001.34, -00001.04 ], Mag [ 00018.45, 00084.30, 00109.35 ]",
    "#0000006939 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00981.45 ], Gyr [ 00000.91, 00002.68, 00000.18 ], Mag [ 00018.45, 00084.30, 00109.35 ]",
    "#0000006940 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00988.28 ], Gyr [ 00002.68, 00000.55, -00002.32 ], Mag [ 00019.65, 00083.70, 00107.70 ]",
    "#0000006941 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00992.68 ], Gyr [ -00000.49, -00001.89, -00001.71 ], Mag [ 00019.65, 00083.70, 00107.70 ]",
    "#0000006942 NoMotion-1. Scaled. Acc [ -00040.53, -00016.11, 00986.33 ], Gyr [ 00000.43, 00002.20, -00001.28 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000006943 NoMotion-1. Scaled. Acc [ -00050.78, -00006.35, 00991.21 ], Gyr [ 00000.61, 00003.17, -00000.61 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000006944 NoMotion-1. Scaled. Acc [ -00036.62, -00020.51, 00990.23 ], Gyr [ 00002.32, 00002.50, -00001.22 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006945 NoMotion-1. Scaled. Acc [ -00048.83, -00011.72, 00995.61 ], Gyr [ 00000.30, 00002.38, -00002.80 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006946 NoMotion-1. Scaled. Acc [ -00039.55, -00007.81, 00978.52 ], Gyr [ 00000.06, 00000.55, -00001.71 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006947 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00986.82 ], Gyr [ 00000.43, 00001.04, -00002.26 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006948 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00989.26 ], Gyr [ 00001.34, 00001.65, -00000.12 ], Mag [ 00019.65, 00084.45, 00109.80 ]",
    "#0000006949 NoMotion-1. Scaled. Acc [ -00049.32, -00020.51, 01006.84 ], Gyr [ 00001.46, 00001.77, -00002.38 ], Mag [ 00019.65, 00084.45, 00109.80 ]",
    "#0000006950 NoMotion-1. Scaled. Acc [ -00035.64, -00014.16, 01003.42 ], Gyr [ -00000.06, 00001.40, 00000.30 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000006951 NoMotion-1. Scaled. Acc [ -00045.41, -00005.86, 00983.89 ], Gyr [ 00000.85, 00001.52, -00000.67 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000006952 NoMotion-1. Scaled. Acc [ -00039.55, -00009.28, 00993.16 ], Gyr [ -00001.59, -00000.12, -00000.49 ], Mag [ 00020.10, 00083.40, 00106.95 ]",
    "#0000006953 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00980.96 ], Gyr [ 00001.34, 00003.11, -00001.10 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006954 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00989.26 ], Gyr [ 00001.22, 00000.73, -00001.40 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006955 NoMotion-1. Scaled. Acc [ -00044.43, -00003.91, 00985.35 ], Gyr [ 00002.38, 00001.71, -00002.93 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006956 NoMotion-1. Scaled. Acc [ -00042.48, 00000.49, 00987.79 ], Gyr [ 00000.79, 00002.68, -00002.26 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006957 NoMotion-1. Scaled. Acc [ -00033.69, -00004.39, 00991.70 ], Gyr [ 00000.67, -00000.61, -00002.01 ], Mag [ 00017.70, 00084.75, 00108.30 ]",
    "#0000006958 NoMotion-1. Scaled. Acc [ -00043.46, 00001.95, 00978.52 ], Gyr [ 00002.07, -00000.43, 00000.49 ], Mag [ 00017.70, 00084.75, 00108.30 ]",
    "#0000006959 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00995.12 ], Gyr [ -00001.04, 00001.46, -00000.06 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000006960 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00990.23 ], Gyr [ -00000.18, 00001.77, -00002.01 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000006961 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00991.21 ], Gyr [ 00002.38, -00001.59, -00001.89 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000006962 NoMotion-1. Scaled. Acc [ -00032.23, -00019.53, 00987.79 ], Gyr [ 00000.00, 00001.46, -00000.67 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000006963 NoMotion-1. Scaled. Acc [ -00047.36, -00017.58, 00985.84 ], Gyr [ 00002.13, 00002.07, -00001.22 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006964 NoMotion-1. Scaled. Acc [ -00049.80, -00006.35, 00980.96 ], Gyr [ -00001.04, 00000.00, 00000.30 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006965 NoMotion-1. Scaled. Acc [ -00028.32, 00000.49, 00990.72 ], Gyr [ -00000.06, 00001.22, -00001.95 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006966 NoMotion-1. Scaled. Acc [ -00043.46, 00000.00, 00987.79 ], Gyr [ 00001.59, -00000.06, -00001.52 ], Mag [ 00018.75, 00084.75, 00106.50 ]",
    "#0000006967 NoMotion-1. Scaled. Acc [ -00054.69, -00014.16, 00995.12 ], Gyr [ 00000.55, 00001.40, -00002.68 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000006968 NoMotion-1. Scaled. Acc [ -00047.85, -00011.72, 00989.26 ], Gyr [ -00001.28, -00000.12, -00003.48 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000006969 NoMotion-1. Scaled. Acc [ -00038.57, -00001.95, 00986.33 ], Gyr [ 00001.22, 00000.12, -00002.99 ], Mag [ 00019.95, 00084.75, 00107.55 ]",
    "#0000006970 NoMotion-1. Scaled. Acc [ -00051.27, -00001.95, 00992.68 ], Gyr [ 00001.59, 00000.61, 00000.55 ], Mag [ 00019.95, 00084.75, 00107.55 ]",
    "#0000006971 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00998.54 ], Gyr [ 00000.00, 00001.10, -00001.04 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006972 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00991.21 ], Gyr [ 00001.04, 00000.43, 00000.30 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006973 NoMotion-1. Scaled. Acc [ -00051.27, -00001.46, 00986.33 ], Gyr [ -00000.30, -00000.24, -00001.16 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006974 NoMotion-1. Scaled. Acc [ -00036.62, -00008.79, 00995.12 ], Gyr [ 00000.61, -00001.71, -00001.59 ], Mag [ 00021.00, 00084.75, 00107.55 ]",
    "#0000006975 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00989.26 ], Gyr [ -00000.61, 00000.06, 00000.00 ], Mag [ 00021.00, 00084.75, 00107.55 ]",
    "#0000006976 NoMotion-1. Scaled. Acc [ -00048.34, -00003.91, 00998.05 ], Gyr [ -00000.06, 00001.46, -00003.35 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000006977 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00986.33 ], Gyr [ 00000.18, 00001.95, -00002.80 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000006978 NoMotion-1. Scaled. Acc [ -00034.67, -00003.91, 00996.58 ], Gyr [ 00000.06, 00001.95, -00000.18 ], Mag [ 00020.25, 00084.30, 00107.10 ]",
    "#0000006979 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00995.61 ], Gyr [ -00001.71, 00001.59, -00002.38 ], Mag [ 00020.25, 00084.30, 00107.10 ]",
    "#0000006980 NoMotion-1. Scaled. Acc [ -00059.57, 00000.49, 00984.38 ], Gyr [ 00000.43, 00002.44, -00002.99 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006981 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00976.07 ], Gyr [ -00000.30, 00001.89, -00004.76 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006982 NoMotion-1. Scaled. Acc [ -00041.02, -00010.25, 00981.45 ], Gyr [ 00002.01, -00000.43, -00000.98 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006983 NoMotion-1. Scaled. Acc [ -00054.69, -00016.11, 00992.19 ], Gyr [ 00000.12, 00000.37, -00000.73 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006984 NoMotion-1. Scaled. Acc [ -00036.62, -00020.02, 00979.00 ], Gyr [ -00000.49, -00000.24, -00004.82 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000006985 NoMotion-1. Scaled. Acc [ -00035.16, -00008.79, 00985.35 ], Gyr [ 00000.37, 00000.37, 00000.06 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000006986 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00990.72 ], Gyr [ -00000.12, -00000.61, -00002.68 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000006987 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00991.70 ], Gyr [ 00001.46, 00002.44, -00001.52 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000006988 NoMotion-1. Scaled. Acc [ -00041.02, 00003.42, 00991.70 ], Gyr [ -00000.43, -00000.30, -00000.37 ], Mag [ 00020.70, 00083.55, 00107.10 ]",
    "#0000006989 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 00979.00 ], Gyr [ -00001.34, 00003.23, -00003.78 ], Mag [ 00020.70, 00083.55, 00107.10 ]",
    "#0000006990 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00997.56 ], Gyr [ -00000.18, 00001.83, -00000.18 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006991 NoMotion-1. Scaled. Acc [ -00040.53, -00000.98, 00987.79 ], Gyr [ 00001.16, -00000.24, -00001.77 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006992 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00983.40 ], Gyr [ 00001.28, 00002.62, -00001.34 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006993 NoMotion-1. Scaled. Acc [ -00048.34, -00008.79, 00983.40 ], Gyr [ 00001.04, 00002.44, -00000.73 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000006994 NoMotion-1. Scaled. Acc [ -00041.02, -00006.35, 00987.30 ], Gyr [ -00000.43, 00001.40, -00002.68 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000006995 NoMotion-1. Scaled. Acc [ -00052.25, -00000.98, 00997.56 ], Gyr [ -00000.24, 00001.40, -00002.74 ], Mag [ 00019.35, 00084.15, 00105.90 ]",
    "#0000006996 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00985.84 ], Gyr [ 00001.95, -00000.37, -00000.73 ], Mag [ 00019.35, 00084.15, 00105.90 ]",
    "#0000006997 NoMotion-1. Scaled. Acc [ -00048.34, -00006.35, 00992.68 ], Gyr [ 00000.79, 00000.12, -00001.40 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000006998 NoMotion-1. Scaled. Acc [ -00046.39, -00003.91, 00995.12 ], Gyr [ 00000.85, -00001.10, -00003.17 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000006999 NoMotion-1. Scaled. Acc [ -00038.57, -00022.46, 00998.05 ], Gyr [ -00000.24, 00000.00, 00000.18 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007000 NoMotion-1. Scaled. Acc [ -00048.83, -00012.70, 00988.77 ], Gyr [ 00001.04, 00000.79, -00001.28 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007001 NoMotion-1. Scaled. Acc [ -00059.08, -00010.74, 00991.21 ], Gyr [ 00001.16, -00000.49, -00004.27 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007002 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00988.77 ], Gyr [ 00000.49, 00001.40, -00001.34 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007003 NoMotion-1. Scaled. Acc [ -00040.53, -00010.74, 00983.89 ], Gyr [ 00000.55, 00000.98, -00002.38 ], Mag [ 00021.75, 00084.75, 00108.60 ]",
    "#0000007004 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00992.68 ], Gyr [ 00001.46, 00000.91, -00000.37 ], Mag [ 00021.75, 00084.75, 00108.60 ]",
    "#0000007005 NoMotion-1. Scaled. Acc [ -00045.41, -00011.23, 00981.45 ], Gyr [ 00001.28, -00000.98, 00001.28 ], Mag [ 00019.05, 00082.95, 00107.40 ]",
    "#0000007006 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00991.70 ], Gyr [ -00000.85, 00001.95, -00000.18 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000007007 NoMotion-1. Scaled. Acc [ -00037.60, -00021.00, 00987.30 ], Gyr [ -00002.20, 00004.27, -00004.39 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000007008 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 00993.65 ], Gyr [ 00001.40, 00002.44, -00001.65 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007009 NoMotion-1. Scaled. Acc [ -00059.08, -00000.49, 00994.14 ], Gyr [ 00000.37, 00001.04, -00001.16 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007010 NoMotion-1. Scaled. Acc [ -00057.13, -00012.70, 00984.38 ], Gyr [ 00001.04, -00000.37, 00000.30 ], Mag [ 00019.35, 00082.65, 00106.20 ]",
    "#0000007011 NoMotion-1. Scaled. Acc [ -00033.69, 00006.35, 00988.28 ], Gyr [ 00001.59, -00001.83, -00003.66 ], Mag [ 00019.35, 00082.65, 00106.20 ]",
    "#0000007012 NoMotion-1. Scaled. Acc [ -00041.99, -00007.32, 00984.86 ], Gyr [ -00001.16, 00003.54, -00002.01 ], Mag [ 00018.15, 00084.00, 00107.55 ]",
    "#0000007013 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00996.58 ], Gyr [ -00001.89, 00001.34, 00000.37 ], Mag [ 00018.15, 00084.00, 00107.55 ]",
    "#0000007014 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00995.12 ], Gyr [ 00000.91, 00002.80, -00001.89 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000007015 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.77 ], Gyr [ 00000.37, 00001.34, -00002.68 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000007016 NoMotion-1. Scaled. Acc [ -00046.88, -00012.21, 00985.35 ], Gyr [ 00000.18, 00000.85, 00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007017 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 01000.49 ], Gyr [ 00002.80, -00000.91, -00003.60 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007018 NoMotion-1. Scaled. Acc [ -00036.62, -00005.86, 01000.98 ], Gyr [ 00002.07, -00001.34, -00000.79 ], Mag [ 00018.75, 00084.30, 00106.80 ]",
    "#0000007019 NoMotion-1. Scaled. Acc [ -00040.53, -00008.79, 00986.82 ], Gyr [ 00001.40, 00000.55, 00000.18 ], Mag [ 00018.75, 00084.30, 00106.80 ]",
    "#0000007020 NoMotion-1. Scaled. Acc [ -00053.22, -00012.70, 00999.02 ], Gyr [ 00001.71, 00004.33, -00001.16 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007021 NoMotion-1. Scaled. Acc [ -00038.57, -00026.86, 00987.30 ], Gyr [ 00001.04, 00000.00, 00000.79 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007022 NoMotion-1. Scaled. Acc [ -00032.23, 00000.00, 00991.70 ], Gyr [ 00000.73, -00001.10, -00001.89 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007023 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00984.38 ], Gyr [ 00000.49, 00001.40, -00001.40 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007024 NoMotion-1. Scaled. Acc [ -00036.62, 00000.98, 00989.26 ], Gyr [ 00001.46, 00004.51, -00001.34 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007025 NoMotion-1. Scaled. Acc [ -00036.62, -00007.32, 00993.16 ], Gyr [ 00001.04, 00001.83, 00000.43 ], Mag [ 00021.15, 00083.70, 00107.70 ]",
    "#0000007026 NoMotion-1. Scaled. Acc [ -00042.48, 00009.77, 00989.75 ], Gyr [ -00000.06, 00001.89, -00002.56 ], Mag [ 00021.15, 00083.70, 00107.70 ]",
    "#0000007027 NoMotion-1. Scaled. Acc [ -00050.78, 00000.49, 00999.51 ], Gyr [ -00000.98, -00000.49, -00002.07 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000007028 NoMotion-1. Scaled. Acc [ -00041.99, -00002.93, 01000.98 ], Gyr [ -00000.18, 00004.70, 00000.37 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000007029 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 01000.49 ], Gyr [ 00001.95, 00001.65, -00000.79 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007030 NoMotion-1. Scaled. Acc [ -00038.09, 00000.00, 00993.16 ], Gyr [ -00000.85, 00000.30, -00000.85 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007031 NoMotion-1. Scaled. Acc [ -00049.32, 00000.00, 00990.23 ], Gyr [ -00001.04, -00000.49, -00003.23 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007032 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00987.79 ], Gyr [ 00000.85, 00000.00, -00000.73 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007033 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 00990.23 ], Gyr [ 00000.91, -00000.73, -00000.06 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007034 NoMotion-1. Scaled. Acc [ -00042.48, -00016.60, 00999.51 ], Gyr [ 00002.56, 00000.91, -00000.18 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007035 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 00987.79 ], Gyr [ 00001.89, 00002.93, -00003.90 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007036 NoMotion-1. Scaled. Acc [ -00041.50, -00016.11, 00993.16 ], Gyr [ -00000.12, 00000.37, 00000.91 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007037 NoMotion-1. Scaled. Acc [ -00050.29, -00006.84, 00991.70 ], Gyr [ 00002.44, 00000.30, -00003.11 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007038 NoMotion-1. Scaled. Acc [ -00037.11, -00014.16, 00982.91 ], Gyr [ 00001.16, 00002.38, -00000.12 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007039 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00988.28 ], Gyr [ -00001.28, 00002.07, -00002.26 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007040 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00988.28 ], Gyr [ -00000.43, 00000.18, -00000.67 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007041 NoMotion-1. Scaled. Acc [ -00048.83, 00007.32, 01000.49 ], Gyr [ 00001.04, 00000.91, -00001.34 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007042 NoMotion-1. Scaled. Acc [ -00047.85, 00002.93, 00991.70 ], Gyr [ 00000.73, 00002.32, -00002.13 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007043 NoMotion-1. Scaled. Acc [ -00042.48, -00007.81, 01000.49 ], Gyr [ -00001.40, 00001.83, -00002.07 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000007044 NoMotion-1. Scaled. Acc [ -00052.73, 00002.44, 00985.84 ], Gyr [ -00000.24, 00002.87, -00004.39 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000007045 NoMotion-1. Scaled. Acc [ -00044.92, -00015.14, 00993.16 ], Gyr [ 00001.16, -00000.37, -00001.34 ], Mag [ 00018.45, 00083.25, 00107.55 ]",
    "#0000007046 NoMotion-1. Scaled. Acc [ -00037.11, -00003.91, 00988.28 ], Gyr [ 00000.91, 00002.32, -00001.77 ], Mag [ 00018.45, 00083.25, 00107.55 ]",
    "#0000007047 NoMotion-1. Scaled. Acc [ -00047.85, 00002.44, 00999.51 ], Gyr [ 00000.18, 00001.83, -00003.05 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007048 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00987.30 ], Gyr [ -00000.73, 00002.07, -00001.40 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007049 NoMotion-1. Scaled. Acc [ -00045.90, -00005.86, 00994.14 ], Gyr [ -00000.06, 00001.89, -00000.85 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007050 NoMotion-1. Scaled. Acc [ -00036.62, -00007.32, 00990.72 ], Gyr [ 00000.00, 00000.37, 00000.30 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007051 NoMotion-1. Scaled. Acc [ -00053.22, 00008.30, 01000.98 ], Gyr [ 00000.55, 00002.68, -00000.43 ], Mag [ 00018.45, 00083.55, 00107.85 ]",
    "#0000007052 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00985.35 ], Gyr [ -00000.67, 00000.73, -00000.12 ], Mag [ 00018.45, 00083.55, 00107.85 ]",
    "#0000007053 NoMotion-1. Scaled. Acc [ -00041.99, -00001.95, 01003.42 ], Gyr [ -00000.55, 00001.10, -00001.95 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000007054 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00987.30 ], Gyr [ -00000.06, 00001.10, -00000.06 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000007055 NoMotion-1. Scaled. Acc [ -00043.46, -00013.18, 00996.58 ], Gyr [ 00001.89, 00001.28, -00001.04 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000007056 NoMotion-1. Scaled. Acc [ -00043.46, -00001.46, 00985.35 ], Gyr [ 00001.52, 00000.55, -00003.41 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000007057 NoMotion-1. Scaled. Acc [ -00052.25, -00008.30, 00990.23 ], Gyr [ 00000.91, -00000.67, 00001.16 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007058 NoMotion-1. Scaled. Acc [ -00051.27, -00004.88, 00984.38 ], Gyr [ 00000.24, 00000.55, 00000.91 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007059 NoMotion-1. Scaled. Acc [ -00037.60, -00011.72, 00988.77 ], Gyr [ 00000.30, 00001.28, -00001.40 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007060 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00994.14 ], Gyr [ -00000.06, 00001.95, -00000.43 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007061 NoMotion-1. Scaled. Acc [ -00048.34, -00018.07, 00991.70 ], Gyr [ 00003.05, 00002.87, -00000.91 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000007062 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00986.82 ], Gyr [ 00000.37, 00002.32, -00001.52 ], Mag [ 00019.95, 00083.55, 00108.60 ]",
    "#0000007063 NoMotion-1. Scaled. Acc [ -00032.23, -00011.72, 00993.16 ], Gyr [ 00000.55, 00002.50, -00000.06 ], Mag [ 00019.95, 00083.55, 00108.60 ]",
    "#0000007064 NoMotion-1. Scaled. Acc [ -00054.20, -00021.97, 00986.82 ], Gyr [ 00000.30, 00000.79, -00002.13 ], Mag [ 00020.85, 00081.15, 00108.00 ]",
    "#0000007065 NoMotion-1. Scaled. Acc [ -00057.13, -00002.93, 00991.21 ], Gyr [ -00000.67, 00001.83, -00001.22 ], Mag [ 00020.85, 00081.15, 00108.00 ]",
    "#0000007066 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00991.21 ], Gyr [ 00000.12, 00000.85, -00002.26 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007067 NoMotion-1. Scaled. Acc [ -00046.39, -00000.98, 00998.54 ], Gyr [ 00000.12, 00002.93, -00001.40 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007068 NoMotion-1. Scaled. Acc [ -00038.57, -00006.35, 00998.54 ], Gyr [ 00000.73, 00000.85, -00000.43 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007069 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00997.56 ], Gyr [ 00000.18, 00003.29, -00002.68 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007070 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00998.05 ], Gyr [ 00000.79, 00003.78, -00000.06 ], Mag [ 00020.70, 00083.55, 00107.55 ]",
    "#0000007071 NoMotion-1. Scaled. Acc [ -00039.06, -00012.21, 00989.75 ], Gyr [ 00000.43, 00000.73, 00000.00 ], Mag [ 00020.70, 00083.55, 00107.55 ]",
    "#0000007072 NoMotion-1. Scaled. Acc [ -00050.78, -00012.70, 00984.38 ], Gyr [ 00000.91, 00003.17, 00001.34 ], Mag [ 00018.60, 00082.20, 00108.00 ]",
    "#0000007073 NoMotion-1. Scaled. Acc [ -00045.41, -00007.81, 00989.75 ], Gyr [ 00000.30, 00001.89, -00002.20 ], Mag [ 00018.60, 00082.20, 00108.00 ]",
    "#0000007074 NoMotion-1. Scaled. Acc [ -00043.46, -00024.90, 01000.00 ], Gyr [ 00001.40, 00000.67, -00002.32 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007075 NoMotion-1. Scaled. Acc [ -00031.74, 00000.98, 00987.79 ], Gyr [ 00000.24, -00000.85, 00000.91 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007076 NoMotion-1. Scaled. Acc [ -00041.50, -00008.30, 00991.70 ], Gyr [ -00000.55, 00002.50, -00001.46 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007077 NoMotion-1. Scaled. Acc [ -00035.16, 00001.46, 00995.61 ], Gyr [ 00002.68, 00002.50, -00000.55 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007078 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00989.26 ], Gyr [ 00000.43, 00002.68, -00003.84 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007079 NoMotion-1. Scaled. Acc [ -00039.06, -00002.93, 00988.77 ], Gyr [ -00000.37, 00000.61, -00000.91 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007080 NoMotion-1. Scaled. Acc [ -00048.83, -00008.30, 00999.51 ], Gyr [ 00000.43, 00001.22, -00000.55 ], Mag [ 00018.60, 00082.65, 00108.00 ]",
    "#0000007081 NoMotion-1. Scaled. Acc [ -00048.83, -00006.84, 00993.65 ], Gyr [ 00000.00, 00000.00, -00001.52 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007082 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00989.26 ], Gyr [ -00001.77, 00001.40, -00002.62 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007083 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00997.07 ], Gyr [ 00000.73, 00001.16, -00000.55 ], Mag [ 00018.60, 00084.15, 00108.00 ]",
    "#0000007084 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00984.86 ], Gyr [ -00000.98, 00000.43, -00003.29 ], Mag [ 00018.60, 00084.15, 00108.00 ]",
    "#0000007085 NoMotion-1. Scaled. Acc [ -00046.88, -00007.81, 00988.28 ], Gyr [ 00001.46, 00000.85, -00000.73 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007086 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00987.30 ], Gyr [ -00001.10, 00001.59, 00000.00 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007087 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00990.23 ], Gyr [ 00000.18, 00000.06, 00000.00 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007088 NoMotion-1. Scaled. Acc [ -00033.69, -00024.90, 00988.77 ], Gyr [ 00000.06, 00001.40, -00002.80 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007089 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 00984.86 ], Gyr [ 00000.79, 00004.02, -00001.46 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007090 NoMotion-1. Scaled. Acc [ -00044.43, -00009.28, 01004.88 ], Gyr [ 00000.00, 00001.10, -00001.28 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007091 NoMotion-1. Scaled. Acc [ -00052.25, -00007.32, 00990.72 ], Gyr [ 00001.83, 00001.16, -00001.95 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007092 NoMotion-1. Scaled. Acc [ -00041.50, -00010.74, 00995.61 ], Gyr [ -00001.10, 00001.71, -00000.06 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007093 NoMotion-1. Scaled. Acc [ -00041.02, -00008.30, 00983.40 ], Gyr [ 00000.49, 00005.37, 00000.49 ], Mag [ 00019.95, 00084.30, 00106.50 ]",
    "#0000007094 NoMotion-1. Scaled. Acc [ -00044.43, -00012.70, 00992.19 ], Gyr [ -00000.12, 00003.72, -00002.80 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007095 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00993.16 ], Gyr [ 00002.01, 00000.79, -00000.67 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007096 NoMotion-1. Scaled. Acc [ -00038.57, -00020.02, 00986.82 ], Gyr [ 00001.46, 00000.00, -00000.73 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007097 NoMotion-1. Scaled. Acc [ -00049.80, -00004.39, 00996.58 ], Gyr [ -00000.30, 00001.95, -00002.44 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007098 NoMotion-1. Scaled. Acc [ -00039.06, -00014.16, 00995.61 ], Gyr [ 00000.73, 00002.99, -00000.98 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007099 NoMotion-1. Scaled. Acc [ -00051.76, -00002.44, 00990.72 ], Gyr [ 00000.67, 00002.32, -00000.37 ], Mag [ 00018.30, 00083.70, 00106.95 ]",
    "#0000007100 NoMotion-1. Scaled. Acc [ -00051.27, -00000.98, 00984.86 ], Gyr [ 00001.22, 00000.85, -00002.44 ], Mag [ 00018.30, 00083.70, 00106.95 ]",
    "#0000007101 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00995.12 ], Gyr [ 00001.59, 00000.98, -00001.65 ], Mag [ 00018.60, 00083.40, 00105.90 ]",
    "#0000007102 NoMotion-1. Scaled. Acc [ -00032.71, -00000.98, 00986.82 ], Gyr [ -00000.91, 00002.20, -00002.32 ], Mag [ 00018.60, 00083.40, 00105.90 ]",
    "#0000007103 NoMotion-1. Scaled. Acc [ -00054.20, -00009.77, 00982.91 ], Gyr [ 00000.67, -00000.49, -00001.34 ], Mag [ 00021.15, 00084.15, 00108.00 ]",
    "#0000007104 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00992.19 ], Gyr [ 00000.24, 00003.35, -00002.87 ], Mag [ 00021.15, 00084.15, 00108.00 ]",
    "#0000007105 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00985.84 ], Gyr [ -00000.18, 00000.37, -00002.93 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007106 NoMotion-1. Scaled. Acc [ -00045.41, -00012.21, 00991.70 ], Gyr [ -00000.98, 00001.77, -00001.34 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007107 NoMotion-1. Scaled. Acc [ -00046.88, -00002.93, 00987.30 ], Gyr [ -00000.18, 00001.77, 00000.79 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007108 NoMotion-1. Scaled. Acc [ -00041.50, -00005.37, 00988.77 ], Gyr [ 00000.79, 00001.16, -00000.91 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007109 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00988.28 ], Gyr [ -00001.89, 00001.95, -00003.05 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007110 NoMotion-1. Scaled. Acc [ -00037.60, -00012.21, 00998.05 ], Gyr [ 00002.01, -00000.85, -00000.49 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007111 NoMotion-1. Scaled. Acc [ -00046.39, -00011.72, 01001.95 ], Gyr [ 00000.91, -00001.16, 00000.24 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007112 NoMotion-1. Scaled. Acc [ -00043.46, -00009.28, 00985.35 ], Gyr [ 00001.71, 00001.10, -00002.20 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007113 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00994.14 ], Gyr [ -00000.67, -00001.22, -00000.43 ], Mag [ 00018.75, 00083.55, 00105.75 ]",
    "#0000007114 NoMotion-1. Scaled. Acc [ -00032.71, -00006.35, 00988.28 ], Gyr [ 00001.10, 00002.93, -00002.32 ], Mag [ 00018.75, 00083.55, 00105.75 ]",
    "#0000007115 NoMotion-1. Scaled. Acc [ -00029.30, -00021.48, 00977.05 ], Gyr [ 00001.59, -00001.46, -00001.59 ], Mag [ 00020.40, 00085.20, 00106.65 ]",
    "#0000007116 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00990.72 ], Gyr [ 00000.43, 00002.68, 00000.61 ], Mag [ 00020.40, 00085.20, 00106.65 ]",
    "#0000007117 NoMotion-1. Scaled. Acc [ -00035.64, -00011.23, 00985.35 ], Gyr [ -00000.24, 00002.68, -00002.93 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000007118 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00982.42 ], Gyr [ 00000.85, -00000.67, -00001.59 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000007119 NoMotion-1. Scaled. Acc [ -00049.32, -00003.42, 00986.82 ], Gyr [ 00000.91, -00000.43, -00001.10 ], Mag [ 00020.10, 00084.45, 00106.65 ]",
    "#0000007120 NoMotion-1. Scaled. Acc [ -00045.90, -00017.09, 00995.61 ], Gyr [ -00000.06, 00002.01, -00002.20 ], Mag [ 00020.10, 00084.45, 00106.65 ]",
    "#0000007121 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00999.51 ], Gyr [ -00001.22, -00000.91, -00000.37 ], Mag [ 00020.85, 00082.95, 00107.40 ]",
    "#0000007122 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00986.82 ], Gyr [ 00002.74, 00000.91, -00001.59 ], Mag [ 00020.85, 00082.95, 00107.40 ]",
    "#0000007123 NoMotion-1. Scaled. Acc [ -00047.85, -00010.74, 00998.05 ], Gyr [ -00000.30, 00000.55, 00000.30 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007124 NoMotion-1. Scaled. Acc [ -00048.83, -00017.58, 00998.54 ], Gyr [ 00001.89, 00000.00, 00001.46 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007125 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 01006.84 ], Gyr [ -00001.52, 00002.01, -00002.93 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007126 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 01000.49 ], Gyr [ 00001.34, 00000.37, -00001.04 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007127 NoMotion-1. Scaled. Acc [ -00036.62, -00008.79, 00991.21 ], Gyr [ 00000.30, 00000.55, -00001.40 ], Mag [ 00020.10, 00085.20, 00108.00 ]",
    "#0000007128 NoMotion-1. Scaled. Acc [ -00040.04, -00013.18, 00991.21 ], Gyr [ -00000.49, 00001.71, -00000.55 ], Mag [ 00020.10, 00085.20, 00108.00 ]",
    "#0000007129 NoMotion-1. Scaled. Acc [ -00035.64, -00006.84, 00987.79 ], Gyr [ 00000.06, 00000.06, -00002.20 ], Mag [ 00018.60, 00083.40, 00109.05 ]",
    "#0000007130 NoMotion-1. Scaled. Acc [ -00030.27, 00000.98, 00990.72 ], Gyr [ 00001.40, 00000.55, -00002.13 ], Mag [ 00018.60, 00083.40, 00109.05 ]",
    "#0000007131 NoMotion-1. Scaled. Acc [ -00038.09, -00022.46, 00991.21 ], Gyr [ 00001.52, 00003.05, -00002.38 ], Mag [ 00020.10, 00085.20, 00108.45 ]",
    "#0000007132 NoMotion-1. Scaled. Acc [ -00040.53, -00002.44, 00990.72 ], Gyr [ 00000.85, 00001.65, -00002.38 ], Mag [ 00020.10, 00085.20, 00108.45 ]",
    "#0000007133 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.28 ], Gyr [ -00000.91, 00002.01, 00000.12 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007134 NoMotion-1. Scaled. Acc [ -00048.34, 00001.46, 01001.46 ], Gyr [ 00001.16, 00001.22, -00002.68 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007135 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00993.16 ], Gyr [ 00002.26, 00002.87, -00000.55 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007136 NoMotion-1. Scaled. Acc [ -00047.85, -00017.58, 00989.75 ], Gyr [ -00000.06, -00001.34, -00000.91 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007137 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 00988.77 ], Gyr [ 00002.01, 00001.65, -00000.98 ], Mag [ 00020.70, 00084.30, 00106.80 ]",
    "#0000007138 NoMotion-1. Scaled. Acc [ -00047.85, -00015.62, 00991.21 ], Gyr [ 00002.20, 00000.98, -00002.44 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007139 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 01001.95 ], Gyr [ -00001.16, 00000.55, -00002.50 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007140 NoMotion-1. Scaled. Acc [ -00040.53, -00003.91, 00990.23 ], Gyr [ 00000.91, 00000.98, -00001.65 ], Mag [ 00018.75, 00085.80, 00108.30 ]",
    "#0000007141 NoMotion-1. Scaled. Acc [ -00043.46, -00005.86, 00996.09 ], Gyr [ 00000.91, 00002.62, -00002.44 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007142 NoMotion-1. Scaled. Acc [ -00055.18, -00003.91, 00994.14 ], Gyr [ 00000.98, 00000.98, 00000.24 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007143 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00994.14 ], Gyr [ 00000.91, -00001.04, -00001.34 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007144 NoMotion-1. Scaled. Acc [ -00045.41, -00012.70, 00995.61 ], Gyr [ -00000.67, 00001.34, -00002.01 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007145 NoMotion-1. Scaled. Acc [ -00052.25, -00005.86, 00986.33 ], Gyr [ 00000.79, 00001.95, -00000.61 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007146 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 00981.45 ], Gyr [ -00000.06, 00000.43, -00000.55 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007147 NoMotion-1. Scaled. Acc [ -00045.41, -00009.28, 00983.89 ], Gyr [ 00002.87, 00001.34, -00003.05 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007148 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 01006.35 ], Gyr [ 00001.59, 00000.49, 00000.43 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007149 NoMotion-1. Scaled. Acc [ -00038.57, -00020.02, 00985.84 ], Gyr [ 00000.12, -00001.83, 00000.85 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007150 NoMotion-1. Scaled. Acc [ -00038.57, 00000.98, 00980.47 ], Gyr [ -00001.28, 00000.79, -00000.79 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007151 NoMotion-1. Scaled. Acc [ -00055.18, -00007.32, 00992.19 ], Gyr [ 00000.06, 00000.61, -00002.93 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007152 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 01000.00 ], Gyr [ 00000.18, 00003.96, -00002.07 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007153 NoMotion-1. Scaled. Acc [ -00049.32, -00008.30, 00989.75 ], Gyr [ 00002.01, 00000.43, -00001.10 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000007154 NoMotion-1. Scaled. Acc [ -00050.29, -00013.18, 00989.75 ], Gyr [ -00000.18, 00001.04, -00002.68 ], Mag [ 00019.05, 00085.20, 00107.40 ]",
    "#0000007155 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00988.77 ], Gyr [ 00001.04, 00000.79, -00002.13 ], Mag [ 00019.05, 00085.20, 00107.40 ]",
    "#0000007156 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 01000.00 ], Gyr [ 00001.16, 00001.89, 00000.85 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000007157 NoMotion-1. Scaled. Acc [ -00043.95, 00010.74, 00991.21 ], Gyr [ 00000.30, 00001.10, -00002.38 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000007158 NoMotion-1. Scaled. Acc [ -00045.90, -00002.44, 00995.12 ], Gyr [ -00001.65, 00002.13, -00001.52 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007159 NoMotion-1. Scaled. Acc [ -00029.30, -00009.28, 00986.82 ], Gyr [ 00000.30, 00005.06, -00002.07 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007160 NoMotion-1. Scaled. Acc [ -00037.60, -00016.11, 01002.93 ], Gyr [ 00001.22, -00000.18, -00001.89 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007161 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00992.68 ], Gyr [ 00001.04, 00003.66, -00000.18 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000007162 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00999.51 ], Gyr [ 00000.06, 00001.77, -00000.43 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000007163 NoMotion-1. Scaled. Acc [ -00046.39, 00000.98, 00984.38 ], Gyr [ -00001.71, 00001.16, -00000.73 ], Mag [ 00019.35, 00085.65, 00108.45 ]",
    "#0000007164 NoMotion-1. Scaled. Acc [ -00050.29, -00011.23, 00989.26 ], Gyr [ 00000.55, 00002.56, -00001.71 ], Mag [ 00019.35, 00085.65, 00108.45 ]",
    "#0000007165 NoMotion-1. Scaled. Acc [ -00029.30, -00013.18, 00993.65 ], Gyr [ 00001.04, 00001.46, -00003.48 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000007166 NoMotion-1. Scaled. Acc [ -00034.67, 00004.88, 00987.30 ], Gyr [ -00000.24, -00000.98, -00001.10 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000007167 NoMotion-1. Scaled. Acc [ -00037.60, -00001.46, 00992.19 ], Gyr [ -00000.73, 00001.04, -00000.30 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007168 NoMotion-1. Scaled. Acc [ -00040.53, -00007.32, 00991.70 ], Gyr [ -00000.85, 00001.77, -00001.22 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007169 NoMotion-1. Scaled. Acc [ -00052.73, -00017.58, 00990.72 ], Gyr [ 00000.12, 00001.52, -00001.16 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007170 NoMotion-1. Scaled. Acc [ -00042.97, -00006.84, 00989.26 ], Gyr [ 00001.04, 00000.49, 00000.12 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007171 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00990.72 ], Gyr [ 00001.77, 00000.79, -00001.22 ], Mag [ 00018.60, 00082.65, 00107.70 ]",
    "#0000007172 NoMotion-1. Scaled. Acc [ -00051.76, -00006.35, 00993.16 ], Gyr [ -00000.24, 00003.66, 00000.00 ], Mag [ 00018.60, 00082.65, 00107.70 ]",
    "#0000007173 NoMotion-1. Scaled. Acc [ -00031.25, -00001.46, 00986.82 ], Gyr [ 00000.67, 00000.98, -00002.68 ], Mag [ 00019.35, 00082.65, 00107.70 ]",
    "#0000007174 NoMotion-1. Scaled. Acc [ -00037.11, -00002.44, 00996.58 ], Gyr [ -00001.52, 00002.26, -00000.79 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007175 NoMotion-1. Scaled. Acc [ -00041.02, 00002.44, 00994.63 ], Gyr [ -00001.10, 00005.79, -00003.48 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007176 NoMotion-1. Scaled. Acc [ -00047.85, -00003.42, 00988.28 ], Gyr [ 00000.98, 00002.74, -00002.62 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007177 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00981.93 ], Gyr [ 00002.26, 00002.20, -00001.34 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007178 NoMotion-1. Scaled. Acc [ -00035.64, -00012.70, 00985.84 ], Gyr [ -00000.91, 00003.96, -00003.78 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007179 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00990.72 ], Gyr [ 00001.77, 00001.10, -00000.79 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007180 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00998.05 ], Gyr [ 00000.06, 00002.32, -00001.77 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007181 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00992.68 ], Gyr [ 00000.00, -00001.40, -00000.18 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007182 NoMotion-1. Scaled. Acc [ -00036.13, -00009.28, 00979.98 ], Gyr [ 00000.73, 00000.55, -00000.12 ], Mag [ 00020.40, 00082.95, 00106.65 ]",
    "#0000007183 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00980.96 ], Gyr [ 00000.85, 00001.04, -00001.46 ], Mag [ 00020.40, 00082.95, 00106.65 ]",
    "#0000007184 NoMotion-1. Scaled. Acc [ -00045.90, -00015.14, 00989.75 ], Gyr [ 00002.99, 00001.52, -00002.99 ], Mag [ 00017.70, 00083.25, 00107.85 ]",
    "#0000007185 NoMotion-1. Scaled. Acc [ -00043.95, 00002.93, 00996.09 ], Gyr [ 00000.61, 00000.67, -00001.40 ], Mag [ 00017.70, 00083.25, 00107.85 ]",
    "#0000007186 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 00974.12 ], Gyr [ -00001.46, -00000.98, -00002.13 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007187 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 00983.89 ], Gyr [ -00000.85, 00000.30, -00003.17 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007188 NoMotion-1. Scaled. Acc [ -00047.85, -00004.88, 00982.91 ], Gyr [ 00000.06, -00000.91, -00001.28 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007189 NoMotion-1. Scaled. Acc [ -00030.76, -00001.46, 00995.61 ], Gyr [ 00001.40, -00000.12, -00000.49 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007190 NoMotion-1. Scaled. Acc [ -00040.53, -00020.02, 00991.70 ], Gyr [ -00000.67, 00001.34, -00001.71 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000007191 NoMotion-1. Scaled. Acc [ -00047.36, -00010.74, 00988.28 ], Gyr [ -00000.49, 00004.33, -00002.99 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000007192 NoMotion-1. Scaled. Acc [ -00045.41, -00003.91, 00989.26 ], Gyr [ -00000.49, 00000.43, -00000.55 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007193 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00983.89 ], Gyr [ 00001.28, 00003.84, -00004.09 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007194 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00993.16 ], Gyr [ -00000.85, 00002.07, -00003.17 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007195 NoMotion-1. Scaled. Acc [ -00042.97, -00010.74, 00996.09 ], Gyr [ 00000.98, 00001.83, -00001.59 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007196 NoMotion-1. Scaled. Acc [ -00040.53, 00002.44, 00997.56 ], Gyr [ 00001.04, 00001.52, -00001.71 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007197 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00987.30 ], Gyr [ 00000.55, 00000.30, -00002.20 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007198 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 00984.86 ], Gyr [ 00002.50, 00000.91, -00002.01 ], Mag [ 00018.45, 00083.55, 00106.05 ]",
    "#0000007199 NoMotion-1. Scaled. Acc [ -00034.18, -00012.70, 00981.45 ], Gyr [ 00000.73, 00006.59, -00001.83 ], Mag [ 00018.45, 00083.55, 00106.05 ]",
    "#0000007200 NoMotion-1. Scaled. Acc [ -00046.88, -00008.30, 00979.49 ], Gyr [ 00000.12, 00003.05, -00001.89 ], Mag [ 00019.95, 00084.00, 00106.80 ]",
    "#0000007201 NoMotion-1. Scaled. Acc [ -00046.88, -00015.62, 00984.86 ], Gyr [ 00000.61, 00000.98, -00002.87 ], Mag [ 00019.95, 00084.00, 00106.80 ]",
    "#0000007202 NoMotion-1. Scaled. Acc [ -00040.04, -00011.23, 01002.93 ], Gyr [ 00002.44, 00000.00, -00000.43 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000007203 NoMotion-1. Scaled. Acc [ -00048.83, -00015.14, 01006.84 ], Gyr [ 00000.06, 00002.93, -00003.23 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000007204 NoMotion-1. Scaled. Acc [ -00045.90, -00008.79, 00985.84 ], Gyr [ -00000.18, 00004.82, -00002.20 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007205 NoMotion-1. Scaled. Acc [ -00044.43, -00005.37, 00986.82 ], Gyr [ -00000.30, -00000.24, -00000.18 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007206 NoMotion-1. Scaled. Acc [ -00042.97, -00000.49, 00989.75 ], Gyr [ -00000.24, 00002.99, -00001.28 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007207 NoMotion-1. Scaled. Acc [ -00052.25, -00009.77, 00997.07 ], Gyr [ 00000.30, 00002.56, -00002.07 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007208 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00995.61 ], Gyr [ -00001.22, 00002.07, -00002.56 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007209 NoMotion-1. Scaled. Acc [ -00037.11, -00006.84, 00984.38 ], Gyr [ 00000.00, 00000.00, -00000.85 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007210 NoMotion-1. Scaled. Acc [ -00045.41, -00011.23, 00983.40 ], Gyr [ 00000.73, -00000.79, -00001.22 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007211 NoMotion-1. Scaled. Acc [ -00043.95, 00004.88, 00986.33 ], Gyr [ 00000.67, 00002.20, -00000.18 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000007212 NoMotion-1. Scaled. Acc [ -00039.55, -00014.65, 00988.77 ], Gyr [ 00001.40, 00000.30, 00000.06 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007213 NoMotion-1. Scaled. Acc [ -00029.79, -00002.44, 00991.70 ], Gyr [ -00001.34, 00002.44, -00001.04 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007214 NoMotion-1. Scaled. Acc [ -00038.57, -00006.84, 00998.54 ], Gyr [ -00000.24, 00001.28, -00001.16 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007215 NoMotion-1. Scaled. Acc [ -00038.09, -00021.48, 00994.63 ], Gyr [ -00001.46, 00000.37, -00002.80 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007216 NoMotion-1. Scaled. Acc [ -00045.90, -00012.21, 00990.72 ], Gyr [ 00001.71, 00003.05, -00003.23 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007217 NoMotion-1. Scaled. Acc [ -00045.41, -00004.39, 00989.26 ], Gyr [ 00000.73, 00002.20, -00000.79 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007218 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00994.63 ], Gyr [ 00000.98, 00000.98, -00003.23 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007219 NoMotion-1. Scaled. Acc [ -00050.29, -00002.44, 00997.56 ], Gyr [ 00000.98, 00003.17, -00002.20 ], Mag [ 00020.25, 00084.75, 00106.80 ]",
    "#0000007220 NoMotion-1. Scaled. Acc [ -00048.34, -00003.42, 00996.58 ], Gyr [ 00001.52, -00000.61, -00000.98 ], Mag [ 00020.25, 00084.75, 00106.80 ]",
    "#0000007221 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 01001.46 ], Gyr [ 00001.28, 00001.89, 00001.77 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007222 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00999.51 ], Gyr [ -00000.37, 00001.83, -00001.89 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007223 NoMotion-1. Scaled. Acc [ -00025.88, -00013.67, 00986.33 ], Gyr [ 00000.98, -00000.49, -00000.12 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007224 NoMotion-1. Scaled. Acc [ -00044.92, -00009.77, 00993.16 ], Gyr [ 00001.22, 00000.37, -00000.12 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007225 NoMotion-1. Scaled. Acc [ -00035.64, -00011.72, 00981.45 ], Gyr [ 00001.16, 00002.56, -00001.89 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007226 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00988.28 ], Gyr [ -00001.22, 00003.29, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007227 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00989.26 ], Gyr [ 00000.18, 00003.35, 00000.00 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000007228 NoMotion-1. Scaled. Acc [ -00052.25, -00013.67, 00993.16 ], Gyr [ -00000.18, 00003.84, -00000.37 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000007229 NoMotion-1. Scaled. Acc [ -00048.34, -00021.97, 00984.38 ], Gyr [ -00001.10, 00001.65, 00000.00 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007230 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00998.05 ], Gyr [ 00001.40, 00001.83, -00002.32 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007231 NoMotion-1. Scaled. Acc [ -00037.60, 00000.98, 00995.12 ], Gyr [ 00001.28, -00000.98, -00001.89 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007232 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00989.75 ], Gyr [ -00001.04, 00003.41, -00001.95 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007233 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 00984.38 ], Gyr [ 00000.18, 00002.68, -00002.44 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007234 NoMotion-1. Scaled. Acc [ -00036.62, -00009.77, 00990.72 ], Gyr [ 00002.44, -00002.07, 00000.79 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000007235 NoMotion-1. Scaled. Acc [ -00041.02, 00004.88, 00993.65 ], Gyr [ 00001.52, 00001.10, -00000.61 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000007236 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00986.33 ], Gyr [ -00003.23, 00002.38, -00002.80 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007237 NoMotion-1. Scaled. Acc [ -00044.92, 00003.91, 00994.63 ], Gyr [ -00000.18, 00001.89, -00000.12 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007238 NoMotion-1. Scaled. Acc [ -00031.74, -00003.42, 00988.28 ], Gyr [ 00000.49, 00000.73, -00001.46 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007239 NoMotion-1. Scaled. Acc [ -00049.80, -00015.14, 01000.49 ], Gyr [ 00000.55, 00000.85, -00003.35 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007240 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00984.38 ], Gyr [ -00001.28, 00001.34, -00000.37 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007241 NoMotion-1. Scaled. Acc [ -00050.29, -00003.42, 00979.49 ], Gyr [ -00000.98, 00003.78, -00001.59 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007242 NoMotion-1. Scaled. Acc [ -00037.11, -00000.49, 00995.12 ], Gyr [ -00002.80, 00001.65, -00003.23 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000007243 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00989.75 ], Gyr [ -00000.12, 00000.49, -00001.22 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000007244 NoMotion-1. Scaled. Acc [ -00051.27, -00010.25, 00986.82 ], Gyr [ 00000.49, -00000.91, -00001.46 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000007245 NoMotion-1. Scaled. Acc [ -00026.86, -00023.44, 00990.72 ], Gyr [ 00000.73, -00000.24, -00000.98 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007246 NoMotion-1. Scaled. Acc [ -00057.62, -00007.32, 00994.63 ], Gyr [ 00001.40, 00001.28, -00000.49 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007247 NoMotion-1. Scaled. Acc [ -00055.66, 00006.35, 00994.14 ], Gyr [ 00000.00, 00001.04, -00000.12 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007248 NoMotion-1. Scaled. Acc [ -00048.83, -00005.37, 00991.21 ], Gyr [ 00000.37, 00000.85, -00002.50 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007249 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00998.54 ], Gyr [ 00001.52, 00001.46, -00001.65 ], Mag [ 00020.40, 00082.65, 00108.45 ]",
    "#0000007250 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00987.30 ], Gyr [ -00001.46, 00002.62, -00004.02 ], Mag [ 00020.40, 00082.65, 00108.45 ]",
    "#0000007251 NoMotion-1. Scaled. Acc [ -00039.55, -00004.39, 00993.16 ], Gyr [ -00000.91, 00002.50, -00001.40 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007252 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 00985.84 ], Gyr [ 00002.13, 00000.73, -00003.23 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007253 NoMotion-1. Scaled. Acc [ -00032.71, 00007.32, 00992.68 ], Gyr [ -00000.06, 00000.55, -00000.91 ], Mag [ 00018.30, 00083.70, 00107.70 ]",
    "#0000007254 NoMotion-1. Scaled. Acc [ -00046.39, -00019.04, 01003.91 ], Gyr [ 00000.73, 00001.46, 00002.01 ], Mag [ 00018.30, 00083.70, 00107.70 ]",
    "#0000007255 NoMotion-1. Scaled. Acc [ -00042.48, 00001.95, 00979.98 ], Gyr [ -00000.98, 00001.52, -00000.61 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007256 NoMotion-1. Scaled. Acc [ -00048.83, -00017.58, 00992.19 ], Gyr [ -00001.34, 00002.62, -00003.11 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007257 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00998.54 ], Gyr [ 00000.85, 00001.40, -00000.61 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007258 NoMotion-1. Scaled. Acc [ -00040.53, -00017.58, 00995.61 ], Gyr [ 00003.17, 00002.80, 00000.85 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007259 NoMotion-1. Scaled. Acc [ -00032.71, 00000.00, 00989.26 ], Gyr [ 00001.22, 00001.28, -00000.12 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000007260 NoMotion-1. Scaled. Acc [ -00045.90, -00013.67, 01005.37 ], Gyr [ 00000.06, -00002.07, 00000.06 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000007261 NoMotion-1. Scaled. Acc [ -00041.99, 00003.91, 00994.14 ], Gyr [ 00001.10, 00000.85, -00000.37 ], Mag [ 00020.85, 00082.20, 00108.00 ]",
    "#0000007262 NoMotion-1. Scaled. Acc [ -00053.22, -00008.79, 00993.65 ], Gyr [ -00000.06, 00000.00, -00000.55 ], Mag [ 00020.85, 00082.20, 00108.00 ]",
    "#0000007263 NoMotion-1. Scaled. Acc [ -00043.46, -00001.95, 00993.16 ], Gyr [ -00002.07, 00000.37, -00003.90 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000007264 NoMotion-1. Scaled. Acc [ -00043.95, 00005.37, 00984.86 ], Gyr [ -00000.12, 00000.91, -00004.02 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007265 NoMotion-1. Scaled. Acc [ -00043.95, 00002.44, 00983.40 ], Gyr [ 00000.85, 00001.89, -00003.41 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007266 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00978.52 ], Gyr [ -00000.49, 00001.10, -00002.80 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007267 NoMotion-1. Scaled. Acc [ -00033.69, -00000.98, 00990.23 ], Gyr [ 00000.00, -00000.98, -00002.50 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007268 NoMotion-1. Scaled. Acc [ -00031.25, -00011.23, 00993.65 ], Gyr [ 00000.12, 00003.54, -00001.89 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007269 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 00990.72 ], Gyr [ 00000.30, -00000.55, 00000.12 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007270 NoMotion-1. Scaled. Acc [ -00034.18, -00009.77, 00977.54 ], Gyr [ 00000.30, 00000.67, -00002.20 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007271 NoMotion-1. Scaled. Acc [ -00037.11, -00006.35, 00998.54 ], Gyr [ -00000.24, 00001.77, -00001.34 ], Mag [ 00021.90, 00084.15, 00108.75 ]",
    "#0000007272 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00997.56 ], Gyr [ 00000.06, 00000.85, -00001.65 ], Mag [ 00021.90, 00084.15, 00108.75 ]",
    "#0000007273 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00992.19 ], Gyr [ 00001.10, 00000.12, -00000.49 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007274 NoMotion-1. Scaled. Acc [ -00025.88, 00006.84, 00979.49 ], Gyr [ -00000.12, 00002.50, -00001.59 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007275 NoMotion-1. Scaled. Acc [ -00031.25, -00001.46, 00996.09 ], Gyr [ 00000.61, 00001.59, -00002.07 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007276 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00977.54 ], Gyr [ -00000.37, 00005.49, -00002.26 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007277 NoMotion-1. Scaled. Acc [ -00037.60, -00007.32, 00994.63 ], Gyr [ 00000.06, 00002.74, -00002.44 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007278 NoMotion-1. Scaled. Acc [ -00059.08, -00004.39, 00984.38 ], Gyr [ 00000.79, 00001.83, -00003.23 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007279 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00991.70 ], Gyr [ -00000.55, 00002.68, -00004.27 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007280 NoMotion-1. Scaled. Acc [ -00046.39, -00009.77, 00986.33 ], Gyr [ -00000.30, 00001.89, -00001.10 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007281 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00987.79 ], Gyr [ 00000.91, 00003.05, -00001.95 ], Mag [ 00021.15, 00083.70, 00108.45 ]",
    "#0000007282 NoMotion-1. Scaled. Acc [ -00051.76, -00009.77, 00998.54 ], Gyr [ -00001.65, 00003.66, -00001.16 ], Mag [ 00021.15, 00083.70, 00108.45 ]",
    "#0000007283 NoMotion-1. Scaled. Acc [ -00053.22, -00000.98, 00994.14 ], Gyr [ 00001.89, 00001.71, -00001.77 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007284 NoMotion-1. Scaled. Acc [ -00043.95, -00008.30, 00998.54 ], Gyr [ -00000.55, -00000.37, 00000.30 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007285 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 01001.95 ], Gyr [ 00001.04, 00001.22, -00000.91 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007286 NoMotion-1. Scaled. Acc [ -00036.62, -00012.21, 00992.19 ], Gyr [ 00001.16, 00001.10, -00001.46 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007287 NoMotion-1. Scaled. Acc [ -00041.99, 00000.98, 00994.63 ], Gyr [ -00000.24, 00001.34, -00000.43 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007288 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00987.79 ], Gyr [ 00001.34, 00000.55, -00002.32 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007289 NoMotion-1. Scaled. Acc [ -00056.15, -00016.60, 00981.93 ], Gyr [ 00000.37, 00002.26, -00001.40 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007290 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 01000.00 ], Gyr [ 00000.55, 00001.71, -00001.52 ], Mag [ 00020.10, 00084.00, 00107.85 ]",
    "#0000007291 NoMotion-1. Scaled. Acc [ -00045.90, -00012.70, 00989.75 ], Gyr [ -00000.06, 00005.37, 00000.24 ], Mag [ 00020.10, 00085.65, 00108.00 ]",
    "#0000007292 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00999.51 ], Gyr [ 00001.04, 00001.28, -00003.35 ], Mag [ 00021.00, 00084.00, 00108.00 ]",
    "#0000007293 NoMotion-1. Scaled. Acc [ -00050.78, -00008.79, 01000.00 ], Gyr [ 00000.37, 00003.84, -00003.23 ], Mag [ 00021.00, 00084.00, 00107.55 ]",
    "#0000007294 NoMotion-1. Scaled. Acc [ -00040.04, 00002.93, 00976.07 ], Gyr [ -00001.83, 00004.82, -00001.65 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007295 NoMotion-1. Scaled. Acc [ -00042.48, -00011.72, 00990.72 ], Gyr [ -00000.24, 00000.30, -00002.56 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007296 NoMotion-1. Scaled. Acc [ -00041.02, 00000.00, 00980.47 ], Gyr [ 00001.77, -00000.55, -00001.59 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007297 NoMotion-1. Scaled. Acc [ -00044.43, -00002.93, 00988.28 ], Gyr [ 00002.68, 00001.28, -00000.91 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007298 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00990.23 ], Gyr [ 00000.73, 00000.55, -00002.32 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007299 NoMotion-1. Scaled. Acc [ -00037.60, -00004.88, 00988.77 ], Gyr [ -00000.61, 00002.26, -00000.49 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007300 NoMotion-1. Scaled. Acc [ -00044.43, 00003.91, 00992.68 ], Gyr [ 00001.46, -00000.37, -00002.74 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007301 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 00991.21 ], Gyr [ -00000.85, -00001.52, 00000.98 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007302 NoMotion-1. Scaled. Acc [ -00034.18, -00008.79, 00990.72 ], Gyr [ -00002.20, 00001.65, -00003.23 ], Mag [ 00019.50, 00083.25, 00108.30 ]",
    "#0000007303 NoMotion-1. Scaled. Acc [ -00041.50, -00016.60, 00988.77 ], Gyr [ 00000.61, 00001.59, 00000.67 ], Mag [ 00019.05, 00082.65, 00108.45 ]",
    "#0000007304 NoMotion-1. Scaled. Acc [ -00036.13, 00000.98, 00987.79 ], Gyr [ 00000.49, 00003.35, -00000.49 ], Mag [ 00019.05, 00082.65, 00108.45 ]",
    "#0000007305 NoMotion-1. Scaled. Acc [ -00050.78, -00018.07, 00996.58 ], Gyr [ -00001.77, 00001.71, -00001.52 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007306 NoMotion-1. Scaled. Acc [ -00038.57, -00016.11, 00991.21 ], Gyr [ -00000.73, 00000.67, -00002.32 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007307 NoMotion-1. Scaled. Acc [ -00028.32, 00001.46, 00995.12 ], Gyr [ 00001.65, 00001.22, -00001.04 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007308 NoMotion-1. Scaled. Acc [ -00038.57, -00006.84, 00985.84 ], Gyr [ -00001.52, -00002.32, -00001.83 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007309 NoMotion-1. Scaled. Acc [ -00034.18, 00001.46, 00986.82 ], Gyr [ -00001.95, -00002.44, -00001.10 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007310 NoMotion-1. Scaled. Acc [ -00043.95, -00000.49, 00987.30 ], Gyr [ 00000.55, 00001.52, -00001.71 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000007311 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00987.79 ], Gyr [ -00000.06, 00000.00, -00001.34 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007312 NoMotion-1. Scaled. Acc [ -00053.22, -00013.67, 00984.86 ], Gyr [ 00001.34, 00001.34, -00003.05 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007313 NoMotion-1. Scaled. Acc [ -00041.50, -00020.51, 00990.72 ], Gyr [ 00003.05, 00002.26, 00000.67 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007314 NoMotion-1. Scaled. Acc [ -00036.62, 00001.46, 00987.30 ], Gyr [ 00000.43, 00001.95, -00003.96 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007315 NoMotion-1. Scaled. Acc [ -00036.62, -00005.86, 00986.33 ], Gyr [ -00000.67, 00000.91, -00001.77 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007316 NoMotion-1. Scaled. Acc [ -00045.90, 00000.00, 00996.09 ], Gyr [ -00000.24, 00002.32, -00002.93 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007317 NoMotion-1. Scaled. Acc [ -00035.64, -00020.02, 00994.14 ], Gyr [ -00000.91, -00000.43, -00003.23 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007318 NoMotion-1. Scaled. Acc [ -00055.18, -00015.62, 00987.30 ], Gyr [ -00000.55, -00000.24, 00000.98 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007319 NoMotion-1. Scaled. Acc [ -00042.97, 00004.39, 00991.21 ], Gyr [ -00000.06, 00003.17, -00002.01 ], Mag [ 00019.50, 00082.80, 00108.60 ]",
    "#0000007320 NoMotion-1. Scaled. Acc [ -00044.43, 00003.91, 00988.77 ], Gyr [ 00000.37, 00001.16, -00001.16 ], Mag [ 00017.85, 00082.20, 00105.60 ]",
    "#0000007321 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00980.96 ], Gyr [ 00000.73, -00001.83, 00000.91 ], Mag [ 00020.25, 00082.50, 00107.10 ]",
    "#0000007322 NoMotion-1. Scaled. Acc [ -00037.60, -00010.74, 00993.16 ], Gyr [ -00000.98, 00001.28, 00002.74 ], Mag [ 00020.25, 00082.50, 00107.10 ]",
    "#0000007323 NoMotion-1. Scaled. Acc [ -00031.25, -00004.39, 00987.30 ], Gyr [ 00000.37, 00001.40, 00000.67 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007324 NoMotion-1. Scaled. Acc [ -00035.16, -00010.25, 00999.51 ], Gyr [ 00000.55, 00000.43, -00002.99 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007325 NoMotion-1. Scaled. Acc [ -00049.80, 00000.00, 00988.28 ], Gyr [ 00001.22, 00001.59, 00000.73 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007326 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00992.19 ], Gyr [ -00000.79, 00001.22, -00003.11 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007327 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00987.79 ], Gyr [ -00000.55, 00002.01, -00004.39 ], Mag [ 00020.25, 00084.00, 00107.10 ]",
    "#0000007328 NoMotion-1. Scaled. Acc [ -00038.09, 00007.32, 00988.28 ], Gyr [ 00000.06, 00000.06, -00002.44 ], Mag [ 00020.25, 00084.00, 00107.10 ]",
    "#0000007329 NoMotion-1. Scaled. Acc [ -00052.25, -00012.70, 00986.82 ], Gyr [ 00001.65, 00000.91, -00001.16 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000007330 NoMotion-1. Scaled. Acc [ -00049.80, -00000.98, 00986.33 ], Gyr [ -00000.49, 00002.87, -00001.89 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000007331 NoMotion-1. Scaled. Acc [ -00040.53, -00021.48, 00994.14 ], Gyr [ -00000.12, 00003.11, -00003.66 ], Mag [ 00017.70, 00084.75, 00107.10 ]",
    "#0000007332 NoMotion-1. Scaled. Acc [ -00052.25, -00005.86, 00998.05 ], Gyr [ 00000.67, 00003.54, -00001.40 ], Mag [ 00017.70, 00084.75, 00107.10 ]",
    "#0000007333 NoMotion-1. Scaled. Acc [ -00039.06, 00005.86, 00983.40 ], Gyr [ 00001.22, 00002.50, 00000.49 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007334 NoMotion-1. Scaled. Acc [ -00028.81, -00008.79, 00994.63 ], Gyr [ 00000.67, 00000.24, -00001.89 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007335 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00992.19 ], Gyr [ 00001.40, 00002.01, -00003.84 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007336 NoMotion-1. Scaled. Acc [ -00054.20, -00018.07, 00987.30 ], Gyr [ 00001.71, 00003.29, 00001.10 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007337 NoMotion-1. Scaled. Acc [ -00040.04, -00006.35, 01001.46 ], Gyr [ 00002.07, 00000.30, -00001.10 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000007338 NoMotion-1. Scaled. Acc [ -00033.20, -00002.44, 00987.30 ], Gyr [ -00000.55, 00000.06, -00001.28 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000007339 NoMotion-1. Scaled. Acc [ -00052.25, -00018.55, 00987.79 ], Gyr [ 00002.26, 00002.13, 00000.24 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007340 NoMotion-1. Scaled. Acc [ -00032.23, -00005.86, 00984.86 ], Gyr [ -00000.61, 00002.68, -00001.46 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007341 NoMotion-1. Scaled. Acc [ -00051.27, 00000.00, 00984.86 ], Gyr [ -00000.37, 00003.29, -00001.40 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007342 NoMotion-1. Scaled. Acc [ -00051.76, -00007.81, 00987.79 ], Gyr [ -00000.12, 00000.85, -00002.56 ], Mag [ 00018.15, 00084.30, 00109.35 ]",
    "#0000007343 NoMotion-1. Scaled. Acc [ -00039.55, 00000.00, 00982.91 ], Gyr [ -00000.79, 00001.16, -00002.62 ], Mag [ 00018.15, 00084.30, 00109.35 ]",
    "#0000007344 NoMotion-1. Scaled. Acc [ -00040.04, -00007.81, 00987.30 ], Gyr [ -00000.18, 00000.55, -00000.61 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007345 NoMotion-1. Scaled. Acc [ -00044.43, -00001.46, 00991.70 ], Gyr [ 00002.93, 00000.67, 00000.37 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007346 NoMotion-1. Scaled. Acc [ -00047.36, -00007.32, 00983.89 ], Gyr [ 00001.28, -00000.73, 00000.49 ], Mag [ 00021.15, 00085.20, 00108.00 ]",
    "#0000007347 NoMotion-1. Scaled. Acc [ -00051.76, -00010.25, 00992.68 ], Gyr [ 00001.10, 00002.32, -00000.91 ], Mag [ 00021.15, 00085.20, 00108.00 ]",
    "#0000007348 NoMotion-1. Scaled. Acc [ -00039.55, 00000.49, 00985.35 ], Gyr [ -00000.18, 00003.90, -00001.40 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007349 NoMotion-1. Scaled. Acc [ -00055.66, -00007.81, 00998.05 ], Gyr [ 00000.37, -00000.67, -00001.71 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007350 NoMotion-1. Scaled. Acc [ -00030.27, -00007.32, 00986.82 ], Gyr [ 00001.89, 00001.71, -00000.79 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007351 NoMotion-1. Scaled. Acc [ -00041.50, -00002.44, 00983.40 ], Gyr [ 00000.98, 00003.78, -00003.60 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000007352 NoMotion-1. Scaled. Acc [ -00040.04, 00000.98, 00985.84 ], Gyr [ 00000.61, 00003.96, -00000.43 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000007353 NoMotion-1. Scaled. Acc [ -00045.41, 00006.84, 00996.58 ], Gyr [ 00000.24, 00001.52, -00003.35 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007354 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 00989.26 ], Gyr [ -00000.98, 00000.61, -00004.09 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007355 NoMotion-1. Scaled. Acc [ -00030.76, -00023.44, 00994.14 ], Gyr [ 00002.26, 00001.16, 00000.55 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007356 NoMotion-1. Scaled. Acc [ -00042.48, -00014.16, 00989.75 ], Gyr [ 00001.22, -00000.49, -00002.13 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007357 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00989.26 ], Gyr [ 00002.13, 00003.41, -00000.12 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007358 NoMotion-1. Scaled. Acc [ -00061.52, -00003.91, 01000.98 ], Gyr [ 00000.91, -00000.79, -00001.71 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007359 NoMotion-1. Scaled. Acc [ -00047.36, -00010.74, 00986.33 ], Gyr [ 00001.22, 00001.28, 00000.37 ], Mag [ 00020.10, 00082.65, 00107.40 ]",
    "#0000007360 NoMotion-1. Scaled. Acc [ -00055.66, -00012.70, 00989.26 ], Gyr [ 00001.95, -00000.79, 00001.22 ], Mag [ 00020.10, 00082.65, 00107.40 ]",
    "#0000007361 NoMotion-1. Scaled. Acc [ -00037.11, -00017.58, 00987.30 ], Gyr [ -00000.12, 00001.10, -00002.01 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007362 NoMotion-1. Scaled. Acc [ -00045.90, 00001.95, 00992.68 ], Gyr [ -00000.55, 00001.34, -00003.23 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007363 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00999.51 ], Gyr [ -00000.98, -00000.85, -00000.55 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000007364 NoMotion-1. Scaled. Acc [ -00040.53, -00012.21, 00980.96 ], Gyr [ -00001.16, 00002.44, -00001.77 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000007365 NoMotion-1. Scaled. Acc [ -00033.20, -00014.16, 00996.58 ], Gyr [ 00000.18, 00001.59, -00003.72 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007366 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00982.42 ], Gyr [ 00002.01, 00001.65, -00000.43 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007367 NoMotion-1. Scaled. Acc [ -00041.99, -00012.21, 00987.30 ], Gyr [ 00000.43, 00003.23, -00000.30 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007368 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00995.61 ], Gyr [ 00000.18, 00000.00, 00000.12 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007369 NoMotion-1. Scaled. Acc [ -00031.74, -00007.81, 01001.95 ], Gyr [ 00001.22, 00001.59, 00000.98 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007370 NoMotion-1. Scaled. Acc [ -00037.11, 00005.86, 00983.89 ], Gyr [ -00000.55, 00000.91, -00002.32 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007371 NoMotion-1. Scaled. Acc [ -00054.20, -00001.95, 00986.82 ], Gyr [ 00001.89, 00002.07, -00000.67 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007372 NoMotion-1. Scaled. Acc [ -00033.20, -00023.93, 00984.38 ], Gyr [ 00002.44, 00000.00, -00001.95 ], Mag [ 00019.65, 00083.40, 00106.95 ]",
    "#0000007373 NoMotion-1. Scaled. Acc [ -00040.53, -00021.00, 00998.05 ], Gyr [ 00001.83, 00000.61, -00004.45 ], Mag [ 00019.65, 00083.40, 00106.95 ]",
    "#0000007374 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00998.05 ], Gyr [ 00000.24, 00001.95, -00000.98 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007375 NoMotion-1. Scaled. Acc [ -00045.41, -00015.14, 00985.35 ], Gyr [ -00000.18, 00001.59, -00001.83 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007376 NoMotion-1. Scaled. Acc [ -00047.85, -00007.81, 00997.56 ], Gyr [ -00000.37, 00000.85, -00003.17 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007377 NoMotion-1. Scaled. Acc [ -00044.92, -00000.98, 00993.65 ], Gyr [ -00002.50, 00002.44, -00001.95 ], Mag [ 00019.95, 00082.05, 00108.30 ]",
    "#0000007378 NoMotion-1. Scaled. Acc [ -00041.02, -00012.70, 00981.45 ], Gyr [ -00000.91, 00002.50, -00002.50 ], Mag [ 00019.95, 00082.05, 00108.30 ]",
    "#0000007379 NoMotion-1. Scaled. Acc [ -00042.97, -00019.53, 00994.63 ], Gyr [ -00000.30, -00000.73, -00000.37 ], Mag [ 00017.85, 00084.90, 00109.05 ]",
    "#0000007380 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00982.42 ], Gyr [ 00004.51, -00000.18, 00000.00 ], Mag [ 00017.85, 00084.90, 00109.05 ]",
    "#0000007381 NoMotion-1. Scaled. Acc [ -00045.41, -00004.88, 00984.86 ], Gyr [ 00000.30, 00003.17, -00002.26 ], Mag [ 00020.25, 00082.80, 00107.85 ]",
    "#0000007382 NoMotion-1. Scaled. Acc [ -00041.02, 00002.44, 00994.14 ], Gyr [ 00001.16, 00001.10, -00002.01 ], Mag [ 00020.25, 00082.80, 00107.85 ]",
    "#0000007383 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00983.40 ], Gyr [ 00000.24, -00002.13, 00000.30 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007384 NoMotion-1. Scaled. Acc [ -00040.53, -00004.88, 01003.91 ], Gyr [ 00001.89, 00001.16, -00000.43 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007385 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00982.91 ], Gyr [ -00000.12, 00000.55, -00003.17 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007386 NoMotion-1. Scaled. Acc [ -00048.83, 00004.39, 00999.51 ], Gyr [ 00002.26, 00000.43, -00001.16 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007387 NoMotion-1. Scaled. Acc [ -00035.64, -00006.35, 00990.72 ], Gyr [ 00000.37, 00003.29, -00001.34 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007388 NoMotion-1. Scaled. Acc [ -00035.64, -00001.95, 00987.30 ], Gyr [ 00000.43, 00000.18, -00000.30 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007389 NoMotion-1. Scaled. Acc [ -00039.55, 00004.88, 00993.65 ], Gyr [ 00000.61, 00004.51, -00002.68 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007390 NoMotion-1. Scaled. Acc [ -00047.85, -00009.77, 00985.35 ], Gyr [ 00000.43, 00000.06, -00003.90 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007391 NoMotion-1. Scaled. Acc [ -00040.04, -00008.30, 00983.40 ], Gyr [ -00000.24, 00002.32, -00002.62 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007392 NoMotion-1. Scaled. Acc [ -00034.67, -00012.21, 00997.56 ], Gyr [ 00001.28, -00001.34, 00001.04 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007393 NoMotion-1. Scaled. Acc [ -00038.57, -00013.18, 00978.52 ], Gyr [ 00000.73, 00000.91, -00000.73 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007394 NoMotion-1. Scaled. Acc [ -00042.48, -00006.35, 00995.12 ], Gyr [ 00000.06, 00000.91, -00001.46 ], Mag [ 00020.40, 00083.40, 00107.70 ]",
    "#0000007395 NoMotion-1. Scaled. Acc [ -00042.48, -00002.93, 00997.07 ], Gyr [ 00002.01, -00000.73, 00000.85 ], Mag [ 00020.40, 00083.40, 00107.70 ]",
    "#0000007396 NoMotion-1. Scaled. Acc [ -00037.60, -00014.16, 01004.88 ], Gyr [ 00000.61, 00001.10, -00000.73 ], Mag [ 00020.70, 00084.00, 00107.85 ]",
    "#0000007397 NoMotion-1. Scaled. Acc [ -00042.97, -00011.23, 00995.61 ], Gyr [ 00001.46, 00001.34, -00000.55 ], Mag [ 00020.70, 00084.00, 00107.85 ]",
    "#0000007398 NoMotion-1. Scaled. Acc [ -00048.34, -00014.65, 00995.12 ], Gyr [ -00000.18, 00001.83, -00002.07 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000007399 NoMotion-1. Scaled. Acc [ -00048.83, -00016.60, 00999.02 ], Gyr [ 00001.10, 00001.28, -00003.72 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000007400 NoMotion-1. Scaled. Acc [ -00041.50, -00013.67, 00998.05 ], Gyr [ 00000.55, 00001.89, -00001.65 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007401 NoMotion-1. Scaled. Acc [ -00047.85, 00009.77, 00991.70 ], Gyr [ -00001.71, 00003.05, -00001.83 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007402 NoMotion-1. Scaled. Acc [ -00037.60, -00026.37, 01001.46 ], Gyr [ 00000.12, 00000.43, 00001.28 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007403 NoMotion-1. Scaled. Acc [ -00035.64, -00011.23, 00992.19 ], Gyr [ -00000.37, 00003.60, -00002.62 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007404 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 01001.95 ], Gyr [ 00001.10, 00002.13, -00000.61 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007405 NoMotion-1. Scaled. Acc [ -00051.27, -00006.35, 00989.26 ], Gyr [ 00001.52, 00001.16, 00002.68 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007406 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 00981.93 ], Gyr [ -00000.18, 00002.20, -00000.24 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007407 NoMotion-1. Scaled. Acc [ -00037.60, -00014.16, 00982.42 ], Gyr [ 00002.26, 00001.46, -00003.41 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007408 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00992.68 ], Gyr [ -00000.30, -00000.55, -00001.34 ], Mag [ 00019.95, 00084.75, 00108.60 ]",
    "#0000007409 NoMotion-1. Scaled. Acc [ -00036.13, -00001.95, 00994.63 ], Gyr [ 00001.71, 00001.34, -00001.95 ], Mag [ 00019.95, 00084.75, 00108.60 ]",
    "#0000007410 NoMotion-1. Scaled. Acc [ -00033.20, -00012.21, 00992.19 ], Gyr [ 00003.35, 00002.38, 00000.24 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007411 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 01000.98 ], Gyr [ 00001.46, -00000.30, -00000.85 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007412 NoMotion-1. Scaled. Acc [ -00046.88, -00017.58, 00991.70 ], Gyr [ 00001.04, -00000.55, -00002.68 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007413 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00990.72 ], Gyr [ 00000.00, 00003.05, -00000.85 ], Mag [ 00020.40, 00084.90, 00107.40 ]",
    "#0000007414 NoMotion-1. Scaled. Acc [ -00036.62, -00001.46, 00986.33 ], Gyr [ 00001.77, 00001.40, -00003.90 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007415 NoMotion-1. Scaled. Acc [ -00059.57, 00001.46, 00988.77 ], Gyr [ 00000.43, 00003.90, -00000.12 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007416 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00991.70 ], Gyr [ 00001.10, 00000.12, -00000.73 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007417 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00993.16 ], Gyr [ 00000.98, 00004.63, -00003.41 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007418 NoMotion-1. Scaled. Acc [ -00044.43, -00004.39, 00993.65 ], Gyr [ 00000.00, 00003.17, -00001.16 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007419 NoMotion-1. Scaled. Acc [ -00061.04, -00011.72, 00992.68 ], Gyr [ -00000.24, 00000.06, -00002.26 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007420 NoMotion-1. Scaled. Acc [ -00048.83, -00003.91, 00993.65 ], Gyr [ 00000.79, 00001.77, -00001.59 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007421 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00989.26 ], Gyr [ 00000.79, -00000.43, -00000.98 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007422 NoMotion-1. Scaled. Acc [ -00038.57, -00021.97, 00993.65 ], Gyr [ -00001.95, 00003.17, -00002.44 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000007423 NoMotion-1. Scaled. Acc [ -00042.48, -00009.77, 00984.86 ], Gyr [ -00002.50, 00000.67, -00002.80 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000007424 NoMotion-1. Scaled. Acc [ -00042.97, -00000.98, 00990.23 ], Gyr [ 00001.77, 00001.34, -00003.48 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007425 NoMotion-1. Scaled. Acc [ -00047.85, -00012.21, 01005.86 ], Gyr [ 00002.20, -00000.12, -00001.34 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007426 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 01000.98 ], Gyr [ -00000.24, -00000.55, -00002.56 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000007427 NoMotion-1. Scaled. Acc [ -00047.85, -00008.79, 00984.86 ], Gyr [ 00000.98, 00001.89, -00002.93 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000007428 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00992.68 ], Gyr [ 00001.83, 00000.67, -00000.67 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007429 NoMotion-1. Scaled. Acc [ -00048.34, -00000.49, 00995.61 ], Gyr [ 00000.55, 00001.59, 00000.06 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007430 NoMotion-1. Scaled. Acc [ -00041.50, -00001.46, 00986.82 ], Gyr [ -00000.12, 00002.62, -00000.24 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007431 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 01000.49 ], Gyr [ 00001.59, 00003.84, -00001.28 ], Mag [ 00019.65, 00085.65, 00106.95 ]",
    "#0000007432 NoMotion-1. Scaled. Acc [ -00053.22, -00009.77, 00975.59 ], Gyr [ 00001.59, 00003.05, -00001.16 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007433 NoMotion-1. Scaled. Acc [ -00044.43, -00013.18, 00986.82 ], Gyr [ -00000.67, 00001.59, -00000.30 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007434 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00984.38 ], Gyr [ 00000.55, 00000.18, 00000.06 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007435 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00987.30 ], Gyr [ 00000.61, -00000.30, 00000.55 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007436 NoMotion-1. Scaled. Acc [ -00040.53, -00009.77, 00983.89 ], Gyr [ -00001.77, 00000.00, -00001.77 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007437 NoMotion-1. Scaled. Acc [ -00037.11, -00007.81, 00995.12 ], Gyr [ 00000.73, 00000.73, -00003.66 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007438 NoMotion-1. Scaled. Acc [ -00054.69, -00009.77, 00984.38 ], Gyr [ 00000.37, 00002.13, -00003.11 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000007439 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00997.56 ], Gyr [ -00000.98, 00001.34, -00004.02 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000007440 NoMotion-1. Scaled. Acc [ -00036.62, -00011.72, 00998.05 ], Gyr [ 00002.07, 00001.28, -00001.52 ], Mag [ 00021.00, 00083.25, 00108.30 ]",
    "#0000007441 NoMotion-1. Scaled. Acc [ -00051.76, -00014.16, 00978.52 ], Gyr [ -00001.40, 00002.44, -00002.07 ], Mag [ 00021.00, 00083.25, 00108.30 ]",
    "#0000007442 NoMotion-1. Scaled. Acc [ -00050.78, -00005.86, 00992.68 ], Gyr [ 00000.00, 00001.22, -00002.74 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000007443 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00984.86 ], Gyr [ 00003.11, -00002.13, -00002.38 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000007444 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 00995.12 ], Gyr [ 00001.59, -00002.56, -00001.22 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007445 NoMotion-1. Scaled. Acc [ -00043.95, -00015.14, 00983.89 ], Gyr [ 00002.07, 00004.27, -00002.50 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007446 NoMotion-1. Scaled. Acc [ -00049.80, 00002.44, 00985.35 ], Gyr [ 00001.89, -00000.85, 00000.61 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007447 NoMotion-1. Scaled. Acc [ -00050.29, -00007.81, 00994.63 ], Gyr [ -00000.12, -00001.22, -00002.01 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000007448 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00995.12 ], Gyr [ 00000.49, 00000.91, -00001.16 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000007449 NoMotion-1. Scaled. Acc [ -00031.74, 00006.84, 00988.28 ], Gyr [ 00000.85, 00002.50, -00002.01 ], Mag [ 00020.10, 00084.45, 00108.75 ]",
    "#0000007450 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00988.28 ], Gyr [ 00001.59, 00001.22, -00002.32 ], Mag [ 00020.10, 00084.45, 00108.75 ]",
    "#0000007451 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00984.38 ], Gyr [ -00000.55, -00000.37, -00002.74 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000007452 NoMotion-1. Scaled. Acc [ -00041.50, -00004.39, 00990.23 ], Gyr [ -00000.30, 00003.72, -00002.93 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007453 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 00984.38 ], Gyr [ -00000.79, 00002.20, 00000.24 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007454 NoMotion-1. Scaled. Acc [ -00049.80, -00004.88, 00980.96 ], Gyr [ 00002.13, 00000.61, -00002.99 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007455 NoMotion-1. Scaled. Acc [ -00038.09, -00006.84, 00993.65 ], Gyr [ 00000.85, 00001.16, -00001.22 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007456 NoMotion-1. Scaled. Acc [ -00053.22, -00017.58, 00991.70 ], Gyr [ 00000.37, -00001.52, 00000.61 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007457 NoMotion-1. Scaled. Acc [ -00049.80, -00003.91, 00987.30 ], Gyr [ 00002.07, 00001.10, 00000.24 ], Mag [ 00019.50, 00084.00, 00107.55 ]",
    "#0000007458 NoMotion-1. Scaled. Acc [ -00041.02, -00003.42, 00996.09 ], Gyr [ 00002.87, 00001.16, 00001.10 ], Mag [ 00019.50, 00084.00, 00107.55 ]",
    "#0000007459 NoMotion-1. Scaled. Acc [ -00052.73, -00002.44, 00993.65 ], Gyr [ -00000.12, 00002.20, -00003.29 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007460 NoMotion-1. Scaled. Acc [ -00021.48, -00016.11, 01008.79 ], Gyr [ -00000.79, 00001.46, -00000.61 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007461 NoMotion-1. Scaled. Acc [ -00048.83, -00008.30, 00980.47 ], Gyr [ -00000.61, 00002.93, 00000.06 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007462 NoMotion-1. Scaled. Acc [ -00036.13, -00018.55, 00989.75 ], Gyr [ -00001.77, 00002.99, -00001.65 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007463 NoMotion-1. Scaled. Acc [ -00056.15, -00003.91, 00989.75 ], Gyr [ -00000.49, 00001.71, 00002.20 ], Mag [ 00020.25, 00084.00, 00109.65 ]",
    "#0000007464 NoMotion-1. Scaled. Acc [ -00054.69, -00000.49, 00995.12 ], Gyr [ 00001.40, 00000.18, -00002.93 ], Mag [ 00018.30, 00082.65, 00105.90 ]",
    "#0000007465 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00981.93 ], Gyr [ 00002.38, 00003.11, -00001.71 ], Mag [ 00018.30, 00082.65, 00105.90 ]",
    "#0000007466 NoMotion-1. Scaled. Acc [ -00047.85, -00015.62, 00985.35 ], Gyr [ -00000.79, 00000.73, 00000.24 ], Mag [ 00019.95, 00085.80, 00108.30 ]",
    "#0000007467 NoMotion-1. Scaled. Acc [ -00029.79, -00001.95, 00990.72 ], Gyr [ -00000.55, 00000.24, -00002.13 ], Mag [ 00019.95, 00085.80, 00108.30 ]",
    "#0000007468 NoMotion-1. Scaled. Acc [ -00046.39, -00007.81, 00986.82 ], Gyr [ 00001.95, 00001.77, -00002.80 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007469 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00997.07 ], Gyr [ -00000.73, -00000.06, -00003.35 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007470 NoMotion-1. Scaled. Acc [ -00034.67, 00000.98, 00984.86 ], Gyr [ -00000.91, 00001.52, -00000.85 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007471 NoMotion-1. Scaled. Acc [ -00055.18, -00007.32, 00988.77 ], Gyr [ 00001.28, 00001.95, -00001.52 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007472 NoMotion-1. Scaled. Acc [ -00037.60, -00002.44, 01000.98 ], Gyr [ 00001.89, 00001.10, -00001.52 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000007473 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00992.68 ], Gyr [ 00002.01, 00000.85, -00002.44 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000007474 NoMotion-1. Scaled. Acc [ -00043.95, -00015.62, 00993.16 ], Gyr [ 00000.91, 00004.09, -00000.24 ], Mag [ 00020.70, 00084.00, 00106.80 ]",
    "#0000007475 NoMotion-1. Scaled. Acc [ -00047.36, -00008.30, 01001.95 ], Gyr [ 00000.06, 00003.05, 00000.55 ], Mag [ 00020.70, 00084.00, 00106.80 ]",
    "#0000007476 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00981.45 ], Gyr [ -00000.55, 00002.38, -00001.40 ], Mag [ 00020.10, 00084.45, 00108.00 ]",
    "#0000007477 NoMotion-1. Scaled. Acc [ -00044.92, -00010.74, 00985.35 ], Gyr [ 00000.55, 00000.85, -00001.52 ], Mag [ 00020.10, 00084.45, 00108.00 ]",
    "#0000007478 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 01001.95 ], Gyr [ -00001.22, 00002.68, -00001.52 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007479 NoMotion-1. Scaled. Acc [ -00047.85, 00007.81, 00986.82 ], Gyr [ 00001.52, 00000.79, -00000.37 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007480 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00989.75 ], Gyr [ 00000.98, 00000.43, -00000.49 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007481 NoMotion-1. Scaled. Acc [ -00054.69, -00014.16, 00985.84 ], Gyr [ 00001.65, 00001.59, 00000.73 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007482 NoMotion-1. Scaled. Acc [ -00046.88, 00003.91, 00997.07 ], Gyr [ 00001.83, -00000.55, -00002.32 ], Mag [ 00019.35, 00082.20, 00106.95 ]",
    "#0000007483 NoMotion-1. Scaled. Acc [ -00049.32, 00000.49, 00985.35 ], Gyr [ 00002.26, 00000.06, -00001.04 ], Mag [ 00020.40, 00082.20, 00108.75 ]",
    "#0000007484 NoMotion-1. Scaled. Acc [ -00047.36, -00000.98, 00998.54 ], Gyr [ 00000.61, -00001.28, -00003.72 ], Mag [ 00020.40, 00082.20, 00108.75 ]",
    "#0000007485 NoMotion-1. Scaled. Acc [ -00041.02, -00000.49, 00988.77 ], Gyr [ -00001.28, 00002.26, -00003.41 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000007486 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00988.77 ], Gyr [ 00000.24, 00001.52, -00002.44 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000007487 NoMotion-1. Scaled. Acc [ -00039.06, -00002.93, 00979.49 ], Gyr [ 00000.98, 00003.11, -00001.59 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007488 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00984.86 ], Gyr [ 00000.24, 00002.01, -00002.80 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007489 NoMotion-1. Scaled. Acc [ -00045.90, 00000.49, 00985.84 ], Gyr [ -00000.43, 00003.23, -00001.65 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007490 NoMotion-1. Scaled. Acc [ -00036.13, -00010.74, 00992.68 ], Gyr [ -00000.06, 00000.18, 00000.49 ], Mag [ 00021.00, 00084.30, 00108.30 ]",
    "#0000007491 NoMotion-1. Scaled. Acc [ -00049.80, -00005.37, 00996.09 ], Gyr [ 00001.95, 00001.65, 00001.89 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007492 NoMotion-1. Scaled. Acc [ -00037.60, -00002.93, 00986.33 ], Gyr [ 00001.04, 00000.37, -00000.73 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007493 NoMotion-1. Scaled. Acc [ -00039.55, -00009.28, 00987.79 ], Gyr [ 00002.07, 00000.91, -00001.10 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007494 NoMotion-1. Scaled. Acc [ -00052.73, -00019.53, 00986.33 ], Gyr [ 00002.68, 00001.22, -00000.91 ], Mag [ 00020.70, 00084.30, 00107.85 ]",
    "#0000007495 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 01002.93 ], Gyr [ -00000.18, 00000.24, -00001.10 ], Mag [ 00020.70, 00084.30, 00107.85 ]",
    "#0000007496 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00987.30 ], Gyr [ 00000.67, -00002.13, -00000.24 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007497 NoMotion-1. Scaled. Acc [ -00041.50, 00004.39, 00990.72 ], Gyr [ 00001.77, 00001.52, -00001.52 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007498 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00993.65 ], Gyr [ -00000.30, 00004.51, -00002.07 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007499 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00991.70 ], Gyr [ -00000.55, -00000.67, -00003.96 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007500 NoMotion-1. Scaled. Acc [ -00049.80, -00002.93, 00994.63 ], Gyr [ 00001.52, -00000.61, -00001.95 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007501 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00980.47 ], Gyr [ 00001.10, 00002.50, -00003.66 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007502 NoMotion-1. Scaled. Acc [ -00049.80, -00001.95, 00994.14 ], Gyr [ 00000.37, -00000.49, -00002.20 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007503 NoMotion-1. Scaled. Acc [ -00049.32, -00010.25, 00984.86 ], Gyr [ -00001.34, 00001.71, -00002.44 ], Mag [ 00018.75, 00082.05, 00107.55 ]",
    "#0000007504 NoMotion-1. Scaled. Acc [ -00035.64, 00001.95, 00984.86 ], Gyr [ -00000.49, 00000.18, 00000.61 ], Mag [ 00018.75, 00082.05, 00107.55 ]",
    "#0000007505 NoMotion-1. Scaled. Acc [ -00041.02, -00019.53, 00987.79 ], Gyr [ 00001.77, -00002.50, -00000.43 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007506 NoMotion-1. Scaled. Acc [ -00050.29, -00005.86, 00985.84 ], Gyr [ 00001.40, 00001.16, 00000.00 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007507 NoMotion-1. Scaled. Acc [ -00037.60, -00001.46, 00991.21 ], Gyr [ 00001.34, 00001.83, -00000.85 ], Mag [ 00020.10, 00084.90, 00107.70 ]",
    "#0000007508 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00993.16 ], Gyr [ 00002.07, 00001.16, 00000.49 ], Mag [ 00020.10, 00084.90, 00107.70 ]",
    "#0000007509 NoMotion-1. Scaled. Acc [ -00052.25, 00000.00, 01000.49 ], Gyr [ 00001.28, 00002.93, -00002.99 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007510 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 00993.65 ], Gyr [ -00000.12, 00002.38, -00000.85 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007511 NoMotion-1. Scaled. Acc [ -00039.55, -00004.88, 00971.19 ], Gyr [ 00000.49, 00000.98, -00001.28 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007512 NoMotion-1. Scaled. Acc [ -00043.95, -00016.11, 00984.86 ], Gyr [ 00000.00, 00002.68, -00002.26 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007513 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00989.26 ], Gyr [ -00000.79, 00004.02, -00000.43 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000007514 NoMotion-1. Scaled. Acc [ -00049.32, -00012.21, 01010.25 ], Gyr [ 00001.10, 00004.21, -00004.21 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000007515 NoMotion-1. Scaled. Acc [ -00038.09, 00005.37, 01000.00 ], Gyr [ -00000.79, 00004.39, -00001.16 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000007516 NoMotion-1. Scaled. Acc [ -00047.36, -00021.97, 00994.63 ], Gyr [ 00000.73, 00000.24, -00000.61 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000007517 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.28 ], Gyr [ 00001.52, 00002.32, -00001.04 ], Mag [ 00019.95, 00081.75, 00107.10 ]",
    "#0000007518 NoMotion-1. Scaled. Acc [ -00043.46, -00021.97, 00984.86 ], Gyr [ 00000.91, 00000.91, -00002.26 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007519 NoMotion-1. Scaled. Acc [ -00058.59, 00000.98, 00989.75 ], Gyr [ 00000.24, -00000.30, -00003.54 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007520 NoMotion-1. Scaled. Acc [ -00046.39, -00013.18, 00995.12 ], Gyr [ 00000.85, 00000.12, -00001.46 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007521 NoMotion-1. Scaled. Acc [ -00039.55, -00013.18, 00989.75 ], Gyr [ 00000.85, 00000.49, -00000.67 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007522 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00996.09 ], Gyr [ -00000.73, 00000.55, -00000.98 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007523 NoMotion-1. Scaled. Acc [ -00037.60, -00000.98, 00981.45 ], Gyr [ 00000.06, 00002.80, -00001.28 ], Mag [ 00019.05, 00083.70, 00108.45 ]",
    "#0000007524 NoMotion-1. Scaled. Acc [ -00050.29, -00015.62, 00994.14 ], Gyr [ 00000.85, -00000.30, -00002.01 ], Mag [ 00019.05, 00083.70, 00108.45 ]",
    "#0000007525 NoMotion-1. Scaled. Acc [ -00043.46, -00015.62, 00988.77 ], Gyr [ -00000.30, 00001.71, -00001.52 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007526 NoMotion-1. Scaled. Acc [ -00036.13, 00000.49, 00994.14 ], Gyr [ -00000.73, 00004.57, -00000.73 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007527 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 00994.14 ], Gyr [ 00001.52, 00001.71, -00004.02 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007528 NoMotion-1. Scaled. Acc [ -00045.41, -00001.46, 00985.35 ], Gyr [ 00001.71, 00001.71, 00001.04 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007529 NoMotion-1. Scaled. Acc [ -00042.48, -00001.95, 00994.14 ], Gyr [ -00000.79, 00001.28, -00003.11 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007530 NoMotion-1. Scaled. Acc [ -00048.83, -00011.23, 00982.91 ], Gyr [ 00000.85, 00002.01, 00000.55 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007531 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00989.75 ], Gyr [ 00001.28, -00001.59, -00003.17 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000007532 NoMotion-1. Scaled. Acc [ -00040.53, -00013.18, 00997.56 ], Gyr [ 00001.89, 00001.77, -00000.85 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000007533 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00992.68 ], Gyr [ -00000.91, -00000.85, 00000.55 ], Mag [ 00021.60, 00083.40, 00108.45 ]",
    "#0000007534 NoMotion-1. Scaled. Acc [ -00035.16, -00005.37, 00982.42 ], Gyr [ 00001.34, 00003.17, -00002.32 ], Mag [ 00021.60, 00083.40, 00108.45 ]",
    "#0000007535 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00993.16 ], Gyr [ 00000.43, 00002.56, 00001.34 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007536 NoMotion-1. Scaled. Acc [ -00048.83, 00001.95, 00984.38 ], Gyr [ 00002.26, -00001.89, 00001.40 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007537 NoMotion-1. Scaled. Acc [ -00050.29, -00008.79, 00991.21 ], Gyr [ -00001.34, 00001.89, 00001.89 ], Mag [ 00020.70, 00085.50, 00107.55 ]",
    "#0000007538 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00989.26 ], Gyr [ 00002.26, 00002.13, -00002.87 ], Mag [ 00020.70, 00085.50, 00107.55 ]",
    "#0000007539 NoMotion-1. Scaled. Acc [ -00039.06, -00019.04, 00999.02 ], Gyr [ -00001.28, 00000.43, -00001.83 ], Mag [ 00020.70, 00085.50, 00107.55 ]",
    "#0000007540 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 00996.58 ], Gyr [ 00001.71, 00001.40, -00000.98 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007541 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00992.19 ], Gyr [ 00000.55, 00002.50, -00001.16 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007542 NoMotion-1. Scaled. Acc [ -00056.15, -00001.95, 00982.42 ], Gyr [ 00000.00, 00004.02, 00000.00 ], Mag [ 00020.25, 00083.55, 00106.50 ]",
    "#0000007543 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00982.42 ], Gyr [ 00000.06, 00003.41, -00002.38 ], Mag [ 00020.25, 00083.55, 00106.50 ]",
    "#0000007544 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 01000.00 ], Gyr [ 00001.46, -00000.37, -00001.65 ], Mag [ 00020.10, 00084.90, 00105.60 ]",
    "#0000007545 NoMotion-1. Scaled. Acc [ -00027.83, -00019.53, 00996.09 ], Gyr [ -00000.43, 00000.00, -00000.98 ], Mag [ 00020.10, 00084.90, 00105.60 ]",
    "#0000007546 NoMotion-1. Scaled. Acc [ -00026.86, -00015.14, 00990.23 ], Gyr [ -00000.12, 00001.71, 00002.44 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007547 NoMotion-1. Scaled. Acc [ -00041.02, -00014.16, 00981.93 ], Gyr [ 00000.43, -00000.49, 00000.37 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007548 NoMotion-1. Scaled. Acc [ -00052.25, -00013.67, 00989.75 ], Gyr [ 00001.71, -00000.79, -00002.13 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007549 NoMotion-1. Scaled. Acc [ -00041.02, -00001.95, 00986.33 ], Gyr [ -00000.98, 00000.85, -00000.55 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007550 NoMotion-1. Scaled. Acc [ -00031.74, -00008.30, 00985.84 ], Gyr [ 00000.55, 00001.46, -00001.71 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007551 NoMotion-1. Scaled. Acc [ -00038.09, -00009.77, 00980.96 ], Gyr [ -00000.43, 00001.40, -00002.26 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007552 NoMotion-1. Scaled. Acc [ -00042.48, -00018.55, 00994.63 ], Gyr [ 00002.01, 00002.62, -00000.61 ], Mag [ 00021.15, 00083.40, 00107.40 ]",
    "#0000007553 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 01000.00 ], Gyr [ -00000.98, 00001.10, -00002.93 ], Mag [ 00021.15, 00083.40, 00107.40 ]",
    "#0000007554 NoMotion-1. Scaled. Acc [ -00051.76, -00013.67, 00983.40 ], Gyr [ -00002.07, 00004.45, 00000.61 ], Mag [ 00019.50, 00085.05, 00107.10 ]",
    "#0000007555 NoMotion-1. Scaled. Acc [ -00050.29, -00013.67, 00991.70 ], Gyr [ 00000.30, 00001.28, -00002.68 ], Mag [ 00019.50, 00085.05, 00107.10 ]",
    "#0000007556 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 00992.68 ], Gyr [ 00002.07, 00002.26, -00002.01 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007557 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00996.58 ], Gyr [ 00001.59, -00000.24, -00002.62 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007558 NoMotion-1. Scaled. Acc [ -00031.74, -00006.84, 00997.07 ], Gyr [ 00000.55, 00001.95, -00000.79 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007559 NoMotion-1. Scaled. Acc [ -00036.62, -00006.84, 01004.88 ], Gyr [ 00000.06, 00000.91, -00000.43 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007560 NoMotion-1. Scaled. Acc [ -00037.11, -00008.30, 00986.33 ], Gyr [ 00001.40, -00001.04, -00002.74 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007561 NoMotion-1. Scaled. Acc [ -00045.90, -00009.77, 00996.58 ], Gyr [ 00001.71, 00000.85, 00000.55 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007562 NoMotion-1. Scaled. Acc [ -00058.11, -00007.32, 01007.32 ], Gyr [ -00002.13, -00000.98, -00001.40 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007563 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00991.21 ], Gyr [ 00000.61, 00002.56, -00003.96 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007564 NoMotion-1. Scaled. Acc [ -00041.02, -00010.25, 00982.42 ], Gyr [ -00000.18, -00000.37, -00002.56 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007565 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 00978.03 ], Gyr [ -00000.73, 00002.20, -00002.50 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007566 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00996.09 ], Gyr [ -00001.10, 00001.16, -00005.30 ], Mag [ 00019.20, 00084.75, 00107.10 ]",
    "#0000007567 NoMotion-1. Scaled. Acc [ -00039.06, 00006.35, 00982.91 ], Gyr [ 00000.55, 00001.10, -00001.28 ], Mag [ 00018.60, 00084.45, 00106.95 ]",
    "#0000007568 NoMotion-1. Scaled. Acc [ -00051.27, -00017.09, 00986.33 ], Gyr [ -00000.55, 00002.13, -00002.38 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007569 NoMotion-1. Scaled. Acc [ -00049.80, 00000.00, 00981.93 ], Gyr [ 00001.46, 00004.09, 00000.43 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007570 NoMotion-1. Scaled. Acc [ -00059.08, -00013.67, 00991.70 ], Gyr [ 00001.95, 00000.30, -00001.22 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007571 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00998.05 ], Gyr [ 00001.04, 00000.49, -00003.84 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007572 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00999.02 ], Gyr [ -00001.04, 00004.27, 00000.00 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007573 NoMotion-1. Scaled. Acc [ -00038.57, -00004.39, 00986.82 ], Gyr [ -00000.12, 00002.01, -00002.50 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007574 NoMotion-1. Scaled. Acc [ -00037.11, -00014.65, 00984.86 ], Gyr [ 00000.00, 00002.93, -00000.98 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000007575 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00997.07 ], Gyr [ -00000.12, 00003.54, -00001.04 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007576 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 01000.00 ], Gyr [ -00001.89, 00002.13, 00000.30 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007577 NoMotion-1. Scaled. Acc [ -00041.50, -00014.65, 00992.68 ], Gyr [ 00000.06, 00000.61, -00001.95 ], Mag [ 00019.65, 00085.65, 00108.45 ]",
    "#0000007578 NoMotion-1. Scaled. Acc [ -00043.46, -00010.74, 01001.95 ], Gyr [ 00000.24, 00000.85, -00001.10 ], Mag [ 00019.65, 00085.65, 00108.45 ]",
    "#0000007579 NoMotion-1. Scaled. Acc [ -00057.13, -00002.93, 00993.65 ], Gyr [ 00001.16, 00000.12, -00001.52 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007580 NoMotion-1. Scaled. Acc [ -00053.71, -00009.28, 00997.56 ], Gyr [ -00000.06, -00001.10, -00002.74 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007581 NoMotion-1. Scaled. Acc [ -00038.57, -00015.62, 00993.16 ], Gyr [ -00001.04, 00000.73, -00000.79 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007582 NoMotion-1. Scaled. Acc [ -00040.04, -00018.55, 00989.75 ], Gyr [ 00001.46, -00000.49, 00000.24 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007583 NoMotion-1. Scaled. Acc [ -00042.97, -00004.39, 00990.72 ], Gyr [ 00000.49, -00002.13, -00001.34 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000007584 NoMotion-1. Scaled. Acc [ -00057.62, -00001.46, 00986.33 ], Gyr [ -00000.12, 00001.59, -00001.77 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000007585 NoMotion-1. Scaled. Acc [ -00049.80, -00016.11, 00996.58 ], Gyr [ 00000.00, -00001.04, -00002.50 ], Mag [ 00019.35, 00082.95, 00109.05 ]",
    "#0000007586 NoMotion-1. Scaled. Acc [ -00050.78, -00013.18, 00990.72 ], Gyr [ 00002.26, -00001.10, -00001.65 ], Mag [ 00019.35, 00083.40, 00106.20 ]",
    "#0000007587 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 01000.98 ], Gyr [ 00001.22, 00001.04, -00000.43 ], Mag [ 00019.35, 00083.40, 00106.20 ]",
    "#0000007588 NoMotion-1. Scaled. Acc [ -00047.85, -00013.67, 01000.49 ], Gyr [ -00000.18, 00002.26, -00002.56 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007589 NoMotion-1. Scaled. Acc [ -00049.32, -00003.91, 00976.56 ], Gyr [ 00001.95, 00000.85, -00002.32 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007590 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00984.86 ], Gyr [ -00000.91, -00000.12, -00003.11 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007591 NoMotion-1. Scaled. Acc [ -00041.99, -00012.70, 00993.65 ], Gyr [ 00000.67, 00001.77, -00003.05 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007592 NoMotion-1. Scaled. Acc [ -00045.90, -00015.62, 00995.12 ], Gyr [ 00001.52, -00000.06, -00000.73 ], Mag [ 00018.60, 00084.90, 00108.00 ]",
    "#0000007593 NoMotion-1. Scaled. Acc [ -00047.85, -00011.23, 00990.23 ], Gyr [ 00000.85, -00000.73, -00001.22 ], Mag [ 00019.05, 00082.95, 00108.00 ]",
    "#0000007594 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00988.28 ], Gyr [ -00000.12, 00004.02, -00003.05 ], Mag [ 00019.05, 00082.95, 00108.00 ]",
    "#0000007595 NoMotion-1. Scaled. Acc [ -00034.67, -00014.16, 01002.44 ], Gyr [ 00000.79, -00000.79, -00002.44 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007596 NoMotion-1. Scaled. Acc [ -00039.55, 00003.91, 00983.89 ], Gyr [ 00000.98, -00000.73, -00000.37 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007597 NoMotion-1. Scaled. Acc [ -00035.16, -00005.37, 00993.16 ], Gyr [ 00000.30, 00000.18, -00000.79 ], Mag [ 00018.75, 00084.75, 00107.55 ]",
    "#0000007598 NoMotion-1. Scaled. Acc [ -00040.53, -00014.65, 00989.75 ], Gyr [ -00002.38, 00002.07, -00002.13 ], Mag [ 00018.75, 00084.75, 00107.55 ]",
    "#0000007599 NoMotion-1. Scaled. Acc [ -00040.04, -00002.44, 00986.82 ], Gyr [ 00000.30, 00001.04, -00001.89 ], Mag [ 00018.75, 00084.75, 00107.55 ]",
    "#0000007600 NoMotion-1. Scaled. Acc [ -00040.04, -00003.91, 00988.77 ], Gyr [ 00001.77, 00002.93, -00001.04 ], Mag [ 00020.85, 00083.70, 00107.40 ]",
    "#0000007601 NoMotion-1. Scaled. Acc [ -00035.16, -00005.86, 00997.07 ], Gyr [ 00001.04, 00001.59, 00000.00 ], Mag [ 00020.85, 00083.70, 00107.40 ]",
    "#0000007602 NoMotion-1. Scaled. Acc [ -00033.20, 00006.35, 00998.54 ], Gyr [ 00000.85, 00002.01, -00003.72 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007603 NoMotion-1. Scaled. Acc [ -00042.97, 00002.44, 00982.91 ], Gyr [ 00000.37, 00001.46, -00002.38 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007604 NoMotion-1. Scaled. Acc [ -00036.13, -00005.37, 00989.26 ], Gyr [ 00001.46, 00002.62, -00002.20 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007605 NoMotion-1. Scaled. Acc [ -00036.13, -00016.60, 00996.09 ], Gyr [ 00000.43, 00002.99, -00000.98 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007606 NoMotion-1. Scaled. Acc [ -00037.60, -00011.23, 00996.09 ], Gyr [ 00000.37, 00000.12, -00001.16 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007607 NoMotion-1. Scaled. Acc [ -00057.13, 00001.95, 00987.79 ], Gyr [ 00001.89, 00001.46, -00002.20 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007608 NoMotion-1. Scaled. Acc [ -00036.13, -00001.46, 00983.89 ], Gyr [ -00000.37, 00002.13, -00002.01 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007609 NoMotion-1. Scaled. Acc [ -00044.92, 00004.88, 00994.63 ], Gyr [ 00000.61, 00003.48, -00004.51 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007610 NoMotion-1. Scaled. Acc [ -00044.43, -00017.58, 00996.58 ], Gyr [ 00001.71, 00000.55, -00000.73 ], Mag [ 00020.10, 00083.70, 00106.95 ]",
    "#0000007611 NoMotion-1. Scaled. Acc [ -00039.06, -00012.70, 01002.44 ], Gyr [ -00000.30, 00000.85, -00000.61 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007612 NoMotion-1. Scaled. Acc [ -00040.04, -00005.37, 00983.89 ], Gyr [ -00000.49, -00000.43, -00002.80 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007613 NoMotion-1. Scaled. Acc [ -00034.67, -00007.81, 00985.84 ], Gyr [ 00000.30, 00001.04, -00001.52 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007614 NoMotion-1. Scaled. Acc [ -00029.79, -00009.77, 01007.32 ], Gyr [ -00000.24, 00002.87, -00001.46 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007615 NoMotion-1. Scaled. Acc [ -00036.13, 00001.95, 01001.46 ], Gyr [ 00000.12, 00002.62, -00000.98 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007616 NoMotion-1. Scaled. Acc [ -00047.36, 00002.44, 00994.14 ], Gyr [ -00000.91, 00002.80, -00004.09 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007617 NoMotion-1. Scaled. Acc [ -00045.90, -00005.37, 00983.40 ], Gyr [ 00000.98, 00001.77, 00000.43 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007618 NoMotion-1. Scaled. Acc [ -00043.46, -00004.39, 00999.02 ], Gyr [ 00002.07, 00001.77, 00000.91 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007619 NoMotion-1. Scaled. Acc [ -00041.50, -00016.11, 00997.56 ], Gyr [ -00001.16, -00000.73, -00000.55 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007620 NoMotion-1. Scaled. Acc [ -00053.71, -00011.72, 00990.72 ], Gyr [ 00000.30, 00001.95, -00003.11 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007621 NoMotion-1. Scaled. Acc [ -00052.73, -00009.28, 00991.21 ], Gyr [ 00000.85, 00000.12, 00002.38 ], Mag [ 00019.35, 00082.95, 00109.05 ]",
    "#0000007622 NoMotion-1. Scaled. Acc [ -00034.67, -00001.95, 00983.89 ], Gyr [ 00001.22, 00002.38, -00001.71 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000007623 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00983.40 ], Gyr [ 00001.16, 00002.26, -00003.72 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000007624 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00981.45 ], Gyr [ -00001.52, 00000.67, -00001.95 ], Mag [ 00018.45, 00083.55, 00107.55 ]",
    "#0000007625 NoMotion-1. Scaled. Acc [ -00040.53, 00000.49, 00990.23 ], Gyr [ -00001.46, 00002.26, -00000.98 ], Mag [ 00018.45, 00084.75, 00105.75 ]",
    "#0000007626 NoMotion-1. Scaled. Acc [ -00032.71, -00015.62, 00981.45 ], Gyr [ 00000.18, 00003.72, -00000.67 ], Mag [ 00018.45, 00084.75, 00105.75 ]",
    "#0000007627 NoMotion-1. Scaled. Acc [ -00054.69, -00009.77, 01000.00 ], Gyr [ 00000.24, 00000.79, -00001.46 ], Mag [ 00018.45, 00084.75, 00105.75 ]",
    "#0000007628 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00988.77 ], Gyr [ 00000.49, 00002.93, -00000.98 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000007629 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00987.30 ], Gyr [ -00000.49, 00001.71, -00001.52 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000007630 NoMotion-1. Scaled. Acc [ -00053.22, -00007.32, 00992.68 ], Gyr [ 00000.61, 00001.89, 00000.37 ], Mag [ 00020.40, 00082.95, 00106.20 ]",
    "#0000007631 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00996.09 ], Gyr [ 00000.55, 00000.73, -00001.04 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007632 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00994.63 ], Gyr [ 00000.24, 00003.90, -00001.40 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007633 NoMotion-1. Scaled. Acc [ -00033.69, 00002.93, 00991.70 ], Gyr [ 00002.50, 00002.01, -00001.52 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007634 NoMotion-1. Scaled. Acc [ -00063.96, -00005.86, 00994.63 ], Gyr [ 00002.26, -00000.55, 00001.28 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007635 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00978.03 ], Gyr [ 00001.04, 00001.04, -00002.26 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007636 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 01005.37 ], Gyr [ -00001.71, 00000.12, -00003.29 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007637 NoMotion-1. Scaled. Acc [ -00040.04, -00004.39, 00984.86 ], Gyr [ 00000.12, 00001.16, 00000.43 ], Mag [ 00020.70, 00084.00, 00109.35 ]",
    "#0000007638 NoMotion-1. Scaled. Acc [ -00042.97, -00004.39, 00983.89 ], Gyr [ 00001.28, 00001.28, 00001.52 ], Mag [ 00020.70, 00084.00, 00109.35 ]",
    "#0000007639 NoMotion-1. Scaled. Acc [ -00033.69, -00005.86, 00978.52 ], Gyr [ -00000.43, 00001.46, -00001.59 ], Mag [ 00019.95, 00082.50, 00107.85 ]",
    "#0000007640 NoMotion-1. Scaled. Acc [ -00039.55, 00000.00, 00988.77 ], Gyr [ 00002.13, 00000.73, -00003.35 ], Mag [ 00019.95, 00082.50, 00107.85 ]",
    "#0000007641 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00986.33 ], Gyr [ -00000.91, 00001.34, -00000.98 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007642 NoMotion-1. Scaled. Acc [ -00049.80, -00010.74, 00983.89 ], Gyr [ 00000.00, 00001.71, -00003.41 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007643 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00988.77 ], Gyr [ -00000.73, 00002.44, -00002.50 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007644 NoMotion-1. Scaled. Acc [ -00034.67, -00006.35, 00990.23 ], Gyr [ 00000.24, 00001.89, -00000.67 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007645 NoMotion-1. Scaled. Acc [ -00044.43, 00000.49, 00988.77 ], Gyr [ 00000.43, -00000.12, -00002.13 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007646 NoMotion-1. Scaled. Acc [ -00048.34, 00000.00, 00991.70 ], Gyr [ 00002.01, -00000.43, -00000.43 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007647 NoMotion-1. Scaled. Acc [ -00048.34, -00002.93, 00986.82 ], Gyr [ 00000.67, -00001.10, -00002.62 ], Mag [ 00019.65, 00082.65, 00108.45 ]",
    "#0000007648 NoMotion-1. Scaled. Acc [ -00042.48, -00014.65, 00982.91 ], Gyr [ 00000.79, -00000.12, 00000.30 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000007649 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00988.28 ], Gyr [ -00001.16, 00003.96, -00004.09 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000007650 NoMotion-1. Scaled. Acc [ -00047.85, -00004.88, 00995.61 ], Gyr [ 00001.89, 00003.29, -00002.07 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007651 NoMotion-1. Scaled. Acc [ -00046.88, 00000.98, 00987.30 ], Gyr [ -00001.40, 00001.65, -00000.79 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007652 NoMotion-1. Scaled. Acc [ -00050.78, -00011.23, 00988.77 ], Gyr [ -00000.18, 00003.66, -00002.13 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007653 NoMotion-1. Scaled. Acc [ -00048.83, 00000.49, 00997.07 ], Gyr [ 00001.59, 00001.10, -00000.73 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007654 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00983.40 ], Gyr [ 00001.10, -00000.61, -00001.22 ], Mag [ 00019.65, 00082.65, 00106.65 ]",
    "#0000007655 NoMotion-1. Scaled. Acc [ -00035.64, -00008.30, 00994.63 ], Gyr [ 00001.04, 00000.85, 00001.04 ], Mag [ 00019.65, 00082.65, 00106.65 ]",
    "#0000007656 NoMotion-1. Scaled. Acc [ -00050.78, -00006.84, 00992.19 ], Gyr [ 00000.67, 00002.99, -00001.83 ], Mag [ 00020.40, 00082.65, 00106.95 ]",
    "#0000007657 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00995.12 ], Gyr [ 00002.50, 00000.55, -00002.20 ], Mag [ 00020.40, 00082.65, 00106.95 ]",
    "#0000007658 NoMotion-1. Scaled. Acc [ -00032.23, -00023.44, 00978.03 ], Gyr [ 00000.85, -00002.38, -00000.67 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007659 NoMotion-1. Scaled. Acc [ -00043.95, -00004.88, 00988.28 ], Gyr [ 00002.68, 00001.65, 00000.00 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007660 NoMotion-1. Scaled. Acc [ -00032.23, -00010.25, 00991.70 ], Gyr [ -00000.61, -00000.79, 00000.18 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007661 NoMotion-1. Scaled. Acc [ -00045.90, -00008.79, 00997.56 ], Gyr [ -00001.04, 00000.67, -00001.16 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007662 NoMotion-1. Scaled. Acc [ -00047.85, 00000.98, 00998.54 ], Gyr [ 00000.79, -00000.55, -00000.98 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007663 NoMotion-1. Scaled. Acc [ -00046.39, -00012.70, 00996.09 ], Gyr [ -00000.73, 00001.77, -00003.48 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007664 NoMotion-1. Scaled. Acc [ -00041.02, -00012.21, 00985.84 ], Gyr [ -00001.16, 00001.10, -00002.07 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007665 NoMotion-1. Scaled. Acc [ -00048.83, -00011.23, 00984.38 ], Gyr [ 00001.10, -00001.95, -00001.52 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007666 NoMotion-1. Scaled. Acc [ -00042.48, -00005.86, 00997.07 ], Gyr [ 00001.22, 00001.22, -00001.28 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007667 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00991.70 ], Gyr [ 00000.91, 00001.52, 00000.67 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007668 NoMotion-1. Scaled. Acc [ -00043.46, -00014.16, 00987.79 ], Gyr [ -00000.73, 00001.71, 00000.00 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007669 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00994.14 ], Gyr [ 00002.74, 00000.98, -00000.55 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007670 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00999.51 ], Gyr [ 00002.62, -00000.12, -00002.87 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000007671 NoMotion-1. Scaled. Acc [ -00061.04, -00005.37, 01002.44 ], Gyr [ 00001.52, 00001.83, -00002.07 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000007672 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00984.38 ], Gyr [ 00000.24, 00001.46, -00001.04 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007673 NoMotion-1. Scaled. Acc [ -00040.53, -00009.77, 00983.89 ], Gyr [ 00000.43, 00002.13, 00000.49 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007674 NoMotion-1. Scaled. Acc [ -00039.55, -00010.74, 00982.91 ], Gyr [ 00000.67, 00004.76, -00003.96 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007675 NoMotion-1. Scaled. Acc [ -00040.04, -00006.84, 01003.42 ], Gyr [ 00001.89, -00000.43, -00000.37 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007676 NoMotion-1. Scaled. Acc [ -00056.15, -00009.28, 00991.70 ], Gyr [ -00000.49, 00002.74, -00002.44 ], Mag [ 00019.65, 00084.15, 00108.75 ]",
    "#0000007677 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00983.40 ], Gyr [ 00001.95, -00000.61, -00001.65 ], Mag [ 00019.65, 00084.15, 00108.75 ]",
    "#0000007678 NoMotion-1. Scaled. Acc [ -00044.43, -00013.67, 00991.21 ], Gyr [ 00001.46, 00000.37, -00000.61 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000007679 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 00993.65 ], Gyr [ 00000.49, 00001.16, -00002.80 ], Mag [ 00018.45, 00083.55, 00106.50 ]",
    "#0000007680 NoMotion-1. Scaled. Acc [ -00050.78, -00014.16, 00983.89 ], Gyr [ 00002.62, 00001.89, -00001.34 ], Mag [ 00018.45, 00083.55, 00106.50 ]",
    "#0000007681 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00991.70 ], Gyr [ 00002.50, 00003.66, -00003.54 ], Mag [ 00018.45, 00083.55, 00106.50 ]",
    "#0000007682 NoMotion-1. Scaled. Acc [ -00051.76, -00013.67, 00985.35 ], Gyr [ -00001.04, 00000.43, -00002.32 ], Mag [ 00018.60, 00082.20, 00108.45 ]",
    "#0000007683 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00997.56 ], Gyr [ 00000.79, 00001.89, -00001.71 ], Mag [ 00019.05, 00084.15, 00107.70 ]",
    "#0000007684 NoMotion-1. Scaled. Acc [ -00053.22, -00003.91, 00997.56 ], Gyr [ 00000.55, 00002.99, -00002.50 ], Mag [ 00019.05, 00084.15, 00107.70 ]",
    "#0000007685 NoMotion-1. Scaled. Acc [ -00031.74, -00003.42, 00990.23 ], Gyr [ 00001.10, 00000.55, -00003.35 ], Mag [ 00018.45, 00083.25, 00106.50 ]",
    "#0000007686 NoMotion-1. Scaled. Acc [ -00045.41, 00002.93, 00999.51 ], Gyr [ -00001.22, 00002.56, -00001.65 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007687 NoMotion-1. Scaled. Acc [ -00042.48, -00024.41, 00991.21 ], Gyr [ 00000.43, 00000.55, 00002.56 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007688 NoMotion-1. Scaled. Acc [ -00033.20, 00004.88, 00985.35 ], Gyr [ -00001.04, 00001.16, -00001.83 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007689 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 00991.21 ], Gyr [ 00000.06, 00003.29, -00001.59 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007690 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00994.14 ], Gyr [ 00001.28, 00002.07, 00000.06 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007691 NoMotion-1. Scaled. Acc [ -00038.09, -00005.86, 00989.26 ], Gyr [ 00001.52, -00000.85, 00000.91 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007692 NoMotion-1. Scaled. Acc [ -00039.06, -00015.62, 00986.33 ], Gyr [ -00001.46, 00004.09, -00001.28 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000007693 NoMotion-1. Scaled. Acc [ -00044.92, 00009.28, 00998.05 ], Gyr [ -00000.79, 00002.68, -00002.01 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000007694 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00990.23 ], Gyr [ 00000.18, 00000.85, -00003.60 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000007695 NoMotion-1. Scaled. Acc [ -00036.62, -00007.81, 00984.86 ], Gyr [ 00001.04, 00001.16, -00001.95 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000007696 NoMotion-1. Scaled. Acc [ -00041.99, -00003.42, 00998.05 ], Gyr [ 00000.61, 00000.18, -00002.26 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007697 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00991.21 ], Gyr [ 00000.30, 00004.63, -00000.37 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007698 NoMotion-1. Scaled. Acc [ -00051.27, -00014.65, 00996.09 ], Gyr [ -00001.40, 00003.11, -00001.71 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007699 NoMotion-1. Scaled. Acc [ -00048.83, 00003.42, 00997.07 ], Gyr [ -00000.49, 00002.74, -00000.79 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007700 NoMotion-1. Scaled. Acc [ -00045.90, -00004.88, 01000.98 ], Gyr [ 00000.67, 00001.04, -00003.05 ], Mag [ 00019.95, 00083.25, 00107.55 ]",
    "#0000007701 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00980.47 ], Gyr [ -00000.12, 00002.01, -00000.30 ], Mag [ 00019.95, 00083.25, 00107.55 ]",
    "#0000007702 NoMotion-1. Scaled. Acc [ -00037.11, -00014.16, 00994.63 ], Gyr [ -00000.24, 00000.67, -00003.17 ], Mag [ 00018.75, 00084.00, 00108.60 ]",
    "#0000007703 NoMotion-1. Scaled. Acc [ -00043.95, -00013.67, 00984.86 ], Gyr [ 00000.55, -00000.06, -00001.59 ], Mag [ 00018.75, 00084.00, 00108.60 ]",
    "#0000007704 NoMotion-1. Scaled. Acc [ -00034.18, -00017.09, 00987.30 ], Gyr [ 00002.44, 00001.95, -00003.48 ], Mag [ 00018.75, 00083.25, 00106.50 ]",
    "#0000007705 NoMotion-1. Scaled. Acc [ -00038.09, -00019.04, 00972.66 ], Gyr [ 00000.55, 00000.49, 00002.38 ], Mag [ 00017.55, 00083.70, 00107.40 ]",
    "#0000007706 NoMotion-1. Scaled. Acc [ -00044.43, -00019.04, 00988.77 ], Gyr [ -00000.67, 00002.80, -00003.90 ], Mag [ 00017.55, 00083.70, 00107.40 ]",
    "#0000007707 NoMotion-1. Scaled. Acc [ -00037.60, -00010.74, 00983.40 ], Gyr [ 00002.93, -00001.89, 00000.12 ], Mag [ 00017.55, 00083.70, 00107.40 ]",
    "#0000007708 NoMotion-1. Scaled. Acc [ -00042.97, -00008.30, 00985.84 ], Gyr [ 00001.10, 00001.71, -00000.24 ], Mag [ 00019.65, 00085.65, 00109.50 ]",
    "#0000007709 NoMotion-1. Scaled. Acc [ -00035.64, -00006.84, 00998.05 ], Gyr [ 00000.98, 00000.43, -00001.46 ], Mag [ 00019.65, 00085.65, 00109.50 ]",
    "#0000007710 NoMotion-1. Scaled. Acc [ -00032.23, -00012.21, 00984.86 ], Gyr [ 00000.18, 00000.30, -00000.91 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000007711 NoMotion-1. Scaled. Acc [ -00048.34, -00012.21, 00991.70 ], Gyr [ -00000.98, 00005.67, -00003.54 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000007712 NoMotion-1. Scaled. Acc [ -00046.39, -00006.84, 00999.51 ], Gyr [ 00000.98, 00004.76, -00003.66 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000007713 NoMotion-1. Scaled. Acc [ -00032.23, -00007.81, 00977.05 ], Gyr [ 00000.00, 00002.44, -00001.28 ], Mag [ 00018.75, 00083.25, 00107.10 ]",
    "#0000007714 NoMotion-1. Scaled. Acc [ -00037.11, -00008.30, 00989.75 ], Gyr [ 00000.12, 00000.43, -00002.13 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007715 NoMotion-1. Scaled. Acc [ -00032.23, -00006.35, 00995.61 ], Gyr [ -00001.04, 00001.16, -00000.98 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007716 NoMotion-1. Scaled. Acc [ -00054.69, -00025.88, 00991.21 ], Gyr [ 00000.98, 00000.79, 00001.89 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007717 NoMotion-1. Scaled. Acc [ -00051.27, 00003.91, 00994.14 ], Gyr [ 00003.23, 00000.55, -00001.95 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007718 NoMotion-1. Scaled. Acc [ -00046.39, -00006.84, 01007.32 ], Gyr [ -00000.37, 00000.55, -00001.59 ], Mag [ 00019.20, 00084.75, 00107.85 ]",
    "#0000007719 NoMotion-1. Scaled. Acc [ -00055.18, 00000.00, 00991.21 ], Gyr [ 00002.80, 00000.49, -00001.28 ], Mag [ 00019.20, 00084.75, 00107.85 ]",
    "#0000007720 NoMotion-1. Scaled. Acc [ -00055.18, -00020.02, 00989.75 ], Gyr [ 00001.59, 00000.12, -00002.44 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007721 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00997.07 ], Gyr [ -00001.34, 00001.28, -00001.59 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007722 NoMotion-1. Scaled. Acc [ -00024.90, -00008.79, 00988.28 ], Gyr [ 00000.30, 00000.67, -00000.43 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007723 NoMotion-1. Scaled. Acc [ -00047.85, 00007.81, 00992.68 ], Gyr [ 00000.61, 00001.10, -00002.13 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007724 NoMotion-1. Scaled. Acc [ -00061.52, -00026.37, 00995.61 ], Gyr [ 00000.85, 00002.93, 00000.85 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007725 NoMotion-1. Scaled. Acc [ -00041.02, 00009.77, 00996.09 ], Gyr [ 00000.18, 00002.13, -00001.83 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007726 NoMotion-1. Scaled. Acc [ -00038.09, -00007.32, 00996.58 ], Gyr [ 00001.46, 00001.83, -00000.37 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007727 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00993.65 ], Gyr [ 00001.89, 00002.80, -00001.46 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000007728 NoMotion-1. Scaled. Acc [ -00049.32, -00009.77, 00989.26 ], Gyr [ 00002.01, 00000.67, -00001.22 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000007729 NoMotion-1. Scaled. Acc [ -00042.97, -00004.88, 00996.09 ], Gyr [ -00000.61, 00002.38, -00001.28 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007730 NoMotion-1. Scaled. Acc [ -00053.71, 00002.44, 00986.82 ], Gyr [ 00001.95, 00000.24, -00000.43 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007731 NoMotion-1. Scaled. Acc [ -00043.95, -00015.14, 00985.84 ], Gyr [ 00001.10, 00002.56, 00000.00 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007732 NoMotion-1. Scaled. Acc [ -00034.18, -00018.07, 00995.12 ], Gyr [ 00002.62, -00000.37, -00002.44 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007733 NoMotion-1. Scaled. Acc [ -00035.64, -00014.65, 00989.75 ], Gyr [ 00002.44, -00000.67, 00001.04 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007734 NoMotion-1. Scaled. Acc [ -00038.57, -00004.39, 00982.42 ], Gyr [ 00000.06, 00002.38, -00000.67 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007735 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 01000.00 ], Gyr [ 00002.01, 00002.13, -00002.38 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007736 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00977.05 ], Gyr [ 00000.37, 00000.61, -00002.20 ], Mag [ 00020.70, 00082.80, 00108.60 ]",
    "#0000007737 NoMotion-1. Scaled. Acc [ -00036.62, -00007.81, 00986.33 ], Gyr [ 00000.00, -00000.37, 00000.55 ], Mag [ 00020.70, 00082.80, 00108.60 ]",
    "#0000007738 NoMotion-1. Scaled. Acc [ -00060.06, -00014.65, 00995.61 ], Gyr [ -00000.85, 00002.32, -00004.21 ], Mag [ 00019.95, 00082.80, 00108.60 ]",
    "#0000007739 NoMotion-1. Scaled. Acc [ -00060.06, -00016.60, 00990.23 ], Gyr [ 00000.12, 00001.95, -00002.80 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000007740 NoMotion-1. Scaled. Acc [ -00052.25, -00003.42, 00986.82 ], Gyr [ 00001.46, -00000.49, -00000.79 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000007741 NoMotion-1. Scaled. Acc [ -00048.34, 00002.44, 00990.23 ], Gyr [ 00002.68, 00002.93, -00000.43 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007742 NoMotion-1. Scaled. Acc [ -00056.15, 00005.86, 00988.28 ], Gyr [ -00000.91, 00000.73, -00004.39 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007743 NoMotion-1. Scaled. Acc [ -00032.71, -00005.37, 00986.33 ], Gyr [ 00001.28, 00001.65, -00002.74 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007744 NoMotion-1. Scaled. Acc [ -00045.90, 00002.93, 00990.72 ], Gyr [ 00000.18, 00001.65, -00001.71 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007745 NoMotion-1. Scaled. Acc [ -00038.57, -00007.32, 00982.91 ], Gyr [ -00001.22, 00002.01, -00001.28 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007746 NoMotion-1. Scaled. Acc [ -00056.64, -00010.74, 00985.84 ], Gyr [ 00000.43, 00000.98, -00003.29 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007747 NoMotion-1. Scaled. Acc [ -00049.32, -00004.39, 00995.12 ], Gyr [ 00001.77, 00000.55, -00001.71 ], Mag [ 00019.50, 00084.30, 00107.85 ]",
    "#0000007748 NoMotion-1. Scaled. Acc [ -00039.55, -00003.42, 00985.84 ], Gyr [ 00000.18, 00004.88, -00002.13 ], Mag [ 00019.50, 00084.30, 00107.85 ]",
    "#0000007749 NoMotion-1. Scaled. Acc [ -00048.83, 00009.28, 00985.84 ], Gyr [ 00000.43, 00002.38, -00000.30 ], Mag [ 00019.20, 00084.30, 00107.85 ]",
    "#0000007750 NoMotion-1. Scaled. Acc [ -00025.88, -00001.46, 00987.79 ], Gyr [ -00001.22, 00003.66, -00003.90 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000007751 NoMotion-1. Scaled. Acc [ -00027.83, -00005.37, 00990.23 ], Gyr [ -00001.59, 00002.56, 00001.40 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000007752 NoMotion-1. Scaled. Acc [ -00046.88, -00017.09, 00988.77 ], Gyr [ -00000.24, 00002.26, -00003.05 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000007753 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00997.56 ], Gyr [ 00000.85, 00004.09, -00000.73 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000007754 NoMotion-1. Scaled. Acc [ -00036.62, -00017.58, 00986.82 ], Gyr [ 00002.20, -00000.49, -00003.05 ], Mag [ 00018.75, 00082.50, 00107.10 ]",
    "#0000007755 NoMotion-1. Scaled. Acc [ -00057.13, -00009.28, 00997.56 ], Gyr [ 00001.40, 00000.91, -00000.85 ], Mag [ 00018.75, 00082.50, 00107.10 ]",
    "#0000007756 NoMotion-1. Scaled. Acc [ -00038.57, 00000.98, 00995.12 ], Gyr [ 00000.73, 00002.68, -00001.83 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007757 NoMotion-1. Scaled. Acc [ -00053.71, -00002.44, 00992.68 ], Gyr [ -00000.37, -00000.12, -00002.13 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007758 NoMotion-1. Scaled. Acc [ -00051.27, -00015.14, 00988.28 ], Gyr [ 00002.07, 00002.99, 00000.37 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007759 NoMotion-1. Scaled. Acc [ -00048.83, -00013.67, 00991.21 ], Gyr [ 00003.05, 00000.24, -00002.07 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007760 NoMotion-1. Scaled. Acc [ -00049.80, -00008.30, 00986.33 ], Gyr [ 00000.61, 00000.24, -00002.26 ], Mag [ 00019.35, 00082.65, 00106.65 ]",
    "#0000007761 NoMotion-1. Scaled. Acc [ -00037.60, 00004.88, 00979.98 ], Gyr [ -00000.12, 00000.85, -00003.72 ], Mag [ 00019.35, 00082.65, 00106.65 ]",
    "#0000007762 NoMotion-1. Scaled. Acc [ -00049.80, -00017.58, 01004.88 ], Gyr [ -00000.55, 00001.59, -00003.66 ], Mag [ 00019.35, 00082.65, 00106.65 ]",
    "#0000007763 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00997.07 ], Gyr [ 00001.59, 00002.68, -00001.83 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000007764 NoMotion-1. Scaled. Acc [ -00046.88, -00012.70, 01005.37 ], Gyr [ -00000.91, 00001.28, -00000.55 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000007765 NoMotion-1. Scaled. Acc [ -00029.30, -00013.67, 00985.35 ], Gyr [ 00000.37, 00001.65, -00002.56 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000007766 NoMotion-1. Scaled. Acc [ -00042.97, -00019.04, 00991.21 ], Gyr [ 00000.91, 00003.05, -00000.24 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000007767 NoMotion-1. Scaled. Acc [ -00054.20, 00000.49, 00986.82 ], Gyr [ 00000.91, 00002.26, -00000.49 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007768 NoMotion-1. Scaled. Acc [ -00041.99, 00003.91, 00982.91 ], Gyr [ 00001.40, 00001.77, -00002.01 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007769 NoMotion-1. Scaled. Acc [ -00041.50, 00001.46, 00997.07 ], Gyr [ 00001.28, 00000.91, -00002.20 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007770 NoMotion-1. Scaled. Acc [ -00034.67, -00002.44, 00992.68 ], Gyr [ 00000.73, 00001.34, -00002.01 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007771 NoMotion-1. Scaled. Acc [ -00045.41, -00016.60, 00986.33 ], Gyr [ 00001.34, 00000.73, -00000.79 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007772 NoMotion-1. Scaled. Acc [ -00039.06, -00003.42, 00995.61 ], Gyr [ 00000.91, 00001.16, -00002.93 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000007773 NoMotion-1. Scaled. Acc [ -00051.27, -00003.91, 00988.77 ], Gyr [ 00000.24, 00001.16, -00000.49 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000007774 NoMotion-1. Scaled. Acc [ -00042.97, -00016.60, 00985.84 ], Gyr [ -00000.49, 00000.55, -00002.62 ], Mag [ 00020.40, 00084.90, 00107.70 ]",
    "#0000007775 NoMotion-1. Scaled. Acc [ -00040.04, -00007.81, 00994.63 ], Gyr [ 00000.00, -00000.49, -00002.32 ], Mag [ 00020.40, 00084.90, 00107.70 ]",
    "#0000007776 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00993.16 ], Gyr [ -00000.12, 00000.98, -00001.83 ], Mag [ 00020.25, 00084.00, 00106.80 ]",
    "#0000007777 NoMotion-1. Scaled. Acc [ -00047.85, -00013.67, 00986.33 ], Gyr [ 00001.95, 00003.78, 00000.00 ], Mag [ 00020.25, 00084.00, 00106.80 ]",
    "#0000007778 NoMotion-1. Scaled. Acc [ -00059.57, -00010.25, 00989.75 ], Gyr [ 00000.24, 00002.32, -00002.68 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000007779 NoMotion-1. Scaled. Acc [ -00053.71, -00007.81, 00988.77 ], Gyr [ 00000.55, 00000.79, -00000.12 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000007780 NoMotion-1. Scaled. Acc [ -00056.15, -00020.51, 00993.16 ], Gyr [ 00001.04, 00001.59, -00001.77 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000007781 NoMotion-1. Scaled. Acc [ -00050.78, -00012.21, 00987.30 ], Gyr [ 00002.87, 00000.49, -00000.30 ], Mag [ 00018.45, 00082.80, 00107.55 ]",
    "#0000007782 NoMotion-1. Scaled. Acc [ -00049.80, -00010.25, 00990.23 ], Gyr [ 00001.65, 00001.22, -00000.12 ], Mag [ 00018.45, 00082.80, 00107.55 ]",
    "#0000007783 NoMotion-1. Scaled. Acc [ -00032.71, -00002.93, 00989.26 ], Gyr [ 00002.68, -00001.89, -00001.52 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007784 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00981.93 ], Gyr [ -00001.10, 00001.46, -00000.43 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007785 NoMotion-1. Scaled. Acc [ -00046.88, -00004.88, 00996.09 ], Gyr [ -00000.37, 00002.87, -00003.54 ], Mag [ 00018.60, 00082.65, 00106.65 ]",
    "#0000007786 NoMotion-1. Scaled. Acc [ -00042.48, -00010.25, 00989.75 ], Gyr [ -00000.30, -00001.59, -00000.24 ], Mag [ 00018.60, 00082.65, 00106.65 ]",
    "#0000007787 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00991.21 ], Gyr [ 00002.20, -00000.06, -00001.22 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000007788 NoMotion-1. Scaled. Acc [ -00047.36, -00001.95, 00998.54 ], Gyr [ -00001.46, 00001.40, -00001.52 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000007789 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 00986.33 ], Gyr [ 00001.71, 00006.89, -00003.78 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000007790 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00995.61 ], Gyr [ 00000.67, 00002.38, -00003.66 ], Mag [ 00018.60, 00083.70, 00109.05 ]",
    "#0000007791 NoMotion-1. Scaled. Acc [ -00051.27, -00012.21, 00997.07 ], Gyr [ 00001.28, 00001.40, 00000.24 ], Mag [ 00020.70, 00082.50, 00106.50 ]",
    "#0000007792 NoMotion-1. Scaled. Acc [ -00046.39, -00012.21, 00989.75 ], Gyr [ 00001.77, 00001.04, -00001.34 ], Mag [ 00020.70, 00082.50, 00106.50 ]",
    "#0000007793 NoMotion-1. Scaled. Acc [ -00053.22, -00011.23, 00987.79 ], Gyr [ 00001.10, 00000.67, -00000.98 ], Mag [ 00019.95, 00083.25, 00107.10 ]",
    "#0000007794 NoMotion-1. Scaled. Acc [ -00042.97, -00006.35, 00983.89 ], Gyr [ 00000.00, 00001.77, -00003.05 ], Mag [ 00019.95, 00083.25, 00107.10 ]",
    "#0000007795 NoMotion-1. Scaled. Acc [ -00041.02, 00001.46, 00984.38 ], Gyr [ 00001.89, 00001.40, 00001.83 ], Mag [ 00019.95, 00083.25, 00107.10 ]",
    "#0000007796 NoMotion-1. Scaled. Acc [ -00039.06, -00021.97, 00986.33 ], Gyr [ -00000.79, 00002.32, -00001.65 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000007797 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00985.35 ], Gyr [ -00000.79, 00000.98, -00001.52 ], Mag [ 00017.70, 00084.30, 00108.30 ]",
    "#0000007798 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 01005.86 ], Gyr [ -00001.04, 00003.29, -00002.44 ], Mag [ 00017.70, 00084.30, 00108.30 ]",
    "#0000007799 NoMotion-1. Scaled. Acc [ -00033.69, -00008.30, 00987.30 ], Gyr [ -00000.24, 00002.01, 00000.79 ], Mag [ 00017.70, 00084.30, 00108.30 ]",
    "#0000007800 NoMotion-1. Scaled. Acc [ -00043.46, -00011.72, 00999.02 ], Gyr [ 00000.24, 00002.62, -00004.15 ], Mag [ 00018.75, 00084.00, 00107.55 ]",
    "#0000007801 NoMotion-1. Scaled. Acc [ -00038.57, 00005.86, 00992.19 ], Gyr [ -00000.55, 00002.62, -00004.76 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007802 NoMotion-1. Scaled. Acc [ -00042.97, 00005.86, 00986.33 ], Gyr [ 00000.30, 00000.61, -00002.74 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007803 NoMotion-1. Scaled. Acc [ -00050.78, -00013.67, 01002.93 ], Gyr [ 00000.06, -00000.67, -00001.28 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007804 NoMotion-1. Scaled. Acc [ -00031.25, -00003.91, 00991.70 ], Gyr [ 00001.28, 00001.95, -00000.79 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007805 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00980.47 ], Gyr [ 00001.59, 00002.62, 00001.77 ], Mag [ 00019.65, 00084.90, 00107.70 ]",
    "#0000007806 NoMotion-1. Scaled. Acc [ -00040.53, 00003.42, 00989.26 ], Gyr [ 00002.80, 00002.26, -00001.28 ], Mag [ 00019.65, 00084.90, 00107.70 ]",
    "#0000007807 NoMotion-1. Scaled. Acc [ -00026.86, -00003.91, 00983.89 ], Gyr [ 00001.34, -00000.91, -00000.18 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007808 NoMotion-1. Scaled. Acc [ -00041.99, -00004.39, 00989.26 ], Gyr [ -00001.04, 00000.49, -00000.12 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007809 NoMotion-1. Scaled. Acc [ -00047.36, -00002.44, 00998.05 ], Gyr [ -00000.67, 00001.16, -00002.38 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000007810 NoMotion-1. Scaled. Acc [ -00039.55, -00008.30, 00998.05 ], Gyr [ 00001.10, 00000.85, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000007811 NoMotion-1. Scaled. Acc [ -00057.62, -00004.39, 00991.70 ], Gyr [ 00000.18, 00001.16, -00000.79 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007812 NoMotion-1. Scaled. Acc [ -00037.60, -00011.23, 00982.91 ], Gyr [ -00000.79, 00002.62, -00003.05 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007813 NoMotion-1. Scaled. Acc [ -00041.02, 00000.98, 00994.14 ], Gyr [ -00002.13, 00002.99, -00001.59 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007814 NoMotion-1. Scaled. Acc [ -00047.36, -00016.60, 00986.82 ], Gyr [ 00000.43, 00001.28, -00000.30 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007815 NoMotion-1. Scaled. Acc [ -00047.36, -00016.11, 00990.72 ], Gyr [ 00002.50, 00000.67, -00002.74 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007816 NoMotion-1. Scaled. Acc [ -00037.60, -00000.98, 00981.45 ], Gyr [ 00000.55, 00003.41, 00000.06 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007817 NoMotion-1. Scaled. Acc [ -00039.55, -00016.11, 00999.02 ], Gyr [ -00000.79, 00001.40, -00002.50 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007818 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00993.16 ], Gyr [ 00002.38, 00000.00, -00002.26 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007819 NoMotion-1. Scaled. Acc [ -00033.69, -00004.39, 01002.93 ], Gyr [ 00000.18, -00000.98, 00000.85 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007820 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00985.35 ], Gyr [ 00000.37, 00000.24, -00001.77 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007821 NoMotion-1. Scaled. Acc [ -00055.18, -00015.14, 00985.84 ], Gyr [ 00000.43, -00001.65, -00002.38 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007822 NoMotion-1. Scaled. Acc [ -00045.90, -00009.28, 01000.98 ], Gyr [ 00001.40, -00001.40, 00002.20 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007823 NoMotion-1. Scaled. Acc [ -00038.09, -00013.18, 00991.21 ], Gyr [ -00001.34, 00000.91, -00003.84 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007824 NoMotion-1. Scaled. Acc [ -00053.22, 00008.30, 00991.70 ], Gyr [ -00001.71, 00003.84, 00000.30 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007825 NoMotion-1. Scaled. Acc [ -00048.34, -00002.44, 00993.16 ], Gyr [ -00000.12, 00003.54, -00001.52 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007826 NoMotion-1. Scaled. Acc [ -00039.06, -00007.32, 00991.70 ], Gyr [ 00002.44, 00002.87, -00000.43 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007827 NoMotion-1. Scaled. Acc [ -00039.55, -00010.74, 00999.02 ], Gyr [ -00000.18, 00001.28, -00000.24 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007828 NoMotion-1. Scaled. Acc [ -00029.30, -00009.77, 00988.77 ], Gyr [ 00001.10, 00001.52, 00000.43 ], Mag [ 00019.35, 00084.45, 00107.40 ]",
    "#0000007829 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 01000.98 ], Gyr [ 00000.55, 00001.40, -00001.95 ], Mag [ 00019.50, 00082.50, 00106.80 ]",
    "#0000007830 NoMotion-1. Scaled. Acc [ -00031.74, -00003.91, 00988.77 ], Gyr [ 00002.62, 00001.34, -00001.16 ], Mag [ 00019.50, 00082.50, 00106.80 ]",
    "#0000007831 NoMotion-1. Scaled. Acc [ -00043.95, -00004.88, 00995.61 ], Gyr [ -00000.37, 00001.65, -00000.98 ], Mag [ 00019.50, 00082.50, 00106.80 ]",
    "#0000007832 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00984.38 ], Gyr [ 00001.28, 00003.60, 00000.24 ], Mag [ 00019.50, 00082.80, 00106.80 ]",
    "#0000007833 NoMotion-1. Scaled. Acc [ -00038.09, -00008.30, 00990.23 ], Gyr [ -00000.18, 00000.55, -00003.05 ], Mag [ 00019.50, 00082.80, 00106.80 ]",
    "#0000007834 NoMotion-1. Scaled. Acc [ -00037.60, -00001.95, 00997.56 ], Gyr [ -00000.79, 00000.49, -00000.55 ], Mag [ 00017.55, 00083.40, 00107.40 ]",
    "#0000007835 NoMotion-1. Scaled. Acc [ -00043.95, -00002.93, 00981.93 ], Gyr [ 00002.50, 00004.27, -00000.55 ], Mag [ 00017.55, 00083.40, 00107.40 ]",
    "#0000007836 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00990.23 ], Gyr [ 00000.49, 00004.27, -00002.87 ], Mag [ 00017.55, 00083.40, 00107.40 ]",
    "#0000007837 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 00988.28 ], Gyr [ -00001.77, 00000.85, -00003.17 ], Mag [ 00020.70, 00084.75, 00108.90 ]",
    "#0000007838 NoMotion-1. Scaled. Acc [ -00048.34, -00017.58, 00979.98 ], Gyr [ 00001.52, 00001.04, -00000.18 ], Mag [ 00020.70, 00084.75, 00108.90 ]",
    "#0000007839 NoMotion-1. Scaled. Acc [ -00039.55, 00005.86, 00992.19 ], Gyr [ -00000.85, 00001.04, -00000.06 ], Mag [ 00019.95, 00083.55, 00107.10 ]",
    "#0000007840 NoMotion-1. Scaled. Acc [ -00056.64, -00002.93, 00992.19 ], Gyr [ 00000.43, 00002.99, -00001.77 ], Mag [ 00020.40, 00083.55, 00107.10 ]",
    "#0000007841 NoMotion-1. Scaled. Acc [ -00036.13, -00005.86, 00984.86 ], Gyr [ 00001.16, -00002.01, 00000.85 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007842 NoMotion-1. Scaled. Acc [ -00047.85, -00007.32, 00996.58 ], Gyr [ 00001.71, -00001.28, -00001.34 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007843 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00988.77 ], Gyr [ 00000.12, 00001.77, -00002.26 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007844 NoMotion-1. Scaled. Acc [ -00048.34, -00012.70, 00999.51 ], Gyr [ 00001.65, -00001.65, 00000.67 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007845 NoMotion-1. Scaled. Acc [ -00049.80, -00006.35, 00987.30 ], Gyr [ 00002.13, -00000.18, -00002.13 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007846 NoMotion-1. Scaled. Acc [ -00041.50, 00001.95, 00980.47 ], Gyr [ 00000.24, 00002.93, -00000.98 ], Mag [ 00019.95, 00084.00, 00106.50 ]",
    "#0000007847 NoMotion-1. Scaled. Acc [ -00034.67, 00000.98, 00987.79 ], Gyr [ -00000.18, 00005.85, -00002.44 ], Mag [ 00019.95, 00084.00, 00106.50 ]",
    "#0000007848 NoMotion-1. Scaled. Acc [ -00040.53, -00014.65, 00990.23 ], Gyr [ 00000.49, 00001.89, -00001.34 ], Mag [ 00021.00, 00085.05, 00109.35 ]",
    "#0000007849 NoMotion-1. Scaled. Acc [ -00051.27, -00009.28, 00991.70 ], Gyr [ 00000.91, 00002.20, -00001.40 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007850 NoMotion-1. Scaled. Acc [ -00048.34, -00001.95, 01000.49 ], Gyr [ 00000.30, -00000.24, 00000.98 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007851 NoMotion-1. Scaled. Acc [ -00040.53, -00001.95, 00989.75 ], Gyr [ 00000.73, 00002.07, -00002.07 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000007852 NoMotion-1. Scaled. Acc [ -00045.90, -00001.46, 01000.49 ], Gyr [ 00000.00, 00001.28, -00000.73 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000007853 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 01001.46 ], Gyr [ 00001.16, 00000.91, -00002.80 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000007854 NoMotion-1. Scaled. Acc [ -00036.13, -00006.84, 00983.89 ], Gyr [ 00002.62, 00002.07, -00002.44 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007855 NoMotion-1. Scaled. Acc [ -00046.39, -00010.74, 00989.26 ], Gyr [ 00001.40, 00000.24, -00002.26 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007856 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00994.63 ], Gyr [ 00000.30, 00001.52, -00001.04 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000007857 NoMotion-1. Scaled. Acc [ -00036.13, -00009.77, 00990.23 ], Gyr [ 00000.43, 00003.17, -00000.91 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000007858 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00985.35 ], Gyr [ 00000.12, 00007.32, -00001.77 ], Mag [ 00018.60, 00084.90, 00107.70 ]",
    "#0000007859 NoMotion-1. Scaled. Acc [ -00039.55, -00007.81, 00998.05 ], Gyr [ -00000.61, 00001.52, -00000.79 ], Mag [ 00018.60, 00084.90, 00107.70 ]",
    "#0000007860 NoMotion-1. Scaled. Acc [ -00038.09, -00002.93, 00980.47 ], Gyr [ 00001.89, 00000.55, 00000.79 ], Mag [ 00020.10, 00083.70, 00105.90 ]",
    "#0000007861 NoMotion-1. Scaled. Acc [ -00037.60, -00011.72, 00989.26 ], Gyr [ 00001.10, 00001.40, -00003.48 ], Mag [ 00020.10, 00083.70, 00105.90 ]",
    "#0000007862 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00996.58 ], Gyr [ 00001.22, 00002.56, 00000.06 ], Mag [ 00020.10, 00083.70, 00105.90 ]",
    "#0000007863 NoMotion-1. Scaled. Acc [ -00022.95, -00011.23, 00978.03 ], Gyr [ 00002.56, 00001.34, 00001.16 ], Mag [ 00021.30, 00083.55, 00108.30 ]",
    "#0000007864 NoMotion-1. Scaled. Acc [ -00044.43, -00007.81, 01006.35 ], Gyr [ 00001.65, 00002.74, -00004.45 ], Mag [ 00021.30, 00083.55, 00108.30 ]",
    "#0000007865 NoMotion-1. Scaled. Acc [ -00030.27, -00001.95, 00983.89 ], Gyr [ 00001.04, 00001.77, 00000.73 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007866 NoMotion-1. Scaled. Acc [ -00040.53, 00004.88, 00989.26 ], Gyr [ 00000.43, 00001.83, -00002.13 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007867 NoMotion-1. Scaled. Acc [ -00045.90, -00001.95, 00978.52 ], Gyr [ 00000.00, 00001.52, 00000.91 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000007868 NoMotion-1. Scaled. Acc [ -00043.46, -00005.86, 00989.75 ], Gyr [ 00001.04, -00001.95, -00000.61 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000007869 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00985.84 ], Gyr [ 00001.22, 00002.93, 00000.00 ], Mag [ 00019.95, 00082.05, 00107.85 ]",
    "#0000007870 NoMotion-1. Scaled. Acc [ -00043.46, -00000.49, 00993.65 ], Gyr [ 00000.37, 00001.46, -00003.72 ], Mag [ 00019.95, 00082.05, 00107.85 ]",
    "#0000007871 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00997.07 ], Gyr [ 00000.98, 00001.46, -00001.28 ], Mag [ 00019.95, 00082.05, 00107.85 ]",
    "#0000007872 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00991.21 ], Gyr [ 00000.61, 00001.71, -00000.37 ], Mag [ 00019.50, 00084.00, 00105.75 ]",
    "#0000007873 NoMotion-1. Scaled. Acc [ -00033.20, -00011.23, 00986.33 ], Gyr [ -00000.55, 00000.98, -00003.05 ], Mag [ 00019.50, 00084.00, 00105.75 ]",
    "#0000007874 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00995.61 ], Gyr [ -00001.28, 00001.40, 00000.12 ], Mag [ 00020.25, 00085.05, 00107.10 ]",
    "#0000007875 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00999.51 ], Gyr [ 00000.79, 00001.95, -00000.79 ], Mag [ 00020.25, 00085.05, 00107.10 ]",
    "#0000007876 NoMotion-1. Scaled. Acc [ -00038.09, -00002.93, 00994.63 ], Gyr [ 00001.71, 00001.46, -00001.89 ], Mag [ 00020.25, 00085.05, 00107.10 ]",
    "#0000007877 NoMotion-1. Scaled. Acc [ -00048.34, 00000.49, 00992.19 ], Gyr [ 00000.55, 00002.13, -00003.11 ], Mag [ 00018.15, 00082.80, 00107.85 ]",
    "#0000007878 NoMotion-1. Scaled. Acc [ -00047.36, -00013.18, 00994.63 ], Gyr [ -00000.18, 00001.40, -00002.32 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007879 NoMotion-1. Scaled. Acc [ -00052.73, -00003.91, 00983.89 ], Gyr [ -00000.61, 00003.41, -00000.73 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007880 NoMotion-1. Scaled. Acc [ -00050.78, -00016.60, 00999.02 ], Gyr [ -00000.24, 00002.26, -00001.10 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007881 NoMotion-1. Scaled. Acc [ -00032.71, -00012.21, 01006.35 ], Gyr [ 00001.52, 00002.01, -00000.30 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007882 NoMotion-1. Scaled. Acc [ -00040.04, -00014.16, 00992.19 ], Gyr [ 00000.37, -00000.43, -00000.61 ], Mag [ 00018.45, 00083.55, 00107.55 ]",
    "#0000007883 NoMotion-1. Scaled. Acc [ -00042.97, -00000.49, 00985.84 ], Gyr [ 00001.28, 00000.67, -00000.79 ], Mag [ 00018.45, 00083.55, 00107.55 ]",
    "#0000007884 NoMotion-1. Scaled. Acc [ -00047.36, -00015.62, 00987.79 ], Gyr [ 00002.68, 00000.98, 00000.43 ], Mag [ 00020.40, 00082.95, 00108.45 ]",
    "#0000007885 NoMotion-1. Scaled. Acc [ -00035.16, -00004.88, 00990.23 ], Gyr [ 00001.71, 00002.01, -00000.55 ], Mag [ 00020.40, 00082.95, 00108.45 ]",
    "#0000007886 NoMotion-1. Scaled. Acc [ -00035.64, -00007.32, 00999.02 ], Gyr [ -00000.18, 00002.26, -00003.29 ], Mag [ 00018.45, 00084.75, 00108.90 ]",
    "#0000007887 NoMotion-1. Scaled. Acc [ -00041.50, -00002.44, 00984.86 ], Gyr [ -00000.43, 00003.41, -00001.04 ], Mag [ 00018.45, 00084.75, 00108.90 ]",
    "#0000007888 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00990.72 ], Gyr [ -00001.77, 00000.73, -00002.50 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007889 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00996.58 ], Gyr [ 00000.06, 00003.23, -00002.13 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007890 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00984.38 ], Gyr [ -00001.46, 00001.65, -00002.99 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007891 NoMotion-1. Scaled. Acc [ -00045.41, 00000.00, 00995.12 ], Gyr [ 00000.49, 00002.50, 00000.73 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007892 NoMotion-1. Scaled. Acc [ -00049.80, -00012.21, 00990.72 ], Gyr [ 00001.46, 00001.52, -00001.77 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007893 NoMotion-1. Scaled. Acc [ -00038.09, -00009.77, 00998.54 ], Gyr [ 00000.49, 00002.62, -00001.34 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007894 NoMotion-1. Scaled. Acc [ -00051.76, -00004.39, 00987.79 ], Gyr [ -00000.06, 00001.95, -00001.71 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007895 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 00979.00 ], Gyr [ 00001.71, 00000.49, -00001.52 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000007896 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00986.82 ], Gyr [ 00001.16, 00000.49, -00000.98 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000007897 NoMotion-1. Scaled. Acc [ -00046.39, -00012.21, 00992.68 ], Gyr [ -00000.37, 00002.38, -00002.13 ], Mag [ 00021.00, 00082.80, 00107.85 ]",
    "#0000007898 NoMotion-1. Scaled. Acc [ -00036.13, -00012.70, 00989.26 ], Gyr [ 00001.16, 00000.98, 00000.55 ], Mag [ 00021.00, 00082.80, 00107.85 ]",
    "#0000007899 NoMotion-1. Scaled. Acc [ -00042.48, -00008.30, 00999.51 ], Gyr [ -00000.43, -00000.91, 00000.18 ], Mag [ 00021.00, 00082.80, 00107.85 ]",
    "#0000007900 NoMotion-1. Scaled. Acc [ -00035.16, -00003.42, 00989.75 ], Gyr [ 00000.55, 00002.13, -00001.34 ], Mag [ 00019.05, 00082.65, 00108.75 ]",
    "#0000007901 NoMotion-1. Scaled. Acc [ -00044.43, -00007.32, 00989.75 ], Gyr [ 00000.55, 00001.40, -00000.79 ], Mag [ 00019.05, 00082.65, 00108.75 ]",
    "#0000007902 NoMotion-1. Scaled. Acc [ -00060.55, -00008.79, 00991.21 ], Gyr [ -00000.43, 00003.41, -00003.05 ], Mag [ 00021.00, 00083.25, 00107.85 ]",
    "#0000007903 NoMotion-1. Scaled. Acc [ -00041.99, -00014.65, 00992.68 ], Gyr [ 00001.89, 00004.02, -00004.02 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000007904 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 01002.93 ], Gyr [ -00000.18, 00003.05, -00000.85 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000007905 NoMotion-1. Scaled. Acc [ -00040.04, -00000.49, 00999.51 ], Gyr [ -00000.43, 00000.73, -00001.28 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000007906 NoMotion-1. Scaled. Acc [ -00042.97, -00014.65, 00990.72 ], Gyr [ 00003.17, 00000.00, -00002.56 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007907 NoMotion-1. Scaled. Acc [ -00034.67, -00016.11, 00990.72 ], Gyr [ 00001.34, 00000.61, -00001.34 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007908 NoMotion-1. Scaled. Acc [ -00039.06, -00007.32, 00997.07 ], Gyr [ 00001.59, -00000.79, 00000.73 ], Mag [ 00020.25, 00084.30, 00107.55 ]",
    "#0000007909 NoMotion-1. Scaled. Acc [ -00039.06, -00015.14, 00976.56 ], Gyr [ -00001.83, 00001.40, -00004.39 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000007910 NoMotion-1. Scaled. Acc [ -00056.64, -00018.07, 00999.02 ], Gyr [ -00001.95, 00003.41, -00001.65 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000007911 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 01001.95 ], Gyr [ -00001.83, 00002.50, 00000.55 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000007912 NoMotion-1. Scaled. Acc [ -00056.15, -00011.23, 00995.12 ], Gyr [ -00001.10, -00002.38, -00000.55 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000007913 NoMotion-1. Scaled. Acc [ -00030.27, 00003.42, 00998.05 ], Gyr [ -00000.30, 00002.87, -00003.90 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000007914 NoMotion-1. Scaled. Acc [ -00057.62, -00010.74, 00995.61 ], Gyr [ 00001.16, 00002.38, -00000.37 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007915 NoMotion-1. Scaled. Acc [ -00040.53, -00011.72, 00998.54 ], Gyr [ 00000.24, 00000.98, -00002.20 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007916 NoMotion-1. Scaled. Acc [ -00056.15, -00016.11, 00997.07 ], Gyr [ 00002.38, 00003.48, 00001.59 ], Mag [ 00019.95, 00083.55, 00108.90 ]",
    "#0000007917 NoMotion-1. Scaled. Acc [ -00038.57, -00007.81, 00991.70 ], Gyr [ 00003.11, -00000.24, -00000.98 ], Mag [ 00019.95, 00083.55, 00108.90 ]",
    "#0000007918 NoMotion-1. Scaled. Acc [ -00046.39, -00012.21, 00995.61 ], Gyr [ 00001.65, 00002.38, -00003.48 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007919 NoMotion-1. Scaled. Acc [ -00041.99, -00007.81, 00993.16 ], Gyr [ 00002.56, 00001.83, 00000.55 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007920 NoMotion-1. Scaled. Acc [ -00033.20, -00007.32, 01002.44 ], Gyr [ 00000.85, -00001.83, 00000.30 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007921 NoMotion-1. Scaled. Acc [ -00039.06, -00004.39, 00988.28 ], Gyr [ 00000.73, 00002.07, -00003.72 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007922 NoMotion-1. Scaled. Acc [ -00042.48, -00005.37, 00979.00 ], Gyr [ -00000.79, 00001.28, -00001.89 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007923 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 01005.86 ], Gyr [ -00000.24, 00002.62, -00003.41 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007924 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 01000.00 ], Gyr [ 00002.20, 00000.12, 00000.06 ], Mag [ 00020.25, 00082.80, 00107.10 ]",
    "#0000007925 NoMotion-1. Scaled. Acc [ -00041.50, 00003.42, 00995.61 ], Gyr [ 00000.91, 00000.43, -00003.41 ], Mag [ 00020.25, 00082.80, 00107.10 ]",
    "#0000007926 NoMotion-1. Scaled. Acc [ -00050.78, -00001.46, 01002.93 ], Gyr [ 00000.91, -00000.30, -00001.10 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000007927 NoMotion-1. Scaled. Acc [ -00043.95, -00011.23, 00987.79 ], Gyr [ -00001.22, 00001.10, -00001.65 ], Mag [ 00019.20, 00082.80, 00108.60 ]",
    "#0000007928 NoMotion-1. Scaled. Acc [ -00044.43, -00002.93, 00992.19 ], Gyr [ 00000.55, 00000.61, -00002.87 ], Mag [ 00019.20, 00082.80, 00108.60 ]",
    "#0000007929 NoMotion-1. Scaled. Acc [ -00043.95, 00000.49, 00988.28 ], Gyr [ 00001.04, 00000.79, -00001.59 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007930 NoMotion-1. Scaled. Acc [ -00024.41, -00004.88, 00980.47 ], Gyr [ -00000.37, 00000.24, -00000.85 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007931 NoMotion-1. Scaled. Acc [ -00048.34, -00007.81, 00993.65 ], Gyr [ -00002.13, 00001.95, -00000.43 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007932 NoMotion-1. Scaled. Acc [ -00046.88, -00000.98, 00991.21 ], Gyr [ 00000.98, 00000.55, -00002.44 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007933 NoMotion-1. Scaled. Acc [ -00053.22, 00001.46, 00981.45 ], Gyr [ 00001.46, -00000.06, -00000.55 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007934 NoMotion-1. Scaled. Acc [ -00053.22, -00019.53, 00981.45 ], Gyr [ 00000.37, 00002.20, -00003.11 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007935 NoMotion-1. Scaled. Acc [ -00044.92, -00005.37, 00993.16 ], Gyr [ 00001.28, 00001.52, -00002.87 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007936 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00986.82 ], Gyr [ -00000.55, 00000.67, -00001.83 ], Mag [ 00020.10, 00083.70, 00106.20 ]",
    "#0000007937 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00995.61 ], Gyr [ 00000.30, 00002.38, -00001.40 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007938 NoMotion-1. Scaled. Acc [ -00041.02, -00005.86, 00991.21 ], Gyr [ 00003.35, 00000.98, -00000.30 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007939 NoMotion-1. Scaled. Acc [ -00035.64, -00008.30, 00991.21 ], Gyr [ 00001.34, -00000.98, 00002.68 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007940 NoMotion-1. Scaled. Acc [ -00041.50, 00002.93, 00990.23 ], Gyr [ -00000.24, 00003.17, -00003.60 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007941 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00991.21 ], Gyr [ 00000.98, 00001.16, 00001.16 ], Mag [ 00018.75, 00084.30, 00107.10 ]",
    "#0000007942 NoMotion-1. Scaled. Acc [ -00047.85, -00011.72, 00975.59 ], Gyr [ 00000.67, 00003.35, 00000.18 ], Mag [ 00018.75, 00084.30, 00107.10 ]",
    "#0000007943 NoMotion-1. Scaled. Acc [ -00040.04, -00010.74, 00994.63 ], Gyr [ 00001.59, 00001.89, -00000.61 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007944 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00980.47 ], Gyr [ -00000.37, 00002.38, -00001.83 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007945 NoMotion-1. Scaled. Acc [ -00045.90, -00017.09, 00984.86 ], Gyr [ 00001.83, 00000.37, 00000.06 ], Mag [ 00019.20, 00084.75, 00107.85 ]",
    "#0000007946 NoMotion-1. Scaled. Acc [ -00060.55, 00011.23, 00988.28 ], Gyr [ 00000.67, 00002.93, -00002.56 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007947 NoMotion-1. Scaled. Acc [ -00041.99, 00001.95, 00994.14 ], Gyr [ 00000.61, -00000.30, -00003.96 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007948 NoMotion-1. Scaled. Acc [ -00040.53, -00009.28, 00988.28 ], Gyr [ 00000.73, 00001.89, -00001.04 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007949 NoMotion-1. Scaled. Acc [ -00040.53, 00006.84, 00989.75 ], Gyr [ -00000.67, 00004.09, -00003.72 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007950 NoMotion-1. Scaled. Acc [ -00039.06, -00017.09, 00979.98 ], Gyr [ 00001.71, 00000.55, -00001.52 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007951 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00992.68 ], Gyr [ 00001.59, -00000.61, -00001.65 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007952 NoMotion-1. Scaled. Acc [ -00046.88, -00003.42, 00998.05 ], Gyr [ -00000.98, 00000.73, 00000.37 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007953 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 01002.44 ], Gyr [ 00002.99, -00000.55, -00000.30 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007954 NoMotion-1. Scaled. Acc [ -00044.43, -00002.44, 00997.56 ], Gyr [ 00001.16, -00001.04, -00000.67 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000007955 NoMotion-1. Scaled. Acc [ -00049.80, 00005.37, 00989.26 ], Gyr [ -00000.37, 00002.80, -00002.20 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000007956 NoMotion-1. Scaled. Acc [ -00046.39, -00011.72, 00994.63 ], Gyr [ 00002.01, -00000.98, -00001.89 ], Mag [ 00019.05, 00082.65, 00109.05 ]",
    "#0000007957 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00995.61 ], Gyr [ -00000.91, 00000.30, -00001.40 ], Mag [ 00019.05, 00082.65, 00109.05 ]",
    "#0000007958 NoMotion-1. Scaled. Acc [ -00050.29, -00009.77, 00989.26 ], Gyr [ 00001.28, -00001.28, 00001.40 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007959 NoMotion-1. Scaled. Acc [ -00047.85, -00014.16, 00994.14 ], Gyr [ -00000.85, 00000.30, -00004.51 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007960 NoMotion-1. Scaled. Acc [ -00044.43, -00011.23, 00995.12 ], Gyr [ 00001.34, 00001.40, -00001.77 ], Mag [ 00020.25, 00084.75, 00107.10 ]",
    "#0000007961 NoMotion-1. Scaled. Acc [ -00057.13, -00013.67, 00985.84 ], Gyr [ 00000.18, 00002.93, -00006.22 ], Mag [ 00020.25, 00084.75, 00107.10 ]",
    "#0000007962 NoMotion-1. Scaled. Acc [ -00038.09, -00002.44, 00987.79 ], Gyr [ -00000.73, 00001.34, -00000.43 ], Mag [ 00020.25, 00084.75, 00107.10 ]",
    "#0000007963 NoMotion-1. Scaled. Acc [ -00042.48, -00003.91, 00994.14 ], Gyr [ -00000.24, -00001.59, -00003.29 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007964 NoMotion-1. Scaled. Acc [ -00037.60, -00010.25, 01001.95 ], Gyr [ 00000.43, 00001.10, -00000.98 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007965 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00998.54 ], Gyr [ 00001.52, 00002.26, -00001.77 ], Mag [ 00021.15, 00081.15, 00107.70 ]",
    "#0000007966 NoMotion-1. Scaled. Acc [ -00040.04, -00009.77, 00987.79 ], Gyr [ -00000.49, 00001.46, -00004.76 ], Mag [ 00021.15, 00081.15, 00107.70 ]",
    "#0000007967 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00992.68 ], Gyr [ -00000.49, -00000.55, 00000.91 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007968 NoMotion-1. Scaled. Acc [ -00049.32, -00001.95, 00986.82 ], Gyr [ 00002.01, 00004.09, 00000.18 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007969 NoMotion-1. Scaled. Acc [ -00044.43, -00016.60, 00988.28 ], Gyr [ -00000.49, 00002.80, -00000.85 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007970 NoMotion-1. Scaled. Acc [ -00050.78, -00004.39, 00996.58 ], Gyr [ 00002.32, 00001.34, -00001.28 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007971 NoMotion-1. Scaled. Acc [ -00042.97, -00016.11, 00982.91 ], Gyr [ 00000.61, 00002.56, -00001.83 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007972 NoMotion-1. Scaled. Acc [ -00059.08, -00004.39, 00983.89 ], Gyr [ 00001.34, 00000.55, -00003.11 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007973 NoMotion-1. Scaled. Acc [ -00046.39, 00003.91, 00990.23 ], Gyr [ -00001.52, 00001.71, -00001.16 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007974 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00992.68 ], Gyr [ 00002.44, 00003.72, -00003.05 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007975 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00999.02 ], Gyr [ 00000.98, 00000.85, -00003.35 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007976 NoMotion-1. Scaled. Acc [ -00039.06, -00015.62, 00993.65 ], Gyr [ 00001.34, -00001.71, -00001.89 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007977 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 01000.00 ], Gyr [ 00000.24, 00000.55, -00001.65 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007978 NoMotion-1. Scaled. Acc [ -00047.85, -00017.09, 00993.16 ], Gyr [ -00000.30, 00001.22, -00004.39 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007979 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00987.79 ], Gyr [ -00000.55, 00001.22, -00002.13 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000007980 NoMotion-1. Scaled. Acc [ -00041.99, -00005.37, 00983.89 ], Gyr [ 00002.26, 00002.99, -00000.24 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000007981 NoMotion-1. Scaled. Acc [ -00044.43, -00008.30, 00997.07 ], Gyr [ 00000.98, 00000.91, -00000.85 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000007982 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00993.16 ], Gyr [ 00001.34, -00002.01, -00001.22 ], Mag [ 00019.95, 00084.00, 00106.50 ]",
    "#0000007983 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00991.21 ], Gyr [ 00000.12, 00002.44, -00001.71 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007984 NoMotion-1. Scaled. Acc [ -00040.04, -00010.25, 00987.79 ], Gyr [ 00001.71, 00000.67, -00001.46 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007985 NoMotion-1. Scaled. Acc [ -00031.25, -00016.60, 01000.00 ], Gyr [ -00001.10, 00001.52, -00002.32 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007986 NoMotion-1. Scaled. Acc [ -00053.22, -00002.44, 01000.49 ], Gyr [ -00000.67, -00000.67, -00000.73 ], Mag [ 00020.70, 00083.55, 00107.85 ]",
    "#0000007987 NoMotion-1. Scaled. Acc [ -00032.71, -00005.37, 01005.37 ], Gyr [ 00000.79, 00001.95, -00001.89 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007988 NoMotion-1. Scaled. Acc [ -00041.02, -00004.39, 00989.26 ], Gyr [ 00002.01, 00000.61, -00001.77 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007989 NoMotion-1. Scaled. Acc [ -00039.55, -00001.46, 00989.75 ], Gyr [ 00002.07, 00000.18, -00000.55 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007990 NoMotion-1. Scaled. Acc [ -00044.43, -00014.65, 00988.77 ], Gyr [ 00000.43, 00002.07, -00002.26 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007991 NoMotion-1. Scaled. Acc [ -00055.66, -00000.49, 00982.91 ], Gyr [ 00001.71, 00001.04, -00003.66 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007992 NoMotion-1. Scaled. Acc [ -00041.99, -00009.77, 00989.26 ], Gyr [ 00000.30, 00000.06, 00000.00 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007993 NoMotion-1. Scaled. Acc [ -00039.06, -00014.65, 00987.30 ], Gyr [ -00002.62, -00000.06, 00000.49 ], Mag [ 00018.60, 00083.70, 00107.40 ]",
    "#0000007994 NoMotion-1. Scaled. Acc [ -00044.43, -00003.91, 00998.05 ], Gyr [ -00000.55, -00003.05, -00000.37 ], Mag [ 00018.60, 00083.70, 00107.40 ]",
    "#0000007995 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00995.61 ], Gyr [ 00000.43, 00003.35, -00001.34 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007996 NoMotion-1. Scaled. Acc [ -00046.88, -00019.04, 00988.77 ], Gyr [ -00000.67, -00001.28, 00000.73 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007997 NoMotion-1. Scaled. Acc [ -00044.43, -00011.23, 00990.23 ], Gyr [ 00001.22, 00001.28, -00002.87 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000007998 NoMotion-1. Scaled. Acc [ -00046.39, -00002.44, 00980.96 ], Gyr [ -00001.95, 00002.68, -00003.96 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000007999 NoMotion-1. Scaled. Acc [ -00056.15, -00006.35, 00995.12 ], Gyr [ 00001.22, 00002.13, -00001.52 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000008000 NoMotion-1. Scaled. Acc [ -00044.92, -00011.72, 01000.00 ], Gyr [ -00000.85, 00003.48, -00000.12 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000008001 NoMotion-1. Scaled. Acc [ -00051.27, -00010.74, 00979.00 ], Gyr [ 00001.77, 00000.24, -00000.49 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000008002 NoMotion-1. Scaled. Acc [ -00041.50, -00018.07, 01006.35 ], Gyr [ -00000.79, 00001.77, -00002.56 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000008003 NoMotion-1. Scaled. Acc [ -00027.34, -00007.32, 00999.02 ], Gyr [ 00000.30, 00001.10, 00000.61 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000008004 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00992.68 ], Gyr [ 00001.40, 00001.04, 00000.24 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000008005 NoMotion-1. Scaled. Acc [ -00045.41, -00001.46, 00993.16 ], Gyr [ -00000.24, -00000.43, -00001.83 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000008006 NoMotion-1. Scaled. Acc [ -00040.53, -00008.79, 00987.79 ], Gyr [ 00001.34, 00000.61, -00002.38 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000008007 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00982.91 ], Gyr [ 00000.73, 00002.38, -00003.54 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000008008 NoMotion-1. Scaled. Acc [ -00043.95, -00006.84, 00994.14 ], Gyr [ 00000.43, 00001.22, 00000.73 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000008009 NoMotion-1. Scaled. Acc [ -00040.53, -00004.88, 00996.09 ], Gyr [ -00001.65, 00005.37, -00002.32 ], Mag [ 00018.15, 00083.55, 00107.85 ]",
    "#0000008010 NoMotion-1. Scaled. Acc [ -00046.39, 00000.98, 00989.75 ], Gyr [ -00001.10, 00003.29, -00000.12 ], Mag [ 00018.15, 00083.55, 00107.85 ]",
    "#0000008011 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00982.42 ], Gyr [ 00000.37, 00000.18, -00001.46 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000008012 NoMotion-1. Scaled. Acc [ -00057.62, -00013.67, 00993.16 ], Gyr [ 00001.22, 00004.45, -00001.95 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000008013 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00999.02 ], Gyr [ 00000.49, 00001.71, -00002.93 ], Mag [ 00021.00, 00082.80, 00107.10 ]",
    "#0000008014 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00986.33 ], Gyr [ -00000.73, 00001.59, -00001.22 ], Mag [ 00020.10, 00082.80, 00107.10 ]",
    "#0000008015 NoMotion-1. Scaled. Acc [ -00041.99, 00004.88, 00985.84 ], Gyr [ 00000.49, 00001.89, -00001.16 ], Mag [ 00020.10, 00084.90, 00108.45 ]",
    "#0000008016 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00978.52 ], Gyr [ 00002.20, 00000.24, 00000.00 ], Mag [ 00020.70, 00083.25, 00106.50 ]",
    "#0000008017 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00994.14 ], Gyr [ 00000.49, 00003.90, -00002.32 ], Mag [ 00020.70, 00083.25, 00106.50 ]",
    "#0000008018 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00988.28 ], Gyr [ 00001.16, 00000.91, 00001.28 ], Mag [ 00020.70, 00083.25, 00106.50 ]",
    "#0000008019 NoMotion-1. Scaled. Acc [ -00056.64, -00000.49, 00994.14 ], Gyr [ 00000.85, 00001.83, -00003.54 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008020 NoMotion-1. Scaled. Acc [ -00048.34, 00000.00, 00996.58 ], Gyr [ -00001.95, 00002.50, 00000.00 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008021 NoMotion-1. Scaled. Acc [ -00051.76, -00016.60, 01003.91 ], Gyr [ 00000.18, 00002.38, -00001.65 ], Mag [ 00019.65, 00084.45, 00106.65 ]",
    "#0000008022 NoMotion-1. Scaled. Acc [ -00052.25, -00001.46, 00985.84 ], Gyr [ 00000.12, 00003.41, -00001.04 ], Mag [ 00019.65, 00084.45, 00106.65 ]",
    "#0000008023 NoMotion-1. Scaled. Acc [ -00048.34, -00013.18, 00999.51 ], Gyr [ -00000.49, 00004.27, -00003.72 ], Mag [ 00019.65, 00084.45, 00106.65 ]",
    "#0000008024 NoMotion-1. Scaled. Acc [ -00059.08, -00005.86, 00999.02 ], Gyr [ -00000.85, 00000.18, -00000.55 ], Mag [ 00021.00, 00084.30, 00107.10 ]",
    "#0000008025 NoMotion-1. Scaled. Acc [ -00043.46, 00000.49, 00985.84 ], Gyr [ 00002.50, -00000.30, -00000.24 ], Mag [ 00021.00, 00084.30, 00107.10 ]",
    "#0000008026 NoMotion-1. Scaled. Acc [ -00042.48, -00009.77, 00980.96 ], Gyr [ 00000.85, 00000.49, -00002.80 ], Mag [ 00019.65, 00084.45, 00107.70 ]",
    "#0000008027 NoMotion-1. Scaled. Acc [ -00042.48, -00003.42, 00992.68 ], Gyr [ 00001.16, 00001.65, -00001.16 ], Mag [ 00020.10, 00082.65, 00107.70 ]",
    "#0000008028 NoMotion-1. Scaled. Acc [ -00032.71, -00003.91, 00980.96 ], Gyr [ -00000.37, -00000.43, -00004.51 ], Mag [ 00020.10, 00082.65, 00107.70 ]",
    "#0000008029 NoMotion-1. Scaled. Acc [ -00049.32, -00009.77, 00981.45 ], Gyr [ 00001.16, 00001.40, -00000.06 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008030 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 01003.91 ], Gyr [ -00000.06, -00001.28, -00000.18 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008031 NoMotion-1. Scaled. Acc [ -00052.73, -00004.39, 00997.56 ], Gyr [ -00000.67, 00001.46, 00000.06 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008032 NoMotion-1. Scaled. Acc [ -00039.06, 00002.93, 00989.26 ], Gyr [ 00000.12, 00002.20, -00002.13 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008033 NoMotion-1. Scaled. Acc [ -00044.92, -00017.09, 00996.58 ], Gyr [ -00001.28, 00002.74, -00001.77 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008034 NoMotion-1. Scaled. Acc [ -00049.32, -00013.18, 00996.09 ], Gyr [ -00000.30, 00002.50, -00002.38 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008035 NoMotion-1. Scaled. Acc [ -00061.52, 00009.28, 00988.28 ], Gyr [ -00000.73, 00000.18, -00002.44 ], Mag [ 00019.65, 00083.40, 00108.75 ]",
    "#0000008036 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00985.35 ], Gyr [ 00000.24, -00000.12, -00000.55 ], Mag [ 00019.65, 00083.40, 00108.75 ]",
    "#0000008037 NoMotion-1. Scaled. Acc [ -00050.29, -00003.42, 00985.84 ], Gyr [ 00001.34, 00000.49, 00000.12 ], Mag [ 00019.50, 00083.25, 00106.80 ]",
    "#0000008038 NoMotion-1. Scaled. Acc [ -00037.11, -00000.98, 00979.98 ], Gyr [ 00001.46, 00002.44, 00000.00 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008039 NoMotion-1. Scaled. Acc [ -00054.20, 00003.42, 00994.63 ], Gyr [ 00001.65, 00001.34, -00002.80 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008040 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00985.35 ], Gyr [ 00001.71, 00001.16, -00002.62 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008041 NoMotion-1. Scaled. Acc [ -00051.27, -00013.18, 00992.19 ], Gyr [ 00000.98, 00001.77, -00000.91 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008042 NoMotion-1. Scaled. Acc [ -00033.69, -00011.23, 00996.58 ], Gyr [ 00001.40, 00001.34, -00001.22 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008043 NoMotion-1. Scaled. Acc [ -00038.09, -00007.32, 00990.23 ], Gyr [ 00001.34, -00000.37, -00001.40 ], Mag [ 00019.20, 00084.00, 00107.55 ]",
    "#0000008044 NoMotion-1. Scaled. Acc [ -00052.73, -00008.30, 00987.30 ], Gyr [ 00000.79, -00000.61, -00001.71 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008045 NoMotion-1. Scaled. Acc [ -00047.85, -00016.11, 00991.21 ], Gyr [ 00000.12, 00001.52, -00002.44 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008046 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00989.26 ], Gyr [ -00000.37, 00002.07, -00001.28 ], Mag [ 00019.50, 00084.15, 00106.95 ]",
    "#0000008047 NoMotion-1. Scaled. Acc [ -00032.71, -00008.30, 00990.23 ], Gyr [ 00001.28, 00000.79, -00001.71 ], Mag [ 00019.50, 00081.75, 00107.55 ]",
    "#0000008048 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00987.30 ], Gyr [ 00001.22, 00001.04, -00002.50 ], Mag [ 00019.50, 00081.75, 00107.55 ]",
    "#0000008049 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 00987.79 ], Gyr [ -00001.28, -00000.12, -00000.67 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000008050 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00989.26 ], Gyr [ 00000.55, 00000.98, -00001.16 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000008051 NoMotion-1. Scaled. Acc [ -00030.76, -00016.60, 00989.26 ], Gyr [ -00000.67, 00003.35, -00002.38 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000008052 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00995.61 ], Gyr [ -00001.16, 00001.52, 00000.24 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000008053 NoMotion-1. Scaled. Acc [ -00045.90, -00000.49, 00979.98 ], Gyr [ -00000.43, 00000.67, -00003.60 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008054 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00995.12 ], Gyr [ 00001.10, 00001.04, -00001.77 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008055 NoMotion-1. Scaled. Acc [ -00046.88, -00000.49, 00983.40 ], Gyr [ 00000.79, 00001.04, -00000.73 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008056 NoMotion-1. Scaled. Acc [ -00042.97, -00001.46, 00993.65 ], Gyr [ 00000.24, 00001.28, -00000.24 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008057 NoMotion-1. Scaled. Acc [ -00043.95, -00006.35, 00996.09 ], Gyr [ 00001.04, 00003.84, -00001.40 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008058 NoMotion-1. Scaled. Acc [ -00041.99, -00011.72, 00990.23 ], Gyr [ -00002.38, 00001.52, -00001.46 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008059 NoMotion-1. Scaled. Acc [ -00033.69, -00020.02, 00987.79 ], Gyr [ 00000.37, 00001.10, -00001.59 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008060 NoMotion-1. Scaled. Acc [ -00044.92, -00001.95, 00983.89 ], Gyr [ -00000.73, 00001.83, -00003.23 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008061 NoMotion-1. Scaled. Acc [ -00035.64, -00008.30, 00990.72 ], Gyr [ 00001.52, 00002.62, -00000.49 ], Mag [ 00018.75, 00084.75, 00108.30 ]",
    "#0000008062 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00985.84 ], Gyr [ 00001.34, 00001.89, -00002.07 ], Mag [ 00018.75, 00084.75, 00108.30 ]",
    "#0000008063 NoMotion-1. Scaled. Acc [ -00054.20, -00000.49, 00994.63 ], Gyr [ 00002.50, 00001.59, -00001.89 ], Mag [ 00019.95, 00083.55, 00107.10 ]",
    "#0000008064 NoMotion-1. Scaled. Acc [ -00051.76, -00004.39, 00993.16 ], Gyr [ 00000.30, 00003.35, -00000.06 ], Mag [ 00019.95, 00083.55, 00107.10 ]",
    "#0000008065 NoMotion-1. Scaled. Acc [ -00051.76, -00015.14, 00992.68 ], Gyr [ 00000.55, -00002.07, -00001.22 ], Mag [ 00019.65, 00082.65, 00107.70 ]",
    "#0000008066 NoMotion-1. Scaled. Acc [ -00046.88, 00001.46, 00987.30 ], Gyr [ 00001.40, 00002.80, -00001.40 ], Mag [ 00019.65, 00082.65, 00107.70 ]",
    "#0000008067 NoMotion-1. Scaled. Acc [ -00037.11, -00009.28, 00992.19 ], Gyr [ 00002.38, 00000.55, -00001.34 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008068 NoMotion-1. Scaled. Acc [ -00044.92, 00003.91, 00995.12 ], Gyr [ 00001.65, 00001.22, 00000.67 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008069 NoMotion-1. Scaled. Acc [ -00040.04, 00002.93, 00992.19 ], Gyr [ -00000.49, 00003.41, -00002.01 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008070 NoMotion-1. Scaled. Acc [ -00036.62, -00007.81, 00995.61 ], Gyr [ 00001.10, 00001.10, -00000.18 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008071 NoMotion-1. Scaled. Acc [ -00050.29, -00019.04, 00980.96 ], Gyr [ 00002.32, 00004.09, -00000.91 ], Mag [ 00020.10, 00082.20, 00106.65 ]",
    "#0000008072 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 00995.12 ], Gyr [ -00000.24, 00002.99, -00002.01 ], Mag [ 00020.10, 00082.20, 00106.65 ]",
    "#0000008073 NoMotion-1. Scaled. Acc [ -00035.64, 00003.42, 00985.35 ], Gyr [ -00001.40, 00002.26, -00003.54 ], Mag [ 00019.05, 00082.95, 00108.75 ]",
    "#0000008074 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00990.72 ], Gyr [ 00000.73, 00004.94, 00001.10 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000008075 NoMotion-1. Scaled. Acc [ -00053.22, -00009.77, 00990.72 ], Gyr [ -00000.73, 00003.05, -00002.32 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008076 NoMotion-1. Scaled. Acc [ -00041.02, -00017.58, 00994.63 ], Gyr [ -00000.55, -00000.43, -00001.10 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008077 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00993.16 ], Gyr [ 00000.24, 00001.34, -00002.38 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008078 NoMotion-1. Scaled. Acc [ -00038.09, 00001.46, 01005.86 ], Gyr [ -00001.46, 00003.96, -00004.33 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008079 NoMotion-1. Scaled. Acc [ -00060.55, -00016.11, 01000.49 ], Gyr [ 00001.28, 00000.61, 00001.10 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008080 NoMotion-1. Scaled. Acc [ -00065.92, -00009.28, 00991.70 ], Gyr [ 00000.43, -00000.55, -00001.59 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008081 NoMotion-1. Scaled. Acc [ -00043.46, -00011.72, 00997.56 ], Gyr [ 00002.26, -00000.06, -00000.30 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008082 NoMotion-1. Scaled. Acc [ -00037.60, -00001.95, 00990.72 ], Gyr [ -00001.46, 00001.04, -00001.95 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008083 NoMotion-1. Scaled. Acc [ -00046.88, -00000.49, 00990.72 ], Gyr [ 00000.06, 00002.62, -00002.56 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008084 NoMotion-1. Scaled. Acc [ -00046.39, -00016.60, 00992.68 ], Gyr [ -00000.24, 00002.01, -00000.98 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008085 NoMotion-1. Scaled. Acc [ -00039.06, 00006.35, 00992.68 ], Gyr [ -00000.24, 00004.39, -00000.79 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008086 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 01005.37 ], Gyr [ -00000.24, 00001.83, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008087 NoMotion-1. Scaled. Acc [ -00048.34, -00016.11, 01005.86 ], Gyr [ -00001.83, 00001.52, 00000.18 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008088 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00993.65 ], Gyr [ -00001.10, 00000.06, -00003.05 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008089 NoMotion-1. Scaled. Acc [ -00055.66, -00011.72, 00998.54 ], Gyr [ 00000.12, -00001.10, -00002.01 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008090 NoMotion-1. Scaled. Acc [ -00047.85, -00014.65, 00980.96 ], Gyr [ -00000.37, 00001.95, -00002.32 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000008091 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00984.38 ], Gyr [ 00002.44, 00000.12, -00000.85 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000008092 NoMotion-1. Scaled. Acc [ -00040.53, -00007.81, 00994.14 ], Gyr [ 00001.22, 00000.79, -00001.65 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008093 NoMotion-1. Scaled. Acc [ -00053.22, -00000.98, 00998.05 ], Gyr [ 00002.20, -00000.30, -00000.30 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008094 NoMotion-1. Scaled. Acc [ -00044.43, -00007.32, 00993.16 ], Gyr [ 00001.40, 00001.71, -00004.02 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008095 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00991.70 ], Gyr [ 00000.30, 00004.02, -00001.52 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008096 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 01002.93 ], Gyr [ 00001.65, -00001.16, -00001.95 ], Mag [ 00019.05, 00082.20, 00108.00 ]",
    "#0000008097 NoMotion-1. Scaled. Acc [ -00047.36, -00010.25, 00987.79 ], Gyr [ 00000.67, 00001.04, -00000.91 ], Mag [ 00019.05, 00082.20, 00108.00 ]",
    "#0000008098 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00998.54 ], Gyr [ 00001.04, 00000.55, -00002.56 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000008099 NoMotion-1. Scaled. Acc [ -00029.79, -00003.42, 01002.93 ], Gyr [ -00000.30, 00001.65, -00002.99 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000008100 NoMotion-1. Scaled. Acc [ -00059.57, -00003.42, 00981.45 ], Gyr [ 00000.49, 00000.67, -00000.12 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000008101 NoMotion-1. Scaled. Acc [ -00045.41, -00003.91, 00995.12 ], Gyr [ 00000.49, 00000.18, -00004.02 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000008102 NoMotion-1. Scaled. Acc [ -00046.88, 00002.93, 00985.35 ], Gyr [ 00001.52, 00000.98, -00002.80 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008103 NoMotion-1. Scaled. Acc [ -00034.18, -00008.79, 00992.68 ], Gyr [ 00001.10, 00002.62, -00002.68 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008104 NoMotion-1. Scaled. Acc [ -00051.76, -00010.74, 00989.26 ], Gyr [ 00002.01, 00001.28, -00000.06 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008105 NoMotion-1. Scaled. Acc [ -00040.04, -00010.74, 00990.23 ], Gyr [ 00000.98, 00002.38, -00002.20 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008106 NoMotion-1. Scaled. Acc [ -00040.04, -00014.65, 01012.21 ], Gyr [ -00000.37, 00003.41, -00001.16 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008107 NoMotion-1. Scaled. Acc [ -00043.95, -00009.77, 00988.77 ], Gyr [ -00000.12, 00000.85, -00002.44 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008108 NoMotion-1. Scaled. Acc [ -00045.41, -00015.62, 00987.30 ], Gyr [ 00001.46, 00002.56, -00002.44 ], Mag [ 00018.75, 00084.00, 00106.05 ]",
    "#0000008109 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00980.96 ], Gyr [ 00000.85, 00003.66, -00001.77 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008110 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00979.98 ], Gyr [ 00000.24, -00000.37, -00001.77 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008111 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00994.14 ], Gyr [ 00000.24, 00003.90, -00001.71 ], Mag [ 00018.30, 00081.90, 00108.00 ]",
    "#0000008112 NoMotion-1. Scaled. Acc [ -00047.36, -00018.07, 00989.26 ], Gyr [ -00000.24, 00000.18, -00000.55 ], Mag [ 00018.30, 00081.90, 00108.00 ]",
    "#0000008113 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00985.35 ], Gyr [ 00000.61, 00000.43, -00002.32 ], Mag [ 00018.30, 00081.90, 00108.00 ]",
    "#0000008114 NoMotion-1. Scaled. Acc [ -00048.83, -00016.11, 00984.86 ], Gyr [ 00000.49, 00000.85, -00000.06 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008115 NoMotion-1. Scaled. Acc [ -00042.48, -00008.79, 00996.58 ], Gyr [ -00000.06, 00001.77, -00001.28 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008116 NoMotion-1. Scaled. Acc [ -00052.25, -00003.91, 00990.72 ], Gyr [ 00002.01, 00001.46, -00001.28 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000008117 NoMotion-1. Scaled. Acc [ -00041.50, -00016.60, 00993.16 ], Gyr [ -00000.49, 00000.37, -00000.24 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000008118 NoMotion-1. Scaled. Acc [ -00042.48, -00010.74, 00989.26 ], Gyr [ 00001.40, 00001.16, -00000.37 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000008119 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 01008.79 ], Gyr [ -00001.52, 00000.12, -00001.28 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000008120 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00988.28 ], Gyr [ -00001.83, 00000.12, 00000.24 ], Mag [ 00020.40, 00084.45, 00109.05 ]",
    "#0000008121 NoMotion-1. Scaled. Acc [ -00054.20, -00000.98, 00979.49 ], Gyr [ 00000.43, -00000.06, -00000.85 ], Mag [ 00020.40, 00084.45, 00109.05 ]",
    "#0000008122 NoMotion-1. Scaled. Acc [ -00045.41, -00007.81, 00984.86 ], Gyr [ -00000.73, -00000.98, -00001.28 ], Mag [ 00020.40, 00084.45, 00109.05 ]",
    "#0000008123 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 01003.42 ], Gyr [ -00000.73, 00001.83, -00001.10 ], Mag [ 00020.10, 00082.65, 00106.65 ]",
    "#0000008124 NoMotion-1. Scaled. Acc [ -00046.39, -00013.18, 00986.82 ], Gyr [ -00000.24, -00000.30, 00000.37 ], Mag [ 00020.10, 00082.65, 00106.65 ]",
    "#0000008125 NoMotion-1. Scaled. Acc [ -00052.25, 00002.44, 00995.12 ], Gyr [ -00000.73, 00001.59, -00001.22 ], Mag [ 00020.10, 00082.65, 00106.65 ]",
    "#0000008126 NoMotion-1. Scaled. Acc [ -00044.92, -00018.07, 00976.56 ], Gyr [ 00000.79, 00002.32, -00002.26 ], Mag [ 00019.35, 00084.15, 00106.65 ]",
    "#0000008127 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00993.16 ], Gyr [ 00001.16, 00000.30, 00003.17 ], Mag [ 00019.50, 00084.30, 00108.30 ]",
    "#0000008128 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00992.19 ], Gyr [ -00001.77, 00004.51, -00001.83 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008129 NoMotion-1. Scaled. Acc [ -00042.48, -00012.21, 01001.46 ], Gyr [ 00001.65, -00001.16, -00000.85 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008130 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00999.51 ], Gyr [ 00002.01, -00000.55, -00000.06 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008131 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00989.75 ], Gyr [ 00001.52, 00002.80, -00000.55 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008132 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00991.70 ], Gyr [ 00000.98, 00002.50, -00002.13 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008133 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00998.54 ], Gyr [ -00000.61, 00001.95, 00000.18 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008134 NoMotion-1. Scaled. Acc [ -00044.43, -00010.74, 00987.30 ], Gyr [ 00001.71, 00001.34, -00000.49 ], Mag [ 00018.60, 00084.15, 00107.40 ]",
    "#0000008135 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 00979.00 ], Gyr [ 00000.55, 00000.61, -00002.07 ], Mag [ 00018.60, 00084.15, 00107.40 ]",
    "#0000008136 NoMotion-1. Scaled. Acc [ -00044.43, -00019.53, 00985.35 ], Gyr [ -00000.91, 00001.59, -00001.34 ], Mag [ 00019.05, 00082.95, 00108.75 ]",
    "#0000008137 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00994.63 ], Gyr [ 00001.83, 00001.10, -00002.13 ], Mag [ 00019.05, 00082.95, 00108.75 ]",
    "#0000008138 NoMotion-1. Scaled. Acc [ -00044.92, -00001.46, 00990.72 ], Gyr [ 00002.99, 00001.83, -00003.23 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008139 NoMotion-1. Scaled. Acc [ -00055.66, -00013.67, 01000.00 ], Gyr [ 00001.16, 00000.91, 00000.85 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008140 NoMotion-1. Scaled. Acc [ -00049.80, -00000.98, 00978.03 ], Gyr [ -00000.18, 00002.20, 00001.65 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008141 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00986.33 ], Gyr [ 00000.55, -00000.37, -00001.10 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008142 NoMotion-1. Scaled. Acc [ -00034.18, -00006.84, 00989.26 ], Gyr [ 00001.59, -00002.26, -00001.28 ], Mag [ 00019.50, 00082.50, 00106.50 ]",
    "#0000008143 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00987.79 ], Gyr [ -00001.28, 00002.93, -00002.38 ], Mag [ 00017.55, 00082.95, 00108.00 ]",
    "#0000008144 NoMotion-1. Scaled. Acc [ -00050.29, -00010.25, 00994.14 ], Gyr [ 00001.95, 00000.49, -00002.26 ], Mag [ 00017.55, 00082.95, 00108.00 ]",
    "#0000008145 NoMotion-1. Scaled. Acc [ -00053.22, -00019.04, 00984.86 ], Gyr [ 00001.89, 00000.98, -00001.34 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008146 NoMotion-1. Scaled. Acc [ -00053.71, -00010.74, 00997.07 ], Gyr [ 00000.18, 00003.05, -00003.72 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008147 NoMotion-1. Scaled. Acc [ -00046.88, -00006.84, 01000.00 ], Gyr [ 00002.20, 00001.22, -00001.65 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008148 NoMotion-1. Scaled. Acc [ -00046.39, -00015.62, 00989.75 ], Gyr [ -00002.38, 00001.46, -00001.52 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008149 NoMotion-1. Scaled. Acc [ -00049.80, -00015.62, 01000.49 ], Gyr [ -00000.61, 00003.05, -00003.05 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008150 NoMotion-1. Scaled. Acc [ -00052.25, -00011.23, 00990.72 ], Gyr [ 00002.44, -00001.83, -00001.04 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008151 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00986.33 ], Gyr [ 00001.77, 00001.16, -00001.52 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000008152 NoMotion-1. Scaled. Acc [ -00044.92, -00002.44, 00989.75 ], Gyr [ 00001.59, 00004.45, 00000.24 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000008153 NoMotion-1. Scaled. Acc [ -00047.36, 00003.42, 00987.30 ], Gyr [ -00000.30, 00000.49, -00001.89 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000008154 NoMotion-1. Scaled. Acc [ -00035.64, -00010.25, 00986.33 ], Gyr [ 00001.71, -00001.16, -00000.98 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000008155 NoMotion-1. Scaled. Acc [ -00051.27, -00007.81, 00973.14 ], Gyr [ 00002.74, 00000.61, 00001.22 ], Mag [ 00019.65, 00083.70, 00109.50 ]",
    "#0000008156 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00998.05 ], Gyr [ 00000.18, 00001.46, -00002.74 ], Mag [ 00019.65, 00083.70, 00109.50 ]",
    "#0000008157 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00985.35 ], Gyr [ 00000.67, 00000.79, -00002.07 ], Mag [ 00018.15, 00082.05, 00105.75 ]",
    "#0000008158 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00995.61 ], Gyr [ 00001.40, 00001.46, -00003.17 ], Mag [ 00018.15, 00082.05, 00105.75 ]",
    "#0000008159 NoMotion-1. Scaled. Acc [ -00040.53, 00000.00, 00993.65 ], Gyr [ -00000.79, 00000.12, -00000.61 ], Mag [ 00018.15, 00082.05, 00105.75 ]",
    "#0000008160 NoMotion-1. Scaled. Acc [ -00056.64, -00013.67, 00985.35 ], Gyr [ -00000.73, 00001.22, 00000.91 ], Mag [ 00019.50, 00086.25, 00108.90 ]",
    "#0000008161 NoMotion-1. Scaled. Acc [ -00043.46, -00006.84, 00995.12 ], Gyr [ -00000.43, 00001.89, 00000.67 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000008162 NoMotion-1. Scaled. Acc [ -00057.13, -00010.74, 00982.91 ], Gyr [ 00002.13, -00001.71, -00001.71 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000008163 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00988.77 ], Gyr [ -00000.37, 00002.07, -00000.98 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000008164 NoMotion-1. Scaled. Acc [ -00039.55, -00003.91, 00978.52 ], Gyr [ -00001.04, 00001.10, -00002.13 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000008165 NoMotion-1. Scaled. Acc [ -00039.06, -00011.23, 01001.46 ], Gyr [ 00001.40, 00000.00, -00001.83 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000008166 NoMotion-1. Scaled. Acc [ -00056.64, -00009.77, 00992.19 ], Gyr [ 00002.01, 00000.85, 00000.49 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000008167 NoMotion-1. Scaled. Acc [ -00043.46, -00017.09, 00990.72 ], Gyr [ -00000.06, 00000.91, -00001.65 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000008168 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 01000.49 ], Gyr [ -00000.43, 00002.38, -00001.89 ], Mag [ 00020.25, 00085.50, 00107.10 ]",
    "#0000008169 NoMotion-1. Scaled. Acc [ -00057.13, -00006.35, 00988.28 ], Gyr [ 00001.10, 00000.79, 00000.49 ], Mag [ 00020.25, 00085.50, 00107.10 ]",
    "#0000008170 NoMotion-1. Scaled. Acc [ -00034.67, -00012.70, 00998.54 ], Gyr [ -00000.24, 00002.20, -00004.15 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000008171 NoMotion-1. Scaled. Acc [ -00047.85, -00003.91, 00989.26 ], Gyr [ 00000.37, 00002.56, -00000.73 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000008172 NoMotion-1. Scaled. Acc [ -00050.29, -00013.18, 00983.40 ], Gyr [ -00002.20, 00002.62, -00002.93 ], Mag [ 00020.10, 00082.95, 00106.95 ]",
    "#0000008173 NoMotion-1. Scaled. Acc [ -00040.53, -00012.21, 00991.70 ], Gyr [ 00001.65, -00000.73, -00000.85 ], Mag [ 00020.10, 00082.95, 00106.95 ]",
    "#0000008174 NoMotion-1. Scaled. Acc [ -00045.41, -00021.00, 00997.07 ], Gyr [ -00000.24, 00000.18, -00001.10 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000008175 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00984.38 ], Gyr [ -00000.79, 00001.46, 00000.73 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000008176 NoMotion-1. Scaled. Acc [ -00044.43, -00012.70, 00988.28 ], Gyr [ 00000.49, 00000.49, -00001.83 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000008177 NoMotion-1. Scaled. Acc [ -00033.69, -00000.98, 00988.28 ], Gyr [ 00001.52, 00001.71, 00000.18 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000008178 NoMotion-1. Scaled. Acc [ -00040.04, -00002.44, 00998.54 ], Gyr [ 00002.13, -00001.28, -00000.37 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000008179 NoMotion-1. Scaled. Acc [ -00042.97, -00008.30, 00994.14 ], Gyr [ -00000.24, 00001.95, -00002.26 ], Mag [ 00020.70, 00082.80, 00107.10 ]",
    "#0000008180 NoMotion-1. Scaled. Acc [ -00039.55, -00001.95, 00983.89 ], Gyr [ 00000.73, 00003.05, 00000.24 ], Mag [ 00020.70, 00082.80, 00107.10 ]",
    "#0000008181 NoMotion-1. Scaled. Acc [ -00043.46, -00011.72, 00993.65 ], Gyr [ -00000.91, 00000.49, -00003.11 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008182 NoMotion-1. Scaled. Acc [ -00050.78, -00010.25, 00983.89 ], Gyr [ 00000.12, 00000.73, 00000.30 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008183 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00982.42 ], Gyr [ 00000.67, 00003.54, -00002.56 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008184 NoMotion-1. Scaled. Acc [ -00042.97, -00008.30, 00993.65 ], Gyr [ 00000.43, 00003.05, 00000.12 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008185 NoMotion-1. Scaled. Acc [ -00051.76, -00000.98, 00990.23 ], Gyr [ 00002.93, 00001.22, 00000.73 ], Mag [ 00019.20, 00084.00, 00106.50 ]",
    "#0000008186 NoMotion-1. Scaled. Acc [ -00041.50, -00010.74, 00995.61 ], Gyr [ 00000.91, 00002.20, -00001.28 ], Mag [ 00019.20, 00084.00, 00106.50 ]",
    "#0000008187 NoMotion-1. Scaled. Acc [ -00037.11, -00013.67, 00991.70 ], Gyr [ -00000.30, 00001.40, -00002.01 ], Mag [ 00021.30, 00082.80, 00107.85 ]",
    "#0000008188 NoMotion-1. Scaled. Acc [ -00036.62, -00014.16, 00992.19 ], Gyr [ 00000.06, 00001.83, 00000.30 ], Mag [ 00021.30, 00082.80, 00107.85 ]",
    "#0000008189 NoMotion-1. Scaled. Acc [ -00035.16, -00010.74, 00986.33 ], Gyr [ 00001.22, -00000.55, -00000.55 ], Mag [ 00021.00, 00084.00, 00108.90 ]",
    "#0000008190 NoMotion-1. Scaled. Acc [ -00037.60, -00017.09, 00972.17 ], Gyr [ 00000.00, 00000.12, -00002.07 ], Mag [ 00021.00, 00084.00, 00108.90 ]",
    "#0000008191 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 01011.23 ], Gyr [ 00000.06, 00001.89, 00000.67 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008192 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00990.23 ], Gyr [ -00000.67, 00001.77, -00003.23 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008193 NoMotion-1. Scaled. Acc [ -00040.53, -00014.16, 00987.79 ], Gyr [ 00000.55, 00004.45, -00000.61 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008194 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00996.58 ], Gyr [ -00000.37, 00002.68, 00001.22 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008195 NoMotion-1. Scaled. Acc [ -00040.53, -00013.67, 00995.12 ], Gyr [ 00000.18, 00002.56, -00002.93 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000008196 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 00996.09 ], Gyr [ -00000.98, 00002.50, -00002.56 ], Mag [ 00020.25, 00082.05, 00106.80 ]",
    "#0000008197 NoMotion-1. Scaled. Acc [ -00055.66, -00000.98, 00993.16 ], Gyr [ 00001.40, 00000.91, -00001.04 ], Mag [ 00020.25, 00082.05, 00106.80 ]",
    "#0000008198 NoMotion-1. Scaled. Acc [ -00048.83, -00010.74, 00986.33 ], Gyr [ 00000.67, -00001.16, 00000.18 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000008199 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 01001.46 ], Gyr [ -00000.98, 00005.18, -00001.16 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008200 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00998.05 ], Gyr [ 00001.10, 00002.38, -00002.20 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008201 NoMotion-1. Scaled. Acc [ -00042.97, -00014.65, 00986.33 ], Gyr [ 00000.30, 00002.93, -00001.04 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008202 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00989.75 ], Gyr [ -00000.30, 00000.73, -00001.95 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008203 NoMotion-1. Scaled. Acc [ -00027.34, -00016.11, 00990.23 ], Gyr [ 00000.91, 00001.52, 00002.13 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000008204 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00989.26 ], Gyr [ 00000.12, 00002.01, -00001.28 ], Mag [ 00019.20, 00084.00, 00108.60 ]",
    "#0000008205 NoMotion-1. Scaled. Acc [ -00039.06, -00009.28, 00986.82 ], Gyr [ -00000.73, 00000.79, -00001.65 ], Mag [ 00019.20, 00084.00, 00108.60 ]",
    "#0000008206 NoMotion-1. Scaled. Acc [ -00042.97, 00000.98, 00983.40 ], Gyr [ -00000.67, 00002.87, -00004.51 ], Mag [ 00019.20, 00084.00, 00108.60 ]",
    "#0000008207 NoMotion-1. Scaled. Acc [ -00051.76, -00005.86, 00985.84 ], Gyr [ -00000.12, 00003.54, 00000.43 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008208 NoMotion-1. Scaled. Acc [ -00036.62, -00011.23, 00999.02 ], Gyr [ -00000.18, 00002.20, -00001.40 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008209 NoMotion-1. Scaled. Acc [ -00038.57, 00001.46, 00991.70 ], Gyr [ 00000.73, 00003.05, -00001.83 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008210 NoMotion-1. Scaled. Acc [ -00045.41, -00002.44, 00993.16 ], Gyr [ 00000.06, 00005.79, -00004.45 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008211 NoMotion-1. Scaled. Acc [ -00041.99, -00013.67, 00982.42 ], Gyr [ 00000.49, -00002.38, -00001.95 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008212 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00990.23 ], Gyr [ 00002.20, 00002.26, 00001.59 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008213 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00989.75 ], Gyr [ 00001.46, 00001.04, -00001.40 ], Mag [ 00019.35, 00084.45, 00106.65 ]",
    "#0000008214 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00994.14 ], Gyr [ 00001.65, 00002.99, 00002.13 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000008215 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00992.19 ], Gyr [ -00000.61, 00003.17, -00003.60 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000008216 NoMotion-1. Scaled. Acc [ -00038.57, -00003.42, 00988.28 ], Gyr [ -00000.06, 00002.01, -00000.18 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000008217 NoMotion-1. Scaled. Acc [ -00040.04, -00009.28, 00991.21 ], Gyr [ 00001.89, 00000.85, -00001.89 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000008218 NoMotion-1. Scaled. Acc [ -00049.32, -00019.53, 00984.38 ], Gyr [ 00001.59, 00003.05, -00000.18 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008219 NoMotion-1. Scaled. Acc [ -00049.32, -00026.37, 00987.79 ], Gyr [ -00000.79, 00002.20, -00001.83 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008220 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00992.19 ], Gyr [ -00000.06, 00002.93, -00002.44 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008221 NoMotion-1. Scaled. Acc [ -00048.34, -00007.81, 00985.35 ], Gyr [ 00001.52, -00000.73, 00001.04 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008222 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00995.61 ], Gyr [ -00002.62, -00000.06, -00004.39 ], Mag [ 00019.95, 00084.00, 00107.85 ]",
    "#0000008223 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00998.54 ], Gyr [ -00001.65, 00001.71, -00000.91 ], Mag [ 00019.95, 00084.00, 00107.85 ]",
    "#0000008224 NoMotion-1. Scaled. Acc [ -00034.67, -00001.95, 00988.77 ], Gyr [ -00000.91, 00000.55, -00000.67 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000008225 NoMotion-1. Scaled. Acc [ -00040.04, -00011.72, 00988.28 ], Gyr [ 00001.77, 00002.20, -00001.10 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000008226 NoMotion-1. Scaled. Acc [ -00040.04, -00009.77, 00991.21 ], Gyr [ 00002.50, 00001.65, -00002.93 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000008227 NoMotion-1. Scaled. Acc [ -00054.20, -00002.93, 00991.70 ], Gyr [ 00001.83, 00000.61, 00000.00 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008228 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00998.54 ], Gyr [ -00001.52, 00002.74, -00002.20 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008229 NoMotion-1. Scaled. Acc [ -00035.64, -00014.65, 00993.65 ], Gyr [ 00000.49, 00001.46, -00002.44 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008230 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00995.12 ], Gyr [ 00002.01, -00002.32, -00001.95 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008231 NoMotion-1. Scaled. Acc [ -00043.46, -00001.95, 01000.00 ], Gyr [ 00000.67, 00003.23, -00001.71 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008232 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00997.56 ], Gyr [ 00001.16, 00002.26, -00003.66 ], Mag [ 00019.50, 00084.30, 00107.85 ]",
    "#0000008233 NoMotion-1. Scaled. Acc [ -00049.80, -00001.46, 00981.93 ], Gyr [ 00001.71, 00000.91, -00001.65 ], Mag [ 00019.50, 00084.30, 00108.30 ]",
    "#0000008234 NoMotion-1. Scaled. Acc [ -00047.36, 00007.32, 00981.93 ], Gyr [ 00001.40, 00000.98, -00001.89 ], Mag [ 00019.05, 00083.40, 00108.30 ]",
    "#0000008235 NoMotion-1. Scaled. Acc [ -00062.99, -00012.70, 00990.72 ], Gyr [ -00000.43, 00003.41, -00003.48 ], Mag [ 00019.05, 00083.40, 00106.65 ]",
    "#0000008236 NoMotion-1. Scaled. Acc [ -00046.88, -00005.37, 00995.61 ], Gyr [ -00000.85, 00002.74, -00001.95 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000008237 NoMotion-1. Scaled. Acc [ -00045.90, -00001.46, 00994.63 ], Gyr [ -00001.16, 00000.43, 00001.10 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000008238 NoMotion-1. Scaled. Acc [ -00044.92, -00015.14, 00993.16 ], Gyr [ -00000.91, -00000.30, 00000.43 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000008239 NoMotion-1. Scaled. Acc [ -00039.06, -00009.77, 00998.05 ], Gyr [ 00000.79, 00001.28, -00001.28 ], Mag [ 00020.25, 00084.00, 00109.35 ]",
    "#0000008240 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00996.09 ], Gyr [ 00000.67, 00003.84, -00001.89 ], Mag [ 00018.60, 00082.65, 00105.90 ]",
    "#0000008241 NoMotion-1. Scaled. Acc [ -00041.02, -00003.42, 00993.16 ], Gyr [ -00000.30, 00004.39, -00003.35 ], Mag [ 00018.60, 00082.65, 00105.90 ]",
    "#0000008242 NoMotion-1. Scaled. Acc [ -00051.76, -00004.39, 00993.65 ], Gyr [ 00002.44, -00000.18, -00001.52 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008243 NoMotion-1. Scaled. Acc [ -00051.76, -00000.98, 00988.28 ], Gyr [ 00000.85, 00003.05, -00003.23 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008244 NoMotion-1. Scaled. Acc [ -00040.04, -00010.74, 00989.26 ], Gyr [ 00000.18, 00001.83, -00001.59 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008245 NoMotion-1. Scaled. Acc [ -00041.99, -00018.07, 00986.82 ], Gyr [ 00002.68, 00000.85, -00003.35 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008246 NoMotion-1. Scaled. Acc [ -00048.34, 00001.95, 00996.58 ], Gyr [ 00001.52, -00000.06, -00002.44 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000008247 NoMotion-1. Scaled. Acc [ -00039.55, -00003.91, 00986.82 ], Gyr [ 00000.67, 00001.10, -00002.07 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000008248 NoMotion-1. Scaled. Acc [ -00040.04, -00001.95, 00984.38 ], Gyr [ 00000.30, -00001.34, -00001.16 ], Mag [ 00018.60, 00083.40, 00107.70 ]",
    "#0000008249 NoMotion-1. Scaled. Acc [ -00054.69, -00007.81, 01005.37 ], Gyr [ -00000.30, 00003.90, -00004.45 ], Mag [ 00018.60, 00084.45, 00107.70 ]",
    "#0000008250 NoMotion-1. Scaled. Acc [ -00040.53, -00009.28, 00995.12 ], Gyr [ 00000.55, 00000.30, 00000.61 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008251 NoMotion-1. Scaled. Acc [ -00055.18, -00014.65, 00993.65 ], Gyr [ 00002.20, 00000.30, -00000.49 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008252 NoMotion-1. Scaled. Acc [ -00039.55, 00005.37, 00983.89 ], Gyr [ 00001.40, 00000.61, -00001.77 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008253 NoMotion-1. Scaled. Acc [ -00060.06, -00016.11, 00988.77 ], Gyr [ 00000.91, 00001.77, -00002.44 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008254 NoMotion-1. Scaled. Acc [ -00047.36, -00004.39, 00996.09 ], Gyr [ 00000.00, 00000.79, -00001.71 ], Mag [ 00020.70, 00083.25, 00107.55 ]",
    "#0000008255 NoMotion-1. Scaled. Acc [ -00046.88, -00011.23, 00997.56 ], Gyr [ 00004.02, 00001.28, -00001.10 ], Mag [ 00020.70, 00083.25, 00107.55 ]",
    "#0000008256 NoMotion-1. Scaled. Acc [ -00034.18, -00013.18, 00997.56 ], Gyr [ -00000.06, 00002.44, -00001.65 ], Mag [ 00019.20, 00084.00, 00107.55 ]",
    "#0000008257 NoMotion-1. Scaled. Acc [ -00040.53, -00016.60, 00982.42 ], Gyr [ -00000.06, 00001.28, -00003.11 ], Mag [ 00019.20, 00084.00, 00107.55 ]",
    "#0000008258 NoMotion-1. Scaled. Acc [ -00036.13, -00003.91, 00994.14 ], Gyr [ 00001.22, 00001.95, 00001.28 ], Mag [ 00018.75, 00082.50, 00106.50 ]",
    "#0000008259 NoMotion-1. Scaled. Acc [ -00049.80, -00007.81, 01000.98 ], Gyr [ 00000.37, -00000.67, 00000.98 ], Mag [ 00018.75, 00082.50, 00106.50 ]",
    "#0000008260 NoMotion-1. Scaled. Acc [ -00045.90, 00005.86, 00989.26 ], Gyr [ -00000.12, 00000.00, -00003.48 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008261 NoMotion-1. Scaled. Acc [ -00046.39, -00017.09, 00988.77 ], Gyr [ 00000.24, 00002.56, -00001.40 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008262 NoMotion-1. Scaled. Acc [ -00040.04, -00006.84, 00991.21 ], Gyr [ 00002.50, 00000.98, 00000.00 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008263 NoMotion-1. Scaled. Acc [ -00041.99, -00005.37, 00978.03 ], Gyr [ 00000.98, -00000.24, 00001.04 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008264 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00982.42 ], Gyr [ 00001.71, 00001.95, -00003.29 ], Mag [ 00019.95, 00082.80, 00106.50 ]",
    "#0000008265 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00996.09 ], Gyr [ 00000.06, 00002.32, -00000.43 ], Mag [ 00019.95, 00082.80, 00106.50 ]",
    "#0000008266 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00990.23 ], Gyr [ -00000.30, 00001.16, -00000.79 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008267 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 01001.46 ], Gyr [ 00000.18, 00003.60, -00002.26 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008268 NoMotion-1. Scaled. Acc [ -00053.22, -00012.21, 00994.14 ], Gyr [ -00000.98, -00000.12, -00003.78 ], Mag [ 00019.50, 00082.80, 00108.60 ]",
    "#0000008269 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00989.75 ], Gyr [ -00000.55, 00002.13, 00001.46 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008270 NoMotion-1. Scaled. Acc [ -00041.99, -00011.72, 00993.65 ], Gyr [ -00001.28, -00000.12, -00001.59 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008271 NoMotion-1. Scaled. Acc [ -00047.85, -00009.77, 00975.59 ], Gyr [ 00000.85, -00000.30, -00001.46 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008272 NoMotion-1. Scaled. Acc [ -00042.97, 00000.98, 00987.79 ], Gyr [ -00001.46, 00000.98, -00002.13 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008273 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00994.63 ], Gyr [ 00000.55, 00001.04, -00003.17 ], Mag [ 00019.35, 00083.70, 00108.75 ]",
    "#0000008274 NoMotion-1. Scaled. Acc [ -00036.13, -00009.77, 00983.40 ], Gyr [ -00000.18, -00001.28, 00001.40 ], Mag [ 00019.35, 00083.70, 00108.75 ]",
    "#0000008275 NoMotion-1. Scaled. Acc [ -00052.25, -00006.35, 01003.42 ], Gyr [ 00001.28, -00001.59, -00001.52 ], Mag [ 00018.15, 00081.75, 00106.80 ]",
    "#0000008276 NoMotion-1. Scaled. Acc [ -00045.41, -00018.07, 00987.30 ], Gyr [ 00001.59, -00001.52, -00001.04 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000008277 NoMotion-1. Scaled. Acc [ -00050.29, -00013.67, 00984.86 ], Gyr [ -00001.89, 00002.44, -00002.68 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000008278 NoMotion-1. Scaled. Acc [ -00041.50, -00011.23, 00987.79 ], Gyr [ 00001.52, -00000.43, 00000.24 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008279 NoMotion-1. Scaled. Acc [ -00034.67, -00010.25, 00995.61 ], Gyr [ 00002.13, 00001.22, -00001.59 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008280 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00979.49 ], Gyr [ -00001.10, 00005.00, -00003.48 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008281 NoMotion-1. Scaled. Acc [ -00043.95, 00001.95, 00986.33 ], Gyr [ -00001.10, 00001.77, -00002.32 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008282 NoMotion-1. Scaled. Acc [ -00049.32, -00003.91, 00982.91 ], Gyr [ 00001.16, 00001.46, -00002.80 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008283 NoMotion-1. Scaled. Acc [ -00049.32, -00011.72, 00988.77 ], Gyr [ 00001.40, 00000.30, 00000.43 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008284 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00988.28 ], Gyr [ 00000.61, 00001.46, -00000.91 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008285 NoMotion-1. Scaled. Acc [ -00049.32, -00023.44, 00988.77 ], Gyr [ -00000.85, 00001.59, -00001.95 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008286 NoMotion-1. Scaled. Acc [ -00033.69, -00015.14, 00986.33 ], Gyr [ 00000.73, 00000.55, 00003.11 ], Mag [ 00019.35, 00083.70, 00106.95 ]",
    "#0000008287 NoMotion-1. Scaled. Acc [ -00031.74, -00002.93, 00985.84 ], Gyr [ 00000.18, 00000.98, 00001.46 ], Mag [ 00019.05, 00083.40, 00108.45 ]",
    "#0000008288 NoMotion-1. Scaled. Acc [ -00041.50, -00013.67, 00993.65 ], Gyr [ 00000.79, 00000.43, -00002.26 ], Mag [ 00019.05, 00083.40, 00108.45 ]",
    "#0000008289 NoMotion-1. Scaled. Acc [ -00040.04, -00019.04, 00997.56 ], Gyr [ 00001.95, -00001.34, -00001.28 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000008290 NoMotion-1. Scaled. Acc [ -00053.22, -00017.58, 00977.05 ], Gyr [ 00002.32, 00001.77, -00000.06 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000008291 NoMotion-1. Scaled. Acc [ -00040.04, -00009.28, 00993.16 ], Gyr [ -00000.37, 00000.98, -00002.93 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008292 NoMotion-1. Scaled. Acc [ -00045.90, -00002.44, 00979.49 ], Gyr [ 00002.13, -00001.10, -00000.37 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008293 NoMotion-1. Scaled. Acc [ -00054.69, -00011.72, 00990.72 ], Gyr [ -00000.18, 00002.93, 00001.28 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008294 NoMotion-1. Scaled. Acc [ -00044.92, -00006.35, 00995.61 ], Gyr [ -00000.98, -00000.79, -00002.26 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008295 NoMotion-1. Scaled. Acc [ -00046.88, -00012.21, 00988.77 ], Gyr [ 00000.00, 00002.32, -00001.16 ], Mag [ 00020.40, 00084.15, 00106.95 ]",
    "#0000008296 NoMotion-1. Scaled. Acc [ -00044.43, -00003.42, 00996.58 ], Gyr [ 00000.12, 00001.95, -00002.80 ], Mag [ 00020.40, 00084.15, 00106.95 ]",
    "#0000008297 NoMotion-1. Scaled. Acc [ -00031.25, -00016.60, 00999.51 ], Gyr [ 00000.55, 00002.87, -00001.22 ], Mag [ 00021.00, 00084.00, 00106.50 ]",
    "#0000008298 NoMotion-1. Scaled. Acc [ -00046.39, -00019.53, 00987.30 ], Gyr [ -00000.24, 00002.68, -00001.89 ], Mag [ 00021.00, 00084.00, 00106.50 ]",
    "#0000008299 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00991.21 ], Gyr [ 00002.26, 00000.91, 00000.37 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008300 NoMotion-1. Scaled. Acc [ -00035.16, -00013.67, 00979.49 ], Gyr [ 00001.22, 00001.52, -00001.22 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008301 NoMotion-1. Scaled. Acc [ -00045.41, -00009.77, 00986.33 ], Gyr [ 00002.93, 00000.43, -00000.49 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008302 NoMotion-1. Scaled. Acc [ -00036.62, 00001.95, 00987.30 ], Gyr [ 00000.24, 00000.12, -00003.11 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008303 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00998.05 ], Gyr [ 00002.44, 00000.49, -00000.67 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008304 NoMotion-1. Scaled. Acc [ -00039.55, -00022.46, 00982.91 ], Gyr [ 00001.22, 00000.37, 00000.37 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008305 NoMotion-1. Scaled. Acc [ -00028.32, -00007.32, 00998.05 ], Gyr [ -00000.85, -00000.37, -00002.20 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000008306 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 00986.82 ], Gyr [ 00000.55, 00000.06, -00001.59 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000008307 NoMotion-1. Scaled. Acc [ -00035.16, -00015.14, 00989.75 ], Gyr [ -00000.24, 00002.56, -00000.79 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000008308 NoMotion-1. Scaled. Acc [ -00035.16, 00004.88, 00993.16 ], Gyr [ -00002.50, 00001.83, -00002.01 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000008309 NoMotion-1. Scaled. Acc [ -00041.99, 00000.98, 00999.02 ], Gyr [ 00000.67, 00002.26, -00001.89 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008310 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00979.49 ], Gyr [ 00000.55, 00001.52, -00000.37 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008311 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00988.28 ], Gyr [ -00000.12, -00000.61, -00001.16 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008312 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00993.65 ], Gyr [ 00001.04, -00000.61, -00003.17 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008313 NoMotion-1. Scaled. Acc [ -00052.25, -00003.91, 01001.95 ], Gyr [ 00002.93, 00000.43, 00000.30 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008314 NoMotion-1. Scaled. Acc [ -00058.59, -00010.25, 00993.16 ], Gyr [ -00000.12, -00000.61, 00000.06 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008315 NoMotion-1. Scaled. Acc [ -00037.60, -00017.58, 00997.07 ], Gyr [ 00000.98, 00002.20, 00000.12 ], Mag [ 00018.60, 00084.15, 00106.20 ]",
    "#0000008316 NoMotion-1. Scaled. Acc [ -00053.22, -00003.91, 01004.88 ], Gyr [ 00000.49, 00001.65, -00001.22 ], Mag [ 00018.60, 00084.15, 00106.20 ]",
    "#0000008317 NoMotion-1. Scaled. Acc [ -00031.74, -00004.39, 00983.89 ], Gyr [ 00000.85, 00002.62, -00002.38 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000008318 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00984.86 ], Gyr [ -00000.61, 00002.07, -00001.34 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000008319 NoMotion-1. Scaled. Acc [ -00046.88, -00016.11, 01000.49 ], Gyr [ 00000.18, 00002.32, -00000.79 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008320 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00981.45 ], Gyr [ 00000.85, 00005.12, -00002.01 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008321 NoMotion-1. Scaled. Acc [ -00038.09, 00001.46, 00994.63 ], Gyr [ 00000.06, 00003.66, -00002.68 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008322 NoMotion-1. Scaled. Acc [ -00028.32, -00009.77, 00993.16 ], Gyr [ 00001.46, 00002.01, -00003.35 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008323 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00992.68 ], Gyr [ 00002.32, 00000.85, -00001.10 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000008324 NoMotion-1. Scaled. Acc [ -00039.55, -00016.60, 01000.49 ], Gyr [ 00001.16, 00001.95, 00001.77 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000008325 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00997.07 ], Gyr [ -00001.46, 00000.98, -00003.29 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008326 NoMotion-1. Scaled. Acc [ -00040.53, -00015.62, 00994.14 ], Gyr [ 00001.22, -00000.18, -00001.65 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008327 NoMotion-1. Scaled. Acc [ -00047.85, 00000.98, 00981.45 ], Gyr [ 00001.34, -00000.98, -00000.61 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000008328 NoMotion-1. Scaled. Acc [ -00044.43, -00003.42, 00980.47 ], Gyr [ -00000.55, 00000.98, 00000.67 ], Mag [ 00019.50, 00084.75, 00107.55 ]",
    "#0000008329 NoMotion-1. Scaled. Acc [ -00038.57, -00010.25, 00974.12 ], Gyr [ 00000.55, 00001.10, -00001.83 ], Mag [ 00019.50, 00084.75, 00107.55 ]",
    "#0000008330 NoMotion-1. Scaled. Acc [ -00040.53, -00010.74, 01001.95 ], Gyr [ -00001.65, 00002.38, -00001.52 ], Mag [ 00019.50, 00084.75, 00107.55 ]",
    "#0000008331 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00994.63 ], Gyr [ -00000.24, 00000.37, -00001.52 ], Mag [ 00021.15, 00084.15, 00107.70 ]",
    "#0000008332 NoMotion-1. Scaled. Acc [ -00040.53, -00007.81, 00988.77 ], Gyr [ -00000.37, 00000.49, -00003.17 ], Mag [ 00021.15, 00084.15, 00107.70 ]",
    "#0000008333 NoMotion-1. Scaled. Acc [ -00053.71, -00004.88, 00993.16 ], Gyr [ -00001.28, 00001.16, -00002.07 ], Mag [ 00020.25, 00084.15, 00107.70 ]",
    "#0000008334 NoMotion-1. Scaled. Acc [ -00047.36, -00002.44, 00993.65 ], Gyr [ 00000.37, 00002.32, -00001.71 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008335 NoMotion-1. Scaled. Acc [ -00047.36, -00009.77, 00996.09 ], Gyr [ 00000.91, 00001.22, -00001.89 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008336 NoMotion-1. Scaled. Acc [ -00041.99, -00004.88, 00981.93 ], Gyr [ 00000.98, 00002.74, -00003.23 ], Mag [ 00020.10, 00082.20, 00106.95 ]",
    "#0000008337 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00987.30 ], Gyr [ -00000.55, 00000.79, -00002.01 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008338 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00987.79 ], Gyr [ 00000.98, 00000.18, -00001.52 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008339 NoMotion-1. Scaled. Acc [ -00036.13, -00000.98, 00992.19 ], Gyr [ -00000.30, -00000.37, 00000.37 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008340 NoMotion-1. Scaled. Acc [ -00041.50, -00010.25, 00991.70 ], Gyr [ 00001.83, 00001.40, -00000.73 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008341 NoMotion-1. Scaled. Acc [ -00033.69, -00011.72, 00987.79 ], Gyr [ -00000.79, 00001.95, -00003.17 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000008342 NoMotion-1. Scaled. Acc [ -00048.34, 00004.39, 00980.96 ], Gyr [ -00000.49, 00004.02, -00001.71 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000008343 NoMotion-1. Scaled. Acc [ -00033.69, -00007.32, 00986.82 ], Gyr [ 00001.77, 00002.01, -00000.61 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008344 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00993.16 ], Gyr [ 00001.65, 00000.37, -00000.85 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008345 NoMotion-1. Scaled. Acc [ -00043.95, 00009.77, 00985.84 ], Gyr [ -00000.06, 00001.34, -00003.48 ], Mag [ 00020.70, 00083.55, 00107.85 ]",
    "#0000008346 NoMotion-1. Scaled. Acc [ -00032.71, -00003.91, 00993.65 ], Gyr [ 00000.61, 00002.74, -00002.93 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008347 NoMotion-1. Scaled. Acc [ -00046.39, -00030.76, 00992.19 ], Gyr [ 00000.91, 00000.12, -00001.77 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008348 NoMotion-1. Scaled. Acc [ -00045.90, -00011.72, 00998.54 ], Gyr [ 00000.79, 00002.38, -00002.44 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008349 NoMotion-1. Scaled. Acc [ -00039.55, -00011.23, 00984.38 ], Gyr [ 00001.52, 00000.73, -00000.12 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008350 NoMotion-1. Scaled. Acc [ -00046.88, -00006.84, 00990.23 ], Gyr [ -00001.71, 00001.28, -00003.29 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000008351 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00990.72 ], Gyr [ -00000.24, 00000.43, 00000.06 ], Mag [ 00017.70, 00084.00, 00107.55 ]",
    "#0000008352 NoMotion-1. Scaled. Acc [ -00050.29, -00014.65, 00995.12 ], Gyr [ 00000.85, 00000.85, -00000.18 ], Mag [ 00020.70, 00082.80, 00106.50 ]",
    "#0000008353 NoMotion-1. Scaled. Acc [ -00046.88, -00020.02, 00997.07 ], Gyr [ 00000.30, 00000.79, -00004.39 ], Mag [ 00020.70, 00082.80, 00106.50 ]",
    "#0000008354 NoMotion-1. Scaled. Acc [ -00036.62, -00006.84, 00995.61 ], Gyr [ -00000.91, 00000.98, -00002.80 ], Mag [ 00020.70, 00082.80, 00106.50 ]",
    "#0000008355 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00991.70 ], Gyr [ -00000.85, 00001.34, -00003.72 ], Mag [ 00020.70, 00083.25, 00107.85 ]",
    "#0000008356 NoMotion-1. Scaled. Acc [ -00039.06, 00000.49, 00994.14 ], Gyr [ 00000.37, -00000.73, 00000.91 ], Mag [ 00020.70, 00083.25, 00107.85 ]",
    "#0000008357 NoMotion-1. Scaled. Acc [ -00040.53, 00001.95, 00989.75 ], Gyr [ -00000.73, 00001.89, -00002.13 ], Mag [ 00018.60, 00083.70, 00108.45 ]",
    "#0000008358 NoMotion-1. Scaled. Acc [ -00040.53, -00006.35, 00986.33 ], Gyr [ -00000.73, 00003.90, -00003.72 ], Mag [ 00018.60, 00083.70, 00108.45 ]",
    "#0000008359 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 00982.91 ], Gyr [ 00000.37, 00002.68, -00002.13 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000008360 NoMotion-1. Scaled. Acc [ -00036.13, -00004.88, 00992.68 ], Gyr [ 00001.77, -00001.16, -00003.66 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000008361 NoMotion-1. Scaled. Acc [ -00041.02, -00006.84, 01000.98 ], Gyr [ 00001.28, 00002.07, -00000.37 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008362 NoMotion-1. Scaled. Acc [ -00048.34, -00009.28, 00984.86 ], Gyr [ 00001.40, -00003.11, -00000.12 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008363 NoMotion-1. Scaled. Acc [ -00044.92, -00000.49, 00983.40 ], Gyr [ 00001.52, 00002.80, -00000.30 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008364 NoMotion-1. Scaled. Acc [ -00058.11, -00004.88, 00989.75 ], Gyr [ -00000.18, 00002.32, -00003.48 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008365 NoMotion-1. Scaled. Acc [ -00049.80, -00010.74, 00993.65 ], Gyr [ 00000.79, -00000.30, -00002.26 ], Mag [ 00018.60, 00083.70, 00107.40 ]",
    "#0000008366 NoMotion-1. Scaled. Acc [ -00035.64, -00009.77, 00998.54 ], Gyr [ 00001.59, 00003.17, -00000.06 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008367 NoMotion-1. Scaled. Acc [ -00043.95, -00010.25, 01000.00 ], Gyr [ -00001.10, 00000.61, -00002.56 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008368 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 00986.33 ], Gyr [ 00000.55, 00000.67, -00002.50 ], Mag [ 00020.85, 00084.00, 00107.85 ]",
    "#0000008369 NoMotion-1. Scaled. Acc [ -00041.99, 00000.49, 00987.30 ], Gyr [ -00000.43, 00002.50, -00001.46 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000008370 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00987.79 ], Gyr [ 00000.43, 00001.10, -00001.71 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008371 NoMotion-1. Scaled. Acc [ -00036.62, 00006.35, 00989.26 ], Gyr [ 00001.28, -00000.85, -00002.44 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008372 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00981.45 ], Gyr [ -00000.98, 00001.95, -00001.89 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008373 NoMotion-1. Scaled. Acc [ -00056.64, -00001.46, 00999.02 ], Gyr [ -00002.74, 00003.66, -00000.79 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008374 NoMotion-1. Scaled. Acc [ -00052.73, -00005.37, 00990.72 ], Gyr [ 00000.79, 00001.34, 00000.18 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000008375 NoMotion-1. Scaled. Acc [ -00033.20, -00010.25, 00991.70 ], Gyr [ 00000.18, 00000.55, 00000.91 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000008376 NoMotion-1. Scaled. Acc [ -00047.85, -00002.93, 00989.75 ], Gyr [ 00001.59, 00002.07, -00002.62 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008377 NoMotion-1. Scaled. Acc [ -00046.39, -00006.84, 00989.26 ], Gyr [ 00000.73, 00001.16, -00000.43 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008378 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00994.14 ], Gyr [ 00002.01, 00001.04, 00000.00 ], Mag [ 00019.95, 00084.30, 00106.80 ]",
    "#0000008379 NoMotion-1. Scaled. Acc [ -00050.78, -00003.42, 00979.00 ], Gyr [ 00000.98, -00000.98, 00001.22 ], Mag [ 00019.95, 00084.30, 00106.80 ]",
    "#0000008380 NoMotion-1. Scaled. Acc [ -00047.85, -00000.49, 00993.65 ], Gyr [ 00001.77, 00002.62, -00002.26 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008381 NoMotion-1. Scaled. Acc [ -00039.55, -00012.21, 00987.30 ], Gyr [ -00000.43, 00002.87, -00003.66 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008382 NoMotion-1. Scaled. Acc [ -00039.06, -00003.91, 00977.54 ], Gyr [ -00000.37, 00000.06, -00001.10 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008383 NoMotion-1. Scaled. Acc [ -00037.11, -00008.79, 00994.63 ], Gyr [ 00001.46, 00000.37, -00001.10 ], Mag [ 00018.75, 00083.55, 00106.50 ]",
    "#0000008384 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00987.30 ], Gyr [ -00000.79, 00002.68, -00004.45 ], Mag [ 00018.75, 00083.55, 00106.50 ]",
    "#0000008385 NoMotion-1. Scaled. Acc [ -00046.88, 00000.49, 00990.72 ], Gyr [ 00000.37, 00005.06, 00001.89 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008386 NoMotion-1. Scaled. Acc [ -00028.81, 00002.44, 00991.21 ], Gyr [ 00001.77, 00000.73, -00002.20 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008387 NoMotion-1. Scaled. Acc [ -00042.97, -00013.67, 01005.37 ], Gyr [ 00001.65, -00001.65, -00001.52 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000008388 NoMotion-1. Scaled. Acc [ -00042.48, -00008.79, 01002.44 ], Gyr [ 00000.98, 00000.85, -00000.49 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008389 NoMotion-1. Scaled. Acc [ -00042.48, -00015.14, 01001.95 ], Gyr [ -00001.28, 00003.29, -00000.73 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008390 NoMotion-1. Scaled. Acc [ -00051.27, -00007.32, 00988.77 ], Gyr [ 00000.55, 00001.65, -00004.39 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008391 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00991.70 ], Gyr [ 00001.04, 00002.68, -00000.79 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008392 NoMotion-1. Scaled. Acc [ -00046.39, -00013.67, 00994.63 ], Gyr [ -00000.18, -00000.37, -00000.24 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000008393 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00986.33 ], Gyr [ 00000.91, 00002.44, -00001.46 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000008394 NoMotion-1. Scaled. Acc [ -00035.16, -00003.42, 00998.05 ], Gyr [ -00000.12, -00000.73, -00003.05 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000008395 NoMotion-1. Scaled. Acc [ -00037.11, -00003.42, 00992.19 ], Gyr [ -00001.16, -00001.83, -00000.67 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000008396 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00988.28 ], Gyr [ -00000.12, 00001.46, -00002.44 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000008397 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00990.72 ], Gyr [ -00001.95, 00003.84, -00000.73 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000008398 NoMotion-1. Scaled. Acc [ -00038.57, -00010.74, 00975.59 ], Gyr [ -00001.16, 00000.79, -00001.95 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008399 NoMotion-1. Scaled. Acc [ -00046.39, -00008.30, 00971.19 ], Gyr [ 00001.59, 00001.22, -00001.46 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008400 NoMotion-1. Scaled. Acc [ -00049.80, -00017.09, 00981.45 ], Gyr [ 00000.30, 00000.37, -00004.57 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008401 NoMotion-1. Scaled. Acc [ -00055.18, -00011.23, 00990.23 ], Gyr [ 00001.16, 00001.52, -00001.95 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008402 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00988.77 ], Gyr [ 00001.77, 00000.43, -00002.01 ], Mag [ 00018.45, 00085.05, 00107.85 ]",
    "#0000008403 NoMotion-1. Scaled. Acc [ -00048.34, -00011.23, 00995.61 ], Gyr [ 00002.20, 00000.98, 00001.04 ], Mag [ 00019.65, 00084.45, 00107.70 ]",
    "#0000008404 NoMotion-1. Scaled. Acc [ -00031.25, -00002.44, 00984.86 ], Gyr [ 00000.00, 00000.67, -00002.01 ], Mag [ 00019.65, 00084.45, 00107.70 ]",
    "#0000008405 NoMotion-1. Scaled. Acc [ -00031.74, -00007.32, 00985.84 ], Gyr [ 00000.43, -00000.06, -00003.05 ], Mag [ 00019.35, 00082.65, 00108.45 ]",
    "#0000008406 NoMotion-1. Scaled. Acc [ -00039.55, -00013.67, 00996.58 ], Gyr [ 00000.55, 00001.16, -00004.51 ], Mag [ 00019.35, 00082.65, 00108.45 ]",
    "#0000008407 NoMotion-1. Scaled. Acc [ -00049.32, -00001.95, 00988.77 ], Gyr [ 00001.10, 00001.22, -00000.12 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008408 NoMotion-1. Scaled. Acc [ -00046.39, 00003.42, 00999.02 ], Gyr [ -00000.61, 00002.87, -00002.62 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008409 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00988.28 ], Gyr [ -00000.18, 00000.91, -00001.77 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008410 NoMotion-1. Scaled. Acc [ -00034.67, -00018.55, 00993.16 ], Gyr [ 00000.49, -00000.37, 00001.28 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008411 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00995.12 ], Gyr [ 00000.43, 00000.55, -00000.43 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008412 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00980.96 ], Gyr [ -00000.30, 00002.01, -00000.67 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008413 NoMotion-1. Scaled. Acc [ -00049.32, -00014.16, 00994.63 ], Gyr [ 00000.12, 00000.61, -00001.89 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008414 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00983.89 ], Gyr [ 00000.06, 00000.37, -00000.24 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008415 NoMotion-1. Scaled. Acc [ -00040.53, 00004.39, 00997.56 ], Gyr [ 00001.04, 00002.50, -00000.37 ], Mag [ 00021.15, 00083.40, 00108.00 ]",
    "#0000008416 NoMotion-1. Scaled. Acc [ -00042.97, -00012.21, 00987.30 ], Gyr [ 00003.05, -00000.30, -00000.18 ], Mag [ 00021.15, 00083.40, 00108.00 ]",
    "#0000008417 NoMotion-1. Scaled. Acc [ -00049.80, -00013.18, 00987.30 ], Gyr [ 00002.20, 00003.11, 00000.49 ], Mag [ 00019.20, 00084.30, 00109.35 ]",
    "#0000008418 NoMotion-1. Scaled. Acc [ -00037.11, 00004.39, 00992.19 ], Gyr [ 00000.91, 00000.73, -00000.49 ], Mag [ 00019.20, 00084.30, 00109.35 ]",
    "#0000008419 NoMotion-1. Scaled. Acc [ -00055.66, 00001.95, 01000.00 ], Gyr [ 00001.34, 00004.15, -00002.62 ], Mag [ 00019.20, 00084.30, 00109.35 ]",
    "#0000008420 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 00990.23 ], Gyr [ -00000.43, 00002.20, -00002.99 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008421 NoMotion-1. Scaled. Acc [ -00043.46, -00010.74, 00982.42 ], Gyr [ -00000.73, -00001.10, -00001.59 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008422 NoMotion-1. Scaled. Acc [ -00037.60, -00003.91, 00989.75 ], Gyr [ -00000.43, 00000.85, -00000.98 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008423 NoMotion-1. Scaled. Acc [ -00044.92, -00002.44, 00998.05 ], Gyr [ 00000.12, 00000.37, -00004.02 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008424 NoMotion-1. Scaled. Acc [ -00035.64, -00001.95, 00992.68 ], Gyr [ 00000.43, 00002.20, -00000.24 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000008425 NoMotion-1. Scaled. Acc [ -00052.73, 00005.37, 00989.75 ], Gyr [ 00000.79, 00003.11, -00002.20 ], Mag [ 00020.25, 00082.50, 00108.60 ]",
    "#0000008426 NoMotion-1. Scaled. Acc [ -00043.46, -00013.18, 00998.05 ], Gyr [ 00000.79, -00000.43, 00000.73 ], Mag [ 00020.25, 00082.50, 00108.60 ]",
    "#0000008427 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00992.68 ], Gyr [ 00000.91, -00000.06, 00000.30 ], Mag [ 00020.25, 00082.50, 00108.60 ]",
    "#0000008428 NoMotion-1. Scaled. Acc [ -00032.71, 00003.91, 00995.12 ], Gyr [ 00001.04, -00000.18, -00001.10 ], Mag [ 00018.75, 00083.55, 00107.85 ]",
    "#0000008429 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00995.12 ], Gyr [ 00001.28, -00000.55, -00001.22 ], Mag [ 00018.75, 00083.55, 00107.85 ]",
    "#0000008430 NoMotion-1. Scaled. Acc [ -00044.92, -00001.95, 00987.30 ], Gyr [ -00001.59, -00001.16, 00000.12 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000008431 NoMotion-1. Scaled. Acc [ -00059.08, 00002.44, 00992.68 ], Gyr [ 00001.22, -00001.89, 00000.30 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000008432 NoMotion-1. Scaled. Acc [ -00040.04, -00008.30, 00993.65 ], Gyr [ -00000.91, 00000.79, -00002.74 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000008433 NoMotion-1. Scaled. Acc [ -00041.99, -00017.58, 00986.33 ], Gyr [ -00000.18, 00002.13, -00002.50 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000008434 NoMotion-1. Scaled. Acc [ -00049.80, -00017.58, 00993.65 ], Gyr [ -00001.16, 00002.87, -00003.05 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000008435 NoMotion-1. Scaled. Acc [ -00034.18, -00009.28, 00993.16 ], Gyr [ 00000.73, 00000.73, -00000.12 ], Mag [ 00018.75, 00083.25, 00107.85 ]",
    "#0000008436 NoMotion-1. Scaled. Acc [ -00050.78, -00011.72, 00988.77 ], Gyr [ -00000.24, 00002.13, 00000.43 ], Mag [ 00018.75, 00083.25, 00107.85 ]",
    "#0000008437 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 00999.02 ], Gyr [ -00000.79, 00002.07, -00002.80 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000008438 NoMotion-1. Scaled. Acc [ -00040.04, -00015.62, 01000.00 ], Gyr [ 00002.26, 00002.07, -00001.28 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008439 NoMotion-1. Scaled. Acc [ -00051.76, -00009.28, 00985.84 ], Gyr [ 00002.99, 00001.52, -00001.40 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008440 NoMotion-1. Scaled. Acc [ -00054.20, -00007.32, 00992.68 ], Gyr [ 00001.46, 00001.40, -00001.34 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008441 NoMotion-1. Scaled. Acc [ -00045.90, -00000.98, 00988.77 ], Gyr [ 00000.85, 00001.04, 00000.06 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008442 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00993.16 ], Gyr [ 00000.43, 00000.61, -00000.67 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008443 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00994.63 ], Gyr [ 00000.06, 00001.83, -00001.89 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008444 NoMotion-1. Scaled. Acc [ -00051.76, -00015.14, 00989.26 ], Gyr [ -00001.28, 00004.09, -00001.52 ], Mag [ 00020.10, 00085.20, 00106.65 ]",
    "#0000008445 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00989.26 ], Gyr [ 00000.67, 00002.44, -00001.46 ], Mag [ 00020.10, 00085.20, 00106.65 ]",
    "#0000008446 NoMotion-1. Scaled. Acc [ -00040.04, -00025.88, 00991.70 ], Gyr [ -00000.98, 00003.17, -00003.54 ], Mag [ 00018.60, 00082.20, 00106.95 ]",
    "#0000008447 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00988.77 ], Gyr [ 00000.73, 00001.22, -00000.85 ], Mag [ 00018.60, 00082.20, 00106.95 ]",
    "#0000008448 NoMotion-1. Scaled. Acc [ -00041.99, -00009.77, 00980.96 ], Gyr [ 00000.24, 00001.52, -00002.68 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008449 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00990.23 ], Gyr [ 00000.67, 00000.37, -00001.52 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008450 NoMotion-1. Scaled. Acc [ -00049.80, -00011.72, 00984.38 ], Gyr [ -00001.65, 00002.26, -00003.90 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008451 NoMotion-1. Scaled. Acc [ -00038.57, 00001.95, 00990.72 ], Gyr [ 00002.07, 00004.27, -00000.67 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008452 NoMotion-1. Scaled. Acc [ -00038.09, -00013.67, 00982.91 ], Gyr [ 00001.59, -00000.49, -00001.65 ], Mag [ 00019.05, 00084.15, 00108.75 ]",
    "#0000008453 NoMotion-1. Scaled. Acc [ -00044.92, -00000.49, 01002.93 ], Gyr [ 00001.52, 00002.38, -00000.43 ], Mag [ 00019.05, 00084.15, 00108.75 ]",
    "#0000008454 NoMotion-1. Scaled. Acc [ -00039.55, -00002.93, 00991.21 ], Gyr [ 00000.61, 00001.77, -00003.35 ], Mag [ 00021.30, 00084.00, 00108.30 ]",
    "#0000008455 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00986.33 ], Gyr [ 00001.04, -00000.37, -00000.61 ], Mag [ 00021.30, 00084.00, 00108.30 ]",
    "#0000008456 NoMotion-1. Scaled. Acc [ -00045.90, -00003.42, 01001.95 ], Gyr [ 00000.91, 00001.04, -00000.49 ], Mag [ 00021.15, 00082.65, 00108.45 ]",
    "#0000008457 NoMotion-1. Scaled. Acc [ -00040.04, 00001.95, 00992.68 ], Gyr [ 00000.06, 00001.28, -00003.78 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000008458 NoMotion-1. Scaled. Acc [ -00045.90, -00002.93, 00993.16 ], Gyr [ -00000.30, 00002.62, -00000.67 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000008459 NoMotion-1. Scaled. Acc [ -00035.64, -00007.81, 00990.72 ], Gyr [ 00000.49, 00000.24, -00000.79 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000008460 NoMotion-1. Scaled. Acc [ -00041.50, 00000.98, 00995.61 ], Gyr [ 00001.34, 00003.29, -00003.17 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000008461 NoMotion-1. Scaled. Acc [ -00054.20, -00002.93, 00984.38 ], Gyr [ 00000.49, 00000.67, -00001.34 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000008462 NoMotion-1. Scaled. Acc [ -00042.97, -00008.79, 00986.82 ], Gyr [ 00001.77, 00001.52, -00001.04 ], Mag [ 00019.20, 00083.55, 00108.30 ]",
    "#0000008463 NoMotion-1. Scaled. Acc [ -00041.99, -00014.16, 00991.70 ], Gyr [ 00001.65, 00004.09, -00000.98 ], Mag [ 00019.20, 00083.55, 00108.30 ]",
    "#0000008464 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00979.98 ], Gyr [ 00000.91, 00000.37, -00000.06 ], Mag [ 00018.75, 00084.75, 00108.60 ]",
    "#0000008465 NoMotion-1. Scaled. Acc [ -00059.08, -00000.49, 00997.07 ], Gyr [ 00003.17, 00000.18, -00000.73 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008466 NoMotion-1. Scaled. Acc [ -00048.34, -00011.23, 00988.77 ], Gyr [ 00000.91, 00001.89, -00002.93 ], Mag [ 00019.50, 00081.75, 00106.80 ]",
    "#0000008467 NoMotion-1. Scaled. Acc [ -00044.43, -00011.72, 00984.38 ], Gyr [ -00001.04, 00002.56, -00001.10 ], Mag [ 00019.50, 00081.75, 00106.80 ]",
    "#0000008468 NoMotion-1. Scaled. Acc [ -00038.09, -00005.37, 00980.96 ], Gyr [ -00000.49, 00000.85, -00004.39 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008469 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00992.68 ], Gyr [ 00000.37, 00001.34, -00001.22 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008470 NoMotion-1. Scaled. Acc [ -00059.08, 00001.46, 00994.63 ], Gyr [ 00000.55, 00002.99, -00003.96 ], Mag [ 00020.10, 00081.45, 00107.40 ]",
    "#0000008471 NoMotion-1. Scaled. Acc [ -00041.50, 00000.00, 00999.51 ], Gyr [ 00000.37, 00003.35, -00001.34 ], Mag [ 00020.10, 00081.45, 00107.40 ]",
    "#0000008472 NoMotion-1. Scaled. Acc [ -00063.48, -00012.21, 00990.72 ], Gyr [ 00000.98, 00000.67, -00001.59 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000008473 NoMotion-1. Scaled. Acc [ -00045.41, -00017.58, 00991.70 ], Gyr [ 00000.30, 00001.83, -00001.16 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000008474 NoMotion-1. Scaled. Acc [ -00047.85, -00019.53, 00994.14 ], Gyr [ 00002.20, 00001.52, -00001.65 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000008475 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00990.23 ], Gyr [ 00001.22, -00000.98, -00000.24 ], Mag [ 00018.60, 00084.90, 00108.00 ]",
    "#0000008476 NoMotion-1. Scaled. Acc [ -00041.50, -00014.65, 01004.39 ], Gyr [ 00001.34, 00001.22, 00000.85 ], Mag [ 00018.60, 00084.90, 00108.00 ]",
    "#0000008477 NoMotion-1. Scaled. Acc [ -00059.57, -00010.74, 00999.02 ], Gyr [ 00002.62, 00003.35, -00002.93 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008478 NoMotion-1. Scaled. Acc [ -00034.67, -00011.72, 00990.72 ], Gyr [ 00000.85, -00000.30, -00001.34 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008479 NoMotion-1. Scaled. Acc [ -00041.99, -00010.25, 00990.23 ], Gyr [ -00001.59, 00001.71, -00001.10 ], Mag [ 00019.65, 00084.45, 00109.50 ]",
    "#0000008480 NoMotion-1. Scaled. Acc [ -00038.57, -00016.11, 00989.26 ], Gyr [ 00001.34, 00001.22, -00002.93 ], Mag [ 00019.65, 00084.45, 00109.50 ]",
    "#0000008481 NoMotion-1. Scaled. Acc [ -00032.71, -00009.77, 00999.51 ], Gyr [ 00002.13, -00000.18, -00000.12 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008482 NoMotion-1. Scaled. Acc [ -00052.25, -00013.18, 00989.75 ], Gyr [ 00000.91, 00004.39, -00003.41 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008483 NoMotion-1. Scaled. Acc [ -00052.73, -00000.49, 00998.54 ], Gyr [ 00000.30, 00002.26, -00000.79 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008484 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00997.07 ], Gyr [ -00000.12, 00003.17, -00001.22 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008485 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 00992.68 ], Gyr [ 00001.89, 00002.68, -00000.12 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008486 NoMotion-1. Scaled. Acc [ -00050.78, -00010.25, 01006.35 ], Gyr [ 00000.55, -00000.24, -00000.06 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008487 NoMotion-1. Scaled. Acc [ -00047.85, -00017.09, 00990.72 ], Gyr [ -00000.12, 00000.30, -00000.24 ], Mag [ 00018.75, 00082.50, 00108.30 ]",
    "#0000008488 NoMotion-1. Scaled. Acc [ -00036.62, -00002.44, 00987.30 ], Gyr [ 00001.59, 00001.34, -00001.28 ], Mag [ 00018.75, 00082.50, 00108.30 ]",
    "#0000008489 NoMotion-1. Scaled. Acc [ -00041.02, -00003.91, 00996.09 ], Gyr [ 00002.50, 00000.00, 00000.18 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008490 NoMotion-1. Scaled. Acc [ -00036.13, -00014.65, 00994.14 ], Gyr [ -00000.06, -00000.85, -00000.24 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008491 NoMotion-1. Scaled. Acc [ -00036.13, -00013.67, 00998.05 ], Gyr [ -00001.04, 00004.09, -00001.52 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008492 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00985.84 ], Gyr [ -00000.49, 00001.89, -00000.55 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008493 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00993.65 ], Gyr [ -00001.77, -00000.12, -00001.34 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000008494 NoMotion-1. Scaled. Acc [ -00051.27, -00014.16, 00979.98 ], Gyr [ 00001.77, -00000.49, -00000.37 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000008495 NoMotion-1. Scaled. Acc [ -00044.92, -00005.86, 00990.23 ], Gyr [ -00000.12, 00002.01, 00000.43 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000008496 NoMotion-1. Scaled. Acc [ -00039.06, -00005.86, 00982.91 ], Gyr [ 00000.79, 00000.61, -00003.05 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000008497 NoMotion-1. Scaled. Acc [ -00051.76, -00005.37, 00993.16 ], Gyr [ 00000.61, 00004.27, -00001.04 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008498 NoMotion-1. Scaled. Acc [ -00037.60, -00006.35, 00981.45 ], Gyr [ 00001.28, 00000.98, -00000.61 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008499 NoMotion-1. Scaled. Acc [ -00041.02, -00004.88, 00974.12 ], Gyr [ -00000.12, 00001.28, -00001.10 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008500 NoMotion-1. Scaled. Acc [ -00050.78, -00008.79, 00992.68 ], Gyr [ 00000.67, 00001.10, -00002.32 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008501 NoMotion-1. Scaled. Acc [ -00034.18, -00013.67, 00989.26 ], Gyr [ 00001.28, 00002.80, -00000.43 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008502 NoMotion-1. Scaled. Acc [ -00048.34, -00012.70, 00993.65 ], Gyr [ 00001.46, 00001.34, -00004.21 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008503 NoMotion-1. Scaled. Acc [ -00049.80, -00018.07, 00984.38 ], Gyr [ 00000.12, 00000.18, 00001.89 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000008504 NoMotion-1. Scaled. Acc [ -00048.34, -00010.74, 00995.12 ], Gyr [ -00000.06, 00002.44, -00002.20 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000008505 NoMotion-1. Scaled. Acc [ -00054.69, -00012.21, 00988.77 ], Gyr [ 00000.24, 00001.34, -00002.20 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000008506 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00997.07 ], Gyr [ 00001.95, 00000.79, -00001.34 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000008507 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00993.65 ], Gyr [ 00001.65, 00001.22, -00002.26 ], Mag [ 00020.25, 00082.80, 00108.30 ]",
    "#0000008508 NoMotion-1. Scaled. Acc [ -00041.02, -00003.91, 00977.54 ], Gyr [ 00000.00, 00002.01, -00000.49 ], Mag [ 00020.25, 00082.80, 00108.30 ]",
    "#0000008509 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00987.30 ], Gyr [ 00001.40, 00000.43, -00005.49 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008510 NoMotion-1. Scaled. Acc [ -00035.16, -00008.30, 00996.58 ], Gyr [ 00000.67, 00001.59, -00002.13 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008511 NoMotion-1. Scaled. Acc [ -00049.32, -00014.16, 00994.63 ], Gyr [ -00002.32, 00000.79, -00000.06 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008512 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00991.70 ], Gyr [ -00000.98, -00000.73, -00001.89 ], Mag [ 00019.50, 00083.55, 00106.50 ]",
    "#0000008513 NoMotion-1. Scaled. Acc [ -00037.11, -00012.70, 00987.30 ], Gyr [ 00000.43, 00001.46, 00000.12 ], Mag [ 00019.50, 00083.55, 00106.50 ]",
    "#0000008514 NoMotion-1. Scaled. Acc [ -00044.92, -00016.60, 00990.72 ], Gyr [ 00002.56, 00001.95, -00000.12 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000008515 NoMotion-1. Scaled. Acc [ -00047.85, -00019.53, 00984.86 ], Gyr [ 00001.04, -00000.30, -00002.01 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000008516 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00989.26 ], Gyr [ -00000.18, 00003.23, -00001.59 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000008517 NoMotion-1. Scaled. Acc [ -00042.97, -00003.42, 00985.35 ], Gyr [ 00001.22, 00000.49, -00001.71 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000008518 NoMotion-1. Scaled. Acc [ -00033.69, -00007.81, 00978.52 ], Gyr [ 00001.52, 00000.67, 00000.00 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000008519 NoMotion-1. Scaled. Acc [ -00031.25, -00015.62, 00996.09 ], Gyr [ -00001.04, 00000.18, -00003.05 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000008520 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00998.05 ], Gyr [ -00001.16, 00004.09, 00000.55 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008521 NoMotion-1. Scaled. Acc [ -00053.22, -00006.35, 00995.61 ], Gyr [ 00000.24, 00002.74, -00002.01 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008522 NoMotion-1. Scaled. Acc [ -00040.53, -00006.84, 00995.12 ], Gyr [ 00000.98, 00002.87, -00001.28 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008523 NoMotion-1. Scaled. Acc [ -00043.95, -00006.84, 00989.75 ], Gyr [ 00000.06, 00001.65, 00000.85 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008524 NoMotion-1. Scaled. Acc [ -00048.34, 00000.00, 00988.77 ], Gyr [ -00000.49, 00002.62, -00001.52 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000008525 NoMotion-1. Scaled. Acc [ -00034.67, -00016.11, 00991.21 ], Gyr [ 00001.22, 00000.43, -00002.44 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000008526 NoMotion-1. Scaled. Acc [ -00062.01, -00008.30, 00996.09 ], Gyr [ 00003.23, -00001.04, 00000.67 ], Mag [ 00018.75, 00084.30, 00109.65 ]",
    "#0000008527 NoMotion-1. Scaled. Acc [ -00043.95, 00001.95, 00987.30 ], Gyr [ 00001.89, -00000.06, -00000.79 ], Mag [ 00018.75, 00084.30, 00109.65 ]",
    "#0000008528 NoMotion-1. Scaled. Acc [ -00034.67, -00007.81, 00985.84 ], Gyr [ 00000.12, -00001.16, 00000.43 ], Mag [ 00018.75, 00084.30, 00109.65 ]",
    "#0000008529 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00979.98 ], Gyr [ 00001.95, 00001.65, 00001.04 ], Mag [ 00021.30, 00083.25, 00107.85 ]",
    "#0000008530 NoMotion-1. Scaled. Acc [ -00048.83, -00004.88, 00990.72 ], Gyr [ 00001.40, 00001.95, -00002.07 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008531 NoMotion-1. Scaled. Acc [ -00038.57, -00015.62, 00988.77 ], Gyr [ -00001.22, 00001.34, -00000.06 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008532 NoMotion-1. Scaled. Acc [ -00042.48, -00002.44, 00999.51 ], Gyr [ -00001.28, 00001.46, 00000.37 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008533 NoMotion-1. Scaled. Acc [ -00035.64, -00013.67, 00996.09 ], Gyr [ 00000.30, 00001.83, -00002.07 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000008534 NoMotion-1. Scaled. Acc [ -00041.02, -00005.37, 00986.82 ], Gyr [ 00000.00, -00001.34, -00001.77 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000008535 NoMotion-1. Scaled. Acc [ -00041.99, -00015.62, 00987.79 ], Gyr [ 00001.34, 00001.40, 00000.24 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008536 NoMotion-1. Scaled. Acc [ -00041.99, -00014.16, 00995.61 ], Gyr [ 00000.91, 00004.63, -00002.93 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008537 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00984.38 ], Gyr [ 00003.11, 00002.38, -00001.22 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008538 NoMotion-1. Scaled. Acc [ -00049.32, -00004.88, 00995.61 ], Gyr [ 00001.22, 00000.91, -00002.32 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008539 NoMotion-1. Scaled. Acc [ -00050.78, 00002.44, 00998.54 ], Gyr [ 00001.34, 00002.07, -00001.46 ], Mag [ 00019.95, 00082.80, 00107.85 ]",
    "#0000008540 NoMotion-1. Scaled. Acc [ -00044.92, -00007.81, 00988.28 ], Gyr [ 00002.01, 00001.10, -00000.18 ], Mag [ 00021.30, 00083.55, 00107.85 ]",
    "#0000008541 NoMotion-1. Scaled. Acc [ -00028.81, -00001.46, 00988.28 ], Gyr [ -00000.37, 00000.00, -00003.29 ], Mag [ 00021.30, 00083.55, 00107.85 ]",
    "#0000008542 NoMotion-1. Scaled. Acc [ -00047.85, -00004.39, 00982.91 ], Gyr [ 00001.10, 00001.34, -00001.71 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000008543 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00978.03 ], Gyr [ 00000.30, 00002.07, -00001.77 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000008544 NoMotion-1. Scaled. Acc [ -00061.04, -00008.79, 00996.09 ], Gyr [ 00000.18, 00001.65, -00003.54 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008545 NoMotion-1. Scaled. Acc [ -00047.85, -00001.46, 00993.16 ], Gyr [ 00000.18, 00001.28, -00001.83 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008546 NoMotion-1. Scaled. Acc [ -00036.62, -00017.58, 01004.39 ], Gyr [ -00000.12, 00002.07, -00000.98 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008547 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00981.93 ], Gyr [ -00000.55, 00003.17, -00002.07 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008548 NoMotion-1. Scaled. Acc [ -00048.34, -00002.93, 00989.75 ], Gyr [ 00001.46, 00000.00, -00001.04 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000008549 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 00991.70 ], Gyr [ 00000.91, 00001.52, -00001.28 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000008550 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00995.61 ], Gyr [ 00001.59, 00002.20, -00001.95 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000008551 NoMotion-1. Scaled. Acc [ -00041.99, -00002.44, 00993.65 ], Gyr [ 00001.40, -00001.59, -00000.43 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008552 NoMotion-1. Scaled. Acc [ -00038.57, -00004.39, 00996.58 ], Gyr [ 00002.13, 00001.89, -00002.44 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008553 NoMotion-1. Scaled. Acc [ -00048.34, -00001.95, 00995.12 ], Gyr [ -00000.55, 00001.52, 00000.30 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008554 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00989.26 ], Gyr [ 00002.07, 00002.07, -00000.73 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008555 NoMotion-1. Scaled. Acc [ -00042.97, 00002.93, 00979.98 ], Gyr [ 00000.43, 00001.59, -00001.65 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008556 NoMotion-1. Scaled. Acc [ -00051.27, -00008.79, 00985.84 ], Gyr [ 00000.37, 00002.13, 00000.06 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008557 NoMotion-1. Scaled. Acc [ -00055.18, -00011.72, 00994.14 ], Gyr [ 00001.59, 00001.40, -00002.38 ], Mag [ 00020.40, 00082.20, 00107.40 ]",
    "#0000008558 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00987.79 ], Gyr [ -00000.61, 00001.28, -00001.65 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000008559 NoMotion-1. Scaled. Acc [ -00039.55, -00013.67, 00987.30 ], Gyr [ -00001.22, 00001.28, -00002.93 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000008560 NoMotion-1. Scaled. Acc [ -00053.22, 00002.44, 00987.30 ], Gyr [ -00000.49, 00001.46, -00002.50 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000008561 NoMotion-1. Scaled. Acc [ -00041.99, -00002.93, 00989.26 ], Gyr [ 00001.22, 00002.07, -00000.43 ], Mag [ 00019.50, 00082.80, 00108.90 ]",
    "#0000008562 NoMotion-1. Scaled. Acc [ -00049.80, -00008.79, 00990.23 ], Gyr [ 00000.06, 00001.65, -00004.57 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008563 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00981.93 ], Gyr [ 00000.91, -00000.79, -00003.11 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008564 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00978.52 ], Gyr [ 00001.65, -00001.52, -00000.49 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008565 NoMotion-1. Scaled. Acc [ -00033.69, -00011.23, 00994.63 ], Gyr [ 00001.52, 00000.98, -00002.32 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008566 NoMotion-1. Scaled. Acc [ -00045.90, -00001.95, 00993.16 ], Gyr [ -00000.73, 00001.83, -00002.50 ], Mag [ 00017.70, 00082.80, 00107.10 ]",
    "#0000008567 NoMotion-1. Scaled. Acc [ -00042.48, 00007.81, 00991.21 ], Gyr [ 00001.10, 00002.01, -00000.55 ], Mag [ 00017.70, 00082.80, 00107.10 ]",
    "#0000008568 NoMotion-1. Scaled. Acc [ -00041.99, -00018.07, 00977.54 ], Gyr [ 00002.38, 00001.22, -00001.77 ], Mag [ 00017.70, 00082.80, 00107.10 ]",
    "#0000008569 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00992.19 ], Gyr [ -00001.10, 00001.52, -00002.74 ], Mag [ 00019.35, 00081.90, 00106.65 ]",
    "#0000008570 NoMotion-1. Scaled. Acc [ -00037.60, -00007.32, 00988.28 ], Gyr [ 00001.04, 00004.02, -00002.07 ], Mag [ 00019.35, 00081.90, 00106.65 ]",
    "#0000008571 NoMotion-1. Scaled. Acc [ -00049.80, -00024.90, 00991.70 ], Gyr [ -00000.79, 00000.91, -00000.85 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000008572 NoMotion-1. Scaled. Acc [ -00046.39, -00024.41, 00995.61 ], Gyr [ 00000.67, 00002.87, -00001.46 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000008573 NoMotion-1. Scaled. Acc [ -00043.95, -00009.77, 00992.68 ], Gyr [ -00000.79, -00000.30, -00000.49 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008574 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00985.35 ], Gyr [ 00001.95, 00002.93, -00002.32 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008575 NoMotion-1. Scaled. Acc [ -00039.55, -00004.88, 00989.75 ], Gyr [ 00000.43, 00002.99, -00002.74 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008576 NoMotion-1. Scaled. Acc [ -00051.76, -00001.46, 00995.12 ], Gyr [ 00001.77, 00002.44, -00002.50 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008577 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00987.79 ], Gyr [ 00001.10, 00000.06, -00002.32 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008578 NoMotion-1. Scaled. Acc [ -00034.18, -00013.67, 00985.84 ], Gyr [ -00001.04, 00001.40, -00000.12 ], Mag [ 00019.95, 00082.80, 00107.85 ]",
    "#0000008579 NoMotion-1. Scaled. Acc [ -00046.88, -00014.65, 00988.28 ], Gyr [ -00000.73, 00002.80, -00000.98 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000008580 NoMotion-1. Scaled. Acc [ -00042.97, -00014.16, 00991.70 ], Gyr [ 00000.18, 00000.49, -00002.26 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008581 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00981.93 ], Gyr [ -00000.91, -00000.49, -00001.16 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008582 NoMotion-1. Scaled. Acc [ -00052.25, 00004.39, 00991.21 ], Gyr [ 00000.85, 00000.91, -00002.13 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008583 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00986.82 ], Gyr [ -00001.71, 00002.07, 00000.06 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008584 NoMotion-1. Scaled. Acc [ -00045.41, -00009.28, 00992.19 ], Gyr [ 00000.73, 00002.32, 00000.18 ], Mag [ 00017.55, 00083.70, 00106.65 ]",
    "#0000008585 NoMotion-1. Scaled. Acc [ -00032.71, -00013.67, 00985.84 ], Gyr [ 00001.04, 00004.09, -00000.91 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000008586 NoMotion-1. Scaled. Acc [ -00042.97, -00015.14, 00989.75 ], Gyr [ 00000.49, 00000.43, -00001.89 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000008587 NoMotion-1. Scaled. Acc [ -00046.88, -00001.95, 00995.61 ], Gyr [ 00002.01, 00001.46, -00003.35 ], Mag [ 00020.70, 00085.80, 00107.55 ]",
    "#0000008588 NoMotion-1. Scaled. Acc [ -00041.50, -00023.44, 00986.33 ], Gyr [ 00001.65, 00000.43, -00000.55 ], Mag [ 00020.70, 00085.80, 00107.55 ]",
    "#0000008589 NoMotion-1. Scaled. Acc [ -00042.48, -00011.72, 00987.79 ], Gyr [ 00002.38, -00000.85, -00002.13 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008590 NoMotion-1. Scaled. Acc [ -00045.90, -00020.51, 00995.61 ], Gyr [ -00000.12, 00000.43, -00003.66 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008591 NoMotion-1. Scaled. Acc [ -00052.25, -00008.79, 00993.65 ], Gyr [ -00001.28, 00000.24, -00002.44 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008592 NoMotion-1. Scaled. Acc [ -00035.16, -00005.86, 00991.21 ], Gyr [ 00000.91, -00001.04, -00000.67 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008593 NoMotion-1. Scaled. Acc [ -00038.09, -00017.09, 00986.82 ], Gyr [ 00000.18, 00003.05, -00002.87 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000008594 NoMotion-1. Scaled. Acc [ -00036.13, 00005.37, 00995.12 ], Gyr [ -00000.43, 00002.50, -00000.67 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000008595 NoMotion-1. Scaled. Acc [ -00044.92, -00002.44, 00977.54 ], Gyr [ 00001.04, 00000.37, -00002.99 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000008596 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00996.58 ], Gyr [ -00001.16, 00000.06, -00000.91 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000008597 NoMotion-1. Scaled. Acc [ -00055.18, -00014.16, 00991.21 ], Gyr [ 00001.40, -00000.24, -00001.10 ], Mag [ 00019.65, 00082.95, 00108.45 ]",
    "#0000008598 NoMotion-1. Scaled. Acc [ -00043.46, 00002.44, 00996.09 ], Gyr [ -00001.04, 00000.24, -00003.05 ], Mag [ 00019.65, 00082.95, 00108.45 ]",
    "#0000008599 NoMotion-1. Scaled. Acc [ -00047.36, -00018.55, 00983.40 ], Gyr [ -00000.30, 00000.24, 00004.45 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008600 NoMotion-1. Scaled. Acc [ -00048.83, -00012.21, 00987.30 ], Gyr [ -00000.43, -00001.52, -00002.74 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008601 NoMotion-1. Scaled. Acc [ -00046.88, -00011.23, 00987.79 ], Gyr [ -00001.10, 00003.35, -00001.89 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008602 NoMotion-1. Scaled. Acc [ -00035.16, -00017.09, 00985.35 ], Gyr [ 00000.37, 00002.01, -00001.89 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008603 NoMotion-1. Scaled. Acc [ -00033.20, -00006.35, 00989.26 ], Gyr [ 00002.80, 00000.30, -00002.13 ], Mag [ 00020.25, 00082.05, 00109.35 ]",
    "#0000008604 NoMotion-1. Scaled. Acc [ -00044.92, -00008.79, 00982.42 ], Gyr [ 00001.65, -00001.10, -00000.55 ], Mag [ 00020.70, 00084.75, 00108.60 ]",
    "#0000008605 NoMotion-1. Scaled. Acc [ -00046.39, -00005.37, 00988.28 ], Gyr [ -00002.13, 00001.28, -00003.41 ], Mag [ 00020.70, 00084.75, 00108.60 ]",
    "#0000008606 NoMotion-1. Scaled. Acc [ -00040.04, -00019.04, 00989.75 ], Gyr [ -00000.49, 00002.50, -00001.22 ], Mag [ 00019.65, 00084.15, 00108.75 ]",
    "#0000008607 NoMotion-1. Scaled. Acc [ -00037.11, -00013.18, 00998.05 ], Gyr [ 00000.43, 00001.95, 00000.67 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008608 NoMotion-1. Scaled. Acc [ -00055.18, -00011.23, 00988.77 ], Gyr [ 00000.85, 00000.30, -00000.61 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008609 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 00981.45 ], Gyr [ 00001.83, 00002.93, -00000.67 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008610 NoMotion-1. Scaled. Acc [ -00052.73, -00013.18, 00983.89 ], Gyr [ 00001.34, 00001.46, -00002.26 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008611 NoMotion-1. Scaled. Acc [ -00043.95, -00006.35, 00985.84 ], Gyr [ 00000.79, -00001.04, -00001.52 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008612 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00988.77 ], Gyr [ 00003.05, -00000.55, -00001.28 ], Mag [ 00019.05, 00082.65, 00108.00 ]",
    "#0000008613 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 00992.19 ], Gyr [ 00000.18, 00001.28, -00001.77 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008614 NoMotion-1. Scaled. Acc [ -00048.34, -00008.79, 00990.23 ], Gyr [ 00002.13, 00000.24, -00000.85 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008615 NoMotion-1. Scaled. Acc [ -00041.50, -00006.84, 00987.30 ], Gyr [ 00000.55, 00000.43, 00000.49 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000008616 NoMotion-1. Scaled. Acc [ -00053.22, -00019.04, 00986.82 ], Gyr [ 00000.91, 00000.67, -00000.49 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000008617 NoMotion-1. Scaled. Acc [ -00049.80, 00004.39, 00985.35 ], Gyr [ -00000.06, 00001.10, -00001.52 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008618 NoMotion-1. Scaled. Acc [ -00032.23, -00001.46, 00979.98 ], Gyr [ -00000.85, 00001.22, -00001.65 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008619 NoMotion-1. Scaled. Acc [ -00045.41, -00008.30, 00987.30 ], Gyr [ -00000.61, 00001.04, -00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008620 NoMotion-1. Scaled. Acc [ -00038.57, -00009.77, 00993.16 ], Gyr [ 00000.67, 00001.77, -00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008621 NoMotion-1. Scaled. Acc [ -00055.18, -00012.21, 00977.54 ], Gyr [ 00002.07, 00003.48, 00000.43 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000008622 NoMotion-1. Scaled. Acc [ -00036.62, -00010.74, 00995.12 ], Gyr [ 00000.12, 00001.40, -00003.35 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000008623 NoMotion-1. Scaled. Acc [ -00033.20, -00004.39, 00977.05 ], Gyr [ -00001.89, 00002.62, -00002.07 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008624 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00984.86 ], Gyr [ 00001.10, -00000.12, -00001.28 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000008625 NoMotion-1. Scaled. Acc [ -00041.50, -00007.81, 00992.19 ], Gyr [ 00001.83, 00001.16, -00000.61 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000008626 NoMotion-1. Scaled. Acc [ -00049.80, -00005.86, 00987.79 ], Gyr [ 00001.10, 00000.18, -00000.24 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008627 NoMotion-1. Scaled. Acc [ -00036.62, -00001.95, 00993.65 ], Gyr [ -00001.16, 00002.56, 00000.30 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008628 NoMotion-1. Scaled. Acc [ -00041.99, -00014.65, 00992.68 ], Gyr [ 00001.22, 00000.18, 00000.73 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008629 NoMotion-1. Scaled. Acc [ -00060.55, -00017.09, 00987.30 ], Gyr [ 00001.04, -00000.24, -00001.89 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008630 NoMotion-1. Scaled. Acc [ -00040.53, -00017.58, 00998.05 ], Gyr [ 00000.85, 00002.87, -00001.65 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000008631 NoMotion-1. Scaled. Acc [ -00050.78, -00002.44, 00985.35 ], Gyr [ -00002.01, 00003.66, -00000.24 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000008632 NoMotion-1. Scaled. Acc [ -00041.99, -00007.32, 00993.65 ], Gyr [ -00001.59, 00001.95, -00000.91 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000008633 NoMotion-1. Scaled. Acc [ -00048.34, -00023.44, 00975.59 ], Gyr [ -00001.04, 00002.20, -00001.71 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000008634 NoMotion-1. Scaled. Acc [ -00046.88, -00005.37, 00994.14 ], Gyr [ 00000.73, 00002.07, -00001.77 ], Mag [ 00018.15, 00083.55, 00107.10 ]",
    "#0000008635 NoMotion-1. Scaled. Acc [ -00047.36, -00013.18, 00989.26 ], Gyr [ 00000.00, 00000.61, -00001.40 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008636 NoMotion-1. Scaled. Acc [ -00048.34, -00003.91, 01000.00 ], Gyr [ 00001.46, -00001.10, -00000.85 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008637 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00990.72 ], Gyr [ -00000.43, -00001.65, -00000.85 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008638 NoMotion-1. Scaled. Acc [ -00052.25, -00019.04, 00987.79 ], Gyr [ -00001.40, 00003.72, -00001.89 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008639 NoMotion-1. Scaled. Acc [ -00043.95, -00005.86, 00981.45 ], Gyr [ -00000.37, -00001.89, -00001.71 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008640 NoMotion-1. Scaled. Acc [ -00055.66, 00005.37, 00994.14 ], Gyr [ 00003.60, 00000.00, 00001.40 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000008641 NoMotion-1. Scaled. Acc [ -00038.09, -00014.16, 00994.14 ], Gyr [ -00000.61, 00000.30, -00004.88 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008642 NoMotion-1. Scaled. Acc [ -00040.53, -00015.62, 00983.89 ], Gyr [ -00000.30, 00001.46, -00000.85 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008643 NoMotion-1. Scaled. Acc [ -00053.71, -00011.72, 00991.70 ], Gyr [ 00001.40, 00003.17, -00000.24 ], Mag [ 00019.65, 00082.95, 00107.40 ]",
    "#0000008644 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00990.72 ], Gyr [ -00000.18, 00001.28, 00000.91 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008645 NoMotion-1. Scaled. Acc [ -00033.69, 00008.30, 00990.23 ], Gyr [ -00000.91, 00000.91, -00003.41 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008646 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 01000.49 ], Gyr [ -00000.49, 00002.68, -00001.34 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008647 NoMotion-1. Scaled. Acc [ -00035.16, -00012.70, 00986.33 ], Gyr [ -00000.67, 00003.48, -00002.44 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008648 NoMotion-1. Scaled. Acc [ -00044.43, -00008.30, 00990.23 ], Gyr [ 00000.49, 00002.50, -00001.77 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000008649 NoMotion-1. Scaled. Acc [ -00041.99, -00007.81, 00987.30 ], Gyr [ 00002.56, 00001.34, 00000.61 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000008650 NoMotion-1. Scaled. Acc [ -00036.13, -00001.46, 00992.68 ], Gyr [ -00000.55, 00001.04, -00003.78 ], Mag [ 00018.75, 00082.50, 00107.55 ]",
    "#0000008651 NoMotion-1. Scaled. Acc [ -00041.50, 00002.44, 00992.68 ], Gyr [ 00001.46, 00002.56, 00001.52 ], Mag [ 00018.75, 00082.50, 00107.55 ]",
    "#0000008652 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00988.28 ], Gyr [ -00000.55, 00000.73, 00000.18 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008653 NoMotion-1. Scaled. Acc [ -00051.27, -00001.46, 00983.89 ], Gyr [ 00000.79, 00003.84, -00001.71 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008654 NoMotion-1. Scaled. Acc [ -00056.64, -00000.98, 00988.77 ], Gyr [ 00001.34, 00000.73, -00002.68 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008655 NoMotion-1. Scaled. Acc [ -00060.55, -00008.79, 00986.82 ], Gyr [ -00000.73, 00002.56, -00003.41 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008656 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00994.63 ], Gyr [ 00002.62, -00000.30, -00002.32 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008657 NoMotion-1. Scaled. Acc [ -00043.95, -00015.62, 00998.05 ], Gyr [ -00001.10, 00000.24, -00002.50 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008658 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00981.93 ], Gyr [ 00000.06, 00002.62, 00000.24 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008659 NoMotion-1. Scaled. Acc [ -00043.46, -00005.37, 00994.14 ], Gyr [ 00000.00, 00003.96, -00001.77 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008660 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00990.72 ], Gyr [ -00000.43, 00002.44, -00003.84 ], Mag [ 00020.10, 00082.65, 00106.95 ]",
    "#0000008661 NoMotion-1. Scaled. Acc [ -00052.73, -00010.25, 00987.79 ], Gyr [ 00002.38, 00002.13, 00000.73 ], Mag [ 00020.10, 00082.65, 00106.95 ]",
    "#0000008662 NoMotion-1. Scaled. Acc [ -00043.95, 00001.95, 00997.07 ], Gyr [ 00000.12, 00001.71, 00001.16 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000008663 NoMotion-1. Scaled. Acc [ -00051.27, -00008.79, 00991.21 ], Gyr [ 00001.65, 00002.99, -00000.55 ], Mag [ 00019.20, 00083.55, 00108.30 ]",
    "#0000006374 NoMotion-1. Scaled. Acc [ -00050.29, -00005.37, 00999.02 ], Gyr [ 00000.12, -00002.44, -00002.50 ], Mag [ 00019.65, 00083.40, 00108.45 ]",
    "#0000006375 NoMotion-1. Scaled. Acc [ -00033.20, -00005.86, 00982.42 ], Gyr [ 00002.68, 00000.79, -00001.28 ], Mag [ 00019.65, 00083.40, 00108.45 ]",
    "#0000006376 NoMotion-1. Scaled. Acc [ -00037.60, 00000.49, 00976.07 ], Gyr [ -00000.24, 00000.73, -00000.18 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006377 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00997.07 ], Gyr [ -00000.43, 00001.40, -00002.32 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006378 NoMotion-1. Scaled. Acc [ -00047.36, -00003.91, 00987.79 ], Gyr [ 00000.49, 00001.59, 00000.12 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000006379 NoMotion-1. Scaled. Acc [ -00034.18, -00001.46, 00984.38 ], Gyr [ 00001.65, 00002.32, 00000.67 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000006380 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00988.77 ], Gyr [ -00000.06, 00003.78, -00002.07 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006381 NoMotion-1. Scaled. Acc [ -00039.06, 00004.39, 01001.95 ], Gyr [ 00000.49, 00001.16, -00002.13 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006382 NoMotion-1. Scaled. Acc [ -00052.73, -00009.28, 00992.68 ], Gyr [ 00000.37, 00000.98, -00001.10 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006383 NoMotion-1. Scaled. Acc [ -00039.55, -00012.70, 00983.40 ], Gyr [ 00001.28, 00000.37, -00002.74 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006384 NoMotion-1. Scaled. Acc [ -00050.78, -00008.30, 00990.72 ], Gyr [ 00000.43, 00001.34, -00002.38 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006385 NoMotion-1. Scaled. Acc [ -00052.25, 00008.30, 01009.28 ], Gyr [ 00001.16, -00001.71, -00002.01 ], Mag [ 00019.95, 00084.30, 00108.90 ]",
    "#0000006386 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00998.54 ], Gyr [ 00000.30, -00000.30, -00000.37 ], Mag [ 00019.95, 00084.30, 00108.90 ]",
    "#0000006387 NoMotion-1. Scaled. Acc [ -00041.50, -00017.09, 00988.28 ], Gyr [ 00001.46, 00000.91, -00000.91 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000006388 NoMotion-1. Scaled. Acc [ -00038.09, -00003.42, 00997.07 ], Gyr [ -00000.79, 00001.71, 00000.61 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000006389 NoMotion-1. Scaled. Acc [ -00030.76, -00013.67, 00990.23 ], Gyr [ 00000.61, 00000.67, -00000.73 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006390 NoMotion-1. Scaled. Acc [ -00038.57, -00003.42, 00988.77 ], Gyr [ -00000.18, 00002.50, 00000.24 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006391 NoMotion-1. Scaled. Acc [ -00037.11, -00020.02, 00992.19 ], Gyr [ 00000.12, 00001.04, -00000.91 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006392 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 00979.00 ], Gyr [ -00000.37, 00004.02, -00000.37 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006393 NoMotion-1. Scaled. Acc [ -00036.62, -00012.21, 00997.56 ], Gyr [ -00001.95, 00001.77, -00000.91 ], Mag [ 00020.40, 00084.90, 00106.20 ]",
    "#0000006394 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00996.58 ], Gyr [ 00001.04, -00000.43, -00001.89 ], Mag [ 00020.40, 00084.90, 00106.20 ]",
    "#0000006395 NoMotion-1. Scaled. Acc [ -00037.60, -00019.04, 00991.21 ], Gyr [ -00000.55, 00001.52, -00003.84 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000006396 NoMotion-1. Scaled. Acc [ -00036.62, -00011.72, 00996.58 ], Gyr [ 00000.24, 00002.93, -00002.01 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000006397 NoMotion-1. Scaled. Acc [ -00039.06, 00000.00, 00993.65 ], Gyr [ 00001.95, 00000.55, -00002.44 ], Mag [ 00020.40, 00084.90, 00108.75 ]",
    "#0000006398 NoMotion-1. Scaled. Acc [ -00038.09, -00011.72, 00984.86 ], Gyr [ 00001.52, 00000.00, -00001.65 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006399 NoMotion-1. Scaled. Acc [ -00038.09, -00016.60, 00984.38 ], Gyr [ 00000.73, 00002.68, -00003.17 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006400 NoMotion-1. Scaled. Acc [ -00041.50, -00021.97, 00990.23 ], Gyr [ -00001.28, 00000.67, 00000.37 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006401 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00981.93 ], Gyr [ 00000.18, 00004.39, -00000.98 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006402 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00995.61 ], Gyr [ 00001.04, 00001.59, -00001.89 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006403 NoMotion-1. Scaled. Acc [ -00039.55, 00000.49, 00991.21 ], Gyr [ 00001.04, -00000.85, 00000.79 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006404 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00989.26 ], Gyr [ -00000.67, 00001.16, -00002.07 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006405 NoMotion-1. Scaled. Acc [ -00036.13, 00000.98, 00981.45 ], Gyr [ 00000.18, 00000.67, -00001.71 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006406 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00989.26 ], Gyr [ -00001.34, 00003.11, -00003.11 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006407 NoMotion-1. Scaled. Acc [ -00036.62, -00003.91, 00984.38 ], Gyr [ 00002.68, -00000.24, -00001.34 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006408 NoMotion-1. Scaled. Acc [ -00026.37, -00011.72, 00993.16 ], Gyr [ 00001.40, 00002.50, -00003.60 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006409 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00986.82 ], Gyr [ 00000.49, 00000.98, -00000.73 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006410 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 01000.49 ], Gyr [ 00001.52, 00000.43, 00000.18 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006411 NoMotion-1. Scaled. Acc [ -00037.11, -00002.93, 00998.05 ], Gyr [ 00000.85, -00000.24, -00001.34 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006412 NoMotion-1. Scaled. Acc [ -00042.48, -00006.84, 00989.26 ], Gyr [ 00003.35, 00002.26, -00004.39 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006413 NoMotion-1. Scaled. Acc [ -00038.09, -00009.28, 00982.42 ], Gyr [ 00001.77, 00000.30, -00001.22 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006414 NoMotion-1. Scaled. Acc [ -00030.76, -00002.93, 00996.58 ], Gyr [ 00000.37, 00000.49, -00001.40 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006415 NoMotion-1. Scaled. Acc [ -00038.09, -00024.90, 00986.82 ], Gyr [ 00002.01, 00000.00, -00000.79 ], Mag [ 00018.60, 00085.20, 00109.05 ]",
    "#0000006416 NoMotion-1. Scaled. Acc [ -00038.57, -00012.21, 01000.00 ], Gyr [ 00001.10, 00000.85, -00002.01 ], Mag [ 00018.60, 00085.20, 00109.05 ]",
    "#0000006417 NoMotion-1. Scaled. Acc [ -00036.13, -00014.16, 00985.35 ], Gyr [ 00001.71, 00003.78, -00002.13 ], Mag [ 00018.60, 00083.40, 00108.45 ]",
    "#0000006418 NoMotion-1. Scaled. Acc [ -00026.37, 00000.00, 00979.49 ], Gyr [ 00001.28, -00001.40, -00000.85 ], Mag [ 00018.60, 00083.40, 00108.45 ]",
    "#0000006419 NoMotion-1. Scaled. Acc [ -00039.06, -00011.23, 00992.68 ], Gyr [ 00001.10, 00000.49, -00002.07 ], Mag [ 00019.05, 00082.95, 00108.45 ]",
    "#0000006420 NoMotion-1. Scaled. Acc [ -00039.55, -00004.39, 00994.14 ], Gyr [ 00000.30, 00000.79, -00002.74 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006421 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00997.07 ], Gyr [ -00001.28, 00003.48, -00002.50 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006422 NoMotion-1. Scaled. Acc [ -00034.18, -00019.53, 00996.58 ], Gyr [ 00002.26, -00000.37, -00001.59 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006423 NoMotion-1. Scaled. Acc [ -00027.83, 00000.49, 00979.98 ], Gyr [ 00002.26, -00000.55, -00000.67 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000006424 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00991.21 ], Gyr [ 00002.50, 00001.52, -00000.30 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000006425 NoMotion-1. Scaled. Acc [ -00055.18, -00014.16, 01007.32 ], Gyr [ 00001.71, 00001.04, -00001.16 ], Mag [ 00019.95, 00084.75, 00106.50 ]",
    "#0000006426 NoMotion-1. Scaled. Acc [ -00050.29, -00010.25, 00988.28 ], Gyr [ 00001.71, 00000.18, -00000.43 ], Mag [ 00019.95, 00084.75, 00106.50 ]",
    "#0000006427 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00988.28 ], Gyr [ 00002.07, 00000.67, 00000.18 ], Mag [ 00020.70, 00082.05, 00109.65 ]",
    "#0000006428 NoMotion-1. Scaled. Acc [ -00044.43, -00010.74, 00990.72 ], Gyr [ -00000.85, 00000.43, -00000.91 ], Mag [ 00020.70, 00082.05, 00109.65 ]",
    "#0000006429 NoMotion-1. Scaled. Acc [ -00045.41, -00011.72, 00992.68 ], Gyr [ 00001.28, 00003.72, -00003.48 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006430 NoMotion-1. Scaled. Acc [ -00046.88, -00010.25, 00992.19 ], Gyr [ -00000.91, 00002.74, -00001.83 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006431 NoMotion-1. Scaled. Acc [ -00049.32, -00001.46, 00992.68 ], Gyr [ 00001.52, 00002.80, -00001.46 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006432 NoMotion-1. Scaled. Acc [ -00053.71, -00005.86, 00984.86 ], Gyr [ 00001.83, 00002.38, -00002.74 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006433 NoMotion-1. Scaled. Acc [ -00045.41, -00013.18, 00989.75 ], Gyr [ 00001.89, 00002.56, -00003.11 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006434 NoMotion-1. Scaled. Acc [ -00045.41, -00005.86, 00995.61 ], Gyr [ 00000.30, -00002.68, 00000.67 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006435 NoMotion-1. Scaled. Acc [ -00047.85, -00003.91, 00995.12 ], Gyr [ 00002.20, -00000.37, -00001.34 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000006436 NoMotion-1. Scaled. Acc [ -00048.83, -00014.16, 00979.49 ], Gyr [ 00000.98, 00001.10, -00002.26 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000006437 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 01006.84 ], Gyr [ 00001.83, 00001.59, -00002.93 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000006438 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00979.49 ], Gyr [ 00001.77, 00000.91, 00001.65 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000006439 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00988.77 ], Gyr [ 00000.18, 00000.79, 00001.28 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006440 NoMotion-1. Scaled. Acc [ -00048.83, -00009.28, 00987.30 ], Gyr [ 00001.46, -00000.55, 00000.24 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006441 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00994.63 ], Gyr [ -00000.67, 00001.10, -00002.62 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006442 NoMotion-1. Scaled. Acc [ -00038.57, -00012.21, 00998.05 ], Gyr [ -00001.28, 00001.04, -00002.74 ], Mag [ 00020.10, 00084.90, 00108.00 ]",
    "#0000006443 NoMotion-1. Scaled. Acc [ -00029.79, -00009.28, 00986.82 ], Gyr [ -00000.30, 00003.23, -00002.68 ], Mag [ 00020.10, 00084.90, 00108.00 ]",
    "#0000006444 NoMotion-1. Scaled. Acc [ -00043.46, -00010.25, 00986.82 ], Gyr [ 00001.83, -00002.07, -00001.95 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006445 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00992.68 ], Gyr [ 00000.61, 00001.16, -00001.59 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006446 NoMotion-1. Scaled. Acc [ -00048.83, -00002.93, 01004.39 ], Gyr [ 00002.62, -00000.85, 00001.34 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006447 NoMotion-1. Scaled. Acc [ -00044.92, -00011.72, 01003.42 ], Gyr [ 00000.91, 00000.00, -00002.93 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006448 NoMotion-1. Scaled. Acc [ -00046.39, -00010.74, 00989.26 ], Gyr [ 00002.01, 00001.40, -00002.13 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000006449 NoMotion-1. Scaled. Acc [ -00041.99, 00001.46, 00988.77 ], Gyr [ 00000.91, -00000.37, -00001.83 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000006450 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00995.12 ], Gyr [ 00002.07, 00001.28, -00002.38 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000006451 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00989.26 ], Gyr [ 00000.55, 00002.62, -00001.71 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000006452 NoMotion-1. Scaled. Acc [ -00043.95, 00001.46, 00983.40 ], Gyr [ -00000.91, 00001.83, -00002.68 ], Mag [ 00020.85, 00084.45, 00108.75 ]",
    "#0000006453 NoMotion-1. Scaled. Acc [ -00043.46, -00004.39, 01000.98 ], Gyr [ 00002.50, 00001.46, -00002.44 ], Mag [ 00020.85, 00084.45, 00108.75 ]",
    "#0000006454 NoMotion-1. Scaled. Acc [ -00042.48, 00000.98, 00997.56 ], Gyr [ 00000.24, 00002.13, -00004.09 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006455 NoMotion-1. Scaled. Acc [ -00051.76, -00007.81, 00979.00 ], Gyr [ 00000.12, 00003.90, -00004.57 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006456 NoMotion-1. Scaled. Acc [ -00048.83, -00020.51, 00999.51 ], Gyr [ 00001.95, 00002.13, -00002.20 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006457 NoMotion-1. Scaled. Acc [ -00039.06, -00007.81, 00987.30 ], Gyr [ 00002.44, -00001.46, -00002.74 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006458 NoMotion-1. Scaled. Acc [ -00034.18, -00015.62, 00999.02 ], Gyr [ 00000.24, 00001.04, -00003.23 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006459 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00983.89 ], Gyr [ -00001.16, 00003.84, 00000.49 ], Mag [ 00018.75, 00083.55, 00107.10 ]",
    "#0000006460 NoMotion-1. Scaled. Acc [ -00055.66, -00011.23, 00994.63 ], Gyr [ 00002.01, 00001.83, -00000.91 ], Mag [ 00018.75, 00083.55, 00107.10 ]",
    "#0000006461 NoMotion-1. Scaled. Acc [ -00047.36, -00001.95, 00996.09 ], Gyr [ 00002.26, -00001.65, -00000.18 ], Mag [ 00019.05, 00084.15, 00105.90 ]",
    "#0000006462 NoMotion-1. Scaled. Acc [ -00054.69, -00005.86, 00998.54 ], Gyr [ 00002.26, 00001.77, 00000.24 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006463 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00992.19 ], Gyr [ 00001.22, 00002.13, -00001.04 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006464 NoMotion-1. Scaled. Acc [ -00041.50, -00013.18, 00988.77 ], Gyr [ 00000.06, 00002.74, -00003.72 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006465 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 01007.32 ], Gyr [ -00000.73, -00000.37, -00002.01 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006466 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00989.75 ], Gyr [ 00000.61, 00003.23, -00002.80 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006467 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00989.26 ], Gyr [ -00000.18, 00001.34, -00000.85 ], Mag [ 00021.15, 00084.45, 00106.20 ]",
    "#0000006468 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00999.51 ], Gyr [ 00001.46, 00002.38, -00000.49 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006469 NoMotion-1. Scaled. Acc [ -00033.69, -00006.35, 00988.77 ], Gyr [ 00002.01, 00000.49, -00001.16 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006470 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 01000.00 ], Gyr [ 00000.73, 00002.38, -00002.44 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006471 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 00994.63 ], Gyr [ 00000.79, 00000.85, -00003.72 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006472 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00988.77 ], Gyr [ 00000.73, 00000.49, 00000.61 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006473 NoMotion-1. Scaled. Acc [ -00039.06, -00007.81, 00980.47 ], Gyr [ 00001.34, 00003.96, -00002.20 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006474 NoMotion-1. Scaled. Acc [ -00043.95, 00004.39, 00998.54 ], Gyr [ -00000.37, 00001.59, -00000.30 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006475 NoMotion-1. Scaled. Acc [ -00038.57, -00010.74, 00994.14 ], Gyr [ -00001.10, -00000.30, -00003.05 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000006476 NoMotion-1. Scaled. Acc [ -00057.13, -00013.18, 01004.88 ], Gyr [ -00000.30, -00000.12, -00002.62 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000006477 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00998.54 ], Gyr [ -00001.04, 00002.07, -00001.10 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000006478 NoMotion-1. Scaled. Acc [ -00053.22, -00009.28, 00992.19 ], Gyr [ -00001.71, 00000.67, -00003.90 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000006479 NoMotion-1. Scaled. Acc [ -00040.53, 00003.42, 00995.61 ], Gyr [ 00000.91, 00003.48, -00001.77 ], Mag [ 00018.45, 00084.75, 00107.85 ]",
    "#0000006480 NoMotion-1. Scaled. Acc [ -00035.64, -00003.42, 00998.05 ], Gyr [ -00001.59, 00001.59, -00002.07 ], Mag [ 00018.45, 00084.75, 00107.85 ]",
    "#0000006481 NoMotion-1. Scaled. Acc [ -00047.85, -00023.93, 00991.21 ], Gyr [ 00000.55, 00002.26, -00000.73 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006482 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00996.09 ], Gyr [ -00000.55, 00001.83, -00000.18 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006483 NoMotion-1. Scaled. Acc [ -00048.34, -00001.46, 00984.38 ], Gyr [ -00001.22, 00003.35, -00002.68 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006484 NoMotion-1. Scaled. Acc [ -00039.55, -00015.62, 00994.63 ], Gyr [ 00001.10, -00000.37, -00001.77 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006485 NoMotion-1. Scaled. Acc [ -00041.50, -00018.55, 00989.26 ], Gyr [ -00000.24, 00001.77, 00000.24 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000006486 NoMotion-1. Scaled. Acc [ -00059.57, -00010.25, 00998.54 ], Gyr [ 00000.79, 00000.06, -00001.16 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000006487 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00982.42 ], Gyr [ 00000.79, 00000.73, -00002.68 ], Mag [ 00019.35, 00084.45, 00108.75 ]",
    "#0000006488 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00995.12 ], Gyr [ 00001.89, -00000.49, -00002.26 ], Mag [ 00019.35, 00084.45, 00108.75 ]",
    "#0000006489 NoMotion-1. Scaled. Acc [ -00047.85, -00015.14, 00988.77 ], Gyr [ 00001.83, 00000.49, -00000.67 ], Mag [ 00020.40, 00083.70, 00106.20 ]",
    "#0000006490 NoMotion-1. Scaled. Acc [ -00047.85, 00000.49, 00994.63 ], Gyr [ 00000.37, 00002.38, -00002.87 ], Mag [ 00020.40, 00083.70, 00106.20 ]",
    "#0000006491 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00991.21 ], Gyr [ 00000.79, 00002.07, -00002.01 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006492 NoMotion-1. Scaled. Acc [ -00029.30, 00005.37, 00989.75 ], Gyr [ -00000.49, 00000.85, -00000.24 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006493 NoMotion-1. Scaled. Acc [ -00050.78, -00000.98, 00996.09 ], Gyr [ 00001.89, 00000.98, -00003.05 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006494 NoMotion-1. Scaled. Acc [ -00043.46, -00017.09, 01001.46 ], Gyr [ 00001.65, 00001.40, 00000.24 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006495 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00991.70 ], Gyr [ -00001.40, 00001.83, 00000.67 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000006496 NoMotion-1. Scaled. Acc [ -00047.36, -00007.32, 00979.98 ], Gyr [ 00002.80, 00004.15, 00000.43 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000006497 NoMotion-1. Scaled. Acc [ -00043.46, -00007.32, 00992.68 ], Gyr [ 00000.49, 00000.91, -00002.32 ], Mag [ 00021.60, 00084.45, 00108.00 ]",
    "#0000006498 NoMotion-1. Scaled. Acc [ -00048.34, 00000.49, 00986.33 ], Gyr [ 00001.95, 00001.71, -00001.65 ], Mag [ 00021.60, 00084.45, 00108.00 ]",
    "#0000006499 NoMotion-1. Scaled. Acc [ -00037.11, -00005.37, 00993.65 ], Gyr [ 00000.55, 00001.95, 00001.52 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006500 NoMotion-1. Scaled. Acc [ -00032.23, -00016.11, 00990.72 ], Gyr [ 00001.71, 00000.98, -00001.40 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006501 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00991.70 ], Gyr [ 00001.28, -00000.79, -00000.79 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006502 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00989.75 ], Gyr [ -00001.52, 00003.35, -00002.87 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006503 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00990.72 ], Gyr [ -00000.79, 00003.29, -00002.20 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000006504 NoMotion-1. Scaled. Acc [ -00047.36, -00010.25, 00993.65 ], Gyr [ -00000.12, 00001.04, -00002.99 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000006505 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00985.84 ], Gyr [ 00000.37, 00001.83, -00001.59 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000006506 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00996.58 ], Gyr [ 00001.59, 00002.93, -00002.50 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000006507 NoMotion-1. Scaled. Acc [ -00037.60, -00005.37, 00981.93 ], Gyr [ 00000.24, 00001.34, -00001.65 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000006508 NoMotion-1. Scaled. Acc [ -00049.32, -00002.93, 00996.09 ], Gyr [ -00000.73, 00000.73, -00003.11 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000006509 NoMotion-1. Scaled. Acc [ -00040.53, -00000.98, 00989.75 ], Gyr [ 00000.55, 00001.77, -00000.43 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006510 NoMotion-1. Scaled. Acc [ -00033.20, -00012.21, 01006.84 ], Gyr [ -00000.55, -00002.20, -00001.95 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006511 NoMotion-1. Scaled. Acc [ -00031.25, -00001.95, 00984.86 ], Gyr [ -00001.83, 00001.71, 00000.24 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006512 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00990.23 ], Gyr [ -00001.22, 00000.98, -00001.83 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006513 NoMotion-1. Scaled. Acc [ -00035.64, -00001.46, 00989.75 ], Gyr [ 00000.00, 00002.38, -00000.79 ], Mag [ 00019.35, 00084.15, 00106.65 ]",
    "#0000006514 NoMotion-1. Scaled. Acc [ -00054.20, -00008.30, 00991.70 ], Gyr [ 00002.80, 00003.66, -00000.85 ], Mag [ 00019.95, 00084.30, 00107.55 ]",
    "#0000006515 NoMotion-1. Scaled. Acc [ -00031.74, -00007.32, 00984.86 ], Gyr [ 00000.67, 00000.18, -00001.22 ], Mag [ 00019.95, 00084.30, 00107.55 ]",
    "#0000006516 NoMotion-1. Scaled. Acc [ -00045.41, 00002.93, 00986.82 ], Gyr [ 00001.22, 00000.91, -00001.10 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000006517 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00997.56 ], Gyr [ -00000.06, -00000.12, 00000.67 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006518 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00989.26 ], Gyr [ 00001.71, 00001.71, 00000.00 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006519 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00998.54 ], Gyr [ -00001.16, 00001.95, -00003.41 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006520 NoMotion-1. Scaled. Acc [ -00041.99, 00001.95, 00990.23 ], Gyr [ -00000.73, 00000.06, -00001.28 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006521 NoMotion-1. Scaled. Acc [ -00034.67, -00008.30, 00975.59 ], Gyr [ 00001.89, 00000.49, 00000.85 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006522 NoMotion-1. Scaled. Acc [ -00044.43, -00008.79, 00985.35 ], Gyr [ 00000.24, 00001.22, -00003.05 ], Mag [ 00019.50, 00082.05, 00107.10 ]",
    "#0000006523 NoMotion-1. Scaled. Acc [ -00044.92, -00007.32, 00994.63 ], Gyr [ 00000.00, 00003.29, -00004.27 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006524 NoMotion-1. Scaled. Acc [ -00031.74, -00006.84, 00993.65 ], Gyr [ 00002.26, 00001.59, -00000.18 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006525 NoMotion-1. Scaled. Acc [ -00043.46, -00016.11, 00982.91 ], Gyr [ 00000.98, -00000.43, -00002.20 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000006526 NoMotion-1. Scaled. Acc [ -00052.25, -00007.81, 00997.07 ], Gyr [ -00000.55, -00000.55, -00001.22 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000006527 NoMotion-1. Scaled. Acc [ -00056.64, 00000.49, 00995.61 ], Gyr [ 00000.67, 00002.01, 00000.79 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006528 NoMotion-1. Scaled. Acc [ -00043.46, -00016.11, 00993.65 ], Gyr [ 00000.18, 00002.80, -00002.32 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006529 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00994.63 ], Gyr [ -00000.55, 00000.67, -00000.49 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006530 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00987.30 ], Gyr [ 00000.67, 00000.43, -00000.98 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006531 NoMotion-1. Scaled. Acc [ -00030.76, -00003.42, 00993.65 ], Gyr [ -00000.06, 00002.26, -00000.85 ], Mag [ 00019.95, 00083.25, 00105.75 ]",
    "#0000006532 NoMotion-1. Scaled. Acc [ -00046.88, 00002.93, 00980.47 ], Gyr [ 00001.04, -00000.37, -00002.38 ], Mag [ 00019.95, 00083.25, 00105.75 ]",
    "#0000006533 NoMotion-1. Scaled. Acc [ -00048.83, -00006.84, 00988.77 ], Gyr [ 00001.10, 00001.65, -00001.28 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006534 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00991.70 ], Gyr [ -00000.49, 00000.73, -00002.13 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006535 NoMotion-1. Scaled. Acc [ -00065.92, 00005.37, 00985.84 ], Gyr [ 00001.83, 00001.65, -00000.06 ], Mag [ 00020.40, 00083.70, 00108.75 ]",
    "#0000006536 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 00995.12 ], Gyr [ 00002.80, 00000.79, -00000.61 ], Mag [ 00020.40, 00083.70, 00108.75 ]",
    "#0000006537 NoMotion-1. Scaled. Acc [ -00044.43, 00000.98, 00991.21 ], Gyr [ 00000.67, 00000.73, 00000.67 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006538 NoMotion-1. Scaled. Acc [ -00048.34, 00005.86, 00990.72 ], Gyr [ 00001.34, 00002.56, 00000.91 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006539 NoMotion-1. Scaled. Acc [ -00044.92, -00005.86, 01000.98 ], Gyr [ 00000.98, 00002.56, -00002.26 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006540 NoMotion-1. Scaled. Acc [ -00044.92, -00018.07, 00987.79 ], Gyr [ 00000.43, 00003.17, -00002.80 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000006541 NoMotion-1. Scaled. Acc [ -00044.92, -00005.37, 00990.23 ], Gyr [ 00001.40, -00001.04, -00001.34 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000006542 NoMotion-1. Scaled. Acc [ -00043.95, -00000.98, 00996.09 ], Gyr [ 00000.37, 00000.06, -00002.93 ], Mag [ 00019.05, 00083.40, 00106.65 ]",
    "#0000006543 NoMotion-1. Scaled. Acc [ -00048.34, -00002.44, 00992.68 ], Gyr [ 00001.16, -00000.18, -00000.12 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006544 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00984.86 ], Gyr [ 00001.46, 00000.98, -00000.67 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006545 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00997.07 ], Gyr [ 00000.30, 00001.04, -00002.07 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006546 NoMotion-1. Scaled. Acc [ -00052.25, -00020.02, 00988.77 ], Gyr [ 00000.37, 00003.29, -00002.38 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006547 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00989.26 ], Gyr [ 00000.67, 00003.05, -00001.89 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006548 NoMotion-1. Scaled. Acc [ -00052.25, 00008.30, 00984.86 ], Gyr [ 00001.10, 00002.01, -00001.71 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006549 NoMotion-1. Scaled. Acc [ -00042.97, -00000.98, 00988.28 ], Gyr [ 00001.40, 00003.60, -00002.62 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006550 NoMotion-1. Scaled. Acc [ -00057.62, -00003.91, 00987.79 ], Gyr [ 00001.59, -00000.55, -00002.68 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006551 NoMotion-1. Scaled. Acc [ -00042.48, -00015.14, 00985.35 ], Gyr [ 00000.49, 00000.73, -00003.48 ], Mag [ 00021.15, 00083.70, 00106.65 ]",
    "#0000006552 NoMotion-1. Scaled. Acc [ -00038.57, -00009.28, 00991.21 ], Gyr [ 00000.55, 00002.07, -00001.71 ], Mag [ 00019.35, 00084.45, 00107.40 ]",
    "#0000006553 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00983.89 ], Gyr [ 00000.49, 00002.44, 00000.30 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006554 NoMotion-1. Scaled. Acc [ -00061.52, -00019.04, 01000.00 ], Gyr [ -00000.91, 00001.65, -00004.45 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006555 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00987.79 ], Gyr [ 00000.37, 00003.84, 00001.59 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006556 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 01001.46 ], Gyr [ 00002.26, 00003.54, -00001.89 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006557 NoMotion-1. Scaled. Acc [ -00034.67, -00005.86, 00990.23 ], Gyr [ 00000.61, -00001.59, -00001.95 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000006558 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00990.23 ], Gyr [ 00000.55, 00001.28, -00002.74 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000006559 NoMotion-1. Scaled. Acc [ -00040.04, -00012.70, 00995.12 ], Gyr [ 00001.52, 00001.89, 00000.79 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006560 NoMotion-1. Scaled. Acc [ -00048.83, -00010.25, 00987.30 ], Gyr [ 00001.16, 00002.80, 00000.12 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006561 NoMotion-1. Scaled. Acc [ -00052.73, -00007.32, 00991.70 ], Gyr [ 00001.46, -00001.16, -00004.39 ], Mag [ 00019.50, 00084.75, 00108.90 ]",
    "#0000006562 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00993.65 ], Gyr [ -00000.49, 00000.55, 00000.00 ], Mag [ 00019.50, 00084.75, 00108.90 ]",
    "#0000006563 NoMotion-1. Scaled. Acc [ -00049.32, -00020.02, 00993.16 ], Gyr [ -00000.49, 00001.34, -00002.56 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006564 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00991.21 ], Gyr [ 00001.52, -00000.12, 00000.79 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006565 NoMotion-1. Scaled. Acc [ -00043.46, -00003.91, 00991.21 ], Gyr [ 00000.43, 00002.13, -00002.01 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006566 NoMotion-1. Scaled. Acc [ -00039.06, -00010.74, 00986.82 ], Gyr [ 00000.00, 00004.09, -00000.98 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000006567 NoMotion-1. Scaled. Acc [ -00051.27, -00002.44, 00992.19 ], Gyr [ 00001.28, -00000.37, -00001.46 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000006568 NoMotion-1. Scaled. Acc [ -00039.06, -00003.42, 00977.54 ], Gyr [ 00000.43, 00000.61, -00003.54 ], Mag [ 00018.60, 00082.65, 00109.50 ]",
    "#0000006569 NoMotion-1. Scaled. Acc [ -00032.23, -00009.77, 00982.91 ], Gyr [ -00000.18, 00000.73, -00002.68 ], Mag [ 00018.60, 00082.65, 00109.50 ]",
    "#0000006570 NoMotion-1. Scaled. Acc [ -00042.97, -00021.00, 01004.39 ], Gyr [ -00000.24, 00001.04, -00002.01 ], Mag [ 00019.05, 00084.90, 00108.00 ]",
    "#0000006571 NoMotion-1. Scaled. Acc [ -00042.97, -00003.42, 01001.95 ], Gyr [ 00000.37, -00000.61, -00000.49 ], Mag [ 00019.05, 00084.90, 00108.00 ]",
    "#0000006572 NoMotion-1. Scaled. Acc [ -00035.64, 00009.28, 00991.21 ], Gyr [ 00001.10, 00003.84, -00000.24 ], Mag [ 00020.85, 00081.90, 00107.40 ]",
    "#0000006573 NoMotion-1. Scaled. Acc [ -00043.46, -00017.58, 00993.16 ], Gyr [ 00000.49, 00002.07, -00000.98 ], Mag [ 00020.85, 00081.90, 00107.40 ]",
    "#0000006574 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00988.77 ], Gyr [ 00000.67, 00002.13, -00003.23 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000006575 NoMotion-1. Scaled. Acc [ -00039.06, -00000.98, 00980.47 ], Gyr [ 00000.06, 00003.05, -00002.74 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000006576 NoMotion-1. Scaled. Acc [ -00051.27, -00006.84, 00982.91 ], Gyr [ 00000.37, -00000.79, -00000.12 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000006577 NoMotion-1. Scaled. Acc [ -00039.55, 00003.42, 00992.68 ], Gyr [ 00000.67, 00004.70, -00001.40 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000006578 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00992.68 ], Gyr [ -00001.95, 00001.83, -00002.44 ], Mag [ 00019.05, 00084.90, 00106.95 ]",
    "#0000006579 NoMotion-1. Scaled. Acc [ -00039.06, -00009.77, 00998.54 ], Gyr [ 00000.37, 00003.05, -00002.87 ], Mag [ 00019.05, 00084.90, 00106.95 ]",
    "#0000006580 NoMotion-1. Scaled. Acc [ -00041.99, 00002.44, 00972.66 ], Gyr [ 00000.79, 00000.79, -00003.29 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006581 NoMotion-1. Scaled. Acc [ -00051.76, -00009.28, 00988.28 ], Gyr [ 00000.30, 00001.22, 00001.16 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006582 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00984.86 ], Gyr [ 00002.26, 00003.29, 00000.43 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006583 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00976.56 ], Gyr [ 00000.73, 00003.66, 00000.30 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006584 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00990.72 ], Gyr [ 00001.52, 00001.71, -00000.91 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006585 NoMotion-1. Scaled. Acc [ -00041.02, -00002.44, 00993.65 ], Gyr [ 00000.30, 00002.93, -00001.10 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006586 NoMotion-1. Scaled. Acc [ -00030.27, 00005.37, 00993.16 ], Gyr [ 00001.10, 00002.50, -00002.44 ], Mag [ 00019.65, 00083.40, 00106.20 ]",
    "#0000006587 NoMotion-1. Scaled. Acc [ -00050.29, 00001.95, 00985.84 ], Gyr [ 00001.10, 00001.89, -00003.66 ], Mag [ 00019.65, 00083.40, 00106.20 ]",
    "#0000006588 NoMotion-1. Scaled. Acc [ -00042.48, 00011.23, 00996.09 ], Gyr [ -00000.43, 00001.52, -00003.23 ], Mag [ 00019.65, 00084.15, 00108.00 ]",
    "#0000006589 NoMotion-1. Scaled. Acc [ -00034.18, -00002.93, 00982.91 ], Gyr [ 00000.00, 00000.98, -00000.79 ], Mag [ 00019.65, 00084.15, 00108.00 ]",
    "#0000006590 NoMotion-1. Scaled. Acc [ -00054.20, -00007.81, 00991.70 ], Gyr [ 00000.91, 00004.76, -00001.16 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006591 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00991.21 ], Gyr [ 00000.12, 00002.32, -00001.65 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006592 NoMotion-1. Scaled. Acc [ -00043.95, -00008.79, 00990.72 ], Gyr [ -00000.30, 00001.65, -00003.66 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006593 NoMotion-1. Scaled. Acc [ -00035.16, 00002.44, 00983.89 ], Gyr [ 00000.85, 00002.99, -00001.28 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000006594 NoMotion-1. Scaled. Acc [ -00047.36, -00007.81, 00993.16 ], Gyr [ -00000.06, -00000.73, -00000.18 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000006595 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00996.09 ], Gyr [ 00001.83, 00001.22, -00002.13 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006596 NoMotion-1. Scaled. Acc [ -00044.43, -00011.72, 00997.56 ], Gyr [ -00000.55, 00003.90, -00002.26 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006597 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00983.89 ], Gyr [ 00000.67, 00000.06, 00001.10 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006598 NoMotion-1. Scaled. Acc [ -00036.13, -00013.67, 00989.75 ], Gyr [ -00000.61, 00001.89, 00000.12 ], Mag [ 00019.35, 00083.70, 00106.95 ]",
    "#0000006599 NoMotion-1. Scaled. Acc [ -00035.64, -00015.14, 00994.14 ], Gyr [ 00001.40, 00001.22, -00002.68 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006600 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00990.72 ], Gyr [ -00001.22, 00002.68, -00000.06 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006601 NoMotion-1. Scaled. Acc [ -00055.66, -00005.37, 00982.42 ], Gyr [ 00003.05, 00000.00, -00002.44 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006602 NoMotion-1. Scaled. Acc [ -00042.97, -00002.44, 00993.65 ], Gyr [ 00001.52, 00002.13, -00000.91 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006603 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 00994.63 ], Gyr [ 00000.06, 00002.80, -00000.43 ], Mag [ 00019.20, 00085.05, 00107.55 ]",
    "#0000006604 NoMotion-1. Scaled. Acc [ -00042.48, -00003.42, 00986.82 ], Gyr [ -00002.68, 00003.72, -00002.38 ], Mag [ 00018.60, 00083.40, 00108.75 ]",
    "#0000006605 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00992.68 ], Gyr [ 00000.67, 00001.89, -00003.84 ], Mag [ 00018.60, 00083.40, 00108.75 ]",
    "#0000006606 NoMotion-1. Scaled. Acc [ -00052.73, -00014.65, 00984.38 ], Gyr [ -00000.55, 00001.52, -00000.30 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000006607 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00994.63 ], Gyr [ 00002.50, 00001.52, 00000.73 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000006608 NoMotion-1. Scaled. Acc [ -00035.64, -00004.88, 00996.09 ], Gyr [ 00000.85, 00003.05, -00001.52 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006609 NoMotion-1. Scaled. Acc [ -00035.16, -00003.91, 01008.30 ], Gyr [ 00001.40, -00001.52, -00002.50 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006610 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00990.72 ], Gyr [ 00001.83, 00001.46, -00000.24 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006611 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 00993.65 ], Gyr [ 00000.85, -00000.67, 00000.85 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006612 NoMotion-1. Scaled. Acc [ -00059.08, -00002.44, 00986.82 ], Gyr [ -00000.55, 00002.26, -00005.85 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006613 NoMotion-1. Scaled. Acc [ -00034.18, -00002.44, 00994.63 ], Gyr [ -00000.61, 00003.17, 00000.49 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006614 NoMotion-1. Scaled. Acc [ -00045.41, -00005.37, 00986.33 ], Gyr [ -00000.49, -00001.89, 00000.06 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006615 NoMotion-1. Scaled. Acc [ -00046.39, -00002.93, 00989.75 ], Gyr [ -00000.85, 00001.95, -00003.17 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006616 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00986.33 ], Gyr [ -00000.24, 00002.50, -00000.79 ], Mag [ 00017.55, 00081.45, 00107.70 ]",
    "#0000006617 NoMotion-1. Scaled. Acc [ -00043.46, -00017.58, 00995.12 ], Gyr [ -00000.73, 00001.04, -00004.21 ], Mag [ 00017.55, 00081.45, 00107.70 ]",
    "#0000006618 NoMotion-1. Scaled. Acc [ -00036.62, -00005.37, 00991.21 ], Gyr [ 00000.98, 00003.66, -00000.37 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006619 NoMotion-1. Scaled. Acc [ -00040.53, -00011.72, 00992.68 ], Gyr [ -00000.06, -00001.16, -00000.73 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006620 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00994.14 ], Gyr [ 00000.06, -00000.49, -00000.91 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006621 NoMotion-1. Scaled. Acc [ -00036.62, -00014.65, 00995.61 ], Gyr [ 00002.44, 00003.23, -00000.55 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006622 NoMotion-1. Scaled. Acc [ -00044.43, -00001.95, 00997.56 ], Gyr [ 00000.67, -00000.18, -00001.71 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006623 NoMotion-1. Scaled. Acc [ -00046.39, 00001.95, 00984.86 ], Gyr [ 00001.40, 00001.59, -00001.77 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000006624 NoMotion-1. Scaled. Acc [ -00036.62, -00015.62, 00976.56 ], Gyr [ 00000.67, 00001.22, -00001.04 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006625 NoMotion-1. Scaled. Acc [ -00044.92, -00017.58, 01005.86 ], Gyr [ 00001.10, 00001.46, 00000.43 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006626 NoMotion-1. Scaled. Acc [ -00046.88, -00016.60, 00991.70 ], Gyr [ 00002.01, 00000.43, -00003.78 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006627 NoMotion-1. Scaled. Acc [ -00040.04, -00011.23, 00995.12 ], Gyr [ -00002.07, 00001.10, -00002.74 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006628 NoMotion-1. Scaled. Acc [ -00033.20, -00003.91, 00998.54 ], Gyr [ 00000.55, 00000.49, 00001.34 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006629 NoMotion-1. Scaled. Acc [ -00041.02, -00001.95, 00996.09 ], Gyr [ 00000.37, 00000.67, -00002.62 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006630 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00993.16 ], Gyr [ 00000.06, 00003.11, -00003.78 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000006631 NoMotion-1. Scaled. Acc [ -00037.60, -00002.44, 00990.23 ], Gyr [ -00000.91, 00003.66, -00001.46 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000006632 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 00994.63 ], Gyr [ 00001.04, 00001.22, 00000.61 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000006633 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00992.19 ], Gyr [ 00001.83, 00001.04, -00002.32 ], Mag [ 00019.35, 00084.90, 00108.00 ]",
    "#0000006634 NoMotion-1. Scaled. Acc [ -00045.90, -00014.65, 00996.58 ], Gyr [ -00000.37, 00001.52, -00000.79 ], Mag [ 00019.35, 00084.90, 00108.00 ]",
    "#0000006635 NoMotion-1. Scaled. Acc [ -00054.69, -00006.84, 00987.30 ], Gyr [ 00000.67, 00000.06, 00000.55 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006636 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00982.42 ], Gyr [ -00000.24, 00000.67, -00000.24 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006637 NoMotion-1. Scaled. Acc [ -00046.88, -00001.46, 00983.40 ], Gyr [ 00000.49, 00000.49, -00001.16 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006638 NoMotion-1. Scaled. Acc [ -00052.73, -00002.93, 00982.42 ], Gyr [ -00000.43, 00003.05, -00003.54 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006639 NoMotion-1. Scaled. Acc [ -00035.16, -00007.81, 00989.26 ], Gyr [ -00000.79, 00000.85, -00004.02 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000006640 NoMotion-1. Scaled. Acc [ -00026.37, 00001.95, 00978.52 ], Gyr [ 00000.79, -00002.07, -00001.46 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000006641 NoMotion-1. Scaled. Acc [ -00048.34, -00007.32, 00999.51 ], Gyr [ -00000.79, 00002.99, 00000.30 ], Mag [ 00019.35, 00082.95, 00109.50 ]",
    "#0000006642 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00985.35 ], Gyr [ 00000.24, 00002.32, -00002.74 ], Mag [ 00019.35, 00082.95, 00109.50 ]",
    "#0000006643 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00995.12 ], Gyr [ -00000.24, 00002.74, -00003.48 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000006644 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00994.63 ], Gyr [ 00000.06, 00000.49, -00003.05 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000006645 NoMotion-1. Scaled. Acc [ -00033.20, 00008.79, 00980.96 ], Gyr [ 00001.95, 00003.29, -00001.65 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006646 NoMotion-1. Scaled. Acc [ -00041.50, -00011.23, 00994.63 ], Gyr [ 00001.71, -00001.04, 00000.00 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006647 NoMotion-1. Scaled. Acc [ -00053.71, -00012.70, 00986.33 ], Gyr [ 00002.07, 00000.37, 00000.06 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006648 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00983.40 ], Gyr [ 00000.61, 00002.99, -00001.52 ], Mag [ 00020.25, 00081.75, 00107.40 ]",
    "#0000006649 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00984.38 ], Gyr [ 00002.50, 00001.28, -00002.26 ], Mag [ 00020.25, 00081.75, 00106.50 ]",
    "#0000006650 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00999.51 ], Gyr [ 00001.83, -00000.85, -00001.83 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006651 NoMotion-1. Scaled. Acc [ -00042.97, -00011.23, 00988.28 ], Gyr [ 00000.98, -00000.98, -00000.12 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006652 NoMotion-1. Scaled. Acc [ -00049.80, -00003.42, 00996.58 ], Gyr [ -00000.06, 00003.05, -00001.34 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006653 NoMotion-1. Scaled. Acc [ -00052.25, -00008.79, 00985.35 ], Gyr [ -00001.34, 00001.52, -00001.46 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000006654 NoMotion-1. Scaled. Acc [ -00041.02, 00004.88, 00993.16 ], Gyr [ 00000.79, 00001.28, -00002.56 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006655 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00987.30 ], Gyr [ -00002.20, 00000.98, -00000.61 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006656 NoMotion-1. Scaled. Acc [ -00024.41, -00006.35, 00986.33 ], Gyr [ 00000.49, 00002.93, -00000.85 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006657 NoMotion-1. Scaled. Acc [ -00039.55, -00003.42, 00988.77 ], Gyr [ -00000.24, 00003.29, -00001.16 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006658 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 00997.07 ], Gyr [ 00002.26, 00001.04, -00000.12 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006659 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00993.16 ], Gyr [ 00001.46, 00000.30, -00000.43 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006660 NoMotion-1. Scaled. Acc [ -00041.50, -00003.42, 00991.70 ], Gyr [ 00000.12, 00004.51, -00003.60 ], Mag [ 00018.60, 00084.15, 00109.05 ]",
    "#0000006661 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00984.86 ], Gyr [ 00000.49, 00000.61, -00000.43 ], Mag [ 00018.60, 00084.15, 00109.05 ]",
    "#0000006662 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00991.21 ], Gyr [ 00002.44, -00000.06, -00000.43 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000006663 NoMotion-1. Scaled. Acc [ -00049.32, -00015.62, 01000.98 ], Gyr [ -00001.28, 00002.68, -00000.98 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006664 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 01005.37 ], Gyr [ 00001.40, 00002.56, -00003.11 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006665 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00991.21 ], Gyr [ -00000.37, 00001.52, -00000.91 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006666 NoMotion-1. Scaled. Acc [ -00041.02, -00006.35, 00998.05 ], Gyr [ 00001.04, -00000.30, 00000.37 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006667 NoMotion-1. Scaled. Acc [ -00035.64, -00000.98, 00990.72 ], Gyr [ -00002.20, 00002.74, -00002.38 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000006668 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 00983.89 ], Gyr [ 00001.10, 00004.09, -00001.71 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000006669 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00993.16 ], Gyr [ 00000.73, 00001.04, -00000.98 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006670 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 00987.79 ], Gyr [ 00002.99, 00002.50, -00002.44 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006671 NoMotion-1. Scaled. Acc [ -00051.27, -00012.21, 00998.05 ], Gyr [ 00001.83, 00001.46, 00000.06 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006672 NoMotion-1. Scaled. Acc [ -00037.60, -00006.35, 00994.63 ], Gyr [ 00000.06, -00002.01, -00001.34 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006673 NoMotion-1. Scaled. Acc [ -00041.99, 00001.46, 01000.98 ], Gyr [ 00001.95, 00000.43, -00000.06 ], Mag [ 00018.15, 00085.05, 00108.90 ]",
    "#0000006674 NoMotion-1. Scaled. Acc [ -00048.34, -00008.30, 00984.86 ], Gyr [ 00001.59, -00000.49, -00001.34 ], Mag [ 00018.15, 00085.05, 00108.90 ]",
    "#0000006675 NoMotion-1. Scaled. Acc [ -00042.48, -00012.21, 00987.30 ], Gyr [ 00000.00, 00001.83, -00002.68 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000006676 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00987.79 ], Gyr [ 00001.83, 00000.37, -00002.99 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000006677 NoMotion-1. Scaled. Acc [ -00041.99, -00003.42, 00985.35 ], Gyr [ 00000.61, 00000.91, -00001.65 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006678 NoMotion-1. Scaled. Acc [ -00039.06, -00000.49, 00991.21 ], Gyr [ -00000.49, 00004.15, 00000.43 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006679 NoMotion-1. Scaled. Acc [ -00041.02, -00002.44, 00995.61 ], Gyr [ -00001.83, 00001.71, -00002.20 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006680 NoMotion-1. Scaled. Acc [ -00062.50, 00000.98, 00991.21 ], Gyr [ 00000.61, -00000.37, -00002.74 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006681 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00982.42 ], Gyr [ 00001.95, 00002.32, -00002.26 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006682 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00991.21 ], Gyr [ 00003.11, -00001.10, 00000.43 ], Mag [ 00018.15, 00085.05, 00107.55 ]",
    "#0000006683 NoMotion-1. Scaled. Acc [ -00051.27, -00013.18, 00997.07 ], Gyr [ 00001.16, 00001.83, -00001.89 ], Mag [ 00019.20, 00083.55, 00107.10 ]",
    "#0000006684 NoMotion-1. Scaled. Acc [ -00044.43, 00005.86, 00993.16 ], Gyr [ 00001.22, -00001.34, 00000.73 ], Mag [ 00019.20, 00083.55, 00107.10 ]",
    "#0000006685 NoMotion-1. Scaled. Acc [ -00028.81, -00001.95, 00986.82 ], Gyr [ 00000.73, 00000.30, 00001.65 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000006686 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00989.75 ], Gyr [ 00001.77, -00000.85, -00001.40 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000006687 NoMotion-1. Scaled. Acc [ -00049.32, -00004.39, 00991.21 ], Gyr [ -00000.12, 00000.91, -00001.16 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006688 NoMotion-1. Scaled. Acc [ -00039.06, 00000.00, 00996.58 ], Gyr [ 00000.37, 00002.01, -00001.04 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006689 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00986.82 ], Gyr [ 00000.18, 00002.01, -00001.52 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006690 NoMotion-1. Scaled. Acc [ -00040.53, -00018.55, 00994.14 ], Gyr [ 00000.37, 00000.43, -00001.95 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006691 NoMotion-1. Scaled. Acc [ -00025.39, -00016.11, 01003.91 ], Gyr [ -00000.43, 00001.71, -00000.98 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000006692 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00990.23 ], Gyr [ -00000.24, 00001.28, -00002.32 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000006693 NoMotion-1. Scaled. Acc [ -00040.53, -00013.67, 01003.42 ], Gyr [ 00000.49, 00002.38, -00000.73 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000006694 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00981.93 ], Gyr [ 00001.10, 00002.32, -00000.49 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000006695 NoMotion-1. Scaled. Acc [ -00036.13, 00001.46, 00998.05 ], Gyr [ -00000.24, 00003.66, 00000.98 ], Mag [ 00019.65, 00083.70, 00108.00 ]",
    "#0000006696 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 01008.79 ], Gyr [ 00001.77, 00001.28, -00003.23 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006697 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00994.14 ], Gyr [ 00002.26, 00000.73, -00001.59 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006698 NoMotion-1. Scaled. Acc [ -00055.18, -00005.86, 01000.98 ], Gyr [ 00002.74, -00000.30, 00000.18 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006699 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00997.56 ], Gyr [ 00001.46, 00000.67, 00000.79 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006700 NoMotion-1. Scaled. Acc [ -00050.78, -00010.74, 00994.63 ], Gyr [ -00001.04, 00000.98, -00000.55 ], Mag [ 00018.60, 00085.20, 00105.90 ]",
    "#0000006701 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00995.61 ], Gyr [ 00000.67, 00002.07, 00001.22 ], Mag [ 00018.60, 00085.20, 00105.90 ]",
    "#0000006702 NoMotion-1. Scaled. Acc [ -00038.09, -00006.35, 00985.35 ], Gyr [ -00000.37, 00002.80, -00000.18 ], Mag [ 00020.40, 00084.45, 00106.95 ]",
    "#0000006703 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00986.82 ], Gyr [ 00000.24, 00003.35, -00004.21 ], Mag [ 00020.40, 00084.45, 00106.95 ]",
    "#0000006704 NoMotion-1. Scaled. Acc [ -00042.48, -00021.97, 00993.16 ], Gyr [ 00000.30, 00002.56, 00001.16 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000006705 NoMotion-1. Scaled. Acc [ -00051.27, -00003.91, 00994.63 ], Gyr [ 00002.26, 00001.71, -00001.34 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000006706 NoMotion-1. Scaled. Acc [ -00045.90, 00004.39, 00992.68 ], Gyr [ 00000.30, 00004.94, -00001.83 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006707 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00989.26 ], Gyr [ -00000.18, 00006.10, -00004.21 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006708 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00993.65 ], Gyr [ -00000.55, 00000.37, 00001.16 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006709 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00995.61 ], Gyr [ 00000.43, 00001.52, -00001.52 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006710 NoMotion-1. Scaled. Acc [ -00047.85, -00010.25, 00983.89 ], Gyr [ 00000.55, 00000.79, -00004.21 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006711 NoMotion-1. Scaled. Acc [ -00053.22, -00007.81, 00998.54 ], Gyr [ 00001.65, 00000.12, -00000.37 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006712 NoMotion-1. Scaled. Acc [ -00032.71, -00004.39, 00997.56 ], Gyr [ -00000.91, 00001.40, -00002.93 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006713 NoMotion-1. Scaled. Acc [ -00038.57, -00000.98, 00990.23 ], Gyr [ 00001.46, 00000.91, -00000.55 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006714 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 00991.21 ], Gyr [ 00000.55, 00000.98, -00001.28 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006715 NoMotion-1. Scaled. Acc [ -00041.02, -00014.65, 00983.40 ], Gyr [ 00000.49, 00001.77, 00001.04 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006716 NoMotion-1. Scaled. Acc [ -00048.34, -00024.41, 01000.00 ], Gyr [ 00000.67, 00000.79, -00000.98 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006717 NoMotion-1. Scaled. Acc [ -00031.74, -00016.11, 00995.12 ], Gyr [ -00000.67, 00002.07, 00000.00 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006718 NoMotion-1. Scaled. Acc [ -00041.50, 00003.91, 00993.16 ], Gyr [ 00000.06, 00001.83, -00000.91 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006719 NoMotion-1. Scaled. Acc [ -00046.88, -00008.30, 00992.19 ], Gyr [ 00001.04, 00003.84, -00001.95 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006720 NoMotion-1. Scaled. Acc [ -00055.18, -00018.55, 00991.21 ], Gyr [ 00000.67, 00001.89, -00002.87 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006721 NoMotion-1. Scaled. Acc [ -00021.97, -00003.42, 00997.56 ], Gyr [ 00000.37, 00001.52, 00001.22 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006722 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00978.03 ], Gyr [ 00001.34, 00000.91, -00002.07 ], Mag [ 00020.85, 00081.90, 00107.70 ]",
    "#0000006723 NoMotion-1. Scaled. Acc [ -00035.64, -00002.93, 00994.14 ], Gyr [ 00001.10, 00000.55, -00000.24 ], Mag [ 00020.85, 00081.90, 00107.70 ]",
    "#0000006724 NoMotion-1. Scaled. Acc [ -00040.53, -00006.35, 01004.39 ], Gyr [ 00000.55, 00003.17, -00001.83 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006725 NoMotion-1. Scaled. Acc [ -00053.71, -00006.35, 00997.56 ], Gyr [ 00000.37, 00000.91, -00001.95 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006726 NoMotion-1. Scaled. Acc [ -00043.46, -00005.37, 00986.33 ], Gyr [ -00002.20, -00001.16, -00002.68 ], Mag [ 00020.10, 00084.45, 00108.45 ]",
    "#0000006727 NoMotion-1. Scaled. Acc [ -00040.53, -00016.11, 00987.30 ], Gyr [ 00002.20, -00000.73, -00001.95 ], Mag [ 00020.10, 00084.45, 00108.45 ]",
    "#0000006728 NoMotion-1. Scaled. Acc [ -00047.36, -00014.16, 00999.51 ], Gyr [ 00000.18, 00002.50, -00001.95 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006729 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00987.79 ], Gyr [ -00000.06, 00001.04, -00002.93 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006730 NoMotion-1. Scaled. Acc [ -00043.95, -00013.67, 00993.65 ], Gyr [ -00001.59, 00002.87, -00000.30 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006731 NoMotion-1. Scaled. Acc [ -00039.55, -00007.32, 00988.77 ], Gyr [ -00001.10, 00004.09, -00001.52 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006732 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00985.35 ], Gyr [ 00000.06, 00003.78, -00002.13 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000006733 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00987.30 ], Gyr [ 00000.18, -00000.12, 00000.06 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006734 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00996.58 ], Gyr [ 00000.61, 00001.34, 00000.85 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006735 NoMotion-1. Scaled. Acc [ -00042.48, 00000.49, 00991.21 ], Gyr [ -00000.55, 00002.80, -00001.22 ], Mag [ 00018.75, 00084.00, 00108.90 ]",
    "#0000006736 NoMotion-1. Scaled. Acc [ -00032.71, -00010.25, 01000.49 ], Gyr [ 00001.16, 00001.34, -00001.89 ], Mag [ 00018.75, 00084.00, 00108.90 ]",
    "#0000006737 NoMotion-1. Scaled. Acc [ -00035.16, -00012.21, 00994.14 ], Gyr [ 00000.67, 00002.20, -00002.80 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006738 NoMotion-1. Scaled. Acc [ -00048.83, -00011.72, 00999.02 ], Gyr [ 00001.40, 00002.50, 00001.40 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006739 NoMotion-1. Scaled. Acc [ -00038.57, -00011.23, 01002.93 ], Gyr [ -00000.06, 00002.99, -00002.50 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006740 NoMotion-1. Scaled. Acc [ -00050.78, -00005.37, 01001.95 ], Gyr [ -00001.65, 00003.96, -00000.55 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006741 NoMotion-1. Scaled. Acc [ -00038.57, -00014.16, 01000.00 ], Gyr [ 00000.43, -00000.67, -00001.59 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000006742 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00997.56 ], Gyr [ -00000.79, 00003.48, -00001.22 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000006743 NoMotion-1. Scaled. Acc [ -00034.18, -00004.39, 01001.46 ], Gyr [ 00001.52, 00002.80, -00000.73 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006744 NoMotion-1. Scaled. Acc [ -00041.99, 00005.86, 00989.75 ], Gyr [ 00001.52, 00001.89, -00002.07 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006745 NoMotion-1. Scaled. Acc [ -00040.04, -00003.91, 00997.56 ], Gyr [ 00000.61, 00000.12, -00001.95 ], Mag [ 00020.70, 00084.30, 00109.35 ]",
    "#0000006746 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 01002.44 ], Gyr [ 00000.30, 00000.24, -00001.83 ], Mag [ 00020.70, 00084.30, 00109.35 ]",
    "#0000006747 NoMotion-1. Scaled. Acc [ -00033.20, -00009.77, 00994.14 ], Gyr [ -00000.73, 00002.44, -00001.22 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006748 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00987.79 ], Gyr [ 00000.61, -00001.83, -00000.24 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006749 NoMotion-1. Scaled. Acc [ -00040.04, -00018.07, 00994.63 ], Gyr [ 00000.49, 00000.43, -00000.67 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006750 NoMotion-1. Scaled. Acc [ -00042.48, -00017.58, 00989.75 ], Gyr [ 00000.43, -00000.30, -00001.77 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006751 NoMotion-1. Scaled. Acc [ -00046.39, -00007.81, 00995.61 ], Gyr [ -00000.30, 00003.60, -00003.66 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006752 NoMotion-1. Scaled. Acc [ -00048.34, -00020.02, 00991.70 ], Gyr [ 00002.38, 00000.73, 00000.37 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006753 NoMotion-1. Scaled. Acc [ -00045.90, -00011.72, 00988.28 ], Gyr [ 00001.10, -00000.85, -00002.38 ], Mag [ 00020.70, 00083.25, 00107.10 ]",
    "#0000006754 NoMotion-1. Scaled. Acc [ -00031.25, -00005.37, 00977.54 ], Gyr [ 00001.22, -00000.85, -00000.06 ], Mag [ 00020.70, 00083.25, 00107.10 ]",
    "#0000006755 NoMotion-1. Scaled. Acc [ -00033.20, -00003.91, 00983.40 ], Gyr [ 00000.67, 00002.07, -00003.41 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006756 NoMotion-1. Scaled. Acc [ -00035.64, -00005.37, 00994.63 ], Gyr [ 00000.12, 00000.12, -00001.34 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006757 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 01006.84 ], Gyr [ -00000.55, 00001.77, -00002.80 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006758 NoMotion-1. Scaled. Acc [ -00039.55, 00001.46, 00984.86 ], Gyr [ 00000.18, 00002.80, -00001.77 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006759 NoMotion-1. Scaled. Acc [ -00051.27, -00013.67, 00993.65 ], Gyr [ 00000.67, 00001.16, -00000.67 ], Mag [ 00021.30, 00084.75, 00107.10 ]",
    "#0000006760 NoMotion-1. Scaled. Acc [ -00046.88, -00010.25, 00996.09 ], Gyr [ -00000.30, -00000.79, -00001.71 ], Mag [ 00021.30, 00084.75, 00107.10 ]",
    "#0000006761 NoMotion-1. Scaled. Acc [ -00058.11, -00002.44, 00978.03 ], Gyr [ 00000.12, 00002.38, -00000.91 ], Mag [ 00019.95, 00084.00, 00105.45 ]",
    "#0000006762 NoMotion-1. Scaled. Acc [ -00040.53, -00002.44, 01000.98 ], Gyr [ 00002.68, 00002.68, -00002.07 ], Mag [ 00019.95, 00084.00, 00105.45 ]",
    "#0000006763 NoMotion-1. Scaled. Acc [ -00030.76, 00000.49, 00988.77 ], Gyr [ 00002.13, 00001.22, 00000.06 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000006764 NoMotion-1. Scaled. Acc [ -00030.76, -00013.18, 01000.98 ], Gyr [ 00002.26, -00000.12, 00000.06 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000006765 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00993.65 ], Gyr [ 00000.85, 00002.07, -00001.77 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006766 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00987.30 ], Gyr [ 00002.93, -00000.91, -00002.44 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006767 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00995.61 ], Gyr [ 00001.95, 00003.90, -00002.44 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006768 NoMotion-1. Scaled. Acc [ -00045.90, -00002.93, 00992.68 ], Gyr [ -00000.06, 00002.13, -00000.30 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006769 NoMotion-1. Scaled. Acc [ -00054.69, -00004.88, 00994.14 ], Gyr [ 00001.22, 00001.22, -00002.74 ], Mag [ 00019.50, 00084.30, 00107.55 ]",
    "#0000006770 NoMotion-1. Scaled. Acc [ -00054.20, -00006.35, 00992.19 ], Gyr [ -00002.07, 00001.89, 00000.18 ], Mag [ 00019.50, 00084.30, 00107.55 ]",
    "#0000006771 NoMotion-1. Scaled. Acc [ -00031.74, -00002.44, 00988.77 ], Gyr [ -00000.30, 00002.50, -00003.29 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000006772 NoMotion-1. Scaled. Acc [ -00046.88, 00001.46, 00988.28 ], Gyr [ 00000.98, 00002.20, 00000.24 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000006773 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00987.79 ], Gyr [ 00002.93, -00001.22, 00000.00 ], Mag [ 00019.35, 00083.70, 00106.65 ]",
    "#0000006774 NoMotion-1. Scaled. Acc [ -00047.85, 00005.37, 00983.40 ], Gyr [ 00000.73, -00000.67, 00000.12 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006775 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00988.28 ], Gyr [ 00002.93, -00000.91, -00002.80 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006776 NoMotion-1. Scaled. Acc [ -00046.39, 00001.46, 00993.16 ], Gyr [ 00000.85, 00000.00, -00000.30 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006777 NoMotion-1. Scaled. Acc [ -00038.57, -00018.07, 00990.23 ], Gyr [ -00002.13, 00000.37, -00001.16 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006778 NoMotion-1. Scaled. Acc [ -00042.97, 00012.70, 00994.63 ], Gyr [ 00001.65, 00002.26, -00000.49 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006779 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00994.63 ], Gyr [ 00000.49, 00003.11, -00001.04 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006780 NoMotion-1. Scaled. Acc [ -00034.18, -00021.00, 00994.63 ], Gyr [ 00000.55, 00002.74, -00002.87 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006781 NoMotion-1. Scaled. Acc [ -00032.23, -00006.84, 01003.42 ], Gyr [ 00000.24, 00004.33, -00001.83 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006782 NoMotion-1. Scaled. Acc [ -00052.73, -00007.81, 00987.79 ], Gyr [ -00000.85, 00000.06, -00003.54 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006783 NoMotion-1. Scaled. Acc [ -00041.02, 00000.98, 00986.33 ], Gyr [ 00001.28, 00000.06, -00002.44 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006784 NoMotion-1. Scaled. Acc [ -00040.04, -00017.09, 01000.98 ], Gyr [ -00000.49, 00000.79, 00000.37 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000006785 NoMotion-1. Scaled. Acc [ -00054.20, -00005.37, 01002.93 ], Gyr [ 00001.04, 00001.10, -00002.01 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000006786 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00992.19 ], Gyr [ -00000.12, 00001.10, -00000.85 ], Mag [ 00020.40, 00084.45, 00107.70 ]",
    "#0000006787 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00994.63 ], Gyr [ 00001.59, -00000.06, -00001.10 ], Mag [ 00020.40, 00084.45, 00107.70 ]",
    "#0000006788 NoMotion-1. Scaled. Acc [ -00045.41, 00004.39, 00984.38 ], Gyr [ 00001.04, 00000.12, -00003.11 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000006789 NoMotion-1. Scaled. Acc [ -00031.74, 00006.84, 01000.98 ], Gyr [ 00000.18, -00001.04, 00000.67 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006790 NoMotion-1. Scaled. Acc [ -00053.71, -00014.65, 00992.19 ], Gyr [ 00000.12, 00001.10, -00002.68 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006791 NoMotion-1. Scaled. Acc [ -00046.39, -00008.30, 00993.65 ], Gyr [ 00000.18, 00002.38, -00002.07 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006792 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00982.42 ], Gyr [ -00001.10, 00001.77, -00002.93 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000006793 NoMotion-1. Scaled. Acc [ -00047.36, -00006.35, 00999.51 ], Gyr [ -00000.73, 00002.07, -00000.37 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000006794 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00984.86 ], Gyr [ 00001.52, -00000.49, -00002.01 ], Mag [ 00018.75, 00084.75, 00106.80 ]",
    "#0000006795 NoMotion-1. Scaled. Acc [ -00052.73, -00007.32, 00993.16 ], Gyr [ -00000.43, 00000.85, -00002.20 ], Mag [ 00018.75, 00084.75, 00106.80 ]",
    "#0000006796 NoMotion-1. Scaled. Acc [ -00058.59, -00003.42, 00995.12 ], Gyr [ 00000.00, 00000.73, -00000.43 ], Mag [ 00019.35, 00085.20, 00107.70 ]",
    "#0000006797 NoMotion-1. Scaled. Acc [ -00057.13, -00014.65, 00991.70 ], Gyr [ 00000.30, 00001.77, -00001.22 ], Mag [ 00019.35, 00085.20, 00107.70 ]",
    "#0000006798 NoMotion-1. Scaled. Acc [ -00044.43, -00007.81, 00990.72 ], Gyr [ 00001.16, 00001.04, -00001.89 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006799 NoMotion-1. Scaled. Acc [ -00038.57, -00008.30, 00998.54 ], Gyr [ 00000.30, 00000.24, -00001.77 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006800 NoMotion-1. Scaled. Acc [ -00034.18, -00009.28, 00989.75 ], Gyr [ 00000.06, 00002.38, -00002.01 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006801 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00992.19 ], Gyr [ 00000.85, 00001.28, -00000.49 ], Mag [ 00019.50, 00083.55, 00108.60 ]",
    "#0000006802 NoMotion-1. Scaled. Acc [ -00044.43, -00001.46, 00983.40 ], Gyr [ 00001.10, 00003.72, -00001.83 ], Mag [ 00019.50, 00083.55, 00108.60 ]",
    "#0000006803 NoMotion-1. Scaled. Acc [ -00041.50, -00008.79, 00984.86 ], Gyr [ -00000.85, 00004.15, 00000.55 ], Mag [ 00020.40, 00083.55, 00108.60 ]",
    "#0000006804 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00999.51 ], Gyr [ -00000.85, 00003.41, -00003.48 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000006805 NoMotion-1. Scaled. Acc [ -00055.18, -00011.72, 00990.72 ], Gyr [ -00001.77, 00002.56, -00001.22 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006806 NoMotion-1. Scaled. Acc [ -00048.83, 00001.46, 00995.61 ], Gyr [ 00000.37, 00002.26, -00002.62 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006807 NoMotion-1. Scaled. Acc [ -00038.57, -00001.46, 01003.91 ], Gyr [ 00000.37, 00001.65, -00002.32 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006808 NoMotion-1. Scaled. Acc [ -00035.16, 00001.95, 00991.70 ], Gyr [ 00001.59, 00000.30, 00000.67 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006809 NoMotion-1. Scaled. Acc [ -00045.41, 00001.95, 00993.16 ], Gyr [ 00001.34, -00000.85, -00000.61 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006810 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00996.09 ], Gyr [ 00002.44, 00001.04, 00000.55 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000006811 NoMotion-1. Scaled. Acc [ -00052.25, -00011.23, 01000.49 ], Gyr [ 00001.52, 00002.32, -00000.85 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000006812 NoMotion-1. Scaled. Acc [ -00035.16, -00012.21, 00988.77 ], Gyr [ -00000.18, 00002.62, -00001.46 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000006813 NoMotion-1. Scaled. Acc [ -00042.48, -00017.09, 00991.70 ], Gyr [ 00001.40, 00001.16, -00001.10 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000006814 NoMotion-1. Scaled. Acc [ -00041.50, -00003.91, 01000.49 ], Gyr [ 00002.01, -00000.06, -00000.43 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000006815 NoMotion-1. Scaled. Acc [ -00040.04, -00017.09, 00990.23 ], Gyr [ 00002.44, 00001.34, -00003.05 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000006816 NoMotion-1. Scaled. Acc [ -00048.34, -00004.39, 00978.52 ], Gyr [ -00000.24, 00000.79, -00003.96 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006817 NoMotion-1. Scaled. Acc [ -00049.32, -00001.46, 00995.61 ], Gyr [ 00000.06, 00001.16, -00001.34 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006818 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 01003.91 ], Gyr [ 00000.43, 00002.68, -00002.68 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006819 NoMotion-1. Scaled. Acc [ -00044.92, -00006.35, 00992.68 ], Gyr [ 00000.85, 00004.02, -00004.09 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006820 NoMotion-1. Scaled. Acc [ -00047.85, -00004.39, 00996.09 ], Gyr [ 00000.00, 00001.34, -00001.65 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000006821 NoMotion-1. Scaled. Acc [ -00050.78, -00006.84, 00983.89 ], Gyr [ -00001.04, -00000.24, -00001.83 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006822 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00992.68 ], Gyr [ 00001.16, 00001.46, -00002.01 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006823 NoMotion-1. Scaled. Acc [ -00043.46, -00003.42, 00994.14 ], Gyr [ 00001.89, 00001.16, 00000.61 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006824 NoMotion-1. Scaled. Acc [ -00052.73, -00001.95, 00993.65 ], Gyr [ 00003.84, 00000.24, -00001.40 ], Mag [ 00019.35, 00082.95, 00106.20 ]",
    "#0000006825 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00989.75 ], Gyr [ 00000.61, 00000.67, -00001.65 ], Mag [ 00019.35, 00082.95, 00106.20 ]",
    "#0000006826 NoMotion-1. Scaled. Acc [ -00039.55, 00002.93, 00985.35 ], Gyr [ 00001.04, 00000.12, -00002.80 ], Mag [ 00019.20, 00085.05, 00108.30 ]",
    "#0000006827 NoMotion-1. Scaled. Acc [ -00038.09, -00009.28, 00995.12 ], Gyr [ -00000.79, 00002.62, -00001.28 ], Mag [ 00019.20, 00085.05, 00108.30 ]",
    "#0000006828 NoMotion-1. Scaled. Acc [ -00050.78, -00006.35, 00993.65 ], Gyr [ 00001.46, 00002.80, -00003.96 ], Mag [ 00021.60, 00084.45, 00109.05 ]",
    "#0000006829 NoMotion-1. Scaled. Acc [ -00040.04, -00004.88, 00985.84 ], Gyr [ -00001.59, 00001.04, -00002.50 ], Mag [ 00021.60, 00084.45, 00109.05 ]",
    "#0000006830 NoMotion-1. Scaled. Acc [ -00057.13, -00004.39, 00992.68 ], Gyr [ -00001.59, 00002.50, -00000.85 ], Mag [ 00020.70, 00085.05, 00108.30 ]",
    "#0000006831 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00985.35 ], Gyr [ -00001.16, 00002.93, -00000.98 ], Mag [ 00020.70, 00085.05, 00108.30 ]",
    "#0000006832 NoMotion-1. Scaled. Acc [ -00040.04, -00020.51, 00984.38 ], Gyr [ -00000.24, 00001.95, -00002.80 ], Mag [ 00021.00, 00085.50, 00107.55 ]",
    "#0000006833 NoMotion-1. Scaled. Acc [ -00046.39, -00012.70, 00991.70 ], Gyr [ -00000.12, 00003.41, -00001.52 ], Mag [ 00021.00, 00085.50, 00107.55 ]",
    "#0000006834 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00986.82 ], Gyr [ 00000.00, 00002.50, -00003.72 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006835 NoMotion-1. Scaled. Acc [ -00039.55, -00021.00, 00985.84 ], Gyr [ 00001.16, 00000.85, -00001.77 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006836 NoMotion-1. Scaled. Acc [ -00043.46, -00006.35, 00984.38 ], Gyr [ 00001.52, 00000.79, -00001.40 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006837 NoMotion-1. Scaled. Acc [ -00041.50, -00001.46, 00989.26 ], Gyr [ -00001.65, 00002.56, -00001.40 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006838 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00982.91 ], Gyr [ 00001.59, 00000.67, -00000.73 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006839 NoMotion-1. Scaled. Acc [ -00054.20, 00000.98, 00996.58 ], Gyr [ 00000.61, 00001.04, -00001.16 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006840 NoMotion-1. Scaled. Acc [ -00049.32, -00009.28, 00990.23 ], Gyr [ -00000.24, 00001.77, -00001.77 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006841 NoMotion-1. Scaled. Acc [ -00047.36, -00000.98, 00999.51 ], Gyr [ 00000.49, 00001.52, -00000.67 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006842 NoMotion-1. Scaled. Acc [ -00040.53, -00025.39, 00994.63 ], Gyr [ -00002.01, 00002.87, -00000.37 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000006843 NoMotion-1. Scaled. Acc [ -00042.97, -00014.16, 00998.05 ], Gyr [ 00000.12, 00003.72, -00002.56 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000006844 NoMotion-1. Scaled. Acc [ -00037.60, -00010.25, 00994.63 ], Gyr [ 00000.55, 00004.39, -00001.95 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006845 NoMotion-1. Scaled. Acc [ -00044.43, 00000.49, 00992.19 ], Gyr [ -00000.49, 00000.43, -00003.54 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006846 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00991.21 ], Gyr [ 00002.07, 00000.24, -00001.52 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006847 NoMotion-1. Scaled. Acc [ -00037.60, -00003.91, 00984.86 ], Gyr [ 00001.22, 00002.93, -00002.68 ], Mag [ 00019.50, 00083.55, 00108.90 ]",
    "#0000006848 NoMotion-1. Scaled. Acc [ -00047.85, -00019.04, 00996.58 ], Gyr [ 00000.67, 00001.95, 00000.24 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006849 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00987.30 ], Gyr [ -00000.37, -00000.73, -00001.59 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006850 NoMotion-1. Scaled. Acc [ -00034.18, 00003.42, 00979.49 ], Gyr [ 00000.49, -00000.37, -00000.85 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006851 NoMotion-1. Scaled. Acc [ -00041.50, 00001.46, 00995.61 ], Gyr [ 00001.10, 00002.32, 00000.55 ], Mag [ 00019.35, 00082.20, 00107.40 ]",
    "#0000006852 NoMotion-1. Scaled. Acc [ -00042.48, 00008.79, 00989.75 ], Gyr [ -00000.24, 00001.95, -00002.99 ], Mag [ 00019.35, 00082.20, 00107.40 ]",
    "#0000006853 NoMotion-1. Scaled. Acc [ -00044.43, 00003.42, 00981.45 ], Gyr [ 00000.91, 00002.32, -00003.66 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006854 NoMotion-1. Scaled. Acc [ -00041.99, -00010.25, 00998.05 ], Gyr [ 00001.52, 00000.37, -00003.05 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006855 NoMotion-1. Scaled. Acc [ -00044.92, -00023.44, 00978.03 ], Gyr [ 00002.50, -00000.43, -00000.30 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006856 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 00994.14 ], Gyr [ 00000.85, 00000.79, -00003.54 ], Mag [ 00019.50, 00085.05, 00107.85 ]",
    "#0000006857 NoMotion-1. Scaled. Acc [ -00031.25, -00007.81, 00996.09 ], Gyr [ -00000.12, 00000.85, -00002.87 ], Mag [ 00019.50, 00085.05, 00107.85 ]",
    "#0000006858 NoMotion-1. Scaled. Acc [ -00041.02, -00007.81, 00990.23 ], Gyr [ -00000.43, 00000.55, -00002.01 ], Mag [ 00020.85, 00083.70, 00107.70 ]",
    "#0000006859 NoMotion-1. Scaled. Acc [ -00038.09, -00008.79, 00989.75 ], Gyr [ 00000.98, 00000.55, -00001.46 ], Mag [ 00020.85, 00083.70, 00107.70 ]",
    "#0000006860 NoMotion-1. Scaled. Acc [ -00041.50, -00003.42, 00984.38 ], Gyr [ 00002.99, 00002.32, -00001.71 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000006861 NoMotion-1. Scaled. Acc [ -00039.55, 00002.93, 01002.93 ], Gyr [ -00001.52, 00000.98, -00001.77 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000006862 NoMotion-1. Scaled. Acc [ -00029.30, -00006.84, 00990.23 ], Gyr [ 00000.43, 00001.52, -00002.07 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006863 NoMotion-1. Scaled. Acc [ -00056.15, -00004.39, 00994.14 ], Gyr [ 00001.28, 00001.52, 00001.04 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006864 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00991.70 ], Gyr [ 00000.18, 00000.06, -00001.34 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006865 NoMotion-1. Scaled. Acc [ -00047.36, -00009.77, 00991.70 ], Gyr [ -00000.30, 00001.10, -00003.29 ], Mag [ 00018.30, 00082.95, 00106.65 ]",
    "#0000006866 NoMotion-1. Scaled. Acc [ -00039.55, -00020.51, 00992.19 ], Gyr [ 00000.55, 00001.71, -00004.27 ], Mag [ 00018.30, 00082.95, 00106.65 ]",
    "#0000006867 NoMotion-1. Scaled. Acc [ -00045.41, -00010.25, 01002.93 ], Gyr [ 00000.24, -00000.91, -00004.39 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000006868 NoMotion-1. Scaled. Acc [ -00042.48, -00005.86, 00982.91 ], Gyr [ 00000.00, 00000.18, 00000.18 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000006869 NoMotion-1. Scaled. Acc [ -00042.97, -00012.21, 00990.72 ], Gyr [ 00000.73, 00003.05, -00002.20 ], Mag [ 00019.20, 00084.30, 00108.30 ]",
    "#0000006870 NoMotion-1. Scaled. Acc [ -00042.48, -00002.44, 00986.33 ], Gyr [ -00001.34, 00002.80, -00003.54 ], Mag [ 00019.20, 00084.30, 00108.30 ]",
    "#0000006871 NoMotion-1. Scaled. Acc [ -00051.27, -00001.95, 01005.37 ], Gyr [ -00000.06, 00000.12, -00001.83 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006872 NoMotion-1. Scaled. Acc [ -00045.90, 00003.42, 00998.54 ], Gyr [ 00002.62, 00000.79, -00001.16 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006873 NoMotion-1. Scaled. Acc [ -00045.41, -00014.16, 00983.89 ], Gyr [ -00001.46, -00001.10, -00004.21 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006874 NoMotion-1. Scaled. Acc [ -00037.11, -00020.51, 00993.65 ], Gyr [ 00000.73, 00000.67, 00001.16 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006875 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00985.35 ], Gyr [ 00001.52, -00000.12, -00001.46 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006876 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 01002.93 ], Gyr [ 00001.52, 00000.24, -00000.43 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000006877 NoMotion-1. Scaled. Acc [ -00041.02, -00005.86, 00984.38 ], Gyr [ -00000.30, 00000.43, 00000.43 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000006878 NoMotion-1. Scaled. Acc [ -00045.41, 00007.32, 00977.54 ], Gyr [ -00000.79, 00000.24, -00002.20 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006879 NoMotion-1. Scaled. Acc [ -00044.92, -00007.81, 00985.35 ], Gyr [ 00001.77, 00000.73, -00002.62 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006880 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00990.72 ], Gyr [ -00000.06, 00001.16, -00000.49 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006881 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00981.93 ], Gyr [ -00000.37, 00001.71, -00003.54 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006882 NoMotion-1. Scaled. Acc [ -00038.09, -00006.84, 00980.47 ], Gyr [ 00002.93, -00001.04, 00001.34 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006883 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00986.82 ], Gyr [ 00000.49, 00002.26, -00001.59 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006884 NoMotion-1. Scaled. Acc [ -00041.50, -00017.58, 00983.40 ], Gyr [ 00000.43, 00000.49, 00000.00 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006885 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00986.82 ], Gyr [ 00000.91, 00000.37, 00001.16 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006886 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 01005.37 ], Gyr [ 00001.04, 00002.44, 00001.89 ], Mag [ 00021.15, 00084.15, 00109.50 ]",
    "#0000006887 NoMotion-1. Scaled. Acc [ -00035.16, -00007.81, 00996.58 ], Gyr [ 00000.49, 00002.20, 00000.30 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006888 NoMotion-1. Scaled. Acc [ -00049.32, -00014.65, 00993.65 ], Gyr [ 00001.52, 00002.01, -00001.46 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006889 NoMotion-1. Scaled. Acc [ -00044.43, -00020.02, 01002.44 ], Gyr [ 00003.17, 00003.17, 00000.30 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006890 NoMotion-1. Scaled. Acc [ -00031.25, -00004.88, 00986.82 ], Gyr [ 00000.91, 00001.04, -00002.93 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006891 NoMotion-1. Scaled. Acc [ -00050.78, -00018.07, 00982.91 ], Gyr [ 00001.77, 00000.79, 00002.01 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006892 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00992.19 ], Gyr [ -00000.91, 00002.07, -00002.50 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000006893 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00989.75 ], Gyr [ -00000.55, 00003.29, -00002.07 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000006894 NoMotion-1. Scaled. Acc [ -00051.27, -00003.42, 00988.77 ], Gyr [ 00001.16, 00000.91, -00003.11 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000006895 NoMotion-1. Scaled. Acc [ -00051.76, -00003.42, 00989.26 ], Gyr [ 00000.06, 00001.16, -00000.43 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000006896 NoMotion-1. Scaled. Acc [ -00038.09, -00008.30, 00997.56 ], Gyr [ -00001.04, 00001.04, -00002.80 ], Mag [ 00018.45, 00084.00, 00108.30 ]",
    "#0000006897 NoMotion-1. Scaled. Acc [ -00039.06, 00001.46, 01000.49 ], Gyr [ 00001.34, -00000.18, -00001.10 ], Mag [ 00018.45, 00084.00, 00108.30 ]",
    "#0000006898 NoMotion-1. Scaled. Acc [ -00053.71, -00020.02, 00989.26 ], Gyr [ 00000.98, 00001.04, -00002.01 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006899 NoMotion-1. Scaled. Acc [ -00041.99, -00015.14, 00999.51 ], Gyr [ 00000.06, 00001.16, -00002.62 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006900 NoMotion-1. Scaled. Acc [ -00050.78, 00001.46, 00995.61 ], Gyr [ -00000.98, 00002.93, -00000.43 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006901 NoMotion-1. Scaled. Acc [ -00035.64, -00003.42, 00986.33 ], Gyr [ -00000.43, 00001.59, -00001.22 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006902 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00998.54 ], Gyr [ -00001.71, 00002.44, -00003.41 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006903 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00988.28 ], Gyr [ 00001.52, 00002.26, -00002.26 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006904 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00991.21 ], Gyr [ -00000.79, 00001.65, 00001.83 ], Mag [ 00019.05, 00082.95, 00106.95 ]",
    "#0000006905 NoMotion-1. Scaled. Acc [ -00038.09, -00016.11, 00998.54 ], Gyr [ -00000.43, 00002.20, -00000.91 ], Mag [ 00019.05, 00082.95, 00106.95 ]",
    "#0000006906 NoMotion-1. Scaled. Acc [ -00040.04, 00001.95, 00997.07 ], Gyr [ 00000.67, 00002.68, 00001.10 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006907 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00990.23 ], Gyr [ 00002.62, 00002.13, -00002.13 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006908 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00982.91 ], Gyr [ 00002.50, -00001.28, 00003.11 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006909 NoMotion-1. Scaled. Acc [ -00043.95, -00012.21, 00979.49 ], Gyr [ 00000.43, 00002.44, -00000.55 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006910 NoMotion-1. Scaled. Acc [ -00044.43, 00000.98, 00988.28 ], Gyr [ 00000.85, 00002.13, -00000.49 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006911 NoMotion-1. Scaled. Acc [ -00031.25, -00012.70, 00989.75 ], Gyr [ 00001.95, 00001.95, -00001.10 ], Mag [ 00019.95, 00082.80, 00106.05 ]",
    "#0000006912 NoMotion-1. Scaled. Acc [ -00045.90, -00018.07, 00993.65 ], Gyr [ 00000.79, 00001.10, -00001.95 ], Mag [ 00019.95, 00082.80, 00106.05 ]",
    "#0000006913 NoMotion-1. Scaled. Acc [ -00042.97, 00004.39, 00983.89 ], Gyr [ -00000.18, 00005.67, -00002.74 ], Mag [ 00020.70, 00083.55, 00106.80 ]",
    "#0000006914 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00996.09 ], Gyr [ 00000.00, 00001.16, -00001.83 ], Mag [ 00020.70, 00083.55, 00106.80 ]",
    "#0000006915 NoMotion-1. Scaled. Acc [ -00044.43, 00003.42, 00990.23 ], Gyr [ -00001.04, 00000.79, -00000.49 ], Mag [ 00018.75, 00085.80, 00106.80 ]",
    "#0000006916 NoMotion-1. Scaled. Acc [ -00034.18, -00002.93, 00991.21 ], Gyr [ 00000.85, -00000.61, -00000.55 ], Mag [ 00018.75, 00085.80, 00106.80 ]",
    "#0000006917 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00989.26 ], Gyr [ -00000.30, 00003.60, -00000.85 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006918 NoMotion-1. Scaled. Acc [ -00053.22, -00010.25, 01003.91 ], Gyr [ -00001.22, -00000.06, -00002.74 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006919 NoMotion-1. Scaled. Acc [ -00038.09, -00001.95, 00981.93 ], Gyr [ 00002.74, 00000.85, -00000.24 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006920 NoMotion-1. Scaled. Acc [ -00035.16, -00010.74, 00995.12 ], Gyr [ 00000.79, -00001.40, -00002.80 ], Mag [ 00019.65, 00084.90, 00108.00 ]",
    "#0000006921 NoMotion-1. Scaled. Acc [ -00042.48, -00011.23, 00994.63 ], Gyr [ -00001.52, 00003.84, -00001.59 ], Mag [ 00019.65, 00084.90, 00108.00 ]",
    "#0000006922 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00993.16 ], Gyr [ 00002.44, -00000.12, -00001.89 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000006923 NoMotion-1. Scaled. Acc [ -00039.55, -00008.30, 00997.07 ], Gyr [ 00000.43, 00002.01, 00002.32 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000006924 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 00990.23 ], Gyr [ 00000.06, 00001.59, -00002.50 ], Mag [ 00018.45, 00085.50, 00107.10 ]",
    "#0000006925 NoMotion-1. Scaled. Acc [ -00043.95, 00003.42, 00991.70 ], Gyr [ 00003.66, 00001.22, 00001.22 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006926 NoMotion-1. Scaled. Acc [ -00041.50, -00015.14, 00988.77 ], Gyr [ -00000.61, 00003.23, -00002.99 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006927 NoMotion-1. Scaled. Acc [ -00043.95, -00010.74, 00993.16 ], Gyr [ -00001.28, 00004.88, -00002.01 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006928 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00993.65 ], Gyr [ 00001.46, -00000.91, -00001.65 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006929 NoMotion-1. Scaled. Acc [ -00041.99, -00013.18, 00989.26 ], Gyr [ -00000.73, 00002.32, -00002.32 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006930 NoMotion-1. Scaled. Acc [ -00041.99, -00004.39, 00992.19 ], Gyr [ -00000.30, 00002.44, -00002.13 ], Mag [ 00019.20, 00083.25, 00108.30 ]",
    "#0000006931 NoMotion-1. Scaled. Acc [ -00055.18, -00002.44, 00982.42 ], Gyr [ 00000.30, 00001.28, -00001.22 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000006932 NoMotion-1. Scaled. Acc [ -00051.27, -00009.28, 00987.30 ], Gyr [ 00001.40, -00000.24, 00001.83 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000006933 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00993.16 ], Gyr [ -00000.12, 00005.00, -00002.44 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006934 NoMotion-1. Scaled. Acc [ -00033.20, -00005.86, 00986.33 ], Gyr [ -00000.06, 00001.83, -00003.90 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006935 NoMotion-1. Scaled. Acc [ -00034.18, -00010.74, 00988.28 ], Gyr [ 00000.30, 00001.28, 00001.95 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006936 NoMotion-1. Scaled. Acc [ -00059.57, -00004.88, 00991.21 ], Gyr [ 00001.59, 00001.89, -00002.93 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006937 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00997.07 ], Gyr [ 00000.73, -00001.22, 00000.24 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006938 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 01000.00 ], Gyr [ -00000.73, 00001.34, -00001.04 ], Mag [ 00018.45, 00084.30, 00109.35 ]",
    "#0000006939 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00981.45 ], Gyr [ 00000.91, 00002.68, 00000.18 ], Mag [ 00018.45, 00084.30, 00109.35 ]",
    "#0000006940 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00988.28 ], Gyr [ 00002.68, 00000.55, -00002.32 ], Mag [ 00019.65, 00083.70, 00107.70 ]",
    "#0000006941 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00992.68 ], Gyr [ -00000.49, -00001.89, -00001.71 ], Mag [ 00019.65, 00083.70, 00107.70 ]",
    "#0000006942 NoMotion-1. Scaled. Acc [ -00040.53, -00016.11, 00986.33 ], Gyr [ 00000.43, 00002.20, -00001.28 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000006943 NoMotion-1. Scaled. Acc [ -00050.78, -00006.35, 00991.21 ], Gyr [ 00000.61, 00003.17, -00000.61 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000006944 NoMotion-1. Scaled. Acc [ -00036.62, -00020.51, 00990.23 ], Gyr [ 00002.32, 00002.50, -00001.22 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006945 NoMotion-1. Scaled. Acc [ -00048.83, -00011.72, 00995.61 ], Gyr [ 00000.30, 00002.38, -00002.80 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006946 NoMotion-1. Scaled. Acc [ -00039.55, -00007.81, 00978.52 ], Gyr [ 00000.06, 00000.55, -00001.71 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006947 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00986.82 ], Gyr [ 00000.43, 00001.04, -00002.26 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006948 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00989.26 ], Gyr [ 00001.34, 00001.65, -00000.12 ], Mag [ 00019.65, 00084.45, 00109.80 ]",
    "#0000006949 NoMotion-1. Scaled. Acc [ -00049.32, -00020.51, 01006.84 ], Gyr [ 00001.46, 00001.77, -00002.38 ], Mag [ 00019.65, 00084.45, 00109.80 ]",
    "#0000006950 NoMotion-1. Scaled. Acc [ -00035.64, -00014.16, 01003.42 ], Gyr [ -00000.06, 00001.40, 00000.30 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000006951 NoMotion-1. Scaled. Acc [ -00045.41, -00005.86, 00983.89 ], Gyr [ 00000.85, 00001.52, -00000.67 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000006952 NoMotion-1. Scaled. Acc [ -00039.55, -00009.28, 00993.16 ], Gyr [ -00001.59, -00000.12, -00000.49 ], Mag [ 00020.10, 00083.40, 00106.95 ]",
    "#0000006953 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00980.96 ], Gyr [ 00001.34, 00003.11, -00001.10 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006954 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00989.26 ], Gyr [ 00001.22, 00000.73, -00001.40 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006955 NoMotion-1. Scaled. Acc [ -00044.43, -00003.91, 00985.35 ], Gyr [ 00002.38, 00001.71, -00002.93 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006956 NoMotion-1. Scaled. Acc [ -00042.48, 00000.49, 00987.79 ], Gyr [ 00000.79, 00002.68, -00002.26 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006957 NoMotion-1. Scaled. Acc [ -00033.69, -00004.39, 00991.70 ], Gyr [ 00000.67, -00000.61, -00002.01 ], Mag [ 00017.70, 00084.75, 00108.30 ]",
    "#0000006958 NoMotion-1. Scaled. Acc [ -00043.46, 00001.95, 00978.52 ], Gyr [ 00002.07, -00000.43, 00000.49 ], Mag [ 00017.70, 00084.75, 00108.30 ]",
    "#0000006959 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00995.12 ], Gyr [ -00001.04, 00001.46, -00000.06 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000006960 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00990.23 ], Gyr [ -00000.18, 00001.77, -00002.01 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000006961 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00991.21 ], Gyr [ 00002.38, -00001.59, -00001.89 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000006962 NoMotion-1. Scaled. Acc [ -00032.23, -00019.53, 00987.79 ], Gyr [ 00000.00, 00001.46, -00000.67 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000006963 NoMotion-1. Scaled. Acc [ -00047.36, -00017.58, 00985.84 ], Gyr [ 00002.13, 00002.07, -00001.22 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006964 NoMotion-1. Scaled. Acc [ -00049.80, -00006.35, 00980.96 ], Gyr [ -00001.04, 00000.00, 00000.30 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006965 NoMotion-1. Scaled. Acc [ -00028.32, 00000.49, 00990.72 ], Gyr [ -00000.06, 00001.22, -00001.95 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006966 NoMotion-1. Scaled. Acc [ -00043.46, 00000.00, 00987.79 ], Gyr [ 00001.59, -00000.06, -00001.52 ], Mag [ 00018.75, 00084.75, 00106.50 ]",
    "#0000006967 NoMotion-1. Scaled. Acc [ -00054.69, -00014.16, 00995.12 ], Gyr [ 00000.55, 00001.40, -00002.68 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000006968 NoMotion-1. Scaled. Acc [ -00047.85, -00011.72, 00989.26 ], Gyr [ -00001.28, -00000.12, -00003.48 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000006969 NoMotion-1. Scaled. Acc [ -00038.57, -00001.95, 00986.33 ], Gyr [ 00001.22, 00000.12, -00002.99 ], Mag [ 00019.95, 00084.75, 00107.55 ]",
    "#0000006970 NoMotion-1. Scaled. Acc [ -00051.27, -00001.95, 00992.68 ], Gyr [ 00001.59, 00000.61, 00000.55 ], Mag [ 00019.95, 00084.75, 00107.55 ]",
    "#0000006971 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00998.54 ], Gyr [ 00000.00, 00001.10, -00001.04 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006972 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00991.21 ], Gyr [ 00001.04, 00000.43, 00000.30 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006973 NoMotion-1. Scaled. Acc [ -00051.27, -00001.46, 00986.33 ], Gyr [ -00000.30, -00000.24, -00001.16 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006974 NoMotion-1. Scaled. Acc [ -00036.62, -00008.79, 00995.12 ], Gyr [ 00000.61, -00001.71, -00001.59 ], Mag [ 00021.00, 00084.75, 00107.55 ]",
    "#0000006975 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00989.26 ], Gyr [ -00000.61, 00000.06, 00000.00 ], Mag [ 00021.00, 00084.75, 00107.55 ]",
    "#0000006976 NoMotion-1. Scaled. Acc [ -00048.34, -00003.91, 00998.05 ], Gyr [ -00000.06, 00001.46, -00003.35 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000006977 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00986.33 ], Gyr [ 00000.18, 00001.95, -00002.80 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000006978 NoMotion-1. Scaled. Acc [ -00034.67, -00003.91, 00996.58 ], Gyr [ 00000.06, 00001.95, -00000.18 ], Mag [ 00020.25, 00084.30, 00107.10 ]",
    "#0000006979 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00995.61 ], Gyr [ -00001.71, 00001.59, -00002.38 ], Mag [ 00020.25, 00084.30, 00107.10 ]",
    "#0000006980 NoMotion-1. Scaled. Acc [ -00059.57, 00000.49, 00984.38 ], Gyr [ 00000.43, 00002.44, -00002.99 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006981 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00976.07 ], Gyr [ -00000.30, 00001.89, -00004.76 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006982 NoMotion-1. Scaled. Acc [ -00041.02, -00010.25, 00981.45 ], Gyr [ 00002.01, -00000.43, -00000.98 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006983 NoMotion-1. Scaled. Acc [ -00054.69, -00016.11, 00992.19 ], Gyr [ 00000.12, 00000.37, -00000.73 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006984 NoMotion-1. Scaled. Acc [ -00036.62, -00020.02, 00979.00 ], Gyr [ -00000.49, -00000.24, -00004.82 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000006985 NoMotion-1. Scaled. Acc [ -00035.16, -00008.79, 00985.35 ], Gyr [ 00000.37, 00000.37, 00000.06 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000006986 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00990.72 ], Gyr [ -00000.12, -00000.61, -00002.68 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000006987 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00991.70 ], Gyr [ 00001.46, 00002.44, -00001.52 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000006988 NoMotion-1. Scaled. Acc [ -00041.02, 00003.42, 00991.70 ], Gyr [ -00000.43, -00000.30, -00000.37 ], Mag [ 00020.70, 00083.55, 00107.10 ]",
    "#0000006989 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 00979.00 ], Gyr [ -00001.34, 00003.23, -00003.78 ], Mag [ 00020.70, 00083.55, 00107.10 ]",
    "#0000006990 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00997.56 ], Gyr [ -00000.18, 00001.83, -00000.18 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006991 NoMotion-1. Scaled. Acc [ -00040.53, -00000.98, 00987.79 ], Gyr [ 00001.16, -00000.24, -00001.77 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006992 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00983.40 ], Gyr [ 00001.28, 00002.62, -00001.34 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006993 NoMotion-1. Scaled. Acc [ -00048.34, -00008.79, 00983.40 ], Gyr [ 00001.04, 00002.44, -00000.73 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000006994 NoMotion-1. Scaled. Acc [ -00041.02, -00006.35, 00987.30 ], Gyr [ -00000.43, 00001.40, -00002.68 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000006995 NoMotion-1. Scaled. Acc [ -00052.25, -00000.98, 00997.56 ], Gyr [ -00000.24, 00001.40, -00002.74 ], Mag [ 00019.35, 00084.15, 00105.90 ]",
    "#0000006996 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00985.84 ], Gyr [ 00001.95, -00000.37, -00000.73 ], Mag [ 00019.35, 00084.15, 00105.90 ]",
    "#0000006997 NoMotion-1. Scaled. Acc [ -00048.34, -00006.35, 00992.68 ], Gyr [ 00000.79, 00000.12, -00001.40 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000006998 NoMotion-1. Scaled. Acc [ -00046.39, -00003.91, 00995.12 ], Gyr [ 00000.85, -00001.10, -00003.17 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000006999 NoMotion-1. Scaled. Acc [ -00038.57, -00022.46, 00998.05 ], Gyr [ -00000.24, 00000.00, 00000.18 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007000 NoMotion-1. Scaled. Acc [ -00048.83, -00012.70, 00988.77 ], Gyr [ 00001.04, 00000.79, -00001.28 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007001 NoMotion-1. Scaled. Acc [ -00059.08, -00010.74, 00991.21 ], Gyr [ 00001.16, -00000.49, -00004.27 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007002 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00988.77 ], Gyr [ 00000.49, 00001.40, -00001.34 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007003 NoMotion-1. Scaled. Acc [ -00040.53, -00010.74, 00983.89 ], Gyr [ 00000.55, 00000.98, -00002.38 ], Mag [ 00021.75, 00084.75, 00108.60 ]",
    "#0000007004 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00992.68 ], Gyr [ 00001.46, 00000.91, -00000.37 ], Mag [ 00021.75, 00084.75, 00108.60 ]",
    "#0000007005 NoMotion-1. Scaled. Acc [ -00045.41, -00011.23, 00981.45 ], Gyr [ 00001.28, -00000.98, 00001.28 ], Mag [ 00019.05, 00082.95, 00107.40 ]",
    "#0000007006 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00991.70 ], Gyr [ -00000.85, 00001.95, -00000.18 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000007007 NoMotion-1. Scaled. Acc [ -00037.60, -00021.00, 00987.30 ], Gyr [ -00002.20, 00004.27, -00004.39 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000007008 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 00993.65 ], Gyr [ 00001.40, 00002.44, -00001.65 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007009 NoMotion-1. Scaled. Acc [ -00059.08, -00000.49, 00994.14 ], Gyr [ 00000.37, 00001.04, -00001.16 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007010 NoMotion-1. Scaled. Acc [ -00057.13, -00012.70, 00984.38 ], Gyr [ 00001.04, -00000.37, 00000.30 ], Mag [ 00019.35, 00082.65, 00106.20 ]",
    "#0000007011 NoMotion-1. Scaled. Acc [ -00033.69, 00006.35, 00988.28 ], Gyr [ 00001.59, -00001.83, -00003.66 ], Mag [ 00019.35, 00082.65, 00106.20 ]",
    "#0000007012 NoMotion-1. Scaled. Acc [ -00041.99, -00007.32, 00984.86 ], Gyr [ -00001.16, 00003.54, -00002.01 ], Mag [ 00018.15, 00084.00, 00107.55 ]",
    "#0000007013 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00996.58 ], Gyr [ -00001.89, 00001.34, 00000.37 ], Mag [ 00018.15, 00084.00, 00107.55 ]",
    "#0000007014 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00995.12 ], Gyr [ 00000.91, 00002.80, -00001.89 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000007015 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.77 ], Gyr [ 00000.37, 00001.34, -00002.68 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000007016 NoMotion-1. Scaled. Acc [ -00046.88, -00012.21, 00985.35 ], Gyr [ 00000.18, 00000.85, 00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007017 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 01000.49 ], Gyr [ 00002.80, -00000.91, -00003.60 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007018 NoMotion-1. Scaled. Acc [ -00036.62, -00005.86, 01000.98 ], Gyr [ 00002.07, -00001.34, -00000.79 ], Mag [ 00018.75, 00084.30, 00106.80 ]",
    "#0000007019 NoMotion-1. Scaled. Acc [ -00040.53, -00008.79, 00986.82 ], Gyr [ 00001.40, 00000.55, 00000.18 ], Mag [ 00018.75, 00084.30, 00106.80 ]",
    "#0000007020 NoMotion-1. Scaled. Acc [ -00053.22, -00012.70, 00999.02 ], Gyr [ 00001.71, 00004.33, -00001.16 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007021 NoMotion-1. Scaled. Acc [ -00038.57, -00026.86, 00987.30 ], Gyr [ 00001.04, 00000.00, 00000.79 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007022 NoMotion-1. Scaled. Acc [ -00032.23, 00000.00, 00991.70 ], Gyr [ 00000.73, -00001.10, -00001.89 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007023 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00984.38 ], Gyr [ 00000.49, 00001.40, -00001.40 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007024 NoMotion-1. Scaled. Acc [ -00036.62, 00000.98, 00989.26 ], Gyr [ 00001.46, 00004.51, -00001.34 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007025 NoMotion-1. Scaled. Acc [ -00036.62, -00007.32, 00993.16 ], Gyr [ 00001.04, 00001.83, 00000.43 ], Mag [ 00021.15, 00083.70, 00107.70 ]",
    "#0000007026 NoMotion-1. Scaled. Acc [ -00042.48, 00009.77, 00989.75 ], Gyr [ -00000.06, 00001.89, -00002.56 ], Mag [ 00021.15, 00083.70, 00107.70 ]",
    "#0000007027 NoMotion-1. Scaled. Acc [ -00050.78, 00000.49, 00999.51 ], Gyr [ -00000.98, -00000.49, -00002.07 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000007028 NoMotion-1. Scaled. Acc [ -00041.99, -00002.93, 01000.98 ], Gyr [ -00000.18, 00004.70, 00000.37 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000007029 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 01000.49 ], Gyr [ 00001.95, 00001.65, -00000.79 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007030 NoMotion-1. Scaled. Acc [ -00038.09, 00000.00, 00993.16 ], Gyr [ -00000.85, 00000.30, -00000.85 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007031 NoMotion-1. Scaled. Acc [ -00049.32, 00000.00, 00990.23 ], Gyr [ -00001.04, -00000.49, -00003.23 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007032 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00987.79 ], Gyr [ 00000.85, 00000.00, -00000.73 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007033 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 00990.23 ], Gyr [ 00000.91, -00000.73, -00000.06 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007034 NoMotion-1. Scaled. Acc [ -00042.48, -00016.60, 00999.51 ], Gyr [ 00002.56, 00000.91, -00000.18 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007035 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 00987.79 ], Gyr [ 00001.89, 00002.93, -00003.90 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007036 NoMotion-1. Scaled. Acc [ -00041.50, -00016.11, 00993.16 ], Gyr [ -00000.12, 00000.37, 00000.91 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007037 NoMotion-1. Scaled. Acc [ -00050.29, -00006.84, 00991.70 ], Gyr [ 00002.44, 00000.30, -00003.11 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007038 NoMotion-1. Scaled. Acc [ -00037.11, -00014.16, 00982.91 ], Gyr [ 00001.16, 00002.38, -00000.12 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007039 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00988.28 ], Gyr [ -00001.28, 00002.07, -00002.26 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007040 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00988.28 ], Gyr [ -00000.43, 00000.18, -00000.67 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007041 NoMotion-1. Scaled. Acc [ -00048.83, 00007.32, 01000.49 ], Gyr [ 00001.04, 00000.91, -00001.34 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007042 NoMotion-1. Scaled. Acc [ -00047.85, 00002.93, 00991.70 ], Gyr [ 00000.73, 00002.32, -00002.13 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007043 NoMotion-1. Scaled. Acc [ -00042.48, -00007.81, 01000.49 ], Gyr [ -00001.40, 00001.83, -00002.07 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000007044 NoMotion-1. Scaled. Acc [ -00052.73, 00002.44, 00985.84 ], Gyr [ -00000.24, 00002.87, -00004.39 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000007045 NoMotion-1. Scaled. Acc [ -00044.92, -00015.14, 00993.16 ], Gyr [ 00001.16, -00000.37, -00001.34 ], Mag [ 00018.45, 00083.25, 00107.55 ]",
    "#0000007046 NoMotion-1. Scaled. Acc [ -00037.11, -00003.91, 00988.28 ], Gyr [ 00000.91, 00002.32, -00001.77 ], Mag [ 00018.45, 00083.25, 00107.55 ]",
    "#0000007047 NoMotion-1. Scaled. Acc [ -00047.85, 00002.44, 00999.51 ], Gyr [ 00000.18, 00001.83, -00003.05 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007048 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00987.30 ], Gyr [ -00000.73, 00002.07, -00001.40 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007049 NoMotion-1. Scaled. Acc [ -00045.90, -00005.86, 00994.14 ], Gyr [ -00000.06, 00001.89, -00000.85 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007050 NoMotion-1. Scaled. Acc [ -00036.62, -00007.32, 00990.72 ], Gyr [ 00000.00, 00000.37, 00000.30 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007051 NoMotion-1. Scaled. Acc [ -00053.22, 00008.30, 01000.98 ], Gyr [ 00000.55, 00002.68, -00000.43 ], Mag [ 00018.45, 00083.55, 00107.85 ]",
    "#0000007052 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00985.35 ], Gyr [ -00000.67, 00000.73, -00000.12 ], Mag [ 00018.45, 00083.55, 00107.85 ]",
    "#0000007053 NoMotion-1. Scaled. Acc [ -00041.99, -00001.95, 01003.42 ], Gyr [ -00000.55, 00001.10, -00001.95 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000007054 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00987.30 ], Gyr [ -00000.06, 00001.10, -00000.06 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000007055 NoMotion-1. Scaled. Acc [ -00043.46, -00013.18, 00996.58 ], Gyr [ 00001.89, 00001.28, -00001.04 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000007056 NoMotion-1. Scaled. Acc [ -00043.46, -00001.46, 00985.35 ], Gyr [ 00001.52, 00000.55, -00003.41 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000007057 NoMotion-1. Scaled. Acc [ -00052.25, -00008.30, 00990.23 ], Gyr [ 00000.91, -00000.67, 00001.16 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007058 NoMotion-1. Scaled. Acc [ -00051.27, -00004.88, 00984.38 ], Gyr [ 00000.24, 00000.55, 00000.91 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007059 NoMotion-1. Scaled. Acc [ -00037.60, -00011.72, 00988.77 ], Gyr [ 00000.30, 00001.28, -00001.40 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007060 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00994.14 ], Gyr [ -00000.06, 00001.95, -00000.43 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007061 NoMotion-1. Scaled. Acc [ -00048.34, -00018.07, 00991.70 ], Gyr [ 00003.05, 00002.87, -00000.91 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000007062 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00986.82 ], Gyr [ 00000.37, 00002.32, -00001.52 ], Mag [ 00019.95, 00083.55, 00108.60 ]",
    "#0000007063 NoMotion-1. Scaled. Acc [ -00032.23, -00011.72, 00993.16 ], Gyr [ 00000.55, 00002.50, -00000.06 ], Mag [ 00019.95, 00083.55, 00108.60 ]",
    "#0000007064 NoMotion-1. Scaled. Acc [ -00054.20, -00021.97, 00986.82 ], Gyr [ 00000.30, 00000.79, -00002.13 ], Mag [ 00020.85, 00081.15, 00108.00 ]",
    "#0000007065 NoMotion-1. Scaled. Acc [ -00057.13, -00002.93, 00991.21 ], Gyr [ -00000.67, 00001.83, -00001.22 ], Mag [ 00020.85, 00081.15, 00108.00 ]",
    "#0000007066 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00991.21 ], Gyr [ 00000.12, 00000.85, -00002.26 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007067 NoMotion-1. Scaled. Acc [ -00046.39, -00000.98, 00998.54 ], Gyr [ 00000.12, 00002.93, -00001.40 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007068 NoMotion-1. Scaled. Acc [ -00038.57, -00006.35, 00998.54 ], Gyr [ 00000.73, 00000.85, -00000.43 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007069 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00997.56 ], Gyr [ 00000.18, 00003.29, -00002.68 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007070 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00998.05 ], Gyr [ 00000.79, 00003.78, -00000.06 ], Mag [ 00020.70, 00083.55, 00107.55 ]",
    "#0000007071 NoMotion-1. Scaled. Acc [ -00039.06, -00012.21, 00989.75 ], Gyr [ 00000.43, 00000.73, 00000.00 ], Mag [ 00020.70, 00083.55, 00107.55 ]",
    "#0000007072 NoMotion-1. Scaled. Acc [ -00050.78, -00012.70, 00984.38 ], Gyr [ 00000.91, 00003.17, 00001.34 ], Mag [ 00018.60, 00082.20, 00108.00 ]",
    "#0000007073 NoMotion-1. Scaled. Acc [ -00045.41, -00007.81, 00989.75 ], Gyr [ 00000.30, 00001.89, -00002.20 ], Mag [ 00018.60, 00082.20, 00108.00 ]",
    "#0000007074 NoMotion-1. Scaled. Acc [ -00043.46, -00024.90, 01000.00 ], Gyr [ 00001.40, 00000.67, -00002.32 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007075 NoMotion-1. Scaled. Acc [ -00031.74, 00000.98, 00987.79 ], Gyr [ 00000.24, -00000.85, 00000.91 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007076 NoMotion-1. Scaled. Acc [ -00041.50, -00008.30, 00991.70 ], Gyr [ -00000.55, 00002.50, -00001.46 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007077 NoMotion-1. Scaled. Acc [ -00035.16, 00001.46, 00995.61 ], Gyr [ 00002.68, 00002.50, -00000.55 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007078 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00989.26 ], Gyr [ 00000.43, 00002.68, -00003.84 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007079 NoMotion-1. Scaled. Acc [ -00039.06, -00002.93, 00988.77 ], Gyr [ -00000.37, 00000.61, -00000.91 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007080 NoMotion-1. Scaled. Acc [ -00048.83, -00008.30, 00999.51 ], Gyr [ 00000.43, 00001.22, -00000.55 ], Mag [ 00018.60, 00082.65, 00108.00 ]",
    "#0000007081 NoMotion-1. Scaled. Acc [ -00048.83, -00006.84, 00993.65 ], Gyr [ 00000.00, 00000.00, -00001.52 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007082 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00989.26 ], Gyr [ -00001.77, 00001.40, -00002.62 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007083 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00997.07 ], Gyr [ 00000.73, 00001.16, -00000.55 ], Mag [ 00018.60, 00084.15, 00108.00 ]",
    "#0000007084 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00984.86 ], Gyr [ -00000.98, 00000.43, -00003.29 ], Mag [ 00018.60, 00084.15, 00108.00 ]",
    "#0000007085 NoMotion-1. Scaled. Acc [ -00046.88, -00007.81, 00988.28 ], Gyr [ 00001.46, 00000.85, -00000.73 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007086 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00987.30 ], Gyr [ -00001.10, 00001.59, 00000.00 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007087 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00990.23 ], Gyr [ 00000.18, 00000.06, 00000.00 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007088 NoMotion-1. Scaled. Acc [ -00033.69, -00024.90, 00988.77 ], Gyr [ 00000.06, 00001.40, -00002.80 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007089 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 00984.86 ], Gyr [ 00000.79, 00004.02, -00001.46 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007090 NoMotion-1. Scaled. Acc [ -00044.43, -00009.28, 01004.88 ], Gyr [ 00000.00, 00001.10, -00001.28 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007091 NoMotion-1. Scaled. Acc [ -00052.25, -00007.32, 00990.72 ], Gyr [ 00001.83, 00001.16, -00001.95 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007092 NoMotion-1. Scaled. Acc [ -00041.50, -00010.74, 00995.61 ], Gyr [ -00001.10, 00001.71, -00000.06 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007093 NoMotion-1. Scaled. Acc [ -00041.02, -00008.30, 00983.40 ], Gyr [ 00000.49, 00005.37, 00000.49 ], Mag [ 00019.95, 00084.30, 00106.50 ]",
    "#0000007094 NoMotion-1. Scaled. Acc [ -00044.43, -00012.70, 00992.19 ], Gyr [ -00000.12, 00003.72, -00002.80 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007095 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00993.16 ], Gyr [ 00002.01, 00000.79, -00000.67 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007096 NoMotion-1. Scaled. Acc [ -00038.57, -00020.02, 00986.82 ], Gyr [ 00001.46, 00000.00, -00000.73 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007097 NoMotion-1. Scaled. Acc [ -00049.80, -00004.39, 00996.58 ], Gyr [ -00000.30, 00001.95, -00002.44 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007098 NoMotion-1. Scaled. Acc [ -00039.06, -00014.16, 00995.61 ], Gyr [ 00000.73, 00002.99, -00000.98 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007099 NoMotion-1. Scaled. Acc [ -00051.76, -00002.44, 00990.72 ], Gyr [ 00000.67, 00002.32, -00000.37 ], Mag [ 00018.30, 00083.70, 00106.95 ]",
    "#0000007100 NoMotion-1. Scaled. Acc [ -00051.27, -00000.98, 00984.86 ], Gyr [ 00001.22, 00000.85, -00002.44 ], Mag [ 00018.30, 00083.70, 00106.95 ]",
    "#0000007101 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00995.12 ], Gyr [ 00001.59, 00000.98, -00001.65 ], Mag [ 00018.60, 00083.40, 00105.90 ]",
    "#0000007102 NoMotion-1. Scaled. Acc [ -00032.71, -00000.98, 00986.82 ], Gyr [ -00000.91, 00002.20, -00002.32 ], Mag [ 00018.60, 00083.40, 00105.90 ]",
    "#0000007103 NoMotion-1. Scaled. Acc [ -00054.20, -00009.77, 00982.91 ], Gyr [ 00000.67, -00000.49, -00001.34 ], Mag [ 00021.15, 00084.15, 00108.00 ]",
    "#0000007104 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00992.19 ], Gyr [ 00000.24, 00003.35, -00002.87 ], Mag [ 00021.15, 00084.15, 00108.00 ]",
    "#0000007105 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00985.84 ], Gyr [ -00000.18, 00000.37, -00002.93 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007106 NoMotion-1. Scaled. Acc [ -00045.41, -00012.21, 00991.70 ], Gyr [ -00000.98, 00001.77, -00001.34 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007107 NoMotion-1. Scaled. Acc [ -00046.88, -00002.93, 00987.30 ], Gyr [ -00000.18, 00001.77, 00000.79 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007108 NoMotion-1. Scaled. Acc [ -00041.50, -00005.37, 00988.77 ], Gyr [ 00000.79, 00001.16, -00000.91 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007109 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00988.28 ], Gyr [ -00001.89, 00001.95, -00003.05 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007110 NoMotion-1. Scaled. Acc [ -00037.60, -00012.21, 00998.05 ], Gyr [ 00002.01, -00000.85, -00000.49 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007111 NoMotion-1. Scaled. Acc [ -00046.39, -00011.72, 01001.95 ], Gyr [ 00000.91, -00001.16, 00000.24 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007112 NoMotion-1. Scaled. Acc [ -00043.46, -00009.28, 00985.35 ], Gyr [ 00001.71, 00001.10, -00002.20 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007113 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00994.14 ], Gyr [ -00000.67, -00001.22, -00000.43 ], Mag [ 00018.75, 00083.55, 00105.75 ]",
    "#0000007114 NoMotion-1. Scaled. Acc [ -00032.71, -00006.35, 00988.28 ], Gyr [ 00001.10, 00002.93, -00002.32 ], Mag [ 00018.75, 00083.55, 00105.75 ]",
    "#0000007115 NoMotion-1. Scaled. Acc [ -00029.30, -00021.48, 00977.05 ], Gyr [ 00001.59, -00001.46, -00001.59 ], Mag [ 00020.40, 00085.20, 00106.65 ]",
    "#0000007116 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00990.72 ], Gyr [ 00000.43, 00002.68, 00000.61 ], Mag [ 00020.40, 00085.20, 00106.65 ]",
    "#0000007117 NoMotion-1. Scaled. Acc [ -00035.64, -00011.23, 00985.35 ], Gyr [ -00000.24, 00002.68, -00002.93 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000007118 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00982.42 ], Gyr [ 00000.85, -00000.67, -00001.59 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000007119 NoMotion-1. Scaled. Acc [ -00049.32, -00003.42, 00986.82 ], Gyr [ 00000.91, -00000.43, -00001.10 ], Mag [ 00020.10, 00084.45, 00106.65 ]",
    "#0000007120 NoMotion-1. Scaled. Acc [ -00045.90, -00017.09, 00995.61 ], Gyr [ -00000.06, 00002.01, -00002.20 ], Mag [ 00020.10, 00084.45, 00106.65 ]",
    "#0000007121 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00999.51 ], Gyr [ -00001.22, -00000.91, -00000.37 ], Mag [ 00020.85, 00082.95, 00107.40 ]",
    "#0000007122 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00986.82 ], Gyr [ 00002.74, 00000.91, -00001.59 ], Mag [ 00020.85, 00082.95, 00107.40 ]",
    "#0000007123 NoMotion-1. Scaled. Acc [ -00047.85, -00010.74, 00998.05 ], Gyr [ -00000.30, 00000.55, 00000.30 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007124 NoMotion-1. Scaled. Acc [ -00048.83, -00017.58, 00998.54 ], Gyr [ 00001.89, 00000.00, 00001.46 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007125 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 01006.84 ], Gyr [ -00001.52, 00002.01, -00002.93 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007126 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 01000.49 ], Gyr [ 00001.34, 00000.37, -00001.04 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007127 NoMotion-1. Scaled. Acc [ -00036.62, -00008.79, 00991.21 ], Gyr [ 00000.30, 00000.55, -00001.40 ], Mag [ 00020.10, 00085.20, 00108.00 ]",
    "#0000007128 NoMotion-1. Scaled. Acc [ -00040.04, -00013.18, 00991.21 ], Gyr [ -00000.49, 00001.71, -00000.55 ], Mag [ 00020.10, 00085.20, 00108.00 ]",
    "#0000007129 NoMotion-1. Scaled. Acc [ -00035.64, -00006.84, 00987.79 ], Gyr [ 00000.06, 00000.06, -00002.20 ], Mag [ 00018.60, 00083.40, 00109.05 ]",
    "#0000007130 NoMotion-1. Scaled. Acc [ -00030.27, 00000.98, 00990.72 ], Gyr [ 00001.40, 00000.55, -00002.13 ], Mag [ 00018.60, 00083.40, 00109.05 ]",
    "#0000007131 NoMotion-1. Scaled. Acc [ -00038.09, -00022.46, 00991.21 ], Gyr [ 00001.52, 00003.05, -00002.38 ], Mag [ 00020.10, 00085.20, 00108.45 ]",
    "#0000007132 NoMotion-1. Scaled. Acc [ -00040.53, -00002.44, 00990.72 ], Gyr [ 00000.85, 00001.65, -00002.38 ], Mag [ 00020.10, 00085.20, 00108.45 ]",
    "#0000007133 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.28 ], Gyr [ -00000.91, 00002.01, 00000.12 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007134 NoMotion-1. Scaled. Acc [ -00048.34, 00001.46, 01001.46 ], Gyr [ 00001.16, 00001.22, -00002.68 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007135 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00993.16 ], Gyr [ 00002.26, 00002.87, -00000.55 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007136 NoMotion-1. Scaled. Acc [ -00047.85, -00017.58, 00989.75 ], Gyr [ -00000.06, -00001.34, -00000.91 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007137 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 00988.77 ], Gyr [ 00002.01, 00001.65, -00000.98 ], Mag [ 00020.70, 00084.30, 00106.80 ]",
    "#0000007138 NoMotion-1. Scaled. Acc [ -00047.85, -00015.62, 00991.21 ], Gyr [ 00002.20, 00000.98, -00002.44 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007139 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 01001.95 ], Gyr [ -00001.16, 00000.55, -00002.50 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007140 NoMotion-1. Scaled. Acc [ -00040.53, -00003.91, 00990.23 ], Gyr [ 00000.91, 00000.98, -00001.65 ], Mag [ 00018.75, 00085.80, 00108.30 ]",
    "#0000007141 NoMotion-1. Scaled. Acc [ -00043.46, -00005.86, 00996.09 ], Gyr [ 00000.91, 00002.62, -00002.44 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007142 NoMotion-1. Scaled. Acc [ -00055.18, -00003.91, 00994.14 ], Gyr [ 00000.98, 00000.98, 00000.24 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007143 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00994.14 ], Gyr [ 00000.91, -00001.04, -00001.34 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007144 NoMotion-1. Scaled. Acc [ -00045.41, -00012.70, 00995.61 ], Gyr [ -00000.67, 00001.34, -00002.01 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007145 NoMotion-1. Scaled. Acc [ -00052.25, -00005.86, 00986.33 ], Gyr [ 00000.79, 00001.95, -00000.61 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007146 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 00981.45 ], Gyr [ -00000.06, 00000.43, -00000.55 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007147 NoMotion-1. Scaled. Acc [ -00045.41, -00009.28, 00983.89 ], Gyr [ 00002.87, 00001.34, -00003.05 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007148 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 01006.35 ], Gyr [ 00001.59, 00000.49, 00000.43 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007149 NoMotion-1. Scaled. Acc [ -00038.57, -00020.02, 00985.84 ], Gyr [ 00000.12, -00001.83, 00000.85 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007150 NoMotion-1. Scaled. Acc [ -00038.57, 00000.98, 00980.47 ], Gyr [ -00001.28, 00000.79, -00000.79 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007151 NoMotion-1. Scaled. Acc [ -00055.18, -00007.32, 00992.19 ], Gyr [ 00000.06, 00000.61, -00002.93 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007152 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 01000.00 ], Gyr [ 00000.18, 00003.96, -00002.07 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007153 NoMotion-1. Scaled. Acc [ -00049.32, -00008.30, 00989.75 ], Gyr [ 00002.01, 00000.43, -00001.10 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000007154 NoMotion-1. Scaled. Acc [ -00050.29, -00013.18, 00989.75 ], Gyr [ -00000.18, 00001.04, -00002.68 ], Mag [ 00019.05, 00085.20, 00107.40 ]",
    "#0000007155 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00988.77 ], Gyr [ 00001.04, 00000.79, -00002.13 ], Mag [ 00019.05, 00085.20, 00107.40 ]",
    "#0000007156 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 01000.00 ], Gyr [ 00001.16, 00001.89, 00000.85 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000007157 NoMotion-1. Scaled. Acc [ -00043.95, 00010.74, 00991.21 ], Gyr [ 00000.30, 00001.10, -00002.38 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000007158 NoMotion-1. Scaled. Acc [ -00045.90, -00002.44, 00995.12 ], Gyr [ -00001.65, 00002.13, -00001.52 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007159 NoMotion-1. Scaled. Acc [ -00029.30, -00009.28, 00986.82 ], Gyr [ 00000.30, 00005.06, -00002.07 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007160 NoMotion-1. Scaled. Acc [ -00037.60, -00016.11, 01002.93 ], Gyr [ 00001.22, -00000.18, -00001.89 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007161 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00992.68 ], Gyr [ 00001.04, 00003.66, -00000.18 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000007162 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00999.51 ], Gyr [ 00000.06, 00001.77, -00000.43 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000007163 NoMotion-1. Scaled. Acc [ -00046.39, 00000.98, 00984.38 ], Gyr [ -00001.71, 00001.16, -00000.73 ], Mag [ 00019.35, 00085.65, 00108.45 ]",
    "#0000007164 NoMotion-1. Scaled. Acc [ -00050.29, -00011.23, 00989.26 ], Gyr [ 00000.55, 00002.56, -00001.71 ], Mag [ 00019.35, 00085.65, 00108.45 ]",
    "#0000007165 NoMotion-1. Scaled. Acc [ -00029.30, -00013.18, 00993.65 ], Gyr [ 00001.04, 00001.46, -00003.48 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000007166 NoMotion-1. Scaled. Acc [ -00034.67, 00004.88, 00987.30 ], Gyr [ -00000.24, -00000.98, -00001.10 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000007167 NoMotion-1. Scaled. Acc [ -00037.60, -00001.46, 00992.19 ], Gyr [ -00000.73, 00001.04, -00000.30 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007168 NoMotion-1. Scaled. Acc [ -00040.53, -00007.32, 00991.70 ], Gyr [ -00000.85, 00001.77, -00001.22 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007169 NoMotion-1. Scaled. Acc [ -00052.73, -00017.58, 00990.72 ], Gyr [ 00000.12, 00001.52, -00001.16 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007170 NoMotion-1. Scaled. Acc [ -00042.97, -00006.84, 00989.26 ], Gyr [ 00001.04, 00000.49, 00000.12 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007171 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00990.72 ], Gyr [ 00001.77, 00000.79, -00001.22 ], Mag [ 00018.60, 00082.65, 00107.70 ]",
    "#0000007172 NoMotion-1. Scaled. Acc [ -00051.76, -00006.35, 00993.16 ], Gyr [ -00000.24, 00003.66, 00000.00 ], Mag [ 00018.60, 00082.65, 00107.70 ]",
    "#0000007173 NoMotion-1. Scaled. Acc [ -00031.25, -00001.46, 00986.82 ], Gyr [ 00000.67, 00000.98, -00002.68 ], Mag [ 00019.35, 00082.65, 00107.70 ]",
    "#0000007174 NoMotion-1. Scaled. Acc [ -00037.11, -00002.44, 00996.58 ], Gyr [ -00001.52, 00002.26, -00000.79 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007175 NoMotion-1. Scaled. Acc [ -00041.02, 00002.44, 00994.63 ], Gyr [ -00001.10, 00005.79, -00003.48 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007176 NoMotion-1. Scaled. Acc [ -00047.85, -00003.42, 00988.28 ], Gyr [ 00000.98, 00002.74, -00002.62 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007177 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00981.93 ], Gyr [ 00002.26, 00002.20, -00001.34 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007178 NoMotion-1. Scaled. Acc [ -00035.64, -00012.70, 00985.84 ], Gyr [ -00000.91, 00003.96, -00003.78 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007179 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00990.72 ], Gyr [ 00001.77, 00001.10, -00000.79 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007180 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00998.05 ], Gyr [ 00000.06, 00002.32, -00001.77 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007181 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00992.68 ], Gyr [ 00000.00, -00001.40, -00000.18 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007182 NoMotion-1. Scaled. Acc [ -00036.13, -00009.28, 00979.98 ], Gyr [ 00000.73, 00000.55, -00000.12 ], Mag [ 00020.40, 00082.95, 00106.65 ]",
    "#0000007183 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00980.96 ], Gyr [ 00000.85, 00001.04, -00001.46 ], Mag [ 00020.40, 00082.95, 00106.65 ]",
    "#0000007184 NoMotion-1. Scaled. Acc [ -00045.90, -00015.14, 00989.75 ], Gyr [ 00002.99, 00001.52, -00002.99 ], Mag [ 00017.70, 00083.25, 00107.85 ]",
    "#0000007185 NoMotion-1. Scaled. Acc [ -00043.95, 00002.93, 00996.09 ], Gyr [ 00000.61, 00000.67, -00001.40 ], Mag [ 00017.70, 00083.25, 00107.85 ]",
    "#0000007186 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 00974.12 ], Gyr [ -00001.46, -00000.98, -00002.13 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007187 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 00983.89 ], Gyr [ -00000.85, 00000.30, -00003.17 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007188 NoMotion-1. Scaled. Acc [ -00047.85, -00004.88, 00982.91 ], Gyr [ 00000.06, -00000.91, -00001.28 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007189 NoMotion-1. Scaled. Acc [ -00030.76, -00001.46, 00995.61 ], Gyr [ 00001.40, -00000.12, -00000.49 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007190 NoMotion-1. Scaled. Acc [ -00040.53, -00020.02, 00991.70 ], Gyr [ -00000.67, 00001.34, -00001.71 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000007191 NoMotion-1. Scaled. Acc [ -00047.36, -00010.74, 00988.28 ], Gyr [ -00000.49, 00004.33, -00002.99 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000007192 NoMotion-1. Scaled. Acc [ -00045.41, -00003.91, 00989.26 ], Gyr [ -00000.49, 00000.43, -00000.55 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007193 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00983.89 ], Gyr [ 00001.28, 00003.84, -00004.09 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007194 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00993.16 ], Gyr [ -00000.85, 00002.07, -00003.17 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007195 NoMotion-1. Scaled. Acc [ -00042.97, -00010.74, 00996.09 ], Gyr [ 00000.98, 00001.83, -00001.59 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007196 NoMotion-1. Scaled. Acc [ -00040.53, 00002.44, 00997.56 ], Gyr [ 00001.04, 00001.52, -00001.71 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007197 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00987.30 ], Gyr [ 00000.55, 00000.30, -00002.20 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007198 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 00984.86 ], Gyr [ 00002.50, 00000.91, -00002.01 ], Mag [ 00018.45, 00083.55, 00106.05 ]",
    "#0000007199 NoMotion-1. Scaled. Acc [ -00034.18, -00012.70, 00981.45 ], Gyr [ 00000.73, 00006.59, -00001.83 ], Mag [ 00018.45, 00083.55, 00106.05 ]",
    "#0000007200 NoMotion-1. Scaled. Acc [ -00046.88, -00008.30, 00979.49 ], Gyr [ 00000.12, 00003.05, -00001.89 ], Mag [ 00019.95, 00084.00, 00106.80 ]",
    "#0000007201 NoMotion-1. Scaled. Acc [ -00046.88, -00015.62, 00984.86 ], Gyr [ 00000.61, 00000.98, -00002.87 ], Mag [ 00019.95, 00084.00, 00106.80 ]",
    "#0000007202 NoMotion-1. Scaled. Acc [ -00040.04, -00011.23, 01002.93 ], Gyr [ 00002.44, 00000.00, -00000.43 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000007203 NoMotion-1. Scaled. Acc [ -00048.83, -00015.14, 01006.84 ], Gyr [ 00000.06, 00002.93, -00003.23 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000007204 NoMotion-1. Scaled. Acc [ -00045.90, -00008.79, 00985.84 ], Gyr [ -00000.18, 00004.82, -00002.20 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007205 NoMotion-1. Scaled. Acc [ -00044.43, -00005.37, 00986.82 ], Gyr [ -00000.30, -00000.24, -00000.18 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007206 NoMotion-1. Scaled. Acc [ -00042.97, -00000.49, 00989.75 ], Gyr [ -00000.24, 00002.99, -00001.28 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007207 NoMotion-1. Scaled. Acc [ -00052.25, -00009.77, 00997.07 ], Gyr [ 00000.30, 00002.56, -00002.07 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007208 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00995.61 ], Gyr [ -00001.22, 00002.07, -00002.56 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007209 NoMotion-1. Scaled. Acc [ -00037.11, -00006.84, 00984.38 ], Gyr [ 00000.00, 00000.00, -00000.85 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007210 NoMotion-1. Scaled. Acc [ -00045.41, -00011.23, 00983.40 ], Gyr [ 00000.73, -00000.79, -00001.22 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007211 NoMotion-1. Scaled. Acc [ -00043.95, 00004.88, 00986.33 ], Gyr [ 00000.67, 00002.20, -00000.18 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000007212 NoMotion-1. Scaled. Acc [ -00039.55, -00014.65, 00988.77 ], Gyr [ 00001.40, 00000.30, 00000.06 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007213 NoMotion-1. Scaled. Acc [ -00029.79, -00002.44, 00991.70 ], Gyr [ -00001.34, 00002.44, -00001.04 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007214 NoMotion-1. Scaled. Acc [ -00038.57, -00006.84, 00998.54 ], Gyr [ -00000.24, 00001.28, -00001.16 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007215 NoMotion-1. Scaled. Acc [ -00038.09, -00021.48, 00994.63 ], Gyr [ -00001.46, 00000.37, -00002.80 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007216 NoMotion-1. Scaled. Acc [ -00045.90, -00012.21, 00990.72 ], Gyr [ 00001.71, 00003.05, -00003.23 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007217 NoMotion-1. Scaled. Acc [ -00045.41, -00004.39, 00989.26 ], Gyr [ 00000.73, 00002.20, -00000.79 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007218 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00994.63 ], Gyr [ 00000.98, 00000.98, -00003.23 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007219 NoMotion-1. Scaled. Acc [ -00050.29, -00002.44, 00997.56 ], Gyr [ 00000.98, 00003.17, -00002.20 ], Mag [ 00020.25, 00084.75, 00106.80 ]",
    "#0000007220 NoMotion-1. Scaled. Acc [ -00048.34, -00003.42, 00996.58 ], Gyr [ 00001.52, -00000.61, -00000.98 ], Mag [ 00020.25, 00084.75, 00106.80 ]",
    "#0000007221 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 01001.46 ], Gyr [ 00001.28, 00001.89, 00001.77 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007222 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00999.51 ], Gyr [ -00000.37, 00001.83, -00001.89 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007223 NoMotion-1. Scaled. Acc [ -00025.88, -00013.67, 00986.33 ], Gyr [ 00000.98, -00000.49, -00000.12 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007224 NoMotion-1. Scaled. Acc [ -00044.92, -00009.77, 00993.16 ], Gyr [ 00001.22, 00000.37, -00000.12 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007225 NoMotion-1. Scaled. Acc [ -00035.64, -00011.72, 00981.45 ], Gyr [ 00001.16, 00002.56, -00001.89 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007226 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00988.28 ], Gyr [ -00001.22, 00003.29, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007227 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00989.26 ], Gyr [ 00000.18, 00003.35, 00000.00 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000007228 NoMotion-1. Scaled. Acc [ -00052.25, -00013.67, 00993.16 ], Gyr [ -00000.18, 00003.84, -00000.37 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000007229 NoMotion-1. Scaled. Acc [ -00048.34, -00021.97, 00984.38 ], Gyr [ -00001.10, 00001.65, 00000.00 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007230 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00998.05 ], Gyr [ 00001.40, 00001.83, -00002.32 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007231 NoMotion-1. Scaled. Acc [ -00037.60, 00000.98, 00995.12 ], Gyr [ 00001.28, -00000.98, -00001.89 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007232 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00989.75 ], Gyr [ -00001.04, 00003.41, -00001.95 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007233 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 00984.38 ], Gyr [ 00000.18, 00002.68, -00002.44 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007234 NoMotion-1. Scaled. Acc [ -00036.62, -00009.77, 00990.72 ], Gyr [ 00002.44, -00002.07, 00000.79 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000007235 NoMotion-1. Scaled. Acc [ -00041.02, 00004.88, 00993.65 ], Gyr [ 00001.52, 00001.10, -00000.61 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000007236 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00986.33 ], Gyr [ -00003.23, 00002.38, -00002.80 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007237 NoMotion-1. Scaled. Acc [ -00044.92, 00003.91, 00994.63 ], Gyr [ -00000.18, 00001.89, -00000.12 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007238 NoMotion-1. Scaled. Acc [ -00031.74, -00003.42, 00988.28 ], Gyr [ 00000.49, 00000.73, -00001.46 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007239 NoMotion-1. Scaled. Acc [ -00049.80, -00015.14, 01000.49 ], Gyr [ 00000.55, 00000.85, -00003.35 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007240 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00984.38 ], Gyr [ -00001.28, 00001.34, -00000.37 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007241 NoMotion-1. Scaled. Acc [ -00050.29, -00003.42, 00979.49 ], Gyr [ -00000.98, 00003.78, -00001.59 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007242 NoMotion-1. Scaled. Acc [ -00037.11, -00000.49, 00995.12 ], Gyr [ -00002.80, 00001.65, -00003.23 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000007243 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00989.75 ], Gyr [ -00000.12, 00000.49, -00001.22 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000007244 NoMotion-1. Scaled. Acc [ -00051.27, -00010.25, 00986.82 ], Gyr [ 00000.49, -00000.91, -00001.46 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000007245 NoMotion-1. Scaled. Acc [ -00026.86, -00023.44, 00990.72 ], Gyr [ 00000.73, -00000.24, -00000.98 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007246 NoMotion-1. Scaled. Acc [ -00057.62, -00007.32, 00994.63 ], Gyr [ 00001.40, 00001.28, -00000.49 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007247 NoMotion-1. Scaled. Acc [ -00055.66, 00006.35, 00994.14 ], Gyr [ 00000.00, 00001.04, -00000.12 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007248 NoMotion-1. Scaled. Acc [ -00048.83, -00005.37, 00991.21 ], Gyr [ 00000.37, 00000.85, -00002.50 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007249 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00998.54 ], Gyr [ 00001.52, 00001.46, -00001.65 ], Mag [ 00020.40, 00082.65, 00108.45 ]",
    "#0000007250 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00987.30 ], Gyr [ -00001.46, 00002.62, -00004.02 ], Mag [ 00020.40, 00082.65, 00108.45 ]",
    "#0000007251 NoMotion-1. Scaled. Acc [ -00039.55, -00004.39, 00993.16 ], Gyr [ -00000.91, 00002.50, -00001.40 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007252 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 00985.84 ], Gyr [ 00002.13, 00000.73, -00003.23 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007253 NoMotion-1. Scaled. Acc [ -00032.71, 00007.32, 00992.68 ], Gyr [ -00000.06, 00000.55, -00000.91 ], Mag [ 00018.30, 00083.70, 00107.70 ]",
    "#0000007254 NoMotion-1. Scaled. Acc [ -00046.39, -00019.04, 01003.91 ], Gyr [ 00000.73, 00001.46, 00002.01 ], Mag [ 00018.30, 00083.70, 00107.70 ]",
    "#0000007255 NoMotion-1. Scaled. Acc [ -00042.48, 00001.95, 00979.98 ], Gyr [ -00000.98, 00001.52, -00000.61 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007256 NoMotion-1. Scaled. Acc [ -00048.83, -00017.58, 00992.19 ], Gyr [ -00001.34, 00002.62, -00003.11 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007257 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00998.54 ], Gyr [ 00000.85, 00001.40, -00000.61 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007258 NoMotion-1. Scaled. Acc [ -00040.53, -00017.58, 00995.61 ], Gyr [ 00003.17, 00002.80, 00000.85 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007259 NoMotion-1. Scaled. Acc [ -00032.71, 00000.00, 00989.26 ], Gyr [ 00001.22, 00001.28, -00000.12 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000007260 NoMotion-1. Scaled. Acc [ -00045.90, -00013.67, 01005.37 ], Gyr [ 00000.06, -00002.07, 00000.06 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000007261 NoMotion-1. Scaled. Acc [ -00041.99, 00003.91, 00994.14 ], Gyr [ 00001.10, 00000.85, -00000.37 ], Mag [ 00020.85, 00082.20, 00108.00 ]",
    "#0000007262 NoMotion-1. Scaled. Acc [ -00053.22, -00008.79, 00993.65 ], Gyr [ -00000.06, 00000.00, -00000.55 ], Mag [ 00020.85, 00082.20, 00108.00 ]",
    "#0000007263 NoMotion-1. Scaled. Acc [ -00043.46, -00001.95, 00993.16 ], Gyr [ -00002.07, 00000.37, -00003.90 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000007264 NoMotion-1. Scaled. Acc [ -00043.95, 00005.37, 00984.86 ], Gyr [ -00000.12, 00000.91, -00004.02 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007265 NoMotion-1. Scaled. Acc [ -00043.95, 00002.44, 00983.40 ], Gyr [ 00000.85, 00001.89, -00003.41 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007266 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00978.52 ], Gyr [ -00000.49, 00001.10, -00002.80 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007267 NoMotion-1. Scaled. Acc [ -00033.69, -00000.98, 00990.23 ], Gyr [ 00000.00, -00000.98, -00002.50 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007268 NoMotion-1. Scaled. Acc [ -00031.25, -00011.23, 00993.65 ], Gyr [ 00000.12, 00003.54, -00001.89 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007269 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 00990.72 ], Gyr [ 00000.30, -00000.55, 00000.12 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007270 NoMotion-1. Scaled. Acc [ -00034.18, -00009.77, 00977.54 ], Gyr [ 00000.30, 00000.67, -00002.20 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007271 NoMotion-1. Scaled. Acc [ -00037.11, -00006.35, 00998.54 ], Gyr [ -00000.24, 00001.77, -00001.34 ], Mag [ 00021.90, 00084.15, 00108.75 ]",
    "#0000007272 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00997.56 ], Gyr [ 00000.06, 00000.85, -00001.65 ], Mag [ 00021.90, 00084.15, 00108.75 ]",
    "#0000007273 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00992.19 ], Gyr [ 00001.10, 00000.12, -00000.49 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007274 NoMotion-1. Scaled. Acc [ -00025.88, 00006.84, 00979.49 ], Gyr [ -00000.12, 00002.50, -00001.59 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007275 NoMotion-1. Scaled. Acc [ -00031.25, -00001.46, 00996.09 ], Gyr [ 00000.61, 00001.59, -00002.07 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007276 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00977.54 ], Gyr [ -00000.37, 00005.49, -00002.26 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007277 NoMotion-1. Scaled. Acc [ -00037.60, -00007.32, 00994.63 ], Gyr [ 00000.06, 00002.74, -00002.44 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007278 NoMotion-1. Scaled. Acc [ -00059.08, -00004.39, 00984.38 ], Gyr [ 00000.79, 00001.83, -00003.23 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007279 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00991.70 ], Gyr [ -00000.55, 00002.68, -00004.27 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007280 NoMotion-1. Scaled. Acc [ -00046.39, -00009.77, 00986.33 ], Gyr [ -00000.30, 00001.89, -00001.10 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007281 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00987.79 ], Gyr [ 00000.91, 00003.05, -00001.95 ], Mag [ 00021.15, 00083.70, 00108.45 ]",
    "#0000007282 NoMotion-1. Scaled. Acc [ -00051.76, -00009.77, 00998.54 ], Gyr [ -00001.65, 00003.66, -00001.16 ], Mag [ 00021.15, 00083.70, 00108.45 ]",
    "#0000007283 NoMotion-1. Scaled. Acc [ -00053.22, -00000.98, 00994.14 ], Gyr [ 00001.89, 00001.71, -00001.77 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007284 NoMotion-1. Scaled. Acc [ -00043.95, -00008.30, 00998.54 ], Gyr [ -00000.55, -00000.37, 00000.30 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007285 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 01001.95 ], Gyr [ 00001.04, 00001.22, -00000.91 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007286 NoMotion-1. Scaled. Acc [ -00036.62, -00012.21, 00992.19 ], Gyr [ 00001.16, 00001.10, -00001.46 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007287 NoMotion-1. Scaled. Acc [ -00041.99, 00000.98, 00994.63 ], Gyr [ -00000.24, 00001.34, -00000.43 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007288 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00987.79 ], Gyr [ 00001.34, 00000.55, -00002.32 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007289 NoMotion-1. Scaled. Acc [ -00056.15, -00016.60, 00981.93 ], Gyr [ 00000.37, 00002.26, -00001.40 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007290 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 01000.00 ], Gyr [ 00000.55, 00001.71, -00001.52 ], Mag [ 00020.10, 00084.00, 00107.85 ]",
    "#0000007291 NoMotion-1. Scaled. Acc [ -00045.90, -00012.70, 00989.75 ], Gyr [ -00000.06, 00005.37, 00000.24 ], Mag [ 00020.10, 00085.65, 00108.00 ]",
    "#0000007292 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00999.51 ], Gyr [ 00001.04, 00001.28, -00003.35 ], Mag [ 00021.00, 00084.00, 00108.00 ]",
    "#0000007293 NoMotion-1. Scaled. Acc [ -00050.78, -00008.79, 01000.00 ], Gyr [ 00000.37, 00003.84, -00003.23 ], Mag [ 00021.00, 00084.00, 00107.55 ]",
    "#0000007294 NoMotion-1. Scaled. Acc [ -00040.04, 00002.93, 00976.07 ], Gyr [ -00001.83, 00004.82, -00001.65 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007295 NoMotion-1. Scaled. Acc [ -00042.48, -00011.72, 00990.72 ], Gyr [ -00000.24, 00000.30, -00002.56 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007296 NoMotion-1. Scaled. Acc [ -00041.02, 00000.00, 00980.47 ], Gyr [ 00001.77, -00000.55, -00001.59 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007297 NoMotion-1. Scaled. Acc [ -00044.43, -00002.93, 00988.28 ], Gyr [ 00002.68, 00001.28, -00000.91 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007298 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00990.23 ], Gyr [ 00000.73, 00000.55, -00002.32 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007299 NoMotion-1. Scaled. Acc [ -00037.60, -00004.88, 00988.77 ], Gyr [ -00000.61, 00002.26, -00000.49 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007300 NoMotion-1. Scaled. Acc [ -00044.43, 00003.91, 00992.68 ], Gyr [ 00001.46, -00000.37, -00002.74 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007301 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 00991.21 ], Gyr [ -00000.85, -00001.52, 00000.98 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007302 NoMotion-1. Scaled. Acc [ -00034.18, -00008.79, 00990.72 ], Gyr [ -00002.20, 00001.65, -00003.23 ], Mag [ 00019.50, 00083.25, 00108.30 ]",
    "#0000007303 NoMotion-1. Scaled. Acc [ -00041.50, -00016.60, 00988.77 ], Gyr [ 00000.61, 00001.59, 00000.67 ], Mag [ 00019.05, 00082.65, 00108.45 ]",
    "#0000007304 NoMotion-1. Scaled. Acc [ -00036.13, 00000.98, 00987.79 ], Gyr [ 00000.49, 00003.35, -00000.49 ], Mag [ 00019.05, 00082.65, 00108.45 ]",
    "#0000007305 NoMotion-1. Scaled. Acc [ -00050.78, -00018.07, 00996.58 ], Gyr [ -00001.77, 00001.71, -00001.52 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007306 NoMotion-1. Scaled. Acc [ -00038.57, -00016.11, 00991.21 ], Gyr [ -00000.73, 00000.67, -00002.32 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007307 NoMotion-1. Scaled. Acc [ -00028.32, 00001.46, 00995.12 ], Gyr [ 00001.65, 00001.22, -00001.04 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007308 NoMotion-1. Scaled. Acc [ -00038.57, -00006.84, 00985.84 ], Gyr [ -00001.52, -00002.32, -00001.83 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007309 NoMotion-1. Scaled. Acc [ -00034.18, 00001.46, 00986.82 ], Gyr [ -00001.95, -00002.44, -00001.10 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007310 NoMotion-1. Scaled. Acc [ -00043.95, -00000.49, 00987.30 ], Gyr [ 00000.55, 00001.52, -00001.71 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000007311 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00987.79 ], Gyr [ -00000.06, 00000.00, -00001.34 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007312 NoMotion-1. Scaled. Acc [ -00053.22, -00013.67, 00984.86 ], Gyr [ 00001.34, 00001.34, -00003.05 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007313 NoMotion-1. Scaled. Acc [ -00041.50, -00020.51, 00990.72 ], Gyr [ 00003.05, 00002.26, 00000.67 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007314 NoMotion-1. Scaled. Acc [ -00036.62, 00001.46, 00987.30 ], Gyr [ 00000.43, 00001.95, -00003.96 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007315 NoMotion-1. Scaled. Acc [ -00036.62, -00005.86, 00986.33 ], Gyr [ -00000.67, 00000.91, -00001.77 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007316 NoMotion-1. Scaled. Acc [ -00045.90, 00000.00, 00996.09 ], Gyr [ -00000.24, 00002.32, -00002.93 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007317 NoMotion-1. Scaled. Acc [ -00035.64, -00020.02, 00994.14 ], Gyr [ -00000.91, -00000.43, -00003.23 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007318 NoMotion-1. Scaled. Acc [ -00055.18, -00015.62, 00987.30 ], Gyr [ -00000.55, -00000.24, 00000.98 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007319 NoMotion-1. Scaled. Acc [ -00042.97, 00004.39, 00991.21 ], Gyr [ -00000.06, 00003.17, -00002.01 ], Mag [ 00019.50, 00082.80, 00108.60 ]",
    "#0000007320 NoMotion-1. Scaled. Acc [ -00044.43, 00003.91, 00988.77 ], Gyr [ 00000.37, 00001.16, -00001.16 ], Mag [ 00017.85, 00082.20, 00105.60 ]",
    "#0000007321 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00980.96 ], Gyr [ 00000.73, -00001.83, 00000.91 ], Mag [ 00020.25, 00082.50, 00107.10 ]",
    "#0000007322 NoMotion-1. Scaled. Acc [ -00037.60, -00010.74, 00993.16 ], Gyr [ -00000.98, 00001.28, 00002.74 ], Mag [ 00020.25, 00082.50, 00107.10 ]",
    "#0000007323 NoMotion-1. Scaled. Acc [ -00031.25, -00004.39, 00987.30 ], Gyr [ 00000.37, 00001.40, 00000.67 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007324 NoMotion-1. Scaled. Acc [ -00035.16, -00010.25, 00999.51 ], Gyr [ 00000.55, 00000.43, -00002.99 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007325 NoMotion-1. Scaled. Acc [ -00049.80, 00000.00, 00988.28 ], Gyr [ 00001.22, 00001.59, 00000.73 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007326 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00992.19 ], Gyr [ -00000.79, 00001.22, -00003.11 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007327 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00987.79 ], Gyr [ -00000.55, 00002.01, -00004.39 ], Mag [ 00020.25, 00084.00, 00107.10 ]",
    "#0000007328 NoMotion-1. Scaled. Acc [ -00038.09, 00007.32, 00988.28 ], Gyr [ 00000.06, 00000.06, -00002.44 ], Mag [ 00020.25, 00084.00, 00107.10 ]",
    "#0000007329 NoMotion-1. Scaled. Acc [ -00052.25, -00012.70, 00986.82 ], Gyr [ 00001.65, 00000.91, -00001.16 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000007330 NoMotion-1. Scaled. Acc [ -00049.80, -00000.98, 00986.33 ], Gyr [ -00000.49, 00002.87, -00001.89 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000007331 NoMotion-1. Scaled. Acc [ -00040.53, -00021.48, 00994.14 ], Gyr [ -00000.12, 00003.11, -00003.66 ], Mag [ 00017.70, 00084.75, 00107.10 ]",
    "#0000007332 NoMotion-1. Scaled. Acc [ -00052.25, -00005.86, 00998.05 ], Gyr [ 00000.67, 00003.54, -00001.40 ], Mag [ 00017.70, 00084.75, 00107.10 ]",
    "#0000007333 NoMotion-1. Scaled. Acc [ -00039.06, 00005.86, 00983.40 ], Gyr [ 00001.22, 00002.50, 00000.49 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007334 NoMotion-1. Scaled. Acc [ -00028.81, -00008.79, 00994.63 ], Gyr [ 00000.67, 00000.24, -00001.89 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007335 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00992.19 ], Gyr [ 00001.40, 00002.01, -00003.84 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007336 NoMotion-1. Scaled. Acc [ -00054.20, -00018.07, 00987.30 ], Gyr [ 00001.71, 00003.29, 00001.10 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007337 NoMotion-1. Scaled. Acc [ -00040.04, -00006.35, 01001.46 ], Gyr [ 00002.07, 00000.30, -00001.10 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000007338 NoMotion-1. Scaled. Acc [ -00033.20, -00002.44, 00987.30 ], Gyr [ -00000.55, 00000.06, -00001.28 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000007339 NoMotion-1. Scaled. Acc [ -00052.25, -00018.55, 00987.79 ], Gyr [ 00002.26, 00002.13, 00000.24 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007340 NoMotion-1. Scaled. Acc [ -00032.23, -00005.86, 00984.86 ], Gyr [ -00000.61, 00002.68, -00001.46 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007341 NoMotion-1. Scaled. Acc [ -00051.27, 00000.00, 00984.86 ], Gyr [ -00000.37, 00003.29, -00001.40 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007342 NoMotion-1. Scaled. Acc [ -00051.76, -00007.81, 00987.79 ], Gyr [ -00000.12, 00000.85, -00002.56 ], Mag [ 00018.15, 00084.30, 00109.35 ]",
    "#0000007343 NoMotion-1. Scaled. Acc [ -00039.55, 00000.00, 00982.91 ], Gyr [ -00000.79, 00001.16, -00002.62 ], Mag [ 00018.15, 00084.30, 00109.35 ]",
    "#0000007344 NoMotion-1. Scaled. Acc [ -00040.04, -00007.81, 00987.30 ], Gyr [ -00000.18, 00000.55, -00000.61 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007345 NoMotion-1. Scaled. Acc [ -00044.43, -00001.46, 00991.70 ], Gyr [ 00002.93, 00000.67, 00000.37 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007346 NoMotion-1. Scaled. Acc [ -00047.36, -00007.32, 00983.89 ], Gyr [ 00001.28, -00000.73, 00000.49 ], Mag [ 00021.15, 00085.20, 00108.00 ]",
    "#0000007347 NoMotion-1. Scaled. Acc [ -00051.76, -00010.25, 00992.68 ], Gyr [ 00001.10, 00002.32, -00000.91 ], Mag [ 00021.15, 00085.20, 00108.00 ]",
    "#0000007348 NoMotion-1. Scaled. Acc [ -00039.55, 00000.49, 00985.35 ], Gyr [ -00000.18, 00003.90, -00001.40 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007349 NoMotion-1. Scaled. Acc [ -00055.66, -00007.81, 00998.05 ], Gyr [ 00000.37, -00000.67, -00001.71 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007350 NoMotion-1. Scaled. Acc [ -00030.27, -00007.32, 00986.82 ], Gyr [ 00001.89, 00001.71, -00000.79 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007351 NoMotion-1. Scaled. Acc [ -00041.50, -00002.44, 00983.40 ], Gyr [ 00000.98, 00003.78, -00003.60 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000007352 NoMotion-1. Scaled. Acc [ -00040.04, 00000.98, 00985.84 ], Gyr [ 00000.61, 00003.96, -00000.43 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000007353 NoMotion-1. Scaled. Acc [ -00045.41, 00006.84, 00996.58 ], Gyr [ 00000.24, 00001.52, -00003.35 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007354 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 00989.26 ], Gyr [ -00000.98, 00000.61, -00004.09 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007355 NoMotion-1. Scaled. Acc [ -00030.76, -00023.44, 00994.14 ], Gyr [ 00002.26, 00001.16, 00000.55 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007356 NoMotion-1. Scaled. Acc [ -00042.48, -00014.16, 00989.75 ], Gyr [ 00001.22, -00000.49, -00002.13 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007357 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00989.26 ], Gyr [ 00002.13, 00003.41, -00000.12 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007358 NoMotion-1. Scaled. Acc [ -00061.52, -00003.91, 01000.98 ], Gyr [ 00000.91, -00000.79, -00001.71 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007359 NoMotion-1. Scaled. Acc [ -00047.36, -00010.74, 00986.33 ], Gyr [ 00001.22, 00001.28, 00000.37 ], Mag [ 00020.10, 00082.65, 00107.40 ]",
    "#0000007360 NoMotion-1. Scaled. Acc [ -00055.66, -00012.70, 00989.26 ], Gyr [ 00001.95, -00000.79, 00001.22 ], Mag [ 00020.10, 00082.65, 00107.40 ]",
    "#0000007361 NoMotion-1. Scaled. Acc [ -00037.11, -00017.58, 00987.30 ], Gyr [ -00000.12, 00001.10, -00002.01 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007362 NoMotion-1. Scaled. Acc [ -00045.90, 00001.95, 00992.68 ], Gyr [ -00000.55, 00001.34, -00003.23 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007363 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00999.51 ], Gyr [ -00000.98, -00000.85, -00000.55 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000007364 NoMotion-1. Scaled. Acc [ -00040.53, -00012.21, 00980.96 ], Gyr [ -00001.16, 00002.44, -00001.77 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000007365 NoMotion-1. Scaled. Acc [ -00033.20, -00014.16, 00996.58 ], Gyr [ 00000.18, 00001.59, -00003.72 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007366 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00982.42 ], Gyr [ 00002.01, 00001.65, -00000.43 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007367 NoMotion-1. Scaled. Acc [ -00041.99, -00012.21, 00987.30 ], Gyr [ 00000.43, 00003.23, -00000.30 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007368 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00995.61 ], Gyr [ 00000.18, 00000.00, 00000.12 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007369 NoMotion-1. Scaled. Acc [ -00031.74, -00007.81, 01001.95 ], Gyr [ 00001.22, 00001.59, 00000.98 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007370 NoMotion-1. Scaled. Acc [ -00037.11, 00005.86, 00983.89 ], Gyr [ -00000.55, 00000.91, -00002.32 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007371 NoMotion-1. Scaled. Acc [ -00054.20, -00001.95, 00986.82 ], Gyr [ 00001.89, 00002.07, -00000.67 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007372 NoMotion-1. Scaled. Acc [ -00033.20, -00023.93, 00984.38 ], Gyr [ 00002.44, 00000.00, -00001.95 ], Mag [ 00019.65, 00083.40, 00106.95 ]",
    "#0000007373 NoMotion-1. Scaled. Acc [ -00040.53, -00021.00, 00998.05 ], Gyr [ 00001.83, 00000.61, -00004.45 ], Mag [ 00019.65, 00083.40, 00106.95 ]",
    "#0000007374 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00998.05 ], Gyr [ 00000.24, 00001.95, -00000.98 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007375 NoMotion-1. Scaled. Acc [ -00045.41, -00015.14, 00985.35 ], Gyr [ -00000.18, 00001.59, -00001.83 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007376 NoMotion-1. Scaled. Acc [ -00047.85, -00007.81, 00997.56 ], Gyr [ -00000.37, 00000.85, -00003.17 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007377 NoMotion-1. Scaled. Acc [ -00044.92, -00000.98, 00993.65 ], Gyr [ -00002.50, 00002.44, -00001.95 ], Mag [ 00019.95, 00082.05, 00108.30 ]",
    "#0000007378 NoMotion-1. Scaled. Acc [ -00041.02, -00012.70, 00981.45 ], Gyr [ -00000.91, 00002.50, -00002.50 ], Mag [ 00019.95, 00082.05, 00108.30 ]",
    "#0000007379 NoMotion-1. Scaled. Acc [ -00042.97, -00019.53, 00994.63 ], Gyr [ -00000.30, -00000.73, -00000.37 ], Mag [ 00017.85, 00084.90, 00109.05 ]",
    "#0000007380 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00982.42 ], Gyr [ 00004.51, -00000.18, 00000.00 ], Mag [ 00017.85, 00084.90, 00109.05 ]",
    "#0000007381 NoMotion-1. Scaled. Acc [ -00045.41, -00004.88, 00984.86 ], Gyr [ 00000.30, 00003.17, -00002.26 ], Mag [ 00020.25, 00082.80, 00107.85 ]",
    "#0000007382 NoMotion-1. Scaled. Acc [ -00041.02, 00002.44, 00994.14 ], Gyr [ 00001.16, 00001.10, -00002.01 ], Mag [ 00020.25, 00082.80, 00107.85 ]",
    "#0000007383 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00983.40 ], Gyr [ 00000.24, -00002.13, 00000.30 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007384 NoMotion-1. Scaled. Acc [ -00040.53, -00004.88, 01003.91 ], Gyr [ 00001.89, 00001.16, -00000.43 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007385 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00982.91 ], Gyr [ -00000.12, 00000.55, -00003.17 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007386 NoMotion-1. Scaled. Acc [ -00048.83, 00004.39, 00999.51 ], Gyr [ 00002.26, 00000.43, -00001.16 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007387 NoMotion-1. Scaled. Acc [ -00035.64, -00006.35, 00990.72 ], Gyr [ 00000.37, 00003.29, -00001.34 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007388 NoMotion-1. Scaled. Acc [ -00035.64, -00001.95, 00987.30 ], Gyr [ 00000.43, 00000.18, -00000.30 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007389 NoMotion-1. Scaled. Acc [ -00039.55, 00004.88, 00993.65 ], Gyr [ 00000.61, 00004.51, -00002.68 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007390 NoMotion-1. Scaled. Acc [ -00047.85, -00009.77, 00985.35 ], Gyr [ 00000.43, 00000.06, -00003.90 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007391 NoMotion-1. Scaled. Acc [ -00040.04, -00008.30, 00983.40 ], Gyr [ -00000.24, 00002.32, -00002.62 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007392 NoMotion-1. Scaled. Acc [ -00034.67, -00012.21, 00997.56 ], Gyr [ 00001.28, -00001.34, 00001.04 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007393 NoMotion-1. Scaled. Acc [ -00038.57, -00013.18, 00978.52 ], Gyr [ 00000.73, 00000.91, -00000.73 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007394 NoMotion-1. Scaled. Acc [ -00042.48, -00006.35, 00995.12 ], Gyr [ 00000.06, 00000.91, -00001.46 ], Mag [ 00020.40, 00083.40, 00107.70 ]",
    "#0000007395 NoMotion-1. Scaled. Acc [ -00042.48, -00002.93, 00997.07 ], Gyr [ 00002.01, -00000.73, 00000.85 ], Mag [ 00020.40, 00083.40, 00107.70 ]",
    "#0000007396 NoMotion-1. Scaled. Acc [ -00037.60, -00014.16, 01004.88 ], Gyr [ 00000.61, 00001.10, -00000.73 ], Mag [ 00020.70, 00084.00, 00107.85 ]",
    "#0000007397 NoMotion-1. Scaled. Acc [ -00042.97, -00011.23, 00995.61 ], Gyr [ 00001.46, 00001.34, -00000.55 ], Mag [ 00020.70, 00084.00, 00107.85 ]",
    "#0000007398 NoMotion-1. Scaled. Acc [ -00048.34, -00014.65, 00995.12 ], Gyr [ -00000.18, 00001.83, -00002.07 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000007399 NoMotion-1. Scaled. Acc [ -00048.83, -00016.60, 00999.02 ], Gyr [ 00001.10, 00001.28, -00003.72 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000007400 NoMotion-1. Scaled. Acc [ -00041.50, -00013.67, 00998.05 ], Gyr [ 00000.55, 00001.89, -00001.65 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007401 NoMotion-1. Scaled. Acc [ -00047.85, 00009.77, 00991.70 ], Gyr [ -00001.71, 00003.05, -00001.83 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007402 NoMotion-1. Scaled. Acc [ -00037.60, -00026.37, 01001.46 ], Gyr [ 00000.12, 00000.43, 00001.28 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007403 NoMotion-1. Scaled. Acc [ -00035.64, -00011.23, 00992.19 ], Gyr [ -00000.37, 00003.60, -00002.62 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007404 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 01001.95 ], Gyr [ 00001.10, 00002.13, -00000.61 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007405 NoMotion-1. Scaled. Acc [ -00051.27, -00006.35, 00989.26 ], Gyr [ 00001.52, 00001.16, 00002.68 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007406 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 00981.93 ], Gyr [ -00000.18, 00002.20, -00000.24 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007407 NoMotion-1. Scaled. Acc [ -00037.60, -00014.16, 00982.42 ], Gyr [ 00002.26, 00001.46, -00003.41 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007408 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00992.68 ], Gyr [ -00000.30, -00000.55, -00001.34 ], Mag [ 00019.95, 00084.75, 00108.60 ]",
    "#0000007409 NoMotion-1. Scaled. Acc [ -00036.13, -00001.95, 00994.63 ], Gyr [ 00001.71, 00001.34, -00001.95 ], Mag [ 00019.95, 00084.75, 00108.60 ]",
    "#0000007410 NoMotion-1. Scaled. Acc [ -00033.20, -00012.21, 00992.19 ], Gyr [ 00003.35, 00002.38, 00000.24 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007411 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 01000.98 ], Gyr [ 00001.46, -00000.30, -00000.85 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007412 NoMotion-1. Scaled. Acc [ -00046.88, -00017.58, 00991.70 ], Gyr [ 00001.04, -00000.55, -00002.68 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007413 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00990.72 ], Gyr [ 00000.00, 00003.05, -00000.85 ], Mag [ 00020.40, 00084.90, 00107.40 ]",
    "#0000007414 NoMotion-1. Scaled. Acc [ -00036.62, -00001.46, 00986.33 ], Gyr [ 00001.77, 00001.40, -00003.90 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007415 NoMotion-1. Scaled. Acc [ -00059.57, 00001.46, 00988.77 ], Gyr [ 00000.43, 00003.90, -00000.12 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007416 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00991.70 ], Gyr [ 00001.10, 00000.12, -00000.73 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007417 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00993.16 ], Gyr [ 00000.98, 00004.63, -00003.41 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007418 NoMotion-1. Scaled. Acc [ -00044.43, -00004.39, 00993.65 ], Gyr [ 00000.00, 00003.17, -00001.16 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007419 NoMotion-1. Scaled. Acc [ -00061.04, -00011.72, 00992.68 ], Gyr [ -00000.24, 00000.06, -00002.26 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007420 NoMotion-1. Scaled. Acc [ -00048.83, -00003.91, 00993.65 ], Gyr [ 00000.79, 00001.77, -00001.59 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007421 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00989.26 ], Gyr [ 00000.79, -00000.43, -00000.98 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007422 NoMotion-1. Scaled. Acc [ -00038.57, -00021.97, 00993.65 ], Gyr [ -00001.95, 00003.17, -00002.44 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000007423 NoMotion-1. Scaled. Acc [ -00042.48, -00009.77, 00984.86 ], Gyr [ -00002.50, 00000.67, -00002.80 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000007424 NoMotion-1. Scaled. Acc [ -00042.97, -00000.98, 00990.23 ], Gyr [ 00001.77, 00001.34, -00003.48 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007425 NoMotion-1. Scaled. Acc [ -00047.85, -00012.21, 01005.86 ], Gyr [ 00002.20, -00000.12, -00001.34 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007426 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 01000.98 ], Gyr [ -00000.24, -00000.55, -00002.56 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000007427 NoMotion-1. Scaled. Acc [ -00047.85, -00008.79, 00984.86 ], Gyr [ 00000.98, 00001.89, -00002.93 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000007428 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00992.68 ], Gyr [ 00001.83, 00000.67, -00000.67 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007429 NoMotion-1. Scaled. Acc [ -00048.34, -00000.49, 00995.61 ], Gyr [ 00000.55, 00001.59, 00000.06 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007430 NoMotion-1. Scaled. Acc [ -00041.50, -00001.46, 00986.82 ], Gyr [ -00000.12, 00002.62, -00000.24 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007431 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 01000.49 ], Gyr [ 00001.59, 00003.84, -00001.28 ], Mag [ 00019.65, 00085.65, 00106.95 ]",
    "#0000007432 NoMotion-1. Scaled. Acc [ -00053.22, -00009.77, 00975.59 ], Gyr [ 00001.59, 00003.05, -00001.16 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007433 NoMotion-1. Scaled. Acc [ -00044.43, -00013.18, 00986.82 ], Gyr [ -00000.67, 00001.59, -00000.30 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007434 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00984.38 ], Gyr [ 00000.55, 00000.18, 00000.06 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007435 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00987.30 ], Gyr [ 00000.61, -00000.30, 00000.55 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007436 NoMotion-1. Scaled. Acc [ -00040.53, -00009.77, 00983.89 ], Gyr [ -00001.77, 00000.00, -00001.77 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007437 NoMotion-1. Scaled. Acc [ -00037.11, -00007.81, 00995.12 ], Gyr [ 00000.73, 00000.73, -00003.66 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007438 NoMotion-1. Scaled. Acc [ -00054.69, -00009.77, 00984.38 ], Gyr [ 00000.37, 00002.13, -00003.11 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000007439 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00997.56 ], Gyr [ -00000.98, 00001.34, -00004.02 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000007440 NoMotion-1. Scaled. Acc [ -00036.62, -00011.72, 00998.05 ], Gyr [ 00002.07, 00001.28, -00001.52 ], Mag [ 00021.00, 00083.25, 00108.30 ]",
    "#0000007441 NoMotion-1. Scaled. Acc [ -00051.76, -00014.16, 00978.52 ], Gyr [ -00001.40, 00002.44, -00002.07 ], Mag [ 00021.00, 00083.25, 00108.30 ]",
    "#0000007442 NoMotion-1. Scaled. Acc [ -00050.78, -00005.86, 00992.68 ], Gyr [ 00000.00, 00001.22, -00002.74 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000007443 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00984.86 ], Gyr [ 00003.11, -00002.13, -00002.38 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000007444 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 00995.12 ], Gyr [ 00001.59, -00002.56, -00001.22 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007445 NoMotion-1. Scaled. Acc [ -00043.95, -00015.14, 00983.89 ], Gyr [ 00002.07, 00004.27, -00002.50 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007446 NoMotion-1. Scaled. Acc [ -00049.80, 00002.44, 00985.35 ], Gyr [ 00001.89, -00000.85, 00000.61 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007447 NoMotion-1. Scaled. Acc [ -00050.29, -00007.81, 00994.63 ], Gyr [ -00000.12, -00001.22, -00002.01 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000007448 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00995.12 ], Gyr [ 00000.49, 00000.91, -00001.16 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000007449 NoMotion-1. Scaled. Acc [ -00031.74, 00006.84, 00988.28 ], Gyr [ 00000.85, 00002.50, -00002.01 ], Mag [ 00020.10, 00084.45, 00108.75 ]",
    "#0000007450 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00988.28 ], Gyr [ 00001.59, 00001.22, -00002.32 ], Mag [ 00020.10, 00084.45, 00108.75 ]",
    "#0000007451 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00984.38 ], Gyr [ -00000.55, -00000.37, -00002.74 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000007452 NoMotion-1. Scaled. Acc [ -00041.50, -00004.39, 00990.23 ], Gyr [ -00000.30, 00003.72, -00002.93 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007453 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 00984.38 ], Gyr [ -00000.79, 00002.20, 00000.24 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007454 NoMotion-1. Scaled. Acc [ -00049.80, -00004.88, 00980.96 ], Gyr [ 00002.13, 00000.61, -00002.99 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007455 NoMotion-1. Scaled. Acc [ -00038.09, -00006.84, 00993.65 ], Gyr [ 00000.85, 00001.16, -00001.22 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007456 NoMotion-1. Scaled. Acc [ -00053.22, -00017.58, 00991.70 ], Gyr [ 00000.37, -00001.52, 00000.61 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007457 NoMotion-1. Scaled. Acc [ -00049.80, -00003.91, 00987.30 ], Gyr [ 00002.07, 00001.10, 00000.24 ], Mag [ 00019.50, 00084.00, 00107.55 ]",
    "#0000007458 NoMotion-1. Scaled. Acc [ -00041.02, -00003.42, 00996.09 ], Gyr [ 00002.87, 00001.16, 00001.10 ], Mag [ 00019.50, 00084.00, 00107.55 ]",
    "#0000007459 NoMotion-1. Scaled. Acc [ -00052.73, -00002.44, 00993.65 ], Gyr [ -00000.12, 00002.20, -00003.29 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007460 NoMotion-1. Scaled. Acc [ -00021.48, -00016.11, 01008.79 ], Gyr [ -00000.79, 00001.46, -00000.61 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007461 NoMotion-1. Scaled. Acc [ -00048.83, -00008.30, 00980.47 ], Gyr [ -00000.61, 00002.93, 00000.06 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007462 NoMotion-1. Scaled. Acc [ -00036.13, -00018.55, 00989.75 ], Gyr [ -00001.77, 00002.99, -00001.65 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007463 NoMotion-1. Scaled. Acc [ -00056.15, -00003.91, 00989.75 ], Gyr [ -00000.49, 00001.71, 00002.20 ], Mag [ 00020.25, 00084.00, 00109.65 ]",
    "#0000007464 NoMotion-1. Scaled. Acc [ -00054.69, -00000.49, 00995.12 ], Gyr [ 00001.40, 00000.18, -00002.93 ], Mag [ 00018.30, 00082.65, 00105.90 ]",
    "#0000007465 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00981.93 ], Gyr [ 00002.38, 00003.11, -00001.71 ], Mag [ 00018.30, 00082.65, 00105.90 ]",
    "#0000007466 NoMotion-1. Scaled. Acc [ -00047.85, -00015.62, 00985.35 ], Gyr [ -00000.79, 00000.73, 00000.24 ], Mag [ 00019.95, 00085.80, 00108.30 ]",
    "#0000007467 NoMotion-1. Scaled. Acc [ -00029.79, -00001.95, 00990.72 ], Gyr [ -00000.55, 00000.24, -00002.13 ], Mag [ 00019.95, 00085.80, 00108.30 ]",
    "#0000007468 NoMotion-1. Scaled. Acc [ -00046.39, -00007.81, 00986.82 ], Gyr [ 00001.95, 00001.77, -00002.80 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007469 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00997.07 ], Gyr [ -00000.73, -00000.06, -00003.35 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007470 NoMotion-1. Scaled. Acc [ -00034.67, 00000.98, 00984.86 ], Gyr [ -00000.91, 00001.52, -00000.85 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007471 NoMotion-1. Scaled. Acc [ -00055.18, -00007.32, 00988.77 ], Gyr [ 00001.28, 00001.95, -00001.52 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007472 NoMotion-1. Scaled. Acc [ -00037.60, -00002.44, 01000.98 ], Gyr [ 00001.89, 00001.10, -00001.52 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000007473 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00992.68 ], Gyr [ 00002.01, 00000.85, -00002.44 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000007474 NoMotion-1. Scaled. Acc [ -00043.95, -00015.62, 00993.16 ], Gyr [ 00000.91, 00004.09, -00000.24 ], Mag [ 00020.70, 00084.00, 00106.80 ]",
    "#0000007475 NoMotion-1. Scaled. Acc [ -00047.36, -00008.30, 01001.95 ], Gyr [ 00000.06, 00003.05, 00000.55 ], Mag [ 00020.70, 00084.00, 00106.80 ]",
    "#0000007476 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00981.45 ], Gyr [ -00000.55, 00002.38, -00001.40 ], Mag [ 00020.10, 00084.45, 00108.00 ]",
    "#0000007477 NoMotion-1. Scaled. Acc [ -00044.92, -00010.74, 00985.35 ], Gyr [ 00000.55, 00000.85, -00001.52 ], Mag [ 00020.10, 00084.45, 00108.00 ]",
    "#0000007478 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 01001.95 ], Gyr [ -00001.22, 00002.68, -00001.52 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007479 NoMotion-1. Scaled. Acc [ -00047.85, 00007.81, 00986.82 ], Gyr [ 00001.52, 00000.79, -00000.37 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007480 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00989.75 ], Gyr [ 00000.98, 00000.43, -00000.49 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007481 NoMotion-1. Scaled. Acc [ -00054.69, -00014.16, 00985.84 ], Gyr [ 00001.65, 00001.59, 00000.73 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007482 NoMotion-1. Scaled. Acc [ -00046.88, 00003.91, 00997.07 ], Gyr [ 00001.83, -00000.55, -00002.32 ], Mag [ 00019.35, 00082.20, 00106.95 ]",
    "#0000007483 NoMotion-1. Scaled. Acc [ -00049.32, 00000.49, 00985.35 ], Gyr [ 00002.26, 00000.06, -00001.04 ], Mag [ 00020.40, 00082.20, 00108.75 ]",
    "#0000007484 NoMotion-1. Scaled. Acc [ -00047.36, -00000.98, 00998.54 ], Gyr [ 00000.61, -00001.28, -00003.72 ], Mag [ 00020.40, 00082.20, 00108.75 ]",
    "#0000007485 NoMotion-1. Scaled. Acc [ -00041.02, -00000.49, 00988.77 ], Gyr [ -00001.28, 00002.26, -00003.41 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000007486 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00988.77 ], Gyr [ 00000.24, 00001.52, -00002.44 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000007487 NoMotion-1. Scaled. Acc [ -00039.06, -00002.93, 00979.49 ], Gyr [ 00000.98, 00003.11, -00001.59 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007488 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00984.86 ], Gyr [ 00000.24, 00002.01, -00002.80 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007489 NoMotion-1. Scaled. Acc [ -00045.90, 00000.49, 00985.84 ], Gyr [ -00000.43, 00003.23, -00001.65 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007490 NoMotion-1. Scaled. Acc [ -00036.13, -00010.74, 00992.68 ], Gyr [ -00000.06, 00000.18, 00000.49 ], Mag [ 00021.00, 00084.30, 00108.30 ]",
    "#0000007491 NoMotion-1. Scaled. Acc [ -00049.80, -00005.37, 00996.09 ], Gyr [ 00001.95, 00001.65, 00001.89 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007492 NoMotion-1. Scaled. Acc [ -00037.60, -00002.93, 00986.33 ], Gyr [ 00001.04, 00000.37, -00000.73 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007493 NoMotion-1. Scaled. Acc [ -00039.55, -00009.28, 00987.79 ], Gyr [ 00002.07, 00000.91, -00001.10 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007494 NoMotion-1. Scaled. Acc [ -00052.73, -00019.53, 00986.33 ], Gyr [ 00002.68, 00001.22, -00000.91 ], Mag [ 00020.70, 00084.30, 00107.85 ]",
    "#0000007495 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 01002.93 ], Gyr [ -00000.18, 00000.24, -00001.10 ], Mag [ 00020.70, 00084.30, 00107.85 ]",
    "#0000007496 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00987.30 ], Gyr [ 00000.67, -00002.13, -00000.24 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007497 NoMotion-1. Scaled. Acc [ -00041.50, 00004.39, 00990.72 ], Gyr [ 00001.77, 00001.52, -00001.52 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007498 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00993.65 ], Gyr [ -00000.30, 00004.51, -00002.07 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007499 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00991.70 ], Gyr [ -00000.55, -00000.67, -00003.96 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007500 NoMotion-1. Scaled. Acc [ -00049.80, -00002.93, 00994.63 ], Gyr [ 00001.52, -00000.61, -00001.95 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007501 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00980.47 ], Gyr [ 00001.10, 00002.50, -00003.66 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007502 NoMotion-1. Scaled. Acc [ -00049.80, -00001.95, 00994.14 ], Gyr [ 00000.37, -00000.49, -00002.20 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007503 NoMotion-1. Scaled. Acc [ -00049.32, -00010.25, 00984.86 ], Gyr [ -00001.34, 00001.71, -00002.44 ], Mag [ 00018.75, 00082.05, 00107.55 ]",
    "#0000007504 NoMotion-1. Scaled. Acc [ -00035.64, 00001.95, 00984.86 ], Gyr [ -00000.49, 00000.18, 00000.61 ], Mag [ 00018.75, 00082.05, 00107.55 ]",
    "#0000007505 NoMotion-1. Scaled. Acc [ -00041.02, -00019.53, 00987.79 ], Gyr [ 00001.77, -00002.50, -00000.43 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007506 NoMotion-1. Scaled. Acc [ -00050.29, -00005.86, 00985.84 ], Gyr [ 00001.40, 00001.16, 00000.00 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007507 NoMotion-1. Scaled. Acc [ -00037.60, -00001.46, 00991.21 ], Gyr [ 00001.34, 00001.83, -00000.85 ], Mag [ 00020.10, 00084.90, 00107.70 ]",
    "#0000007508 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00993.16 ], Gyr [ 00002.07, 00001.16, 00000.49 ], Mag [ 00020.10, 00084.90, 00107.70 ]",
    "#0000007509 NoMotion-1. Scaled. Acc [ -00052.25, 00000.00, 01000.49 ], Gyr [ 00001.28, 00002.93, -00002.99 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007510 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 00993.65 ], Gyr [ -00000.12, 00002.38, -00000.85 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007511 NoMotion-1. Scaled. Acc [ -00039.55, -00004.88, 00971.19 ], Gyr [ 00000.49, 00000.98, -00001.28 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007512 NoMotion-1. Scaled. Acc [ -00043.95, -00016.11, 00984.86 ], Gyr [ 00000.00, 00002.68, -00002.26 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007513 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00989.26 ], Gyr [ -00000.79, 00004.02, -00000.43 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000007514 NoMotion-1. Scaled. Acc [ -00049.32, -00012.21, 01010.25 ], Gyr [ 00001.10, 00004.21, -00004.21 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000007515 NoMotion-1. Scaled. Acc [ -00038.09, 00005.37, 01000.00 ], Gyr [ -00000.79, 00004.39, -00001.16 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000007516 NoMotion-1. Scaled. Acc [ -00047.36, -00021.97, 00994.63 ], Gyr [ 00000.73, 00000.24, -00000.61 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000007517 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.28 ], Gyr [ 00001.52, 00002.32, -00001.04 ], Mag [ 00019.95, 00081.75, 00107.10 ]",
    "#0000007518 NoMotion-1. Scaled. Acc [ -00043.46, -00021.97, 00984.86 ], Gyr [ 00000.91, 00000.91, -00002.26 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007519 NoMotion-1. Scaled. Acc [ -00058.59, 00000.98, 00989.75 ], Gyr [ 00000.24, -00000.30, -00003.54 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007520 NoMotion-1. Scaled. Acc [ -00046.39, -00013.18, 00995.12 ], Gyr [ 00000.85, 00000.12, -00001.46 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007521 NoMotion-1. Scaled. Acc [ -00039.55, -00013.18, 00989.75 ], Gyr [ 00000.85, 00000.49, -00000.67 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007522 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00996.09 ], Gyr [ -00000.73, 00000.55, -00000.98 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007523 NoMotion-1. Scaled. Acc [ -00037.60, -00000.98, 00981.45 ], Gyr [ 00000.06, 00002.80, -00001.28 ], Mag [ 00019.05, 00083.70, 00108.45 ]",
    "#0000007524 NoMotion-1. Scaled. Acc [ -00050.29, -00015.62, 00994.14 ], Gyr [ 00000.85, -00000.30, -00002.01 ], Mag [ 00019.05, 00083.70, 00108.45 ]",
    "#0000007525 NoMotion-1. Scaled. Acc [ -00043.46, -00015.62, 00988.77 ], Gyr [ -00000.30, 00001.71, -00001.52 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007526 NoMotion-1. Scaled. Acc [ -00036.13, 00000.49, 00994.14 ], Gyr [ -00000.73, 00004.57, -00000.73 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007527 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 00994.14 ], Gyr [ 00001.52, 00001.71, -00004.02 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007528 NoMotion-1. Scaled. Acc [ -00045.41, -00001.46, 00985.35 ], Gyr [ 00001.71, 00001.71, 00001.04 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007529 NoMotion-1. Scaled. Acc [ -00042.48, -00001.95, 00994.14 ], Gyr [ -00000.79, 00001.28, -00003.11 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007530 NoMotion-1. Scaled. Acc [ -00048.83, -00011.23, 00982.91 ], Gyr [ 00000.85, 00002.01, 00000.55 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007531 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00989.75 ], Gyr [ 00001.28, -00001.59, -00003.17 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000007532 NoMotion-1. Scaled. Acc [ -00040.53, -00013.18, 00997.56 ], Gyr [ 00001.89, 00001.77, -00000.85 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000007533 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00992.68 ], Gyr [ -00000.91, -00000.85, 00000.55 ], Mag [ 00021.60, 00083.40, 00108.45 ]",
    "#0000007534 NoMotion-1. Scaled. Acc [ -00035.16, -00005.37, 00982.42 ], Gyr [ 00001.34, 00003.17, -00002.32 ], Mag [ 00021.60, 00083.40, 00108.45 ]",
    "#0000007535 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00993.16 ], Gyr [ 00000.43, 00002.56, 00001.34 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007536 NoMotion-1. Scaled. Acc [ -00048.83, 00001.95, 00984.38 ], Gyr [ 00002.26, -00001.89, 00001.40 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007537 NoMotion-1. Scaled. Acc [ -00050.29, -00008.79, 00991.21 ], Gyr [ -00001.34, 00001.89, 00001.89 ], Mag [ 00020.70, 00085.50, 00107.55 ]",
    "#0000007538 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00989.26 ], Gyr [ 00002.26, 00002.13, -00002.87 ], Mag [ 00020.70, 00085.50, 00107.55 ]",
    "#0000007539 NoMotion-1. Scaled. Acc [ -00039.06, -00019.04, 00999.02 ], Gyr [ -00001.28, 00000.43, -00001.83 ], Mag [ 00020.70, 00085.50, 00107.55 ]",
    "#0000007540 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 00996.58 ], Gyr [ 00001.71, 00001.40, -00000.98 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007541 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00992.19 ], Gyr [ 00000.55, 00002.50, -00001.16 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007542 NoMotion-1. Scaled. Acc [ -00056.15, -00001.95, 00982.42 ], Gyr [ 00000.00, 00004.02, 00000.00 ], Mag [ 00020.25, 00083.55, 00106.50 ]",
    "#0000007543 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00982.42 ], Gyr [ 00000.06, 00003.41, -00002.38 ], Mag [ 00020.25, 00083.55, 00106.50 ]",
    "#0000007544 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 01000.00 ], Gyr [ 00001.46, -00000.37, -00001.65 ], Mag [ 00020.10, 00084.90, 00105.60 ]",
    "#0000007545 NoMotion-1. Scaled. Acc [ -00027.83, -00019.53, 00996.09 ], Gyr [ -00000.43, 00000.00, -00000.98 ], Mag [ 00020.10, 00084.90, 00105.60 ]",
    "#0000007546 NoMotion-1. Scaled. Acc [ -00026.86, -00015.14, 00990.23 ], Gyr [ -00000.12, 00001.71, 00002.44 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007547 NoMotion-1. Scaled. Acc [ -00041.02, -00014.16, 00981.93 ], Gyr [ 00000.43, -00000.49, 00000.37 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007548 NoMotion-1. Scaled. Acc [ -00052.25, -00013.67, 00989.75 ], Gyr [ 00001.71, -00000.79, -00002.13 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007549 NoMotion-1. Scaled. Acc [ -00041.02, -00001.95, 00986.33 ], Gyr [ -00000.98, 00000.85, -00000.55 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000007550 NoMotion-1. Scaled. Acc [ -00031.74, -00008.30, 00985.84 ], Gyr [ 00000.55, 00001.46, -00001.71 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007551 NoMotion-1. Scaled. Acc [ -00038.09, -00009.77, 00980.96 ], Gyr [ -00000.43, 00001.40, -00002.26 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007552 NoMotion-1. Scaled. Acc [ -00042.48, -00018.55, 00994.63 ], Gyr [ 00002.01, 00002.62, -00000.61 ], Mag [ 00021.15, 00083.40, 00107.40 ]",
    "#0000007553 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 01000.00 ], Gyr [ -00000.98, 00001.10, -00002.93 ], Mag [ 00021.15, 00083.40, 00107.40 ]",
    "#0000007554 NoMotion-1. Scaled. Acc [ -00051.76, -00013.67, 00983.40 ], Gyr [ -00002.07, 00004.45, 00000.61 ], Mag [ 00019.50, 00085.05, 00107.10 ]",
    "#0000007555 NoMotion-1. Scaled. Acc [ -00050.29, -00013.67, 00991.70 ], Gyr [ 00000.30, 00001.28, -00002.68 ], Mag [ 00019.50, 00085.05, 00107.10 ]",
    "#0000007556 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 00992.68 ], Gyr [ 00002.07, 00002.26, -00002.01 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007557 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00996.58 ], Gyr [ 00001.59, -00000.24, -00002.62 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007558 NoMotion-1. Scaled. Acc [ -00031.74, -00006.84, 00997.07 ], Gyr [ 00000.55, 00001.95, -00000.79 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007559 NoMotion-1. Scaled. Acc [ -00036.62, -00006.84, 01004.88 ], Gyr [ 00000.06, 00000.91, -00000.43 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007560 NoMotion-1. Scaled. Acc [ -00037.11, -00008.30, 00986.33 ], Gyr [ 00001.40, -00001.04, -00002.74 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007561 NoMotion-1. Scaled. Acc [ -00045.90, -00009.77, 00996.58 ], Gyr [ 00001.71, 00000.85, 00000.55 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007562 NoMotion-1. Scaled. Acc [ -00058.11, -00007.32, 01007.32 ], Gyr [ -00002.13, -00000.98, -00001.40 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007563 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00991.21 ], Gyr [ 00000.61, 00002.56, -00003.96 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007564 NoMotion-1. Scaled. Acc [ -00041.02, -00010.25, 00982.42 ], Gyr [ -00000.18, -00000.37, -00002.56 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007565 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 00978.03 ], Gyr [ -00000.73, 00002.20, -00002.50 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007566 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00996.09 ], Gyr [ -00001.10, 00001.16, -00005.30 ], Mag [ 00019.20, 00084.75, 00107.10 ]",
    "#0000007567 NoMotion-1. Scaled. Acc [ -00039.06, 00006.35, 00982.91 ], Gyr [ 00000.55, 00001.10, -00001.28 ], Mag [ 00018.60, 00084.45, 00106.95 ]",
    "#0000007568 NoMotion-1. Scaled. Acc [ -00051.27, -00017.09, 00986.33 ], Gyr [ -00000.55, 00002.13, -00002.38 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007569 NoMotion-1. Scaled. Acc [ -00049.80, 00000.00, 00981.93 ], Gyr [ 00001.46, 00004.09, 00000.43 ], Mag [ 00019.50, 00084.75, 00107.10 ]",
    "#0000007570 NoMotion-1. Scaled. Acc [ -00059.08, -00013.67, 00991.70 ], Gyr [ 00001.95, 00000.30, -00001.22 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007571 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00998.05 ], Gyr [ 00001.04, 00000.49, -00003.84 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007572 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00999.02 ], Gyr [ -00001.04, 00004.27, 00000.00 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007573 NoMotion-1. Scaled. Acc [ -00038.57, -00004.39, 00986.82 ], Gyr [ -00000.12, 00002.01, -00002.50 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000007574 NoMotion-1. Scaled. Acc [ -00037.11, -00014.65, 00984.86 ], Gyr [ 00000.00, 00002.93, -00000.98 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000007575 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00997.07 ], Gyr [ -00000.12, 00003.54, -00001.04 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007576 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 01000.00 ], Gyr [ -00001.89, 00002.13, 00000.30 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007577 NoMotion-1. Scaled. Acc [ -00041.50, -00014.65, 00992.68 ], Gyr [ 00000.06, 00000.61, -00001.95 ], Mag [ 00019.65, 00085.65, 00108.45 ]",
    "#0000007578 NoMotion-1. Scaled. Acc [ -00043.46, -00010.74, 01001.95 ], Gyr [ 00000.24, 00000.85, -00001.10 ], Mag [ 00019.65, 00085.65, 00108.45 ]",
    "#0000007579 NoMotion-1. Scaled. Acc [ -00057.13, -00002.93, 00993.65 ], Gyr [ 00001.16, 00000.12, -00001.52 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007580 NoMotion-1. Scaled. Acc [ -00053.71, -00009.28, 00997.56 ], Gyr [ -00000.06, -00001.10, -00002.74 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007581 NoMotion-1. Scaled. Acc [ -00038.57, -00015.62, 00993.16 ], Gyr [ -00001.04, 00000.73, -00000.79 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007582 NoMotion-1. Scaled. Acc [ -00040.04, -00018.55, 00989.75 ], Gyr [ 00001.46, -00000.49, 00000.24 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007583 NoMotion-1. Scaled. Acc [ -00042.97, -00004.39, 00990.72 ], Gyr [ 00000.49, -00002.13, -00001.34 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000007584 NoMotion-1. Scaled. Acc [ -00057.62, -00001.46, 00986.33 ], Gyr [ -00000.12, 00001.59, -00001.77 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000007585 NoMotion-1. Scaled. Acc [ -00049.80, -00016.11, 00996.58 ], Gyr [ 00000.00, -00001.04, -00002.50 ], Mag [ 00019.35, 00082.95, 00109.05 ]",
    "#0000007586 NoMotion-1. Scaled. Acc [ -00050.78, -00013.18, 00990.72 ], Gyr [ 00002.26, -00001.10, -00001.65 ], Mag [ 00019.35, 00083.40, 00106.20 ]",
    "#0000007587 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 01000.98 ], Gyr [ 00001.22, 00001.04, -00000.43 ], Mag [ 00019.35, 00083.40, 00106.20 ]",
    "#0000007588 NoMotion-1. Scaled. Acc [ -00047.85, -00013.67, 01000.49 ], Gyr [ -00000.18, 00002.26, -00002.56 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007589 NoMotion-1. Scaled. Acc [ -00049.32, -00003.91, 00976.56 ], Gyr [ 00001.95, 00000.85, -00002.32 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007590 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00984.86 ], Gyr [ -00000.91, -00000.12, -00003.11 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007591 NoMotion-1. Scaled. Acc [ -00041.99, -00012.70, 00993.65 ], Gyr [ 00000.67, 00001.77, -00003.05 ], Mag [ 00019.65, 00084.15, 00109.05 ]",
    "#0000007592 NoMotion-1. Scaled. Acc [ -00045.90, -00015.62, 00995.12 ], Gyr [ 00001.52, -00000.06, -00000.73 ], Mag [ 00018.60, 00084.90, 00108.00 ]",
    "#0000007593 NoMotion-1. Scaled. Acc [ -00047.85, -00011.23, 00990.23 ], Gyr [ 00000.85, -00000.73, -00001.22 ], Mag [ 00019.05, 00082.95, 00108.00 ]",
    "#0000007594 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00988.28 ], Gyr [ -00000.12, 00004.02, -00003.05 ], Mag [ 00019.05, 00082.95, 00108.00 ]",
    "#0000007595 NoMotion-1. Scaled. Acc [ -00034.67, -00014.16, 01002.44 ], Gyr [ 00000.79, -00000.79, -00002.44 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007596 NoMotion-1. Scaled. Acc [ -00039.55, 00003.91, 00983.89 ], Gyr [ 00000.98, -00000.73, -00000.37 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007597 NoMotion-1. Scaled. Acc [ -00035.16, -00005.37, 00993.16 ], Gyr [ 00000.30, 00000.18, -00000.79 ], Mag [ 00018.75, 00084.75, 00107.55 ]",
    "#0000007598 NoMotion-1. Scaled. Acc [ -00040.53, -00014.65, 00989.75 ], Gyr [ -00002.38, 00002.07, -00002.13 ], Mag [ 00018.75, 00084.75, 00107.55 ]",
    "#0000007599 NoMotion-1. Scaled. Acc [ -00040.04, -00002.44, 00986.82 ], Gyr [ 00000.30, 00001.04, -00001.89 ], Mag [ 00018.75, 00084.75, 00107.55 ]",
    "#0000007600 NoMotion-1. Scaled. Acc [ -00040.04, -00003.91, 00988.77 ], Gyr [ 00001.77, 00002.93, -00001.04 ], Mag [ 00020.85, 00083.70, 00107.40 ]",
    "#0000007601 NoMotion-1. Scaled. Acc [ -00035.16, -00005.86, 00997.07 ], Gyr [ 00001.04, 00001.59, 00000.00 ], Mag [ 00020.85, 00083.70, 00107.40 ]",
    "#0000007602 NoMotion-1. Scaled. Acc [ -00033.20, 00006.35, 00998.54 ], Gyr [ 00000.85, 00002.01, -00003.72 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007603 NoMotion-1. Scaled. Acc [ -00042.97, 00002.44, 00982.91 ], Gyr [ 00000.37, 00001.46, -00002.38 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007604 NoMotion-1. Scaled. Acc [ -00036.13, -00005.37, 00989.26 ], Gyr [ 00001.46, 00002.62, -00002.20 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007605 NoMotion-1. Scaled. Acc [ -00036.13, -00016.60, 00996.09 ], Gyr [ 00000.43, 00002.99, -00000.98 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007606 NoMotion-1. Scaled. Acc [ -00037.60, -00011.23, 00996.09 ], Gyr [ 00000.37, 00000.12, -00001.16 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007607 NoMotion-1. Scaled. Acc [ -00057.13, 00001.95, 00987.79 ], Gyr [ 00001.89, 00001.46, -00002.20 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007608 NoMotion-1. Scaled. Acc [ -00036.13, -00001.46, 00983.89 ], Gyr [ -00000.37, 00002.13, -00002.01 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007609 NoMotion-1. Scaled. Acc [ -00044.92, 00004.88, 00994.63 ], Gyr [ 00000.61, 00003.48, -00004.51 ], Mag [ 00020.10, 00081.45, 00107.70 ]",
    "#0000007610 NoMotion-1. Scaled. Acc [ -00044.43, -00017.58, 00996.58 ], Gyr [ 00001.71, 00000.55, -00000.73 ], Mag [ 00020.10, 00083.70, 00106.95 ]",
    "#0000007611 NoMotion-1. Scaled. Acc [ -00039.06, -00012.70, 01002.44 ], Gyr [ -00000.30, 00000.85, -00000.61 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007612 NoMotion-1. Scaled. Acc [ -00040.04, -00005.37, 00983.89 ], Gyr [ -00000.49, -00000.43, -00002.80 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007613 NoMotion-1. Scaled. Acc [ -00034.67, -00007.81, 00985.84 ], Gyr [ 00000.30, 00001.04, -00001.52 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007614 NoMotion-1. Scaled. Acc [ -00029.79, -00009.77, 01007.32 ], Gyr [ -00000.24, 00002.87, -00001.46 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007615 NoMotion-1. Scaled. Acc [ -00036.13, 00001.95, 01001.46 ], Gyr [ 00000.12, 00002.62, -00000.98 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007616 NoMotion-1. Scaled. Acc [ -00047.36, 00002.44, 00994.14 ], Gyr [ -00000.91, 00002.80, -00004.09 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007617 NoMotion-1. Scaled. Acc [ -00045.90, -00005.37, 00983.40 ], Gyr [ 00000.98, 00001.77, 00000.43 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007618 NoMotion-1. Scaled. Acc [ -00043.46, -00004.39, 00999.02 ], Gyr [ 00002.07, 00001.77, 00000.91 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000007619 NoMotion-1. Scaled. Acc [ -00041.50, -00016.11, 00997.56 ], Gyr [ -00001.16, -00000.73, -00000.55 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007620 NoMotion-1. Scaled. Acc [ -00053.71, -00011.72, 00990.72 ], Gyr [ 00000.30, 00001.95, -00003.11 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007621 NoMotion-1. Scaled. Acc [ -00052.73, -00009.28, 00991.21 ], Gyr [ 00000.85, 00000.12, 00002.38 ], Mag [ 00019.35, 00082.95, 00109.05 ]",
    "#0000007622 NoMotion-1. Scaled. Acc [ -00034.67, -00001.95, 00983.89 ], Gyr [ 00001.22, 00002.38, -00001.71 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000007623 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00983.40 ], Gyr [ 00001.16, 00002.26, -00003.72 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000007624 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00981.45 ], Gyr [ -00001.52, 00000.67, -00001.95 ], Mag [ 00018.45, 00083.55, 00107.55 ]",
    "#0000007625 NoMotion-1. Scaled. Acc [ -00040.53, 00000.49, 00990.23 ], Gyr [ -00001.46, 00002.26, -00000.98 ], Mag [ 00018.45, 00084.75, 00105.75 ]",
    "#0000007626 NoMotion-1. Scaled. Acc [ -00032.71, -00015.62, 00981.45 ], Gyr [ 00000.18, 00003.72, -00000.67 ], Mag [ 00018.45, 00084.75, 00105.75 ]",
    "#0000007627 NoMotion-1. Scaled. Acc [ -00054.69, -00009.77, 01000.00 ], Gyr [ 00000.24, 00000.79, -00001.46 ], Mag [ 00018.45, 00084.75, 00105.75 ]",
    "#0000007628 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00988.77 ], Gyr [ 00000.49, 00002.93, -00000.98 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000007629 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00987.30 ], Gyr [ -00000.49, 00001.71, -00001.52 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000007630 NoMotion-1. Scaled. Acc [ -00053.22, -00007.32, 00992.68 ], Gyr [ 00000.61, 00001.89, 00000.37 ], Mag [ 00020.40, 00082.95, 00106.20 ]",
    "#0000007631 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00996.09 ], Gyr [ 00000.55, 00000.73, -00001.04 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007632 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00994.63 ], Gyr [ 00000.24, 00003.90, -00001.40 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007633 NoMotion-1. Scaled. Acc [ -00033.69, 00002.93, 00991.70 ], Gyr [ 00002.50, 00002.01, -00001.52 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007634 NoMotion-1. Scaled. Acc [ -00063.96, -00005.86, 00994.63 ], Gyr [ 00002.26, -00000.55, 00001.28 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007635 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00978.03 ], Gyr [ 00001.04, 00001.04, -00002.26 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007636 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 01005.37 ], Gyr [ -00001.71, 00000.12, -00003.29 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007637 NoMotion-1. Scaled. Acc [ -00040.04, -00004.39, 00984.86 ], Gyr [ 00000.12, 00001.16, 00000.43 ], Mag [ 00020.70, 00084.00, 00109.35 ]",
    "#0000007638 NoMotion-1. Scaled. Acc [ -00042.97, -00004.39, 00983.89 ], Gyr [ 00001.28, 00001.28, 00001.52 ], Mag [ 00020.70, 00084.00, 00109.35 ]",
    "#0000007639 NoMotion-1. Scaled. Acc [ -00033.69, -00005.86, 00978.52 ], Gyr [ -00000.43, 00001.46, -00001.59 ], Mag [ 00019.95, 00082.50, 00107.85 ]",
    "#0000007640 NoMotion-1. Scaled. Acc [ -00039.55, 00000.00, 00988.77 ], Gyr [ 00002.13, 00000.73, -00003.35 ], Mag [ 00019.95, 00082.50, 00107.85 ]",
    "#0000007641 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00986.33 ], Gyr [ -00000.91, 00001.34, -00000.98 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007642 NoMotion-1. Scaled. Acc [ -00049.80, -00010.74, 00983.89 ], Gyr [ 00000.00, 00001.71, -00003.41 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007643 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00988.77 ], Gyr [ -00000.73, 00002.44, -00002.50 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007644 NoMotion-1. Scaled. Acc [ -00034.67, -00006.35, 00990.23 ], Gyr [ 00000.24, 00001.89, -00000.67 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007645 NoMotion-1. Scaled. Acc [ -00044.43, 00000.49, 00988.77 ], Gyr [ 00000.43, -00000.12, -00002.13 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007646 NoMotion-1. Scaled. Acc [ -00048.34, 00000.00, 00991.70 ], Gyr [ 00002.01, -00000.43, -00000.43 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007647 NoMotion-1. Scaled. Acc [ -00048.34, -00002.93, 00986.82 ], Gyr [ 00000.67, -00001.10, -00002.62 ], Mag [ 00019.65, 00082.65, 00108.45 ]",
    "#0000007648 NoMotion-1. Scaled. Acc [ -00042.48, -00014.65, 00982.91 ], Gyr [ 00000.79, -00000.12, 00000.30 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000007649 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00988.28 ], Gyr [ -00001.16, 00003.96, -00004.09 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000007650 NoMotion-1. Scaled. Acc [ -00047.85, -00004.88, 00995.61 ], Gyr [ 00001.89, 00003.29, -00002.07 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007651 NoMotion-1. Scaled. Acc [ -00046.88, 00000.98, 00987.30 ], Gyr [ -00001.40, 00001.65, -00000.79 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007652 NoMotion-1. Scaled. Acc [ -00050.78, -00011.23, 00988.77 ], Gyr [ -00000.18, 00003.66, -00002.13 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007653 NoMotion-1. Scaled. Acc [ -00048.83, 00000.49, 00997.07 ], Gyr [ 00001.59, 00001.10, -00000.73 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007654 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00983.40 ], Gyr [ 00001.10, -00000.61, -00001.22 ], Mag [ 00019.65, 00082.65, 00106.65 ]",
    "#0000007655 NoMotion-1. Scaled. Acc [ -00035.64, -00008.30, 00994.63 ], Gyr [ 00001.04, 00000.85, 00001.04 ], Mag [ 00019.65, 00082.65, 00106.65 ]",
    "#0000007656 NoMotion-1. Scaled. Acc [ -00050.78, -00006.84, 00992.19 ], Gyr [ 00000.67, 00002.99, -00001.83 ], Mag [ 00020.40, 00082.65, 00106.95 ]",
    "#0000007657 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00995.12 ], Gyr [ 00002.50, 00000.55, -00002.20 ], Mag [ 00020.40, 00082.65, 00106.95 ]",
    "#0000007658 NoMotion-1. Scaled. Acc [ -00032.23, -00023.44, 00978.03 ], Gyr [ 00000.85, -00002.38, -00000.67 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007659 NoMotion-1. Scaled. Acc [ -00043.95, -00004.88, 00988.28 ], Gyr [ 00002.68, 00001.65, 00000.00 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007660 NoMotion-1. Scaled. Acc [ -00032.23, -00010.25, 00991.70 ], Gyr [ -00000.61, -00000.79, 00000.18 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007661 NoMotion-1. Scaled. Acc [ -00045.90, -00008.79, 00997.56 ], Gyr [ -00001.04, 00000.67, -00001.16 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007662 NoMotion-1. Scaled. Acc [ -00047.85, 00000.98, 00998.54 ], Gyr [ 00000.79, -00000.55, -00000.98 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007663 NoMotion-1. Scaled. Acc [ -00046.39, -00012.70, 00996.09 ], Gyr [ -00000.73, 00001.77, -00003.48 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007664 NoMotion-1. Scaled. Acc [ -00041.02, -00012.21, 00985.84 ], Gyr [ -00001.16, 00001.10, -00002.07 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007665 NoMotion-1. Scaled. Acc [ -00048.83, -00011.23, 00984.38 ], Gyr [ 00001.10, -00001.95, -00001.52 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007666 NoMotion-1. Scaled. Acc [ -00042.48, -00005.86, 00997.07 ], Gyr [ 00001.22, 00001.22, -00001.28 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007667 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00991.70 ], Gyr [ 00000.91, 00001.52, 00000.67 ], Mag [ 00019.65, 00083.70, 00108.45 ]",
    "#0000007668 NoMotion-1. Scaled. Acc [ -00043.46, -00014.16, 00987.79 ], Gyr [ -00000.73, 00001.71, 00000.00 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007669 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00994.14 ], Gyr [ 00002.74, 00000.98, -00000.55 ], Mag [ 00018.75, 00082.50, 00106.80 ]",
    "#0000007670 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00999.51 ], Gyr [ 00002.62, -00000.12, -00002.87 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000007671 NoMotion-1. Scaled. Acc [ -00061.04, -00005.37, 01002.44 ], Gyr [ 00001.52, 00001.83, -00002.07 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000007672 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00984.38 ], Gyr [ 00000.24, 00001.46, -00001.04 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007673 NoMotion-1. Scaled. Acc [ -00040.53, -00009.77, 00983.89 ], Gyr [ 00000.43, 00002.13, 00000.49 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007674 NoMotion-1. Scaled. Acc [ -00039.55, -00010.74, 00982.91 ], Gyr [ 00000.67, 00004.76, -00003.96 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007675 NoMotion-1. Scaled. Acc [ -00040.04, -00006.84, 01003.42 ], Gyr [ 00001.89, -00000.43, -00000.37 ], Mag [ 00019.05, 00082.65, 00107.70 ]",
    "#0000007676 NoMotion-1. Scaled. Acc [ -00056.15, -00009.28, 00991.70 ], Gyr [ -00000.49, 00002.74, -00002.44 ], Mag [ 00019.65, 00084.15, 00108.75 ]",
    "#0000007677 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00983.40 ], Gyr [ 00001.95, -00000.61, -00001.65 ], Mag [ 00019.65, 00084.15, 00108.75 ]",
    "#0000007678 NoMotion-1. Scaled. Acc [ -00044.43, -00013.67, 00991.21 ], Gyr [ 00001.46, 00000.37, -00000.61 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000007679 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 00993.65 ], Gyr [ 00000.49, 00001.16, -00002.80 ], Mag [ 00018.45, 00083.55, 00106.50 ]",
    "#0000007680 NoMotion-1. Scaled. Acc [ -00050.78, -00014.16, 00983.89 ], Gyr [ 00002.62, 00001.89, -00001.34 ], Mag [ 00018.45, 00083.55, 00106.50 ]",
    "#0000007681 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00991.70 ], Gyr [ 00002.50, 00003.66, -00003.54 ], Mag [ 00018.45, 00083.55, 00106.50 ]",
    "#0000007682 NoMotion-1. Scaled. Acc [ -00051.76, -00013.67, 00985.35 ], Gyr [ -00001.04, 00000.43, -00002.32 ], Mag [ 00018.60, 00082.20, 00108.45 ]",
    "#0000007683 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00997.56 ], Gyr [ 00000.79, 00001.89, -00001.71 ], Mag [ 00019.05, 00084.15, 00107.70 ]",
    "#0000007684 NoMotion-1. Scaled. Acc [ -00053.22, -00003.91, 00997.56 ], Gyr [ 00000.55, 00002.99, -00002.50 ], Mag [ 00019.05, 00084.15, 00107.70 ]",
    "#0000007685 NoMotion-1. Scaled. Acc [ -00031.74, -00003.42, 00990.23 ], Gyr [ 00001.10, 00000.55, -00003.35 ], Mag [ 00018.45, 00083.25, 00106.50 ]",
    "#0000007686 NoMotion-1. Scaled. Acc [ -00045.41, 00002.93, 00999.51 ], Gyr [ -00001.22, 00002.56, -00001.65 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007687 NoMotion-1. Scaled. Acc [ -00042.48, -00024.41, 00991.21 ], Gyr [ 00000.43, 00000.55, 00002.56 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007688 NoMotion-1. Scaled. Acc [ -00033.20, 00004.88, 00985.35 ], Gyr [ -00001.04, 00001.16, -00001.83 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007689 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 00991.21 ], Gyr [ 00000.06, 00003.29, -00001.59 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007690 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00994.14 ], Gyr [ 00001.28, 00002.07, 00000.06 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007691 NoMotion-1. Scaled. Acc [ -00038.09, -00005.86, 00989.26 ], Gyr [ 00001.52, -00000.85, 00000.91 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007692 NoMotion-1. Scaled. Acc [ -00039.06, -00015.62, 00986.33 ], Gyr [ -00001.46, 00004.09, -00001.28 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000007693 NoMotion-1. Scaled. Acc [ -00044.92, 00009.28, 00998.05 ], Gyr [ -00000.79, 00002.68, -00002.01 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000007694 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00990.23 ], Gyr [ 00000.18, 00000.85, -00003.60 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000007695 NoMotion-1. Scaled. Acc [ -00036.62, -00007.81, 00984.86 ], Gyr [ 00001.04, 00001.16, -00001.95 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000007696 NoMotion-1. Scaled. Acc [ -00041.99, -00003.42, 00998.05 ], Gyr [ 00000.61, 00000.18, -00002.26 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007697 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00991.21 ], Gyr [ 00000.30, 00004.63, -00000.37 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007698 NoMotion-1. Scaled. Acc [ -00051.27, -00014.65, 00996.09 ], Gyr [ -00001.40, 00003.11, -00001.71 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007699 NoMotion-1. Scaled. Acc [ -00048.83, 00003.42, 00997.07 ], Gyr [ -00000.49, 00002.74, -00000.79 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000007700 NoMotion-1. Scaled. Acc [ -00045.90, -00004.88, 01000.98 ], Gyr [ 00000.67, 00001.04, -00003.05 ], Mag [ 00019.95, 00083.25, 00107.55 ]",
    "#0000007701 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00980.47 ], Gyr [ -00000.12, 00002.01, -00000.30 ], Mag [ 00019.95, 00083.25, 00107.55 ]",
    "#0000007702 NoMotion-1. Scaled. Acc [ -00037.11, -00014.16, 00994.63 ], Gyr [ -00000.24, 00000.67, -00003.17 ], Mag [ 00018.75, 00084.00, 00108.60 ]",
    "#0000007703 NoMotion-1. Scaled. Acc [ -00043.95, -00013.67, 00984.86 ], Gyr [ 00000.55, -00000.06, -00001.59 ], Mag [ 00018.75, 00084.00, 00108.60 ]",
    "#0000007704 NoMotion-1. Scaled. Acc [ -00034.18, -00017.09, 00987.30 ], Gyr [ 00002.44, 00001.95, -00003.48 ], Mag [ 00018.75, 00083.25, 00106.50 ]",
    "#0000007705 NoMotion-1. Scaled. Acc [ -00038.09, -00019.04, 00972.66 ], Gyr [ 00000.55, 00000.49, 00002.38 ], Mag [ 00017.55, 00083.70, 00107.40 ]",
    "#0000007706 NoMotion-1. Scaled. Acc [ -00044.43, -00019.04, 00988.77 ], Gyr [ -00000.67, 00002.80, -00003.90 ], Mag [ 00017.55, 00083.70, 00107.40 ]",
    "#0000007707 NoMotion-1. Scaled. Acc [ -00037.60, -00010.74, 00983.40 ], Gyr [ 00002.93, -00001.89, 00000.12 ], Mag [ 00017.55, 00083.70, 00107.40 ]",
    "#0000007708 NoMotion-1. Scaled. Acc [ -00042.97, -00008.30, 00985.84 ], Gyr [ 00001.10, 00001.71, -00000.24 ], Mag [ 00019.65, 00085.65, 00109.50 ]",
    "#0000007709 NoMotion-1. Scaled. Acc [ -00035.64, -00006.84, 00998.05 ], Gyr [ 00000.98, 00000.43, -00001.46 ], Mag [ 00019.65, 00085.65, 00109.50 ]",
    "#0000007710 NoMotion-1. Scaled. Acc [ -00032.23, -00012.21, 00984.86 ], Gyr [ 00000.18, 00000.30, -00000.91 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000007711 NoMotion-1. Scaled. Acc [ -00048.34, -00012.21, 00991.70 ], Gyr [ -00000.98, 00005.67, -00003.54 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000007712 NoMotion-1. Scaled. Acc [ -00046.39, -00006.84, 00999.51 ], Gyr [ 00000.98, 00004.76, -00003.66 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000007713 NoMotion-1. Scaled. Acc [ -00032.23, -00007.81, 00977.05 ], Gyr [ 00000.00, 00002.44, -00001.28 ], Mag [ 00018.75, 00083.25, 00107.10 ]",
    "#0000007714 NoMotion-1. Scaled. Acc [ -00037.11, -00008.30, 00989.75 ], Gyr [ 00000.12, 00000.43, -00002.13 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007715 NoMotion-1. Scaled. Acc [ -00032.23, -00006.35, 00995.61 ], Gyr [ -00001.04, 00001.16, -00000.98 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007716 NoMotion-1. Scaled. Acc [ -00054.69, -00025.88, 00991.21 ], Gyr [ 00000.98, 00000.79, 00001.89 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007717 NoMotion-1. Scaled. Acc [ -00051.27, 00003.91, 00994.14 ], Gyr [ 00003.23, 00000.55, -00001.95 ], Mag [ 00020.25, 00082.50, 00108.30 ]",
    "#0000007718 NoMotion-1. Scaled. Acc [ -00046.39, -00006.84, 01007.32 ], Gyr [ -00000.37, 00000.55, -00001.59 ], Mag [ 00019.20, 00084.75, 00107.85 ]",
    "#0000007719 NoMotion-1. Scaled. Acc [ -00055.18, 00000.00, 00991.21 ], Gyr [ 00002.80, 00000.49, -00001.28 ], Mag [ 00019.20, 00084.75, 00107.85 ]",
    "#0000007720 NoMotion-1. Scaled. Acc [ -00055.18, -00020.02, 00989.75 ], Gyr [ 00001.59, 00000.12, -00002.44 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007721 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00997.07 ], Gyr [ -00001.34, 00001.28, -00001.59 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007722 NoMotion-1. Scaled. Acc [ -00024.90, -00008.79, 00988.28 ], Gyr [ 00000.30, 00000.67, -00000.43 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007723 NoMotion-1. Scaled. Acc [ -00047.85, 00007.81, 00992.68 ], Gyr [ 00000.61, 00001.10, -00002.13 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007724 NoMotion-1. Scaled. Acc [ -00061.52, -00026.37, 00995.61 ], Gyr [ 00000.85, 00002.93, 00000.85 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007725 NoMotion-1. Scaled. Acc [ -00041.02, 00009.77, 00996.09 ], Gyr [ 00000.18, 00002.13, -00001.83 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007726 NoMotion-1. Scaled. Acc [ -00038.09, -00007.32, 00996.58 ], Gyr [ 00001.46, 00001.83, -00000.37 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007727 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00993.65 ], Gyr [ 00001.89, 00002.80, -00001.46 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000007728 NoMotion-1. Scaled. Acc [ -00049.32, -00009.77, 00989.26 ], Gyr [ 00002.01, 00000.67, -00001.22 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000007729 NoMotion-1. Scaled. Acc [ -00042.97, -00004.88, 00996.09 ], Gyr [ -00000.61, 00002.38, -00001.28 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007730 NoMotion-1. Scaled. Acc [ -00053.71, 00002.44, 00986.82 ], Gyr [ 00001.95, 00000.24, -00000.43 ], Mag [ 00020.25, 00084.00, 00107.55 ]",
    "#0000007731 NoMotion-1. Scaled. Acc [ -00043.95, -00015.14, 00985.84 ], Gyr [ 00001.10, 00002.56, 00000.00 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007732 NoMotion-1. Scaled. Acc [ -00034.18, -00018.07, 00995.12 ], Gyr [ 00002.62, -00000.37, -00002.44 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007733 NoMotion-1. Scaled. Acc [ -00035.64, -00014.65, 00989.75 ], Gyr [ 00002.44, -00000.67, 00001.04 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007734 NoMotion-1. Scaled. Acc [ -00038.57, -00004.39, 00982.42 ], Gyr [ 00000.06, 00002.38, -00000.67 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007735 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 01000.00 ], Gyr [ 00002.01, 00002.13, -00002.38 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007736 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00977.05 ], Gyr [ 00000.37, 00000.61, -00002.20 ], Mag [ 00020.70, 00082.80, 00108.60 ]",
    "#0000007737 NoMotion-1. Scaled. Acc [ -00036.62, -00007.81, 00986.33 ], Gyr [ 00000.00, -00000.37, 00000.55 ], Mag [ 00020.70, 00082.80, 00108.60 ]",
    "#0000007738 NoMotion-1. Scaled. Acc [ -00060.06, -00014.65, 00995.61 ], Gyr [ -00000.85, 00002.32, -00004.21 ], Mag [ 00019.95, 00082.80, 00108.60 ]",
    "#0000007739 NoMotion-1. Scaled. Acc [ -00060.06, -00016.60, 00990.23 ], Gyr [ 00000.12, 00001.95, -00002.80 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000007740 NoMotion-1. Scaled. Acc [ -00052.25, -00003.42, 00986.82 ], Gyr [ 00001.46, -00000.49, -00000.79 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000007741 NoMotion-1. Scaled. Acc [ -00048.34, 00002.44, 00990.23 ], Gyr [ 00002.68, 00002.93, -00000.43 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007742 NoMotion-1. Scaled. Acc [ -00056.15, 00005.86, 00988.28 ], Gyr [ -00000.91, 00000.73, -00004.39 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007743 NoMotion-1. Scaled. Acc [ -00032.71, -00005.37, 00986.33 ], Gyr [ 00001.28, 00001.65, -00002.74 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007744 NoMotion-1. Scaled. Acc [ -00045.90, 00002.93, 00990.72 ], Gyr [ 00000.18, 00001.65, -00001.71 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007745 NoMotion-1. Scaled. Acc [ -00038.57, -00007.32, 00982.91 ], Gyr [ -00001.22, 00002.01, -00001.28 ], Mag [ 00019.95, 00081.75, 00108.30 ]",
    "#0000007746 NoMotion-1. Scaled. Acc [ -00056.64, -00010.74, 00985.84 ], Gyr [ 00000.43, 00000.98, -00003.29 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007747 NoMotion-1. Scaled. Acc [ -00049.32, -00004.39, 00995.12 ], Gyr [ 00001.77, 00000.55, -00001.71 ], Mag [ 00019.50, 00084.30, 00107.85 ]",
    "#0000007748 NoMotion-1. Scaled. Acc [ -00039.55, -00003.42, 00985.84 ], Gyr [ 00000.18, 00004.88, -00002.13 ], Mag [ 00019.50, 00084.30, 00107.85 ]",
    "#0000007749 NoMotion-1. Scaled. Acc [ -00048.83, 00009.28, 00985.84 ], Gyr [ 00000.43, 00002.38, -00000.30 ], Mag [ 00019.20, 00084.30, 00107.85 ]",
    "#0000007750 NoMotion-1. Scaled. Acc [ -00025.88, -00001.46, 00987.79 ], Gyr [ -00001.22, 00003.66, -00003.90 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000007751 NoMotion-1. Scaled. Acc [ -00027.83, -00005.37, 00990.23 ], Gyr [ -00001.59, 00002.56, 00001.40 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000007752 NoMotion-1. Scaled. Acc [ -00046.88, -00017.09, 00988.77 ], Gyr [ -00000.24, 00002.26, -00003.05 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000007753 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00997.56 ], Gyr [ 00000.85, 00004.09, -00000.73 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000007754 NoMotion-1. Scaled. Acc [ -00036.62, -00017.58, 00986.82 ], Gyr [ 00002.20, -00000.49, -00003.05 ], Mag [ 00018.75, 00082.50, 00107.10 ]",
    "#0000007755 NoMotion-1. Scaled. Acc [ -00057.13, -00009.28, 00997.56 ], Gyr [ 00001.40, 00000.91, -00000.85 ], Mag [ 00018.75, 00082.50, 00107.10 ]",
    "#0000007756 NoMotion-1. Scaled. Acc [ -00038.57, 00000.98, 00995.12 ], Gyr [ 00000.73, 00002.68, -00001.83 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007757 NoMotion-1. Scaled. Acc [ -00053.71, -00002.44, 00992.68 ], Gyr [ -00000.37, -00000.12, -00002.13 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007758 NoMotion-1. Scaled. Acc [ -00051.27, -00015.14, 00988.28 ], Gyr [ 00002.07, 00002.99, 00000.37 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007759 NoMotion-1. Scaled. Acc [ -00048.83, -00013.67, 00991.21 ], Gyr [ 00003.05, 00000.24, -00002.07 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007760 NoMotion-1. Scaled. Acc [ -00049.80, -00008.30, 00986.33 ], Gyr [ 00000.61, 00000.24, -00002.26 ], Mag [ 00019.35, 00082.65, 00106.65 ]",
    "#0000007761 NoMotion-1. Scaled. Acc [ -00037.60, 00004.88, 00979.98 ], Gyr [ -00000.12, 00000.85, -00003.72 ], Mag [ 00019.35, 00082.65, 00106.65 ]",
    "#0000007762 NoMotion-1. Scaled. Acc [ -00049.80, -00017.58, 01004.88 ], Gyr [ -00000.55, 00001.59, -00003.66 ], Mag [ 00019.35, 00082.65, 00106.65 ]",
    "#0000007763 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00997.07 ], Gyr [ 00001.59, 00002.68, -00001.83 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000007764 NoMotion-1. Scaled. Acc [ -00046.88, -00012.70, 01005.37 ], Gyr [ -00000.91, 00001.28, -00000.55 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000007765 NoMotion-1. Scaled. Acc [ -00029.30, -00013.67, 00985.35 ], Gyr [ 00000.37, 00001.65, -00002.56 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000007766 NoMotion-1. Scaled. Acc [ -00042.97, -00019.04, 00991.21 ], Gyr [ 00000.91, 00003.05, -00000.24 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000007767 NoMotion-1. Scaled. Acc [ -00054.20, 00000.49, 00986.82 ], Gyr [ 00000.91, 00002.26, -00000.49 ], Mag [ 00019.05, 00084.15, 00108.45 ]",
    "#0000007768 NoMotion-1. Scaled. Acc [ -00041.99, 00003.91, 00982.91 ], Gyr [ 00001.40, 00001.77, -00002.01 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007769 NoMotion-1. Scaled. Acc [ -00041.50, 00001.46, 00997.07 ], Gyr [ 00001.28, 00000.91, -00002.20 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007770 NoMotion-1. Scaled. Acc [ -00034.67, -00002.44, 00992.68 ], Gyr [ 00000.73, 00001.34, -00002.01 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007771 NoMotion-1. Scaled. Acc [ -00045.41, -00016.60, 00986.33 ], Gyr [ 00001.34, 00000.73, -00000.79 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007772 NoMotion-1. Scaled. Acc [ -00039.06, -00003.42, 00995.61 ], Gyr [ 00000.91, 00001.16, -00002.93 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000007773 NoMotion-1. Scaled. Acc [ -00051.27, -00003.91, 00988.77 ], Gyr [ 00000.24, 00001.16, -00000.49 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000007774 NoMotion-1. Scaled. Acc [ -00042.97, -00016.60, 00985.84 ], Gyr [ -00000.49, 00000.55, -00002.62 ], Mag [ 00020.40, 00084.90, 00107.70 ]",
    "#0000007775 NoMotion-1. Scaled. Acc [ -00040.04, -00007.81, 00994.63 ], Gyr [ 00000.00, -00000.49, -00002.32 ], Mag [ 00020.40, 00084.90, 00107.70 ]",
    "#0000007776 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00993.16 ], Gyr [ -00000.12, 00000.98, -00001.83 ], Mag [ 00020.25, 00084.00, 00106.80 ]",
    "#0000007777 NoMotion-1. Scaled. Acc [ -00047.85, -00013.67, 00986.33 ], Gyr [ 00001.95, 00003.78, 00000.00 ], Mag [ 00020.25, 00084.00, 00106.80 ]",
    "#0000007778 NoMotion-1. Scaled. Acc [ -00059.57, -00010.25, 00989.75 ], Gyr [ 00000.24, 00002.32, -00002.68 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000007779 NoMotion-1. Scaled. Acc [ -00053.71, -00007.81, 00988.77 ], Gyr [ 00000.55, 00000.79, -00000.12 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000007780 NoMotion-1. Scaled. Acc [ -00056.15, -00020.51, 00993.16 ], Gyr [ 00001.04, 00001.59, -00001.77 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000007781 NoMotion-1. Scaled. Acc [ -00050.78, -00012.21, 00987.30 ], Gyr [ 00002.87, 00000.49, -00000.30 ], Mag [ 00018.45, 00082.80, 00107.55 ]",
    "#0000007782 NoMotion-1. Scaled. Acc [ -00049.80, -00010.25, 00990.23 ], Gyr [ 00001.65, 00001.22, -00000.12 ], Mag [ 00018.45, 00082.80, 00107.55 ]",
    "#0000007783 NoMotion-1. Scaled. Acc [ -00032.71, -00002.93, 00989.26 ], Gyr [ 00002.68, -00001.89, -00001.52 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007784 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00981.93 ], Gyr [ -00001.10, 00001.46, -00000.43 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007785 NoMotion-1. Scaled. Acc [ -00046.88, -00004.88, 00996.09 ], Gyr [ -00000.37, 00002.87, -00003.54 ], Mag [ 00018.60, 00082.65, 00106.65 ]",
    "#0000007786 NoMotion-1. Scaled. Acc [ -00042.48, -00010.25, 00989.75 ], Gyr [ -00000.30, -00001.59, -00000.24 ], Mag [ 00018.60, 00082.65, 00106.65 ]",
    "#0000007787 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00991.21 ], Gyr [ 00002.20, -00000.06, -00001.22 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000007788 NoMotion-1. Scaled. Acc [ -00047.36, -00001.95, 00998.54 ], Gyr [ -00001.46, 00001.40, -00001.52 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000007789 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 00986.33 ], Gyr [ 00001.71, 00006.89, -00003.78 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000007790 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00995.61 ], Gyr [ 00000.67, 00002.38, -00003.66 ], Mag [ 00018.60, 00083.70, 00109.05 ]",
    "#0000007791 NoMotion-1. Scaled. Acc [ -00051.27, -00012.21, 00997.07 ], Gyr [ 00001.28, 00001.40, 00000.24 ], Mag [ 00020.70, 00082.50, 00106.50 ]",
    "#0000007792 NoMotion-1. Scaled. Acc [ -00046.39, -00012.21, 00989.75 ], Gyr [ 00001.77, 00001.04, -00001.34 ], Mag [ 00020.70, 00082.50, 00106.50 ]",
    "#0000007793 NoMotion-1. Scaled. Acc [ -00053.22, -00011.23, 00987.79 ], Gyr [ 00001.10, 00000.67, -00000.98 ], Mag [ 00019.95, 00083.25, 00107.10 ]",
    "#0000007794 NoMotion-1. Scaled. Acc [ -00042.97, -00006.35, 00983.89 ], Gyr [ 00000.00, 00001.77, -00003.05 ], Mag [ 00019.95, 00083.25, 00107.10 ]",
    "#0000007795 NoMotion-1. Scaled. Acc [ -00041.02, 00001.46, 00984.38 ], Gyr [ 00001.89, 00001.40, 00001.83 ], Mag [ 00019.95, 00083.25, 00107.10 ]",
    "#0000007796 NoMotion-1. Scaled. Acc [ -00039.06, -00021.97, 00986.33 ], Gyr [ -00000.79, 00002.32, -00001.65 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000007797 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00985.35 ], Gyr [ -00000.79, 00000.98, -00001.52 ], Mag [ 00017.70, 00084.30, 00108.30 ]",
    "#0000007798 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 01005.86 ], Gyr [ -00001.04, 00003.29, -00002.44 ], Mag [ 00017.70, 00084.30, 00108.30 ]",
    "#0000007799 NoMotion-1. Scaled. Acc [ -00033.69, -00008.30, 00987.30 ], Gyr [ -00000.24, 00002.01, 00000.79 ], Mag [ 00017.70, 00084.30, 00108.30 ]",
    "#0000007800 NoMotion-1. Scaled. Acc [ -00043.46, -00011.72, 00999.02 ], Gyr [ 00000.24, 00002.62, -00004.15 ], Mag [ 00018.75, 00084.00, 00107.55 ]",
    "#0000007801 NoMotion-1. Scaled. Acc [ -00038.57, 00005.86, 00992.19 ], Gyr [ -00000.55, 00002.62, -00004.76 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007802 NoMotion-1. Scaled. Acc [ -00042.97, 00005.86, 00986.33 ], Gyr [ 00000.30, 00000.61, -00002.74 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007803 NoMotion-1. Scaled. Acc [ -00050.78, -00013.67, 01002.93 ], Gyr [ 00000.06, -00000.67, -00001.28 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007804 NoMotion-1. Scaled. Acc [ -00031.25, -00003.91, 00991.70 ], Gyr [ 00001.28, 00001.95, -00000.79 ], Mag [ 00019.95, 00082.50, 00105.45 ]",
    "#0000007805 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00980.47 ], Gyr [ 00001.59, 00002.62, 00001.77 ], Mag [ 00019.65, 00084.90, 00107.70 ]",
    "#0000007806 NoMotion-1. Scaled. Acc [ -00040.53, 00003.42, 00989.26 ], Gyr [ 00002.80, 00002.26, -00001.28 ], Mag [ 00019.65, 00084.90, 00107.70 ]",
    "#0000007807 NoMotion-1. Scaled. Acc [ -00026.86, -00003.91, 00983.89 ], Gyr [ 00001.34, -00000.91, -00000.18 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007808 NoMotion-1. Scaled. Acc [ -00041.99, -00004.39, 00989.26 ], Gyr [ -00001.04, 00000.49, -00000.12 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000007809 NoMotion-1. Scaled. Acc [ -00047.36, -00002.44, 00998.05 ], Gyr [ -00000.67, 00001.16, -00002.38 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000007810 NoMotion-1. Scaled. Acc [ -00039.55, -00008.30, 00998.05 ], Gyr [ 00001.10, 00000.85, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000007811 NoMotion-1. Scaled. Acc [ -00057.62, -00004.39, 00991.70 ], Gyr [ 00000.18, 00001.16, -00000.79 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007812 NoMotion-1. Scaled. Acc [ -00037.60, -00011.23, 00982.91 ], Gyr [ -00000.79, 00002.62, -00003.05 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007813 NoMotion-1. Scaled. Acc [ -00041.02, 00000.98, 00994.14 ], Gyr [ -00002.13, 00002.99, -00001.59 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007814 NoMotion-1. Scaled. Acc [ -00047.36, -00016.60, 00986.82 ], Gyr [ 00000.43, 00001.28, -00000.30 ], Mag [ 00019.20, 00083.25, 00107.10 ]",
    "#0000007815 NoMotion-1. Scaled. Acc [ -00047.36, -00016.11, 00990.72 ], Gyr [ 00002.50, 00000.67, -00002.74 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007816 NoMotion-1. Scaled. Acc [ -00037.60, -00000.98, 00981.45 ], Gyr [ 00000.55, 00003.41, 00000.06 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007817 NoMotion-1. Scaled. Acc [ -00039.55, -00016.11, 00999.02 ], Gyr [ -00000.79, 00001.40, -00002.50 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007818 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00993.16 ], Gyr [ 00002.38, 00000.00, -00002.26 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007819 NoMotion-1. Scaled. Acc [ -00033.69, -00004.39, 01002.93 ], Gyr [ 00000.18, -00000.98, 00000.85 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000007820 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00985.35 ], Gyr [ 00000.37, 00000.24, -00001.77 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007821 NoMotion-1. Scaled. Acc [ -00055.18, -00015.14, 00985.84 ], Gyr [ 00000.43, -00001.65, -00002.38 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007822 NoMotion-1. Scaled. Acc [ -00045.90, -00009.28, 01000.98 ], Gyr [ 00001.40, -00001.40, 00002.20 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007823 NoMotion-1. Scaled. Acc [ -00038.09, -00013.18, 00991.21 ], Gyr [ -00001.34, 00000.91, -00003.84 ], Mag [ 00020.40, 00084.90, 00108.00 ]",
    "#0000007824 NoMotion-1. Scaled. Acc [ -00053.22, 00008.30, 00991.70 ], Gyr [ -00001.71, 00003.84, 00000.30 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007825 NoMotion-1. Scaled. Acc [ -00048.34, -00002.44, 00993.16 ], Gyr [ -00000.12, 00003.54, -00001.52 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007826 NoMotion-1. Scaled. Acc [ -00039.06, -00007.32, 00991.70 ], Gyr [ 00002.44, 00002.87, -00000.43 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007827 NoMotion-1. Scaled. Acc [ -00039.55, -00010.74, 00999.02 ], Gyr [ -00000.18, 00001.28, -00000.24 ], Mag [ 00021.30, 00083.55, 00107.10 ]",
    "#0000007828 NoMotion-1. Scaled. Acc [ -00029.30, -00009.77, 00988.77 ], Gyr [ 00001.10, 00001.52, 00000.43 ], Mag [ 00019.35, 00084.45, 00107.40 ]",
    "#0000007829 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 01000.98 ], Gyr [ 00000.55, 00001.40, -00001.95 ], Mag [ 00019.50, 00082.50, 00106.80 ]",
    "#0000007830 NoMotion-1. Scaled. Acc [ -00031.74, -00003.91, 00988.77 ], Gyr [ 00002.62, 00001.34, -00001.16 ], Mag [ 00019.50, 00082.50, 00106.80 ]",
    "#0000007831 NoMotion-1. Scaled. Acc [ -00043.95, -00004.88, 00995.61 ], Gyr [ -00000.37, 00001.65, -00000.98 ], Mag [ 00019.50, 00082.50, 00106.80 ]",
    "#0000007832 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00984.38 ], Gyr [ 00001.28, 00003.60, 00000.24 ], Mag [ 00019.50, 00082.80, 00106.80 ]",
    "#0000007833 NoMotion-1. Scaled. Acc [ -00038.09, -00008.30, 00990.23 ], Gyr [ -00000.18, 00000.55, -00003.05 ], Mag [ 00019.50, 00082.80, 00106.80 ]",
    "#0000007834 NoMotion-1. Scaled. Acc [ -00037.60, -00001.95, 00997.56 ], Gyr [ -00000.79, 00000.49, -00000.55 ], Mag [ 00017.55, 00083.40, 00107.40 ]",
    "#0000007835 NoMotion-1. Scaled. Acc [ -00043.95, -00002.93, 00981.93 ], Gyr [ 00002.50, 00004.27, -00000.55 ], Mag [ 00017.55, 00083.40, 00107.40 ]",
    "#0000007836 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00990.23 ], Gyr [ 00000.49, 00004.27, -00002.87 ], Mag [ 00017.55, 00083.40, 00107.40 ]",
    "#0000007837 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 00988.28 ], Gyr [ -00001.77, 00000.85, -00003.17 ], Mag [ 00020.70, 00084.75, 00108.90 ]",
    "#0000007838 NoMotion-1. Scaled. Acc [ -00048.34, -00017.58, 00979.98 ], Gyr [ 00001.52, 00001.04, -00000.18 ], Mag [ 00020.70, 00084.75, 00108.90 ]",
    "#0000007839 NoMotion-1. Scaled. Acc [ -00039.55, 00005.86, 00992.19 ], Gyr [ -00000.85, 00001.04, -00000.06 ], Mag [ 00019.95, 00083.55, 00107.10 ]",
    "#0000007840 NoMotion-1. Scaled. Acc [ -00056.64, -00002.93, 00992.19 ], Gyr [ 00000.43, 00002.99, -00001.77 ], Mag [ 00020.40, 00083.55, 00107.10 ]",
    "#0000007841 NoMotion-1. Scaled. Acc [ -00036.13, -00005.86, 00984.86 ], Gyr [ 00001.16, -00002.01, 00000.85 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007842 NoMotion-1. Scaled. Acc [ -00047.85, -00007.32, 00996.58 ], Gyr [ 00001.71, -00001.28, -00001.34 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007843 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00988.77 ], Gyr [ 00000.12, 00001.77, -00002.26 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007844 NoMotion-1. Scaled. Acc [ -00048.34, -00012.70, 00999.51 ], Gyr [ 00001.65, -00001.65, 00000.67 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007845 NoMotion-1. Scaled. Acc [ -00049.80, -00006.35, 00987.30 ], Gyr [ 00002.13, -00000.18, -00002.13 ], Mag [ 00020.70, 00085.80, 00107.85 ]",
    "#0000007846 NoMotion-1. Scaled. Acc [ -00041.50, 00001.95, 00980.47 ], Gyr [ 00000.24, 00002.93, -00000.98 ], Mag [ 00019.95, 00084.00, 00106.50 ]",
    "#0000007847 NoMotion-1. Scaled. Acc [ -00034.67, 00000.98, 00987.79 ], Gyr [ -00000.18, 00005.85, -00002.44 ], Mag [ 00019.95, 00084.00, 00106.50 ]",
    "#0000007848 NoMotion-1. Scaled. Acc [ -00040.53, -00014.65, 00990.23 ], Gyr [ 00000.49, 00001.89, -00001.34 ], Mag [ 00021.00, 00085.05, 00109.35 ]",
    "#0000007849 NoMotion-1. Scaled. Acc [ -00051.27, -00009.28, 00991.70 ], Gyr [ 00000.91, 00002.20, -00001.40 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007850 NoMotion-1. Scaled. Acc [ -00048.34, -00001.95, 01000.49 ], Gyr [ 00000.30, -00000.24, 00000.98 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007851 NoMotion-1. Scaled. Acc [ -00040.53, -00001.95, 00989.75 ], Gyr [ 00000.73, 00002.07, -00002.07 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000007852 NoMotion-1. Scaled. Acc [ -00045.90, -00001.46, 01000.49 ], Gyr [ 00000.00, 00001.28, -00000.73 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000007853 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 01001.46 ], Gyr [ 00001.16, 00000.91, -00002.80 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000007854 NoMotion-1. Scaled. Acc [ -00036.13, -00006.84, 00983.89 ], Gyr [ 00002.62, 00002.07, -00002.44 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007855 NoMotion-1. Scaled. Acc [ -00046.39, -00010.74, 00989.26 ], Gyr [ 00001.40, 00000.24, -00002.26 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000007856 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00994.63 ], Gyr [ 00000.30, 00001.52, -00001.04 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000007857 NoMotion-1. Scaled. Acc [ -00036.13, -00009.77, 00990.23 ], Gyr [ 00000.43, 00003.17, -00000.91 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000007858 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00985.35 ], Gyr [ 00000.12, 00007.32, -00001.77 ], Mag [ 00018.60, 00084.90, 00107.70 ]",
    "#0000007859 NoMotion-1. Scaled. Acc [ -00039.55, -00007.81, 00998.05 ], Gyr [ -00000.61, 00001.52, -00000.79 ], Mag [ 00018.60, 00084.90, 00107.70 ]",
    "#0000007860 NoMotion-1. Scaled. Acc [ -00038.09, -00002.93, 00980.47 ], Gyr [ 00001.89, 00000.55, 00000.79 ], Mag [ 00020.10, 00083.70, 00105.90 ]",
    "#0000007861 NoMotion-1. Scaled. Acc [ -00037.60, -00011.72, 00989.26 ], Gyr [ 00001.10, 00001.40, -00003.48 ], Mag [ 00020.10, 00083.70, 00105.90 ]",
    "#0000007862 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00996.58 ], Gyr [ 00001.22, 00002.56, 00000.06 ], Mag [ 00020.10, 00083.70, 00105.90 ]",
    "#0000007863 NoMotion-1. Scaled. Acc [ -00022.95, -00011.23, 00978.03 ], Gyr [ 00002.56, 00001.34, 00001.16 ], Mag [ 00021.30, 00083.55, 00108.30 ]",
    "#0000007864 NoMotion-1. Scaled. Acc [ -00044.43, -00007.81, 01006.35 ], Gyr [ 00001.65, 00002.74, -00004.45 ], Mag [ 00021.30, 00083.55, 00108.30 ]",
    "#0000007865 NoMotion-1. Scaled. Acc [ -00030.27, -00001.95, 00983.89 ], Gyr [ 00001.04, 00001.77, 00000.73 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007866 NoMotion-1. Scaled. Acc [ -00040.53, 00004.88, 00989.26 ], Gyr [ 00000.43, 00001.83, -00002.13 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007867 NoMotion-1. Scaled. Acc [ -00045.90, -00001.95, 00978.52 ], Gyr [ 00000.00, 00001.52, 00000.91 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000007868 NoMotion-1. Scaled. Acc [ -00043.46, -00005.86, 00989.75 ], Gyr [ 00001.04, -00001.95, -00000.61 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000007869 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00985.84 ], Gyr [ 00001.22, 00002.93, 00000.00 ], Mag [ 00019.95, 00082.05, 00107.85 ]",
    "#0000007870 NoMotion-1. Scaled. Acc [ -00043.46, -00000.49, 00993.65 ], Gyr [ 00000.37, 00001.46, -00003.72 ], Mag [ 00019.95, 00082.05, 00107.85 ]",
    "#0000007871 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00997.07 ], Gyr [ 00000.98, 00001.46, -00001.28 ], Mag [ 00019.95, 00082.05, 00107.85 ]",
    "#0000007872 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00991.21 ], Gyr [ 00000.61, 00001.71, -00000.37 ], Mag [ 00019.50, 00084.00, 00105.75 ]",
    "#0000007873 NoMotion-1. Scaled. Acc [ -00033.20, -00011.23, 00986.33 ], Gyr [ -00000.55, 00000.98, -00003.05 ], Mag [ 00019.50, 00084.00, 00105.75 ]",
    "#0000007874 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00995.61 ], Gyr [ -00001.28, 00001.40, 00000.12 ], Mag [ 00020.25, 00085.05, 00107.10 ]",
    "#0000007875 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00999.51 ], Gyr [ 00000.79, 00001.95, -00000.79 ], Mag [ 00020.25, 00085.05, 00107.10 ]",
    "#0000007876 NoMotion-1. Scaled. Acc [ -00038.09, -00002.93, 00994.63 ], Gyr [ 00001.71, 00001.46, -00001.89 ], Mag [ 00020.25, 00085.05, 00107.10 ]",
    "#0000007877 NoMotion-1. Scaled. Acc [ -00048.34, 00000.49, 00992.19 ], Gyr [ 00000.55, 00002.13, -00003.11 ], Mag [ 00018.15, 00082.80, 00107.85 ]",
    "#0000007878 NoMotion-1. Scaled. Acc [ -00047.36, -00013.18, 00994.63 ], Gyr [ -00000.18, 00001.40, -00002.32 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007879 NoMotion-1. Scaled. Acc [ -00052.73, -00003.91, 00983.89 ], Gyr [ -00000.61, 00003.41, -00000.73 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007880 NoMotion-1. Scaled. Acc [ -00050.78, -00016.60, 00999.02 ], Gyr [ -00000.24, 00002.26, -00001.10 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007881 NoMotion-1. Scaled. Acc [ -00032.71, -00012.21, 01006.35 ], Gyr [ 00001.52, 00002.01, -00000.30 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007882 NoMotion-1. Scaled. Acc [ -00040.04, -00014.16, 00992.19 ], Gyr [ 00000.37, -00000.43, -00000.61 ], Mag [ 00018.45, 00083.55, 00107.55 ]",
    "#0000007883 NoMotion-1. Scaled. Acc [ -00042.97, -00000.49, 00985.84 ], Gyr [ 00001.28, 00000.67, -00000.79 ], Mag [ 00018.45, 00083.55, 00107.55 ]",
    "#0000007884 NoMotion-1. Scaled. Acc [ -00047.36, -00015.62, 00987.79 ], Gyr [ 00002.68, 00000.98, 00000.43 ], Mag [ 00020.40, 00082.95, 00108.45 ]",
    "#0000007885 NoMotion-1. Scaled. Acc [ -00035.16, -00004.88, 00990.23 ], Gyr [ 00001.71, 00002.01, -00000.55 ], Mag [ 00020.40, 00082.95, 00108.45 ]",
    "#0000007886 NoMotion-1. Scaled. Acc [ -00035.64, -00007.32, 00999.02 ], Gyr [ -00000.18, 00002.26, -00003.29 ], Mag [ 00018.45, 00084.75, 00108.90 ]",
    "#0000007887 NoMotion-1. Scaled. Acc [ -00041.50, -00002.44, 00984.86 ], Gyr [ -00000.43, 00003.41, -00001.04 ], Mag [ 00018.45, 00084.75, 00108.90 ]",
    "#0000007888 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00990.72 ], Gyr [ -00001.77, 00000.73, -00002.50 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007889 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00996.58 ], Gyr [ 00000.06, 00003.23, -00002.13 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007890 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00984.38 ], Gyr [ -00001.46, 00001.65, -00002.99 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007891 NoMotion-1. Scaled. Acc [ -00045.41, 00000.00, 00995.12 ], Gyr [ 00000.49, 00002.50, 00000.73 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007892 NoMotion-1. Scaled. Acc [ -00049.80, -00012.21, 00990.72 ], Gyr [ 00001.46, 00001.52, -00001.77 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007893 NoMotion-1. Scaled. Acc [ -00038.09, -00009.77, 00998.54 ], Gyr [ 00000.49, 00002.62, -00001.34 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007894 NoMotion-1. Scaled. Acc [ -00051.76, -00004.39, 00987.79 ], Gyr [ -00000.06, 00001.95, -00001.71 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000007895 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 00979.00 ], Gyr [ 00001.71, 00000.49, -00001.52 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000007896 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00986.82 ], Gyr [ 00001.16, 00000.49, -00000.98 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000007897 NoMotion-1. Scaled. Acc [ -00046.39, -00012.21, 00992.68 ], Gyr [ -00000.37, 00002.38, -00002.13 ], Mag [ 00021.00, 00082.80, 00107.85 ]",
    "#0000007898 NoMotion-1. Scaled. Acc [ -00036.13, -00012.70, 00989.26 ], Gyr [ 00001.16, 00000.98, 00000.55 ], Mag [ 00021.00, 00082.80, 00107.85 ]",
    "#0000007899 NoMotion-1. Scaled. Acc [ -00042.48, -00008.30, 00999.51 ], Gyr [ -00000.43, -00000.91, 00000.18 ], Mag [ 00021.00, 00082.80, 00107.85 ]",
    "#0000007900 NoMotion-1. Scaled. Acc [ -00035.16, -00003.42, 00989.75 ], Gyr [ 00000.55, 00002.13, -00001.34 ], Mag [ 00019.05, 00082.65, 00108.75 ]",
    "#0000007901 NoMotion-1. Scaled. Acc [ -00044.43, -00007.32, 00989.75 ], Gyr [ 00000.55, 00001.40, -00000.79 ], Mag [ 00019.05, 00082.65, 00108.75 ]",
    "#0000007902 NoMotion-1. Scaled. Acc [ -00060.55, -00008.79, 00991.21 ], Gyr [ -00000.43, 00003.41, -00003.05 ], Mag [ 00021.00, 00083.25, 00107.85 ]",
    "#0000007903 NoMotion-1. Scaled. Acc [ -00041.99, -00014.65, 00992.68 ], Gyr [ 00001.89, 00004.02, -00004.02 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000007904 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 01002.93 ], Gyr [ -00000.18, 00003.05, -00000.85 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000007905 NoMotion-1. Scaled. Acc [ -00040.04, -00000.49, 00999.51 ], Gyr [ -00000.43, 00000.73, -00001.28 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000007906 NoMotion-1. Scaled. Acc [ -00042.97, -00014.65, 00990.72 ], Gyr [ 00003.17, 00000.00, -00002.56 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007907 NoMotion-1. Scaled. Acc [ -00034.67, -00016.11, 00990.72 ], Gyr [ 00001.34, 00000.61, -00001.34 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007908 NoMotion-1. Scaled. Acc [ -00039.06, -00007.32, 00997.07 ], Gyr [ 00001.59, -00000.79, 00000.73 ], Mag [ 00020.25, 00084.30, 00107.55 ]",
    "#0000007909 NoMotion-1. Scaled. Acc [ -00039.06, -00015.14, 00976.56 ], Gyr [ -00001.83, 00001.40, -00004.39 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000007910 NoMotion-1. Scaled. Acc [ -00056.64, -00018.07, 00999.02 ], Gyr [ -00001.95, 00003.41, -00001.65 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000007911 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 01001.95 ], Gyr [ -00001.83, 00002.50, 00000.55 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000007912 NoMotion-1. Scaled. Acc [ -00056.15, -00011.23, 00995.12 ], Gyr [ -00001.10, -00002.38, -00000.55 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000007913 NoMotion-1. Scaled. Acc [ -00030.27, 00003.42, 00998.05 ], Gyr [ -00000.30, 00002.87, -00003.90 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000007914 NoMotion-1. Scaled. Acc [ -00057.62, -00010.74, 00995.61 ], Gyr [ 00001.16, 00002.38, -00000.37 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007915 NoMotion-1. Scaled. Acc [ -00040.53, -00011.72, 00998.54 ], Gyr [ 00000.24, 00000.98, -00002.20 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007916 NoMotion-1. Scaled. Acc [ -00056.15, -00016.11, 00997.07 ], Gyr [ 00002.38, 00003.48, 00001.59 ], Mag [ 00019.95, 00083.55, 00108.90 ]",
    "#0000007917 NoMotion-1. Scaled. Acc [ -00038.57, -00007.81, 00991.70 ], Gyr [ 00003.11, -00000.24, -00000.98 ], Mag [ 00019.95, 00083.55, 00108.90 ]",
    "#0000007918 NoMotion-1. Scaled. Acc [ -00046.39, -00012.21, 00995.61 ], Gyr [ 00001.65, 00002.38, -00003.48 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007919 NoMotion-1. Scaled. Acc [ -00041.99, -00007.81, 00993.16 ], Gyr [ 00002.56, 00001.83, 00000.55 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007920 NoMotion-1. Scaled. Acc [ -00033.20, -00007.32, 01002.44 ], Gyr [ 00000.85, -00001.83, 00000.30 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007921 NoMotion-1. Scaled. Acc [ -00039.06, -00004.39, 00988.28 ], Gyr [ 00000.73, 00002.07, -00003.72 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007922 NoMotion-1. Scaled. Acc [ -00042.48, -00005.37, 00979.00 ], Gyr [ -00000.79, 00001.28, -00001.89 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007923 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 01005.86 ], Gyr [ -00000.24, 00002.62, -00003.41 ], Mag [ 00018.60, 00081.90, 00107.40 ]",
    "#0000007924 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 01000.00 ], Gyr [ 00002.20, 00000.12, 00000.06 ], Mag [ 00020.25, 00082.80, 00107.10 ]",
    "#0000007925 NoMotion-1. Scaled. Acc [ -00041.50, 00003.42, 00995.61 ], Gyr [ 00000.91, 00000.43, -00003.41 ], Mag [ 00020.25, 00082.80, 00107.10 ]",
    "#0000007926 NoMotion-1. Scaled. Acc [ -00050.78, -00001.46, 01002.93 ], Gyr [ 00000.91, -00000.30, -00001.10 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000007927 NoMotion-1. Scaled. Acc [ -00043.95, -00011.23, 00987.79 ], Gyr [ -00001.22, 00001.10, -00001.65 ], Mag [ 00019.20, 00082.80, 00108.60 ]",
    "#0000007928 NoMotion-1. Scaled. Acc [ -00044.43, -00002.93, 00992.19 ], Gyr [ 00000.55, 00000.61, -00002.87 ], Mag [ 00019.20, 00082.80, 00108.60 ]",
    "#0000007929 NoMotion-1. Scaled. Acc [ -00043.95, 00000.49, 00988.28 ], Gyr [ 00001.04, 00000.79, -00001.59 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007930 NoMotion-1. Scaled. Acc [ -00024.41, -00004.88, 00980.47 ], Gyr [ -00000.37, 00000.24, -00000.85 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007931 NoMotion-1. Scaled. Acc [ -00048.34, -00007.81, 00993.65 ], Gyr [ -00002.13, 00001.95, -00000.43 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007932 NoMotion-1. Scaled. Acc [ -00046.88, -00000.98, 00991.21 ], Gyr [ 00000.98, 00000.55, -00002.44 ], Mag [ 00018.45, 00085.05, 00108.60 ]",
    "#0000007933 NoMotion-1. Scaled. Acc [ -00053.22, 00001.46, 00981.45 ], Gyr [ 00001.46, -00000.06, -00000.55 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007934 NoMotion-1. Scaled. Acc [ -00053.22, -00019.53, 00981.45 ], Gyr [ 00000.37, 00002.20, -00003.11 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007935 NoMotion-1. Scaled. Acc [ -00044.92, -00005.37, 00993.16 ], Gyr [ 00001.28, 00001.52, -00002.87 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007936 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00986.82 ], Gyr [ -00000.55, 00000.67, -00001.83 ], Mag [ 00020.10, 00083.70, 00106.20 ]",
    "#0000007937 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00995.61 ], Gyr [ 00000.30, 00002.38, -00001.40 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007938 NoMotion-1. Scaled. Acc [ -00041.02, -00005.86, 00991.21 ], Gyr [ 00003.35, 00000.98, -00000.30 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007939 NoMotion-1. Scaled. Acc [ -00035.64, -00008.30, 00991.21 ], Gyr [ 00001.34, -00000.98, 00002.68 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007940 NoMotion-1. Scaled. Acc [ -00041.50, 00002.93, 00990.23 ], Gyr [ -00000.24, 00003.17, -00003.60 ], Mag [ 00020.70, 00081.75, 00106.50 ]",
    "#0000007941 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00991.21 ], Gyr [ 00000.98, 00001.16, 00001.16 ], Mag [ 00018.75, 00084.30, 00107.10 ]",
    "#0000007942 NoMotion-1. Scaled. Acc [ -00047.85, -00011.72, 00975.59 ], Gyr [ 00000.67, 00003.35, 00000.18 ], Mag [ 00018.75, 00084.30, 00107.10 ]",
    "#0000007943 NoMotion-1. Scaled. Acc [ -00040.04, -00010.74, 00994.63 ], Gyr [ 00001.59, 00001.89, -00000.61 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007944 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00980.47 ], Gyr [ -00000.37, 00002.38, -00001.83 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007945 NoMotion-1. Scaled. Acc [ -00045.90, -00017.09, 00984.86 ], Gyr [ 00001.83, 00000.37, 00000.06 ], Mag [ 00019.20, 00084.75, 00107.85 ]",
    "#0000007946 NoMotion-1. Scaled. Acc [ -00060.55, 00011.23, 00988.28 ], Gyr [ 00000.67, 00002.93, -00002.56 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007947 NoMotion-1. Scaled. Acc [ -00041.99, 00001.95, 00994.14 ], Gyr [ 00000.61, -00000.30, -00003.96 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007948 NoMotion-1. Scaled. Acc [ -00040.53, -00009.28, 00988.28 ], Gyr [ 00000.73, 00001.89, -00001.04 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007949 NoMotion-1. Scaled. Acc [ -00040.53, 00006.84, 00989.75 ], Gyr [ -00000.67, 00004.09, -00003.72 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007950 NoMotion-1. Scaled. Acc [ -00039.06, -00017.09, 00979.98 ], Gyr [ 00001.71, 00000.55, -00001.52 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007951 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00992.68 ], Gyr [ 00001.59, -00000.61, -00001.65 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007952 NoMotion-1. Scaled. Acc [ -00046.88, -00003.42, 00998.05 ], Gyr [ -00000.98, 00000.73, 00000.37 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007953 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 01002.44 ], Gyr [ 00002.99, -00000.55, -00000.30 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000007954 NoMotion-1. Scaled. Acc [ -00044.43, -00002.44, 00997.56 ], Gyr [ 00001.16, -00001.04, -00000.67 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000007955 NoMotion-1. Scaled. Acc [ -00049.80, 00005.37, 00989.26 ], Gyr [ -00000.37, 00002.80, -00002.20 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000007956 NoMotion-1. Scaled. Acc [ -00046.39, -00011.72, 00994.63 ], Gyr [ 00002.01, -00000.98, -00001.89 ], Mag [ 00019.05, 00082.65, 00109.05 ]",
    "#0000007957 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00995.61 ], Gyr [ -00000.91, 00000.30, -00001.40 ], Mag [ 00019.05, 00082.65, 00109.05 ]",
    "#0000007958 NoMotion-1. Scaled. Acc [ -00050.29, -00009.77, 00989.26 ], Gyr [ 00001.28, -00001.28, 00001.40 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007959 NoMotion-1. Scaled. Acc [ -00047.85, -00014.16, 00994.14 ], Gyr [ -00000.85, 00000.30, -00004.51 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007960 NoMotion-1. Scaled. Acc [ -00044.43, -00011.23, 00995.12 ], Gyr [ 00001.34, 00001.40, -00001.77 ], Mag [ 00020.25, 00084.75, 00107.10 ]",
    "#0000007961 NoMotion-1. Scaled. Acc [ -00057.13, -00013.67, 00985.84 ], Gyr [ 00000.18, 00002.93, -00006.22 ], Mag [ 00020.25, 00084.75, 00107.10 ]",
    "#0000007962 NoMotion-1. Scaled. Acc [ -00038.09, -00002.44, 00987.79 ], Gyr [ -00000.73, 00001.34, -00000.43 ], Mag [ 00020.25, 00084.75, 00107.10 ]",
    "#0000007963 NoMotion-1. Scaled. Acc [ -00042.48, -00003.91, 00994.14 ], Gyr [ -00000.24, -00001.59, -00003.29 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007964 NoMotion-1. Scaled. Acc [ -00037.60, -00010.25, 01001.95 ], Gyr [ 00000.43, 00001.10, -00000.98 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007965 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00998.54 ], Gyr [ 00001.52, 00002.26, -00001.77 ], Mag [ 00021.15, 00081.15, 00107.70 ]",
    "#0000007966 NoMotion-1. Scaled. Acc [ -00040.04, -00009.77, 00987.79 ], Gyr [ -00000.49, 00001.46, -00004.76 ], Mag [ 00021.15, 00081.15, 00107.70 ]",
    "#0000007967 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00992.68 ], Gyr [ -00000.49, -00000.55, 00000.91 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007968 NoMotion-1. Scaled. Acc [ -00049.32, -00001.95, 00986.82 ], Gyr [ 00002.01, 00004.09, 00000.18 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007969 NoMotion-1. Scaled. Acc [ -00044.43, -00016.60, 00988.28 ], Gyr [ -00000.49, 00002.80, -00000.85 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007970 NoMotion-1. Scaled. Acc [ -00050.78, -00004.39, 00996.58 ], Gyr [ 00002.32, 00001.34, -00001.28 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007971 NoMotion-1. Scaled. Acc [ -00042.97, -00016.11, 00982.91 ], Gyr [ 00000.61, 00002.56, -00001.83 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007972 NoMotion-1. Scaled. Acc [ -00059.08, -00004.39, 00983.89 ], Gyr [ 00001.34, 00000.55, -00003.11 ], Mag [ 00019.95, 00082.80, 00107.55 ]",
    "#0000007973 NoMotion-1. Scaled. Acc [ -00046.39, 00003.91, 00990.23 ], Gyr [ -00001.52, 00001.71, -00001.16 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007974 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00992.68 ], Gyr [ 00002.44, 00003.72, -00003.05 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007975 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00999.02 ], Gyr [ 00000.98, 00000.85, -00003.35 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007976 NoMotion-1. Scaled. Acc [ -00039.06, -00015.62, 00993.65 ], Gyr [ 00001.34, -00001.71, -00001.89 ], Mag [ 00019.35, 00082.65, 00106.95 ]",
    "#0000007977 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 01000.00 ], Gyr [ 00000.24, 00000.55, -00001.65 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007978 NoMotion-1. Scaled. Acc [ -00047.85, -00017.09, 00993.16 ], Gyr [ -00000.30, 00001.22, -00004.39 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007979 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00987.79 ], Gyr [ -00000.55, 00001.22, -00002.13 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000007980 NoMotion-1. Scaled. Acc [ -00041.99, -00005.37, 00983.89 ], Gyr [ 00002.26, 00002.99, -00000.24 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000007981 NoMotion-1. Scaled. Acc [ -00044.43, -00008.30, 00997.07 ], Gyr [ 00000.98, 00000.91, -00000.85 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000007982 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00993.16 ], Gyr [ 00001.34, -00002.01, -00001.22 ], Mag [ 00019.95, 00084.00, 00106.50 ]",
    "#0000007983 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00991.21 ], Gyr [ 00000.12, 00002.44, -00001.71 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007984 NoMotion-1. Scaled. Acc [ -00040.04, -00010.25, 00987.79 ], Gyr [ 00001.71, 00000.67, -00001.46 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007985 NoMotion-1. Scaled. Acc [ -00031.25, -00016.60, 01000.00 ], Gyr [ -00001.10, 00001.52, -00002.32 ], Mag [ 00019.50, 00083.25, 00107.10 ]",
    "#0000007986 NoMotion-1. Scaled. Acc [ -00053.22, -00002.44, 01000.49 ], Gyr [ -00000.67, -00000.67, -00000.73 ], Mag [ 00020.70, 00083.55, 00107.85 ]",
    "#0000007987 NoMotion-1. Scaled. Acc [ -00032.71, -00005.37, 01005.37 ], Gyr [ 00000.79, 00001.95, -00001.89 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007988 NoMotion-1. Scaled. Acc [ -00041.02, -00004.39, 00989.26 ], Gyr [ 00002.01, 00000.61, -00001.77 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007989 NoMotion-1. Scaled. Acc [ -00039.55, -00001.46, 00989.75 ], Gyr [ 00002.07, 00000.18, -00000.55 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007990 NoMotion-1. Scaled. Acc [ -00044.43, -00014.65, 00988.77 ], Gyr [ 00000.43, 00002.07, -00002.26 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007991 NoMotion-1. Scaled. Acc [ -00055.66, -00000.49, 00982.91 ], Gyr [ 00001.71, 00001.04, -00003.66 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007992 NoMotion-1. Scaled. Acc [ -00041.99, -00009.77, 00989.26 ], Gyr [ 00000.30, 00000.06, 00000.00 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007993 NoMotion-1. Scaled. Acc [ -00039.06, -00014.65, 00987.30 ], Gyr [ -00002.62, -00000.06, 00000.49 ], Mag [ 00018.60, 00083.70, 00107.40 ]",
    "#0000007994 NoMotion-1. Scaled. Acc [ -00044.43, -00003.91, 00998.05 ], Gyr [ -00000.55, -00003.05, -00000.37 ], Mag [ 00018.60, 00083.70, 00107.40 ]",
    "#0000007995 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00995.61 ], Gyr [ 00000.43, 00003.35, -00001.34 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007996 NoMotion-1. Scaled. Acc [ -00046.88, -00019.04, 00988.77 ], Gyr [ -00000.67, -00001.28, 00000.73 ], Mag [ 00020.25, 00083.55, 00108.60 ]",
    "#0000007997 NoMotion-1. Scaled. Acc [ -00044.43, -00011.23, 00990.23 ], Gyr [ 00001.22, 00001.28, -00002.87 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000007998 NoMotion-1. Scaled. Acc [ -00046.39, -00002.44, 00980.96 ], Gyr [ -00001.95, 00002.68, -00003.96 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000007999 NoMotion-1. Scaled. Acc [ -00056.15, -00006.35, 00995.12 ], Gyr [ 00001.22, 00002.13, -00001.52 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000008000 NoMotion-1. Scaled. Acc [ -00044.92, -00011.72, 01000.00 ], Gyr [ -00000.85, 00003.48, -00000.12 ], Mag [ 00019.95, 00085.50, 00107.55 ]",
    "#0000008001 NoMotion-1. Scaled. Acc [ -00051.27, -00010.74, 00979.00 ], Gyr [ 00001.77, 00000.24, -00000.49 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000008002 NoMotion-1. Scaled. Acc [ -00041.50, -00018.07, 01006.35 ], Gyr [ -00000.79, 00001.77, -00002.56 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000008003 NoMotion-1. Scaled. Acc [ -00027.34, -00007.32, 00999.02 ], Gyr [ 00000.30, 00001.10, 00000.61 ], Mag [ 00019.20, 00084.00, 00108.30 ]",
    "#0000008004 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00992.68 ], Gyr [ 00001.40, 00001.04, 00000.24 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000008005 NoMotion-1. Scaled. Acc [ -00045.41, -00001.46, 00993.16 ], Gyr [ -00000.24, -00000.43, -00001.83 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000008006 NoMotion-1. Scaled. Acc [ -00040.53, -00008.79, 00987.79 ], Gyr [ 00001.34, 00000.61, -00002.38 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000008007 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00982.91 ], Gyr [ 00000.73, 00002.38, -00003.54 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000008008 NoMotion-1. Scaled. Acc [ -00043.95, -00006.84, 00994.14 ], Gyr [ 00000.43, 00001.22, 00000.73 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000008009 NoMotion-1. Scaled. Acc [ -00040.53, -00004.88, 00996.09 ], Gyr [ -00001.65, 00005.37, -00002.32 ], Mag [ 00018.15, 00083.55, 00107.85 ]",
    "#0000008010 NoMotion-1. Scaled. Acc [ -00046.39, 00000.98, 00989.75 ], Gyr [ -00001.10, 00003.29, -00000.12 ], Mag [ 00018.15, 00083.55, 00107.85 ]",
    "#0000008011 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00982.42 ], Gyr [ 00000.37, 00000.18, -00001.46 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000008012 NoMotion-1. Scaled. Acc [ -00057.62, -00013.67, 00993.16 ], Gyr [ 00001.22, 00004.45, -00001.95 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000008013 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00999.02 ], Gyr [ 00000.49, 00001.71, -00002.93 ], Mag [ 00021.00, 00082.80, 00107.10 ]",
    "#0000008014 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00986.33 ], Gyr [ -00000.73, 00001.59, -00001.22 ], Mag [ 00020.10, 00082.80, 00107.10 ]",
    "#0000008015 NoMotion-1. Scaled. Acc [ -00041.99, 00004.88, 00985.84 ], Gyr [ 00000.49, 00001.89, -00001.16 ], Mag [ 00020.10, 00084.90, 00108.45 ]",
    "#0000008016 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00978.52 ], Gyr [ 00002.20, 00000.24, 00000.00 ], Mag [ 00020.70, 00083.25, 00106.50 ]",
    "#0000008017 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00994.14 ], Gyr [ 00000.49, 00003.90, -00002.32 ], Mag [ 00020.70, 00083.25, 00106.50 ]",
    "#0000008018 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00988.28 ], Gyr [ 00001.16, 00000.91, 00001.28 ], Mag [ 00020.70, 00083.25, 00106.50 ]",
    "#0000008019 NoMotion-1. Scaled. Acc [ -00056.64, -00000.49, 00994.14 ], Gyr [ 00000.85, 00001.83, -00003.54 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008020 NoMotion-1. Scaled. Acc [ -00048.34, 00000.00, 00996.58 ], Gyr [ -00001.95, 00002.50, 00000.00 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008021 NoMotion-1. Scaled. Acc [ -00051.76, -00016.60, 01003.91 ], Gyr [ 00000.18, 00002.38, -00001.65 ], Mag [ 00019.65, 00084.45, 00106.65 ]",
    "#0000008022 NoMotion-1. Scaled. Acc [ -00052.25, -00001.46, 00985.84 ], Gyr [ 00000.12, 00003.41, -00001.04 ], Mag [ 00019.65, 00084.45, 00106.65 ]",
    "#0000008023 NoMotion-1. Scaled. Acc [ -00048.34, -00013.18, 00999.51 ], Gyr [ -00000.49, 00004.27, -00003.72 ], Mag [ 00019.65, 00084.45, 00106.65 ]",
    "#0000008024 NoMotion-1. Scaled. Acc [ -00059.08, -00005.86, 00999.02 ], Gyr [ -00000.85, 00000.18, -00000.55 ], Mag [ 00021.00, 00084.30, 00107.10 ]",
    "#0000008025 NoMotion-1. Scaled. Acc [ -00043.46, 00000.49, 00985.84 ], Gyr [ 00002.50, -00000.30, -00000.24 ], Mag [ 00021.00, 00084.30, 00107.10 ]",
    "#0000008026 NoMotion-1. Scaled. Acc [ -00042.48, -00009.77, 00980.96 ], Gyr [ 00000.85, 00000.49, -00002.80 ], Mag [ 00019.65, 00084.45, 00107.70 ]",
    "#0000008027 NoMotion-1. Scaled. Acc [ -00042.48, -00003.42, 00992.68 ], Gyr [ 00001.16, 00001.65, -00001.16 ], Mag [ 00020.10, 00082.65, 00107.70 ]",
    "#0000008028 NoMotion-1. Scaled. Acc [ -00032.71, -00003.91, 00980.96 ], Gyr [ -00000.37, -00000.43, -00004.51 ], Mag [ 00020.10, 00082.65, 00107.70 ]",
    "#0000008029 NoMotion-1. Scaled. Acc [ -00049.32, -00009.77, 00981.45 ], Gyr [ 00001.16, 00001.40, -00000.06 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008030 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 01003.91 ], Gyr [ -00000.06, -00001.28, -00000.18 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008031 NoMotion-1. Scaled. Acc [ -00052.73, -00004.39, 00997.56 ], Gyr [ -00000.67, 00001.46, 00000.06 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008032 NoMotion-1. Scaled. Acc [ -00039.06, 00002.93, 00989.26 ], Gyr [ 00000.12, 00002.20, -00002.13 ], Mag [ 00021.30, 00084.00, 00109.35 ]",
    "#0000008033 NoMotion-1. Scaled. Acc [ -00044.92, -00017.09, 00996.58 ], Gyr [ -00001.28, 00002.74, -00001.77 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008034 NoMotion-1. Scaled. Acc [ -00049.32, -00013.18, 00996.09 ], Gyr [ -00000.30, 00002.50, -00002.38 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008035 NoMotion-1. Scaled. Acc [ -00061.52, 00009.28, 00988.28 ], Gyr [ -00000.73, 00000.18, -00002.44 ], Mag [ 00019.65, 00083.40, 00108.75 ]",
    "#0000008036 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00985.35 ], Gyr [ 00000.24, -00000.12, -00000.55 ], Mag [ 00019.65, 00083.40, 00108.75 ]",
    "#0000008037 NoMotion-1. Scaled. Acc [ -00050.29, -00003.42, 00985.84 ], Gyr [ 00001.34, 00000.49, 00000.12 ], Mag [ 00019.50, 00083.25, 00106.80 ]",
    "#0000008038 NoMotion-1. Scaled. Acc [ -00037.11, -00000.98, 00979.98 ], Gyr [ 00001.46, 00002.44, 00000.00 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008039 NoMotion-1. Scaled. Acc [ -00054.20, 00003.42, 00994.63 ], Gyr [ 00001.65, 00001.34, -00002.80 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008040 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00985.35 ], Gyr [ 00001.71, 00001.16, -00002.62 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008041 NoMotion-1. Scaled. Acc [ -00051.27, -00013.18, 00992.19 ], Gyr [ 00000.98, 00001.77, -00000.91 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008042 NoMotion-1. Scaled. Acc [ -00033.69, -00011.23, 00996.58 ], Gyr [ 00001.40, 00001.34, -00001.22 ], Mag [ 00017.55, 00082.95, 00106.95 ]",
    "#0000008043 NoMotion-1. Scaled. Acc [ -00038.09, -00007.32, 00990.23 ], Gyr [ 00001.34, -00000.37, -00001.40 ], Mag [ 00019.20, 00084.00, 00107.55 ]",
    "#0000008044 NoMotion-1. Scaled. Acc [ -00052.73, -00008.30, 00987.30 ], Gyr [ 00000.79, -00000.61, -00001.71 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008045 NoMotion-1. Scaled. Acc [ -00047.85, -00016.11, 00991.21 ], Gyr [ 00000.12, 00001.52, -00002.44 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008046 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00989.26 ], Gyr [ -00000.37, 00002.07, -00001.28 ], Mag [ 00019.50, 00084.15, 00106.95 ]",
    "#0000008047 NoMotion-1. Scaled. Acc [ -00032.71, -00008.30, 00990.23 ], Gyr [ 00001.28, 00000.79, -00001.71 ], Mag [ 00019.50, 00081.75, 00107.55 ]",
    "#0000008048 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00987.30 ], Gyr [ 00001.22, 00001.04, -00002.50 ], Mag [ 00019.50, 00081.75, 00107.55 ]",
    "#0000008049 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 00987.79 ], Gyr [ -00001.28, -00000.12, -00000.67 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000008050 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00989.26 ], Gyr [ 00000.55, 00000.98, -00001.16 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000008051 NoMotion-1. Scaled. Acc [ -00030.76, -00016.60, 00989.26 ], Gyr [ -00000.67, 00003.35, -00002.38 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000008052 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00995.61 ], Gyr [ -00001.16, 00001.52, 00000.24 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000008053 NoMotion-1. Scaled. Acc [ -00045.90, -00000.49, 00979.98 ], Gyr [ -00000.43, 00000.67, -00003.60 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008054 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00995.12 ], Gyr [ 00001.10, 00001.04, -00001.77 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008055 NoMotion-1. Scaled. Acc [ -00046.88, -00000.49, 00983.40 ], Gyr [ 00000.79, 00001.04, -00000.73 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008056 NoMotion-1. Scaled. Acc [ -00042.97, -00001.46, 00993.65 ], Gyr [ 00000.24, 00001.28, -00000.24 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000008057 NoMotion-1. Scaled. Acc [ -00043.95, -00006.35, 00996.09 ], Gyr [ 00001.04, 00003.84, -00001.40 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008058 NoMotion-1. Scaled. Acc [ -00041.99, -00011.72, 00990.23 ], Gyr [ -00002.38, 00001.52, -00001.46 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008059 NoMotion-1. Scaled. Acc [ -00033.69, -00020.02, 00987.79 ], Gyr [ 00000.37, 00001.10, -00001.59 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008060 NoMotion-1. Scaled. Acc [ -00044.92, -00001.95, 00983.89 ], Gyr [ -00000.73, 00001.83, -00003.23 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008061 NoMotion-1. Scaled. Acc [ -00035.64, -00008.30, 00990.72 ], Gyr [ 00001.52, 00002.62, -00000.49 ], Mag [ 00018.75, 00084.75, 00108.30 ]",
    "#0000008062 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00985.84 ], Gyr [ 00001.34, 00001.89, -00002.07 ], Mag [ 00018.75, 00084.75, 00108.30 ]",
    "#0000008063 NoMotion-1. Scaled. Acc [ -00054.20, -00000.49, 00994.63 ], Gyr [ 00002.50, 00001.59, -00001.89 ], Mag [ 00019.95, 00083.55, 00107.10 ]",
    "#0000008064 NoMotion-1. Scaled. Acc [ -00051.76, -00004.39, 00993.16 ], Gyr [ 00000.30, 00003.35, -00000.06 ], Mag [ 00019.95, 00083.55, 00107.10 ]",
    "#0000008065 NoMotion-1. Scaled. Acc [ -00051.76, -00015.14, 00992.68 ], Gyr [ 00000.55, -00002.07, -00001.22 ], Mag [ 00019.65, 00082.65, 00107.70 ]",
    "#0000008066 NoMotion-1. Scaled. Acc [ -00046.88, 00001.46, 00987.30 ], Gyr [ 00001.40, 00002.80, -00001.40 ], Mag [ 00019.65, 00082.65, 00107.70 ]",
    "#0000008067 NoMotion-1. Scaled. Acc [ -00037.11, -00009.28, 00992.19 ], Gyr [ 00002.38, 00000.55, -00001.34 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008068 NoMotion-1. Scaled. Acc [ -00044.92, 00003.91, 00995.12 ], Gyr [ 00001.65, 00001.22, 00000.67 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008069 NoMotion-1. Scaled. Acc [ -00040.04, 00002.93, 00992.19 ], Gyr [ -00000.49, 00003.41, -00002.01 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008070 NoMotion-1. Scaled. Acc [ -00036.62, -00007.81, 00995.61 ], Gyr [ 00001.10, 00001.10, -00000.18 ], Mag [ 00018.45, 00085.50, 00107.55 ]",
    "#0000008071 NoMotion-1. Scaled. Acc [ -00050.29, -00019.04, 00980.96 ], Gyr [ 00002.32, 00004.09, -00000.91 ], Mag [ 00020.10, 00082.20, 00106.65 ]",
    "#0000008072 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 00995.12 ], Gyr [ -00000.24, 00002.99, -00002.01 ], Mag [ 00020.10, 00082.20, 00106.65 ]",
    "#0000008073 NoMotion-1. Scaled. Acc [ -00035.64, 00003.42, 00985.35 ], Gyr [ -00001.40, 00002.26, -00003.54 ], Mag [ 00019.05, 00082.95, 00108.75 ]",
    "#0000008074 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00990.72 ], Gyr [ 00000.73, 00004.94, 00001.10 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000008075 NoMotion-1. Scaled. Acc [ -00053.22, -00009.77, 00990.72 ], Gyr [ -00000.73, 00003.05, -00002.32 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008076 NoMotion-1. Scaled. Acc [ -00041.02, -00017.58, 00994.63 ], Gyr [ -00000.55, -00000.43, -00001.10 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008077 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00993.16 ], Gyr [ 00000.24, 00001.34, -00002.38 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008078 NoMotion-1. Scaled. Acc [ -00038.09, 00001.46, 01005.86 ], Gyr [ -00001.46, 00003.96, -00004.33 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008079 NoMotion-1. Scaled. Acc [ -00060.55, -00016.11, 01000.49 ], Gyr [ 00001.28, 00000.61, 00001.10 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008080 NoMotion-1. Scaled. Acc [ -00065.92, -00009.28, 00991.70 ], Gyr [ 00000.43, -00000.55, -00001.59 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008081 NoMotion-1. Scaled. Acc [ -00043.46, -00011.72, 00997.56 ], Gyr [ 00002.26, -00000.06, -00000.30 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008082 NoMotion-1. Scaled. Acc [ -00037.60, -00001.95, 00990.72 ], Gyr [ -00001.46, 00001.04, -00001.95 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008083 NoMotion-1. Scaled. Acc [ -00046.88, -00000.49, 00990.72 ], Gyr [ 00000.06, 00002.62, -00002.56 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008084 NoMotion-1. Scaled. Acc [ -00046.39, -00016.60, 00992.68 ], Gyr [ -00000.24, 00002.01, -00000.98 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008085 NoMotion-1. Scaled. Acc [ -00039.06, 00006.35, 00992.68 ], Gyr [ -00000.24, 00004.39, -00000.79 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008086 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 01005.37 ], Gyr [ -00000.24, 00001.83, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008087 NoMotion-1. Scaled. Acc [ -00048.34, -00016.11, 01005.86 ], Gyr [ -00001.83, 00001.52, 00000.18 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008088 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00993.65 ], Gyr [ -00001.10, 00000.06, -00003.05 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008089 NoMotion-1. Scaled. Acc [ -00055.66, -00011.72, 00998.54 ], Gyr [ 00000.12, -00001.10, -00002.01 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008090 NoMotion-1. Scaled. Acc [ -00047.85, -00014.65, 00980.96 ], Gyr [ -00000.37, 00001.95, -00002.32 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000008091 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00984.38 ], Gyr [ 00002.44, 00000.12, -00000.85 ], Mag [ 00018.45, 00084.30, 00108.30 ]",
    "#0000008092 NoMotion-1. Scaled. Acc [ -00040.53, -00007.81, 00994.14 ], Gyr [ 00001.22, 00000.79, -00001.65 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008093 NoMotion-1. Scaled. Acc [ -00053.22, -00000.98, 00998.05 ], Gyr [ 00002.20, -00000.30, -00000.30 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008094 NoMotion-1. Scaled. Acc [ -00044.43, -00007.32, 00993.16 ], Gyr [ 00001.40, 00001.71, -00004.02 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008095 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00991.70 ], Gyr [ 00000.30, 00004.02, -00001.52 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000008096 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 01002.93 ], Gyr [ 00001.65, -00001.16, -00001.95 ], Mag [ 00019.05, 00082.20, 00108.00 ]",
    "#0000008097 NoMotion-1. Scaled. Acc [ -00047.36, -00010.25, 00987.79 ], Gyr [ 00000.67, 00001.04, -00000.91 ], Mag [ 00019.05, 00082.20, 00108.00 ]",
    "#0000008098 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00998.54 ], Gyr [ 00001.04, 00000.55, -00002.56 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000008099 NoMotion-1. Scaled. Acc [ -00029.79, -00003.42, 01002.93 ], Gyr [ -00000.30, 00001.65, -00002.99 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000008100 NoMotion-1. Scaled. Acc [ -00059.57, -00003.42, 00981.45 ], Gyr [ 00000.49, 00000.67, -00000.12 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000008101 NoMotion-1. Scaled. Acc [ -00045.41, -00003.91, 00995.12 ], Gyr [ 00000.49, 00000.18, -00004.02 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000008102 NoMotion-1. Scaled. Acc [ -00046.88, 00002.93, 00985.35 ], Gyr [ 00001.52, 00000.98, -00002.80 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008103 NoMotion-1. Scaled. Acc [ -00034.18, -00008.79, 00992.68 ], Gyr [ 00001.10, 00002.62, -00002.68 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008104 NoMotion-1. Scaled. Acc [ -00051.76, -00010.74, 00989.26 ], Gyr [ 00002.01, 00001.28, -00000.06 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008105 NoMotion-1. Scaled. Acc [ -00040.04, -00010.74, 00990.23 ], Gyr [ 00000.98, 00002.38, -00002.20 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000008106 NoMotion-1. Scaled. Acc [ -00040.04, -00014.65, 01012.21 ], Gyr [ -00000.37, 00003.41, -00001.16 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008107 NoMotion-1. Scaled. Acc [ -00043.95, -00009.77, 00988.77 ], Gyr [ -00000.12, 00000.85, -00002.44 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000008108 NoMotion-1. Scaled. Acc [ -00045.41, -00015.62, 00987.30 ], Gyr [ 00001.46, 00002.56, -00002.44 ], Mag [ 00018.75, 00084.00, 00106.05 ]",
    "#0000008109 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00980.96 ], Gyr [ 00000.85, 00003.66, -00001.77 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008110 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00979.98 ], Gyr [ 00000.24, -00000.37, -00001.77 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008111 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00994.14 ], Gyr [ 00000.24, 00003.90, -00001.71 ], Mag [ 00018.30, 00081.90, 00108.00 ]",
    "#0000008112 NoMotion-1. Scaled. Acc [ -00047.36, -00018.07, 00989.26 ], Gyr [ -00000.24, 00000.18, -00000.55 ], Mag [ 00018.30, 00081.90, 00108.00 ]",
    "#0000008113 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00985.35 ], Gyr [ 00000.61, 00000.43, -00002.32 ], Mag [ 00018.30, 00081.90, 00108.00 ]",
    "#0000008114 NoMotion-1. Scaled. Acc [ -00048.83, -00016.11, 00984.86 ], Gyr [ 00000.49, 00000.85, -00000.06 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008115 NoMotion-1. Scaled. Acc [ -00042.48, -00008.79, 00996.58 ], Gyr [ -00000.06, 00001.77, -00001.28 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008116 NoMotion-1. Scaled. Acc [ -00052.25, -00003.91, 00990.72 ], Gyr [ 00002.01, 00001.46, -00001.28 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000008117 NoMotion-1. Scaled. Acc [ -00041.50, -00016.60, 00993.16 ], Gyr [ -00000.49, 00000.37, -00000.24 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000008118 NoMotion-1. Scaled. Acc [ -00042.48, -00010.74, 00989.26 ], Gyr [ 00001.40, 00001.16, -00000.37 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000008119 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 01008.79 ], Gyr [ -00001.52, 00000.12, -00001.28 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000008120 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00988.28 ], Gyr [ -00001.83, 00000.12, 00000.24 ], Mag [ 00020.40, 00084.45, 00109.05 ]",
    "#0000008121 NoMotion-1. Scaled. Acc [ -00054.20, -00000.98, 00979.49 ], Gyr [ 00000.43, -00000.06, -00000.85 ], Mag [ 00020.40, 00084.45, 00109.05 ]",
    "#0000008122 NoMotion-1. Scaled. Acc [ -00045.41, -00007.81, 00984.86 ], Gyr [ -00000.73, -00000.98, -00001.28 ], Mag [ 00020.40, 00084.45, 00109.05 ]",
    "#0000008123 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 01003.42 ], Gyr [ -00000.73, 00001.83, -00001.10 ], Mag [ 00020.10, 00082.65, 00106.65 ]",
    "#0000008124 NoMotion-1. Scaled. Acc [ -00046.39, -00013.18, 00986.82 ], Gyr [ -00000.24, -00000.30, 00000.37 ], Mag [ 00020.10, 00082.65, 00106.65 ]",
    "#0000008125 NoMotion-1. Scaled. Acc [ -00052.25, 00002.44, 00995.12 ], Gyr [ -00000.73, 00001.59, -00001.22 ], Mag [ 00020.10, 00082.65, 00106.65 ]",
    "#0000008126 NoMotion-1. Scaled. Acc [ -00044.92, -00018.07, 00976.56 ], Gyr [ 00000.79, 00002.32, -00002.26 ], Mag [ 00019.35, 00084.15, 00106.65 ]",
    "#0000008127 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00993.16 ], Gyr [ 00001.16, 00000.30, 00003.17 ], Mag [ 00019.50, 00084.30, 00108.30 ]",
    "#0000008128 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00992.19 ], Gyr [ -00001.77, 00004.51, -00001.83 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008129 NoMotion-1. Scaled. Acc [ -00042.48, -00012.21, 01001.46 ], Gyr [ 00001.65, -00001.16, -00000.85 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008130 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00999.51 ], Gyr [ 00002.01, -00000.55, -00000.06 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008131 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00989.75 ], Gyr [ 00001.52, 00002.80, -00000.55 ], Mag [ 00019.65, 00084.90, 00106.20 ]",
    "#0000008132 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00991.70 ], Gyr [ 00000.98, 00002.50, -00002.13 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008133 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00998.54 ], Gyr [ -00000.61, 00001.95, 00000.18 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008134 NoMotion-1. Scaled. Acc [ -00044.43, -00010.74, 00987.30 ], Gyr [ 00001.71, 00001.34, -00000.49 ], Mag [ 00018.60, 00084.15, 00107.40 ]",
    "#0000008135 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 00979.00 ], Gyr [ 00000.55, 00000.61, -00002.07 ], Mag [ 00018.60, 00084.15, 00107.40 ]",
    "#0000008136 NoMotion-1. Scaled. Acc [ -00044.43, -00019.53, 00985.35 ], Gyr [ -00000.91, 00001.59, -00001.34 ], Mag [ 00019.05, 00082.95, 00108.75 ]",
    "#0000008137 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00994.63 ], Gyr [ 00001.83, 00001.10, -00002.13 ], Mag [ 00019.05, 00082.95, 00108.75 ]",
    "#0000008138 NoMotion-1. Scaled. Acc [ -00044.92, -00001.46, 00990.72 ], Gyr [ 00002.99, 00001.83, -00003.23 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008139 NoMotion-1. Scaled. Acc [ -00055.66, -00013.67, 01000.00 ], Gyr [ 00001.16, 00000.91, 00000.85 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008140 NoMotion-1. Scaled. Acc [ -00049.80, -00000.98, 00978.03 ], Gyr [ -00000.18, 00002.20, 00001.65 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008141 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00986.33 ], Gyr [ 00000.55, -00000.37, -00001.10 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008142 NoMotion-1. Scaled. Acc [ -00034.18, -00006.84, 00989.26 ], Gyr [ 00001.59, -00002.26, -00001.28 ], Mag [ 00019.50, 00082.50, 00106.50 ]",
    "#0000008143 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00987.79 ], Gyr [ -00001.28, 00002.93, -00002.38 ], Mag [ 00017.55, 00082.95, 00108.00 ]",
    "#0000008144 NoMotion-1. Scaled. Acc [ -00050.29, -00010.25, 00994.14 ], Gyr [ 00001.95, 00000.49, -00002.26 ], Mag [ 00017.55, 00082.95, 00108.00 ]",
    "#0000008145 NoMotion-1. Scaled. Acc [ -00053.22, -00019.04, 00984.86 ], Gyr [ 00001.89, 00000.98, -00001.34 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008146 NoMotion-1. Scaled. Acc [ -00053.71, -00010.74, 00997.07 ], Gyr [ 00000.18, 00003.05, -00003.72 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008147 NoMotion-1. Scaled. Acc [ -00046.88, -00006.84, 01000.00 ], Gyr [ 00002.20, 00001.22, -00001.65 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008148 NoMotion-1. Scaled. Acc [ -00046.39, -00015.62, 00989.75 ], Gyr [ -00002.38, 00001.46, -00001.52 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008149 NoMotion-1. Scaled. Acc [ -00049.80, -00015.62, 01000.49 ], Gyr [ -00000.61, 00003.05, -00003.05 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008150 NoMotion-1. Scaled. Acc [ -00052.25, -00011.23, 00990.72 ], Gyr [ 00002.44, -00001.83, -00001.04 ], Mag [ 00019.95, 00085.05, 00106.50 ]",
    "#0000008151 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00986.33 ], Gyr [ 00001.77, 00001.16, -00001.52 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000008152 NoMotion-1. Scaled. Acc [ -00044.92, -00002.44, 00989.75 ], Gyr [ 00001.59, 00004.45, 00000.24 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000008153 NoMotion-1. Scaled. Acc [ -00047.36, 00003.42, 00987.30 ], Gyr [ -00000.30, 00000.49, -00001.89 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000008154 NoMotion-1. Scaled. Acc [ -00035.64, -00010.25, 00986.33 ], Gyr [ 00001.71, -00001.16, -00000.98 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000008155 NoMotion-1. Scaled. Acc [ -00051.27, -00007.81, 00973.14 ], Gyr [ 00002.74, 00000.61, 00001.22 ], Mag [ 00019.65, 00083.70, 00109.50 ]",
    "#0000008156 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00998.05 ], Gyr [ 00000.18, 00001.46, -00002.74 ], Mag [ 00019.65, 00083.70, 00109.50 ]",
    "#0000008157 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00985.35 ], Gyr [ 00000.67, 00000.79, -00002.07 ], Mag [ 00018.15, 00082.05, 00105.75 ]",
    "#0000008158 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00995.61 ], Gyr [ 00001.40, 00001.46, -00003.17 ], Mag [ 00018.15, 00082.05, 00105.75 ]",
    "#0000008159 NoMotion-1. Scaled. Acc [ -00040.53, 00000.00, 00993.65 ], Gyr [ -00000.79, 00000.12, -00000.61 ], Mag [ 00018.15, 00082.05, 00105.75 ]",
    "#0000008160 NoMotion-1. Scaled. Acc [ -00056.64, -00013.67, 00985.35 ], Gyr [ -00000.73, 00001.22, 00000.91 ], Mag [ 00019.50, 00086.25, 00108.90 ]",
    "#0000008161 NoMotion-1. Scaled. Acc [ -00043.46, -00006.84, 00995.12 ], Gyr [ -00000.43, 00001.89, 00000.67 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000008162 NoMotion-1. Scaled. Acc [ -00057.13, -00010.74, 00982.91 ], Gyr [ 00002.13, -00001.71, -00001.71 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000008163 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00988.77 ], Gyr [ -00000.37, 00002.07, -00000.98 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000008164 NoMotion-1. Scaled. Acc [ -00039.55, -00003.91, 00978.52 ], Gyr [ -00001.04, 00001.10, -00002.13 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000008165 NoMotion-1. Scaled. Acc [ -00039.06, -00011.23, 01001.46 ], Gyr [ 00001.40, 00000.00, -00001.83 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000008166 NoMotion-1. Scaled. Acc [ -00056.64, -00009.77, 00992.19 ], Gyr [ 00002.01, 00000.85, 00000.49 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000008167 NoMotion-1. Scaled. Acc [ -00043.46, -00017.09, 00990.72 ], Gyr [ -00000.06, 00000.91, -00001.65 ], Mag [ 00021.15, 00083.70, 00106.95 ]",
    "#0000008168 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 01000.49 ], Gyr [ -00000.43, 00002.38, -00001.89 ], Mag [ 00020.25, 00085.50, 00107.10 ]",
    "#0000008169 NoMotion-1. Scaled. Acc [ -00057.13, -00006.35, 00988.28 ], Gyr [ 00001.10, 00000.79, 00000.49 ], Mag [ 00020.25, 00085.50, 00107.10 ]",
    "#0000008170 NoMotion-1. Scaled. Acc [ -00034.67, -00012.70, 00998.54 ], Gyr [ -00000.24, 00002.20, -00004.15 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000008171 NoMotion-1. Scaled. Acc [ -00047.85, -00003.91, 00989.26 ], Gyr [ 00000.37, 00002.56, -00000.73 ], Mag [ 00019.95, 00083.25, 00108.30 ]",
    "#0000008172 NoMotion-1. Scaled. Acc [ -00050.29, -00013.18, 00983.40 ], Gyr [ -00002.20, 00002.62, -00002.93 ], Mag [ 00020.10, 00082.95, 00106.95 ]",
    "#0000008173 NoMotion-1. Scaled. Acc [ -00040.53, -00012.21, 00991.70 ], Gyr [ 00001.65, -00000.73, -00000.85 ], Mag [ 00020.10, 00082.95, 00106.95 ]",
    "#0000008174 NoMotion-1. Scaled. Acc [ -00045.41, -00021.00, 00997.07 ], Gyr [ -00000.24, 00000.18, -00001.10 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000008175 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00984.38 ], Gyr [ -00000.79, 00001.46, 00000.73 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000008176 NoMotion-1. Scaled. Acc [ -00044.43, -00012.70, 00988.28 ], Gyr [ 00000.49, 00000.49, -00001.83 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000008177 NoMotion-1. Scaled. Acc [ -00033.69, -00000.98, 00988.28 ], Gyr [ 00001.52, 00001.71, 00000.18 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000008178 NoMotion-1. Scaled. Acc [ -00040.04, -00002.44, 00998.54 ], Gyr [ 00002.13, -00001.28, -00000.37 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000008179 NoMotion-1. Scaled. Acc [ -00042.97, -00008.30, 00994.14 ], Gyr [ -00000.24, 00001.95, -00002.26 ], Mag [ 00020.70, 00082.80, 00107.10 ]",
    "#0000008180 NoMotion-1. Scaled. Acc [ -00039.55, -00001.95, 00983.89 ], Gyr [ 00000.73, 00003.05, 00000.24 ], Mag [ 00020.70, 00082.80, 00107.10 ]",
    "#0000008181 NoMotion-1. Scaled. Acc [ -00043.46, -00011.72, 00993.65 ], Gyr [ -00000.91, 00000.49, -00003.11 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008182 NoMotion-1. Scaled. Acc [ -00050.78, -00010.25, 00983.89 ], Gyr [ 00000.12, 00000.73, 00000.30 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008183 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00982.42 ], Gyr [ 00000.67, 00003.54, -00002.56 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008184 NoMotion-1. Scaled. Acc [ -00042.97, -00008.30, 00993.65 ], Gyr [ 00000.43, 00003.05, 00000.12 ], Mag [ 00020.25, 00084.30, 00106.80 ]",
    "#0000008185 NoMotion-1. Scaled. Acc [ -00051.76, -00000.98, 00990.23 ], Gyr [ 00002.93, 00001.22, 00000.73 ], Mag [ 00019.20, 00084.00, 00106.50 ]",
    "#0000008186 NoMotion-1. Scaled. Acc [ -00041.50, -00010.74, 00995.61 ], Gyr [ 00000.91, 00002.20, -00001.28 ], Mag [ 00019.20, 00084.00, 00106.50 ]",
    "#0000008187 NoMotion-1. Scaled. Acc [ -00037.11, -00013.67, 00991.70 ], Gyr [ -00000.30, 00001.40, -00002.01 ], Mag [ 00021.30, 00082.80, 00107.85 ]",
    "#0000008188 NoMotion-1. Scaled. Acc [ -00036.62, -00014.16, 00992.19 ], Gyr [ 00000.06, 00001.83, 00000.30 ], Mag [ 00021.30, 00082.80, 00107.85 ]",
    "#0000008189 NoMotion-1. Scaled. Acc [ -00035.16, -00010.74, 00986.33 ], Gyr [ 00001.22, -00000.55, -00000.55 ], Mag [ 00021.00, 00084.00, 00108.90 ]",
    "#0000008190 NoMotion-1. Scaled. Acc [ -00037.60, -00017.09, 00972.17 ], Gyr [ 00000.00, 00000.12, -00002.07 ], Mag [ 00021.00, 00084.00, 00108.90 ]",
    "#0000008191 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 01011.23 ], Gyr [ 00000.06, 00001.89, 00000.67 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008192 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00990.23 ], Gyr [ -00000.67, 00001.77, -00003.23 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008193 NoMotion-1. Scaled. Acc [ -00040.53, -00014.16, 00987.79 ], Gyr [ 00000.55, 00004.45, -00000.61 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008194 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00996.58 ], Gyr [ -00000.37, 00002.68, 00001.22 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000008195 NoMotion-1. Scaled. Acc [ -00040.53, -00013.67, 00995.12 ], Gyr [ 00000.18, 00002.56, -00002.93 ], Mag [ 00020.10, 00084.45, 00107.70 ]",
    "#0000008196 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 00996.09 ], Gyr [ -00000.98, 00002.50, -00002.56 ], Mag [ 00020.25, 00082.05, 00106.80 ]",
    "#0000008197 NoMotion-1. Scaled. Acc [ -00055.66, -00000.98, 00993.16 ], Gyr [ 00001.40, 00000.91, -00001.04 ], Mag [ 00020.25, 00082.05, 00106.80 ]",
    "#0000008198 NoMotion-1. Scaled. Acc [ -00048.83, -00010.74, 00986.33 ], Gyr [ 00000.67, -00001.16, 00000.18 ], Mag [ 00020.10, 00084.15, 00107.70 ]",
    "#0000008199 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 01001.46 ], Gyr [ -00000.98, 00005.18, -00001.16 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008200 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00998.05 ], Gyr [ 00001.10, 00002.38, -00002.20 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008201 NoMotion-1. Scaled. Acc [ -00042.97, -00014.65, 00986.33 ], Gyr [ 00000.30, 00002.93, -00001.04 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008202 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00989.75 ], Gyr [ -00000.30, 00000.73, -00001.95 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000008203 NoMotion-1. Scaled. Acc [ -00027.34, -00016.11, 00990.23 ], Gyr [ 00000.91, 00001.52, 00002.13 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000008204 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00989.26 ], Gyr [ 00000.12, 00002.01, -00001.28 ], Mag [ 00019.20, 00084.00, 00108.60 ]",
    "#0000008205 NoMotion-1. Scaled. Acc [ -00039.06, -00009.28, 00986.82 ], Gyr [ -00000.73, 00000.79, -00001.65 ], Mag [ 00019.20, 00084.00, 00108.60 ]",
    "#0000008206 NoMotion-1. Scaled. Acc [ -00042.97, 00000.98, 00983.40 ], Gyr [ -00000.67, 00002.87, -00004.51 ], Mag [ 00019.20, 00084.00, 00108.60 ]",
    "#0000008207 NoMotion-1. Scaled. Acc [ -00051.76, -00005.86, 00985.84 ], Gyr [ -00000.12, 00003.54, 00000.43 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008208 NoMotion-1. Scaled. Acc [ -00036.62, -00011.23, 00999.02 ], Gyr [ -00000.18, 00002.20, -00001.40 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008209 NoMotion-1. Scaled. Acc [ -00038.57, 00001.46, 00991.70 ], Gyr [ 00000.73, 00003.05, -00001.83 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008210 NoMotion-1. Scaled. Acc [ -00045.41, -00002.44, 00993.16 ], Gyr [ 00000.06, 00005.79, -00004.45 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008211 NoMotion-1. Scaled. Acc [ -00041.99, -00013.67, 00982.42 ], Gyr [ 00000.49, -00002.38, -00001.95 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008212 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00990.23 ], Gyr [ 00002.20, 00002.26, 00001.59 ], Mag [ 00019.95, 00082.80, 00108.90 ]",
    "#0000008213 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00989.75 ], Gyr [ 00001.46, 00001.04, -00001.40 ], Mag [ 00019.35, 00084.45, 00106.65 ]",
    "#0000008214 NoMotion-1. Scaled. Acc [ -00041.99, -00006.84, 00994.14 ], Gyr [ 00001.65, 00002.99, 00002.13 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000008215 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00992.19 ], Gyr [ -00000.61, 00003.17, -00003.60 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000008216 NoMotion-1. Scaled. Acc [ -00038.57, -00003.42, 00988.28 ], Gyr [ -00000.06, 00002.01, -00000.18 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000008217 NoMotion-1. Scaled. Acc [ -00040.04, -00009.28, 00991.21 ], Gyr [ 00001.89, 00000.85, -00001.89 ], Mag [ 00019.35, 00083.40, 00107.70 ]",
    "#0000008218 NoMotion-1. Scaled. Acc [ -00049.32, -00019.53, 00984.38 ], Gyr [ 00001.59, 00003.05, -00000.18 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008219 NoMotion-1. Scaled. Acc [ -00049.32, -00026.37, 00987.79 ], Gyr [ -00000.79, 00002.20, -00001.83 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008220 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00992.19 ], Gyr [ -00000.06, 00002.93, -00002.44 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008221 NoMotion-1. Scaled. Acc [ -00048.34, -00007.81, 00985.35 ], Gyr [ 00001.52, -00000.73, 00001.04 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000008222 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00995.61 ], Gyr [ -00002.62, -00000.06, -00004.39 ], Mag [ 00019.95, 00084.00, 00107.85 ]",
    "#0000008223 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00998.54 ], Gyr [ -00001.65, 00001.71, -00000.91 ], Mag [ 00019.95, 00084.00, 00107.85 ]",
    "#0000008224 NoMotion-1. Scaled. Acc [ -00034.67, -00001.95, 00988.77 ], Gyr [ -00000.91, 00000.55, -00000.67 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000008225 NoMotion-1. Scaled. Acc [ -00040.04, -00011.72, 00988.28 ], Gyr [ 00001.77, 00002.20, -00001.10 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000008226 NoMotion-1. Scaled. Acc [ -00040.04, -00009.77, 00991.21 ], Gyr [ 00002.50, 00001.65, -00002.93 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000008227 NoMotion-1. Scaled. Acc [ -00054.20, -00002.93, 00991.70 ], Gyr [ 00001.83, 00000.61, 00000.00 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008228 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00998.54 ], Gyr [ -00001.52, 00002.74, -00002.20 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008229 NoMotion-1. Scaled. Acc [ -00035.64, -00014.65, 00993.65 ], Gyr [ 00000.49, 00001.46, -00002.44 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008230 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00995.12 ], Gyr [ 00002.01, -00002.32, -00001.95 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008231 NoMotion-1. Scaled. Acc [ -00043.46, -00001.95, 01000.00 ], Gyr [ 00000.67, 00003.23, -00001.71 ], Mag [ 00017.70, 00084.00, 00107.85 ]",
    "#0000008232 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00997.56 ], Gyr [ 00001.16, 00002.26, -00003.66 ], Mag [ 00019.50, 00084.30, 00107.85 ]",
    "#0000008233 NoMotion-1. Scaled. Acc [ -00049.80, -00001.46, 00981.93 ], Gyr [ 00001.71, 00000.91, -00001.65 ], Mag [ 00019.50, 00084.30, 00108.30 ]",
    "#0000008234 NoMotion-1. Scaled. Acc [ -00047.36, 00007.32, 00981.93 ], Gyr [ 00001.40, 00000.98, -00001.89 ], Mag [ 00019.05, 00083.40, 00108.30 ]",
    "#0000008235 NoMotion-1. Scaled. Acc [ -00062.99, -00012.70, 00990.72 ], Gyr [ -00000.43, 00003.41, -00003.48 ], Mag [ 00019.05, 00083.40, 00106.65 ]",
    "#0000008236 NoMotion-1. Scaled. Acc [ -00046.88, -00005.37, 00995.61 ], Gyr [ -00000.85, 00002.74, -00001.95 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000008237 NoMotion-1. Scaled. Acc [ -00045.90, -00001.46, 00994.63 ], Gyr [ -00001.16, 00000.43, 00001.10 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000008238 NoMotion-1. Scaled. Acc [ -00044.92, -00015.14, 00993.16 ], Gyr [ -00000.91, -00000.30, 00000.43 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000008239 NoMotion-1. Scaled. Acc [ -00039.06, -00009.77, 00998.05 ], Gyr [ 00000.79, 00001.28, -00001.28 ], Mag [ 00020.25, 00084.00, 00109.35 ]",
    "#0000008240 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00996.09 ], Gyr [ 00000.67, 00003.84, -00001.89 ], Mag [ 00018.60, 00082.65, 00105.90 ]",
    "#0000008241 NoMotion-1. Scaled. Acc [ -00041.02, -00003.42, 00993.16 ], Gyr [ -00000.30, 00004.39, -00003.35 ], Mag [ 00018.60, 00082.65, 00105.90 ]",
    "#0000008242 NoMotion-1. Scaled. Acc [ -00051.76, -00004.39, 00993.65 ], Gyr [ 00002.44, -00000.18, -00001.52 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008243 NoMotion-1. Scaled. Acc [ -00051.76, -00000.98, 00988.28 ], Gyr [ 00000.85, 00003.05, -00003.23 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008244 NoMotion-1. Scaled. Acc [ -00040.04, -00010.74, 00989.26 ], Gyr [ 00000.18, 00001.83, -00001.59 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008245 NoMotion-1. Scaled. Acc [ -00041.99, -00018.07, 00986.82 ], Gyr [ 00002.68, 00000.85, -00003.35 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000008246 NoMotion-1. Scaled. Acc [ -00048.34, 00001.95, 00996.58 ], Gyr [ 00001.52, -00000.06, -00002.44 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000008247 NoMotion-1. Scaled. Acc [ -00039.55, -00003.91, 00986.82 ], Gyr [ 00000.67, 00001.10, -00002.07 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000008248 NoMotion-1. Scaled. Acc [ -00040.04, -00001.95, 00984.38 ], Gyr [ 00000.30, -00001.34, -00001.16 ], Mag [ 00018.60, 00083.40, 00107.70 ]",
    "#0000008249 NoMotion-1. Scaled. Acc [ -00054.69, -00007.81, 01005.37 ], Gyr [ -00000.30, 00003.90, -00004.45 ], Mag [ 00018.60, 00084.45, 00107.70 ]",
    "#0000008250 NoMotion-1. Scaled. Acc [ -00040.53, -00009.28, 00995.12 ], Gyr [ 00000.55, 00000.30, 00000.61 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008251 NoMotion-1. Scaled. Acc [ -00055.18, -00014.65, 00993.65 ], Gyr [ 00002.20, 00000.30, -00000.49 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008252 NoMotion-1. Scaled. Acc [ -00039.55, 00005.37, 00983.89 ], Gyr [ 00001.40, 00000.61, -00001.77 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008253 NoMotion-1. Scaled. Acc [ -00060.06, -00016.11, 00988.77 ], Gyr [ 00000.91, 00001.77, -00002.44 ], Mag [ 00018.60, 00082.65, 00106.95 ]",
    "#0000008254 NoMotion-1. Scaled. Acc [ -00047.36, -00004.39, 00996.09 ], Gyr [ 00000.00, 00000.79, -00001.71 ], Mag [ 00020.70, 00083.25, 00107.55 ]",
    "#0000008255 NoMotion-1. Scaled. Acc [ -00046.88, -00011.23, 00997.56 ], Gyr [ 00004.02, 00001.28, -00001.10 ], Mag [ 00020.70, 00083.25, 00107.55 ]",
    "#0000008256 NoMotion-1. Scaled. Acc [ -00034.18, -00013.18, 00997.56 ], Gyr [ -00000.06, 00002.44, -00001.65 ], Mag [ 00019.20, 00084.00, 00107.55 ]",
    "#0000008257 NoMotion-1. Scaled. Acc [ -00040.53, -00016.60, 00982.42 ], Gyr [ -00000.06, 00001.28, -00003.11 ], Mag [ 00019.20, 00084.00, 00107.55 ]",
    "#0000008258 NoMotion-1. Scaled. Acc [ -00036.13, -00003.91, 00994.14 ], Gyr [ 00001.22, 00001.95, 00001.28 ], Mag [ 00018.75, 00082.50, 00106.50 ]",
    "#0000008259 NoMotion-1. Scaled. Acc [ -00049.80, -00007.81, 01000.98 ], Gyr [ 00000.37, -00000.67, 00000.98 ], Mag [ 00018.75, 00082.50, 00106.50 ]",
    "#0000008260 NoMotion-1. Scaled. Acc [ -00045.90, 00005.86, 00989.26 ], Gyr [ -00000.12, 00000.00, -00003.48 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008261 NoMotion-1. Scaled. Acc [ -00046.39, -00017.09, 00988.77 ], Gyr [ 00000.24, 00002.56, -00001.40 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008262 NoMotion-1. Scaled. Acc [ -00040.04, -00006.84, 00991.21 ], Gyr [ 00002.50, 00000.98, 00000.00 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008263 NoMotion-1. Scaled. Acc [ -00041.99, -00005.37, 00978.03 ], Gyr [ 00000.98, -00000.24, 00001.04 ], Mag [ 00019.35, 00082.95, 00106.65 ]",
    "#0000008264 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00982.42 ], Gyr [ 00001.71, 00001.95, -00003.29 ], Mag [ 00019.95, 00082.80, 00106.50 ]",
    "#0000008265 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00996.09 ], Gyr [ 00000.06, 00002.32, -00000.43 ], Mag [ 00019.95, 00082.80, 00106.50 ]",
    "#0000008266 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00990.23 ], Gyr [ -00000.30, 00001.16, -00000.79 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008267 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 01001.46 ], Gyr [ 00000.18, 00003.60, -00002.26 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008268 NoMotion-1. Scaled. Acc [ -00053.22, -00012.21, 00994.14 ], Gyr [ -00000.98, -00000.12, -00003.78 ], Mag [ 00019.50, 00082.80, 00108.60 ]",
    "#0000008269 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00989.75 ], Gyr [ -00000.55, 00002.13, 00001.46 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008270 NoMotion-1. Scaled. Acc [ -00041.99, -00011.72, 00993.65 ], Gyr [ -00001.28, -00000.12, -00001.59 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008271 NoMotion-1. Scaled. Acc [ -00047.85, -00009.77, 00975.59 ], Gyr [ 00000.85, -00000.30, -00001.46 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008272 NoMotion-1. Scaled. Acc [ -00042.97, 00000.98, 00987.79 ], Gyr [ -00001.46, 00000.98, -00002.13 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008273 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00994.63 ], Gyr [ 00000.55, 00001.04, -00003.17 ], Mag [ 00019.35, 00083.70, 00108.75 ]",
    "#0000008274 NoMotion-1. Scaled. Acc [ -00036.13, -00009.77, 00983.40 ], Gyr [ -00000.18, -00001.28, 00001.40 ], Mag [ 00019.35, 00083.70, 00108.75 ]",
    "#0000008275 NoMotion-1. Scaled. Acc [ -00052.25, -00006.35, 01003.42 ], Gyr [ 00001.28, -00001.59, -00001.52 ], Mag [ 00018.15, 00081.75, 00106.80 ]",
    "#0000008276 NoMotion-1. Scaled. Acc [ -00045.41, -00018.07, 00987.30 ], Gyr [ 00001.59, -00001.52, -00001.04 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000008277 NoMotion-1. Scaled. Acc [ -00050.29, -00013.67, 00984.86 ], Gyr [ -00001.89, 00002.44, -00002.68 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000008278 NoMotion-1. Scaled. Acc [ -00041.50, -00011.23, 00987.79 ], Gyr [ 00001.52, -00000.43, 00000.24 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008279 NoMotion-1. Scaled. Acc [ -00034.67, -00010.25, 00995.61 ], Gyr [ 00002.13, 00001.22, -00001.59 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008280 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00979.49 ], Gyr [ -00001.10, 00005.00, -00003.48 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008281 NoMotion-1. Scaled. Acc [ -00043.95, 00001.95, 00986.33 ], Gyr [ -00001.10, 00001.77, -00002.32 ], Mag [ 00019.50, 00084.30, 00108.60 ]",
    "#0000008282 NoMotion-1. Scaled. Acc [ -00049.32, -00003.91, 00982.91 ], Gyr [ 00001.16, 00001.46, -00002.80 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008283 NoMotion-1. Scaled. Acc [ -00049.32, -00011.72, 00988.77 ], Gyr [ 00001.40, 00000.30, 00000.43 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008284 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00988.28 ], Gyr [ 00000.61, 00001.46, -00000.91 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008285 NoMotion-1. Scaled. Acc [ -00049.32, -00023.44, 00988.77 ], Gyr [ -00000.85, 00001.59, -00001.95 ], Mag [ 00019.95, 00083.55, 00107.55 ]",
    "#0000008286 NoMotion-1. Scaled. Acc [ -00033.69, -00015.14, 00986.33 ], Gyr [ 00000.73, 00000.55, 00003.11 ], Mag [ 00019.35, 00083.70, 00106.95 ]",
    "#0000008287 NoMotion-1. Scaled. Acc [ -00031.74, -00002.93, 00985.84 ], Gyr [ 00000.18, 00000.98, 00001.46 ], Mag [ 00019.05, 00083.40, 00108.45 ]",
    "#0000008288 NoMotion-1. Scaled. Acc [ -00041.50, -00013.67, 00993.65 ], Gyr [ 00000.79, 00000.43, -00002.26 ], Mag [ 00019.05, 00083.40, 00108.45 ]",
    "#0000008289 NoMotion-1. Scaled. Acc [ -00040.04, -00019.04, 00997.56 ], Gyr [ 00001.95, -00001.34, -00001.28 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000008290 NoMotion-1. Scaled. Acc [ -00053.22, -00017.58, 00977.05 ], Gyr [ 00002.32, 00001.77, -00000.06 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000008291 NoMotion-1. Scaled. Acc [ -00040.04, -00009.28, 00993.16 ], Gyr [ -00000.37, 00000.98, -00002.93 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008292 NoMotion-1. Scaled. Acc [ -00045.90, -00002.44, 00979.49 ], Gyr [ 00002.13, -00001.10, -00000.37 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008293 NoMotion-1. Scaled. Acc [ -00054.69, -00011.72, 00990.72 ], Gyr [ -00000.18, 00002.93, 00001.28 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008294 NoMotion-1. Scaled. Acc [ -00044.92, -00006.35, 00995.61 ], Gyr [ -00000.98, -00000.79, -00002.26 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008295 NoMotion-1. Scaled. Acc [ -00046.88, -00012.21, 00988.77 ], Gyr [ 00000.00, 00002.32, -00001.16 ], Mag [ 00020.40, 00084.15, 00106.95 ]",
    "#0000008296 NoMotion-1. Scaled. Acc [ -00044.43, -00003.42, 00996.58 ], Gyr [ 00000.12, 00001.95, -00002.80 ], Mag [ 00020.40, 00084.15, 00106.95 ]",
    "#0000008297 NoMotion-1. Scaled. Acc [ -00031.25, -00016.60, 00999.51 ], Gyr [ 00000.55, 00002.87, -00001.22 ], Mag [ 00021.00, 00084.00, 00106.50 ]",
    "#0000008298 NoMotion-1. Scaled. Acc [ -00046.39, -00019.53, 00987.30 ], Gyr [ -00000.24, 00002.68, -00001.89 ], Mag [ 00021.00, 00084.00, 00106.50 ]",
    "#0000008299 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00991.21 ], Gyr [ 00002.26, 00000.91, 00000.37 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008300 NoMotion-1. Scaled. Acc [ -00035.16, -00013.67, 00979.49 ], Gyr [ 00001.22, 00001.52, -00001.22 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008301 NoMotion-1. Scaled. Acc [ -00045.41, -00009.77, 00986.33 ], Gyr [ 00002.93, 00000.43, -00000.49 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008302 NoMotion-1. Scaled. Acc [ -00036.62, 00001.95, 00987.30 ], Gyr [ 00000.24, 00000.12, -00003.11 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008303 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00998.05 ], Gyr [ 00002.44, 00000.49, -00000.67 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008304 NoMotion-1. Scaled. Acc [ -00039.55, -00022.46, 00982.91 ], Gyr [ 00001.22, 00000.37, 00000.37 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000008305 NoMotion-1. Scaled. Acc [ -00028.32, -00007.32, 00998.05 ], Gyr [ -00000.85, -00000.37, -00002.20 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000008306 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 00986.82 ], Gyr [ 00000.55, 00000.06, -00001.59 ], Mag [ 00019.35, 00083.70, 00107.70 ]",
    "#0000008307 NoMotion-1. Scaled. Acc [ -00035.16, -00015.14, 00989.75 ], Gyr [ -00000.24, 00002.56, -00000.79 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000008308 NoMotion-1. Scaled. Acc [ -00035.16, 00004.88, 00993.16 ], Gyr [ -00002.50, 00001.83, -00002.01 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000008309 NoMotion-1. Scaled. Acc [ -00041.99, 00000.98, 00999.02 ], Gyr [ 00000.67, 00002.26, -00001.89 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008310 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00979.49 ], Gyr [ 00000.55, 00001.52, -00000.37 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008311 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00988.28 ], Gyr [ -00000.12, -00000.61, -00001.16 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008312 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00993.65 ], Gyr [ 00001.04, -00000.61, -00003.17 ], Mag [ 00019.50, 00082.80, 00107.55 ]",
    "#0000008313 NoMotion-1. Scaled. Acc [ -00052.25, -00003.91, 01001.95 ], Gyr [ 00002.93, 00000.43, 00000.30 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008314 NoMotion-1. Scaled. Acc [ -00058.59, -00010.25, 00993.16 ], Gyr [ -00000.12, -00000.61, 00000.06 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008315 NoMotion-1. Scaled. Acc [ -00037.60, -00017.58, 00997.07 ], Gyr [ 00000.98, 00002.20, 00000.12 ], Mag [ 00018.60, 00084.15, 00106.20 ]",
    "#0000008316 NoMotion-1. Scaled. Acc [ -00053.22, -00003.91, 01004.88 ], Gyr [ 00000.49, 00001.65, -00001.22 ], Mag [ 00018.60, 00084.15, 00106.20 ]",
    "#0000008317 NoMotion-1. Scaled. Acc [ -00031.74, -00004.39, 00983.89 ], Gyr [ 00000.85, 00002.62, -00002.38 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000008318 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00984.86 ], Gyr [ -00000.61, 00002.07, -00001.34 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000008319 NoMotion-1. Scaled. Acc [ -00046.88, -00016.11, 01000.49 ], Gyr [ 00000.18, 00002.32, -00000.79 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008320 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00981.45 ], Gyr [ 00000.85, 00005.12, -00002.01 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008321 NoMotion-1. Scaled. Acc [ -00038.09, 00001.46, 00994.63 ], Gyr [ 00000.06, 00003.66, -00002.68 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008322 NoMotion-1. Scaled. Acc [ -00028.32, -00009.77, 00993.16 ], Gyr [ 00001.46, 00002.01, -00003.35 ], Mag [ 00020.25, 00081.00, 00106.80 ]",
    "#0000008323 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00992.68 ], Gyr [ 00002.32, 00000.85, -00001.10 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000008324 NoMotion-1. Scaled. Acc [ -00039.55, -00016.60, 01000.49 ], Gyr [ 00001.16, 00001.95, 00001.77 ], Mag [ 00019.65, 00083.70, 00106.65 ]",
    "#0000008325 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00997.07 ], Gyr [ -00001.46, 00000.98, -00003.29 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008326 NoMotion-1. Scaled. Acc [ -00040.53, -00015.62, 00994.14 ], Gyr [ 00001.22, -00000.18, -00001.65 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008327 NoMotion-1. Scaled. Acc [ -00047.85, 00000.98, 00981.45 ], Gyr [ 00001.34, -00000.98, -00000.61 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000008328 NoMotion-1. Scaled. Acc [ -00044.43, -00003.42, 00980.47 ], Gyr [ -00000.55, 00000.98, 00000.67 ], Mag [ 00019.50, 00084.75, 00107.55 ]",
    "#0000008329 NoMotion-1. Scaled. Acc [ -00038.57, -00010.25, 00974.12 ], Gyr [ 00000.55, 00001.10, -00001.83 ], Mag [ 00019.50, 00084.75, 00107.55 ]",
    "#0000008330 NoMotion-1. Scaled. Acc [ -00040.53, -00010.74, 01001.95 ], Gyr [ -00001.65, 00002.38, -00001.52 ], Mag [ 00019.50, 00084.75, 00107.55 ]",
    "#0000008331 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00994.63 ], Gyr [ -00000.24, 00000.37, -00001.52 ], Mag [ 00021.15, 00084.15, 00107.70 ]",
    "#0000008332 NoMotion-1. Scaled. Acc [ -00040.53, -00007.81, 00988.77 ], Gyr [ -00000.37, 00000.49, -00003.17 ], Mag [ 00021.15, 00084.15, 00107.70 ]",
    "#0000008333 NoMotion-1. Scaled. Acc [ -00053.71, -00004.88, 00993.16 ], Gyr [ -00001.28, 00001.16, -00002.07 ], Mag [ 00020.25, 00084.15, 00107.70 ]",
    "#0000008334 NoMotion-1. Scaled. Acc [ -00047.36, -00002.44, 00993.65 ], Gyr [ 00000.37, 00002.32, -00001.71 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008335 NoMotion-1. Scaled. Acc [ -00047.36, -00009.77, 00996.09 ], Gyr [ 00000.91, 00001.22, -00001.89 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008336 NoMotion-1. Scaled. Acc [ -00041.99, -00004.88, 00981.93 ], Gyr [ 00000.98, 00002.74, -00003.23 ], Mag [ 00020.10, 00082.20, 00106.95 ]",
    "#0000008337 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00987.30 ], Gyr [ -00000.55, 00000.79, -00002.01 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008338 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00987.79 ], Gyr [ 00000.98, 00000.18, -00001.52 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008339 NoMotion-1. Scaled. Acc [ -00036.13, -00000.98, 00992.19 ], Gyr [ -00000.30, -00000.37, 00000.37 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008340 NoMotion-1. Scaled. Acc [ -00041.50, -00010.25, 00991.70 ], Gyr [ 00001.83, 00001.40, -00000.73 ], Mag [ 00018.45, 00084.00, 00107.55 ]",
    "#0000008341 NoMotion-1. Scaled. Acc [ -00033.69, -00011.72, 00987.79 ], Gyr [ -00000.79, 00001.95, -00003.17 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000008342 NoMotion-1. Scaled. Acc [ -00048.34, 00004.39, 00980.96 ], Gyr [ -00000.49, 00004.02, -00001.71 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000008343 NoMotion-1. Scaled. Acc [ -00033.69, -00007.32, 00986.82 ], Gyr [ 00001.77, 00002.01, -00000.61 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008344 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00993.16 ], Gyr [ 00001.65, 00000.37, -00000.85 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008345 NoMotion-1. Scaled. Acc [ -00043.95, 00009.77, 00985.84 ], Gyr [ -00000.06, 00001.34, -00003.48 ], Mag [ 00020.70, 00083.55, 00107.85 ]",
    "#0000008346 NoMotion-1. Scaled. Acc [ -00032.71, -00003.91, 00993.65 ], Gyr [ 00000.61, 00002.74, -00002.93 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008347 NoMotion-1. Scaled. Acc [ -00046.39, -00030.76, 00992.19 ], Gyr [ 00000.91, 00000.12, -00001.77 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008348 NoMotion-1. Scaled. Acc [ -00045.90, -00011.72, 00998.54 ], Gyr [ 00000.79, 00002.38, -00002.44 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008349 NoMotion-1. Scaled. Acc [ -00039.55, -00011.23, 00984.38 ], Gyr [ 00001.52, 00000.73, -00000.12 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008350 NoMotion-1. Scaled. Acc [ -00046.88, -00006.84, 00990.23 ], Gyr [ -00001.71, 00001.28, -00003.29 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000008351 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00990.72 ], Gyr [ -00000.24, 00000.43, 00000.06 ], Mag [ 00017.70, 00084.00, 00107.55 ]",
    "#0000008352 NoMotion-1. Scaled. Acc [ -00050.29, -00014.65, 00995.12 ], Gyr [ 00000.85, 00000.85, -00000.18 ], Mag [ 00020.70, 00082.80, 00106.50 ]",
    "#0000008353 NoMotion-1. Scaled. Acc [ -00046.88, -00020.02, 00997.07 ], Gyr [ 00000.30, 00000.79, -00004.39 ], Mag [ 00020.70, 00082.80, 00106.50 ]",
    "#0000008354 NoMotion-1. Scaled. Acc [ -00036.62, -00006.84, 00995.61 ], Gyr [ -00000.91, 00000.98, -00002.80 ], Mag [ 00020.70, 00082.80, 00106.50 ]",
    "#0000008355 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00991.70 ], Gyr [ -00000.85, 00001.34, -00003.72 ], Mag [ 00020.70, 00083.25, 00107.85 ]",
    "#0000008356 NoMotion-1. Scaled. Acc [ -00039.06, 00000.49, 00994.14 ], Gyr [ 00000.37, -00000.73, 00000.91 ], Mag [ 00020.70, 00083.25, 00107.85 ]",
    "#0000008357 NoMotion-1. Scaled. Acc [ -00040.53, 00001.95, 00989.75 ], Gyr [ -00000.73, 00001.89, -00002.13 ], Mag [ 00018.60, 00083.70, 00108.45 ]",
    "#0000008358 NoMotion-1. Scaled. Acc [ -00040.53, -00006.35, 00986.33 ], Gyr [ -00000.73, 00003.90, -00003.72 ], Mag [ 00018.60, 00083.70, 00108.45 ]",
    "#0000008359 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 00982.91 ], Gyr [ 00000.37, 00002.68, -00002.13 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000008360 NoMotion-1. Scaled. Acc [ -00036.13, -00004.88, 00992.68 ], Gyr [ 00001.77, -00001.16, -00003.66 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000008361 NoMotion-1. Scaled. Acc [ -00041.02, -00006.84, 01000.98 ], Gyr [ 00001.28, 00002.07, -00000.37 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008362 NoMotion-1. Scaled. Acc [ -00048.34, -00009.28, 00984.86 ], Gyr [ 00001.40, -00003.11, -00000.12 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008363 NoMotion-1. Scaled. Acc [ -00044.92, -00000.49, 00983.40 ], Gyr [ 00001.52, 00002.80, -00000.30 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008364 NoMotion-1. Scaled. Acc [ -00058.11, -00004.88, 00989.75 ], Gyr [ -00000.18, 00002.32, -00003.48 ], Mag [ 00020.25, 00083.25, 00107.85 ]",
    "#0000008365 NoMotion-1. Scaled. Acc [ -00049.80, -00010.74, 00993.65 ], Gyr [ 00000.79, -00000.30, -00002.26 ], Mag [ 00018.60, 00083.70, 00107.40 ]",
    "#0000008366 NoMotion-1. Scaled. Acc [ -00035.64, -00009.77, 00998.54 ], Gyr [ 00001.59, 00003.17, -00000.06 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008367 NoMotion-1. Scaled. Acc [ -00043.95, -00010.25, 01000.00 ], Gyr [ -00001.10, 00000.61, -00002.56 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008368 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 00986.33 ], Gyr [ 00000.55, 00000.67, -00002.50 ], Mag [ 00020.85, 00084.00, 00107.85 ]",
    "#0000008369 NoMotion-1. Scaled. Acc [ -00041.99, 00000.49, 00987.30 ], Gyr [ -00000.43, 00002.50, -00001.46 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000008370 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00987.79 ], Gyr [ 00000.43, 00001.10, -00001.71 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008371 NoMotion-1. Scaled. Acc [ -00036.62, 00006.35, 00989.26 ], Gyr [ 00001.28, -00000.85, -00002.44 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008372 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00981.45 ], Gyr [ -00000.98, 00001.95, -00001.89 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008373 NoMotion-1. Scaled. Acc [ -00056.64, -00001.46, 00999.02 ], Gyr [ -00002.74, 00003.66, -00000.79 ], Mag [ 00020.40, 00083.70, 00108.00 ]",
    "#0000008374 NoMotion-1. Scaled. Acc [ -00052.73, -00005.37, 00990.72 ], Gyr [ 00000.79, 00001.34, 00000.18 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000008375 NoMotion-1. Scaled. Acc [ -00033.20, -00010.25, 00991.70 ], Gyr [ 00000.18, 00000.55, 00000.91 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000008376 NoMotion-1. Scaled. Acc [ -00047.85, -00002.93, 00989.75 ], Gyr [ 00001.59, 00002.07, -00002.62 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008377 NoMotion-1. Scaled. Acc [ -00046.39, -00006.84, 00989.26 ], Gyr [ 00000.73, 00001.16, -00000.43 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000008378 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00994.14 ], Gyr [ 00002.01, 00001.04, 00000.00 ], Mag [ 00019.95, 00084.30, 00106.80 ]",
    "#0000008379 NoMotion-1. Scaled. Acc [ -00050.78, -00003.42, 00979.00 ], Gyr [ 00000.98, -00000.98, 00001.22 ], Mag [ 00019.95, 00084.30, 00106.80 ]",
    "#0000008380 NoMotion-1. Scaled. Acc [ -00047.85, -00000.49, 00993.65 ], Gyr [ 00001.77, 00002.62, -00002.26 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008381 NoMotion-1. Scaled. Acc [ -00039.55, -00012.21, 00987.30 ], Gyr [ -00000.43, 00002.87, -00003.66 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008382 NoMotion-1. Scaled. Acc [ -00039.06, -00003.91, 00977.54 ], Gyr [ -00000.37, 00000.06, -00001.10 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008383 NoMotion-1. Scaled. Acc [ -00037.11, -00008.79, 00994.63 ], Gyr [ 00001.46, 00000.37, -00001.10 ], Mag [ 00018.75, 00083.55, 00106.50 ]",
    "#0000008384 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00987.30 ], Gyr [ -00000.79, 00002.68, -00004.45 ], Mag [ 00018.75, 00083.55, 00106.50 ]",
    "#0000008385 NoMotion-1. Scaled. Acc [ -00046.88, 00000.49, 00990.72 ], Gyr [ 00000.37, 00005.06, 00001.89 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008386 NoMotion-1. Scaled. Acc [ -00028.81, 00002.44, 00991.21 ], Gyr [ 00001.77, 00000.73, -00002.20 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000008387 NoMotion-1. Scaled. Acc [ -00042.97, -00013.67, 01005.37 ], Gyr [ 00001.65, -00001.65, -00001.52 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000008388 NoMotion-1. Scaled. Acc [ -00042.48, -00008.79, 01002.44 ], Gyr [ 00000.98, 00000.85, -00000.49 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008389 NoMotion-1. Scaled. Acc [ -00042.48, -00015.14, 01001.95 ], Gyr [ -00001.28, 00003.29, -00000.73 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008390 NoMotion-1. Scaled. Acc [ -00051.27, -00007.32, 00988.77 ], Gyr [ 00000.55, 00001.65, -00004.39 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008391 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00991.70 ], Gyr [ 00001.04, 00002.68, -00000.79 ], Mag [ 00019.65, 00083.40, 00107.40 ]",
    "#0000008392 NoMotion-1. Scaled. Acc [ -00046.39, -00013.67, 00994.63 ], Gyr [ -00000.18, -00000.37, -00000.24 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000008393 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00986.33 ], Gyr [ 00000.91, 00002.44, -00001.46 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000008394 NoMotion-1. Scaled. Acc [ -00035.16, -00003.42, 00998.05 ], Gyr [ -00000.12, -00000.73, -00003.05 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000008395 NoMotion-1. Scaled. Acc [ -00037.11, -00003.42, 00992.19 ], Gyr [ -00001.16, -00001.83, -00000.67 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000008396 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00988.28 ], Gyr [ -00000.12, 00001.46, -00002.44 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000008397 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00990.72 ], Gyr [ -00001.95, 00003.84, -00000.73 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000008398 NoMotion-1. Scaled. Acc [ -00038.57, -00010.74, 00975.59 ], Gyr [ -00001.16, 00000.79, -00001.95 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008399 NoMotion-1. Scaled. Acc [ -00046.39, -00008.30, 00971.19 ], Gyr [ 00001.59, 00001.22, -00001.46 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008400 NoMotion-1. Scaled. Acc [ -00049.80, -00017.09, 00981.45 ], Gyr [ 00000.30, 00000.37, -00004.57 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008401 NoMotion-1. Scaled. Acc [ -00055.18, -00011.23, 00990.23 ], Gyr [ 00001.16, 00001.52, -00001.95 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000008402 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00988.77 ], Gyr [ 00001.77, 00000.43, -00002.01 ], Mag [ 00018.45, 00085.05, 00107.85 ]",
    "#0000008403 NoMotion-1. Scaled. Acc [ -00048.34, -00011.23, 00995.61 ], Gyr [ 00002.20, 00000.98, 00001.04 ], Mag [ 00019.65, 00084.45, 00107.70 ]",
    "#0000008404 NoMotion-1. Scaled. Acc [ -00031.25, -00002.44, 00984.86 ], Gyr [ 00000.00, 00000.67, -00002.01 ], Mag [ 00019.65, 00084.45, 00107.70 ]",
    "#0000008405 NoMotion-1. Scaled. Acc [ -00031.74, -00007.32, 00985.84 ], Gyr [ 00000.43, -00000.06, -00003.05 ], Mag [ 00019.35, 00082.65, 00108.45 ]",
    "#0000008406 NoMotion-1. Scaled. Acc [ -00039.55, -00013.67, 00996.58 ], Gyr [ 00000.55, 00001.16, -00004.51 ], Mag [ 00019.35, 00082.65, 00108.45 ]",
    "#0000008407 NoMotion-1. Scaled. Acc [ -00049.32, -00001.95, 00988.77 ], Gyr [ 00001.10, 00001.22, -00000.12 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008408 NoMotion-1. Scaled. Acc [ -00046.39, 00003.42, 00999.02 ], Gyr [ -00000.61, 00002.87, -00002.62 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008409 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00988.28 ], Gyr [ -00000.18, 00000.91, -00001.77 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008410 NoMotion-1. Scaled. Acc [ -00034.67, -00018.55, 00993.16 ], Gyr [ 00000.49, -00000.37, 00001.28 ], Mag [ 00019.50, 00085.50, 00107.55 ]",
    "#0000008411 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00995.12 ], Gyr [ 00000.43, 00000.55, -00000.43 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008412 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00980.96 ], Gyr [ -00000.30, 00002.01, -00000.67 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008413 NoMotion-1. Scaled. Acc [ -00049.32, -00014.16, 00994.63 ], Gyr [ 00000.12, 00000.61, -00001.89 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008414 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00983.89 ], Gyr [ 00000.06, 00000.37, -00000.24 ], Mag [ 00020.25, 00084.30, 00109.35 ]",
    "#0000008415 NoMotion-1. Scaled. Acc [ -00040.53, 00004.39, 00997.56 ], Gyr [ 00001.04, 00002.50, -00000.37 ], Mag [ 00021.15, 00083.40, 00108.00 ]",
    "#0000008416 NoMotion-1. Scaled. Acc [ -00042.97, -00012.21, 00987.30 ], Gyr [ 00003.05, -00000.30, -00000.18 ], Mag [ 00021.15, 00083.40, 00108.00 ]",
    "#0000008417 NoMotion-1. Scaled. Acc [ -00049.80, -00013.18, 00987.30 ], Gyr [ 00002.20, 00003.11, 00000.49 ], Mag [ 00019.20, 00084.30, 00109.35 ]",
    "#0000008418 NoMotion-1. Scaled. Acc [ -00037.11, 00004.39, 00992.19 ], Gyr [ 00000.91, 00000.73, -00000.49 ], Mag [ 00019.20, 00084.30, 00109.35 ]",
    "#0000008419 NoMotion-1. Scaled. Acc [ -00055.66, 00001.95, 01000.00 ], Gyr [ 00001.34, 00004.15, -00002.62 ], Mag [ 00019.20, 00084.30, 00109.35 ]",
    "#0000008420 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 00990.23 ], Gyr [ -00000.43, 00002.20, -00002.99 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008421 NoMotion-1. Scaled. Acc [ -00043.46, -00010.74, 00982.42 ], Gyr [ -00000.73, -00001.10, -00001.59 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008422 NoMotion-1. Scaled. Acc [ -00037.60, -00003.91, 00989.75 ], Gyr [ -00000.43, 00000.85, -00000.98 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008423 NoMotion-1. Scaled. Acc [ -00044.92, -00002.44, 00998.05 ], Gyr [ 00000.12, 00000.37, -00004.02 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000008424 NoMotion-1. Scaled. Acc [ -00035.64, -00001.95, 00992.68 ], Gyr [ 00000.43, 00002.20, -00000.24 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000008425 NoMotion-1. Scaled. Acc [ -00052.73, 00005.37, 00989.75 ], Gyr [ 00000.79, 00003.11, -00002.20 ], Mag [ 00020.25, 00082.50, 00108.60 ]",
    "#0000008426 NoMotion-1. Scaled. Acc [ -00043.46, -00013.18, 00998.05 ], Gyr [ 00000.79, -00000.43, 00000.73 ], Mag [ 00020.25, 00082.50, 00108.60 ]",
    "#0000008427 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00992.68 ], Gyr [ 00000.91, -00000.06, 00000.30 ], Mag [ 00020.25, 00082.50, 00108.60 ]",
    "#0000008428 NoMotion-1. Scaled. Acc [ -00032.71, 00003.91, 00995.12 ], Gyr [ 00001.04, -00000.18, -00001.10 ], Mag [ 00018.75, 00083.55, 00107.85 ]",
    "#0000008429 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00995.12 ], Gyr [ 00001.28, -00000.55, -00001.22 ], Mag [ 00018.75, 00083.55, 00107.85 ]",
    "#0000008430 NoMotion-1. Scaled. Acc [ -00044.92, -00001.95, 00987.30 ], Gyr [ -00001.59, -00001.16, 00000.12 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000008431 NoMotion-1. Scaled. Acc [ -00059.08, 00002.44, 00992.68 ], Gyr [ 00001.22, -00001.89, 00000.30 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000008432 NoMotion-1. Scaled. Acc [ -00040.04, -00008.30, 00993.65 ], Gyr [ -00000.91, 00000.79, -00002.74 ], Mag [ 00020.10, 00082.20, 00107.40 ]",
    "#0000008433 NoMotion-1. Scaled. Acc [ -00041.99, -00017.58, 00986.33 ], Gyr [ -00000.18, 00002.13, -00002.50 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000008434 NoMotion-1. Scaled. Acc [ -00049.80, -00017.58, 00993.65 ], Gyr [ -00001.16, 00002.87, -00003.05 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000008435 NoMotion-1. Scaled. Acc [ -00034.18, -00009.28, 00993.16 ], Gyr [ 00000.73, 00000.73, -00000.12 ], Mag [ 00018.75, 00083.25, 00107.85 ]",
    "#0000008436 NoMotion-1. Scaled. Acc [ -00050.78, -00011.72, 00988.77 ], Gyr [ -00000.24, 00002.13, 00000.43 ], Mag [ 00018.75, 00083.25, 00107.85 ]",
    "#0000008437 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 00999.02 ], Gyr [ -00000.79, 00002.07, -00002.80 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000008438 NoMotion-1. Scaled. Acc [ -00040.04, -00015.62, 01000.00 ], Gyr [ 00002.26, 00002.07, -00001.28 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008439 NoMotion-1. Scaled. Acc [ -00051.76, -00009.28, 00985.84 ], Gyr [ 00002.99, 00001.52, -00001.40 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008440 NoMotion-1. Scaled. Acc [ -00054.20, -00007.32, 00992.68 ], Gyr [ 00001.46, 00001.40, -00001.34 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008441 NoMotion-1. Scaled. Acc [ -00045.90, -00000.98, 00988.77 ], Gyr [ 00000.85, 00001.04, 00000.06 ], Mag [ 00020.85, 00082.20, 00107.70 ]",
    "#0000008442 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00993.16 ], Gyr [ 00000.43, 00000.61, -00000.67 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008443 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00994.63 ], Gyr [ 00000.06, 00001.83, -00001.89 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000008444 NoMotion-1. Scaled. Acc [ -00051.76, -00015.14, 00989.26 ], Gyr [ -00001.28, 00004.09, -00001.52 ], Mag [ 00020.10, 00085.20, 00106.65 ]",
    "#0000008445 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00989.26 ], Gyr [ 00000.67, 00002.44, -00001.46 ], Mag [ 00020.10, 00085.20, 00106.65 ]",
    "#0000008446 NoMotion-1. Scaled. Acc [ -00040.04, -00025.88, 00991.70 ], Gyr [ -00000.98, 00003.17, -00003.54 ], Mag [ 00018.60, 00082.20, 00106.95 ]",
    "#0000008447 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00988.77 ], Gyr [ 00000.73, 00001.22, -00000.85 ], Mag [ 00018.60, 00082.20, 00106.95 ]",
    "#0000008448 NoMotion-1. Scaled. Acc [ -00041.99, -00009.77, 00980.96 ], Gyr [ 00000.24, 00001.52, -00002.68 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008449 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00990.23 ], Gyr [ 00000.67, 00000.37, -00001.52 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008450 NoMotion-1. Scaled. Acc [ -00049.80, -00011.72, 00984.38 ], Gyr [ -00001.65, 00002.26, -00003.90 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008451 NoMotion-1. Scaled. Acc [ -00038.57, 00001.95, 00990.72 ], Gyr [ 00002.07, 00004.27, -00000.67 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000008452 NoMotion-1. Scaled. Acc [ -00038.09, -00013.67, 00982.91 ], Gyr [ 00001.59, -00000.49, -00001.65 ], Mag [ 00019.05, 00084.15, 00108.75 ]",
    "#0000008453 NoMotion-1. Scaled. Acc [ -00044.92, -00000.49, 01002.93 ], Gyr [ 00001.52, 00002.38, -00000.43 ], Mag [ 00019.05, 00084.15, 00108.75 ]",
    "#0000008454 NoMotion-1. Scaled. Acc [ -00039.55, -00002.93, 00991.21 ], Gyr [ 00000.61, 00001.77, -00003.35 ], Mag [ 00021.30, 00084.00, 00108.30 ]",
    "#0000008455 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00986.33 ], Gyr [ 00001.04, -00000.37, -00000.61 ], Mag [ 00021.30, 00084.00, 00108.30 ]",
    "#0000008456 NoMotion-1. Scaled. Acc [ -00045.90, -00003.42, 01001.95 ], Gyr [ 00000.91, 00001.04, -00000.49 ], Mag [ 00021.15, 00082.65, 00108.45 ]",
    "#0000008457 NoMotion-1. Scaled. Acc [ -00040.04, 00001.95, 00992.68 ], Gyr [ 00000.06, 00001.28, -00003.78 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000008458 NoMotion-1. Scaled. Acc [ -00045.90, -00002.93, 00993.16 ], Gyr [ -00000.30, 00002.62, -00000.67 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000008459 NoMotion-1. Scaled. Acc [ -00035.64, -00007.81, 00990.72 ], Gyr [ 00000.49, 00000.24, -00000.79 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000008460 NoMotion-1. Scaled. Acc [ -00041.50, 00000.98, 00995.61 ], Gyr [ 00001.34, 00003.29, -00003.17 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000008461 NoMotion-1. Scaled. Acc [ -00054.20, -00002.93, 00984.38 ], Gyr [ 00000.49, 00000.67, -00001.34 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000008462 NoMotion-1. Scaled. Acc [ -00042.97, -00008.79, 00986.82 ], Gyr [ 00001.77, 00001.52, -00001.04 ], Mag [ 00019.20, 00083.55, 00108.30 ]",
    "#0000008463 NoMotion-1. Scaled. Acc [ -00041.99, -00014.16, 00991.70 ], Gyr [ 00001.65, 00004.09, -00000.98 ], Mag [ 00019.20, 00083.55, 00108.30 ]",
    "#0000008464 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00979.98 ], Gyr [ 00000.91, 00000.37, -00000.06 ], Mag [ 00018.75, 00084.75, 00108.60 ]",
    "#0000008465 NoMotion-1. Scaled. Acc [ -00059.08, -00000.49, 00997.07 ], Gyr [ 00003.17, 00000.18, -00000.73 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008466 NoMotion-1. Scaled. Acc [ -00048.34, -00011.23, 00988.77 ], Gyr [ 00000.91, 00001.89, -00002.93 ], Mag [ 00019.50, 00081.75, 00106.80 ]",
    "#0000008467 NoMotion-1. Scaled. Acc [ -00044.43, -00011.72, 00984.38 ], Gyr [ -00001.04, 00002.56, -00001.10 ], Mag [ 00019.50, 00081.75, 00106.80 ]",
    "#0000008468 NoMotion-1. Scaled. Acc [ -00038.09, -00005.37, 00980.96 ], Gyr [ -00000.49, 00000.85, -00004.39 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008469 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00992.68 ], Gyr [ 00000.37, 00001.34, -00001.22 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008470 NoMotion-1. Scaled. Acc [ -00059.08, 00001.46, 00994.63 ], Gyr [ 00000.55, 00002.99, -00003.96 ], Mag [ 00020.10, 00081.45, 00107.40 ]",
    "#0000008471 NoMotion-1. Scaled. Acc [ -00041.50, 00000.00, 00999.51 ], Gyr [ 00000.37, 00003.35, -00001.34 ], Mag [ 00020.10, 00081.45, 00107.40 ]",
    "#0000008472 NoMotion-1. Scaled. Acc [ -00063.48, -00012.21, 00990.72 ], Gyr [ 00000.98, 00000.67, -00001.59 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000008473 NoMotion-1. Scaled. Acc [ -00045.41, -00017.58, 00991.70 ], Gyr [ 00000.30, 00001.83, -00001.16 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000008474 NoMotion-1. Scaled. Acc [ -00047.85, -00019.53, 00994.14 ], Gyr [ 00002.20, 00001.52, -00001.65 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000008475 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00990.23 ], Gyr [ 00001.22, -00000.98, -00000.24 ], Mag [ 00018.60, 00084.90, 00108.00 ]",
    "#0000008476 NoMotion-1. Scaled. Acc [ -00041.50, -00014.65, 01004.39 ], Gyr [ 00001.34, 00001.22, 00000.85 ], Mag [ 00018.60, 00084.90, 00108.00 ]",
    "#0000008477 NoMotion-1. Scaled. Acc [ -00059.57, -00010.74, 00999.02 ], Gyr [ 00002.62, 00003.35, -00002.93 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008478 NoMotion-1. Scaled. Acc [ -00034.67, -00011.72, 00990.72 ], Gyr [ 00000.85, -00000.30, -00001.34 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000008479 NoMotion-1. Scaled. Acc [ -00041.99, -00010.25, 00990.23 ], Gyr [ -00001.59, 00001.71, -00001.10 ], Mag [ 00019.65, 00084.45, 00109.50 ]",
    "#0000008480 NoMotion-1. Scaled. Acc [ -00038.57, -00016.11, 00989.26 ], Gyr [ 00001.34, 00001.22, -00002.93 ], Mag [ 00019.65, 00084.45, 00109.50 ]",
    "#0000008481 NoMotion-1. Scaled. Acc [ -00032.71, -00009.77, 00999.51 ], Gyr [ 00002.13, -00000.18, -00000.12 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008482 NoMotion-1. Scaled. Acc [ -00052.25, -00013.18, 00989.75 ], Gyr [ 00000.91, 00004.39, -00003.41 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008483 NoMotion-1. Scaled. Acc [ -00052.73, -00000.49, 00998.54 ], Gyr [ 00000.30, 00002.26, -00000.79 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008484 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00997.07 ], Gyr [ -00000.12, 00003.17, -00001.22 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008485 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 00992.68 ], Gyr [ 00001.89, 00002.68, -00000.12 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008486 NoMotion-1. Scaled. Acc [ -00050.78, -00010.25, 01006.35 ], Gyr [ 00000.55, -00000.24, -00000.06 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008487 NoMotion-1. Scaled. Acc [ -00047.85, -00017.09, 00990.72 ], Gyr [ -00000.12, 00000.30, -00000.24 ], Mag [ 00018.75, 00082.50, 00108.30 ]",
    "#0000008488 NoMotion-1. Scaled. Acc [ -00036.62, -00002.44, 00987.30 ], Gyr [ 00001.59, 00001.34, -00001.28 ], Mag [ 00018.75, 00082.50, 00108.30 ]",
    "#0000008489 NoMotion-1. Scaled. Acc [ -00041.02, -00003.91, 00996.09 ], Gyr [ 00002.50, 00000.00, 00000.18 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008490 NoMotion-1. Scaled. Acc [ -00036.13, -00014.65, 00994.14 ], Gyr [ -00000.06, -00000.85, -00000.24 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008491 NoMotion-1. Scaled. Acc [ -00036.13, -00013.67, 00998.05 ], Gyr [ -00001.04, 00004.09, -00001.52 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008492 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00985.84 ], Gyr [ -00000.49, 00001.89, -00000.55 ], Mag [ 00019.95, 00083.25, 00107.85 ]",
    "#0000008493 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00993.65 ], Gyr [ -00001.77, -00000.12, -00001.34 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000008494 NoMotion-1. Scaled. Acc [ -00051.27, -00014.16, 00979.98 ], Gyr [ 00001.77, -00000.49, -00000.37 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000008495 NoMotion-1. Scaled. Acc [ -00044.92, -00005.86, 00990.23 ], Gyr [ -00000.12, 00002.01, 00000.43 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000008496 NoMotion-1. Scaled. Acc [ -00039.06, -00005.86, 00982.91 ], Gyr [ 00000.79, 00000.61, -00003.05 ], Mag [ 00019.50, 00083.25, 00107.85 ]",
    "#0000008497 NoMotion-1. Scaled. Acc [ -00051.76, -00005.37, 00993.16 ], Gyr [ 00000.61, 00004.27, -00001.04 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008498 NoMotion-1. Scaled. Acc [ -00037.60, -00006.35, 00981.45 ], Gyr [ 00001.28, 00000.98, -00000.61 ], Mag [ 00021.30, 00084.75, 00108.30 ]",
    "#0000008499 NoMotion-1. Scaled. Acc [ -00041.02, -00004.88, 00974.12 ], Gyr [ -00000.12, 00001.28, -00001.10 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008500 NoMotion-1. Scaled. Acc [ -00050.78, -00008.79, 00992.68 ], Gyr [ 00000.67, 00001.10, -00002.32 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008501 NoMotion-1. Scaled. Acc [ -00034.18, -00013.67, 00989.26 ], Gyr [ 00001.28, 00002.80, -00000.43 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008502 NoMotion-1. Scaled. Acc [ -00048.34, -00012.70, 00993.65 ], Gyr [ 00001.46, 00001.34, -00004.21 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000008503 NoMotion-1. Scaled. Acc [ -00049.80, -00018.07, 00984.38 ], Gyr [ 00000.12, 00000.18, 00001.89 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000008504 NoMotion-1. Scaled. Acc [ -00048.34, -00010.74, 00995.12 ], Gyr [ -00000.06, 00002.44, -00002.20 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000008505 NoMotion-1. Scaled. Acc [ -00054.69, -00012.21, 00988.77 ], Gyr [ 00000.24, 00001.34, -00002.20 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000008506 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00997.07 ], Gyr [ 00001.95, 00000.79, -00001.34 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000008507 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00993.65 ], Gyr [ 00001.65, 00001.22, -00002.26 ], Mag [ 00020.25, 00082.80, 00108.30 ]",
    "#0000008508 NoMotion-1. Scaled. Acc [ -00041.02, -00003.91, 00977.54 ], Gyr [ 00000.00, 00002.01, -00000.49 ], Mag [ 00020.25, 00082.80, 00108.30 ]",
    "#0000008509 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00987.30 ], Gyr [ 00001.40, 00000.43, -00005.49 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008510 NoMotion-1. Scaled. Acc [ -00035.16, -00008.30, 00996.58 ], Gyr [ 00000.67, 00001.59, -00002.13 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008511 NoMotion-1. Scaled. Acc [ -00049.32, -00014.16, 00994.63 ], Gyr [ -00002.32, 00000.79, -00000.06 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000008512 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00991.70 ], Gyr [ -00000.98, -00000.73, -00001.89 ], Mag [ 00019.50, 00083.55, 00106.50 ]",
    "#0000008513 NoMotion-1. Scaled. Acc [ -00037.11, -00012.70, 00987.30 ], Gyr [ 00000.43, 00001.46, 00000.12 ], Mag [ 00019.50, 00083.55, 00106.50 ]",
    "#0000008514 NoMotion-1. Scaled. Acc [ -00044.92, -00016.60, 00990.72 ], Gyr [ 00002.56, 00001.95, -00000.12 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000008515 NoMotion-1. Scaled. Acc [ -00047.85, -00019.53, 00984.86 ], Gyr [ 00001.04, -00000.30, -00002.01 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000008516 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00989.26 ], Gyr [ -00000.18, 00003.23, -00001.59 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000008517 NoMotion-1. Scaled. Acc [ -00042.97, -00003.42, 00985.35 ], Gyr [ 00001.22, 00000.49, -00001.71 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000008518 NoMotion-1. Scaled. Acc [ -00033.69, -00007.81, 00978.52 ], Gyr [ 00001.52, 00000.67, 00000.00 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000008519 NoMotion-1. Scaled. Acc [ -00031.25, -00015.62, 00996.09 ], Gyr [ -00001.04, 00000.18, -00003.05 ], Mag [ 00020.25, 00084.75, 00107.85 ]",
    "#0000008520 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00998.05 ], Gyr [ -00001.16, 00004.09, 00000.55 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008521 NoMotion-1. Scaled. Acc [ -00053.22, -00006.35, 00995.61 ], Gyr [ 00000.24, 00002.74, -00002.01 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000008522 NoMotion-1. Scaled. Acc [ -00040.53, -00006.84, 00995.12 ], Gyr [ 00000.98, 00002.87, -00001.28 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008523 NoMotion-1. Scaled. Acc [ -00043.95, -00006.84, 00989.75 ], Gyr [ 00000.06, 00001.65, 00000.85 ], Mag [ 00019.50, 00083.25, 00107.55 ]",
    "#0000008524 NoMotion-1. Scaled. Acc [ -00048.34, 00000.00, 00988.77 ], Gyr [ -00000.49, 00002.62, -00001.52 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000008525 NoMotion-1. Scaled. Acc [ -00034.67, -00016.11, 00991.21 ], Gyr [ 00001.22, 00000.43, -00002.44 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000008526 NoMotion-1. Scaled. Acc [ -00062.01, -00008.30, 00996.09 ], Gyr [ 00003.23, -00001.04, 00000.67 ], Mag [ 00018.75, 00084.30, 00109.65 ]",
    "#0000008527 NoMotion-1. Scaled. Acc [ -00043.95, 00001.95, 00987.30 ], Gyr [ 00001.89, -00000.06, -00000.79 ], Mag [ 00018.75, 00084.30, 00109.65 ]",
    "#0000008528 NoMotion-1. Scaled. Acc [ -00034.67, -00007.81, 00985.84 ], Gyr [ 00000.12, -00001.16, 00000.43 ], Mag [ 00018.75, 00084.30, 00109.65 ]",
    "#0000008529 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00979.98 ], Gyr [ 00001.95, 00001.65, 00001.04 ], Mag [ 00021.30, 00083.25, 00107.85 ]",
    "#0000008530 NoMotion-1. Scaled. Acc [ -00048.83, -00004.88, 00990.72 ], Gyr [ 00001.40, 00001.95, -00002.07 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008531 NoMotion-1. Scaled. Acc [ -00038.57, -00015.62, 00988.77 ], Gyr [ -00001.22, 00001.34, -00000.06 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008532 NoMotion-1. Scaled. Acc [ -00042.48, -00002.44, 00999.51 ], Gyr [ -00001.28, 00001.46, 00000.37 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000008533 NoMotion-1. Scaled. Acc [ -00035.64, -00013.67, 00996.09 ], Gyr [ 00000.30, 00001.83, -00002.07 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000008534 NoMotion-1. Scaled. Acc [ -00041.02, -00005.37, 00986.82 ], Gyr [ 00000.00, -00001.34, -00001.77 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000008535 NoMotion-1. Scaled. Acc [ -00041.99, -00015.62, 00987.79 ], Gyr [ 00001.34, 00001.40, 00000.24 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008536 NoMotion-1. Scaled. Acc [ -00041.99, -00014.16, 00995.61 ], Gyr [ 00000.91, 00004.63, -00002.93 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008537 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00984.38 ], Gyr [ 00003.11, 00002.38, -00001.22 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008538 NoMotion-1. Scaled. Acc [ -00049.32, -00004.88, 00995.61 ], Gyr [ 00001.22, 00000.91, -00002.32 ], Mag [ 00020.25, 00083.55, 00107.10 ]",
    "#0000008539 NoMotion-1. Scaled. Acc [ -00050.78, 00002.44, 00998.54 ], Gyr [ 00001.34, 00002.07, -00001.46 ], Mag [ 00019.95, 00082.80, 00107.85 ]",
    "#0000008540 NoMotion-1. Scaled. Acc [ -00044.92, -00007.81, 00988.28 ], Gyr [ 00002.01, 00001.10, -00000.18 ], Mag [ 00021.30, 00083.55, 00107.85 ]",
    "#0000008541 NoMotion-1. Scaled. Acc [ -00028.81, -00001.46, 00988.28 ], Gyr [ -00000.37, 00000.00, -00003.29 ], Mag [ 00021.30, 00083.55, 00107.85 ]",
    "#0000008542 NoMotion-1. Scaled. Acc [ -00047.85, -00004.39, 00982.91 ], Gyr [ 00001.10, 00001.34, -00001.71 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000008543 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00978.03 ], Gyr [ 00000.30, 00002.07, -00001.77 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000008544 NoMotion-1. Scaled. Acc [ -00061.04, -00008.79, 00996.09 ], Gyr [ 00000.18, 00001.65, -00003.54 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008545 NoMotion-1. Scaled. Acc [ -00047.85, -00001.46, 00993.16 ], Gyr [ 00000.18, 00001.28, -00001.83 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008546 NoMotion-1. Scaled. Acc [ -00036.62, -00017.58, 01004.39 ], Gyr [ -00000.12, 00002.07, -00000.98 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008547 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00981.93 ], Gyr [ -00000.55, 00003.17, -00002.07 ], Mag [ 00020.10, 00082.20, 00108.00 ]",
    "#0000008548 NoMotion-1. Scaled. Acc [ -00048.34, -00002.93, 00989.75 ], Gyr [ 00001.46, 00000.00, -00001.04 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000008549 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 00991.70 ], Gyr [ 00000.91, 00001.52, -00001.28 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000008550 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00995.61 ], Gyr [ 00001.59, 00002.20, -00001.95 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000008551 NoMotion-1. Scaled. Acc [ -00041.99, -00002.44, 00993.65 ], Gyr [ 00001.40, -00001.59, -00000.43 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008552 NoMotion-1. Scaled. Acc [ -00038.57, -00004.39, 00996.58 ], Gyr [ 00002.13, 00001.89, -00002.44 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000008553 NoMotion-1. Scaled. Acc [ -00048.34, -00001.95, 00995.12 ], Gyr [ -00000.55, 00001.52, 00000.30 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008554 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00989.26 ], Gyr [ 00002.07, 00002.07, -00000.73 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008555 NoMotion-1. Scaled. Acc [ -00042.97, 00002.93, 00979.98 ], Gyr [ 00000.43, 00001.59, -00001.65 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008556 NoMotion-1. Scaled. Acc [ -00051.27, -00008.79, 00985.84 ], Gyr [ 00000.37, 00002.13, 00000.06 ], Mag [ 00019.20, 00083.55, 00107.85 ]",
    "#0000008557 NoMotion-1. Scaled. Acc [ -00055.18, -00011.72, 00994.14 ], Gyr [ 00001.59, 00001.40, -00002.38 ], Mag [ 00020.40, 00082.20, 00107.40 ]",
    "#0000008558 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00987.79 ], Gyr [ -00000.61, 00001.28, -00001.65 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000008559 NoMotion-1. Scaled. Acc [ -00039.55, -00013.67, 00987.30 ], Gyr [ -00001.22, 00001.28, -00002.93 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000008560 NoMotion-1. Scaled. Acc [ -00053.22, 00002.44, 00987.30 ], Gyr [ -00000.49, 00001.46, -00002.50 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000008561 NoMotion-1. Scaled. Acc [ -00041.99, -00002.93, 00989.26 ], Gyr [ 00001.22, 00002.07, -00000.43 ], Mag [ 00019.50, 00082.80, 00108.90 ]",
    "#0000008562 NoMotion-1. Scaled. Acc [ -00049.80, -00008.79, 00990.23 ], Gyr [ 00000.06, 00001.65, -00004.57 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008563 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00981.93 ], Gyr [ 00000.91, -00000.79, -00003.11 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008564 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00978.52 ], Gyr [ 00001.65, -00001.52, -00000.49 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008565 NoMotion-1. Scaled. Acc [ -00033.69, -00011.23, 00994.63 ], Gyr [ 00001.52, 00000.98, -00002.32 ], Mag [ 00019.05, 00082.65, 00107.40 ]",
    "#0000008566 NoMotion-1. Scaled. Acc [ -00045.90, -00001.95, 00993.16 ], Gyr [ -00000.73, 00001.83, -00002.50 ], Mag [ 00017.70, 00082.80, 00107.10 ]",
    "#0000008567 NoMotion-1. Scaled. Acc [ -00042.48, 00007.81, 00991.21 ], Gyr [ 00001.10, 00002.01, -00000.55 ], Mag [ 00017.70, 00082.80, 00107.10 ]",
    "#0000008568 NoMotion-1. Scaled. Acc [ -00041.99, -00018.07, 00977.54 ], Gyr [ 00002.38, 00001.22, -00001.77 ], Mag [ 00017.70, 00082.80, 00107.10 ]",
    "#0000008569 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00992.19 ], Gyr [ -00001.10, 00001.52, -00002.74 ], Mag [ 00019.35, 00081.90, 00106.65 ]",
    "#0000008570 NoMotion-1. Scaled. Acc [ -00037.60, -00007.32, 00988.28 ], Gyr [ 00001.04, 00004.02, -00002.07 ], Mag [ 00019.35, 00081.90, 00106.65 ]",
    "#0000008571 NoMotion-1. Scaled. Acc [ -00049.80, -00024.90, 00991.70 ], Gyr [ -00000.79, 00000.91, -00000.85 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000008572 NoMotion-1. Scaled. Acc [ -00046.39, -00024.41, 00995.61 ], Gyr [ 00000.67, 00002.87, -00001.46 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000008573 NoMotion-1. Scaled. Acc [ -00043.95, -00009.77, 00992.68 ], Gyr [ -00000.79, -00000.30, -00000.49 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008574 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00985.35 ], Gyr [ 00001.95, 00002.93, -00002.32 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000008575 NoMotion-1. Scaled. Acc [ -00039.55, -00004.88, 00989.75 ], Gyr [ 00000.43, 00002.99, -00002.74 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008576 NoMotion-1. Scaled. Acc [ -00051.76, -00001.46, 00995.12 ], Gyr [ 00001.77, 00002.44, -00002.50 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008577 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00987.79 ], Gyr [ 00001.10, 00000.06, -00002.32 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008578 NoMotion-1. Scaled. Acc [ -00034.18, -00013.67, 00985.84 ], Gyr [ -00001.04, 00001.40, -00000.12 ], Mag [ 00019.95, 00082.80, 00107.85 ]",
    "#0000008579 NoMotion-1. Scaled. Acc [ -00046.88, -00014.65, 00988.28 ], Gyr [ -00000.73, 00002.80, -00000.98 ], Mag [ 00019.50, 00083.55, 00107.55 ]",
    "#0000008580 NoMotion-1. Scaled. Acc [ -00042.97, -00014.16, 00991.70 ], Gyr [ 00000.18, 00000.49, -00002.26 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008581 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00981.93 ], Gyr [ -00000.91, -00000.49, -00001.16 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008582 NoMotion-1. Scaled. Acc [ -00052.25, 00004.39, 00991.21 ], Gyr [ 00000.85, 00000.91, -00002.13 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008583 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00986.82 ], Gyr [ -00001.71, 00002.07, 00000.06 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000008584 NoMotion-1. Scaled. Acc [ -00045.41, -00009.28, 00992.19 ], Gyr [ 00000.73, 00002.32, 00000.18 ], Mag [ 00017.55, 00083.70, 00106.65 ]",
    "#0000008585 NoMotion-1. Scaled. Acc [ -00032.71, -00013.67, 00985.84 ], Gyr [ 00001.04, 00004.09, -00000.91 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000008586 NoMotion-1. Scaled. Acc [ -00042.97, -00015.14, 00989.75 ], Gyr [ 00000.49, 00000.43, -00001.89 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000008587 NoMotion-1. Scaled. Acc [ -00046.88, -00001.95, 00995.61 ], Gyr [ 00002.01, 00001.46, -00003.35 ], Mag [ 00020.70, 00085.80, 00107.55 ]",
    "#0000008588 NoMotion-1. Scaled. Acc [ -00041.50, -00023.44, 00986.33 ], Gyr [ 00001.65, 00000.43, -00000.55 ], Mag [ 00020.70, 00085.80, 00107.55 ]",
    "#0000008589 NoMotion-1. Scaled. Acc [ -00042.48, -00011.72, 00987.79 ], Gyr [ 00002.38, -00000.85, -00002.13 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008590 NoMotion-1. Scaled. Acc [ -00045.90, -00020.51, 00995.61 ], Gyr [ -00000.12, 00000.43, -00003.66 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008591 NoMotion-1. Scaled. Acc [ -00052.25, -00008.79, 00993.65 ], Gyr [ -00001.28, 00000.24, -00002.44 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008592 NoMotion-1. Scaled. Acc [ -00035.16, -00005.86, 00991.21 ], Gyr [ 00000.91, -00001.04, -00000.67 ], Mag [ 00020.40, 00085.95, 00107.70 ]",
    "#0000008593 NoMotion-1. Scaled. Acc [ -00038.09, -00017.09, 00986.82 ], Gyr [ 00000.18, 00003.05, -00002.87 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000008594 NoMotion-1. Scaled. Acc [ -00036.13, 00005.37, 00995.12 ], Gyr [ -00000.43, 00002.50, -00000.67 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000008595 NoMotion-1. Scaled. Acc [ -00044.92, -00002.44, 00977.54 ], Gyr [ 00001.04, 00000.37, -00002.99 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000008596 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00996.58 ], Gyr [ -00001.16, 00000.06, -00000.91 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000008597 NoMotion-1. Scaled. Acc [ -00055.18, -00014.16, 00991.21 ], Gyr [ 00001.40, -00000.24, -00001.10 ], Mag [ 00019.65, 00082.95, 00108.45 ]",
    "#0000008598 NoMotion-1. Scaled. Acc [ -00043.46, 00002.44, 00996.09 ], Gyr [ -00001.04, 00000.24, -00003.05 ], Mag [ 00019.65, 00082.95, 00108.45 ]",
    "#0000008599 NoMotion-1. Scaled. Acc [ -00047.36, -00018.55, 00983.40 ], Gyr [ -00000.30, 00000.24, 00004.45 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008600 NoMotion-1. Scaled. Acc [ -00048.83, -00012.21, 00987.30 ], Gyr [ -00000.43, -00001.52, -00002.74 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008601 NoMotion-1. Scaled. Acc [ -00046.88, -00011.23, 00987.79 ], Gyr [ -00001.10, 00003.35, -00001.89 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008602 NoMotion-1. Scaled. Acc [ -00035.16, -00017.09, 00985.35 ], Gyr [ 00000.37, 00002.01, -00001.89 ], Mag [ 00018.30, 00085.20, 00107.70 ]",
    "#0000008603 NoMotion-1. Scaled. Acc [ -00033.20, -00006.35, 00989.26 ], Gyr [ 00002.80, 00000.30, -00002.13 ], Mag [ 00020.25, 00082.05, 00109.35 ]",
    "#0000008604 NoMotion-1. Scaled. Acc [ -00044.92, -00008.79, 00982.42 ], Gyr [ 00001.65, -00001.10, -00000.55 ], Mag [ 00020.70, 00084.75, 00108.60 ]",
    "#0000008605 NoMotion-1. Scaled. Acc [ -00046.39, -00005.37, 00988.28 ], Gyr [ -00002.13, 00001.28, -00003.41 ], Mag [ 00020.70, 00084.75, 00108.60 ]",
    "#0000008606 NoMotion-1. Scaled. Acc [ -00040.04, -00019.04, 00989.75 ], Gyr [ -00000.49, 00002.50, -00001.22 ], Mag [ 00019.65, 00084.15, 00108.75 ]",
    "#0000008607 NoMotion-1. Scaled. Acc [ -00037.11, -00013.18, 00998.05 ], Gyr [ 00000.43, 00001.95, 00000.67 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008608 NoMotion-1. Scaled. Acc [ -00055.18, -00011.23, 00988.77 ], Gyr [ 00000.85, 00000.30, -00000.61 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008609 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 00981.45 ], Gyr [ 00001.83, 00002.93, -00000.67 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008610 NoMotion-1. Scaled. Acc [ -00052.73, -00013.18, 00983.89 ], Gyr [ 00001.34, 00001.46, -00002.26 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008611 NoMotion-1. Scaled. Acc [ -00043.95, -00006.35, 00985.84 ], Gyr [ 00000.79, -00001.04, -00001.52 ], Mag [ 00019.95, 00084.30, 00108.60 ]",
    "#0000008612 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00988.77 ], Gyr [ 00003.05, -00000.55, -00001.28 ], Mag [ 00019.05, 00082.65, 00108.00 ]",
    "#0000008613 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 00992.19 ], Gyr [ 00000.18, 00001.28, -00001.77 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008614 NoMotion-1. Scaled. Acc [ -00048.34, -00008.79, 00990.23 ], Gyr [ 00002.13, 00000.24, -00000.85 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000008615 NoMotion-1. Scaled. Acc [ -00041.50, -00006.84, 00987.30 ], Gyr [ 00000.55, 00000.43, 00000.49 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000008616 NoMotion-1. Scaled. Acc [ -00053.22, -00019.04, 00986.82 ], Gyr [ 00000.91, 00000.67, -00000.49 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000008617 NoMotion-1. Scaled. Acc [ -00049.80, 00004.39, 00985.35 ], Gyr [ -00000.06, 00001.10, -00001.52 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008618 NoMotion-1. Scaled. Acc [ -00032.23, -00001.46, 00979.98 ], Gyr [ -00000.85, 00001.22, -00001.65 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008619 NoMotion-1. Scaled. Acc [ -00045.41, -00008.30, 00987.30 ], Gyr [ -00000.61, 00001.04, -00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008620 NoMotion-1. Scaled. Acc [ -00038.57, -00009.77, 00993.16 ], Gyr [ 00000.67, 00001.77, -00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000008621 NoMotion-1. Scaled. Acc [ -00055.18, -00012.21, 00977.54 ], Gyr [ 00002.07, 00003.48, 00000.43 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000008622 NoMotion-1. Scaled. Acc [ -00036.62, -00010.74, 00995.12 ], Gyr [ 00000.12, 00001.40, -00003.35 ], Mag [ 00020.70, 00083.55, 00108.30 ]",
    "#0000008623 NoMotion-1. Scaled. Acc [ -00033.20, -00004.39, 00977.05 ], Gyr [ -00001.89, 00002.62, -00002.07 ], Mag [ 00019.50, 00084.00, 00108.90 ]",
    "#0000008624 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00984.86 ], Gyr [ 00001.10, -00000.12, -00001.28 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000008625 NoMotion-1. Scaled. Acc [ -00041.50, -00007.81, 00992.19 ], Gyr [ 00001.83, 00001.16, -00000.61 ], Mag [ 00020.10, 00082.95, 00108.00 ]",
    "#0000008626 NoMotion-1. Scaled. Acc [ -00049.80, -00005.86, 00987.79 ], Gyr [ 00001.10, 00000.18, -00000.24 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008627 NoMotion-1. Scaled. Acc [ -00036.62, -00001.95, 00993.65 ], Gyr [ -00001.16, 00002.56, 00000.30 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008628 NoMotion-1. Scaled. Acc [ -00041.99, -00014.65, 00992.68 ], Gyr [ 00001.22, 00000.18, 00000.73 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008629 NoMotion-1. Scaled. Acc [ -00060.55, -00017.09, 00987.30 ], Gyr [ 00001.04, -00000.24, -00001.89 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000008630 NoMotion-1. Scaled. Acc [ -00040.53, -00017.58, 00998.05 ], Gyr [ 00000.85, 00002.87, -00001.65 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000008631 NoMotion-1. Scaled. Acc [ -00050.78, -00002.44, 00985.35 ], Gyr [ -00002.01, 00003.66, -00000.24 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000008632 NoMotion-1. Scaled. Acc [ -00041.99, -00007.32, 00993.65 ], Gyr [ -00001.59, 00001.95, -00000.91 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000008633 NoMotion-1. Scaled. Acc [ -00048.34, -00023.44, 00975.59 ], Gyr [ -00001.04, 00002.20, -00001.71 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000008634 NoMotion-1. Scaled. Acc [ -00046.88, -00005.37, 00994.14 ], Gyr [ 00000.73, 00002.07, -00001.77 ], Mag [ 00018.15, 00083.55, 00107.10 ]",
    "#0000008635 NoMotion-1. Scaled. Acc [ -00047.36, -00013.18, 00989.26 ], Gyr [ 00000.00, 00000.61, -00001.40 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008636 NoMotion-1. Scaled. Acc [ -00048.34, -00003.91, 01000.00 ], Gyr [ 00001.46, -00001.10, -00000.85 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008637 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00990.72 ], Gyr [ -00000.43, -00001.65, -00000.85 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008638 NoMotion-1. Scaled. Acc [ -00052.25, -00019.04, 00987.79 ], Gyr [ -00001.40, 00003.72, -00001.89 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008639 NoMotion-1. Scaled. Acc [ -00043.95, -00005.86, 00981.45 ], Gyr [ -00000.37, -00001.89, -00001.71 ], Mag [ 00020.70, 00084.75, 00107.85 ]",
    "#0000008640 NoMotion-1. Scaled. Acc [ -00055.66, 00005.37, 00994.14 ], Gyr [ 00003.60, 00000.00, 00001.40 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000008641 NoMotion-1. Scaled. Acc [ -00038.09, -00014.16, 00994.14 ], Gyr [ -00000.61, 00000.30, -00004.88 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008642 NoMotion-1. Scaled. Acc [ -00040.53, -00015.62, 00983.89 ], Gyr [ -00000.30, 00001.46, -00000.85 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000008643 NoMotion-1. Scaled. Acc [ -00053.71, -00011.72, 00991.70 ], Gyr [ 00001.40, 00003.17, -00000.24 ], Mag [ 00019.65, 00082.95, 00107.40 ]",
    "#0000008644 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00990.72 ], Gyr [ -00000.18, 00001.28, 00000.91 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008645 NoMotion-1. Scaled. Acc [ -00033.69, 00008.30, 00990.23 ], Gyr [ -00000.91, 00000.91, -00003.41 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008646 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 01000.49 ], Gyr [ -00000.49, 00002.68, -00001.34 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008647 NoMotion-1. Scaled. Acc [ -00035.16, -00012.70, 00986.33 ], Gyr [ -00000.67, 00003.48, -00002.44 ], Mag [ 00019.35, 00083.70, 00109.50 ]",
    "#0000008648 NoMotion-1. Scaled. Acc [ -00044.43, -00008.30, 00990.23 ], Gyr [ 00000.49, 00002.50, -00001.77 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000008649 NoMotion-1. Scaled. Acc [ -00041.99, -00007.81, 00987.30 ], Gyr [ 00002.56, 00001.34, 00000.61 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000008650 NoMotion-1. Scaled. Acc [ -00036.13, -00001.46, 00992.68 ], Gyr [ -00000.55, 00001.04, -00003.78 ], Mag [ 00018.75, 00082.50, 00107.55 ]",
    "#0000008651 NoMotion-1. Scaled. Acc [ -00041.50, 00002.44, 00992.68 ], Gyr [ 00001.46, 00002.56, 00001.52 ], Mag [ 00018.75, 00082.50, 00107.55 ]",
    "#0000008652 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00988.28 ], Gyr [ -00000.55, 00000.73, 00000.18 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008653 NoMotion-1. Scaled. Acc [ -00051.27, -00001.46, 00983.89 ], Gyr [ 00000.79, 00003.84, -00001.71 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000008654 NoMotion-1. Scaled. Acc [ -00056.64, -00000.98, 00988.77 ], Gyr [ 00001.34, 00000.73, -00002.68 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008655 NoMotion-1. Scaled. Acc [ -00060.55, -00008.79, 00986.82 ], Gyr [ -00000.73, 00002.56, -00003.41 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008656 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00994.63 ], Gyr [ 00002.62, -00000.30, -00002.32 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008657 NoMotion-1. Scaled. Acc [ -00043.95, -00015.62, 00998.05 ], Gyr [ -00001.10, 00000.24, -00002.50 ], Mag [ 00019.20, 00083.25, 00108.90 ]",
    "#0000008658 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00981.93 ], Gyr [ 00000.06, 00002.62, 00000.24 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008659 NoMotion-1. Scaled. Acc [ -00043.46, -00005.37, 00994.14 ], Gyr [ 00000.00, 00003.96, -00001.77 ], Mag [ 00018.75, 00084.75, 00107.85 ]",
    "#0000008660 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00990.72 ], Gyr [ -00000.43, 00002.44, -00003.84 ], Mag [ 00020.10, 00082.65, 00106.95 ]",
    "#0000008661 NoMotion-1. Scaled. Acc [ -00052.73, -00010.25, 00987.79 ], Gyr [ 00002.38, 00002.13, 00000.73 ], Mag [ 00020.10, 00082.65, 00106.95 ]",
    "#0000008662 NoMotion-1. Scaled. Acc [ -00043.95, 00001.95, 00997.07 ], Gyr [ 00000.12, 00001.71, 00001.16 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000008663 NoMotion-1. Scaled. Acc [ -00051.27, -00008.79, 00991.21 ], Gyr [ 00001.65, 00002.99, -00000.55 ], Mag [ 00019.20, 00083.55, 00108.30 ]"
]

export const testDataToThreshold = [
    "#0000006374 NoMotion-1. Scaled. Acc [ -00050.29, -00005.37, 00999.02 ], Gyr [ 00000.12, -00002.44, -00002.50 ], Mag [ 00019.65, 00083.40, 00108.45 ]",
    "#0000006375 NoMotion-1. Scaled. Acc [ -00033.20, -00005.86, 00982.42 ], Gyr [ 00002.68, 00000.79, -00001.28 ], Mag [ 00019.65, 00083.40, 00108.45 ]",
    "#0000006376 NoMotion-1. Scaled. Acc [ -00037.60, 00000.49, 00976.07 ], Gyr [ -00000.24, 00000.73, -00000.18 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006377 NoMotion-1. Scaled. Acc [ -00046.88, -00004.39, 00997.07 ], Gyr [ -00000.43, 00001.40, -00002.32 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006378 NoMotion-1. Scaled. Acc [ -00047.36, -00003.91, 00987.79 ], Gyr [ 00000.49, 00001.59, 00000.12 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000006379 NoMotion-1. Scaled. Acc [ -00034.18, -00001.46, 00984.38 ], Gyr [ 00001.65, 00002.32, 00000.67 ], Mag [ 00019.20, 00082.50, 00106.80 ]",
    "#0000006380 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00988.77 ], Gyr [ -00000.06, 00003.78, -00002.07 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006381 NoMotion-1. Scaled. Acc [ -00039.06, 00004.39, 01001.95 ], Gyr [ 00000.49, 00001.16, -00002.13 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006382 NoMotion-1. Scaled. Acc [ -00052.73, -00009.28, 00992.68 ], Gyr [ 00000.37, 00000.98, -00001.10 ], Mag [ 00018.75, 00085.50, 00107.10 ]",
    "#0000006383 NoMotion-1. Scaled. Acc [ -00039.55, -00012.70, 00983.40 ], Gyr [ 00001.28, 00000.37, -00002.74 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006384 NoMotion-1. Scaled. Acc [ -00050.78, -00008.30, 00990.72 ], Gyr [ 00000.43, 00001.34, -00002.38 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006385 NoMotion-1. Scaled. Acc [ -00052.25, 00008.30, 01009.28 ], Gyr [ 00001.16, -00001.71, -00002.01 ], Mag [ 00019.95, 00084.30, 00108.90 ]",
    "#0000006386 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00998.54 ], Gyr [ 00000.30, -00000.30, -00000.37 ], Mag [ 00019.95, 00084.30, 00108.90 ]",
    "#0000006387 NoMotion-1. Scaled. Acc [ -00041.50, -00017.09, 00988.28 ], Gyr [ 00001.46, 00000.91, -00000.91 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000006388 NoMotion-1. Scaled. Acc [ -00038.09, -00003.42, 00997.07 ], Gyr [ -00000.79, 00001.71, 00000.61 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000006389 NoMotion-1. Scaled. Acc [ -00030.76, -00013.67, 00990.23 ], Gyr [ 00000.61, 00000.67, -00000.73 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006390 NoMotion-1. Scaled. Acc [ -00038.57, -00003.42, 00988.77 ], Gyr [ -00000.18, 00002.50, 00000.24 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006391 NoMotion-1. Scaled. Acc [ -00037.11, -00020.02, 00992.19 ], Gyr [ 00000.12, 00001.04, -00000.91 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006392 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 00979.00 ], Gyr [ -00000.37, 00004.02, -00000.37 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006393 NoMotion-1. Scaled. Acc [ -00036.62, -00012.21, 00997.56 ], Gyr [ -00001.95, 00001.77, -00000.91 ], Mag [ 00020.40, 00084.90, 00106.20 ]",
    "#0000006394 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00996.58 ], Gyr [ 00001.04, -00000.43, -00001.89 ], Mag [ 00020.40, 00084.90, 00106.20 ]",
    "#0000006395 NoMotion-1. Scaled. Acc [ -00037.60, -00019.04, 00991.21 ], Gyr [ -00000.55, 00001.52, -00003.84 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000006396 NoMotion-1. Scaled. Acc [ -00036.62, -00011.72, 00996.58 ], Gyr [ 00000.24, 00002.93, -00002.01 ], Mag [ 00018.45, 00082.80, 00107.85 ]",
    "#0000006397 NoMotion-1. Scaled. Acc [ -00039.06, 00000.00, 00993.65 ], Gyr [ 00001.95, 00000.55, -00002.44 ], Mag [ 00020.40, 00084.90, 00108.75 ]",
    "#0000006398 NoMotion-1. Scaled. Acc [ -00038.09, -00011.72, 00984.86 ], Gyr [ 00001.52, 00000.00, -00001.65 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006399 NoMotion-1. Scaled. Acc [ -00038.09, -00016.60, 00984.38 ], Gyr [ 00000.73, 00002.68, -00003.17 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006400 NoMotion-1. Scaled. Acc [ -00041.50, -00021.97, 00990.23 ], Gyr [ -00001.28, 00000.67, 00000.37 ], Mag [ 00021.15, 00084.45, 00106.65 ]",
    "#0000006401 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00981.93 ], Gyr [ 00000.18, 00004.39, -00000.98 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006402 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00995.61 ], Gyr [ 00001.04, 00001.59, -00001.89 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006403 NoMotion-1. Scaled. Acc [ -00039.55, 00000.49, 00991.21 ], Gyr [ 00001.04, -00000.85, 00000.79 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006404 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00989.26 ], Gyr [ -00000.67, 00001.16, -00002.07 ], Mag [ 00018.60, 00083.40, 00106.95 ]",
    "#0000006405 NoMotion-1. Scaled. Acc [ -00036.13, 00000.98, 00981.45 ], Gyr [ 00000.18, 00000.67, -00001.71 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006406 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00989.26 ], Gyr [ -00001.34, 00003.11, -00003.11 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006407 NoMotion-1. Scaled. Acc [ -00036.62, -00003.91, 00984.38 ], Gyr [ 00002.68, -00000.24, -00001.34 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006408 NoMotion-1. Scaled. Acc [ -00026.37, -00011.72, 00993.16 ], Gyr [ 00001.40, 00002.50, -00003.60 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006409 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00986.82 ], Gyr [ 00000.49, 00000.98, -00000.73 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006410 NoMotion-1. Scaled. Acc [ -00048.34, -00006.84, 01000.49 ], Gyr [ 00001.52, 00000.43, 00000.18 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006411 NoMotion-1. Scaled. Acc [ -00037.11, -00002.93, 00998.05 ], Gyr [ 00000.85, -00000.24, -00001.34 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006412 NoMotion-1. Scaled. Acc [ -00042.48, -00006.84, 00989.26 ], Gyr [ 00003.35, 00002.26, -00004.39 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006413 NoMotion-1. Scaled. Acc [ -00038.09, -00009.28, 00982.42 ], Gyr [ 00001.77, 00000.30, -00001.22 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006414 NoMotion-1. Scaled. Acc [ -00030.76, -00002.93, 00996.58 ], Gyr [ 00000.37, 00000.49, -00001.40 ], Mag [ 00021.60, 00084.15, 00108.00 ]",
    "#0000006415 NoMotion-1. Scaled. Acc [ -00038.09, -00024.90, 00986.82 ], Gyr [ 00002.01, 00000.00, -00000.79 ], Mag [ 00018.60, 00085.20, 00109.05 ]",
    "#0000006416 NoMotion-1. Scaled. Acc [ -00038.57, -00012.21, 01000.00 ], Gyr [ 00001.10, 00000.85, -00002.01 ], Mag [ 00018.60, 00085.20, 00109.05 ]",
    "#0000006417 NoMotion-1. Scaled. Acc [ -00036.13, -00014.16, 00985.35 ], Gyr [ 00001.71, 00003.78, -00002.13 ], Mag [ 00018.60, 00083.40, 00108.45 ]",
    "#0000006418 NoMotion-1. Scaled. Acc [ -00026.37, 00000.00, 00979.49 ], Gyr [ 00001.28, -00001.40, -00000.85 ], Mag [ 00018.60, 00083.40, 00108.45 ]",
    "#0000006419 NoMotion-1. Scaled. Acc [ -00039.06, -00011.23, 00992.68 ], Gyr [ 00001.10, 00000.49, -00002.07 ], Mag [ 00019.05, 00082.95, 00108.45 ]",
    "#0000006420 NoMotion-1. Scaled. Acc [ -00039.55, -00004.39, 00994.14 ], Gyr [ 00000.30, 00000.79, -00002.74 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006421 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00997.07 ], Gyr [ -00001.28, 00003.48, -00002.50 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006422 NoMotion-1. Scaled. Acc [ -00034.18, -00019.53, 00996.58 ], Gyr [ 00002.26, -00000.37, -00001.59 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000006423 NoMotion-1. Scaled. Acc [ -00027.83, 00000.49, 00979.98 ], Gyr [ 00002.26, -00000.55, -00000.67 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000006424 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00991.21 ], Gyr [ 00002.50, 00001.52, -00000.30 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000006425 NoMotion-1. Scaled. Acc [ -00055.18, -00014.16, 01007.32 ], Gyr [ 00001.71, 00001.04, -00001.16 ], Mag [ 00019.95, 00084.75, 00106.50 ]",
    "#0000006426 NoMotion-1. Scaled. Acc [ -00050.29, -00010.25, 00988.28 ], Gyr [ 00001.71, 00000.18, -00000.43 ], Mag [ 00019.95, 00084.75, 00106.50 ]",
    "#0000006427 NoMotion-1. Scaled. Acc [ -00043.95, -00005.37, 00988.28 ], Gyr [ 00002.07, 00000.67, 00000.18 ], Mag [ 00020.70, 00082.05, 00109.65 ]",
    "#0000006428 NoMotion-1. Scaled. Acc [ -00044.43, -00010.74, 00990.72 ], Gyr [ -00000.85, 00000.43, -00000.91 ], Mag [ 00020.70, 00082.05, 00109.65 ]",
    "#0000006429 NoMotion-1. Scaled. Acc [ -00045.41, -00011.72, 00992.68 ], Gyr [ 00001.28, 00003.72, -00003.48 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006430 NoMotion-1. Scaled. Acc [ -00046.88, -00010.25, 00992.19 ], Gyr [ -00000.91, 00002.74, -00001.83 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006431 NoMotion-1. Scaled. Acc [ -00049.32, -00001.46, 00992.68 ], Gyr [ 00001.52, 00002.80, -00001.46 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006432 NoMotion-1. Scaled. Acc [ -00053.71, -00005.86, 00984.86 ], Gyr [ 00001.83, 00002.38, -00002.74 ], Mag [ 00019.05, 00081.90, 00109.50 ]",
    "#0000006433 NoMotion-1. Scaled. Acc [ -00045.41, -00013.18, 00989.75 ], Gyr [ 00001.89, 00002.56, -00003.11 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006434 NoMotion-1. Scaled. Acc [ -00045.41, -00005.86, 00995.61 ], Gyr [ 00000.30, -00002.68, 00000.67 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006435 NoMotion-1. Scaled. Acc [ -00047.85, -00003.91, 00995.12 ], Gyr [ 00002.20, -00000.37, -00001.34 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000006436 NoMotion-1. Scaled. Acc [ -00048.83, -00014.16, 00979.49 ], Gyr [ 00000.98, 00001.10, -00002.26 ], Mag [ 00019.95, 00084.00, 00108.60 ]",
    "#0000006437 NoMotion-1. Scaled. Acc [ -00038.57, 00000.49, 01006.84 ], Gyr [ 00001.83, 00001.59, -00002.93 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000006438 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 00979.49 ], Gyr [ 00001.77, 00000.91, 00001.65 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000006439 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00988.77 ], Gyr [ 00000.18, 00000.79, 00001.28 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006440 NoMotion-1. Scaled. Acc [ -00048.83, -00009.28, 00987.30 ], Gyr [ 00001.46, -00000.55, 00000.24 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006441 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00994.63 ], Gyr [ -00000.67, 00001.10, -00002.62 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006442 NoMotion-1. Scaled. Acc [ -00038.57, -00012.21, 00998.05 ], Gyr [ -00001.28, 00001.04, -00002.74 ], Mag [ 00020.10, 00084.90, 00108.00 ]",
    "#0000006443 NoMotion-1. Scaled. Acc [ -00029.79, -00009.28, 00986.82 ], Gyr [ -00000.30, 00003.23, -00002.68 ], Mag [ 00020.10, 00084.90, 00108.00 ]",
    "#0000006444 NoMotion-1. Scaled. Acc [ -00043.46, -00010.25, 00986.82 ], Gyr [ 00001.83, -00002.07, -00001.95 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006445 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00992.68 ], Gyr [ 00000.61, 00001.16, -00001.59 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006446 NoMotion-1. Scaled. Acc [ -00048.83, -00002.93, 01004.39 ], Gyr [ 00002.62, -00000.85, 00001.34 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006447 NoMotion-1. Scaled. Acc [ -00044.92, -00011.72, 01003.42 ], Gyr [ 00000.91, 00000.00, -00002.93 ], Mag [ 00019.35, 00082.95, 00107.40 ]",
    "#0000006448 NoMotion-1. Scaled. Acc [ -00046.39, -00010.74, 00989.26 ], Gyr [ 00002.01, 00001.40, -00002.13 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000006449 NoMotion-1. Scaled. Acc [ -00041.99, 00001.46, 00988.77 ], Gyr [ 00000.91, -00000.37, -00001.83 ], Mag [ 00019.20, 00084.30, 00107.10 ]",
    "#0000006450 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00995.12 ], Gyr [ 00002.07, 00001.28, -00002.38 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000006451 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00989.26 ], Gyr [ 00000.55, 00002.62, -00001.71 ], Mag [ 00019.95, 00084.30, 00108.30 ]",
    "#0000006452 NoMotion-1. Scaled. Acc [ -00043.95, 00001.46, 00983.40 ], Gyr [ -00000.91, 00001.83, -00002.68 ], Mag [ 00020.85, 00084.45, 00108.75 ]",
    "#0000006453 NoMotion-1. Scaled. Acc [ -00043.46, -00004.39, 01000.98 ], Gyr [ 00002.50, 00001.46, -00002.44 ], Mag [ 00020.85, 00084.45, 00108.75 ]",
    "#0000006454 NoMotion-1. Scaled. Acc [ -00042.48, 00000.98, 00997.56 ], Gyr [ 00000.24, 00002.13, -00004.09 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006455 NoMotion-1. Scaled. Acc [ -00051.76, -00007.81, 00979.00 ], Gyr [ 00000.12, 00003.90, -00004.57 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006456 NoMotion-1. Scaled. Acc [ -00048.83, -00020.51, 00999.51 ], Gyr [ 00001.95, 00002.13, -00002.20 ], Mag [ 00018.30, 00084.45, 00108.00 ]",
    "#0000006457 NoMotion-1. Scaled. Acc [ -00039.06, -00007.81, 00987.30 ], Gyr [ 00002.44, -00001.46, -00002.74 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006458 NoMotion-1. Scaled. Acc [ -00034.18, -00015.62, 00999.02 ], Gyr [ 00000.24, 00001.04, -00003.23 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006459 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00983.89 ], Gyr [ -00001.16, 00003.84, 00000.49 ], Mag [ 00018.75, 00083.55, 00107.10 ]",
    "#0000006460 NoMotion-1. Scaled. Acc [ -00055.66, -00011.23, 00994.63 ], Gyr [ 00002.01, 00001.83, -00000.91 ], Mag [ 00018.75, 00083.55, 00107.10 ]",
    "#0000006461 NoMotion-1. Scaled. Acc [ -00047.36, -00001.95, 00996.09 ], Gyr [ 00002.26, -00001.65, -00000.18 ], Mag [ 00019.05, 00084.15, 00105.90 ]",
    "#0000006462 NoMotion-1. Scaled. Acc [ -00054.69, -00005.86, 00998.54 ], Gyr [ 00002.26, 00001.77, 00000.24 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006463 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 00992.19 ], Gyr [ 00001.22, 00002.13, -00001.04 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006464 NoMotion-1. Scaled. Acc [ -00041.50, -00013.18, 00988.77 ], Gyr [ 00000.06, 00002.74, -00003.72 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006465 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 01007.32 ], Gyr [ -00000.73, -00000.37, -00002.01 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006466 NoMotion-1. Scaled. Acc [ -00041.50, -00004.88, 00989.75 ], Gyr [ 00000.61, 00003.23, -00002.80 ], Mag [ 00020.25, 00084.75, 00107.55 ]",
    "#0000006467 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00989.26 ], Gyr [ -00000.18, 00001.34, -00000.85 ], Mag [ 00021.15, 00084.45, 00106.20 ]",
    "#0000006468 NoMotion-1. Scaled. Acc [ -00041.99, -00009.28, 00999.51 ], Gyr [ 00001.46, 00002.38, -00000.49 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006469 NoMotion-1. Scaled. Acc [ -00033.69, -00006.35, 00988.77 ], Gyr [ 00002.01, 00000.49, -00001.16 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006470 NoMotion-1. Scaled. Acc [ -00040.53, -00004.39, 01000.00 ], Gyr [ 00000.73, 00002.38, -00002.44 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006471 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 00994.63 ], Gyr [ 00000.79, 00000.85, -00003.72 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006472 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00988.77 ], Gyr [ 00000.73, 00000.49, 00000.61 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006473 NoMotion-1. Scaled. Acc [ -00039.06, -00007.81, 00980.47 ], Gyr [ 00001.34, 00003.96, -00002.20 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006474 NoMotion-1. Scaled. Acc [ -00043.95, 00004.39, 00998.54 ], Gyr [ -00000.37, 00001.59, -00000.30 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006475 NoMotion-1. Scaled. Acc [ -00038.57, -00010.74, 00994.14 ], Gyr [ -00001.10, -00000.30, -00003.05 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000006476 NoMotion-1. Scaled. Acc [ -00057.13, -00013.18, 01004.88 ], Gyr [ -00000.30, -00000.12, -00002.62 ], Mag [ 00019.20, 00085.05, 00106.80 ]",
    "#0000006477 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00998.54 ], Gyr [ -00001.04, 00002.07, -00001.10 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000006478 NoMotion-1. Scaled. Acc [ -00053.22, -00009.28, 00992.19 ], Gyr [ -00001.71, 00000.67, -00003.90 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000006479 NoMotion-1. Scaled. Acc [ -00040.53, 00003.42, 00995.61 ], Gyr [ 00000.91, 00003.48, -00001.77 ], Mag [ 00018.45, 00084.75, 00107.85 ]",
    "#0000006480 NoMotion-1. Scaled. Acc [ -00035.64, -00003.42, 00998.05 ], Gyr [ -00001.59, 00001.59, -00002.07 ], Mag [ 00018.45, 00084.75, 00107.85 ]",
    "#0000006481 NoMotion-1. Scaled. Acc [ -00047.85, -00023.93, 00991.21 ], Gyr [ 00000.55, 00002.26, -00000.73 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006482 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00996.09 ], Gyr [ -00000.55, 00001.83, -00000.18 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006483 NoMotion-1. Scaled. Acc [ -00048.34, -00001.46, 00984.38 ], Gyr [ -00001.22, 00003.35, -00002.68 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006484 NoMotion-1. Scaled. Acc [ -00039.55, -00015.62, 00994.63 ], Gyr [ 00001.10, -00000.37, -00001.77 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000006485 NoMotion-1. Scaled. Acc [ -00041.50, -00018.55, 00989.26 ], Gyr [ -00000.24, 00001.77, 00000.24 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000006486 NoMotion-1. Scaled. Acc [ -00059.57, -00010.25, 00998.54 ], Gyr [ 00000.79, 00000.06, -00001.16 ], Mag [ 00021.00, 00082.80, 00107.55 ]",
    "#0000006487 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00982.42 ], Gyr [ 00000.79, 00000.73, -00002.68 ], Mag [ 00019.35, 00084.45, 00108.75 ]",
    "#0000006488 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00995.12 ], Gyr [ 00001.89, -00000.49, -00002.26 ], Mag [ 00019.35, 00084.45, 00108.75 ]",
    "#0000006489 NoMotion-1. Scaled. Acc [ -00047.85, -00015.14, 00988.77 ], Gyr [ 00001.83, 00000.49, -00000.67 ], Mag [ 00020.40, 00083.70, 00106.20 ]",
    "#0000006490 NoMotion-1. Scaled. Acc [ -00047.85, 00000.49, 00994.63 ], Gyr [ 00000.37, 00002.38, -00002.87 ], Mag [ 00020.40, 00083.70, 00106.20 ]",
    "#0000006491 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00991.21 ], Gyr [ 00000.79, 00002.07, -00002.01 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006492 NoMotion-1. Scaled. Acc [ -00029.30, 00005.37, 00989.75 ], Gyr [ -00000.49, 00000.85, -00000.24 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006493 NoMotion-1. Scaled. Acc [ -00050.78, -00000.98, 00996.09 ], Gyr [ 00001.89, 00000.98, -00003.05 ], Mag [ 00018.75, 00084.30, 00105.75 ]",
    "#0000006494 NoMotion-1. Scaled. Acc [ -00043.46, -00017.09, 01001.46 ], Gyr [ 00001.65, 00001.40, 00000.24 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006495 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00991.70 ], Gyr [ -00001.40, 00001.83, 00000.67 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000006496 NoMotion-1. Scaled. Acc [ -00047.36, -00007.32, 00979.98 ], Gyr [ 00002.80, 00004.15, 00000.43 ], Mag [ 00020.25, 00083.25, 00108.30 ]",
    "#0000006497 NoMotion-1. Scaled. Acc [ -00043.46, -00007.32, 00992.68 ], Gyr [ 00000.49, 00000.91, -00002.32 ], Mag [ 00021.60, 00084.45, 00108.00 ]",
    "#0000006498 NoMotion-1. Scaled. Acc [ -00048.34, 00000.49, 00986.33 ], Gyr [ 00001.95, 00001.71, -00001.65 ], Mag [ 00021.60, 00084.45, 00108.00 ]",
    "#0000006499 NoMotion-1. Scaled. Acc [ -00037.11, -00005.37, 00993.65 ], Gyr [ 00000.55, 00001.95, 00001.52 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006500 NoMotion-1. Scaled. Acc [ -00032.23, -00016.11, 00990.72 ], Gyr [ 00001.71, 00000.98, -00001.40 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006501 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00991.70 ], Gyr [ 00001.28, -00000.79, -00000.79 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006502 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00989.75 ], Gyr [ -00001.52, 00003.35, -00002.87 ], Mag [ 00020.40, 00084.45, 00106.65 ]",
    "#0000006503 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00990.72 ], Gyr [ -00000.79, 00003.29, -00002.20 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000006504 NoMotion-1. Scaled. Acc [ -00047.36, -00010.25, 00993.65 ], Gyr [ -00000.12, 00001.04, -00002.99 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000006505 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00985.84 ], Gyr [ 00000.37, 00001.83, -00001.59 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000006506 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00996.58 ], Gyr [ 00001.59, 00002.93, -00002.50 ], Mag [ 00019.95, 00084.30, 00107.10 ]",
    "#0000006507 NoMotion-1. Scaled. Acc [ -00037.60, -00005.37, 00981.93 ], Gyr [ 00000.24, 00001.34, -00001.65 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000006508 NoMotion-1. Scaled. Acc [ -00049.32, -00002.93, 00996.09 ], Gyr [ -00000.73, 00000.73, -00003.11 ], Mag [ 00018.75, 00083.55, 00106.80 ]",
    "#0000006509 NoMotion-1. Scaled. Acc [ -00040.53, -00000.98, 00989.75 ], Gyr [ 00000.55, 00001.77, -00000.43 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006510 NoMotion-1. Scaled. Acc [ -00033.20, -00012.21, 01006.84 ], Gyr [ -00000.55, -00002.20, -00001.95 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006511 NoMotion-1. Scaled. Acc [ -00031.25, -00001.95, 00984.86 ], Gyr [ -00001.83, 00001.71, 00000.24 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006512 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00990.23 ], Gyr [ -00001.22, 00000.98, -00001.83 ], Mag [ 00018.30, 00083.40, 00106.95 ]",
    "#0000006513 NoMotion-1. Scaled. Acc [ -00035.64, -00001.46, 00989.75 ], Gyr [ 00000.00, 00002.38, -00000.79 ], Mag [ 00019.35, 00084.15, 00106.65 ]",
    "#0000006514 NoMotion-1. Scaled. Acc [ -00054.20, -00008.30, 00991.70 ], Gyr [ 00002.80, 00003.66, -00000.85 ], Mag [ 00019.95, 00084.30, 00107.55 ]",
    "#0000006515 NoMotion-1. Scaled. Acc [ -00031.74, -00007.32, 00984.86 ], Gyr [ 00000.67, 00000.18, -00001.22 ], Mag [ 00019.95, 00084.30, 00107.55 ]",
    "#0000006516 NoMotion-1. Scaled. Acc [ -00045.41, 00002.93, 00986.82 ], Gyr [ 00001.22, 00000.91, -00001.10 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000006517 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 00997.56 ], Gyr [ -00000.06, -00000.12, 00000.67 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006518 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00989.26 ], Gyr [ 00001.71, 00001.71, 00000.00 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006519 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00998.54 ], Gyr [ -00001.16, 00001.95, -00003.41 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006520 NoMotion-1. Scaled. Acc [ -00041.99, 00001.95, 00990.23 ], Gyr [ -00000.73, 00000.06, -00001.28 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006521 NoMotion-1. Scaled. Acc [ -00034.67, -00008.30, 00975.59 ], Gyr [ 00001.89, 00000.49, 00000.85 ], Mag [ 00020.25, 00085.50, 00106.80 ]",
    "#0000006522 NoMotion-1. Scaled. Acc [ -00044.43, -00008.79, 00985.35 ], Gyr [ 00000.24, 00001.22, -00003.05 ], Mag [ 00019.50, 00082.05, 00107.10 ]",
    "#0000006523 NoMotion-1. Scaled. Acc [ -00044.92, -00007.32, 00994.63 ], Gyr [ 00000.00, 00003.29, -00004.27 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006524 NoMotion-1. Scaled. Acc [ -00031.74, -00006.84, 00993.65 ], Gyr [ 00002.26, 00001.59, -00000.18 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006525 NoMotion-1. Scaled. Acc [ -00043.46, -00016.11, 00982.91 ], Gyr [ 00000.98, -00000.43, -00002.20 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000006526 NoMotion-1. Scaled. Acc [ -00052.25, -00007.81, 00997.07 ], Gyr [ -00000.55, -00000.55, -00001.22 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000006527 NoMotion-1. Scaled. Acc [ -00056.64, 00000.49, 00995.61 ], Gyr [ 00000.67, 00002.01, 00000.79 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006528 NoMotion-1. Scaled. Acc [ -00043.46, -00016.11, 00993.65 ], Gyr [ 00000.18, 00002.80, -00002.32 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006529 NoMotion-1. Scaled. Acc [ -00047.36, -00006.84, 00994.63 ], Gyr [ -00000.55, 00000.67, -00000.49 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006530 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00987.30 ], Gyr [ 00000.67, 00000.43, -00000.98 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006531 NoMotion-1. Scaled. Acc [ -00030.76, -00003.42, 00993.65 ], Gyr [ -00000.06, 00002.26, -00000.85 ], Mag [ 00019.95, 00083.25, 00105.75 ]",
    "#0000006532 NoMotion-1. Scaled. Acc [ -00046.88, 00002.93, 00980.47 ], Gyr [ 00001.04, -00000.37, -00002.38 ], Mag [ 00019.95, 00083.25, 00105.75 ]",
    "#0000006533 NoMotion-1. Scaled. Acc [ -00048.83, -00006.84, 00988.77 ], Gyr [ 00001.10, 00001.65, -00001.28 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006534 NoMotion-1. Scaled. Acc [ -00042.97, -00010.25, 00991.70 ], Gyr [ -00000.49, 00000.73, -00002.13 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000006535 NoMotion-1. Scaled. Acc [ -00065.92, 00005.37, 00985.84 ], Gyr [ 00001.83, 00001.65, -00000.06 ], Mag [ 00020.40, 00083.70, 00108.75 ]",
    "#0000006536 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 00995.12 ], Gyr [ 00002.80, 00000.79, -00000.61 ], Mag [ 00020.40, 00083.70, 00108.75 ]",
    "#0000006537 NoMotion-1. Scaled. Acc [ -00044.43, 00000.98, 00991.21 ], Gyr [ 00000.67, 00000.73, 00000.67 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006538 NoMotion-1. Scaled. Acc [ -00048.34, 00005.86, 00990.72 ], Gyr [ 00001.34, 00002.56, 00000.91 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006539 NoMotion-1. Scaled. Acc [ -00044.92, -00005.86, 01000.98 ], Gyr [ 00000.98, 00002.56, -00002.26 ], Mag [ 00019.20, 00082.50, 00107.85 ]",
    "#0000006540 NoMotion-1. Scaled. Acc [ -00044.92, -00018.07, 00987.79 ], Gyr [ 00000.43, 00003.17, -00002.80 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000006541 NoMotion-1. Scaled. Acc [ -00044.92, -00005.37, 00990.23 ], Gyr [ 00001.40, -00001.04, -00001.34 ], Mag [ 00019.35, 00084.15, 00107.40 ]",
    "#0000006542 NoMotion-1. Scaled. Acc [ -00043.95, -00000.98, 00996.09 ], Gyr [ 00000.37, 00000.06, -00002.93 ], Mag [ 00019.05, 00083.40, 00106.65 ]",
    "#0000006543 NoMotion-1. Scaled. Acc [ -00048.34, -00002.44, 00992.68 ], Gyr [ 00001.16, -00000.18, -00000.12 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006544 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00984.86 ], Gyr [ 00001.46, 00000.98, -00000.67 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000006545 NoMotion-1. Scaled. Acc [ -00042.48, -00007.32, 00997.07 ], Gyr [ 00000.30, 00001.04, -00002.07 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006546 NoMotion-1. Scaled. Acc [ -00052.25, -00020.02, 00988.77 ], Gyr [ 00000.37, 00003.29, -00002.38 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006547 NoMotion-1. Scaled. Acc [ -00039.06, -00013.67, 00989.26 ], Gyr [ 00000.67, 00003.05, -00001.89 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006548 NoMotion-1. Scaled. Acc [ -00052.25, 00008.30, 00984.86 ], Gyr [ 00001.10, 00002.01, -00001.71 ], Mag [ 00019.35, 00083.70, 00107.40 ]",
    "#0000006549 NoMotion-1. Scaled. Acc [ -00042.97, -00000.98, 00988.28 ], Gyr [ 00001.40, 00003.60, -00002.62 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006550 NoMotion-1. Scaled. Acc [ -00057.62, -00003.91, 00987.79 ], Gyr [ 00001.59, -00000.55, -00002.68 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006551 NoMotion-1. Scaled. Acc [ -00042.48, -00015.14, 00985.35 ], Gyr [ 00000.49, 00000.73, -00003.48 ], Mag [ 00021.15, 00083.70, 00106.65 ]",
    "#0000006552 NoMotion-1. Scaled. Acc [ -00038.57, -00009.28, 00991.21 ], Gyr [ 00000.55, 00002.07, -00001.71 ], Mag [ 00019.35, 00084.45, 00107.40 ]",
    "#0000006553 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00983.89 ], Gyr [ 00000.49, 00002.44, 00000.30 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006554 NoMotion-1. Scaled. Acc [ -00061.52, -00019.04, 01000.00 ], Gyr [ -00000.91, 00001.65, -00004.45 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006555 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00987.79 ], Gyr [ 00000.37, 00003.84, 00001.59 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006556 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 01001.46 ], Gyr [ 00002.26, 00003.54, -00001.89 ], Mag [ 00020.25, 00084.30, 00108.60 ]",
    "#0000006557 NoMotion-1. Scaled. Acc [ -00034.67, -00005.86, 00990.23 ], Gyr [ 00000.61, -00001.59, -00001.95 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000006558 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00990.23 ], Gyr [ 00000.55, 00001.28, -00002.74 ], Mag [ 00020.10, 00084.15, 00108.45 ]",
    "#0000006559 NoMotion-1. Scaled. Acc [ -00040.04, -00012.70, 00995.12 ], Gyr [ 00001.52, 00001.89, 00000.79 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006560 NoMotion-1. Scaled. Acc [ -00048.83, -00010.25, 00987.30 ], Gyr [ 00001.16, 00002.80, 00000.12 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000006561 NoMotion-1. Scaled. Acc [ -00052.73, -00007.32, 00991.70 ], Gyr [ 00001.46, -00001.16, -00004.39 ], Mag [ 00019.50, 00084.75, 00108.90 ]",
    "#0000006562 NoMotion-1. Scaled. Acc [ -00034.18, -00010.25, 00993.65 ], Gyr [ -00000.49, 00000.55, 00000.00 ], Mag [ 00019.50, 00084.75, 00108.90 ]",
    "#0000006563 NoMotion-1. Scaled. Acc [ -00049.32, -00020.02, 00993.16 ], Gyr [ -00000.49, 00001.34, -00002.56 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006564 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00991.21 ], Gyr [ 00001.52, -00000.12, 00000.79 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006565 NoMotion-1. Scaled. Acc [ -00043.46, -00003.91, 00991.21 ], Gyr [ 00000.43, 00002.13, -00002.01 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006566 NoMotion-1. Scaled. Acc [ -00039.06, -00010.74, 00986.82 ], Gyr [ 00000.00, 00004.09, -00000.98 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000006567 NoMotion-1. Scaled. Acc [ -00051.27, -00002.44, 00992.19 ], Gyr [ 00001.28, -00000.37, -00001.46 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000006568 NoMotion-1. Scaled. Acc [ -00039.06, -00003.42, 00977.54 ], Gyr [ 00000.43, 00000.61, -00003.54 ], Mag [ 00018.60, 00082.65, 00109.50 ]",
    "#0000006569 NoMotion-1. Scaled. Acc [ -00032.23, -00009.77, 00982.91 ], Gyr [ -00000.18, 00000.73, -00002.68 ], Mag [ 00018.60, 00082.65, 00109.50 ]",
    "#0000006570 NoMotion-1. Scaled. Acc [ -00042.97, -00021.00, 01004.39 ], Gyr [ -00000.24, 00001.04, -00002.01 ], Mag [ 00019.05, 00084.90, 00108.00 ]",
    "#0000006571 NoMotion-1. Scaled. Acc [ -00042.97, -00003.42, 01001.95 ], Gyr [ 00000.37, -00000.61, -00000.49 ], Mag [ 00019.05, 00084.90, 00108.00 ]",
    "#0000006572 NoMotion-1. Scaled. Acc [ -00035.64, 00009.28, 00991.21 ], Gyr [ 00001.10, 00003.84, -00000.24 ], Mag [ 00020.85, 00081.90, 00107.40 ]",
    "#0000006573 NoMotion-1. Scaled. Acc [ -00043.46, -00017.58, 00993.16 ], Gyr [ 00000.49, 00002.07, -00000.98 ], Mag [ 00020.85, 00081.90, 00107.40 ]",
    "#0000006574 NoMotion-1. Scaled. Acc [ -00047.85, 00000.00, 00988.77 ], Gyr [ 00000.67, 00002.13, -00003.23 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000006575 NoMotion-1. Scaled. Acc [ -00039.06, -00000.98, 00980.47 ], Gyr [ 00000.06, 00003.05, -00002.74 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000006576 NoMotion-1. Scaled. Acc [ -00051.27, -00006.84, 00982.91 ], Gyr [ 00000.37, -00000.79, -00000.12 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000006577 NoMotion-1. Scaled. Acc [ -00039.55, 00003.42, 00992.68 ], Gyr [ 00000.67, 00004.70, -00001.40 ], Mag [ 00019.20, 00083.55, 00107.55 ]",
    "#0000006578 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00992.68 ], Gyr [ -00001.95, 00001.83, -00002.44 ], Mag [ 00019.05, 00084.90, 00106.95 ]",
    "#0000006579 NoMotion-1. Scaled. Acc [ -00039.06, -00009.77, 00998.54 ], Gyr [ 00000.37, 00003.05, -00002.87 ], Mag [ 00019.05, 00084.90, 00106.95 ]",
    "#0000006580 NoMotion-1. Scaled. Acc [ -00041.99, 00002.44, 00972.66 ], Gyr [ 00000.79, 00000.79, -00003.29 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006581 NoMotion-1. Scaled. Acc [ -00051.76, -00009.28, 00988.28 ], Gyr [ 00000.30, 00001.22, 00001.16 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006582 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00984.86 ], Gyr [ 00002.26, 00003.29, 00000.43 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006583 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00976.56 ], Gyr [ 00000.73, 00003.66, 00000.30 ], Mag [ 00019.50, 00083.55, 00107.85 ]",
    "#0000006584 NoMotion-1. Scaled. Acc [ -00046.39, -00004.39, 00990.72 ], Gyr [ 00001.52, 00001.71, -00000.91 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006585 NoMotion-1. Scaled. Acc [ -00041.02, -00002.44, 00993.65 ], Gyr [ 00000.30, 00002.93, -00001.10 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006586 NoMotion-1. Scaled. Acc [ -00030.27, 00005.37, 00993.16 ], Gyr [ 00001.10, 00002.50, -00002.44 ], Mag [ 00019.65, 00083.40, 00106.20 ]",
    "#0000006587 NoMotion-1. Scaled. Acc [ -00050.29, 00001.95, 00985.84 ], Gyr [ 00001.10, 00001.89, -00003.66 ], Mag [ 00019.65, 00083.40, 00106.20 ]",
    "#0000006588 NoMotion-1. Scaled. Acc [ -00042.48, 00011.23, 00996.09 ], Gyr [ -00000.43, 00001.52, -00003.23 ], Mag [ 00019.65, 00084.15, 00108.00 ]",
    "#0000006589 NoMotion-1. Scaled. Acc [ -00034.18, -00002.93, 00982.91 ], Gyr [ 00000.00, 00000.98, -00000.79 ], Mag [ 00019.65, 00084.15, 00108.00 ]",
    "#0000006590 NoMotion-1. Scaled. Acc [ -00054.20, -00007.81, 00991.70 ], Gyr [ 00000.91, 00004.76, -00001.16 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006591 NoMotion-1. Scaled. Acc [ -00046.39, -00001.95, 00991.21 ], Gyr [ 00000.12, 00002.32, -00001.65 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006592 NoMotion-1. Scaled. Acc [ -00043.95, -00008.79, 00990.72 ], Gyr [ -00000.30, 00001.65, -00003.66 ], Mag [ 00020.25, 00082.50, 00106.80 ]",
    "#0000006593 NoMotion-1. Scaled. Acc [ -00035.16, 00002.44, 00983.89 ], Gyr [ 00000.85, 00002.99, -00001.28 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000006594 NoMotion-1. Scaled. Acc [ -00047.36, -00007.81, 00993.16 ], Gyr [ -00000.06, -00000.73, -00000.18 ], Mag [ 00019.05, 00084.45, 00106.95 ]",
    "#0000006595 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00996.09 ], Gyr [ 00001.83, 00001.22, -00002.13 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006596 NoMotion-1. Scaled. Acc [ -00044.43, -00011.72, 00997.56 ], Gyr [ -00000.55, 00003.90, -00002.26 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006597 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00983.89 ], Gyr [ 00000.67, 00000.06, 00001.10 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006598 NoMotion-1. Scaled. Acc [ -00036.13, -00013.67, 00989.75 ], Gyr [ -00000.61, 00001.89, 00000.12 ], Mag [ 00019.35, 00083.70, 00106.95 ]",
    "#0000006599 NoMotion-1. Scaled. Acc [ -00035.64, -00015.14, 00994.14 ], Gyr [ 00001.40, 00001.22, -00002.68 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006600 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00990.72 ], Gyr [ -00001.22, 00002.68, -00000.06 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006601 NoMotion-1. Scaled. Acc [ -00055.66, -00005.37, 00982.42 ], Gyr [ 00003.05, 00000.00, -00002.44 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006602 NoMotion-1. Scaled. Acc [ -00042.97, -00002.44, 00993.65 ], Gyr [ 00001.52, 00002.13, -00000.91 ], Mag [ 00019.35, 00085.20, 00108.45 ]",
    "#0000006603 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 00994.63 ], Gyr [ 00000.06, 00002.80, -00000.43 ], Mag [ 00019.20, 00085.05, 00107.55 ]",
    "#0000006604 NoMotion-1. Scaled. Acc [ -00042.48, -00003.42, 00986.82 ], Gyr [ -00002.68, 00003.72, -00002.38 ], Mag [ 00018.60, 00083.40, 00108.75 ]",
    "#0000006605 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00992.68 ], Gyr [ 00000.67, 00001.89, -00003.84 ], Mag [ 00018.60, 00083.40, 00108.75 ]",
    "#0000006606 NoMotion-1. Scaled. Acc [ -00052.73, -00014.65, 00984.38 ], Gyr [ -00000.55, 00001.52, -00000.30 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000006607 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00994.63 ], Gyr [ 00002.50, 00001.52, 00000.73 ], Mag [ 00018.75, 00084.30, 00108.60 ]",
    "#0000006608 NoMotion-1. Scaled. Acc [ -00035.64, -00004.88, 00996.09 ], Gyr [ 00000.85, 00003.05, -00001.52 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006609 NoMotion-1. Scaled. Acc [ -00035.16, -00003.91, 01008.30 ], Gyr [ 00001.40, -00001.52, -00002.50 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006610 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00990.72 ], Gyr [ 00001.83, 00001.46, -00000.24 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006611 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 00993.65 ], Gyr [ 00000.85, -00000.67, 00000.85 ], Mag [ 00020.40, 00083.40, 00106.20 ]",
    "#0000006612 NoMotion-1. Scaled. Acc [ -00059.08, -00002.44, 00986.82 ], Gyr [ -00000.55, 00002.26, -00005.85 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006613 NoMotion-1. Scaled. Acc [ -00034.18, -00002.44, 00994.63 ], Gyr [ -00000.61, 00003.17, 00000.49 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006614 NoMotion-1. Scaled. Acc [ -00045.41, -00005.37, 00986.33 ], Gyr [ -00000.49, -00001.89, 00000.06 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006615 NoMotion-1. Scaled. Acc [ -00046.39, -00002.93, 00989.75 ], Gyr [ -00000.85, 00001.95, -00003.17 ], Mag [ 00019.20, 00084.30, 00107.55 ]",
    "#0000006616 NoMotion-1. Scaled. Acc [ -00039.55, -00006.35, 00986.33 ], Gyr [ -00000.24, 00002.50, -00000.79 ], Mag [ 00017.55, 00081.45, 00107.70 ]",
    "#0000006617 NoMotion-1. Scaled. Acc [ -00043.46, -00017.58, 00995.12 ], Gyr [ -00000.73, 00001.04, -00004.21 ], Mag [ 00017.55, 00081.45, 00107.70 ]",
    "#0000006618 NoMotion-1. Scaled. Acc [ -00036.62, -00005.37, 00991.21 ], Gyr [ 00000.98, 00003.66, -00000.37 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006619 NoMotion-1. Scaled. Acc [ -00040.53, -00011.72, 00992.68 ], Gyr [ -00000.06, -00001.16, -00000.73 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006620 NoMotion-1. Scaled. Acc [ -00036.62, -00013.67, 00994.14 ], Gyr [ 00000.06, -00000.49, -00000.91 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000006621 NoMotion-1. Scaled. Acc [ -00036.62, -00014.65, 00995.61 ], Gyr [ 00002.44, 00003.23, -00000.55 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006622 NoMotion-1. Scaled. Acc [ -00044.43, -00001.95, 00997.56 ], Gyr [ 00000.67, -00000.18, -00001.71 ], Mag [ 00019.50, 00084.30, 00107.10 ]",
    "#0000006623 NoMotion-1. Scaled. Acc [ -00046.39, 00001.95, 00984.86 ], Gyr [ 00001.40, 00001.59, -00001.77 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000006624 NoMotion-1. Scaled. Acc [ -00036.62, -00015.62, 00976.56 ], Gyr [ 00000.67, 00001.22, -00001.04 ], Mag [ 00019.20, 00083.55, 00106.05 ]",
    "#0000006625 NoMotion-1. Scaled. Acc [ -00044.92, -00017.58, 01005.86 ], Gyr [ 00001.10, 00001.46, 00000.43 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006626 NoMotion-1. Scaled. Acc [ -00046.88, -00016.60, 00991.70 ], Gyr [ 00002.01, 00000.43, -00003.78 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006627 NoMotion-1. Scaled. Acc [ -00040.04, -00011.23, 00995.12 ], Gyr [ -00002.07, 00001.10, -00002.74 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006628 NoMotion-1. Scaled. Acc [ -00033.20, -00003.91, 00998.54 ], Gyr [ 00000.55, 00000.49, 00001.34 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006629 NoMotion-1. Scaled. Acc [ -00041.02, -00001.95, 00996.09 ], Gyr [ 00000.37, 00000.67, -00002.62 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000006630 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00993.16 ], Gyr [ 00000.06, 00003.11, -00003.78 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000006631 NoMotion-1. Scaled. Acc [ -00037.60, -00002.44, 00990.23 ], Gyr [ -00000.91, 00003.66, -00001.46 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000006632 NoMotion-1. Scaled. Acc [ -00042.48, -00004.88, 00994.63 ], Gyr [ 00001.04, 00001.22, 00000.61 ], Mag [ 00019.65, 00084.15, 00106.95 ]",
    "#0000006633 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00992.19 ], Gyr [ 00001.83, 00001.04, -00002.32 ], Mag [ 00019.35, 00084.90, 00108.00 ]",
    "#0000006634 NoMotion-1. Scaled. Acc [ -00045.90, -00014.65, 00996.58 ], Gyr [ -00000.37, 00001.52, -00000.79 ], Mag [ 00019.35, 00084.90, 00108.00 ]",
    "#0000006635 NoMotion-1. Scaled. Acc [ -00054.69, -00006.84, 00987.30 ], Gyr [ 00000.67, 00000.06, 00000.55 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006636 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00982.42 ], Gyr [ -00000.24, 00000.67, -00000.24 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006637 NoMotion-1. Scaled. Acc [ -00046.88, -00001.46, 00983.40 ], Gyr [ 00000.49, 00000.49, -00001.16 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006638 NoMotion-1. Scaled. Acc [ -00052.73, -00002.93, 00982.42 ], Gyr [ -00000.43, 00003.05, -00003.54 ], Mag [ 00019.95, 00085.50, 00107.85 ]",
    "#0000006639 NoMotion-1. Scaled. Acc [ -00035.16, -00007.81, 00989.26 ], Gyr [ -00000.79, 00000.85, -00004.02 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000006640 NoMotion-1. Scaled. Acc [ -00026.37, 00001.95, 00978.52 ], Gyr [ 00000.79, -00002.07, -00001.46 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000006641 NoMotion-1. Scaled. Acc [ -00048.34, -00007.32, 00999.51 ], Gyr [ -00000.79, 00002.99, 00000.30 ], Mag [ 00019.35, 00082.95, 00109.50 ]",
    "#0000006642 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00985.35 ], Gyr [ 00000.24, 00002.32, -00002.74 ], Mag [ 00019.35, 00082.95, 00109.50 ]",
    "#0000006643 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00995.12 ], Gyr [ -00000.24, 00002.74, -00003.48 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000006644 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00994.63 ], Gyr [ 00000.06, 00000.49, -00003.05 ], Mag [ 00021.30, 00084.30, 00106.80 ]",
    "#0000006645 NoMotion-1. Scaled. Acc [ -00033.20, 00008.79, 00980.96 ], Gyr [ 00001.95, 00003.29, -00001.65 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006646 NoMotion-1. Scaled. Acc [ -00041.50, -00011.23, 00994.63 ], Gyr [ 00001.71, -00001.04, 00000.00 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006647 NoMotion-1. Scaled. Acc [ -00053.71, -00012.70, 00986.33 ], Gyr [ 00002.07, 00000.37, 00000.06 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000006648 NoMotion-1. Scaled. Acc [ -00047.36, -00001.46, 00983.40 ], Gyr [ 00000.61, 00002.99, -00001.52 ], Mag [ 00020.25, 00081.75, 00107.40 ]",
    "#0000006649 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00984.38 ], Gyr [ 00002.50, 00001.28, -00002.26 ], Mag [ 00020.25, 00081.75, 00106.50 ]",
    "#0000006650 NoMotion-1. Scaled. Acc [ -00049.32, -00007.32, 00999.51 ], Gyr [ 00001.83, -00000.85, -00001.83 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006651 NoMotion-1. Scaled. Acc [ -00042.97, -00011.23, 00988.28 ], Gyr [ 00000.98, -00000.98, -00000.12 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006652 NoMotion-1. Scaled. Acc [ -00049.80, -00003.42, 00996.58 ], Gyr [ -00000.06, 00003.05, -00001.34 ], Mag [ 00021.75, 00084.30, 00108.30 ]",
    "#0000006653 NoMotion-1. Scaled. Acc [ -00052.25, -00008.79, 00985.35 ], Gyr [ -00001.34, 00001.52, -00001.46 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000006654 NoMotion-1. Scaled. Acc [ -00041.02, 00004.88, 00993.16 ], Gyr [ 00000.79, 00001.28, -00002.56 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006655 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00987.30 ], Gyr [ -00002.20, 00000.98, -00000.61 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006656 NoMotion-1. Scaled. Acc [ -00024.41, -00006.35, 00986.33 ], Gyr [ 00000.49, 00002.93, -00000.85 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006657 NoMotion-1. Scaled. Acc [ -00039.55, -00003.42, 00988.77 ], Gyr [ -00000.24, 00003.29, -00001.16 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006658 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 00997.07 ], Gyr [ 00002.26, 00001.04, -00000.12 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006659 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00993.16 ], Gyr [ 00001.46, 00000.30, -00000.43 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006660 NoMotion-1. Scaled. Acc [ -00041.50, -00003.42, 00991.70 ], Gyr [ 00000.12, 00004.51, -00003.60 ], Mag [ 00018.60, 00084.15, 00109.05 ]",
    "#0000006661 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00984.86 ], Gyr [ 00000.49, 00000.61, -00000.43 ], Mag [ 00018.60, 00084.15, 00109.05 ]",
    "#0000006662 NoMotion-1. Scaled. Acc [ -00049.80, -00009.77, 00991.21 ], Gyr [ 00002.44, -00000.06, -00000.43 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000006663 NoMotion-1. Scaled. Acc [ -00049.32, -00015.62, 01000.98 ], Gyr [ -00001.28, 00002.68, -00000.98 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006664 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 01005.37 ], Gyr [ 00001.40, 00002.56, -00003.11 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006665 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00991.21 ], Gyr [ -00000.37, 00001.52, -00000.91 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006666 NoMotion-1. Scaled. Acc [ -00041.02, -00006.35, 00998.05 ], Gyr [ 00001.04, -00000.30, 00000.37 ], Mag [ 00019.95, 00082.50, 00108.30 ]",
    "#0000006667 NoMotion-1. Scaled. Acc [ -00035.64, -00000.98, 00990.72 ], Gyr [ -00002.20, 00002.74, -00002.38 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000006668 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 00983.89 ], Gyr [ 00001.10, 00004.09, -00001.71 ], Mag [ 00019.35, 00085.65, 00106.65 ]",
    "#0000006669 NoMotion-1. Scaled. Acc [ -00043.46, -00002.93, 00993.16 ], Gyr [ 00000.73, 00001.04, -00000.98 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006670 NoMotion-1. Scaled. Acc [ -00046.88, -00006.35, 00987.79 ], Gyr [ 00002.99, 00002.50, -00002.44 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006671 NoMotion-1. Scaled. Acc [ -00051.27, -00012.21, 00998.05 ], Gyr [ 00001.83, 00001.46, 00000.06 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006672 NoMotion-1. Scaled. Acc [ -00037.60, -00006.35, 00994.63 ], Gyr [ 00000.06, -00002.01, -00001.34 ], Mag [ 00021.75, 00084.75, 00106.80 ]",
    "#0000006673 NoMotion-1. Scaled. Acc [ -00041.99, 00001.46, 01000.98 ], Gyr [ 00001.95, 00000.43, -00000.06 ], Mag [ 00018.15, 00085.05, 00108.90 ]",
    "#0000006674 NoMotion-1. Scaled. Acc [ -00048.34, -00008.30, 00984.86 ], Gyr [ 00001.59, -00000.49, -00001.34 ], Mag [ 00018.15, 00085.05, 00108.90 ]",
    "#0000006675 NoMotion-1. Scaled. Acc [ -00042.48, -00012.21, 00987.30 ], Gyr [ 00000.00, 00001.83, -00002.68 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000006676 NoMotion-1. Scaled. Acc [ -00049.32, -00006.35, 00987.79 ], Gyr [ 00001.83, 00000.37, -00002.99 ], Mag [ 00020.10, 00083.40, 00108.00 ]",
    "#0000006677 NoMotion-1. Scaled. Acc [ -00041.99, -00003.42, 00985.35 ], Gyr [ 00000.61, 00000.91, -00001.65 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006678 NoMotion-1. Scaled. Acc [ -00039.06, -00000.49, 00991.21 ], Gyr [ -00000.49, 00004.15, 00000.43 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006679 NoMotion-1. Scaled. Acc [ -00041.02, -00002.44, 00995.61 ], Gyr [ -00001.83, 00001.71, -00002.20 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006680 NoMotion-1. Scaled. Acc [ -00062.50, 00000.98, 00991.21 ], Gyr [ 00000.61, -00000.37, -00002.74 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006681 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00982.42 ], Gyr [ 00001.95, 00002.32, -00002.26 ], Mag [ 00021.15, 00082.95, 00108.00 ]",
    "#0000006682 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00991.21 ], Gyr [ 00003.11, -00001.10, 00000.43 ], Mag [ 00018.15, 00085.05, 00107.55 ]",
    "#0000006683 NoMotion-1. Scaled. Acc [ -00051.27, -00013.18, 00997.07 ], Gyr [ 00001.16, 00001.83, -00001.89 ], Mag [ 00019.20, 00083.55, 00107.10 ]",
    "#0000006684 NoMotion-1. Scaled. Acc [ -00044.43, 00005.86, 00993.16 ], Gyr [ 00001.22, -00001.34, 00000.73 ], Mag [ 00019.20, 00083.55, 00107.10 ]",
    "#0000006685 NoMotion-1. Scaled. Acc [ -00028.81, -00001.95, 00986.82 ], Gyr [ 00000.73, 00000.30, 00001.65 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000006686 NoMotion-1. Scaled. Acc [ -00047.36, -00013.67, 00989.75 ], Gyr [ 00001.77, -00000.85, -00001.40 ], Mag [ 00020.85, 00084.45, 00106.95 ]",
    "#0000006687 NoMotion-1. Scaled. Acc [ -00049.32, -00004.39, 00991.21 ], Gyr [ -00000.12, 00000.91, -00001.16 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006688 NoMotion-1. Scaled. Acc [ -00039.06, 00000.00, 00996.58 ], Gyr [ 00000.37, 00002.01, -00001.04 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006689 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00986.82 ], Gyr [ 00000.18, 00002.01, -00001.52 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006690 NoMotion-1. Scaled. Acc [ -00040.53, -00018.55, 00994.14 ], Gyr [ 00000.37, 00000.43, -00001.95 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006691 NoMotion-1. Scaled. Acc [ -00025.39, -00016.11, 01003.91 ], Gyr [ -00000.43, 00001.71, -00000.98 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000006692 NoMotion-1. Scaled. Acc [ -00048.34, -00005.37, 00990.23 ], Gyr [ -00000.24, 00001.28, -00002.32 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000006693 NoMotion-1. Scaled. Acc [ -00040.53, -00013.67, 01003.42 ], Gyr [ 00000.49, 00002.38, -00000.73 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000006694 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00981.93 ], Gyr [ 00001.10, 00002.32, -00000.49 ], Mag [ 00021.15, 00084.15, 00107.40 ]",
    "#0000006695 NoMotion-1. Scaled. Acc [ -00036.13, 00001.46, 00998.05 ], Gyr [ -00000.24, 00003.66, 00000.98 ], Mag [ 00019.65, 00083.70, 00108.00 ]",
    "#0000006696 NoMotion-1. Scaled. Acc [ -00042.97, -00003.91, 01008.79 ], Gyr [ 00001.77, 00001.28, -00003.23 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006697 NoMotion-1. Scaled. Acc [ -00042.97, -00012.70, 00994.14 ], Gyr [ 00002.26, 00000.73, -00001.59 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006698 NoMotion-1. Scaled. Acc [ -00055.18, -00005.86, 01000.98 ], Gyr [ 00002.74, -00000.30, 00000.18 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006699 NoMotion-1. Scaled. Acc [ -00043.46, -00004.88, 00997.56 ], Gyr [ 00001.46, 00000.67, 00000.79 ], Mag [ 00019.35, 00083.70, 00105.60 ]",
    "#0000006700 NoMotion-1. Scaled. Acc [ -00050.78, -00010.74, 00994.63 ], Gyr [ -00001.04, 00000.98, -00000.55 ], Mag [ 00018.60, 00085.20, 00105.90 ]",
    "#0000006701 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00995.61 ], Gyr [ 00000.67, 00002.07, 00001.22 ], Mag [ 00018.60, 00085.20, 00105.90 ]",
    "#0000006702 NoMotion-1. Scaled. Acc [ -00038.09, -00006.35, 00985.35 ], Gyr [ -00000.37, 00002.80, -00000.18 ], Mag [ 00020.40, 00084.45, 00106.95 ]",
    "#0000006703 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00986.82 ], Gyr [ 00000.24, 00003.35, -00004.21 ], Mag [ 00020.40, 00084.45, 00106.95 ]",
    "#0000006704 NoMotion-1. Scaled. Acc [ -00042.48, -00021.97, 00993.16 ], Gyr [ 00000.30, 00002.56, 00001.16 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000006705 NoMotion-1. Scaled. Acc [ -00051.27, -00003.91, 00994.63 ], Gyr [ 00002.26, 00001.71, -00001.34 ], Mag [ 00019.05, 00083.70, 00106.65 ]",
    "#0000006706 NoMotion-1. Scaled. Acc [ -00045.90, 00004.39, 00992.68 ], Gyr [ 00000.30, 00004.94, -00001.83 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006707 NoMotion-1. Scaled. Acc [ -00054.20, -00004.88, 00989.26 ], Gyr [ -00000.18, 00006.10, -00004.21 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006708 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00993.65 ], Gyr [ -00000.55, 00000.37, 00001.16 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006709 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00995.61 ], Gyr [ 00000.43, 00001.52, -00001.52 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006710 NoMotion-1. Scaled. Acc [ -00047.85, -00010.25, 00983.89 ], Gyr [ 00000.55, 00000.79, -00004.21 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006711 NoMotion-1. Scaled. Acc [ -00053.22, -00007.81, 00998.54 ], Gyr [ 00001.65, 00000.12, -00000.37 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006712 NoMotion-1. Scaled. Acc [ -00032.71, -00004.39, 00997.56 ], Gyr [ -00000.91, 00001.40, -00002.93 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006713 NoMotion-1. Scaled. Acc [ -00038.57, -00000.98, 00990.23 ], Gyr [ 00001.46, 00000.91, -00000.55 ], Mag [ 00019.95, 00085.80, 00106.80 ]",
    "#0000006714 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 00991.21 ], Gyr [ 00000.55, 00000.98, -00001.28 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006715 NoMotion-1. Scaled. Acc [ -00041.02, -00014.65, 00983.40 ], Gyr [ 00000.49, 00001.77, 00001.04 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006716 NoMotion-1. Scaled. Acc [ -00048.34, -00024.41, 01000.00 ], Gyr [ 00000.67, 00000.79, -00000.98 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006717 NoMotion-1. Scaled. Acc [ -00031.74, -00016.11, 00995.12 ], Gyr [ -00000.67, 00002.07, 00000.00 ], Mag [ 00019.35, 00084.15, 00108.00 ]",
    "#0000006718 NoMotion-1. Scaled. Acc [ -00041.50, 00003.91, 00993.16 ], Gyr [ 00000.06, 00001.83, -00000.91 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006719 NoMotion-1. Scaled. Acc [ -00046.88, -00008.30, 00992.19 ], Gyr [ 00001.04, 00003.84, -00001.95 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006720 NoMotion-1. Scaled. Acc [ -00055.18, -00018.55, 00991.21 ], Gyr [ 00000.67, 00001.89, -00002.87 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006721 NoMotion-1. Scaled. Acc [ -00021.97, -00003.42, 00997.56 ], Gyr [ 00000.37, 00001.52, 00001.22 ], Mag [ 00019.05, 00085.20, 00106.95 ]",
    "#0000006722 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00978.03 ], Gyr [ 00001.34, 00000.91, -00002.07 ], Mag [ 00020.85, 00081.90, 00107.70 ]",
    "#0000006723 NoMotion-1. Scaled. Acc [ -00035.64, -00002.93, 00994.14 ], Gyr [ 00001.10, 00000.55, -00000.24 ], Mag [ 00020.85, 00081.90, 00107.70 ]",
    "#0000006724 NoMotion-1. Scaled. Acc [ -00040.53, -00006.35, 01004.39 ], Gyr [ 00000.55, 00003.17, -00001.83 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006725 NoMotion-1. Scaled. Acc [ -00053.71, -00006.35, 00997.56 ], Gyr [ 00000.37, 00000.91, -00001.95 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000006726 NoMotion-1. Scaled. Acc [ -00043.46, -00005.37, 00986.33 ], Gyr [ -00002.20, -00001.16, -00002.68 ], Mag [ 00020.10, 00084.45, 00108.45 ]",
    "#0000006727 NoMotion-1. Scaled. Acc [ -00040.53, -00016.11, 00987.30 ], Gyr [ 00002.20, -00000.73, -00001.95 ], Mag [ 00020.10, 00084.45, 00108.45 ]",
    "#0000006728 NoMotion-1. Scaled. Acc [ -00047.36, -00014.16, 00999.51 ], Gyr [ 00000.18, 00002.50, -00001.95 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006729 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00987.79 ], Gyr [ -00000.06, 00001.04, -00002.93 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006730 NoMotion-1. Scaled. Acc [ -00043.95, -00013.67, 00993.65 ], Gyr [ -00001.59, 00002.87, -00000.30 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006731 NoMotion-1. Scaled. Acc [ -00039.55, -00007.32, 00988.77 ], Gyr [ -00001.10, 00004.09, -00001.52 ], Mag [ 00019.35, 00084.45, 00107.70 ]",
    "#0000006732 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00985.35 ], Gyr [ 00000.06, 00003.78, -00002.13 ], Mag [ 00018.45, 00084.00, 00107.85 ]",
    "#0000006733 NoMotion-1. Scaled. Acc [ -00044.43, -00010.25, 00987.30 ], Gyr [ 00000.18, -00000.12, 00000.06 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006734 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00996.58 ], Gyr [ 00000.61, 00001.34, 00000.85 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006735 NoMotion-1. Scaled. Acc [ -00042.48, 00000.49, 00991.21 ], Gyr [ -00000.55, 00002.80, -00001.22 ], Mag [ 00018.75, 00084.00, 00108.90 ]",
    "#0000006736 NoMotion-1. Scaled. Acc [ -00032.71, -00010.25, 01000.49 ], Gyr [ 00001.16, 00001.34, -00001.89 ], Mag [ 00018.75, 00084.00, 00108.90 ]",
    "#0000006737 NoMotion-1. Scaled. Acc [ -00035.16, -00012.21, 00994.14 ], Gyr [ 00000.67, 00002.20, -00002.80 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006738 NoMotion-1. Scaled. Acc [ -00048.83, -00011.72, 00999.02 ], Gyr [ 00001.40, 00002.50, 00001.40 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006739 NoMotion-1. Scaled. Acc [ -00038.57, -00011.23, 01002.93 ], Gyr [ -00000.06, 00002.99, -00002.50 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006740 NoMotion-1. Scaled. Acc [ -00050.78, -00005.37, 01001.95 ], Gyr [ -00001.65, 00003.96, -00000.55 ], Mag [ 00020.40, 00084.45, 00108.75 ]",
    "#0000006741 NoMotion-1. Scaled. Acc [ -00038.57, -00014.16, 01000.00 ], Gyr [ 00000.43, -00000.67, -00001.59 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000006742 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00997.56 ], Gyr [ -00000.79, 00003.48, -00001.22 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000006743 NoMotion-1. Scaled. Acc [ -00034.18, -00004.39, 01001.46 ], Gyr [ 00001.52, 00002.80, -00000.73 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006744 NoMotion-1. Scaled. Acc [ -00041.99, 00005.86, 00989.75 ], Gyr [ 00001.52, 00001.89, -00002.07 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006745 NoMotion-1. Scaled. Acc [ -00040.04, -00003.91, 00997.56 ], Gyr [ 00000.61, 00000.12, -00001.95 ], Mag [ 00020.70, 00084.30, 00109.35 ]",
    "#0000006746 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 01002.44 ], Gyr [ 00000.30, 00000.24, -00001.83 ], Mag [ 00020.70, 00084.30, 00109.35 ]",
    "#0000006747 NoMotion-1. Scaled. Acc [ -00033.20, -00009.77, 00994.14 ], Gyr [ -00000.73, 00002.44, -00001.22 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006748 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00987.79 ], Gyr [ 00000.61, -00001.83, -00000.24 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006749 NoMotion-1. Scaled. Acc [ -00040.04, -00018.07, 00994.63 ], Gyr [ 00000.49, 00000.43, -00000.67 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006750 NoMotion-1. Scaled. Acc [ -00042.48, -00017.58, 00989.75 ], Gyr [ 00000.43, -00000.30, -00001.77 ], Mag [ 00019.65, 00082.95, 00106.20 ]",
    "#0000006751 NoMotion-1. Scaled. Acc [ -00046.39, -00007.81, 00995.61 ], Gyr [ -00000.30, 00003.60, -00003.66 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006752 NoMotion-1. Scaled. Acc [ -00048.34, -00020.02, 00991.70 ], Gyr [ 00002.38, 00000.73, 00000.37 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006753 NoMotion-1. Scaled. Acc [ -00045.90, -00011.72, 00988.28 ], Gyr [ 00001.10, -00000.85, -00002.38 ], Mag [ 00020.70, 00083.25, 00107.10 ]",
    "#0000006754 NoMotion-1. Scaled. Acc [ -00031.25, -00005.37, 00977.54 ], Gyr [ 00001.22, -00000.85, -00000.06 ], Mag [ 00020.70, 00083.25, 00107.10 ]",
    "#0000006755 NoMotion-1. Scaled. Acc [ -00033.20, -00003.91, 00983.40 ], Gyr [ 00000.67, 00002.07, -00003.41 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006756 NoMotion-1. Scaled. Acc [ -00035.64, -00005.37, 00994.63 ], Gyr [ 00000.12, 00000.12, -00001.34 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006757 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 01006.84 ], Gyr [ -00000.55, 00001.77, -00002.80 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006758 NoMotion-1. Scaled. Acc [ -00039.55, 00001.46, 00984.86 ], Gyr [ 00000.18, 00002.80, -00001.77 ], Mag [ 00020.85, 00084.15, 00107.70 ]",
    "#0000006759 NoMotion-1. Scaled. Acc [ -00051.27, -00013.67, 00993.65 ], Gyr [ 00000.67, 00001.16, -00000.67 ], Mag [ 00021.30, 00084.75, 00107.10 ]",
    "#0000006760 NoMotion-1. Scaled. Acc [ -00046.88, -00010.25, 00996.09 ], Gyr [ -00000.30, -00000.79, -00001.71 ], Mag [ 00021.30, 00084.75, 00107.10 ]",
    "#0000006761 NoMotion-1. Scaled. Acc [ -00058.11, -00002.44, 00978.03 ], Gyr [ 00000.12, 00002.38, -00000.91 ], Mag [ 00019.95, 00084.00, 00105.45 ]",
    "#0000006762 NoMotion-1. Scaled. Acc [ -00040.53, -00002.44, 01000.98 ], Gyr [ 00002.68, 00002.68, -00002.07 ], Mag [ 00019.95, 00084.00, 00105.45 ]",
    "#0000006763 NoMotion-1. Scaled. Acc [ -00030.76, 00000.49, 00988.77 ], Gyr [ 00002.13, 00001.22, 00000.06 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000006764 NoMotion-1. Scaled. Acc [ -00030.76, -00013.18, 01000.98 ], Gyr [ 00002.26, -00000.12, 00000.06 ], Mag [ 00021.15, 00082.95, 00106.95 ]",
    "#0000006765 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00993.65 ], Gyr [ 00000.85, 00002.07, -00001.77 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006766 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00987.30 ], Gyr [ 00002.93, -00000.91, -00002.44 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006767 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00995.61 ], Gyr [ 00001.95, 00003.90, -00002.44 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006768 NoMotion-1. Scaled. Acc [ -00045.90, -00002.93, 00992.68 ], Gyr [ -00000.06, 00002.13, -00000.30 ], Mag [ 00018.60, 00084.15, 00107.70 ]",
    "#0000006769 NoMotion-1. Scaled. Acc [ -00054.69, -00004.88, 00994.14 ], Gyr [ 00001.22, 00001.22, -00002.74 ], Mag [ 00019.50, 00084.30, 00107.55 ]",
    "#0000006770 NoMotion-1. Scaled. Acc [ -00054.20, -00006.35, 00992.19 ], Gyr [ -00002.07, 00001.89, 00000.18 ], Mag [ 00019.50, 00084.30, 00107.55 ]",
    "#0000006771 NoMotion-1. Scaled. Acc [ -00031.74, -00002.44, 00988.77 ], Gyr [ -00000.30, 00002.50, -00003.29 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000006772 NoMotion-1. Scaled. Acc [ -00046.88, 00001.46, 00988.28 ], Gyr [ 00000.98, 00002.20, 00000.24 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000006773 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00987.79 ], Gyr [ 00002.93, -00001.22, 00000.00 ], Mag [ 00019.35, 00083.70, 00106.65 ]",
    "#0000006774 NoMotion-1. Scaled. Acc [ -00047.85, 00005.37, 00983.40 ], Gyr [ 00000.73, -00000.67, 00000.12 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006775 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00988.28 ], Gyr [ 00002.93, -00000.91, -00002.80 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006776 NoMotion-1. Scaled. Acc [ -00046.39, 00001.46, 00993.16 ], Gyr [ 00000.85, 00000.00, -00000.30 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006777 NoMotion-1. Scaled. Acc [ -00038.57, -00018.07, 00990.23 ], Gyr [ -00002.13, 00000.37, -00001.16 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006778 NoMotion-1. Scaled. Acc [ -00042.97, 00012.70, 00994.63 ], Gyr [ 00001.65, 00002.26, -00000.49 ], Mag [ 00020.85, 00082.65, 00109.05 ]",
    "#0000006779 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00994.63 ], Gyr [ 00000.49, 00003.11, -00001.04 ], Mag [ 00019.35, 00082.65, 00107.40 ]",
    "#0000006780 NoMotion-1. Scaled. Acc [ -00034.18, -00021.00, 00994.63 ], Gyr [ 00000.55, 00002.74, -00002.87 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006781 NoMotion-1. Scaled. Acc [ -00032.23, -00006.84, 01003.42 ], Gyr [ 00000.24, 00004.33, -00001.83 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006782 NoMotion-1. Scaled. Acc [ -00052.73, -00007.81, 00987.79 ], Gyr [ -00000.85, 00000.06, -00003.54 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006783 NoMotion-1. Scaled. Acc [ -00041.02, 00000.98, 00986.33 ], Gyr [ 00001.28, 00000.06, -00002.44 ], Mag [ 00019.65, 00084.90, 00107.40 ]",
    "#0000006784 NoMotion-1. Scaled. Acc [ -00040.04, -00017.09, 01000.98 ], Gyr [ -00000.49, 00000.79, 00000.37 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000006785 NoMotion-1. Scaled. Acc [ -00054.20, -00005.37, 01002.93 ], Gyr [ 00001.04, 00001.10, -00002.01 ], Mag [ 00020.10, 00082.95, 00106.65 ]",
    "#0000006786 NoMotion-1. Scaled. Acc [ -00040.04, -00015.14, 00992.19 ], Gyr [ -00000.12, 00001.10, -00000.85 ], Mag [ 00020.40, 00084.45, 00107.70 ]",
    "#0000006787 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00994.63 ], Gyr [ 00001.59, -00000.06, -00001.10 ], Mag [ 00020.40, 00084.45, 00107.70 ]",
    "#0000006788 NoMotion-1. Scaled. Acc [ -00045.41, 00004.39, 00984.38 ], Gyr [ 00001.04, 00000.12, -00003.11 ], Mag [ 00018.75, 00083.25, 00107.55 ]",
    "#0000006789 NoMotion-1. Scaled. Acc [ -00031.74, 00006.84, 01000.98 ], Gyr [ 00000.18, -00001.04, 00000.67 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006790 NoMotion-1. Scaled. Acc [ -00053.71, -00014.65, 00992.19 ], Gyr [ 00000.12, 00001.10, -00002.68 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006791 NoMotion-1. Scaled. Acc [ -00046.39, -00008.30, 00993.65 ], Gyr [ 00000.18, 00002.38, -00002.07 ], Mag [ 00019.35, 00082.95, 00106.95 ]",
    "#0000006792 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00982.42 ], Gyr [ -00001.10, 00001.77, -00002.93 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000006793 NoMotion-1. Scaled. Acc [ -00047.36, -00006.35, 00999.51 ], Gyr [ -00000.73, 00002.07, -00000.37 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000006794 NoMotion-1. Scaled. Acc [ -00039.55, 00000.98, 00984.86 ], Gyr [ 00001.52, -00000.49, -00002.01 ], Mag [ 00018.75, 00084.75, 00106.80 ]",
    "#0000006795 NoMotion-1. Scaled. Acc [ -00052.73, -00007.32, 00993.16 ], Gyr [ -00000.43, 00000.85, -00002.20 ], Mag [ 00018.75, 00084.75, 00106.80 ]",
    "#0000006796 NoMotion-1. Scaled. Acc [ -00058.59, -00003.42, 00995.12 ], Gyr [ 00000.00, 00000.73, -00000.43 ], Mag [ 00019.35, 00085.20, 00107.70 ]",
    "#0000006797 NoMotion-1. Scaled. Acc [ -00057.13, -00014.65, 00991.70 ], Gyr [ 00000.30, 00001.77, -00001.22 ], Mag [ 00019.35, 00085.20, 00107.70 ]",
    "#0000006798 NoMotion-1. Scaled. Acc [ -00044.43, -00007.81, 00990.72 ], Gyr [ 00001.16, 00001.04, -00001.89 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006799 NoMotion-1. Scaled. Acc [ -00038.57, -00008.30, 00998.54 ], Gyr [ 00000.30, 00000.24, -00001.77 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006800 NoMotion-1. Scaled. Acc [ -00034.18, -00009.28, 00989.75 ], Gyr [ 00000.06, 00002.38, -00002.01 ], Mag [ 00019.95, 00083.55, 00107.85 ]",
    "#0000006801 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00992.19 ], Gyr [ 00000.85, 00001.28, -00000.49 ], Mag [ 00019.50, 00083.55, 00108.60 ]",
    "#0000006802 NoMotion-1. Scaled. Acc [ -00044.43, -00001.46, 00983.40 ], Gyr [ 00001.10, 00003.72, -00001.83 ], Mag [ 00019.50, 00083.55, 00108.60 ]",
    "#0000006803 NoMotion-1. Scaled. Acc [ -00041.50, -00008.79, 00984.86 ], Gyr [ -00000.85, 00004.15, 00000.55 ], Mag [ 00020.40, 00083.55, 00108.60 ]",
    "#0000006804 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00999.51 ], Gyr [ -00000.85, 00003.41, -00003.48 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000006805 NoMotion-1. Scaled. Acc [ -00055.18, -00011.72, 00990.72 ], Gyr [ -00001.77, 00002.56, -00001.22 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006806 NoMotion-1. Scaled. Acc [ -00048.83, 00001.46, 00995.61 ], Gyr [ 00000.37, 00002.26, -00002.62 ], Mag [ 00019.50, 00083.25, 00106.50 ]",
    "#0000006807 NoMotion-1. Scaled. Acc [ -00038.57, -00001.46, 01003.91 ], Gyr [ 00000.37, 00001.65, -00002.32 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006808 NoMotion-1. Scaled. Acc [ -00035.16, 00001.95, 00991.70 ], Gyr [ 00001.59, 00000.30, 00000.67 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006809 NoMotion-1. Scaled. Acc [ -00045.41, 00001.95, 00993.16 ], Gyr [ 00001.34, -00000.85, -00000.61 ], Mag [ 00019.05, 00084.15, 00106.95 ]",
    "#0000006810 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00996.09 ], Gyr [ 00002.44, 00001.04, 00000.55 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000006811 NoMotion-1. Scaled. Acc [ -00052.25, -00011.23, 01000.49 ], Gyr [ 00001.52, 00002.32, -00000.85 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000006812 NoMotion-1. Scaled. Acc [ -00035.16, -00012.21, 00988.77 ], Gyr [ -00000.18, 00002.62, -00001.46 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000006813 NoMotion-1. Scaled. Acc [ -00042.48, -00017.09, 00991.70 ], Gyr [ 00001.40, 00001.16, -00001.10 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000006814 NoMotion-1. Scaled. Acc [ -00041.50, -00003.91, 01000.49 ], Gyr [ 00002.01, -00000.06, -00000.43 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000006815 NoMotion-1. Scaled. Acc [ -00040.04, -00017.09, 00990.23 ], Gyr [ 00002.44, 00001.34, -00003.05 ], Mag [ 00019.95, 00083.25, 00106.50 ]",
    "#0000006816 NoMotion-1. Scaled. Acc [ -00048.34, -00004.39, 00978.52 ], Gyr [ -00000.24, 00000.79, -00003.96 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006817 NoMotion-1. Scaled. Acc [ -00049.32, -00001.46, 00995.61 ], Gyr [ 00000.06, 00001.16, -00001.34 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006818 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 01003.91 ], Gyr [ 00000.43, 00002.68, -00002.68 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006819 NoMotion-1. Scaled. Acc [ -00044.92, -00006.35, 00992.68 ], Gyr [ 00000.85, 00004.02, -00004.09 ], Mag [ 00019.65, 00083.70, 00107.40 ]",
    "#0000006820 NoMotion-1. Scaled. Acc [ -00047.85, -00004.39, 00996.09 ], Gyr [ 00000.00, 00001.34, -00001.65 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000006821 NoMotion-1. Scaled. Acc [ -00050.78, -00006.84, 00983.89 ], Gyr [ -00001.04, -00000.24, -00001.83 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006822 NoMotion-1. Scaled. Acc [ -00044.43, -00015.62, 00992.68 ], Gyr [ 00001.16, 00001.46, -00002.01 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006823 NoMotion-1. Scaled. Acc [ -00043.46, -00003.42, 00994.14 ], Gyr [ 00001.89, 00001.16, 00000.61 ], Mag [ 00018.45, 00083.25, 00107.10 ]",
    "#0000006824 NoMotion-1. Scaled. Acc [ -00052.73, -00001.95, 00993.65 ], Gyr [ 00003.84, 00000.24, -00001.40 ], Mag [ 00019.35, 00082.95, 00106.20 ]",
    "#0000006825 NoMotion-1. Scaled. Acc [ -00050.78, -00009.77, 00989.75 ], Gyr [ 00000.61, 00000.67, -00001.65 ], Mag [ 00019.35, 00082.95, 00106.20 ]",
    "#0000006826 NoMotion-1. Scaled. Acc [ -00039.55, 00002.93, 00985.35 ], Gyr [ 00001.04, 00000.12, -00002.80 ], Mag [ 00019.20, 00085.05, 00108.30 ]",
    "#0000006827 NoMotion-1. Scaled. Acc [ -00038.09, -00009.28, 00995.12 ], Gyr [ -00000.79, 00002.62, -00001.28 ], Mag [ 00019.20, 00085.05, 00108.30 ]",
    "#0000006828 NoMotion-1. Scaled. Acc [ -00050.78, -00006.35, 00993.65 ], Gyr [ 00001.46, 00002.80, -00003.96 ], Mag [ 00021.60, 00084.45, 00109.05 ]",
    "#0000006829 NoMotion-1. Scaled. Acc [ -00040.04, -00004.88, 00985.84 ], Gyr [ -00001.59, 00001.04, -00002.50 ], Mag [ 00021.60, 00084.45, 00109.05 ]",
    "#0000006830 NoMotion-1. Scaled. Acc [ -00057.13, -00004.39, 00992.68 ], Gyr [ -00001.59, 00002.50, -00000.85 ], Mag [ 00020.70, 00085.05, 00108.30 ]",
    "#0000006831 NoMotion-1. Scaled. Acc [ -00043.46, -00008.30, 00985.35 ], Gyr [ -00001.16, 00002.93, -00000.98 ], Mag [ 00020.70, 00085.05, 00108.30 ]",
    "#0000006832 NoMotion-1. Scaled. Acc [ -00040.04, -00020.51, 00984.38 ], Gyr [ -00000.24, 00001.95, -00002.80 ], Mag [ 00021.00, 00085.50, 00107.55 ]",
    "#0000006833 NoMotion-1. Scaled. Acc [ -00046.39, -00012.70, 00991.70 ], Gyr [ -00000.12, 00003.41, -00001.52 ], Mag [ 00021.00, 00085.50, 00107.55 ]",
    "#0000006834 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00986.82 ], Gyr [ 00000.00, 00002.50, -00003.72 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006835 NoMotion-1. Scaled. Acc [ -00039.55, -00021.00, 00985.84 ], Gyr [ 00001.16, 00000.85, -00001.77 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006836 NoMotion-1. Scaled. Acc [ -00043.46, -00006.35, 00984.38 ], Gyr [ 00001.52, 00000.79, -00001.40 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006837 NoMotion-1. Scaled. Acc [ -00041.50, -00001.46, 00989.26 ], Gyr [ -00001.65, 00002.56, -00001.40 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000006838 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00982.91 ], Gyr [ 00001.59, 00000.67, -00000.73 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006839 NoMotion-1. Scaled. Acc [ -00054.20, 00000.98, 00996.58 ], Gyr [ 00000.61, 00001.04, -00001.16 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006840 NoMotion-1. Scaled. Acc [ -00049.32, -00009.28, 00990.23 ], Gyr [ -00000.24, 00001.77, -00001.77 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006841 NoMotion-1. Scaled. Acc [ -00047.36, -00000.98, 00999.51 ], Gyr [ 00000.49, 00001.52, -00000.67 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006842 NoMotion-1. Scaled. Acc [ -00040.53, -00025.39, 00994.63 ], Gyr [ -00002.01, 00002.87, -00000.37 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000006843 NoMotion-1. Scaled. Acc [ -00042.97, -00014.16, 00998.05 ], Gyr [ 00000.12, 00003.72, -00002.56 ], Mag [ 00019.35, 00083.40, 00106.95 ]",
    "#0000006844 NoMotion-1. Scaled. Acc [ -00037.60, -00010.25, 00994.63 ], Gyr [ 00000.55, 00004.39, -00001.95 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006845 NoMotion-1. Scaled. Acc [ -00044.43, 00000.49, 00992.19 ], Gyr [ -00000.49, 00000.43, -00003.54 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006846 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00991.21 ], Gyr [ 00002.07, 00000.24, -00001.52 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006847 NoMotion-1. Scaled. Acc [ -00037.60, -00003.91, 00984.86 ], Gyr [ 00001.22, 00002.93, -00002.68 ], Mag [ 00019.50, 00083.55, 00108.90 ]",
    "#0000006848 NoMotion-1. Scaled. Acc [ -00047.85, -00019.04, 00996.58 ], Gyr [ 00000.67, 00001.95, 00000.24 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006849 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00987.30 ], Gyr [ -00000.37, -00000.73, -00001.59 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006850 NoMotion-1. Scaled. Acc [ -00034.18, 00003.42, 00979.49 ], Gyr [ 00000.49, -00000.37, -00000.85 ], Mag [ 00019.05, 00085.20, 00108.45 ]",
    "#0000006851 NoMotion-1. Scaled. Acc [ -00041.50, 00001.46, 00995.61 ], Gyr [ 00001.10, 00002.32, 00000.55 ], Mag [ 00019.35, 00082.20, 00107.40 ]",
    "#0000006852 NoMotion-1. Scaled. Acc [ -00042.48, 00008.79, 00989.75 ], Gyr [ -00000.24, 00001.95, -00002.99 ], Mag [ 00019.35, 00082.20, 00107.40 ]",
    "#0000006853 NoMotion-1. Scaled. Acc [ -00044.43, 00003.42, 00981.45 ], Gyr [ 00000.91, 00002.32, -00003.66 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006854 NoMotion-1. Scaled. Acc [ -00041.99, -00010.25, 00998.05 ], Gyr [ 00001.52, 00000.37, -00003.05 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006855 NoMotion-1. Scaled. Acc [ -00044.92, -00023.44, 00978.03 ], Gyr [ 00002.50, -00000.43, -00000.30 ], Mag [ 00021.00, 00084.30, 00107.55 ]",
    "#0000006856 NoMotion-1. Scaled. Acc [ -00043.95, -00003.42, 00994.14 ], Gyr [ 00000.85, 00000.79, -00003.54 ], Mag [ 00019.50, 00085.05, 00107.85 ]",
    "#0000006857 NoMotion-1. Scaled. Acc [ -00031.25, -00007.81, 00996.09 ], Gyr [ -00000.12, 00000.85, -00002.87 ], Mag [ 00019.50, 00085.05, 00107.85 ]",
    "#0000006858 NoMotion-1. Scaled. Acc [ -00041.02, -00007.81, 00990.23 ], Gyr [ -00000.43, 00000.55, -00002.01 ], Mag [ 00020.85, 00083.70, 00107.70 ]",
    "#0000006859 NoMotion-1. Scaled. Acc [ -00038.09, -00008.79, 00989.75 ], Gyr [ 00000.98, 00000.55, -00001.46 ], Mag [ 00020.85, 00083.70, 00107.70 ]",
    "#0000006860 NoMotion-1. Scaled. Acc [ -00041.50, -00003.42, 00984.38 ], Gyr [ 00002.99, 00002.32, -00001.71 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000006861 NoMotion-1. Scaled. Acc [ -00039.55, 00002.93, 01002.93 ], Gyr [ -00001.52, 00000.98, -00001.77 ], Mag [ 00019.20, 00083.25, 00107.85 ]",
    "#0000006862 NoMotion-1. Scaled. Acc [ -00029.30, -00006.84, 00990.23 ], Gyr [ 00000.43, 00001.52, -00002.07 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006863 NoMotion-1. Scaled. Acc [ -00056.15, -00004.39, 00994.14 ], Gyr [ 00001.28, 00001.52, 00001.04 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006864 NoMotion-1. Scaled. Acc [ -00042.97, -00011.72, 00991.70 ], Gyr [ 00000.18, 00000.06, -00001.34 ], Mag [ 00019.65, 00084.45, 00106.95 ]",
    "#0000006865 NoMotion-1. Scaled. Acc [ -00047.36, -00009.77, 00991.70 ], Gyr [ -00000.30, 00001.10, -00003.29 ], Mag [ 00018.30, 00082.95, 00106.65 ]",
    "#0000006866 NoMotion-1. Scaled. Acc [ -00039.55, -00020.51, 00992.19 ], Gyr [ 00000.55, 00001.71, -00004.27 ], Mag [ 00018.30, 00082.95, 00106.65 ]",
    "#0000006867 NoMotion-1. Scaled. Acc [ -00045.41, -00010.25, 01002.93 ], Gyr [ 00000.24, -00000.91, -00004.39 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000006868 NoMotion-1. Scaled. Acc [ -00042.48, -00005.86, 00982.91 ], Gyr [ 00000.00, 00000.18, 00000.18 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000006869 NoMotion-1. Scaled. Acc [ -00042.97, -00012.21, 00990.72 ], Gyr [ 00000.73, 00003.05, -00002.20 ], Mag [ 00019.20, 00084.30, 00108.30 ]",
    "#0000006870 NoMotion-1. Scaled. Acc [ -00042.48, -00002.44, 00986.33 ], Gyr [ -00001.34, 00002.80, -00003.54 ], Mag [ 00019.20, 00084.30, 00108.30 ]",
    "#0000006871 NoMotion-1. Scaled. Acc [ -00051.27, -00001.95, 01005.37 ], Gyr [ -00000.06, 00000.12, -00001.83 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006872 NoMotion-1. Scaled. Acc [ -00045.90, 00003.42, 00998.54 ], Gyr [ 00002.62, 00000.79, -00001.16 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006873 NoMotion-1. Scaled. Acc [ -00045.41, -00014.16, 00983.89 ], Gyr [ -00001.46, -00001.10, -00004.21 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006874 NoMotion-1. Scaled. Acc [ -00037.11, -00020.51, 00993.65 ], Gyr [ 00000.73, 00000.67, 00001.16 ], Mag [ 00020.10, 00085.95, 00108.00 ]",
    "#0000006875 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00985.35 ], Gyr [ 00001.52, -00000.12, -00001.46 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006876 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 01002.93 ], Gyr [ 00001.52, 00000.24, -00000.43 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000006877 NoMotion-1. Scaled. Acc [ -00041.02, -00005.86, 00984.38 ], Gyr [ -00000.30, 00000.43, 00000.43 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000006878 NoMotion-1. Scaled. Acc [ -00045.41, 00007.32, 00977.54 ], Gyr [ -00000.79, 00000.24, -00002.20 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006879 NoMotion-1. Scaled. Acc [ -00044.92, -00007.81, 00985.35 ], Gyr [ 00001.77, 00000.73, -00002.62 ], Mag [ 00019.50, 00084.75, 00107.85 ]",
    "#0000006880 NoMotion-1. Scaled. Acc [ -00041.02, 00000.49, 00990.72 ], Gyr [ -00000.06, 00001.16, -00000.49 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006881 NoMotion-1. Scaled. Acc [ -00049.80, -00006.84, 00981.93 ], Gyr [ -00000.37, 00001.71, -00003.54 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006882 NoMotion-1. Scaled. Acc [ -00038.09, -00006.84, 00980.47 ], Gyr [ 00002.93, -00001.04, 00001.34 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006883 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00986.82 ], Gyr [ 00000.49, 00002.26, -00001.59 ], Mag [ 00018.60, 00082.95, 00106.65 ]",
    "#0000006884 NoMotion-1. Scaled. Acc [ -00041.50, -00017.58, 00983.40 ], Gyr [ 00000.43, 00000.49, 00000.00 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006885 NoMotion-1. Scaled. Acc [ -00043.46, -00007.81, 00986.82 ], Gyr [ 00000.91, 00000.37, 00001.16 ], Mag [ 00019.65, 00084.45, 00108.75 ]",
    "#0000006886 NoMotion-1. Scaled. Acc [ -00047.85, -00012.70, 01005.37 ], Gyr [ 00001.04, 00002.44, 00001.89 ], Mag [ 00021.15, 00084.15, 00109.50 ]",
    "#0000006887 NoMotion-1. Scaled. Acc [ -00035.16, -00007.81, 00996.58 ], Gyr [ 00000.49, 00002.20, 00000.30 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006888 NoMotion-1. Scaled. Acc [ -00049.32, -00014.65, 00993.65 ], Gyr [ 00001.52, 00002.01, -00001.46 ], Mag [ 00020.25, 00082.50, 00107.85 ]",
    "#0000006889 NoMotion-1. Scaled. Acc [ -00044.43, -00020.02, 01002.44 ], Gyr [ 00003.17, 00003.17, 00000.30 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006890 NoMotion-1. Scaled. Acc [ -00031.25, -00004.88, 00986.82 ], Gyr [ 00000.91, 00001.04, -00002.93 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006891 NoMotion-1. Scaled. Acc [ -00050.78, -00018.07, 00982.91 ], Gyr [ 00001.77, 00000.79, 00002.01 ], Mag [ 00020.70, 00084.00, 00109.95 ]",
    "#0000006892 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00992.19 ], Gyr [ -00000.91, 00002.07, -00002.50 ], Mag [ 00020.10, 00083.70, 00107.70 ]",
    "#0000006893 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00989.75 ], Gyr [ -00000.55, 00003.29, -00002.07 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000006894 NoMotion-1. Scaled. Acc [ -00051.27, -00003.42, 00988.77 ], Gyr [ 00001.16, 00000.91, -00003.11 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000006895 NoMotion-1. Scaled. Acc [ -00051.76, -00003.42, 00989.26 ], Gyr [ 00000.06, 00001.16, -00000.43 ], Mag [ 00019.95, 00083.55, 00106.80 ]",
    "#0000006896 NoMotion-1. Scaled. Acc [ -00038.09, -00008.30, 00997.56 ], Gyr [ -00001.04, 00001.04, -00002.80 ], Mag [ 00018.45, 00084.00, 00108.30 ]",
    "#0000006897 NoMotion-1. Scaled. Acc [ -00039.06, 00001.46, 01000.49 ], Gyr [ 00001.34, -00000.18, -00001.10 ], Mag [ 00018.45, 00084.00, 00108.30 ]",
    "#0000006898 NoMotion-1. Scaled. Acc [ -00053.71, -00020.02, 00989.26 ], Gyr [ 00000.98, 00001.04, -00002.01 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006899 NoMotion-1. Scaled. Acc [ -00041.99, -00015.14, 00999.51 ], Gyr [ 00000.06, 00001.16, -00002.62 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006900 NoMotion-1. Scaled. Acc [ -00050.78, 00001.46, 00995.61 ], Gyr [ -00000.98, 00002.93, -00000.43 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006901 NoMotion-1. Scaled. Acc [ -00035.64, -00003.42, 00986.33 ], Gyr [ -00000.43, 00001.59, -00001.22 ], Mag [ 00021.30, 00084.00, 00107.55 ]",
    "#0000006902 NoMotion-1. Scaled. Acc [ -00044.92, -00011.23, 00998.54 ], Gyr [ -00001.71, 00002.44, -00003.41 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006903 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00988.28 ], Gyr [ 00001.52, 00002.26, -00002.26 ], Mag [ 00019.50, 00083.25, 00106.05 ]",
    "#0000006904 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00991.21 ], Gyr [ -00000.79, 00001.65, 00001.83 ], Mag [ 00019.05, 00082.95, 00106.95 ]",
    "#0000006905 NoMotion-1. Scaled. Acc [ -00038.09, -00016.11, 00998.54 ], Gyr [ -00000.43, 00002.20, -00000.91 ], Mag [ 00019.05, 00082.95, 00106.95 ]",
    "#0000006906 NoMotion-1. Scaled. Acc [ -00040.04, 00001.95, 00997.07 ], Gyr [ 00000.67, 00002.68, 00001.10 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006907 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00990.23 ], Gyr [ 00002.62, 00002.13, -00002.13 ], Mag [ 00020.70, 00084.00, 00108.60 ]",
    "#0000006908 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00982.91 ], Gyr [ 00002.50, -00001.28, 00003.11 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006909 NoMotion-1. Scaled. Acc [ -00043.95, -00012.21, 00979.49 ], Gyr [ 00000.43, 00002.44, -00000.55 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006910 NoMotion-1. Scaled. Acc [ -00044.43, 00000.98, 00988.28 ], Gyr [ 00000.85, 00002.13, -00000.49 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000006911 NoMotion-1. Scaled. Acc [ -00031.25, -00012.70, 00989.75 ], Gyr [ 00001.95, 00001.95, -00001.10 ], Mag [ 00019.95, 00082.80, 00106.05 ]",
    "#0000006912 NoMotion-1. Scaled. Acc [ -00045.90, -00018.07, 00993.65 ], Gyr [ 00000.79, 00001.10, -00001.95 ], Mag [ 00019.95, 00082.80, 00106.05 ]",
    "#0000006913 NoMotion-1. Scaled. Acc [ -00042.97, 00004.39, 00983.89 ], Gyr [ -00000.18, 00005.67, -00002.74 ], Mag [ 00020.70, 00083.55, 00106.80 ]",
    "#0000006914 NoMotion-1. Scaled. Acc [ -00046.39, -00008.79, 00996.09 ], Gyr [ 00000.00, 00001.16, -00001.83 ], Mag [ 00020.70, 00083.55, 00106.80 ]",
    "#0000006915 NoMotion-1. Scaled. Acc [ -00044.43, 00003.42, 00990.23 ], Gyr [ -00001.04, 00000.79, -00000.49 ], Mag [ 00018.75, 00085.80, 00106.80 ]",
    "#0000006916 NoMotion-1. Scaled. Acc [ -00034.18, -00002.93, 00991.21 ], Gyr [ 00000.85, -00000.61, -00000.55 ], Mag [ 00018.75, 00085.80, 00106.80 ]",
    "#0000006917 NoMotion-1. Scaled. Acc [ -00039.55, -00005.86, 00989.26 ], Gyr [ -00000.30, 00003.60, -00000.85 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006918 NoMotion-1. Scaled. Acc [ -00053.22, -00010.25, 01003.91 ], Gyr [ -00001.22, -00000.06, -00002.74 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006919 NoMotion-1. Scaled. Acc [ -00038.09, -00001.95, 00981.93 ], Gyr [ 00002.74, 00000.85, -00000.24 ], Mag [ 00019.50, 00084.30, 00109.35 ]",
    "#0000006920 NoMotion-1. Scaled. Acc [ -00035.16, -00010.74, 00995.12 ], Gyr [ 00000.79, -00001.40, -00002.80 ], Mag [ 00019.65, 00084.90, 00108.00 ]",
    "#0000006921 NoMotion-1. Scaled. Acc [ -00042.48, -00011.23, 00994.63 ], Gyr [ -00001.52, 00003.84, -00001.59 ], Mag [ 00019.65, 00084.90, 00108.00 ]",
    "#0000006922 NoMotion-1. Scaled. Acc [ -00044.92, -00003.42, 00993.16 ], Gyr [ 00002.44, -00000.12, -00001.89 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000006923 NoMotion-1. Scaled. Acc [ -00039.55, -00008.30, 00997.07 ], Gyr [ 00000.43, 00002.01, 00002.32 ], Mag [ 00019.20, 00084.75, 00106.05 ]",
    "#0000006924 NoMotion-1. Scaled. Acc [ -00037.11, -00001.46, 00990.23 ], Gyr [ 00000.06, 00001.59, -00002.50 ], Mag [ 00018.45, 00085.50, 00107.10 ]",
    "#0000006925 NoMotion-1. Scaled. Acc [ -00043.95, 00003.42, 00991.70 ], Gyr [ 00003.66, 00001.22, 00001.22 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006926 NoMotion-1. Scaled. Acc [ -00041.50, -00015.14, 00988.77 ], Gyr [ -00000.61, 00003.23, -00002.99 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006927 NoMotion-1. Scaled. Acc [ -00043.95, -00010.74, 00993.16 ], Gyr [ -00001.28, 00004.88, -00002.01 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006928 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00993.65 ], Gyr [ 00001.46, -00000.91, -00001.65 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006929 NoMotion-1. Scaled. Acc [ -00041.99, -00013.18, 00989.26 ], Gyr [ -00000.73, 00002.32, -00002.32 ], Mag [ 00017.55, 00084.45, 00108.45 ]",
    "#0000006930 NoMotion-1. Scaled. Acc [ -00041.99, -00004.39, 00992.19 ], Gyr [ -00000.30, 00002.44, -00002.13 ], Mag [ 00019.20, 00083.25, 00108.30 ]",
    "#0000006931 NoMotion-1. Scaled. Acc [ -00055.18, -00002.44, 00982.42 ], Gyr [ 00000.30, 00001.28, -00001.22 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000006932 NoMotion-1. Scaled. Acc [ -00051.27, -00009.28, 00987.30 ], Gyr [ 00001.40, -00000.24, 00001.83 ], Mag [ 00019.05, 00085.20, 00107.70 ]",
    "#0000006933 NoMotion-1. Scaled. Acc [ -00037.11, -00015.62, 00993.16 ], Gyr [ -00000.12, 00005.00, -00002.44 ], Mag [ 00020.25, 00083.55, 00107.55 ]",
    "#0000006934 NoMotion-1. Scaled. Acc [ -00033.20, -00005.86, 00986.33 ], Gyr [ -00000.06, 00001.83, -00003.90 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006935 NoMotion-1. Scaled. Acc [ -00034.18, -00010.74, 00988.28 ], Gyr [ 00000.30, 00001.28, 00001.95 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006936 NoMotion-1. Scaled. Acc [ -00059.57, -00004.88, 00991.21 ], Gyr [ 00001.59, 00001.89, -00002.93 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006937 NoMotion-1. Scaled. Acc [ -00043.95, -00007.81, 00997.07 ], Gyr [ 00000.73, -00001.22, 00000.24 ], Mag [ 00020.70, 00082.80, 00107.55 ]",
    "#0000006938 NoMotion-1. Scaled. Acc [ -00050.29, -00007.32, 01000.00 ], Gyr [ -00000.73, 00001.34, -00001.04 ], Mag [ 00018.45, 00084.30, 00109.35 ]",
    "#0000006939 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00981.45 ], Gyr [ 00000.91, 00002.68, 00000.18 ], Mag [ 00018.45, 00084.30, 00109.35 ]",
    "#0000006940 NoMotion-1. Scaled. Acc [ -00039.55, -00010.25, 00988.28 ], Gyr [ 00002.68, 00000.55, -00002.32 ], Mag [ 00019.65, 00083.70, 00107.70 ]",
    "#0000006941 NoMotion-1. Scaled. Acc [ -00041.99, -00003.91, 00992.68 ], Gyr [ -00000.49, -00001.89, -00001.71 ], Mag [ 00019.65, 00083.70, 00107.70 ]",
    "#0000006942 NoMotion-1. Scaled. Acc [ -00040.53, -00016.11, 00986.33 ], Gyr [ 00000.43, 00002.20, -00001.28 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000006943 NoMotion-1. Scaled. Acc [ -00050.78, -00006.35, 00991.21 ], Gyr [ 00000.61, 00003.17, -00000.61 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000006944 NoMotion-1. Scaled. Acc [ -00036.62, -00020.51, 00990.23 ], Gyr [ 00002.32, 00002.50, -00001.22 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006945 NoMotion-1. Scaled. Acc [ -00048.83, -00011.72, 00995.61 ], Gyr [ 00000.30, 00002.38, -00002.80 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006946 NoMotion-1. Scaled. Acc [ -00039.55, -00007.81, 00978.52 ], Gyr [ 00000.06, 00000.55, -00001.71 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006947 NoMotion-1. Scaled. Acc [ -00042.97, -00018.55, 00986.82 ], Gyr [ 00000.43, 00001.04, -00002.26 ], Mag [ 00019.65, 00084.15, 00109.50 ]",
    "#0000006948 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00989.26 ], Gyr [ 00001.34, 00001.65, -00000.12 ], Mag [ 00019.65, 00084.45, 00109.80 ]",
    "#0000006949 NoMotion-1. Scaled. Acc [ -00049.32, -00020.51, 01006.84 ], Gyr [ 00001.46, 00001.77, -00002.38 ], Mag [ 00019.65, 00084.45, 00109.80 ]",
    "#0000006950 NoMotion-1. Scaled. Acc [ -00035.64, -00014.16, 01003.42 ], Gyr [ -00000.06, 00001.40, 00000.30 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000006951 NoMotion-1. Scaled. Acc [ -00045.41, -00005.86, 00983.89 ], Gyr [ 00000.85, 00001.52, -00000.67 ], Mag [ 00020.85, 00083.70, 00106.95 ]",
    "#0000006952 NoMotion-1. Scaled. Acc [ -00039.55, -00009.28, 00993.16 ], Gyr [ -00001.59, -00000.12, -00000.49 ], Mag [ 00020.10, 00083.40, 00106.95 ]",
    "#0000006953 NoMotion-1. Scaled. Acc [ -00048.83, -00001.46, 00980.96 ], Gyr [ 00001.34, 00003.11, -00001.10 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006954 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00989.26 ], Gyr [ 00001.22, 00000.73, -00001.40 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006955 NoMotion-1. Scaled. Acc [ -00044.43, -00003.91, 00985.35 ], Gyr [ 00002.38, 00001.71, -00002.93 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006956 NoMotion-1. Scaled. Acc [ -00042.48, 00000.49, 00987.79 ], Gyr [ 00000.79, 00002.68, -00002.26 ], Mag [ 00021.00, 00083.55, 00108.60 ]",
    "#0000006957 NoMotion-1. Scaled. Acc [ -00033.69, -00004.39, 00991.70 ], Gyr [ 00000.67, -00000.61, -00002.01 ], Mag [ 00017.70, 00084.75, 00108.30 ]",
    "#0000006958 NoMotion-1. Scaled. Acc [ -00043.46, 00001.95, 00978.52 ], Gyr [ 00002.07, -00000.43, 00000.49 ], Mag [ 00017.70, 00084.75, 00108.30 ]",
    "#0000006959 NoMotion-1. Scaled. Acc [ -00043.46, -00014.65, 00995.12 ], Gyr [ -00001.04, 00001.46, -00000.06 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000006960 NoMotion-1. Scaled. Acc [ -00042.97, -00007.81, 00990.23 ], Gyr [ -00000.18, 00001.77, -00002.01 ], Mag [ 00019.50, 00082.80, 00107.10 ]",
    "#0000006961 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00991.21 ], Gyr [ 00002.38, -00001.59, -00001.89 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000006962 NoMotion-1. Scaled. Acc [ -00032.23, -00019.53, 00987.79 ], Gyr [ 00000.00, 00001.46, -00000.67 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000006963 NoMotion-1. Scaled. Acc [ -00047.36, -00017.58, 00985.84 ], Gyr [ 00002.13, 00002.07, -00001.22 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006964 NoMotion-1. Scaled. Acc [ -00049.80, -00006.35, 00980.96 ], Gyr [ -00001.04, 00000.00, 00000.30 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006965 NoMotion-1. Scaled. Acc [ -00028.32, 00000.49, 00990.72 ], Gyr [ -00000.06, 00001.22, -00001.95 ], Mag [ 00019.35, 00084.45, 00109.05 ]",
    "#0000006966 NoMotion-1. Scaled. Acc [ -00043.46, 00000.00, 00987.79 ], Gyr [ 00001.59, -00000.06, -00001.52 ], Mag [ 00018.75, 00084.75, 00106.50 ]",
    "#0000006967 NoMotion-1. Scaled. Acc [ -00054.69, -00014.16, 00995.12 ], Gyr [ 00000.55, 00001.40, -00002.68 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000006968 NoMotion-1. Scaled. Acc [ -00047.85, -00011.72, 00989.26 ], Gyr [ -00001.28, -00000.12, -00003.48 ], Mag [ 00018.75, 00084.30, 00107.85 ]",
    "#0000006969 NoMotion-1. Scaled. Acc [ -00038.57, -00001.95, 00986.33 ], Gyr [ 00001.22, 00000.12, -00002.99 ], Mag [ 00019.95, 00084.75, 00107.55 ]",
    "#0000006970 NoMotion-1. Scaled. Acc [ -00051.27, -00001.95, 00992.68 ], Gyr [ 00001.59, 00000.61, 00000.55 ], Mag [ 00019.95, 00084.75, 00107.55 ]",
    "#0000006971 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00998.54 ], Gyr [ 00000.00, 00001.10, -00001.04 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006972 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00991.21 ], Gyr [ 00001.04, 00000.43, 00000.30 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006973 NoMotion-1. Scaled. Acc [ -00051.27, -00001.46, 00986.33 ], Gyr [ -00000.30, -00000.24, -00001.16 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000006974 NoMotion-1. Scaled. Acc [ -00036.62, -00008.79, 00995.12 ], Gyr [ 00000.61, -00001.71, -00001.59 ], Mag [ 00021.00, 00084.75, 00107.55 ]",
    "#0000006975 NoMotion-1. Scaled. Acc [ -00041.50, -00009.28, 00989.26 ], Gyr [ -00000.61, 00000.06, 00000.00 ], Mag [ 00021.00, 00084.75, 00107.55 ]",
    "#0000006976 NoMotion-1. Scaled. Acc [ -00048.34, -00003.91, 00998.05 ], Gyr [ -00000.06, 00001.46, -00003.35 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000006977 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00986.33 ], Gyr [ 00000.18, 00001.95, -00002.80 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000006978 NoMotion-1. Scaled. Acc [ -00034.67, -00003.91, 00996.58 ], Gyr [ 00000.06, 00001.95, -00000.18 ], Mag [ 00020.25, 00084.30, 00107.10 ]",
    "#0000006979 NoMotion-1. Scaled. Acc [ -00040.04, -00002.93, 00995.61 ], Gyr [ -00001.71, 00001.59, -00002.38 ], Mag [ 00020.25, 00084.30, 00107.10 ]",
    "#0000006980 NoMotion-1. Scaled. Acc [ -00059.57, 00000.49, 00984.38 ], Gyr [ 00000.43, 00002.44, -00002.99 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006981 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00976.07 ], Gyr [ -00000.30, 00001.89, -00004.76 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006982 NoMotion-1. Scaled. Acc [ -00041.02, -00010.25, 00981.45 ], Gyr [ 00002.01, -00000.43, -00000.98 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006983 NoMotion-1. Scaled. Acc [ -00054.69, -00016.11, 00992.19 ], Gyr [ 00000.12, 00000.37, -00000.73 ], Mag [ 00020.10, 00083.40, 00107.40 ]",
    "#0000006984 NoMotion-1. Scaled. Acc [ -00036.62, -00020.02, 00979.00 ], Gyr [ -00000.49, -00000.24, -00004.82 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000006985 NoMotion-1. Scaled. Acc [ -00035.16, -00008.79, 00985.35 ], Gyr [ 00000.37, 00000.37, 00000.06 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000006986 NoMotion-1. Scaled. Acc [ -00035.64, -00005.86, 00990.72 ], Gyr [ -00000.12, -00000.61, -00002.68 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000006987 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00991.70 ], Gyr [ 00001.46, 00002.44, -00001.52 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000006988 NoMotion-1. Scaled. Acc [ -00041.02, 00003.42, 00991.70 ], Gyr [ -00000.43, -00000.30, -00000.37 ], Mag [ 00020.70, 00083.55, 00107.10 ]",
    "#0000006989 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 00979.00 ], Gyr [ -00001.34, 00003.23, -00003.78 ], Mag [ 00020.70, 00083.55, 00107.10 ]",
    "#0000006990 NoMotion-1. Scaled. Acc [ -00040.04, -00017.58, 00997.56 ], Gyr [ -00000.18, 00001.83, -00000.18 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006991 NoMotion-1. Scaled. Acc [ -00040.53, -00000.98, 00987.79 ], Gyr [ 00001.16, -00000.24, -00001.77 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006992 NoMotion-1. Scaled. Acc [ -00047.85, -00009.28, 00983.40 ], Gyr [ 00001.28, 00002.62, -00001.34 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000006993 NoMotion-1. Scaled. Acc [ -00048.34, -00008.79, 00983.40 ], Gyr [ 00001.04, 00002.44, -00000.73 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000006994 NoMotion-1. Scaled. Acc [ -00041.02, -00006.35, 00987.30 ], Gyr [ -00000.43, 00001.40, -00002.68 ], Mag [ 00020.85, 00082.95, 00108.00 ]",
    "#0000006995 NoMotion-1. Scaled. Acc [ -00052.25, -00000.98, 00997.56 ], Gyr [ -00000.24, 00001.40, -00002.74 ], Mag [ 00019.35, 00084.15, 00105.90 ]",
    "#0000006996 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00985.84 ], Gyr [ 00001.95, -00000.37, -00000.73 ], Mag [ 00019.35, 00084.15, 00105.90 ]",
    "#0000006997 NoMotion-1. Scaled. Acc [ -00048.34, -00006.35, 00992.68 ], Gyr [ 00000.79, 00000.12, -00001.40 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000006998 NoMotion-1. Scaled. Acc [ -00046.39, -00003.91, 00995.12 ], Gyr [ 00000.85, -00001.10, -00003.17 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000006999 NoMotion-1. Scaled. Acc [ -00038.57, -00022.46, 00998.05 ], Gyr [ -00000.24, 00000.00, 00000.18 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007000 NoMotion-1. Scaled. Acc [ -00048.83, -00012.70, 00988.77 ], Gyr [ 00001.04, 00000.79, -00001.28 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007001 NoMotion-1. Scaled. Acc [ -00059.08, -00010.74, 00991.21 ], Gyr [ 00001.16, -00000.49, -00004.27 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007002 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00988.77 ], Gyr [ 00000.49, 00001.40, -00001.34 ], Mag [ 00020.25, 00084.00, 00107.85 ]",
    "#0000007003 NoMotion-1. Scaled. Acc [ -00040.53, -00010.74, 00983.89 ], Gyr [ 00000.55, 00000.98, -00002.38 ], Mag [ 00021.75, 00084.75, 00108.60 ]",
    "#0000007004 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00992.68 ], Gyr [ 00001.46, 00000.91, -00000.37 ], Mag [ 00021.75, 00084.75, 00108.60 ]",
    "#0000007005 NoMotion-1. Scaled. Acc [ -00045.41, -00011.23, 00981.45 ], Gyr [ 00001.28, -00000.98, 00001.28 ], Mag [ 00019.05, 00082.95, 00107.40 ]",
    "#0000007006 NoMotion-1. Scaled. Acc [ -00045.90, -00006.35, 00991.70 ], Gyr [ -00000.85, 00001.95, -00000.18 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000007007 NoMotion-1. Scaled. Acc [ -00037.60, -00021.00, 00987.30 ], Gyr [ -00002.20, 00004.27, -00004.39 ], Mag [ 00019.95, 00082.50, 00106.50 ]",
    "#0000007008 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 00993.65 ], Gyr [ 00001.40, 00002.44, -00001.65 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007009 NoMotion-1. Scaled. Acc [ -00059.08, -00000.49, 00994.14 ], Gyr [ 00000.37, 00001.04, -00001.16 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007010 NoMotion-1. Scaled. Acc [ -00057.13, -00012.70, 00984.38 ], Gyr [ 00001.04, -00000.37, 00000.30 ], Mag [ 00019.35, 00082.65, 00106.20 ]",
    "#0000007011 NoMotion-1. Scaled. Acc [ -00033.69, 00006.35, 00988.28 ], Gyr [ 00001.59, -00001.83, -00003.66 ], Mag [ 00019.35, 00082.65, 00106.20 ]",
    "#0000007012 NoMotion-1. Scaled. Acc [ -00041.99, -00007.32, 00984.86 ], Gyr [ -00001.16, 00003.54, -00002.01 ], Mag [ 00018.15, 00084.00, 00107.55 ]",
    "#0000007013 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 00996.58 ], Gyr [ -00001.89, 00001.34, 00000.37 ], Mag [ 00018.15, 00084.00, 00107.55 ]",
    "#0000007014 NoMotion-1. Scaled. Acc [ -00040.53, -00011.23, 00995.12 ], Gyr [ 00000.91, 00002.80, -00001.89 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000007015 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.77 ], Gyr [ 00000.37, 00001.34, -00002.68 ], Mag [ 00019.65, 00083.40, 00108.00 ]",
    "#0000007016 NoMotion-1. Scaled. Acc [ -00046.88, -00012.21, 00985.35 ], Gyr [ 00000.18, 00000.85, 00000.91 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007017 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 01000.49 ], Gyr [ 00002.80, -00000.91, -00003.60 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007018 NoMotion-1. Scaled. Acc [ -00036.62, -00005.86, 01000.98 ], Gyr [ 00002.07, -00001.34, -00000.79 ], Mag [ 00018.75, 00084.30, 00106.80 ]",
    "#0000007019 NoMotion-1. Scaled. Acc [ -00040.53, -00008.79, 00986.82 ], Gyr [ 00001.40, 00000.55, 00000.18 ], Mag [ 00018.75, 00084.30, 00106.80 ]",
    "#0000007020 NoMotion-1. Scaled. Acc [ -00053.22, -00012.70, 00999.02 ], Gyr [ 00001.71, 00004.33, -00001.16 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007021 NoMotion-1. Scaled. Acc [ -00038.57, -00026.86, 00987.30 ], Gyr [ 00001.04, 00000.00, 00000.79 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007022 NoMotion-1. Scaled. Acc [ -00032.23, 00000.00, 00991.70 ], Gyr [ 00000.73, -00001.10, -00001.89 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007023 NoMotion-1. Scaled. Acc [ -00047.85, -00005.37, 00984.38 ], Gyr [ 00000.49, 00001.40, -00001.40 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007024 NoMotion-1. Scaled. Acc [ -00036.62, 00000.98, 00989.26 ], Gyr [ 00001.46, 00004.51, -00001.34 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007025 NoMotion-1. Scaled. Acc [ -00036.62, -00007.32, 00993.16 ], Gyr [ 00001.04, 00001.83, 00000.43 ], Mag [ 00021.15, 00083.70, 00107.70 ]",
    "#0000007026 NoMotion-1. Scaled. Acc [ -00042.48, 00009.77, 00989.75 ], Gyr [ -00000.06, 00001.89, -00002.56 ], Mag [ 00021.15, 00083.70, 00107.70 ]",
    "#0000007027 NoMotion-1. Scaled. Acc [ -00050.78, 00000.49, 00999.51 ], Gyr [ -00000.98, -00000.49, -00002.07 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000007028 NoMotion-1. Scaled. Acc [ -00041.99, -00002.93, 01000.98 ], Gyr [ -00000.18, 00004.70, 00000.37 ], Mag [ 00019.50, 00083.55, 00106.80 ]",
    "#0000007029 NoMotion-1. Scaled. Acc [ -00042.97, -00001.95, 01000.49 ], Gyr [ 00001.95, 00001.65, -00000.79 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007030 NoMotion-1. Scaled. Acc [ -00038.09, 00000.00, 00993.16 ], Gyr [ -00000.85, 00000.30, -00000.85 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007031 NoMotion-1. Scaled. Acc [ -00049.32, 00000.00, 00990.23 ], Gyr [ -00001.04, -00000.49, -00003.23 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007032 NoMotion-1. Scaled. Acc [ -00045.41, -00003.42, 00987.79 ], Gyr [ 00000.85, 00000.00, -00000.73 ], Mag [ 00019.50, 00084.00, 00109.35 ]",
    "#0000007033 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 00990.23 ], Gyr [ 00000.91, -00000.73, -00000.06 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007034 NoMotion-1. Scaled. Acc [ -00042.48, -00016.60, 00999.51 ], Gyr [ 00002.56, 00000.91, -00000.18 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007035 NoMotion-1. Scaled. Acc [ -00036.13, -00010.25, 00987.79 ], Gyr [ 00001.89, 00002.93, -00003.90 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007036 NoMotion-1. Scaled. Acc [ -00041.50, -00016.11, 00993.16 ], Gyr [ -00000.12, 00000.37, 00000.91 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007037 NoMotion-1. Scaled. Acc [ -00050.29, -00006.84, 00991.70 ], Gyr [ 00002.44, 00000.30, -00003.11 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007038 NoMotion-1. Scaled. Acc [ -00037.11, -00014.16, 00982.91 ], Gyr [ 00001.16, 00002.38, -00000.12 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007039 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00988.28 ], Gyr [ -00001.28, 00002.07, -00002.26 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007040 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00988.28 ], Gyr [ -00000.43, 00000.18, -00000.67 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007041 NoMotion-1. Scaled. Acc [ -00048.83, 00007.32, 01000.49 ], Gyr [ 00001.04, 00000.91, -00001.34 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007042 NoMotion-1. Scaled. Acc [ -00047.85, 00002.93, 00991.70 ], Gyr [ 00000.73, 00002.32, -00002.13 ], Mag [ 00019.50, 00082.80, 00105.75 ]",
    "#0000007043 NoMotion-1. Scaled. Acc [ -00042.48, -00007.81, 01000.49 ], Gyr [ -00001.40, 00001.83, -00002.07 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000007044 NoMotion-1. Scaled. Acc [ -00052.73, 00002.44, 00985.84 ], Gyr [ -00000.24, 00002.87, -00004.39 ], Mag [ 00018.30, 00084.15, 00107.40 ]",
    "#0000007045 NoMotion-1. Scaled. Acc [ -00044.92, -00015.14, 00993.16 ], Gyr [ 00001.16, -00000.37, -00001.34 ], Mag [ 00018.45, 00083.25, 00107.55 ]",
    "#0000007046 NoMotion-1. Scaled. Acc [ -00037.11, -00003.91, 00988.28 ], Gyr [ 00000.91, 00002.32, -00001.77 ], Mag [ 00018.45, 00083.25, 00107.55 ]",
    "#0000007047 NoMotion-1. Scaled. Acc [ -00047.85, 00002.44, 00999.51 ], Gyr [ 00000.18, 00001.83, -00003.05 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007048 NoMotion-1. Scaled. Acc [ -00040.53, -00005.86, 00987.30 ], Gyr [ -00000.73, 00002.07, -00001.40 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007049 NoMotion-1. Scaled. Acc [ -00045.90, -00005.86, 00994.14 ], Gyr [ -00000.06, 00001.89, -00000.85 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007050 NoMotion-1. Scaled. Acc [ -00036.62, -00007.32, 00990.72 ], Gyr [ 00000.00, 00000.37, 00000.30 ], Mag [ 00020.85, 00082.65, 00107.40 ]",
    "#0000007051 NoMotion-1. Scaled. Acc [ -00053.22, 00008.30, 01000.98 ], Gyr [ 00000.55, 00002.68, -00000.43 ], Mag [ 00018.45, 00083.55, 00107.85 ]",
    "#0000007052 NoMotion-1. Scaled. Acc [ -00044.92, -00008.30, 00985.35 ], Gyr [ -00000.67, 00000.73, -00000.12 ], Mag [ 00018.45, 00083.55, 00107.85 ]",
    "#0000007053 NoMotion-1. Scaled. Acc [ -00041.99, -00001.95, 01003.42 ], Gyr [ -00000.55, 00001.10, -00001.95 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000007054 NoMotion-1. Scaled. Acc [ -00037.60, -00007.81, 00987.30 ], Gyr [ -00000.06, 00001.10, -00000.06 ], Mag [ 00018.60, 00083.70, 00108.00 ]",
    "#0000007055 NoMotion-1. Scaled. Acc [ -00043.46, -00013.18, 00996.58 ], Gyr [ 00001.89, 00001.28, -00001.04 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000007056 NoMotion-1. Scaled. Acc [ -00043.46, -00001.46, 00985.35 ], Gyr [ 00001.52, 00000.55, -00003.41 ], Mag [ 00019.65, 00083.70, 00106.95 ]",
    "#0000007057 NoMotion-1. Scaled. Acc [ -00052.25, -00008.30, 00990.23 ], Gyr [ 00000.91, -00000.67, 00001.16 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007058 NoMotion-1. Scaled. Acc [ -00051.27, -00004.88, 00984.38 ], Gyr [ 00000.24, 00000.55, 00000.91 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007059 NoMotion-1. Scaled. Acc [ -00037.60, -00011.72, 00988.77 ], Gyr [ 00000.30, 00001.28, -00001.40 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007060 NoMotion-1. Scaled. Acc [ -00039.55, -00011.72, 00994.14 ], Gyr [ -00000.06, 00001.95, -00000.43 ], Mag [ 00018.60, 00084.15, 00106.65 ]",
    "#0000007061 NoMotion-1. Scaled. Acc [ -00048.34, -00018.07, 00991.70 ], Gyr [ 00003.05, 00002.87, -00000.91 ], Mag [ 00020.10, 00082.95, 00107.40 ]",
    "#0000007062 NoMotion-1. Scaled. Acc [ -00041.50, -00009.77, 00986.82 ], Gyr [ 00000.37, 00002.32, -00001.52 ], Mag [ 00019.95, 00083.55, 00108.60 ]",
    "#0000007063 NoMotion-1. Scaled. Acc [ -00032.23, -00011.72, 00993.16 ], Gyr [ 00000.55, 00002.50, -00000.06 ], Mag [ 00019.95, 00083.55, 00108.60 ]",
    "#0000007064 NoMotion-1. Scaled. Acc [ -00054.20, -00021.97, 00986.82 ], Gyr [ 00000.30, 00000.79, -00002.13 ], Mag [ 00020.85, 00081.15, 00108.00 ]",
    "#0000007065 NoMotion-1. Scaled. Acc [ -00057.13, -00002.93, 00991.21 ], Gyr [ -00000.67, 00001.83, -00001.22 ], Mag [ 00020.85, 00081.15, 00108.00 ]",
    "#0000007066 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00991.21 ], Gyr [ 00000.12, 00000.85, -00002.26 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007067 NoMotion-1. Scaled. Acc [ -00046.39, -00000.98, 00998.54 ], Gyr [ 00000.12, 00002.93, -00001.40 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007068 NoMotion-1. Scaled. Acc [ -00038.57, -00006.35, 00998.54 ], Gyr [ 00000.73, 00000.85, -00000.43 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007069 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00997.56 ], Gyr [ 00000.18, 00003.29, -00002.68 ], Mag [ 00019.65, 00082.65, 00105.60 ]",
    "#0000007070 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00998.05 ], Gyr [ 00000.79, 00003.78, -00000.06 ], Mag [ 00020.70, 00083.55, 00107.55 ]",
    "#0000007071 NoMotion-1. Scaled. Acc [ -00039.06, -00012.21, 00989.75 ], Gyr [ 00000.43, 00000.73, 00000.00 ], Mag [ 00020.70, 00083.55, 00107.55 ]",
    "#0000007072 NoMotion-1. Scaled. Acc [ -00050.78, -00012.70, 00984.38 ], Gyr [ 00000.91, 00003.17, 00001.34 ], Mag [ 00018.60, 00082.20, 00108.00 ]",
    "#0000007073 NoMotion-1. Scaled. Acc [ -00045.41, -00007.81, 00989.75 ], Gyr [ 00000.30, 00001.89, -00002.20 ], Mag [ 00018.60, 00082.20, 00108.00 ]",
    "#0000007074 NoMotion-1. Scaled. Acc [ -00043.46, -00024.90, 01000.00 ], Gyr [ 00001.40, 00000.67, -00002.32 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007075 NoMotion-1. Scaled. Acc [ -00031.74, 00000.98, 00987.79 ], Gyr [ 00000.24, -00000.85, 00000.91 ], Mag [ 00019.65, 00082.95, 00106.95 ]",
    "#0000007076 NoMotion-1. Scaled. Acc [ -00041.50, -00008.30, 00991.70 ], Gyr [ -00000.55, 00002.50, -00001.46 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007077 NoMotion-1. Scaled. Acc [ -00035.16, 00001.46, 00995.61 ], Gyr [ 00002.68, 00002.50, -00000.55 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007078 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00989.26 ], Gyr [ 00000.43, 00002.68, -00003.84 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007079 NoMotion-1. Scaled. Acc [ -00039.06, -00002.93, 00988.77 ], Gyr [ -00000.37, 00000.61, -00000.91 ], Mag [ 00020.40, 00083.40, 00108.00 ]",
    "#0000007080 NoMotion-1. Scaled. Acc [ -00048.83, -00008.30, 00999.51 ], Gyr [ 00000.43, 00001.22, -00000.55 ], Mag [ 00018.60, 00082.65, 00108.00 ]",
    "#0000007081 NoMotion-1. Scaled. Acc [ -00048.83, -00006.84, 00993.65 ], Gyr [ 00000.00, 00000.00, -00001.52 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007082 NoMotion-1. Scaled. Acc [ -00051.76, -00015.62, 00989.26 ], Gyr [ -00001.77, 00001.40, -00002.62 ], Mag [ 00018.45, 00082.80, 00107.10 ]",
    "#0000007083 NoMotion-1. Scaled. Acc [ -00044.43, -00006.84, 00997.07 ], Gyr [ 00000.73, 00001.16, -00000.55 ], Mag [ 00018.60, 00084.15, 00108.00 ]",
    "#0000007084 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00984.86 ], Gyr [ -00000.98, 00000.43, -00003.29 ], Mag [ 00018.60, 00084.15, 00108.00 ]",
    "#0000007085 NoMotion-1. Scaled. Acc [ -00046.88, -00007.81, 00988.28 ], Gyr [ 00001.46, 00000.85, -00000.73 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007086 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00987.30 ], Gyr [ -00001.10, 00001.59, 00000.00 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007087 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00990.23 ], Gyr [ 00000.18, 00000.06, 00000.00 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007088 NoMotion-1. Scaled. Acc [ -00033.69, -00024.90, 00988.77 ], Gyr [ 00000.06, 00001.40, -00002.80 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007089 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 00984.86 ], Gyr [ 00000.79, 00004.02, -00001.46 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007090 NoMotion-1. Scaled. Acc [ -00044.43, -00009.28, 01004.88 ], Gyr [ 00000.00, 00001.10, -00001.28 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007091 NoMotion-1. Scaled. Acc [ -00052.25, -00007.32, 00990.72 ], Gyr [ 00001.83, 00001.16, -00001.95 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007092 NoMotion-1. Scaled. Acc [ -00041.50, -00010.74, 00995.61 ], Gyr [ -00001.10, 00001.71, -00000.06 ], Mag [ 00018.30, 00082.65, 00106.95 ]",
    "#0000007093 NoMotion-1. Scaled. Acc [ -00041.02, -00008.30, 00983.40 ], Gyr [ 00000.49, 00005.37, 00000.49 ], Mag [ 00019.95, 00084.30, 00106.50 ]",
    "#0000007094 NoMotion-1. Scaled. Acc [ -00044.43, -00012.70, 00992.19 ], Gyr [ -00000.12, 00003.72, -00002.80 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007095 NoMotion-1. Scaled. Acc [ -00041.50, -00012.70, 00993.16 ], Gyr [ 00002.01, 00000.79, -00000.67 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007096 NoMotion-1. Scaled. Acc [ -00038.57, -00020.02, 00986.82 ], Gyr [ 00001.46, 00000.00, -00000.73 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007097 NoMotion-1. Scaled. Acc [ -00049.80, -00004.39, 00996.58 ], Gyr [ -00000.30, 00001.95, -00002.44 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007098 NoMotion-1. Scaled. Acc [ -00039.06, -00014.16, 00995.61 ], Gyr [ 00000.73, 00002.99, -00000.98 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007099 NoMotion-1. Scaled. Acc [ -00051.76, -00002.44, 00990.72 ], Gyr [ 00000.67, 00002.32, -00000.37 ], Mag [ 00018.30, 00083.70, 00106.95 ]",
    "#0000007100 NoMotion-1. Scaled. Acc [ -00051.27, -00000.98, 00984.86 ], Gyr [ 00001.22, 00000.85, -00002.44 ], Mag [ 00018.30, 00083.70, 00106.95 ]",
    "#0000007101 NoMotion-1. Scaled. Acc [ -00036.62, -00002.93, 00995.12 ], Gyr [ 00001.59, 00000.98, -00001.65 ], Mag [ 00018.60, 00083.40, 00105.90 ]",
    "#0000007102 NoMotion-1. Scaled. Acc [ -00032.71, -00000.98, 00986.82 ], Gyr [ -00000.91, 00002.20, -00002.32 ], Mag [ 00018.60, 00083.40, 00105.90 ]",
    "#0000007103 NoMotion-1. Scaled. Acc [ -00054.20, -00009.77, 00982.91 ], Gyr [ 00000.67, -00000.49, -00001.34 ], Mag [ 00021.15, 00084.15, 00108.00 ]",
    "#0000007104 NoMotion-1. Scaled. Acc [ -00046.39, -00011.23, 00992.19 ], Gyr [ 00000.24, 00003.35, -00002.87 ], Mag [ 00021.15, 00084.15, 00108.00 ]",
    "#0000007105 NoMotion-1. Scaled. Acc [ -00041.02, -00009.77, 00985.84 ], Gyr [ -00000.18, 00000.37, -00002.93 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007106 NoMotion-1. Scaled. Acc [ -00045.41, -00012.21, 00991.70 ], Gyr [ -00000.98, 00001.77, -00001.34 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007107 NoMotion-1. Scaled. Acc [ -00046.88, -00002.93, 00987.30 ], Gyr [ -00000.18, 00001.77, 00000.79 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007108 NoMotion-1. Scaled. Acc [ -00041.50, -00005.37, 00988.77 ], Gyr [ 00000.79, 00001.16, -00000.91 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007109 NoMotion-1. Scaled. Acc [ -00045.41, -00007.32, 00988.28 ], Gyr [ -00001.89, 00001.95, -00003.05 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007110 NoMotion-1. Scaled. Acc [ -00037.60, -00012.21, 00998.05 ], Gyr [ 00002.01, -00000.85, -00000.49 ], Mag [ 00018.75, 00084.30, 00107.55 ]",
    "#0000007111 NoMotion-1. Scaled. Acc [ -00046.39, -00011.72, 01001.95 ], Gyr [ 00000.91, -00001.16, 00000.24 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007112 NoMotion-1. Scaled. Acc [ -00043.46, -00009.28, 00985.35 ], Gyr [ 00001.71, 00001.10, -00002.20 ], Mag [ 00020.40, 00085.20, 00107.40 ]",
    "#0000007113 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00994.14 ], Gyr [ -00000.67, -00001.22, -00000.43 ], Mag [ 00018.75, 00083.55, 00105.75 ]",
    "#0000007114 NoMotion-1. Scaled. Acc [ -00032.71, -00006.35, 00988.28 ], Gyr [ 00001.10, 00002.93, -00002.32 ], Mag [ 00018.75, 00083.55, 00105.75 ]",
    "#0000007115 NoMotion-1. Scaled. Acc [ -00029.30, -00021.48, 00977.05 ], Gyr [ 00001.59, -00001.46, -00001.59 ], Mag [ 00020.40, 00085.20, 00106.65 ]",
    "#0000007116 NoMotion-1. Scaled. Acc [ -00043.95, -00009.28, 00990.72 ], Gyr [ 00000.43, 00002.68, 00000.61 ], Mag [ 00020.40, 00085.20, 00106.65 ]",
    "#0000007117 NoMotion-1. Scaled. Acc [ -00035.64, -00011.23, 00985.35 ], Gyr [ -00000.24, 00002.68, -00002.93 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000007118 NoMotion-1. Scaled. Acc [ -00045.41, 00000.98, 00982.42 ], Gyr [ 00000.85, -00000.67, -00001.59 ], Mag [ 00019.20, 00084.00, 00107.10 ]",
    "#0000007119 NoMotion-1. Scaled. Acc [ -00049.32, -00003.42, 00986.82 ], Gyr [ 00000.91, -00000.43, -00001.10 ], Mag [ 00020.10, 00084.45, 00106.65 ]",
    "#0000007120 NoMotion-1. Scaled. Acc [ -00045.90, -00017.09, 00995.61 ], Gyr [ -00000.06, 00002.01, -00002.20 ], Mag [ 00020.10, 00084.45, 00106.65 ]",
    "#0000007121 NoMotion-1. Scaled. Acc [ -00051.27, -00004.39, 00999.51 ], Gyr [ -00001.22, -00000.91, -00000.37 ], Mag [ 00020.85, 00082.95, 00107.40 ]",
    "#0000007122 NoMotion-1. Scaled. Acc [ -00038.57, -00005.86, 00986.82 ], Gyr [ 00002.74, 00000.91, -00001.59 ], Mag [ 00020.85, 00082.95, 00107.40 ]",
    "#0000007123 NoMotion-1. Scaled. Acc [ -00047.85, -00010.74, 00998.05 ], Gyr [ -00000.30, 00000.55, 00000.30 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007124 NoMotion-1. Scaled. Acc [ -00048.83, -00017.58, 00998.54 ], Gyr [ 00001.89, 00000.00, 00001.46 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007125 NoMotion-1. Scaled. Acc [ -00042.97, -00009.28, 01006.84 ], Gyr [ -00001.52, 00002.01, -00002.93 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007126 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 01000.49 ], Gyr [ 00001.34, 00000.37, -00001.04 ], Mag [ 00021.00, 00084.00, 00107.85 ]",
    "#0000007127 NoMotion-1. Scaled. Acc [ -00036.62, -00008.79, 00991.21 ], Gyr [ 00000.30, 00000.55, -00001.40 ], Mag [ 00020.10, 00085.20, 00108.00 ]",
    "#0000007128 NoMotion-1. Scaled. Acc [ -00040.04, -00013.18, 00991.21 ], Gyr [ -00000.49, 00001.71, -00000.55 ], Mag [ 00020.10, 00085.20, 00108.00 ]",
    "#0000007129 NoMotion-1. Scaled. Acc [ -00035.64, -00006.84, 00987.79 ], Gyr [ 00000.06, 00000.06, -00002.20 ], Mag [ 00018.60, 00083.40, 00109.05 ]",
    "#0000007130 NoMotion-1. Scaled. Acc [ -00030.27, 00000.98, 00990.72 ], Gyr [ 00001.40, 00000.55, -00002.13 ], Mag [ 00018.60, 00083.40, 00109.05 ]",
    "#0000007131 NoMotion-1. Scaled. Acc [ -00038.09, -00022.46, 00991.21 ], Gyr [ 00001.52, 00003.05, -00002.38 ], Mag [ 00020.10, 00085.20, 00108.45 ]",
    "#0000007132 NoMotion-1. Scaled. Acc [ -00040.53, -00002.44, 00990.72 ], Gyr [ 00000.85, 00001.65, -00002.38 ], Mag [ 00020.10, 00085.20, 00108.45 ]",
    "#0000007133 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.28 ], Gyr [ -00000.91, 00002.01, 00000.12 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007134 NoMotion-1. Scaled. Acc [ -00048.34, 00001.46, 01001.46 ], Gyr [ 00001.16, 00001.22, -00002.68 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007135 NoMotion-1. Scaled. Acc [ -00038.09, -00010.25, 00993.16 ], Gyr [ 00002.26, 00002.87, -00000.55 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007136 NoMotion-1. Scaled. Acc [ -00047.85, -00017.58, 00989.75 ], Gyr [ -00000.06, -00001.34, -00000.91 ], Mag [ 00019.20, 00083.55, 00108.90 ]",
    "#0000007137 NoMotion-1. Scaled. Acc [ -00047.36, -00000.49, 00988.77 ], Gyr [ 00002.01, 00001.65, -00000.98 ], Mag [ 00020.70, 00084.30, 00106.80 ]",
    "#0000007138 NoMotion-1. Scaled. Acc [ -00047.85, -00015.62, 00991.21 ], Gyr [ 00002.20, 00000.98, -00002.44 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007139 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 01001.95 ], Gyr [ -00001.16, 00000.55, -00002.50 ], Mag [ 00019.95, 00084.75, 00108.30 ]",
    "#0000007140 NoMotion-1. Scaled. Acc [ -00040.53, -00003.91, 00990.23 ], Gyr [ 00000.91, 00000.98, -00001.65 ], Mag [ 00018.75, 00085.80, 00108.30 ]",
    "#0000007141 NoMotion-1. Scaled. Acc [ -00043.46, -00005.86, 00996.09 ], Gyr [ 00000.91, 00002.62, -00002.44 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007142 NoMotion-1. Scaled. Acc [ -00055.18, -00003.91, 00994.14 ], Gyr [ 00000.98, 00000.98, 00000.24 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007143 NoMotion-1. Scaled. Acc [ -00048.83, -00005.86, 00994.14 ], Gyr [ 00000.91, -00001.04, -00001.34 ], Mag [ 00020.85, 00083.70, 00109.50 ]",
    "#0000007144 NoMotion-1. Scaled. Acc [ -00045.41, -00012.70, 00995.61 ], Gyr [ -00000.67, 00001.34, -00002.01 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007145 NoMotion-1. Scaled. Acc [ -00052.25, -00005.86, 00986.33 ], Gyr [ 00000.79, 00001.95, -00000.61 ], Mag [ 00019.20, 00085.05, 00107.10 ]",
    "#0000007146 NoMotion-1. Scaled. Acc [ -00041.99, -00000.49, 00981.45 ], Gyr [ -00000.06, 00000.43, -00000.55 ], Mag [ 00020.40, 00083.70, 00107.70 ]",
    "#0000007147 NoMotion-1. Scaled. Acc [ -00045.41, -00009.28, 00983.89 ], Gyr [ 00002.87, 00001.34, -00003.05 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007148 NoMotion-1. Scaled. Acc [ -00039.06, -00004.88, 01006.35 ], Gyr [ 00001.59, 00000.49, 00000.43 ], Mag [ 00019.20, 00082.80, 00107.55 ]",
    "#0000007149 NoMotion-1. Scaled. Acc [ -00038.57, -00020.02, 00985.84 ], Gyr [ 00000.12, -00001.83, 00000.85 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007150 NoMotion-1. Scaled. Acc [ -00038.57, 00000.98, 00980.47 ], Gyr [ -00001.28, 00000.79, -00000.79 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007151 NoMotion-1. Scaled. Acc [ -00055.18, -00007.32, 00992.19 ], Gyr [ 00000.06, 00000.61, -00002.93 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007152 NoMotion-1. Scaled. Acc [ -00041.99, -00000.98, 01000.00 ], Gyr [ 00000.18, 00003.96, -00002.07 ], Mag [ 00020.10, 00083.70, 00108.45 ]",
    "#0000007153 NoMotion-1. Scaled. Acc [ -00049.32, -00008.30, 00989.75 ], Gyr [ 00002.01, 00000.43, -00001.10 ], Mag [ 00019.50, 00083.25, 00108.60 ]",
    "#0000007154 NoMotion-1. Scaled. Acc [ -00050.29, -00013.18, 00989.75 ], Gyr [ -00000.18, 00001.04, -00002.68 ], Mag [ 00019.05, 00085.20, 00107.40 ]",
    "#0000007155 NoMotion-1. Scaled. Acc [ -00048.83, -00007.32, 00988.77 ], Gyr [ 00001.04, 00000.79, -00002.13 ], Mag [ 00019.05, 00085.20, 00107.40 ]",
    "#0000007156 NoMotion-1. Scaled. Acc [ -00044.43, -00004.88, 01000.00 ], Gyr [ 00001.16, 00001.89, 00000.85 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000007157 NoMotion-1. Scaled. Acc [ -00043.95, 00010.74, 00991.21 ], Gyr [ 00000.30, 00001.10, -00002.38 ], Mag [ 00019.95, 00083.55, 00108.30 ]",
    "#0000007158 NoMotion-1. Scaled. Acc [ -00045.90, -00002.44, 00995.12 ], Gyr [ -00001.65, 00002.13, -00001.52 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007159 NoMotion-1. Scaled. Acc [ -00029.30, -00009.28, 00986.82 ], Gyr [ 00000.30, 00005.06, -00002.07 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007160 NoMotion-1. Scaled. Acc [ -00037.60, -00016.11, 01002.93 ], Gyr [ 00001.22, -00000.18, -00001.89 ], Mag [ 00020.10, 00084.90, 00107.40 ]",
    "#0000007161 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00992.68 ], Gyr [ 00001.04, 00003.66, -00000.18 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000007162 NoMotion-1. Scaled. Acc [ -00046.39, -00006.35, 00999.51 ], Gyr [ 00000.06, 00001.77, -00000.43 ], Mag [ 00018.30, 00083.70, 00108.00 ]",
    "#0000007163 NoMotion-1. Scaled. Acc [ -00046.39, 00000.98, 00984.38 ], Gyr [ -00001.71, 00001.16, -00000.73 ], Mag [ 00019.35, 00085.65, 00108.45 ]",
    "#0000007164 NoMotion-1. Scaled. Acc [ -00050.29, -00011.23, 00989.26 ], Gyr [ 00000.55, 00002.56, -00001.71 ], Mag [ 00019.35, 00085.65, 00108.45 ]",
    "#0000007165 NoMotion-1. Scaled. Acc [ -00029.30, -00013.18, 00993.65 ], Gyr [ 00001.04, 00001.46, -00003.48 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000007166 NoMotion-1. Scaled. Acc [ -00034.67, 00004.88, 00987.30 ], Gyr [ -00000.24, -00000.98, -00001.10 ], Mag [ 00019.20, 00084.75, 00106.80 ]",
    "#0000007167 NoMotion-1. Scaled. Acc [ -00037.60, -00001.46, 00992.19 ], Gyr [ -00000.73, 00001.04, -00000.30 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007168 NoMotion-1. Scaled. Acc [ -00040.53, -00007.32, 00991.70 ], Gyr [ -00000.85, 00001.77, -00001.22 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007169 NoMotion-1. Scaled. Acc [ -00052.73, -00017.58, 00990.72 ], Gyr [ 00000.12, 00001.52, -00001.16 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007170 NoMotion-1. Scaled. Acc [ -00042.97, -00006.84, 00989.26 ], Gyr [ 00001.04, 00000.49, 00000.12 ], Mag [ 00019.95, 00084.00, 00107.55 ]",
    "#0000007171 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 00990.72 ], Gyr [ 00001.77, 00000.79, -00001.22 ], Mag [ 00018.60, 00082.65, 00107.70 ]",
    "#0000007172 NoMotion-1. Scaled. Acc [ -00051.76, -00006.35, 00993.16 ], Gyr [ -00000.24, 00003.66, 00000.00 ], Mag [ 00018.60, 00082.65, 00107.70 ]",
    "#0000007173 NoMotion-1. Scaled. Acc [ -00031.25, -00001.46, 00986.82 ], Gyr [ 00000.67, 00000.98, -00002.68 ], Mag [ 00019.35, 00082.65, 00107.70 ]",
    "#0000007174 NoMotion-1. Scaled. Acc [ -00037.11, -00002.44, 00996.58 ], Gyr [ -00001.52, 00002.26, -00000.79 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007175 NoMotion-1. Scaled. Acc [ -00041.02, 00002.44, 00994.63 ], Gyr [ -00001.10, 00005.79, -00003.48 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007176 NoMotion-1. Scaled. Acc [ -00047.85, -00003.42, 00988.28 ], Gyr [ 00000.98, 00002.74, -00002.62 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007177 NoMotion-1. Scaled. Acc [ -00051.76, -00006.84, 00981.93 ], Gyr [ 00002.26, 00002.20, -00001.34 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007178 NoMotion-1. Scaled. Acc [ -00035.64, -00012.70, 00985.84 ], Gyr [ -00000.91, 00003.96, -00003.78 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007179 NoMotion-1. Scaled. Acc [ -00043.46, -00009.77, 00990.72 ], Gyr [ 00001.77, 00001.10, -00000.79 ], Mag [ 00019.35, 00082.95, 00108.45 ]",
    "#0000007180 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00998.05 ], Gyr [ 00000.06, 00002.32, -00001.77 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007181 NoMotion-1. Scaled. Acc [ -00041.50, -00007.32, 00992.68 ], Gyr [ 00000.00, -00001.40, -00000.18 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007182 NoMotion-1. Scaled. Acc [ -00036.13, -00009.28, 00979.98 ], Gyr [ 00000.73, 00000.55, -00000.12 ], Mag [ 00020.40, 00082.95, 00106.65 ]",
    "#0000007183 NoMotion-1. Scaled. Acc [ -00041.50, -00000.98, 00980.96 ], Gyr [ 00000.85, 00001.04, -00001.46 ], Mag [ 00020.40, 00082.95, 00106.65 ]",
    "#0000007184 NoMotion-1. Scaled. Acc [ -00045.90, -00015.14, 00989.75 ], Gyr [ 00002.99, 00001.52, -00002.99 ], Mag [ 00017.70, 00083.25, 00107.85 ]",
    "#0000007185 NoMotion-1. Scaled. Acc [ -00043.95, 00002.93, 00996.09 ], Gyr [ 00000.61, 00000.67, -00001.40 ], Mag [ 00017.70, 00083.25, 00107.85 ]",
    "#0000007186 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 00974.12 ], Gyr [ -00001.46, -00000.98, -00002.13 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007187 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 00983.89 ], Gyr [ -00000.85, 00000.30, -00003.17 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007188 NoMotion-1. Scaled. Acc [ -00047.85, -00004.88, 00982.91 ], Gyr [ 00000.06, -00000.91, -00001.28 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007189 NoMotion-1. Scaled. Acc [ -00030.76, -00001.46, 00995.61 ], Gyr [ 00001.40, -00000.12, -00000.49 ], Mag [ 00019.95, 00084.00, 00107.10 ]",
    "#0000007190 NoMotion-1. Scaled. Acc [ -00040.53, -00020.02, 00991.70 ], Gyr [ -00000.67, 00001.34, -00001.71 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000007191 NoMotion-1. Scaled. Acc [ -00047.36, -00010.74, 00988.28 ], Gyr [ -00000.49, 00004.33, -00002.99 ], Mag [ 00018.60, 00084.45, 00107.40 ]",
    "#0000007192 NoMotion-1. Scaled. Acc [ -00045.41, -00003.91, 00989.26 ], Gyr [ -00000.49, 00000.43, -00000.55 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007193 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00983.89 ], Gyr [ 00001.28, 00003.84, -00004.09 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007194 NoMotion-1. Scaled. Acc [ -00042.97, -00007.32, 00993.16 ], Gyr [ -00000.85, 00002.07, -00003.17 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007195 NoMotion-1. Scaled. Acc [ -00042.97, -00010.74, 00996.09 ], Gyr [ 00000.98, 00001.83, -00001.59 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007196 NoMotion-1. Scaled. Acc [ -00040.53, 00002.44, 00997.56 ], Gyr [ 00001.04, 00001.52, -00001.71 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007197 NoMotion-1. Scaled. Acc [ -00043.95, -00003.91, 00987.30 ], Gyr [ 00000.55, 00000.30, -00002.20 ], Mag [ 00019.65, 00084.90, 00106.95 ]",
    "#0000007198 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 00984.86 ], Gyr [ 00002.50, 00000.91, -00002.01 ], Mag [ 00018.45, 00083.55, 00106.05 ]",
    "#0000007199 NoMotion-1. Scaled. Acc [ -00034.18, -00012.70, 00981.45 ], Gyr [ 00000.73, 00006.59, -00001.83 ], Mag [ 00018.45, 00083.55, 00106.05 ]",
    "#0000007200 NoMotion-1. Scaled. Acc [ -00046.88, -00008.30, 00979.49 ], Gyr [ 00000.12, 00003.05, -00001.89 ], Mag [ 00019.95, 00084.00, 00106.80 ]",
    "#0000007201 NoMotion-1. Scaled. Acc [ -00046.88, -00015.62, 00984.86 ], Gyr [ 00000.61, 00000.98, -00002.87 ], Mag [ 00019.95, 00084.00, 00106.80 ]",
    "#0000007202 NoMotion-1. Scaled. Acc [ -00040.04, -00011.23, 01002.93 ], Gyr [ 00002.44, 00000.00, -00000.43 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000007203 NoMotion-1. Scaled. Acc [ -00048.83, -00015.14, 01006.84 ], Gyr [ 00000.06, 00002.93, -00003.23 ], Mag [ 00018.15, 00084.00, 00107.10 ]",
    "#0000007204 NoMotion-1. Scaled. Acc [ -00045.90, -00008.79, 00985.84 ], Gyr [ -00000.18, 00004.82, -00002.20 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007205 NoMotion-1. Scaled. Acc [ -00044.43, -00005.37, 00986.82 ], Gyr [ -00000.30, -00000.24, -00000.18 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007206 NoMotion-1. Scaled. Acc [ -00042.97, -00000.49, 00989.75 ], Gyr [ -00000.24, 00002.99, -00001.28 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007207 NoMotion-1. Scaled. Acc [ -00052.25, -00009.77, 00997.07 ], Gyr [ 00000.30, 00002.56, -00002.07 ], Mag [ 00020.40, 00083.70, 00106.65 ]",
    "#0000007208 NoMotion-1. Scaled. Acc [ -00039.06, -00008.30, 00995.61 ], Gyr [ -00001.22, 00002.07, -00002.56 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007209 NoMotion-1. Scaled. Acc [ -00037.11, -00006.84, 00984.38 ], Gyr [ 00000.00, 00000.00, -00000.85 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007210 NoMotion-1. Scaled. Acc [ -00045.41, -00011.23, 00983.40 ], Gyr [ 00000.73, -00000.79, -00001.22 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007211 NoMotion-1. Scaled. Acc [ -00043.95, 00004.88, 00986.33 ], Gyr [ 00000.67, 00002.20, -00000.18 ], Mag [ 00019.65, 00083.70, 00108.75 ]",
    "#0000007212 NoMotion-1. Scaled. Acc [ -00039.55, -00014.65, 00988.77 ], Gyr [ 00001.40, 00000.30, 00000.06 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007213 NoMotion-1. Scaled. Acc [ -00029.79, -00002.44, 00991.70 ], Gyr [ -00001.34, 00002.44, -00001.04 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007214 NoMotion-1. Scaled. Acc [ -00038.57, -00006.84, 00998.54 ], Gyr [ -00000.24, 00001.28, -00001.16 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007215 NoMotion-1. Scaled. Acc [ -00038.09, -00021.48, 00994.63 ], Gyr [ -00001.46, 00000.37, -00002.80 ], Mag [ 00019.20, 00083.55, 00106.50 ]",
    "#0000007216 NoMotion-1. Scaled. Acc [ -00045.90, -00012.21, 00990.72 ], Gyr [ 00001.71, 00003.05, -00003.23 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007217 NoMotion-1. Scaled. Acc [ -00045.41, -00004.39, 00989.26 ], Gyr [ 00000.73, 00002.20, -00000.79 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007218 NoMotion-1. Scaled. Acc [ -00045.90, -00006.84, 00994.63 ], Gyr [ 00000.98, 00000.98, -00003.23 ], Mag [ 00019.65, 00084.45, 00107.40 ]",
    "#0000007219 NoMotion-1. Scaled. Acc [ -00050.29, -00002.44, 00997.56 ], Gyr [ 00000.98, 00003.17, -00002.20 ], Mag [ 00020.25, 00084.75, 00106.80 ]",
    "#0000007220 NoMotion-1. Scaled. Acc [ -00048.34, -00003.42, 00996.58 ], Gyr [ 00001.52, -00000.61, -00000.98 ], Mag [ 00020.25, 00084.75, 00106.80 ]",
    "#0000007221 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 01001.46 ], Gyr [ 00001.28, 00001.89, 00001.77 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007222 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00999.51 ], Gyr [ -00000.37, 00001.83, -00001.89 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007223 NoMotion-1. Scaled. Acc [ -00025.88, -00013.67, 00986.33 ], Gyr [ 00000.98, -00000.49, -00000.12 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007224 NoMotion-1. Scaled. Acc [ -00044.92, -00009.77, 00993.16 ], Gyr [ 00001.22, 00000.37, -00000.12 ], Mag [ 00017.85, 00084.90, 00108.00 ]",
    "#0000007225 NoMotion-1. Scaled. Acc [ -00035.64, -00011.72, 00981.45 ], Gyr [ 00001.16, 00002.56, -00001.89 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007226 NoMotion-1. Scaled. Acc [ -00039.06, -00006.84, 00988.28 ], Gyr [ -00001.22, 00003.29, -00001.77 ], Mag [ 00020.25, 00083.55, 00107.85 ]",
    "#0000007227 NoMotion-1. Scaled. Acc [ -00039.55, -00006.84, 00989.26 ], Gyr [ 00000.18, 00003.35, 00000.00 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000007228 NoMotion-1. Scaled. Acc [ -00052.25, -00013.67, 00993.16 ], Gyr [ -00000.18, 00003.84, -00000.37 ], Mag [ 00018.60, 00084.45, 00106.65 ]",
    "#0000007229 NoMotion-1. Scaled. Acc [ -00048.34, -00021.97, 00984.38 ], Gyr [ -00001.10, 00001.65, 00000.00 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007230 NoMotion-1. Scaled. Acc [ -00041.50, -00005.86, 00998.05 ], Gyr [ 00001.40, 00001.83, -00002.32 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007231 NoMotion-1. Scaled. Acc [ -00037.60, 00000.98, 00995.12 ], Gyr [ 00001.28, -00000.98, -00001.89 ], Mag [ 00020.10, 00084.15, 00107.40 ]",
    "#0000007232 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00989.75 ], Gyr [ -00001.04, 00003.41, -00001.95 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007233 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 00984.38 ], Gyr [ 00000.18, 00002.68, -00002.44 ], Mag [ 00020.40, 00084.15, 00107.40 ]",
    "#0000007234 NoMotion-1. Scaled. Acc [ -00036.62, -00009.77, 00990.72 ], Gyr [ 00002.44, -00002.07, 00000.79 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000007235 NoMotion-1. Scaled. Acc [ -00041.02, 00004.88, 00993.65 ], Gyr [ 00001.52, 00001.10, -00000.61 ], Mag [ 00020.10, 00083.70, 00108.00 ]",
    "#0000007236 NoMotion-1. Scaled. Acc [ -00047.36, -00005.37, 00986.33 ], Gyr [ -00003.23, 00002.38, -00002.80 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007237 NoMotion-1. Scaled. Acc [ -00044.92, 00003.91, 00994.63 ], Gyr [ -00000.18, 00001.89, -00000.12 ], Mag [ 00020.10, 00083.40, 00107.70 ]",
    "#0000007238 NoMotion-1. Scaled. Acc [ -00031.74, -00003.42, 00988.28 ], Gyr [ 00000.49, 00000.73, -00001.46 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007239 NoMotion-1. Scaled. Acc [ -00049.80, -00015.14, 01000.49 ], Gyr [ 00000.55, 00000.85, -00003.35 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007240 NoMotion-1. Scaled. Acc [ -00041.99, -00001.46, 00984.38 ], Gyr [ -00001.28, 00001.34, -00000.37 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007241 NoMotion-1. Scaled. Acc [ -00050.29, -00003.42, 00979.49 ], Gyr [ -00000.98, 00003.78, -00001.59 ], Mag [ 00019.35, 00084.45, 00106.20 ]",
    "#0000007242 NoMotion-1. Scaled. Acc [ -00037.11, -00000.49, 00995.12 ], Gyr [ -00002.80, 00001.65, -00003.23 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000007243 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00989.75 ], Gyr [ -00000.12, 00000.49, -00001.22 ], Mag [ 00020.70, 00084.00, 00107.10 ]",
    "#0000007244 NoMotion-1. Scaled. Acc [ -00051.27, -00010.25, 00986.82 ], Gyr [ 00000.49, -00000.91, -00001.46 ], Mag [ 00020.25, 00083.25, 00106.50 ]",
    "#0000007245 NoMotion-1. Scaled. Acc [ -00026.86, -00023.44, 00990.72 ], Gyr [ 00000.73, -00000.24, -00000.98 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007246 NoMotion-1. Scaled. Acc [ -00057.62, -00007.32, 00994.63 ], Gyr [ 00001.40, 00001.28, -00000.49 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007247 NoMotion-1. Scaled. Acc [ -00055.66, 00006.35, 00994.14 ], Gyr [ 00000.00, 00001.04, -00000.12 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007248 NoMotion-1. Scaled. Acc [ -00048.83, -00005.37, 00991.21 ], Gyr [ 00000.37, 00000.85, -00002.50 ], Mag [ 00020.25, 00083.25, 00106.80 ]",
    "#0000007249 NoMotion-1. Scaled. Acc [ -00042.97, -00005.86, 00998.54 ], Gyr [ 00001.52, 00001.46, -00001.65 ], Mag [ 00020.40, 00082.65, 00108.45 ]",
    "#0000007250 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00987.30 ], Gyr [ -00001.46, 00002.62, -00004.02 ], Mag [ 00020.40, 00082.65, 00108.45 ]",
    "#0000007251 NoMotion-1. Scaled. Acc [ -00039.55, -00004.39, 00993.16 ], Gyr [ -00000.91, 00002.50, -00001.40 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007252 NoMotion-1. Scaled. Acc [ -00040.04, -00007.32, 00985.84 ], Gyr [ 00002.13, 00000.73, -00003.23 ], Mag [ 00019.35, 00084.15, 00107.70 ]",
    "#0000007253 NoMotion-1. Scaled. Acc [ -00032.71, 00007.32, 00992.68 ], Gyr [ -00000.06, 00000.55, -00000.91 ], Mag [ 00018.30, 00083.70, 00107.70 ]",
    "#0000007254 NoMotion-1. Scaled. Acc [ -00046.39, -00019.04, 01003.91 ], Gyr [ 00000.73, 00001.46, 00002.01 ], Mag [ 00018.30, 00083.70, 00107.70 ]",
    "#0000007255 NoMotion-1. Scaled. Acc [ -00042.48, 00001.95, 00979.98 ], Gyr [ -00000.98, 00001.52, -00000.61 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007256 NoMotion-1. Scaled. Acc [ -00048.83, -00017.58, 00992.19 ], Gyr [ -00001.34, 00002.62, -00003.11 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007257 NoMotion-1. Scaled. Acc [ -00035.64, -00004.39, 00998.54 ], Gyr [ 00000.85, 00001.40, -00000.61 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007258 NoMotion-1. Scaled. Acc [ -00040.53, -00017.58, 00995.61 ], Gyr [ 00003.17, 00002.80, 00000.85 ], Mag [ 00020.25, 00084.00, 00108.90 ]",
    "#0000007259 NoMotion-1. Scaled. Acc [ -00032.71, 00000.00, 00989.26 ], Gyr [ 00001.22, 00001.28, -00000.12 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000007260 NoMotion-1. Scaled. Acc [ -00045.90, -00013.67, 01005.37 ], Gyr [ 00000.06, -00002.07, 00000.06 ], Mag [ 00020.40, 00082.65, 00107.70 ]",
    "#0000007261 NoMotion-1. Scaled. Acc [ -00041.99, 00003.91, 00994.14 ], Gyr [ 00001.10, 00000.85, -00000.37 ], Mag [ 00020.85, 00082.20, 00108.00 ]",
    "#0000007262 NoMotion-1. Scaled. Acc [ -00053.22, -00008.79, 00993.65 ], Gyr [ -00000.06, 00000.00, -00000.55 ], Mag [ 00020.85, 00082.20, 00108.00 ]",
    "#0000007263 NoMotion-1. Scaled. Acc [ -00043.46, -00001.95, 00993.16 ], Gyr [ -00002.07, 00000.37, -00003.90 ], Mag [ 00020.10, 00084.15, 00106.95 ]",
    "#0000007264 NoMotion-1. Scaled. Acc [ -00043.95, 00005.37, 00984.86 ], Gyr [ -00000.12, 00000.91, -00004.02 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007265 NoMotion-1. Scaled. Acc [ -00043.95, 00002.44, 00983.40 ], Gyr [ 00000.85, 00001.89, -00003.41 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007266 NoMotion-1. Scaled. Acc [ -00043.46, -00013.67, 00978.52 ], Gyr [ -00000.49, 00001.10, -00002.80 ], Mag [ 00020.40, 00083.70, 00108.45 ]",
    "#0000007267 NoMotion-1. Scaled. Acc [ -00033.69, -00000.98, 00990.23 ], Gyr [ 00000.00, -00000.98, -00002.50 ], Mag [ 00019.35, 00084.15, 00108.45 ]",
    "#0000007268 NoMotion-1. Scaled. Acc [ -00031.25, -00011.23, 00993.65 ], Gyr [ 00000.12, 00003.54, -00001.89 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007269 NoMotion-1. Scaled. Acc [ -00042.97, -00005.37, 00990.72 ], Gyr [ 00000.30, -00000.55, 00000.12 ], Mag [ 00019.35, 00084.15, 00106.95 ]",
    "#0000007270 NoMotion-1. Scaled. Acc [ -00034.18, -00009.77, 00977.54 ], Gyr [ 00000.30, 00000.67, -00002.20 ], Mag [ 00019.50, 00084.00, 00108.30 ]",
    "#0000007271 NoMotion-1. Scaled. Acc [ -00037.11, -00006.35, 00998.54 ], Gyr [ -00000.24, 00001.77, -00001.34 ], Mag [ 00021.90, 00084.15, 00108.75 ]",
    "#0000007272 NoMotion-1. Scaled. Acc [ -00043.95, -00007.32, 00997.56 ], Gyr [ 00000.06, 00000.85, -00001.65 ], Mag [ 00021.90, 00084.15, 00108.75 ]",
    "#0000007273 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00992.19 ], Gyr [ 00001.10, 00000.12, -00000.49 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007274 NoMotion-1. Scaled. Acc [ -00025.88, 00006.84, 00979.49 ], Gyr [ -00000.12, 00002.50, -00001.59 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007275 NoMotion-1. Scaled. Acc [ -00031.25, -00001.46, 00996.09 ], Gyr [ 00000.61, 00001.59, -00002.07 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007276 NoMotion-1. Scaled. Acc [ -00048.34, -00015.14, 00977.54 ], Gyr [ -00000.37, 00005.49, -00002.26 ], Mag [ 00021.00, 00083.25, 00107.10 ]",
    "#0000007277 NoMotion-1. Scaled. Acc [ -00037.60, -00007.32, 00994.63 ], Gyr [ 00000.06, 00002.74, -00002.44 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007278 NoMotion-1. Scaled. Acc [ -00059.08, -00004.39, 00984.38 ], Gyr [ 00000.79, 00001.83, -00003.23 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007279 NoMotion-1. Scaled. Acc [ -00046.88, -00009.28, 00991.70 ], Gyr [ -00000.55, 00002.68, -00004.27 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007280 NoMotion-1. Scaled. Acc [ -00046.39, -00009.77, 00986.33 ], Gyr [ -00000.30, 00001.89, -00001.10 ], Mag [ 00019.95, 00082.05, 00107.10 ]",
    "#0000007281 NoMotion-1. Scaled. Acc [ -00043.95, -00012.70, 00987.79 ], Gyr [ 00000.91, 00003.05, -00001.95 ], Mag [ 00021.15, 00083.70, 00108.45 ]",
    "#0000007282 NoMotion-1. Scaled. Acc [ -00051.76, -00009.77, 00998.54 ], Gyr [ -00001.65, 00003.66, -00001.16 ], Mag [ 00021.15, 00083.70, 00108.45 ]",
    "#0000007283 NoMotion-1. Scaled. Acc [ -00053.22, -00000.98, 00994.14 ], Gyr [ 00001.89, 00001.71, -00001.77 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007284 NoMotion-1. Scaled. Acc [ -00043.95, -00008.30, 00998.54 ], Gyr [ -00000.55, -00000.37, 00000.30 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007285 NoMotion-1. Scaled. Acc [ -00038.09, -00004.88, 01001.95 ], Gyr [ 00001.04, 00001.22, -00000.91 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007286 NoMotion-1. Scaled. Acc [ -00036.62, -00012.21, 00992.19 ], Gyr [ 00001.16, 00001.10, -00001.46 ], Mag [ 00019.95, 00083.55, 00106.05 ]",
    "#0000007287 NoMotion-1. Scaled. Acc [ -00041.99, 00000.98, 00994.63 ], Gyr [ -00000.24, 00001.34, -00000.43 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007288 NoMotion-1. Scaled. Acc [ -00051.27, -00005.86, 00987.79 ], Gyr [ 00001.34, 00000.55, -00002.32 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007289 NoMotion-1. Scaled. Acc [ -00056.15, -00016.60, 00981.93 ], Gyr [ 00000.37, 00002.26, -00001.40 ], Mag [ 00019.50, 00084.00, 00107.85 ]",
    "#0000007290 NoMotion-1. Scaled. Acc [ -00042.48, -00016.11, 01000.00 ], Gyr [ 00000.55, 00001.71, -00001.52 ], Mag [ 00020.10, 00084.00, 00107.85 ]",
    "#0000007291 NoMotion-1. Scaled. Acc [ -00045.90, -00012.70, 00989.75 ], Gyr [ -00000.06, 00005.37, 00000.24 ], Mag [ 00020.10, 00085.65, 00108.00 ]",
    "#0000007292 NoMotion-1. Scaled. Acc [ -00045.41, -00006.84, 00999.51 ], Gyr [ 00001.04, 00001.28, -00003.35 ], Mag [ 00021.00, 00084.00, 00108.00 ]",
    "#0000007293 NoMotion-1. Scaled. Acc [ -00050.78, -00008.79, 01000.00 ], Gyr [ 00000.37, 00003.84, -00003.23 ], Mag [ 00021.00, 00084.00, 00107.55 ]",
    "#0000007294 NoMotion-1. Scaled. Acc [ -00040.04, 00002.93, 00976.07 ], Gyr [ -00001.83, 00004.82, -00001.65 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007295 NoMotion-1. Scaled. Acc [ -00042.48, -00011.72, 00990.72 ], Gyr [ -00000.24, 00000.30, -00002.56 ], Mag [ 00019.35, 00082.95, 00108.00 ]",
    "#0000007296 NoMotion-1. Scaled. Acc [ -00041.02, 00000.00, 00980.47 ], Gyr [ 00001.77, -00000.55, -00001.59 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007297 NoMotion-1. Scaled. Acc [ -00044.43, -00002.93, 00988.28 ], Gyr [ 00002.68, 00001.28, -00000.91 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007298 NoMotion-1. Scaled. Acc [ -00050.78, -00007.32, 00990.23 ], Gyr [ 00000.73, 00000.55, -00002.32 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007299 NoMotion-1. Scaled. Acc [ -00037.60, -00004.88, 00988.77 ], Gyr [ -00000.61, 00002.26, -00000.49 ], Mag [ 00019.35, 00083.70, 00108.45 ]",
    "#0000007300 NoMotion-1. Scaled. Acc [ -00044.43, 00003.91, 00992.68 ], Gyr [ 00001.46, -00000.37, -00002.74 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007301 NoMotion-1. Scaled. Acc [ -00038.57, -00003.91, 00991.21 ], Gyr [ -00000.85, -00001.52, 00000.98 ], Mag [ 00020.40, 00082.95, 00107.40 ]",
    "#0000007302 NoMotion-1. Scaled. Acc [ -00034.18, -00008.79, 00990.72 ], Gyr [ -00002.20, 00001.65, -00003.23 ], Mag [ 00019.50, 00083.25, 00108.30 ]",
    "#0000007303 NoMotion-1. Scaled. Acc [ -00041.50, -00016.60, 00988.77 ], Gyr [ 00000.61, 00001.59, 00000.67 ], Mag [ 00019.05, 00082.65, 00108.45 ]",
    "#0000007304 NoMotion-1. Scaled. Acc [ -00036.13, 00000.98, 00987.79 ], Gyr [ 00000.49, 00003.35, -00000.49 ], Mag [ 00019.05, 00082.65, 00108.45 ]",
    "#0000007305 NoMotion-1. Scaled. Acc [ -00050.78, -00018.07, 00996.58 ], Gyr [ -00001.77, 00001.71, -00001.52 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007306 NoMotion-1. Scaled. Acc [ -00038.57, -00016.11, 00991.21 ], Gyr [ -00000.73, 00000.67, -00002.32 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007307 NoMotion-1. Scaled. Acc [ -00028.32, 00001.46, 00995.12 ], Gyr [ 00001.65, 00001.22, -00001.04 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007308 NoMotion-1. Scaled. Acc [ -00038.57, -00006.84, 00985.84 ], Gyr [ -00001.52, -00002.32, -00001.83 ], Mag [ 00019.50, 00084.00, 00107.10 ]",
    "#0000007309 NoMotion-1. Scaled. Acc [ -00034.18, 00001.46, 00986.82 ], Gyr [ -00001.95, -00002.44, -00001.10 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007310 NoMotion-1. Scaled. Acc [ -00043.95, -00000.49, 00987.30 ], Gyr [ 00000.55, 00001.52, -00001.71 ], Mag [ 00019.05, 00083.70, 00107.40 ]",
    "#0000007311 NoMotion-1. Scaled. Acc [ -00041.02, -00008.79, 00987.79 ], Gyr [ -00000.06, 00000.00, -00001.34 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007312 NoMotion-1. Scaled. Acc [ -00053.22, -00013.67, 00984.86 ], Gyr [ 00001.34, 00001.34, -00003.05 ], Mag [ 00019.05, 00083.70, 00107.70 ]",
    "#0000007313 NoMotion-1. Scaled. Acc [ -00041.50, -00020.51, 00990.72 ], Gyr [ 00003.05, 00002.26, 00000.67 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007314 NoMotion-1. Scaled. Acc [ -00036.62, 00001.46, 00987.30 ], Gyr [ 00000.43, 00001.95, -00003.96 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007315 NoMotion-1. Scaled. Acc [ -00036.62, -00005.86, 00986.33 ], Gyr [ -00000.67, 00000.91, -00001.77 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007316 NoMotion-1. Scaled. Acc [ -00045.90, 00000.00, 00996.09 ], Gyr [ -00000.24, 00002.32, -00002.93 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007317 NoMotion-1. Scaled. Acc [ -00035.64, -00020.02, 00994.14 ], Gyr [ -00000.91, -00000.43, -00003.23 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007318 NoMotion-1. Scaled. Acc [ -00055.18, -00015.62, 00987.30 ], Gyr [ -00000.55, -00000.24, 00000.98 ], Mag [ 00020.40, 00083.40, 00106.95 ]",
    "#0000007319 NoMotion-1. Scaled. Acc [ -00042.97, 00004.39, 00991.21 ], Gyr [ -00000.06, 00003.17, -00002.01 ], Mag [ 00019.50, 00082.80, 00108.60 ]",
    "#0000007320 NoMotion-1. Scaled. Acc [ -00044.43, 00003.91, 00988.77 ], Gyr [ 00000.37, 00001.16, -00001.16 ], Mag [ 00017.85, 00082.20, 00105.60 ]",
    "#0000007321 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00980.96 ], Gyr [ 00000.73, -00001.83, 00000.91 ], Mag [ 00020.25, 00082.50, 00107.10 ]",
    "#0000007322 NoMotion-1. Scaled. Acc [ -00037.60, -00010.74, 00993.16 ], Gyr [ -00000.98, 00001.28, 00002.74 ], Mag [ 00020.25, 00082.50, 00107.10 ]",
    "#0000007323 NoMotion-1. Scaled. Acc [ -00031.25, -00004.39, 00987.30 ], Gyr [ 00000.37, 00001.40, 00000.67 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007324 NoMotion-1. Scaled. Acc [ -00035.16, -00010.25, 00999.51 ], Gyr [ 00000.55, 00000.43, -00002.99 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007325 NoMotion-1. Scaled. Acc [ -00049.80, 00000.00, 00988.28 ], Gyr [ 00001.22, 00001.59, 00000.73 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007326 NoMotion-1. Scaled. Acc [ -00052.25, -00009.28, 00992.19 ], Gyr [ -00000.79, 00001.22, -00003.11 ], Mag [ 00020.40, 00084.15, 00108.75 ]",
    "#0000007327 NoMotion-1. Scaled. Acc [ -00040.04, -00012.21, 00987.79 ], Gyr [ -00000.55, 00002.01, -00004.39 ], Mag [ 00020.25, 00084.00, 00107.10 ]",
    "#0000007328 NoMotion-1. Scaled. Acc [ -00038.09, 00007.32, 00988.28 ], Gyr [ 00000.06, 00000.06, -00002.44 ], Mag [ 00020.25, 00084.00, 00107.10 ]",
    "#0000007329 NoMotion-1. Scaled. Acc [ -00052.25, -00012.70, 00986.82 ], Gyr [ 00001.65, 00000.91, -00001.16 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000007330 NoMotion-1. Scaled. Acc [ -00049.80, -00000.98, 00986.33 ], Gyr [ -00000.49, 00002.87, -00001.89 ], Mag [ 00020.25, 00083.55, 00106.80 ]",
    "#0000007331 NoMotion-1. Scaled. Acc [ -00040.53, -00021.48, 00994.14 ], Gyr [ -00000.12, 00003.11, -00003.66 ], Mag [ 00017.70, 00084.75, 00107.10 ]",
    "#0000007332 NoMotion-1. Scaled. Acc [ -00052.25, -00005.86, 00998.05 ], Gyr [ 00000.67, 00003.54, -00001.40 ], Mag [ 00017.70, 00084.75, 00107.10 ]",
    "#0000007333 NoMotion-1. Scaled. Acc [ -00039.06, 00005.86, 00983.40 ], Gyr [ 00001.22, 00002.50, 00000.49 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007334 NoMotion-1. Scaled. Acc [ -00028.81, -00008.79, 00994.63 ], Gyr [ 00000.67, 00000.24, -00001.89 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007335 NoMotion-1. Scaled. Acc [ -00043.46, -00011.23, 00992.19 ], Gyr [ 00001.40, 00002.01, -00003.84 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007336 NoMotion-1. Scaled. Acc [ -00054.20, -00018.07, 00987.30 ], Gyr [ 00001.71, 00003.29, 00001.10 ], Mag [ 00019.35, 00084.45, 00108.00 ]",
    "#0000007337 NoMotion-1. Scaled. Acc [ -00040.04, -00006.35, 01001.46 ], Gyr [ 00002.07, 00000.30, -00001.10 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000007338 NoMotion-1. Scaled. Acc [ -00033.20, -00002.44, 00987.30 ], Gyr [ -00000.55, 00000.06, -00001.28 ], Mag [ 00019.50, 00082.80, 00107.85 ]",
    "#0000007339 NoMotion-1. Scaled. Acc [ -00052.25, -00018.55, 00987.79 ], Gyr [ 00002.26, 00002.13, 00000.24 ], Mag [ 00019.50, 00083.55, 00107.10 ]",
    "#0000007340 NoMotion-1. Scaled. Acc [ -00032.23, -00005.86, 00984.86 ], Gyr [ -00000.61, 00002.68, -00001.46 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007341 NoMotion-1. Scaled. Acc [ -00051.27, 00000.00, 00984.86 ], Gyr [ -00000.37, 00003.29, -00001.40 ], Mag [ 00019.35, 00083.40, 00106.65 ]",
    "#0000007342 NoMotion-1. Scaled. Acc [ -00051.76, -00007.81, 00987.79 ], Gyr [ -00000.12, 00000.85, -00002.56 ], Mag [ 00018.15, 00084.30, 00109.35 ]",
    "#0000007343 NoMotion-1. Scaled. Acc [ -00039.55, 00000.00, 00982.91 ], Gyr [ -00000.79, 00001.16, -00002.62 ], Mag [ 00018.15, 00084.30, 00109.35 ]",
    "#0000007344 NoMotion-1. Scaled. Acc [ -00040.04, -00007.81, 00987.30 ], Gyr [ -00000.18, 00000.55, -00000.61 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007345 NoMotion-1. Scaled. Acc [ -00044.43, -00001.46, 00991.70 ], Gyr [ 00002.93, 00000.67, 00000.37 ], Mag [ 00020.10, 00084.15, 00108.00 ]",
    "#0000007346 NoMotion-1. Scaled. Acc [ -00047.36, -00007.32, 00983.89 ], Gyr [ 00001.28, -00000.73, 00000.49 ], Mag [ 00021.15, 00085.20, 00108.00 ]",
    "#0000007347 NoMotion-1. Scaled. Acc [ -00051.76, -00010.25, 00992.68 ], Gyr [ 00001.10, 00002.32, -00000.91 ], Mag [ 00021.15, 00085.20, 00108.00 ]",
    "#0000007348 NoMotion-1. Scaled. Acc [ -00039.55, 00000.49, 00985.35 ], Gyr [ -00000.18, 00003.90, -00001.40 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007349 NoMotion-1. Scaled. Acc [ -00055.66, -00007.81, 00998.05 ], Gyr [ 00000.37, -00000.67, -00001.71 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007350 NoMotion-1. Scaled. Acc [ -00030.27, -00007.32, 00986.82 ], Gyr [ 00001.89, 00001.71, -00000.79 ], Mag [ 00018.45, 00083.55, 00108.30 ]",
    "#0000007351 NoMotion-1. Scaled. Acc [ -00041.50, -00002.44, 00983.40 ], Gyr [ 00000.98, 00003.78, -00003.60 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000007352 NoMotion-1. Scaled. Acc [ -00040.04, 00000.98, 00985.84 ], Gyr [ 00000.61, 00003.96, -00000.43 ], Mag [ 00019.05, 00083.40, 00106.20 ]",
    "#0000007353 NoMotion-1. Scaled. Acc [ -00045.41, 00006.84, 00996.58 ], Gyr [ 00000.24, 00001.52, -00003.35 ], Mag [ 00019.65, 00082.95, 00108.00 ]",
    "#0000007354 NoMotion-1. Scaled. Acc [ -00041.50, -00006.35, 00989.26 ], Gyr [ -00000.98, 00000.61, -00004.09 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007355 NoMotion-1. Scaled. Acc [ -00030.76, -00023.44, 00994.14 ], Gyr [ 00002.26, 00001.16, 00000.55 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007356 NoMotion-1. Scaled. Acc [ -00042.48, -00014.16, 00989.75 ], Gyr [ 00001.22, -00000.49, -00002.13 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007357 NoMotion-1. Scaled. Acc [ -00053.71, -00011.23, 00989.26 ], Gyr [ 00002.13, 00003.41, -00000.12 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007358 NoMotion-1. Scaled. Acc [ -00061.52, -00003.91, 01000.98 ], Gyr [ 00000.91, -00000.79, -00001.71 ], Mag [ 00021.60, 00084.15, 00106.95 ]",
    "#0000007359 NoMotion-1. Scaled. Acc [ -00047.36, -00010.74, 00986.33 ], Gyr [ 00001.22, 00001.28, 00000.37 ], Mag [ 00020.10, 00082.65, 00107.40 ]",
    "#0000007360 NoMotion-1. Scaled. Acc [ -00055.66, -00012.70, 00989.26 ], Gyr [ 00001.95, -00000.79, 00001.22 ], Mag [ 00020.10, 00082.65, 00107.40 ]",
    "#0000007361 NoMotion-1. Scaled. Acc [ -00037.11, -00017.58, 00987.30 ], Gyr [ -00000.12, 00001.10, -00002.01 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007362 NoMotion-1. Scaled. Acc [ -00045.90, 00001.95, 00992.68 ], Gyr [ -00000.55, 00001.34, -00003.23 ], Mag [ 00019.65, 00083.40, 00106.65 ]",
    "#0000007363 NoMotion-1. Scaled. Acc [ -00044.92, 00001.46, 00999.51 ], Gyr [ -00000.98, -00000.85, -00000.55 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000007364 NoMotion-1. Scaled. Acc [ -00040.53, -00012.21, 00980.96 ], Gyr [ -00001.16, 00002.44, -00001.77 ], Mag [ 00020.10, 00083.70, 00107.40 ]",
    "#0000007365 NoMotion-1. Scaled. Acc [ -00033.20, -00014.16, 00996.58 ], Gyr [ 00000.18, 00001.59, -00003.72 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007366 NoMotion-1. Scaled. Acc [ -00045.90, -00003.91, 00982.42 ], Gyr [ 00002.01, 00001.65, -00000.43 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007367 NoMotion-1. Scaled. Acc [ -00041.99, -00012.21, 00987.30 ], Gyr [ 00000.43, 00003.23, -00000.30 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007368 NoMotion-1. Scaled. Acc [ -00039.55, -00008.79, 00995.61 ], Gyr [ 00000.18, 00000.00, 00000.12 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007369 NoMotion-1. Scaled. Acc [ -00031.74, -00007.81, 01001.95 ], Gyr [ 00001.22, 00001.59, 00000.98 ], Mag [ 00020.40, 00083.70, 00107.40 ]",
    "#0000007370 NoMotion-1. Scaled. Acc [ -00037.11, 00005.86, 00983.89 ], Gyr [ -00000.55, 00000.91, -00002.32 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007371 NoMotion-1. Scaled. Acc [ -00054.20, -00001.95, 00986.82 ], Gyr [ 00001.89, 00002.07, -00000.67 ], Mag [ 00020.10, 00084.15, 00106.65 ]",
    "#0000007372 NoMotion-1. Scaled. Acc [ -00033.20, -00023.93, 00984.38 ], Gyr [ 00002.44, 00000.00, -00001.95 ], Mag [ 00019.65, 00083.40, 00106.95 ]",
    "#0000007373 NoMotion-1. Scaled. Acc [ -00040.53, -00021.00, 00998.05 ], Gyr [ 00001.83, 00000.61, -00004.45 ], Mag [ 00019.65, 00083.40, 00106.95 ]",
    "#0000007374 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 00998.05 ], Gyr [ 00000.24, 00001.95, -00000.98 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007375 NoMotion-1. Scaled. Acc [ -00045.41, -00015.14, 00985.35 ], Gyr [ -00000.18, 00001.59, -00001.83 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007376 NoMotion-1. Scaled. Acc [ -00047.85, -00007.81, 00997.56 ], Gyr [ -00000.37, 00000.85, -00003.17 ], Mag [ 00018.75, 00084.00, 00107.85 ]",
    "#0000007377 NoMotion-1. Scaled. Acc [ -00044.92, -00000.98, 00993.65 ], Gyr [ -00002.50, 00002.44, -00001.95 ], Mag [ 00019.95, 00082.05, 00108.30 ]",
    "#0000007378 NoMotion-1. Scaled. Acc [ -00041.02, -00012.70, 00981.45 ], Gyr [ -00000.91, 00002.50, -00002.50 ], Mag [ 00019.95, 00082.05, 00108.30 ]",
    "#0000007379 NoMotion-1. Scaled. Acc [ -00042.97, -00019.53, 00994.63 ], Gyr [ -00000.30, -00000.73, -00000.37 ], Mag [ 00017.85, 00084.90, 00109.05 ]",
    "#0000007380 NoMotion-1. Scaled. Acc [ -00047.36, -00008.79, 00982.42 ], Gyr [ 00004.51, -00000.18, 00000.00 ], Mag [ 00017.85, 00084.90, 00109.05 ]",
    "#0000007381 NoMotion-1. Scaled. Acc [ -00045.41, -00004.88, 00984.86 ], Gyr [ 00000.30, 00003.17, -00002.26 ], Mag [ 00020.25, 00082.80, 00107.85 ]",
    "#0000007382 NoMotion-1. Scaled. Acc [ -00041.02, 00002.44, 00994.14 ], Gyr [ 00001.16, 00001.10, -00002.01 ], Mag [ 00020.25, 00082.80, 00107.85 ]",
    "#0000007383 NoMotion-1. Scaled. Acc [ -00041.02, -00001.46, 00983.40 ], Gyr [ 00000.24, -00002.13, 00000.30 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007384 NoMotion-1. Scaled. Acc [ -00040.53, -00004.88, 01003.91 ], Gyr [ 00001.89, 00001.16, -00000.43 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007385 NoMotion-1. Scaled. Acc [ -00044.92, -00009.28, 00982.91 ], Gyr [ -00000.12, 00000.55, -00003.17 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007386 NoMotion-1. Scaled. Acc [ -00048.83, 00004.39, 00999.51 ], Gyr [ 00002.26, 00000.43, -00001.16 ], Mag [ 00016.95, 00082.80, 00107.85 ]",
    "#0000007387 NoMotion-1. Scaled. Acc [ -00035.64, -00006.35, 00990.72 ], Gyr [ 00000.37, 00003.29, -00001.34 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007388 NoMotion-1. Scaled. Acc [ -00035.64, -00001.95, 00987.30 ], Gyr [ 00000.43, 00000.18, -00000.30 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007389 NoMotion-1. Scaled. Acc [ -00039.55, 00004.88, 00993.65 ], Gyr [ 00000.61, 00004.51, -00002.68 ], Mag [ 00019.35, 00083.70, 00108.00 ]",
    "#0000007390 NoMotion-1. Scaled. Acc [ -00047.85, -00009.77, 00985.35 ], Gyr [ 00000.43, 00000.06, -00003.90 ], Mag [ 00019.05, 00083.70, 00106.95 ]",
    "#0000007391 NoMotion-1. Scaled. Acc [ -00040.04, -00008.30, 00983.40 ], Gyr [ -00000.24, 00002.32, -00002.62 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007392 NoMotion-1. Scaled. Acc [ -00034.67, -00012.21, 00997.56 ], Gyr [ 00001.28, -00001.34, 00001.04 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007393 NoMotion-1. Scaled. Acc [ -00038.57, -00013.18, 00978.52 ], Gyr [ 00000.73, 00000.91, -00000.73 ], Mag [ 00021.30, 00085.50, 00107.55 ]",
    "#0000007394 NoMotion-1. Scaled. Acc [ -00042.48, -00006.35, 00995.12 ], Gyr [ 00000.06, 00000.91, -00001.46 ], Mag [ 00020.40, 00083.40, 00107.70 ]",
    "#0000007395 NoMotion-1. Scaled. Acc [ -00042.48, -00002.93, 00997.07 ], Gyr [ 00002.01, -00000.73, 00000.85 ], Mag [ 00020.40, 00083.40, 00107.70 ]",
    "#0000007396 NoMotion-1. Scaled. Acc [ -00037.60, -00014.16, 01004.88 ], Gyr [ 00000.61, 00001.10, -00000.73 ], Mag [ 00020.70, 00084.00, 00107.85 ]",
    "#0000007397 NoMotion-1. Scaled. Acc [ -00042.97, -00011.23, 00995.61 ], Gyr [ 00001.46, 00001.34, -00000.55 ], Mag [ 00020.70, 00084.00, 00107.85 ]",
    "#0000007398 NoMotion-1. Scaled. Acc [ -00048.34, -00014.65, 00995.12 ], Gyr [ -00000.18, 00001.83, -00002.07 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000007399 NoMotion-1. Scaled. Acc [ -00048.83, -00016.60, 00999.02 ], Gyr [ 00001.10, 00001.28, -00003.72 ], Mag [ 00019.50, 00084.00, 00106.80 ]",
    "#0000007400 NoMotion-1. Scaled. Acc [ -00041.50, -00013.67, 00998.05 ], Gyr [ 00000.55, 00001.89, -00001.65 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007401 NoMotion-1. Scaled. Acc [ -00047.85, 00009.77, 00991.70 ], Gyr [ -00001.71, 00003.05, -00001.83 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007402 NoMotion-1. Scaled. Acc [ -00037.60, -00026.37, 01001.46 ], Gyr [ 00000.12, 00000.43, 00001.28 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007403 NoMotion-1. Scaled. Acc [ -00035.64, -00011.23, 00992.19 ], Gyr [ -00000.37, 00003.60, -00002.62 ], Mag [ 00019.35, 00083.40, 00107.40 ]",
    "#0000007404 NoMotion-1. Scaled. Acc [ -00053.71, -00014.16, 01001.95 ], Gyr [ 00001.10, 00002.13, -00000.61 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007405 NoMotion-1. Scaled. Acc [ -00051.27, -00006.35, 00989.26 ], Gyr [ 00001.52, 00001.16, 00002.68 ], Mag [ 00020.40, 00084.15, 00107.70 ]",
    "#0000007406 NoMotion-1. Scaled. Acc [ -00050.29, -00009.28, 00981.93 ], Gyr [ -00000.18, 00002.20, -00000.24 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007407 NoMotion-1. Scaled. Acc [ -00037.60, -00014.16, 00982.42 ], Gyr [ 00002.26, 00001.46, -00003.41 ], Mag [ 00019.65, 00084.15, 00107.40 ]",
    "#0000007408 NoMotion-1. Scaled. Acc [ -00041.99, -00008.79, 00992.68 ], Gyr [ -00000.30, -00000.55, -00001.34 ], Mag [ 00019.95, 00084.75, 00108.60 ]",
    "#0000007409 NoMotion-1. Scaled. Acc [ -00036.13, -00001.95, 00994.63 ], Gyr [ 00001.71, 00001.34, -00001.95 ], Mag [ 00019.95, 00084.75, 00108.60 ]",
    "#0000007410 NoMotion-1. Scaled. Acc [ -00033.20, -00012.21, 00992.19 ], Gyr [ 00003.35, 00002.38, 00000.24 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007411 NoMotion-1. Scaled. Acc [ -00038.57, -00005.37, 01000.98 ], Gyr [ 00001.46, -00000.30, -00000.85 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007412 NoMotion-1. Scaled. Acc [ -00046.88, -00017.58, 00991.70 ], Gyr [ 00001.04, -00000.55, -00002.68 ], Mag [ 00020.85, 00083.70, 00108.00 ]",
    "#0000007413 NoMotion-1. Scaled. Acc [ -00049.80, -00012.70, 00990.72 ], Gyr [ 00000.00, 00003.05, -00000.85 ], Mag [ 00020.40, 00084.90, 00107.40 ]",
    "#0000007414 NoMotion-1. Scaled. Acc [ -00036.62, -00001.46, 00986.33 ], Gyr [ 00001.77, 00001.40, -00003.90 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007415 NoMotion-1. Scaled. Acc [ -00059.57, 00001.46, 00988.77 ], Gyr [ 00000.43, 00003.90, -00000.12 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007416 NoMotion-1. Scaled. Acc [ -00045.41, -00000.49, 00991.70 ], Gyr [ 00001.10, 00000.12, -00000.73 ], Mag [ 00018.45, 00084.30, 00108.60 ]",
    "#0000007417 NoMotion-1. Scaled. Acc [ -00051.27, -00008.30, 00993.16 ], Gyr [ 00000.98, 00004.63, -00003.41 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007418 NoMotion-1. Scaled. Acc [ -00044.43, -00004.39, 00993.65 ], Gyr [ 00000.00, 00003.17, -00001.16 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007419 NoMotion-1. Scaled. Acc [ -00061.04, -00011.72, 00992.68 ], Gyr [ -00000.24, 00000.06, -00002.26 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007420 NoMotion-1. Scaled. Acc [ -00048.83, -00003.91, 00993.65 ], Gyr [ 00000.79, 00001.77, -00001.59 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007421 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00989.26 ], Gyr [ 00000.79, -00000.43, -00000.98 ], Mag [ 00020.25, 00084.75, 00108.30 ]",
    "#0000007422 NoMotion-1. Scaled. Acc [ -00038.57, -00021.97, 00993.65 ], Gyr [ -00001.95, 00003.17, -00002.44 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000007423 NoMotion-1. Scaled. Acc [ -00042.48, -00009.77, 00984.86 ], Gyr [ -00002.50, 00000.67, -00002.80 ], Mag [ 00019.05, 00084.15, 00107.40 ]",
    "#0000007424 NoMotion-1. Scaled. Acc [ -00042.97, -00000.98, 00990.23 ], Gyr [ 00001.77, 00001.34, -00003.48 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007425 NoMotion-1. Scaled. Acc [ -00047.85, -00012.21, 01005.86 ], Gyr [ 00002.20, -00000.12, -00001.34 ], Mag [ 00020.10, 00084.45, 00106.95 ]",
    "#0000007426 NoMotion-1. Scaled. Acc [ -00041.02, -00015.62, 01000.98 ], Gyr [ -00000.24, -00000.55, -00002.56 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000007427 NoMotion-1. Scaled. Acc [ -00047.85, -00008.79, 00984.86 ], Gyr [ 00000.98, 00001.89, -00002.93 ], Mag [ 00019.65, 00084.15, 00106.65 ]",
    "#0000007428 NoMotion-1. Scaled. Acc [ -00038.09, -00007.81, 00992.68 ], Gyr [ 00001.83, 00000.67, -00000.67 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007429 NoMotion-1. Scaled. Acc [ -00048.34, -00000.49, 00995.61 ], Gyr [ 00000.55, 00001.59, 00000.06 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007430 NoMotion-1. Scaled. Acc [ -00041.50, -00001.46, 00986.82 ], Gyr [ -00000.12, 00002.62, -00000.24 ], Mag [ 00018.60, 00083.70, 00107.70 ]",
    "#0000007431 NoMotion-1. Scaled. Acc [ -00044.92, -00002.93, 01000.49 ], Gyr [ 00001.59, 00003.84, -00001.28 ], Mag [ 00019.65, 00085.65, 00106.95 ]",
    "#0000007432 NoMotion-1. Scaled. Acc [ -00053.22, -00009.77, 00975.59 ], Gyr [ 00001.59, 00003.05, -00001.16 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007433 NoMotion-1. Scaled. Acc [ -00044.43, -00013.18, 00986.82 ], Gyr [ -00000.67, 00001.59, -00000.30 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007434 NoMotion-1. Scaled. Acc [ -00046.39, -00003.42, 00984.38 ], Gyr [ 00000.55, 00000.18, 00000.06 ], Mag [ 00019.95, 00084.75, 00107.85 ]",
    "#0000007435 NoMotion-1. Scaled. Acc [ -00040.53, -00010.25, 00987.30 ], Gyr [ 00000.61, -00000.30, 00000.55 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007436 NoMotion-1. Scaled. Acc [ -00040.53, -00009.77, 00983.89 ], Gyr [ -00001.77, 00000.00, -00001.77 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007437 NoMotion-1. Scaled. Acc [ -00037.11, -00007.81, 00995.12 ], Gyr [ 00000.73, 00000.73, -00003.66 ], Mag [ 00018.15, 00084.75, 00107.55 ]",
    "#0000007438 NoMotion-1. Scaled. Acc [ -00054.69, -00009.77, 00984.38 ], Gyr [ 00000.37, 00002.13, -00003.11 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000007439 NoMotion-1. Scaled. Acc [ -00045.90, -00004.39, 00997.56 ], Gyr [ -00000.98, 00001.34, -00004.02 ], Mag [ 00018.45, 00083.55, 00106.80 ]",
    "#0000007440 NoMotion-1. Scaled. Acc [ -00036.62, -00011.72, 00998.05 ], Gyr [ 00002.07, 00001.28, -00001.52 ], Mag [ 00021.00, 00083.25, 00108.30 ]",
    "#0000007441 NoMotion-1. Scaled. Acc [ -00051.76, -00014.16, 00978.52 ], Gyr [ -00001.40, 00002.44, -00002.07 ], Mag [ 00021.00, 00083.25, 00108.30 ]",
    "#0000007442 NoMotion-1. Scaled. Acc [ -00050.78, -00005.86, 00992.68 ], Gyr [ 00000.00, 00001.22, -00002.74 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000007443 NoMotion-1. Scaled. Acc [ -00044.92, -00012.70, 00984.86 ], Gyr [ 00003.11, -00002.13, -00002.38 ], Mag [ 00019.65, 00083.40, 00107.70 ]",
    "#0000007444 NoMotion-1. Scaled. Acc [ -00034.18, -00000.98, 00995.12 ], Gyr [ 00001.59, -00002.56, -00001.22 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007445 NoMotion-1. Scaled. Acc [ -00043.95, -00015.14, 00983.89 ], Gyr [ 00002.07, 00004.27, -00002.50 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007446 NoMotion-1. Scaled. Acc [ -00049.80, 00002.44, 00985.35 ], Gyr [ 00001.89, -00000.85, 00000.61 ], Mag [ 00019.65, 00082.20, 00108.00 ]",
    "#0000007447 NoMotion-1. Scaled. Acc [ -00050.29, -00007.81, 00994.63 ], Gyr [ -00000.12, -00001.22, -00002.01 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000007448 NoMotion-1. Scaled. Acc [ -00041.02, -00002.93, 00995.12 ], Gyr [ 00000.49, 00000.91, -00001.16 ], Mag [ 00020.10, 00082.65, 00108.45 ]",
    "#0000007449 NoMotion-1. Scaled. Acc [ -00031.74, 00006.84, 00988.28 ], Gyr [ 00000.85, 00002.50, -00002.01 ], Mag [ 00020.10, 00084.45, 00108.75 ]",
    "#0000007450 NoMotion-1. Scaled. Acc [ -00042.48, -00012.70, 00988.28 ], Gyr [ 00001.59, 00001.22, -00002.32 ], Mag [ 00020.10, 00084.45, 00108.75 ]",
    "#0000007451 NoMotion-1. Scaled. Acc [ -00044.43, -00009.77, 00984.38 ], Gyr [ -00000.55, -00000.37, -00002.74 ], Mag [ 00020.70, 00084.75, 00108.30 ]",
    "#0000007452 NoMotion-1. Scaled. Acc [ -00041.50, -00004.39, 00990.23 ], Gyr [ -00000.30, 00003.72, -00002.93 ], Mag [ 00019.35, 00083.40, 00108.00 ]",
    "#0000007453 NoMotion-1. Scaled. Acc [ -00041.99, -00008.30, 00984.38 ], Gyr [ -00000.79, 00002.20, 00000.24 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007454 NoMotion-1. Scaled. Acc [ -00049.80, -00004.88, 00980.96 ], Gyr [ 00002.13, 00000.61, -00002.99 ], Mag [ 00019.65, 00084.15, 00107.70 ]",
    "#0000007455 NoMotion-1. Scaled. Acc [ -00038.09, -00006.84, 00993.65 ], Gyr [ 00000.85, 00001.16, -00001.22 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007456 NoMotion-1. Scaled. Acc [ -00053.22, -00017.58, 00991.70 ], Gyr [ 00000.37, -00001.52, 00000.61 ], Mag [ 00020.40, 00083.40, 00107.40 ]",
    "#0000007457 NoMotion-1. Scaled. Acc [ -00049.80, -00003.91, 00987.30 ], Gyr [ 00002.07, 00001.10, 00000.24 ], Mag [ 00019.50, 00084.00, 00107.55 ]",
    "#0000007458 NoMotion-1. Scaled. Acc [ -00041.02, -00003.42, 00996.09 ], Gyr [ 00002.87, 00001.16, 00001.10 ], Mag [ 00019.50, 00084.00, 00107.55 ]",
    "#0000007459 NoMotion-1. Scaled. Acc [ -00052.73, -00002.44, 00993.65 ], Gyr [ -00000.12, 00002.20, -00003.29 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007460 NoMotion-1. Scaled. Acc [ -00021.48, -00016.11, 01008.79 ], Gyr [ -00000.79, 00001.46, -00000.61 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007461 NoMotion-1. Scaled. Acc [ -00048.83, -00008.30, 00980.47 ], Gyr [ -00000.61, 00002.93, 00000.06 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007462 NoMotion-1. Scaled. Acc [ -00036.13, -00018.55, 00989.75 ], Gyr [ -00001.77, 00002.99, -00001.65 ], Mag [ 00022.05, 00082.80, 00107.85 ]",
    "#0000007463 NoMotion-1. Scaled. Acc [ -00056.15, -00003.91, 00989.75 ], Gyr [ -00000.49, 00001.71, 00002.20 ], Mag [ 00020.25, 00084.00, 00109.65 ]",
    "#0000007464 NoMotion-1. Scaled. Acc [ -00054.69, -00000.49, 00995.12 ], Gyr [ 00001.40, 00000.18, -00002.93 ], Mag [ 00018.30, 00082.65, 00105.90 ]",
    "#0000007465 NoMotion-1. Scaled. Acc [ -00046.39, -00001.46, 00981.93 ], Gyr [ 00002.38, 00003.11, -00001.71 ], Mag [ 00018.30, 00082.65, 00105.90 ]",
    "#0000007466 NoMotion-1. Scaled. Acc [ -00047.85, -00015.62, 00985.35 ], Gyr [ -00000.79, 00000.73, 00000.24 ], Mag [ 00019.95, 00085.80, 00108.30 ]",
    "#0000007467 NoMotion-1. Scaled. Acc [ -00029.79, -00001.95, 00990.72 ], Gyr [ -00000.55, 00000.24, -00002.13 ], Mag [ 00019.95, 00085.80, 00108.30 ]",
    "#0000007468 NoMotion-1. Scaled. Acc [ -00046.39, -00007.81, 00986.82 ], Gyr [ 00001.95, 00001.77, -00002.80 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007469 NoMotion-1. Scaled. Acc [ -00038.09, -00004.39, 00997.07 ], Gyr [ -00000.73, -00000.06, -00003.35 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007470 NoMotion-1. Scaled. Acc [ -00034.67, 00000.98, 00984.86 ], Gyr [ -00000.91, 00001.52, -00000.85 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007471 NoMotion-1. Scaled. Acc [ -00055.18, -00007.32, 00988.77 ], Gyr [ 00001.28, 00001.95, -00001.52 ], Mag [ 00018.45, 00083.55, 00105.00 ]",
    "#0000007472 NoMotion-1. Scaled. Acc [ -00037.60, -00002.44, 01000.98 ], Gyr [ 00001.89, 00001.10, -00001.52 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000007473 NoMotion-1. Scaled. Acc [ -00045.90, -00007.32, 00992.68 ], Gyr [ 00002.01, 00000.85, -00002.44 ], Mag [ 00019.20, 00083.25, 00107.55 ]",
    "#0000007474 NoMotion-1. Scaled. Acc [ -00043.95, -00015.62, 00993.16 ], Gyr [ 00000.91, 00004.09, -00000.24 ], Mag [ 00020.70, 00084.00, 00106.80 ]",
    "#0000007475 NoMotion-1. Scaled. Acc [ -00047.36, -00008.30, 01001.95 ], Gyr [ 00000.06, 00003.05, 00000.55 ], Mag [ 00020.70, 00084.00, 00106.80 ]",
    "#0000007476 NoMotion-1. Scaled. Acc [ -00047.85, -00005.86, 00981.45 ], Gyr [ -00000.55, 00002.38, -00001.40 ], Mag [ 00020.10, 00084.45, 00108.00 ]",
    "#0000007477 NoMotion-1. Scaled. Acc [ -00044.92, -00010.74, 00985.35 ], Gyr [ 00000.55, 00000.85, -00001.52 ], Mag [ 00020.10, 00084.45, 00108.00 ]",
    "#0000007478 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 01001.95 ], Gyr [ -00001.22, 00002.68, -00001.52 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007479 NoMotion-1. Scaled. Acc [ -00047.85, 00007.81, 00986.82 ], Gyr [ 00001.52, 00000.79, -00000.37 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007480 NoMotion-1. Scaled. Acc [ -00046.88, -00010.74, 00989.75 ], Gyr [ 00000.98, 00000.43, -00000.49 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007481 NoMotion-1. Scaled. Acc [ -00054.69, -00014.16, 00985.84 ], Gyr [ 00001.65, 00001.59, 00000.73 ], Mag [ 00018.75, 00083.25, 00108.30 ]",
    "#0000007482 NoMotion-1. Scaled. Acc [ -00046.88, 00003.91, 00997.07 ], Gyr [ 00001.83, -00000.55, -00002.32 ], Mag [ 00019.35, 00082.20, 00106.95 ]",
    "#0000007483 NoMotion-1. Scaled. Acc [ -00049.32, 00000.49, 00985.35 ], Gyr [ 00002.26, 00000.06, -00001.04 ], Mag [ 00020.40, 00082.20, 00108.75 ]",
    "#0000007484 NoMotion-1. Scaled. Acc [ -00047.36, -00000.98, 00998.54 ], Gyr [ 00000.61, -00001.28, -00003.72 ], Mag [ 00020.40, 00082.20, 00108.75 ]",
    "#0000007485 NoMotion-1. Scaled. Acc [ -00041.02, -00000.49, 00988.77 ], Gyr [ -00001.28, 00002.26, -00003.41 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000007486 NoMotion-1. Scaled. Acc [ -00051.76, -00012.70, 00988.77 ], Gyr [ 00000.24, 00001.52, -00002.44 ], Mag [ 00019.65, 00084.45, 00108.00 ]",
    "#0000007487 NoMotion-1. Scaled. Acc [ -00039.06, -00002.93, 00979.49 ], Gyr [ 00000.98, 00003.11, -00001.59 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007488 NoMotion-1. Scaled. Acc [ -00051.27, -00011.72, 00984.86 ], Gyr [ 00000.24, 00002.01, -00002.80 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007489 NoMotion-1. Scaled. Acc [ -00045.90, 00000.49, 00985.84 ], Gyr [ -00000.43, 00003.23, -00001.65 ], Mag [ 00020.25, 00084.30, 00108.30 ]",
    "#0000007490 NoMotion-1. Scaled. Acc [ -00036.13, -00010.74, 00992.68 ], Gyr [ -00000.06, 00000.18, 00000.49 ], Mag [ 00021.00, 00084.30, 00108.30 ]",
    "#0000007491 NoMotion-1. Scaled. Acc [ -00049.80, -00005.37, 00996.09 ], Gyr [ 00001.95, 00001.65, 00001.89 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007492 NoMotion-1. Scaled. Acc [ -00037.60, -00002.93, 00986.33 ], Gyr [ 00001.04, 00000.37, -00000.73 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007493 NoMotion-1. Scaled. Acc [ -00039.55, -00009.28, 00987.79 ], Gyr [ 00002.07, 00000.91, -00001.10 ], Mag [ 00021.00, 00085.50, 00106.80 ]",
    "#0000007494 NoMotion-1. Scaled. Acc [ -00052.73, -00019.53, 00986.33 ], Gyr [ 00002.68, 00001.22, -00000.91 ], Mag [ 00020.70, 00084.30, 00107.85 ]",
    "#0000007495 NoMotion-1. Scaled. Acc [ -00045.90, -00013.18, 01002.93 ], Gyr [ -00000.18, 00000.24, -00001.10 ], Mag [ 00020.70, 00084.30, 00107.85 ]",
    "#0000007496 NoMotion-1. Scaled. Acc [ -00036.62, -00000.98, 00987.30 ], Gyr [ 00000.67, -00002.13, -00000.24 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007497 NoMotion-1. Scaled. Acc [ -00041.50, 00004.39, 00990.72 ], Gyr [ 00001.77, 00001.52, -00001.52 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007498 NoMotion-1. Scaled. Acc [ -00049.32, -00006.84, 00993.65 ], Gyr [ -00000.30, 00004.51, -00002.07 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007499 NoMotion-1. Scaled. Acc [ -00041.02, -00010.74, 00991.70 ], Gyr [ -00000.55, -00000.67, -00003.96 ], Mag [ 00019.05, 00083.40, 00107.40 ]",
    "#0000007500 NoMotion-1. Scaled. Acc [ -00049.80, -00002.93, 00994.63 ], Gyr [ 00001.52, -00000.61, -00001.95 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007501 NoMotion-1. Scaled. Acc [ -00035.16, -00009.28, 00980.47 ], Gyr [ 00001.10, 00002.50, -00003.66 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007502 NoMotion-1. Scaled. Acc [ -00049.80, -00001.95, 00994.14 ], Gyr [ 00000.37, -00000.49, -00002.20 ], Mag [ 00020.10, 00083.70, 00106.65 ]",
    "#0000007503 NoMotion-1. Scaled. Acc [ -00049.32, -00010.25, 00984.86 ], Gyr [ -00001.34, 00001.71, -00002.44 ], Mag [ 00018.75, 00082.05, 00107.55 ]",
    "#0000007504 NoMotion-1. Scaled. Acc [ -00035.64, 00001.95, 00984.86 ], Gyr [ -00000.49, 00000.18, 00000.61 ], Mag [ 00018.75, 00082.05, 00107.55 ]",
    "#0000007505 NoMotion-1. Scaled. Acc [ -00041.02, -00019.53, 00987.79 ], Gyr [ 00001.77, -00002.50, -00000.43 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007506 NoMotion-1. Scaled. Acc [ -00050.29, -00005.86, 00985.84 ], Gyr [ 00001.40, 00001.16, 00000.00 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007507 NoMotion-1. Scaled. Acc [ -00037.60, -00001.46, 00991.21 ], Gyr [ 00001.34, 00001.83, -00000.85 ], Mag [ 00020.10, 00084.90, 00107.70 ]",
    "#0000007508 NoMotion-1. Scaled. Acc [ -00049.32, -00002.44, 00993.16 ], Gyr [ 00002.07, 00001.16, 00000.49 ], Mag [ 00020.10, 00084.90, 00107.70 ]",
    "#0000007509 NoMotion-1. Scaled. Acc [ -00052.25, 00000.00, 01000.49 ], Gyr [ 00001.28, 00002.93, -00002.99 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007510 NoMotion-1. Scaled. Acc [ -00041.02, -00009.28, 00993.65 ], Gyr [ -00000.12, 00002.38, -00000.85 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007511 NoMotion-1. Scaled. Acc [ -00039.55, -00004.88, 00971.19 ], Gyr [ 00000.49, 00000.98, -00001.28 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007512 NoMotion-1. Scaled. Acc [ -00043.95, -00016.11, 00984.86 ], Gyr [ 00000.00, 00002.68, -00002.26 ], Mag [ 00020.70, 00085.50, 00107.85 ]",
    "#0000007513 NoMotion-1. Scaled. Acc [ -00043.95, 00000.00, 00989.26 ], Gyr [ -00000.79, 00004.02, -00000.43 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000007514 NoMotion-1. Scaled. Acc [ -00049.32, -00012.21, 01010.25 ], Gyr [ 00001.10, 00004.21, -00004.21 ], Mag [ 00019.95, 00084.00, 00108.30 ]",
    "#0000007515 NoMotion-1. Scaled. Acc [ -00038.09, 00005.37, 01000.00 ], Gyr [ -00000.79, 00004.39, -00001.16 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000007516 NoMotion-1. Scaled. Acc [ -00047.36, -00021.97, 00994.63 ], Gyr [ 00000.73, 00000.24, -00000.61 ], Mag [ 00020.85, 00084.15, 00107.40 ]",
    "#0000007517 NoMotion-1. Scaled. Acc [ -00042.48, -00009.28, 00988.28 ], Gyr [ 00001.52, 00002.32, -00001.04 ], Mag [ 00019.95, 00081.75, 00107.10 ]",
    "#0000007518 NoMotion-1. Scaled. Acc [ -00043.46, -00021.97, 00984.86 ], Gyr [ 00000.91, 00000.91, -00002.26 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007519 NoMotion-1. Scaled. Acc [ -00058.59, 00000.98, 00989.75 ], Gyr [ 00000.24, -00000.30, -00003.54 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007520 NoMotion-1. Scaled. Acc [ -00046.39, -00013.18, 00995.12 ], Gyr [ 00000.85, 00000.12, -00001.46 ], Mag [ 00019.05, 00084.45, 00108.00 ]",
    "#0000007521 NoMotion-1. Scaled. Acc [ -00039.55, -00013.18, 00989.75 ], Gyr [ 00000.85, 00000.49, -00000.67 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007522 NoMotion-1. Scaled. Acc [ -00042.48, -00001.46, 00996.09 ], Gyr [ -00000.73, 00000.55, -00000.98 ], Mag [ 00019.20, 00084.00, 00107.85 ]",
    "#0000007523 NoMotion-1. Scaled. Acc [ -00037.60, -00000.98, 00981.45 ], Gyr [ 00000.06, 00002.80, -00001.28 ], Mag [ 00019.05, 00083.70, 00108.45 ]",
    "#0000007524 NoMotion-1. Scaled. Acc [ -00050.29, -00015.62, 00994.14 ], Gyr [ 00000.85, -00000.30, -00002.01 ], Mag [ 00019.05, 00083.70, 00108.45 ]",
    "#0000007525 NoMotion-1. Scaled. Acc [ -00043.46, -00015.62, 00988.77 ], Gyr [ -00000.30, 00001.71, -00001.52 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007526 NoMotion-1. Scaled. Acc [ -00036.13, 00000.49, 00994.14 ], Gyr [ -00000.73, 00004.57, -00000.73 ], Mag [ 00020.10, 00082.95, 00107.70 ]",
    "#0000007527 NoMotion-1. Scaled. Acc [ -00033.20, -00012.70, 00994.14 ], Gyr [ 00001.52, 00001.71, -00004.02 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007528 NoMotion-1. Scaled. Acc [ -00045.41, -00001.46, 00985.35 ], Gyr [ 00001.71, 00001.71, 00001.04 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007529 NoMotion-1. Scaled. Acc [ -00042.48, -00001.95, 00994.14 ], Gyr [ -00000.79, 00001.28, -00003.11 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007530 NoMotion-1. Scaled. Acc [ -00048.83, -00011.23, 00982.91 ], Gyr [ 00000.85, 00002.01, 00000.55 ], Mag [ 00019.65, 00082.20, 00107.70 ]",
    "#0000007531 NoMotion-1. Scaled. Acc [ -00041.99, 00002.93, 00989.75 ], Gyr [ 00001.28, -00001.59, -00003.17 ], Mag [ 00019.95, 00084.30, 00107.85 ]",
    "#0000007532 NoMotion-1. Scaled. Acc [ -00040.53, -00013.18, 00997.56 ], Gyr [ 00001.89, 00001.77, -00000.85 ], Mag [ 00019.95, 00084.30, 00107.85 ]"
]