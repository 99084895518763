import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkStepperRank: 0,
  checkStepperContent: true,
  isLoading: false,
  isImproveScore: false,
  galenThreshold: null,
  stepsData: {
    step1: { vibration: "strong", wrist: true, left: true },
    "step1.5": {},
    step2: {},
    "step2.5": {},
    "step2.7": {},
    step3: {},
    "step3.1": {},
    "step3.2": {},
    "step3.3": {},
    "step3.5": {},
    step4: {},
    "step4.1": {},
    "step4.2": {},
    "step4.3": {},
    step5: {},
    "step5.5": {},
    step6: {},
    step7: {},
  },
  vibrationPopUp: false,
  instructionsPopUpState: false,
  factoryPopUp: false,
  dataLoadPopUp: false,
  measurements: [],
  galenApiCheckResult: null,
  deviceDataId: null,
  isLoadData: null,
  isSetDefaultData: null,
  isDataSend: null,
  userChoice: {
    side: null,
    placement: null,
    orientation: null,
    leftClickMotion: null,
    rigthClickMotion: null,
  },
};

export const onBoardSlice = createSlice({
  name: "onBoard",
  initialState,
  reducers: {
    stepperRank: (state, action) => {
      state.checkStepperRank = action.payload;
    },
    checkStepperContent: (state, action) => {
      state.checkStepperContent = action.payload;
    },
    stepperData: (state, action) => {
      state.stepsData = action.payload;
      state.userChoice.side = action.payload.step1.left
        ? "left"
        : action.payload.step1.rigth
        ? "rigth"
        : null;
      state.userChoice.placement = action.payload.step1.wrist
        ? "wrist"
        : action.payload.step1.arm
        ? "arm"
        : action.payload.step1.leg
        ? "leg"
        : null;
      state.userChoice.orientation = action.payload.step2.horizontal
        ? "horizontal"
        : action.payload.step2.vertical
        ? "vertical"
        : null;
    },
    vibrationPopUp: (state, action) => {
      state.vibrationPopUp = action.payload;
    },
    instructionsPopUpState: (state, action) => {
      state.instructionsPopUpState = action.payload;
    },
    setFactoryPopUp: (state, action) => {
      state.factoryPopUp = action.payload;
    },
    setIsLoadData: (state, action) => {
      state.isLoadData = action.payload;
    },
    setIsSetDefaultData: (state, action) => {
      state.isSetDefaultData = action.payload;
    },
    setLoadDataPopUp: (state, action) => {
      state.loadDataPopUp = action.payload;
    },
    setFirmwareUpdatePopUp: (state, action) => {
      state.firmwareUpdatePopUp = action.payload;
    },
    setMeasurements: (state, action) => {
      const step = Object.keys(action.payload)[0];
      const newState = state.measurements.filter((item) => !item[step]);
      state.measurements = [...newState, action.payload];
    },
    setCutMeasurements: (state, action) => {
      const newState = action.payload;
      state.measurements = newState;
    },
    setDefaultMeasurements: (state, action) => {
      state.measurements = [];
    },
    setDefaultDataAfterLoad: (state, action) => {
      state.measurements = [];
      state.stepsData = initialState.stepsData;
      state.isLoadData = null;
      state.galenThreshold = null;
    },
    setFullMeasurements: (state, action) => {
      state.measurements = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsImproveScore: (state, action) => {
      state.isImproveScore = action.payload;
    },
    setGalenThreshold: (state, action) => {
      state.galenThreshold = action.payload;
    },
    setGalenApiCheckResult: (state, action) => {
      state.galenApiCheckResult = action.payload;
    },
    setDeviceDataId: (state, action) => {
      state.deviceDataId = action.payload;
    },
    setIsDataSend: (state, action) => {
      state.isDataSend = action.payload;
    },
  },
});

export const {
  stepperRank,
  instructionsPopUpState,
  checkStepperContent,
  stepperData,
  vibrationPopUp,
  setFactoryPopUp,
  setLoadDataPopUp,
  setFirmwareUpdatePopUp,
  setMeasurements,
  setCutMeasurements,
  setGalenThreshold,
  setIsLoading,
  setIsImproveScore,
  setGalenApiCheckResult,
  setDefaultMeasurements,
  setDeviceDataId,
  setFullMeasurements,
  setIsLoadData,
  setIsSetDefaultData,
  setIsDataSend,
  setDefaultDataAfterLoad,
} = onBoardSlice.actions;

export const selectStepperRank = (state) => state.onBoard.checkStepperRank;
export const selectStepperContent = (state) =>
  state.onBoard.checkStepperContent;
export const selectStepperData = (state) => state.onBoard.stepsData;
export const selectUserChoice = (state) => state.onBoard.userChoice;
export const selectDevicePlacement = (state) =>
  state.onBoard.stepsData.step1.arm;
export const selectVibrationPopUp = (state) => state.onBoard.vibrationPopUp;
export const selectInstructionsPopUp = (state) =>
  state.onBoard.instructionsPopUpState;
export const selectFactoryPopUp = (state) => state.onBoard.factoryPopUp;
export const selectLoadDataPopUp = (state) => state.onBoard.loadDataPopUp;
export const selectFirmwareUpdatePopUp = (state) =>
  state.onBoard.firmwareUpdatePopUp;
export const selectMeasurements = (state) => state.onBoard.measurements;
export const selectLoading = (state) => state.onBoard.isLoading;
export const selectIsImproveScore = (state) => state.onBoard.isImproveScore;
export const selectGalenThreshold = (state) => state.onBoard.galenThreshold;
export const selectGalenApiCheckResult = (state) =>
  state.onBoard.galenApiCheckResult;
export const selectDeviceDataId = (state) => state.onBoard.deviceDataId;
export const selectIsLoadData = (state) => state.onBoard.isLoadData;
export const selectIsSetDefaultData = (state) => state.onBoard.isSetDefaultData;
export const selectIsDataSend = (state) => state.onBoard.isDataSend;

export default onBoardSlice.reducer;
