import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    token: null,
    next_firmware: null,
    current_firmware_version: null,
    userLoadData: null
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.userLoadData = null;
      state.next_firmware = null;
      state.current_firmware_version = null;
    },
    userToken: (state, action) => {
      state.token = action.payload.token;
    },
    setNextFirmware: (state, action) => {
      state.next_firmware = action.payload;
    },
    setCurrentFirmware: (state, action) => {
      state.current_firmware_version = action.payload;
    },
    setUserLoadData: (state, action) => {
      state.userLoadData = action.payload
    },
  },
});

export const { login, logout, userToken, setNextFirmware, setCurrentFirmware, setUserLoadData } = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectToken = (state) => state.user.token;
export const selectNextFirmware = (state) => state.user.next_firmware;
export const selectCurrentFirmware = (state) => state.user.current_firmware_version;
export const selectUserLoadData = (state) => state.user.userLoadData;

export default userSlice.reducer;
