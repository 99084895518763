import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectLoading, setIsLoading, stepperRank, vibrationPopUp } from '../../reducer/onBoardSlice';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

const initState = {

}

const RegisteredEmailModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(selectLoading);

    return (
        <div className="homeScreenPopoup" style={{padding: '2rem 2rem 0 2rem', borderRadius: '0'}}>
            <div className="popUp-content">
                <div className="popUp-body">
                    <p style={{
                            width: '100%', 
                            marginBottom: '5px', 
                            fontSize: '14px', fontWeight: 'bold', 
                            letterSpacing: '2px',
                            color: '#75787d'
                        }}>REGISTERED EMAIL <span style={{color: 'red'}}>*</span></p>
                    <TextField
                            id="standard-basic"
                            variant="filled"
                            name='emailAddress'
                            type='email'
                            fullWidth
                            sx={{
                                "& .MuiInputBase-root": {
                                    backgroundColor: '#e8f0ff'
                                }
                              }}
                        />
                    <span className='button-blue' style={{marginTop: '30px', width: '100%'}}>Continue</span>    
                    <p style={{margin: '20px 0 20px 0'}}>
                        <span onClick={""} style={{color: '#3840D7', cursor: 'pointer', textDecoration: 'underline'}}>Back</span>
                    </p>                                  
                </div>
            </div>
        </div>
    )
}

export default RegisteredEmailModal;