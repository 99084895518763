import { delay } from '../utils/_helperFunction';
import BluetoothTerminal from './BluetoothTerminal'

export default class BluetoothServer extends BluetoothTerminal {
    
    constructor() {
        super();
        this._data = [];
        this._isRead = false;
        this._isConnected = false;
        this.receive = this.receiveData.bind(this);
        this.getCurrentVersion = this.getVersion.bind(this);
        this.setDataToDevice = this.setDataToDevice.bind(this);
        this.count = 10;
    }

    start() {
        return this.connect()
        .then(() => {
            this._isConnected = true;
            console.log('The server is connect to device ', this._device?.name || '')
            return this._device;
        })
    }

    read(seconds) {
        this._isRead = true;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this._isRead = false;
                const workData = [...this._data];
                this._data = []
                resolve(workData);
            }, seconds * 1000);    
        });
    }

    async getVersion() {
        if (this.count > 0 && !this._currentVersion) {
            await delay(1000)
            console.log('await delay(1000)', this._currentVersion)
            this.count = this.count  - 1
            //! TEMP FOR TESTING FILE DOWNLOAD
            if (this.count === 2) {
                this._currentVersion = '0.6b96'
            }

            return this.getVersion();
        } else {
            return this._currentVersion
        }
    }
    // !data is to testing only, then empty array
    async setDataToDevice(cms = ['S', '8', '1', '4'], data , timeMs = 2000) {
        for await (const one of cms) {
            console.log('SEND_TO_DEVICE : ', one)
            try {
                this.send(one)
            } catch (error) {
                console.log('SEND_TO_DEVICE_STOP_ERROR === ', error.message || error)
            }
            await delay(timeMs)
        }
        for await (const one of data) {
            console.log('SEND_TO_DEVICE : ', one)
            try {
                this.send(one)
            } catch (error) {
                console.log('SEND_TO_DEVICE_DATA_ERROR === ', error.message || error)
            }
            await delay(timeMs)
        }
        return true;
    }

    async receiveData(data) {
        if (this._isRead) {
            this._data.push(data);
        }
    }
    getDevice() {
        return this._device;
    }

    async sendCommand(key, ms = 3000) {
        if (!key) {
            return
        }
        console.log('sendCommand to device === ', key)
        this.send(key)
        await delay(ms)
        return true;
    }
}

