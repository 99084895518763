import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    selectLoading,
    setIsLoading,
    vibrationPopUp,
    stepperRank,
} from '../../reducer/onBoardSlice';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import Loading from '../../shared/Loading-screen/Loading';
import { apiMethods, baseGalenHeaders, galenBasePath } from '../../api/helper';
import { fetchData } from '../../api/fetchData';
import { setNotify } from '../../reducer/notifySlice';
import { notifyTypes } from '../../utils/constants';

const PasswordResetModal = ({ checkEmailVerify }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectLoading);
    const [error, setError] = useState('');
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [password, setPassword] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [otpCode, setOtpCode] = useState('');
    let [searchParams] = useSearchParams();

    React.useEffect(() => {
        const emailAddress = searchParams.get('email');
        const otpCode = searchParams.get('otp');
        if (emailAddress && emailAddress.includes('@')) {
            setEmailAddress(emailAddress);
        }
        if (otpCode && !isNaN(Number(otpCode))) {
            setOtpCode(otpCode);
        }
    }, [searchParams]);

    const handleChange = (event) => {
        if (isLoading) {
            return;
        }
        setPassword(event.target.value);
        if (error) {
            setError('');
        }
    };

    const onPasswordResetClick = async () => {
        const passwordRegex = new RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,})/);

        if (!passwordRegex.test(password)) {
            setIsPasswordError(true);
            return;
        }
        else {
            setIsPasswordError(false);
        }
        
        dispatch(setIsLoading(true));
        try {
            const path = `${galenBasePath}/user/password/reset`;
            const body = {
                emailAddress,
                newPassword: password,
                confirmPassword: password,
                otpCode,
            };
            await fetchData(path, apiMethods.POST, body, null, baseGalenHeaders);
            dispatch(
                setNotify({
                    message: `Password reset successfully`,
                    type: notifyTypes.SUCCESS,
                    duration: 3000,
                })
            );                                    
            dispatch(stepperRank(0.1));
            dispatch(vibrationPopUp(false));
        } catch (error) {
            dispatch(
                setNotify({
                    message: error,
                    type: notifyTypes.ERROR,
                    duration: 3000,
                })
            );
        }
        dispatch(setIsLoading(false));
    };

    return (
        <div className="homeScreenPopoup" style={{ borderRadius: '0', position: 'relative' }}>
            <div className="popUp-content" style={{ marginTop: '0px' }}>
                <div className="popUp-body" style={{ paddingTop: '0px' }}>
                    <div>
                        <p className="popUp-panel-text" style={{ marginBottom: '25px' }}>
                            Please enter new password and OTP{' '}
                            {emailAddress && `for ${emailAddress}`}
                        </p>
                    </div>
                    <TextField
                        id="standard-basic"
                        label="New password"
                        variant="standard"
                        name="newPassword"
                        type={'password'}
                        error={!!isPasswordError}
                        value={password}
                        focused
                        // helperText={emailError}
                        helperText="Must be 8 or more characters and contain at least 1 number, 1 lower case letter, 1 upper case letter and 1 special character."
                        onChange={handleChange}
                        fullWidth
                    />
                    {!isPasswordError.length ? <div style={{ height: '23px' }}></div> : null}
                    <TextField
                        id="standard-basic"
                        label="OTP"
                        variant="standard"
                        name="otpCode"
                        value={otpCode}
                        error={error.length ? true : false}
                        helperText={error}
                        onChange={handleChange}
                        fullWidth
                        disabed
                        // sx={{
                        //     "& label": {
                        //       textAlign: 'center',
                        //       fontSize: '26px',
                        //       position: 'relative !important',
                        //       top: '15px !important',
                        //       color: '#C1C1C1'
                        //     },
                        //     "& .MuiFormLabel-root.Mui-focused": {
                        //         position: 'absolute !important',
                        //         marginTop: '-32px',
                        //     },
                        //     "& .MuiInputBase-root": {
                        //         marginTop: '0'
                        //     }
                        //   }}
                    />
                    {!error.length ? <div style={{ height: '23px' }}></div> : null}
                    <button
                        className="button-blue"
                        disabled={isLoading}
                        onClick={onPasswordResetClick}
                        style={{ marginTop: '30px' }}
                    >
                        Reset
                    </button>
                </div>
            </div>
            {isLoading && <Loading isInBlock />}
        </div>
    );
};

export default PasswordResetModal;