import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectLoading, setIsLoading, stepperRank } from '../../reducer/onBoardSlice';
import {login} from "../../reducer/userSlice";
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { delay } from '../../utils/_helperFunction';
import Loading from '../../shared/Loading-screen/Loading';
import { apiMethods, baseGalenHeaders, galenBasePath } from '../../api/helper';
import { fetchData } from '../../api/fetchData';
import { setNotify } from '../../reducer/notifySlice';
import { notifyTypes } from '../../utils/constants';

const initState = {
    emailAddress: '',
    otpCode: ''
}

const EmailVerificationModal = ({checkEmailVerify}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(selectLoading);
    const [error, setError] = React.useState('')
    const [emailError, setEmailError] = React.useState('')
    const [state, setState] = React.useState(initState);
    const { emailAddress, otpCode } = state;
    let [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {
        const emailAddress = searchParams.get('email');
        const otpCode = searchParams.get('otp');
        if (emailAddress && emailAddress.includes('@')) {
            setState(prev => ({...prev, emailAddress}))
        }
        if (otpCode && !isNaN(Number(otpCode))) {
            setState(prev => ({...prev, otpCode}))
        }
    }, [searchParams]);

    const handleChange = (event) => {
        if (isLoading) {
            return
        }
        const name = event.target.name;
        let value = event.target.value;
        setState(prev => ({...prev, [name]: value}))
        if (error) {
            setError('')
        }
    }

    const onSubmit =  async () => {
        if (!emailAddress.length || !emailAddress.includes('@')) {
            setEmailError('Enter valid email')
            return
        }
        if (!otpCode?.length) {
            setError('Otp code is too short')
            return
        }
        const { error } = await checkEmailVerify(emailAddress, otpCode);
        if (error) {
            setEmailError(error)
        }
        dispatch(setIsLoading(false))
    }

    const onCodeResend = async () => {
        if (!emailAddress.length || !emailAddress.includes('@')) {
            setEmailError('Enter valid email')
            return
        }
        dispatch(setIsLoading(true))
        try {
            const path = `${galenBasePath}/user/register/send-code?email=${emailAddress}`;
            const method = apiMethods.POST;
            const headers = {
                ...baseGalenHeaders
            };
            await fetchData(path, method, {}, null, headers);
            dispatch(
                setNotify({
                    message: `Please check ${emailAddress} email to activate your account`,
                    type: notifyTypes.INFO,
                    duration: 3000
                })
            );
        } catch (error) {
            dispatch(setNotify({message: `Code resend error ${error}`, type: notifyTypes.ERROR, duration: 3000}))
        }
        dispatch(setIsLoading(false))
    }

    const userEmail = searchParams.get('email')?.includes('@') ? searchParams.get('email') : null;

    return (
        <div
            className="homeScreenPopoup"
            style={{ padding: '2rem 2rem 0 2rem', borderRadius: '0', position: 'relative' }}
        >
            <div className="popUp-content">
                <div className="popUp-body">
                    <div>
                        <p className="popUp-panel-text" style={{ lineHeight: '45px' }}>
                            Please enter the confirmation code sent to {userEmail ? userEmail : 'your email address'}
                        </p>
                    </div>
                    <TextField
                        id="standard-basic"
                        label="Email"
                        variant="standard"
                        name="emailAddress"
                        type="email"
                        error={emailError.length ? true : false}
                        value={emailAddress}
                        helperText={emailError}
                        onChange={handleChange}
                        fullWidth
                    />
                    {!emailError.length ? <div style={{ height: '23px' }}></div> : null}
                    <TextField
                        id="standard-basic"
                        label="OTP"
                        variant="standard"
                        name="otpCode"
                        type="otp"
                        value={otpCode}
                        error={error.length ? true : false}
                        helperText={error}
                        onChange={handleChange}
                        fullWidth
                        // sx={{
                        //     "& label": {
                        //       textAlign: 'center',
                        //       fontSize: '26px',
                        //       position: 'relative !important',
                        //       top: '15px !important',
                        //       color: '#C1C1C1'
                        //     },
                        //     "& .MuiFormLabel-root.Mui-focused": {
                        //         position: 'absolute !important',
                        //         marginTop: '-32px',
                        //     },
                        //     "& .MuiInputBase-root": {
                        //         marginTop: '0'
                        //     }
                        //   }}
                    />
                    {!error.length ? <div style={{ height: '23px' }}></div> : null}
                    <button
                        className="button-blue"
                        disabled={isLoading}
                        onClick={onSubmit}
                        style={{ marginTop: '30px', width: '100%' }}
                    >
                        Confirm
                    </button>
                    <p style={{ margin: '20px 0 20px 0' }}>
                        <span>Didn’t receive the code?</span>
                        <span
                            onClick={onCodeResend}
                            style={{
                                color: !isLoading ? '#3840D7' : 'gray',
                                cursor: 'pointer',
                                marginLeft: '10px',
                            }}
                            className="onCodeResend"
                        >
                            Resend code
                        </span>
                    </p>
                </div>
            </div>
            {isLoading && <Loading isInBlock />}
        </div>
    );
}

export default EmailVerificationModal;