export const galenBasePath = "https://api.galencloud.com";
export const apiMethods = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
};
export const baseGalenHeaders = {
  "X-TENANT-DOMAIN": "6degrees-dev.galencloud.com",
  "X-API-VERSION": "3",
  "X-DEVICE-TYPE": "Device",
};
