
import { createSlice } from '@reduxjs/toolkit';
import { notifyTypes } from '../utils/constants';
import { getRandomNumber } from '../utils/_helperFunction';

export const notifySlice = createSlice({
  name: 'notify',
  initialState: {
    notifications: []
  },
  reducers: {
    setNotify:(state, action) => {
      const newNotification = {...action.payload || {}, id: getRandomNumber() }
      if (!newNotification.type) (
        newNotification.type = notifyTypes.SUCCESS
      )
      if (!newNotification.message) (
        newNotification.message = 'Successfully!'
      )
      if (!newNotification.duration) (
        newNotification.duration = 3000
      )
      state.notifications = [...state.notifications, newNotification];
    },
    removeNotify:(state, action) => {
      state.notifications = state.notifications.filter(n => n.id !== action.payload);
    },
  },
});

export const { setNotify, removeNotify } = notifySlice.actions;

export const selectNotifications = (state) => state.notify.notifications;

export default notifySlice.reducer;
