import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  selectFactoryPopUp,
  selectLoadDataPopUp,
  selectLoading,
  selectStepperData,
  selectStepperRank,
  setDefaultDataAfterLoad,
  setFactoryPopUp,
  setLoadDataPopUp,
  stepperRank,
} from '../../reducer/onBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectUser } from '../../reducer/userSlice';
import VibrationModal from '../../modals/commonModal/CommonModal';
import account_circle from '../../images/account_circle.png';
import './Header.css';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Header = () => {
  const [headerTitle, setHeaderTitle] = React.useState(null);
  const StepperRank = useSelector(selectStepperRank);
  const location = useLocation();
  const isLoading = useSelector(selectLoading);
  const [dropDown, setDropDown] = React.useState(null);
  const factoryPopUp = useSelector(selectFactoryPopUp);
  const loadDataPopUp = useSelector(selectLoadDataPopUp);
  const [resetFactory, setResetFactory] = React.useState(false);
  const [dataLoad, setDataLoad] = React.useState(false);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepData = useSelector(selectStepperData);

  React.useEffect(() => {
    const { wrist } = stepData['step1'];
    if (StepperRank === 0) {
      setHeaderTitle('Device Placement');
    } else if (StepperRank === 1) {
      setHeaderTitle(wrist ? 'Select base position' : 'Base position training');
    } else if (StepperRank === 1.5) {
      setHeaderTitle('Base position training');
    } else if (StepperRank === 2) {
      setHeaderTitle('Select clicking style');
    } else if (StepperRank === 2.5) {
      setHeaderTitle('Left click training');
    } else if (StepperRank === 2.6) {
      setHeaderTitle('Select clicking style');
    } else if (StepperRank === 2.7) {
      setHeaderTitle('Right click training');
    } else if (StepperRank === 2.8) {
      setHeaderTitle('Selected clicking styles');
    } else if (StepperRank >= 3 && StepperRank < 3.4) {
      setHeaderTitle('Calibration');
    } else if (StepperRank === 4) {
      setHeaderTitle('Calculating score... Please wait');
    } else if (StepperRank === 4.1) {
      const header = isLoading
        ? ['Uploading data to your MyMove, please wait...']
        : ['Congratulations!', <br />, "You've finished the calibration"];
      setHeaderTitle(header);
    } else if (StepperRank === 5) {
      setHeaderTitle([
        'You are done.',
        ' Please turn off this webpage and',
        <br />,
        'restart your device.',
      ]);
    } else if (StepperRank === 5.5) {
      setHeaderTitle([
        'Congratulations!',
        <br />,
        "You've finished the calibration",
      ]);
    } else if (StepperRank === 6) {
      setHeaderTitle([
        'You are done.',
        ' Please turn off this webpage and',
        <br />,
        'restart your device.',
      ]);
    }
  }, [StepperRank, isLoading, stepData]);

  const headerMargin = React.useMemo(() => {
    let m = '';
    if (StepperRank === 0) {
      m = '80px';
    }
    if (
      StepperRank === 1 ||
      StepperRank === 1.5 ||
      StepperRank === 2.5 ||
      StepperRank === 2.7 ||
      StepperRank >= 3
    ) {
      m = '100px';
    }
    if (StepperRank === 2.8) {
      m = '40px';
    }
    if (StepperRank === 2.6) {
      m = '40px';
    }
    if (StepperRank === 2) {
      m = '20px';
    }
    return m;
  }, [StepperRank]);

  const handleClick = (event) => {
    if (dropDown !== event.currentTarget && !isLoading && StepperRank !== 5) {
      setDropDown(event.currentTarget);
    }
  };
  const handleClose = () => {
    setDropDown(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(setDefaultDataAfterLoad());
    dispatch(stepperRank(0));
    setDropDown(null);
    navigate('/');
  };

  const factoryReset = () => {
    // if (!resetFactory){
    dispatch(setFactoryPopUp(true));
    setResetFactory(true);
    // }
  };

  const loadData = () => {
    dispatch(setLoadDataPopUp(true));
    setDataLoad(true);
  };

  const handleHomeClick = () => {
    if (StepperRank === 5) {
      return;
    }
    // else  {
    //   dispatch(stepperRank(0))
    //   dispatch(setDefaultDataAfterLoad())
    //   dispatch(setGalenApiCheckResult(null))
    // }
  };

  const isDisable = isLoading || StepperRank === 5;

  return (
    <>
      {location?.pathname === '/onboarding' && (
        <header className="header_container">
          <div className="header_home">
            <Link to={isDisable ? '#' : '/'}>
              <img
                src="/assets/Images/Home_icon.png"
                alt="homeIcon"
                style={{ cursor: isDisable ? 'default' : 'pointer' }}
                onClick={handleHomeClick}
              />
            </Link>

            <img
              src={account_circle}
              alt="account_circle"
              className="accountCircle"
            />

            <Button
              onMouseOver={handleClick}
              // onMouseLeave={handleClose}
              style={{
                color: 'white',
                textTransform: 'none',
                fontFamily: 'sequelSansMediumBody',
                fontSize: '16px',
                cursor: isDisable ? 'default' : 'pointer',
              }}
            >
              {user?.userName || ''}
            </Button>

            <Menu
              anchorEl={dropDown}
              open={Boolean(dropDown)}
              onClose={handleClose}
              MenuListProps={{ onMouseLeave: handleClose }}
            >
              <MenuItem onClick={factoryReset}>Factory Reset</MenuItem>
              <MenuItem onClick={loadData}>Load Data</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            {factoryPopUp && resetFactory && (
              <VibrationModal modalType="factoryReset" />
            )}
            {loadDataPopUp && dataLoad && (
              <VibrationModal modalType="loadData" />
            )}
          </div>
          <h3 style={{ width: '100%' }}>
            {headerTitle}
          </h3>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <img
              src="/assets/Images/mainLogo.png"
              alt="mainLogo"
              className="mainLogo"
            />
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
