/* eslint-disable default-case */
import MoveUpArm_Still from "../images/step5/up/MoveUpArm_Still.png";
import MoveDownArm_Still from "../images/step5/up/MoveUpArm_Still.png";
import ArmTwistOut_Still from "../images/step3/ArmTwistOut_Still.gif";
import ArmTwistIn from "../images/step3/ArmTwistIn.gif";
import ArmTwistOut from "../images/step3/ArmTwistOut.gif";

import MoveDownArm from "../images/step5/down/MoveDownArm.gif";
import MoveUpArm from "../images/step5/up/MoveUpArm.gif";
import Shrug_Still_Blue from "../images/step3/Shrug_Still_Blue.png";
import ShrugDown from "../images/step3/ShrugDown.gif";
import ShrugDown_Still from "../images/step3/ShrugDown_Still.gif";
import ShrugUp from "../images/step3/ShrugUp.gif";
import ShrugUp_Still from "../images/step3/ShrugUp_Still.gif";

import KneeUP from "../images/step3/KneeUP.gif";
import KneeUP_Still from "../images/step3/KneeUP_Still.gif";
import KneeUP_Still_Blue from "../images/step3/KneeUP_Still_Blue.png";

import KneeDown from "../images/step3/KneeDown.gif";
import KneeDown_still from "../images/step3/KneeDown_still.gif";
import KneeDown_Still_Blue from "../images/step3/KneeDown_Still_Blue.png";

import LegTwistIn from "../images/step3/LegTwistIn.gif";
import LegTwistIn_still from "../images/step3/LegTwistIn_still.gif";
import LegTwistIn_still_Blue from "../images/step3/LegTwistIn_still_Blue.png";

import LegTwistOut from "../images/step3/LegTwistOut.gif";
import LegTwistOut_still from "../images/step3/LegTwistOut_still.gif";
import LegTwistOut_still_Blue from "../images/step3/LegTwistOut_still_Blue.png";

import VerticalTwistIn from "../images/step3/wrist_twist/VerticalTwistIn.gif";
import VerticalTwistIn_still from "../images/step3/wrist_twist/VerticalTwistIn_still.gif";
import VerticalTwistIn_still_blue from "../images/step3/wrist_twist/VerticalTwistIn_still_blue.png";
import VerticalTwistOut from "../images/step3/wrist_twist/VerticalTwistOut.gif";

import HorizontalTwistIn from "../images/step3/wrist_twist/HorizontalTwistIn.gif";
import HorizontalTwistOut from "../images/step3/wrist_twist/HorizontalTwistOut.gif";
import HorizontalTwistOut_Still from "../images/step3/wrist_twist/HorizontalTwistOut_Still.gif";
import HorizontalTwistOut_Still_Blue from "../images/step3/wrist_twist/HorizontalTwistOut_Still_Blue.png";

import HorizontalFistBumpIn from "../images/step3/wrist_bump/HorizontalFistBumpIn.gif";
import HorizontalFistBumpIn_Still from "../images/step3/wrist_bump/HorizontalFistBumpIn_Still.gif";
import HorizontalFistBumpOut_Still from "../images/step3/wrist_bump/HorizontalFistBumpOut_Still.gif";
import HorizontalFistBumpIn_Still_Blue from "../images/step3/wrist_bump/HorizontalFistBumpIn_Still_Blue.png";

import HorizontalFistBumpOut from "../images/step3/wrist_bump/HorizontalFistBumpOut.gif";

import VerticalFistBumpIn from "../images/step3/wrist_bump/VerticalFistBumpIn.gif";
import VerticalFistBumpIn_still from "../images/step3/wrist_bump/VerticalFistBumpIn_still.gif";
import VerticalFistBumpIn_still_Blue from "../images/step3/wrist_bump/VerticalFistBumpIn_still_Blue.png";

import VerticalFistBumpOut from "../images/step3/wrist_bump/VerticalFistBumpOut.gif";
import VerticalFistBumpOut_still from "../images/step3/wrist_bump/VerticalFistBumpOut_still.gif";

import HorizontalWhipUp from "../images/step3/wrist_whip/HorizontalWhipUp.gif";
import HorizontalWhipUp_still from "../images/step3/wrist_whip/HorizontalWhipUp_still.gif";

import HorizontalWhipDown from "../images/step3/wrist_whip/HorizontalWhipDown.gif";
import HorizontalWhipDown_still from "../images/step3/wrist_whip/HorizontalWhipDown_still.gif";
import HorizontalWhipDown_still_blue from "../images/step3/wrist_whip/HorizontalWhipDown_still_blue.png";

import VerticalWhipUp from "../images/step3/wrist_whip/VerticalWhipUp.gif";
import VerticalWhipUp_still from "../images/step3/wrist_whip/VerticalWhipUp_still.gif";
import VerticalWhipUp_still_blue from "../images/step3/wrist_whip/VerticalWhipUp_still_blue.png";

import VerticalWhipDown from "../images/step3/wrist_whip/VerticalWhipDown.gif";
import VerticalWhipDown_still from "../images/step3/wrist_whip/VerticalWhipDown_still.gif";
import VerticalWhipDown_still_blue from "../images/step3/wrist_whip/VerticalWhipDown_still_blue.png";

import MoveRigthArm from "../images/step5/right/MoveRigthArm.gif";
import MoveRigthArm_still from "../images/step5/right/MoveRigthArm_still.png";
import MoveRigthArm_still_Blue from "../images/step5/right/MoveRigthArm_still_Blue.png";

import MoveRigthLeg from "../images/step5/right/SideKickRigth.gif";
import MoveLeftLeg from "../images/step5/left/SideKickLeft.gif";
import MoveLeg_still from "../images/step5/right/SideKick_still.gif";
import MoveLeg_still_blue from "../images/step5/right/SideKick_still_blue.png";

import MoveRigthWrist from "../images/step5/right/MoveRigthWrist.gif";
import MoveLeftWrist from "../images/step5/left/MoveLeftWrist.gif";
import MoveWrist_still from "../images/step5/right/MoveWrist_still.png";
import MoveWrist_still_blue from "../images/step5/right/MoveWrist_still_blue.png";

import MoveRigthWristH from "../images/step5/right/MoveRigthWristH.gif";
import MoveLeftWristH from "../images/step5/left/MoveLeftWristH.gif";
import MoveWristH_still from "../images/step5/right/MoveWristH_still.png";
import MoveWristH_still_blue from "../images/step5/right/MoveWristH_still_blue.png";

import MoveLeftArm from "../images/step5/left/MoveLeftArm.gif";
import MoveLeftArm_still from "../images/step5/left/MoveLeftArm_still.png";
import MoveLeftArm_still_blue from "../images/step5/left/MoveLeftArm_still_blue.png";

import MoveUpWrist from "../images/step5/up/MoveUpWrist.gif";
import MoveUpWrist_still from "../images/step5/up/MoveUpWrist_still.gif";

import MoveUpLeg from "../images/step5/up/MoveUpLeg.gif";
import MoveUpLeg_Still from "../images/step5/up/MoveUpLeg_Still.png";
import MoveUpWrist_still_Blue from "../images/step5/up/MoveUpWrist_still_Blue.png";

import MoveDownWrist from "../images/step5/down/MoveDownWrist.gif";
import MoveDownWrist_still from "../images/step5/down/MoveDownWrist_still.gif";

import MoveDownLeg from "../images/step5/down/MoveDownLeg.gif";
import MoveDownLeg_Still from "../images/step5/up/MoveUpLeg_Still.png";
import MoveDownLeg_Still_Blue from "../images/step5/up/MoveUpLeg_Still_Blue.png";
import MoveDownWrist_still_Blue from "../images/step5/down/MoveDownWrist_still_Blue.png";
import MoveDownArm_Still_Blue from "../images/step5/up/MoveUpArm_Still_Blue.png";
import MoveUpArm_Still_Blue from "../images/step5/up/MoveUpArm_Still_Blue.png";

import ArmTwistOut_Still_Blue from "../images/step5/down/ArmTwistOut_Still_Blue.png";

import { clickForDevice, stepsToDevice } from "./constants";

export const delay = (waitTimeInMs) =>
  new Promise((resolve) => setTimeout(resolve, waitTimeInMs));

export const getRandomNumber = (min = 10000000, max = 99999999) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const getStepsData = (data = {}) => {
  const items = [
    "arm",
    "leg",
    "wrist",
    "left",
    "right",
    "horizontal",
    "vertical",
    "vibration",
    "frontKick",
    "sideKick",
    "kneeUp",
    "game",
    "regular",
    "twist",
    "whip",
    "swipe",
    "bump",
    "up",
    "down",
    "in",
    "out",
    "shrugUp",
    "shrugDown",
    "armTwist",
    "leftClick",
    "rightClick",
    "legTwistIn",
    "legTwistOut",
    "kneeDown",
  ];
  const result = {};
  Object.keys(data).forEach((key) => {
    if (items.includes(key) && data[key]) {
      result[key] = data[key];
    }
  });
  return result;
};

export const getSwipeData = (rank) => {
  let data = {};
  if (rank === 3) {
    data = { swipe: true, right: true };
  }
  if (rank === 3.1) {
    data = { swipe: true, left: true };
  }
  if (rank === 3.2) {
    data = { swipe: true, up: true };
  }
  if (rank === 3.3) {
    data = { swipe: true, down: true };
  }
  return data;
};

export const getExerciseData = (stepData = {}, step = "step3") => {
  let exercise = "";
  let imageData = "";
  let baseImage = "";
  let clickData = "";
  let restImage = "";
  const isVertical = stepData["step2"]?.vertical || stepData[step]?.vertical;
  switch (true) {
    case stepData.step1?.leg:
      switch (true) {
        // case stepData[step]?.frontKick:
        //     exercise = 'Kick'
        //     imageData = leg_kick_front_animate;
        //     baseImage = leg_kick_front;
        //     clickData = 'FrontKick'
        //     restImage = Leg_Base_postion;
        //     break;
        // case stepData[step]?.sideKick:
        //     exercise = 'Side kick'
        //     imageData = leg_kick_side_animate;
        //     baseImage = leg_kick_side;
        //     clickData = stepData[step]?.left ? 'SideKickLeft' : 'SideKickRight';
        //     restImage = out_position;
        //     break;
        case stepData[step]?.legTwistIn:
          exercise = "Twist in";
          imageData = LegTwistIn;
          baseImage = LegTwistIn_still_Blue;
          clickData = "LegTwistIn";
          restImage = LegTwistIn_still;
          break;
        case stepData[step]?.legTwistOut:
          exercise = "Twist out";
          imageData = LegTwistOut;
          baseImage = LegTwistOut_still_Blue;
          clickData = "LegTwistOut";
          restImage = LegTwistOut_still;
          break;
        case stepData[step]?.kneeUp:
          exercise = "Knee up";
          imageData = KneeUP;
          baseImage = KneeUP_Still_Blue;
          clickData = "KneeUP";
          restImage = KneeUP_Still;
          break;
        case stepData[step]?.kneeDown:
          exercise = "Knee down";
          imageData = KneeDown;
          baseImage = KneeDown_Still_Blue;
          clickData = "KneeDown";
          restImage = KneeDown_still;
          break;
      }
      break;
    case stepData.step1?.arm:
      switch (true) {
        case stepData[step]?.shrugUp:
          exercise = "Shrug up";
          imageData = ShrugUp;
          baseImage = Shrug_Still_Blue;
          restImage = ShrugUp_Still;
          clickData = "ShrugUp";
          break;
        case stepData[step]?.shrugDown:
          exercise = "Shrug down";
          imageData = ShrugDown;
          baseImage = Shrug_Still_Blue;
          restImage = ShrugDown_Still;
          clickData = "ShrugDown";
          break;
        case stepData[step]?.armTwist:
          exercise = stepData[step].in
            ? "Twist In"
            : stepData[step].out
            ? "Twist Out"
            : "Twist";
          clickData = stepData[step].in ? "TwistIn" : "TwistOut";
          imageData = stepData[step].in ? ArmTwistIn : ArmTwistOut;
          baseImage = ArmTwistOut_Still_Blue;
          restImage = ArmTwistOut_Still;
          break;
      }
      break;
    case stepData.step1?.wrist:
      switch (true) {
        case stepData[step]?.twist:
          exercise = stepData[step]?.in
            ? "Twist In"
            : stepData[step]?.out
            ? "Twist Out"
            : "Twist";
          clickData = stepData[step]?.in ? "TwistIn" : "TwistOut";
          imageData =
            isVertical && stepData[step]?.in
              ? VerticalTwistIn
              : isVertical && stepData[step]?.out
              ? VerticalTwistOut
              : stepData[step]?.in
              ? HorizontalTwistIn
              : HorizontalTwistOut;
          baseImage = isVertical
            ? VerticalTwistIn_still_blue
            : HorizontalTwistOut_Still_Blue;
          restImage = isVertical
            ? VerticalTwistIn_still
            : HorizontalTwistOut_Still;
          break;
        case stepData[step]?.whip:
          exercise = stepData[step]?.up
            ? "Whip Up"
            : stepData[step]?.down
            ? "Whip Down"
            : "Whip";
          clickData = stepData[step]?.up ? "WhipUp" : "WhipDown";
          imageData =
            isVertical && stepData[step]?.up
              ? VerticalWhipUp
              : isVertical && stepData[step]?.down
              ? VerticalWhipDown
              : stepData[step]?.up
              ? HorizontalWhipUp
              : HorizontalWhipDown;
          baseImage = isVertical
            ? VerticalWhipUp_still_blue
            : HorizontalWhipDown_still_blue;

          restImage = isVertical
            ? VerticalWhipUp_still
            : HorizontalWhipUp_still;
          break;
        // case stepData[step]?.swipe:
        //     exercise = stepData[step].left
        //         ? 'Swipe Left'
        //         : stepData[step].right
        //         ? 'Swipe Right'
        //         : 'Swipe'
        //     clickData = stepData[step].left
        //         ? 'SwipeLeft'
        //         : 'SwipeRight'
        //     imageData = whip_right_left_animation;
        //     baseImage = whip_right_left;
        //     restImage = whip_right_left;
        //     break;
        case stepData[step]?.bump:
          exercise = stepData[step]?.in
            ? "Fist bump in"
            : stepData[step]?.out
            ? "Fist bump out"
            : "Fist bump";
          clickData = stepData[step]?.in ? "FistBumpIn" : "FistBumpOut";

          imageData =
            isVertical && stepData[step]?.in
              ? VerticalFistBumpIn
              : isVertical && stepData[step]?.out
              ? VerticalFistBumpOut
              : stepData[step]?.in
              ? HorizontalFistBumpIn
              : HorizontalFistBumpOut;

          baseImage = isVertical
            ? VerticalFistBumpIn_still_Blue
            : HorizontalFistBumpIn_Still_Blue;

          restImage = isVertical
            ? stepData[step]?.in
              ? VerticalFistBumpIn_still
              : VerticalFistBumpOut_still
            : stepData[step]?.in
            ? HorizontalFistBumpIn_Still
            : HorizontalFistBumpOut_Still;
          break;
      }
      break;
  }
  const header = (
    <p>
      Repeat {exercise} x <span>10</span>
    </p>
  );
  return { exercise, header, imageData, baseImage, clickData, restImage };
};

export const getCapitalized = (str) => {
  let capitalized = "";
  if (typeof str === "string" && str.length) {
    capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  }
  return capitalized;
};

export const isEmpty = (obj) => {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
};

export const getDataToDevice = (measurements = [], stepData) => {
  const data = [];
  for (const one of measurements) {
    switch (true) {
      case !isEmpty(one["1.5"]?.filteredStepsData):
        const step_1_Data = one["1.5"]?.filteredStepsData;
        data.push(step_1_Data.left ? "1" : "2");
        data.push(step_1_Data.wrist ? "1" : step_1_Data.leg ? "2" : "3");
        data.push(step_1_Data.horizontal ? "2" : "1");
        break;
      case !isEmpty(one["2.5"]?.filteredStepsData):
        const step_2_Analyze = one["2.5"]?.galenAnalyze;
        const { clickData: leftClick } = getExerciseData(stepData, "step3");
        data.push(clickForDevice[leftClick]);
        data.push(String(step_2_Analyze.Calibration));
        break;
      case !isEmpty(one["2.7"]?.filteredStepsData):
        const step_3_Analyze = one["2.7"]?.galenAnalyze;
        data.push(String(step_3_Analyze.Calibration));
        break;
      case !isEmpty(one["3"]?.filteredStepsData):
        const step_4_Analyze = one["3"]?.galenAnalyze;
        data.push(String(step_4_Analyze.Calibration));
        break;

      case !isEmpty(one["3.1"]?.filteredStepsData):
        const step_5_Analyze = one["3.1"]?.galenAnalyze;
        data.push(String(step_5_Analyze.Calibration));
        break;
      case !isEmpty(one["3.2"]?.filteredStepsData):
        const step_6_Analyze = one["3.2"]?.galenAnalyze;
        data.push(String(step_6_Analyze.Calibration));
        break;
      case !isEmpty(one["3.3"]?.filteredStepsData):
        const step_7_Analyze = one["3.3"]?.galenAnalyze;
        data.push(String(step_7_Analyze.Calibration));
        break;
    }
  }
  return data;
};

export const getLoadDataForDevice = (loadData) => {
  const data = [];
  stepsToDevice.forEach((item) => {
    const value = loadData[item].value;
    switch (item) {
      case "Side":
        data.push(value === "Left" ? "1" : "2");
        break;
      case "Placement":
        data.push(value === "Wrist" ? "1" : value === "Leg" ? "2" : "3");
        break;
      case "Orientation":
        data.push(value === "Horizontal" ? "2" : "1");
        break;
      case "LeftClickMotion":
        data.push(clickForDevice[value]);
        break;
      case "LeftClickValue":
        data.push(String(value));
        break;
      case "RightClickValue":
        data.push(String(value));
        break;
      case "SlideRightValue":
        data.push(String(value));
        break;
      case "SlideLeftValue":
        data.push(String(value));
        break;
      case "SlideUpValue":
        data.push(String(value));
        break;
      case "SlideDownValue":
        data.push(String(value));
        break;
    }
  });
  return data;
};

export const getSortedMeasurements = (measurements = []) => {
  const result = [...measurements]?.sort((a, b) => {
    if (Object.keys(a)[0] < Object.keys(b)[0]) {
      return -1;
    }
    if (Object.keys(a)[0] > Object.keys(b)[0]) {
      return 1;
    }
    return 0;
  });
  return result;
};

export const getMoveImages = (stepData, StepperRank) => {
  let imageData = "";
  let restImage = "";
  let baseImage = "";
  switch (StepperRank) {
    case 3:
      switch (true) {
        case stepData["step1"]?.wrist:
          if (stepData["step2"]?.vertical) {
            imageData = MoveRigthWrist;
            restImage = MoveWrist_still;
            baseImage = MoveWrist_still_blue;
          } else {
            imageData = MoveRigthWristH;
            restImage = MoveWristH_still;
            baseImage = MoveWristH_still_blue;
          }
          break;
        case stepData["step1"]?.arm:
          imageData = MoveRigthArm;
          restImage = MoveRigthArm_still;
          baseImage = MoveRigthArm_still_Blue;
          break;
        case stepData["step1"]?.leg:
          imageData = MoveLeftLeg;
          restImage = MoveLeg_still;
          baseImage = MoveLeg_still_blue;
          break;
      }
      break;
    case 3.1:
      switch (true) {
        case stepData["step1"]?.wrist:
          if (stepData["step2"]?.vertical) {
            imageData = MoveLeftWrist;
            restImage = MoveWrist_still;
            baseImage = MoveWrist_still_blue;
          } else {
            imageData = MoveLeftWristH;
            restImage = MoveWristH_still;
            baseImage = MoveWristH_still_blue;
          }
          break;
        case stepData["step1"]?.arm:
          imageData = MoveLeftArm;
          restImage = MoveLeftArm_still;
          baseImage = MoveLeftArm_still_blue;
          break;
        case stepData["step1"]?.leg:
          imageData = MoveRigthLeg;
          restImage = MoveLeg_still;
          baseImage = MoveLeg_still_blue;
          break;
      }
      break;
    case 3.2:
      switch (true) {
        case stepData["step1"]?.wrist:
          if (stepData["step2"]?.vertical) {
            imageData = VerticalWhipUp;
            restImage = VerticalWhipUp_still;
            baseImage = VerticalWhipUp_still_blue;
          } else {
            imageData = HorizontalWhipUp;
            restImage = HorizontalWhipUp_still;
            baseImage = HorizontalWhipDown_still_blue;
          }
          break;
        case stepData["step1"]?.arm:
          imageData = MoveUpArm;
          restImage = MoveUpArm_Still;
          baseImage = MoveUpArm_Still_Blue;
          break;
        case stepData["step1"]?.leg:
          imageData = MoveUpLeg;
          restImage = MoveUpLeg_Still;
          baseImage = MoveDownLeg_Still_Blue;
          break;
      }
      break;
    case 3.3:
      switch (true) {
        case stepData["step1"]?.wrist:
          if (stepData["step2"]?.vertical) {
            imageData = VerticalWhipDown;
            restImage = VerticalWhipDown_still;
            baseImage = VerticalWhipDown_still_blue;
          } else {
            imageData = HorizontalWhipDown;
            restImage = HorizontalWhipDown_still;
            baseImage = HorizontalWhipDown_still_blue;
          }
          break;
        case stepData["step1"]?.arm:
          imageData = MoveDownArm;
          restImage = MoveDownArm_Still;
          baseImage = MoveDownArm_Still_Blue;
          break;
        case stepData["step1"]?.leg:
          imageData = MoveDownLeg;
          restImage = MoveDownLeg_Still;
          baseImage = MoveDownLeg_Still_Blue;
          break;
      }
      break;
  }
  return { imageData, restImage, baseImage };
};
