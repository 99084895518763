import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../reducer/userSlice';
import headerReducer from '../reducer/headerSlice';
import onBoardReducer from '../reducer/onBoardSlice';
import notifyReducer from '../reducer/notifySlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    header: headerReducer,
    onBoard: onBoardReducer,
    notify: notifyReducer
  },
});
