import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    selectLoading,
    setFullMeasurements,
    setGalenThreshold,
    setIsLoadData,
    setIsSetDefaultData,
    stepperData,
    stepperRank,
    vibrationPopUp,
    setLoadDataPopUp,
    selectStepperRank,
} from '../../reducer/onBoardSlice';
import { useDispatch } from 'react-redux';
import { selectUserLoadData } from '../../reducer/userSlice';
import Loading from '../../shared/Loading-screen/Loading';
import { fakeMeasurements, fakeStepsData } from '../../utils/constants';

const LoadDataModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector(selectLoading);
    const StepperRank = useSelector(selectStepperRank);
    // const measurements = useSelector(selectMeasurements);
    // const stepData = useSelector(selectStepperData);
    const userLoadData = useSelector(selectUserLoadData);

    const handleClose = () => {
        dispatch(vibrationPopUp(false));
        dispatch(setLoadDataPopUp(false))
        if (StepperRank <= 0.6) {
            dispatch(stepperRank(0))
        }
        navigate("/onboarding")
    }

    const setLoadDataToRedux = (fakeMeasurements, fakeStepsData, galenThreshold) => {
        dispatch(setFullMeasurements(fakeMeasurements));
        dispatch(stepperData(fakeStepsData));
        dispatch(setGalenThreshold(galenThreshold))
    }

    const handleSaved = () => {
        const load = userLoadData?.content[userLoadData?.content?.length - 1]?.data
        if (!load) {
            return
        }
        const tempStepsData = {
            ...fakeStepsData,
            "step1": {
                ...fakeStepsData['step1'],
                vibration: (load?.BuzzerStrength?.value || 'medium').toLowerCase(),
                [load?.Placement?.value?.toLowerCase()]: true,
                [load?.Side?.value?.toLowerCase()]: true
            },
            "step2": {
                'ok': true,
                [load?.Orientation?.value?.toLowerCase()]: true
            },
        }
        const tempMeasurements = [...fakeMeasurements.map(item => {
            let newItem = item;
            if (item['1.5']?.filteredStepsData) {
                newItem = { '1.5': { filteredStepsData: tempStepsData['step1'] }}
            }
            if (item['2.5']?.filteredStepsData) {
                newItem = { '2.5': { filteredStepsData: tempStepsData['step2'] }}
            }
            return newItem;
        })]

        const threshold = {
            ThresholdValue: load?.ThresholdValue?.value,
            APIVersion: load?.ApiVersion?.value,
        };

        setLoadDataToRedux(tempMeasurements, tempStepsData, threshold)
        dispatch(stepperRank(4.1))
        dispatch(setIsLoadData(true))
        navigate("/onboarding")
    }

    const handleDefault = async () => {
        dispatch(stepperRank(4.1));
        dispatch(setIsSetDefaultData(true));
        navigate('/onboarding');
    };

    return (
        <div
            className="homeScreenPopoup"
            style={{ padding: '2rem 2rem 0 2rem', borderRadius: '0', position: 'relative' }}
        >
            <div className="popUp-panel-factory" style={{ backgroundColor: '#EDEDED' }}>
                <p style={{ color: '#142194' }}>Load Data</p>
            </div>
            <div className="popUp-content">
                <div className="popUp-body">
                    <div className="factory-text" style={{ margin: '60px 70px 30px' }}>
                        <p>
                            {
                                !userLoadData
                                    ? 'No previously saved data'
                                    : 'Would you like to upload your saved data, or activate using factory default settings?'
                            }
                        </p>
                    </div>
                    <div className="blue-red-buttons" style={{ marginBottom: '30px' }}>
                        <button
                            disabled={!userLoadData}
                            onClick={handleSaved}
                            className="button-blue"
                        >
                            Saved
                        </button>
                        <button
                            onClick={handleDefault}
                            className="button-blue"
                        >
                            Default
                        </button>
                        <span
                            onClick={handleClose}
                            className="button-blue"
                        >
                            Cancel
                        </span>
                    </div>
                </div>
            </div>
            {isLoading && <Loading isInBlock />}
        </div>
    );
}

export default LoadDataModal;