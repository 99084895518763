
import { createSlice } from '@reduxjs/toolkit';

export const headerSlice = createSlice({
  name: 'header',
  initialState: {
    headerTitle: null,
  },
   reducers: {
    mainHeading:(state,action) => {
      state.headerTitle = action.payload;
    },
  },
});

export const { mainHeading } = headerSlice.actions;

export const selectMainHeading = (state) => state.header.headerTitle;

export default headerSlice.reducer;
