import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Router from './router/routes/router';
import { store } from './_store/store';
import './index.css';
import BluetoothContext from './terminal/BluetoothContext';
import BluetoothServer from './terminal/BluetoothServer';

const server = new BluetoothServer();
const container = document.getElementById('root');
const root = createRoot(container);

(function() {
	if (typeof document !== 'undefined') {
		var workerScript = document.currentScript && document.currentScript.dataset.serviceWorker;
		if (workerScript && 'serviceWorker' in navigator) {
			navigator.serviceWorker.register(workerScript);
		}
	}
})();

root.render(
	<Provider store={store}>
		<BluetoothContext.Provider value = {server}>
			<Router />
		</BluetoothContext.Provider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
